import React from 'react';
import { getSafeString } from '@cpa/base-core/helpers';
import { ICardProps, IGenericComponentData } from '@cpa/base-core/types';

import bgUrl1 from '../../../assets/images/profession/1.jpg';
import bgUrl2 from '../../../assets/images/profession/2.jpg';
import bgUrl3 from '../../../assets/images/profession/3.jpg';
import bgUrl4 from '../../../assets/images/profession/4.jpg';
import bgUrl5 from '../../../assets/images/profession/5.jpg';
import bgUrl6 from '../../../assets/images/profession/6.jpg';
import bgUrl7 from '../../../assets/images/profession/7.jpg';
import bgUrl8 from '../../../assets/images/profession/8.jpg';
import bgUrl9 from '../../../assets/images/profession/9.jpg';
import bgUrl10 from '../../../assets/images/profession/10.jpg';
import bgUrl11 from '../../../assets/images/profession/11.jpg';
import bgUrl12 from '../../../assets/images/profession/12.jpg';
import bgUrl13 from '../../../assets/images/profession/13.jpg';
import bgUrl14 from '../../../assets/images/profession/14.jpg';
import SelectionButton from '../../../components/SelectionButton/SelectionButton';

import styles from './ProfessionItemCard.module.scss';

const ProfessionItemCard: React.FC<ICardProps> = ({ data, item, onClick, onItemSelect, isSelected, hideSelectionMarker }) => {
  const fullComponentData = data as IGenericComponentData;

  const i = fullComponentData.items.findIndex((arrItem) => arrItem.identifier === item.identifier);
  let bgImg;
  const noOfImages = 14;
  switch (true) {
    case i % noOfImages === 1:
      bgImg = bgUrl1;
      break;
    case i % noOfImages === 2:
      bgImg = bgUrl2;
      break;
    case i % noOfImages === 3:
      bgImg = bgUrl3;
      break;
    case i % noOfImages === 4:
      bgImg = bgUrl4;
      break;
    case i % noOfImages === 5:
      bgImg = bgUrl5;
      break;
    case i % noOfImages === 6:
      bgImg = bgUrl6;
      break;
    case i % noOfImages === 7:
      bgImg = bgUrl7;
      break;
    case i % noOfImages === 8:
      bgImg = bgUrl8;
      break;
    case i % noOfImages === 9:
      bgImg = bgUrl9;
      break;
    case i % noOfImages === 10:
      bgImg = bgUrl10;
      break;
    case i % noOfImages === 11:
      bgImg = bgUrl11;
      break;
    case i % noOfImages === 12:
      bgImg = bgUrl12;
      break;
    case i % noOfImages === 13:
      bgImg = bgUrl13;
      break;
    default:
      // i % noOfImages === noOfImages - noOfImages (= 0)
      bgImg = bgUrl14;
      break;
  }

  return (
    <div
      key={String(item?.identifier)}
      className={styles.item}
      onClick={onClick}
      style={{
        ...(bgImg && typeof bgImg === 'string' ? { backgroundImage: `url(${CSS.escape(bgImg)})` } : undefined),
        backgroundSize: '120%',
        backgroundPosition: 'center 0px',
        backgroundRepeat: 'no-repeat',
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      {!hideSelectionMarker && <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} />}
      <div className={styles.inner}>
        <div className={styles.title}>{item.name}</div>
        <div className={styles.text}>{getSafeString(item.description as string, true)}</div>
      </div>
    </div>
  );
};

export default ProfessionItemCard;
