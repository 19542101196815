import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { IconButton } from '@fluentui/react';

import styles from './DifferenceAsObjectsOverview.module.scss';

export interface IDifferenceAsObjectsOverviewProps {
  className?: string;
  containerRef: React.RefObject<HTMLDivElement>;
  scrollHeight: number;
}

const DifferenceAsObjectsOverview: React.FC<IDifferenceAsObjectsOverviewProps> = ({ className, containerRef, scrollHeight }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const navigatorRef = useRef<HTMLDivElement>(null);

  const [redPositions, setRedPositions] = useState<{ from: number; to: number }[]>([]);
  const [greenPositions, setGreenPositions] = useState<{ from: number; to: number }[]>([]);

  const getElementPositions = useCallback(
    (selector: string): { from: number; to: number }[] => {
      if (!containerRef.current) {
        return [];
      }

      const differenceContainerRect = containerRef.current.getBoundingClientRect();
      const totalHeight = scrollHeight - differenceContainerRect.top;
      const containerTopOffset = differenceContainerRect.top - containerRef.current.scrollTop;

      const elements = containerRef.current.querySelectorAll(selector) as unknown as HTMLElement[];

      const results: { from: number; to: number }[] = [];

      for (const element of elements) {
        if (!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)) {
          continue;
        }

        const rect = element.getBoundingClientRect();

        results.push({
          from: Math.floor(rect.top - containerTopOffset) / totalHeight,
          to: Math.floor(rect.bottom - containerTopOffset) / totalHeight,
        });
      }

      return results;
    },
    [scrollHeight, containerRef]
  );

  const refresh = useCallback(async () => {
    if (!containerRef.current) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 200));

    setGreenPositions(getElementPositions('[data-difference-ins="1"],ins'));
    setRedPositions(getElementPositions('[data-difference-del="1"],del'));
  }, [containerRef, getElementPositions]);

  useEffect(() => {
    refresh();
  }, [scrollHeight, refresh]);

  const onBackButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!containerRef.current) {
        return;
      }

      const currentPosition = containerRef.current.scrollTop;

      const reds = redPositions.map((position) => {
        return position.from * scrollHeight - containerRef.current!.clientHeight / 2;
      });

      const previousReds = reds.filter((s) => s < currentPosition - 50);

      const nearestRed = previousReds.length ? previousReds[previousReds.length - 1] : reds[reds.length - 1];

      containerRef.current.scrollTop = nearestRed;

      e.preventDefault();
      e.stopPropagation();
    },
    [scrollHeight, containerRef, redPositions]
  );

  const onForwardButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!containerRef.current) {
        return;
      }

      const currentPosition = containerRef.current.scrollTop || 0;

      const reds = redPositions.map((position) => {
        return position.from * scrollHeight - containerRef.current!.clientHeight / 2;
      });

      const nextReds = reds.filter((s) => s > currentPosition + 100);

      const nearestRed = nextReds.length ? nextReds[0] : reds[0];

      containerRef.current.scrollTop = nearestRed;

      e.preventDefault();
      e.stopPropagation();
    },
    [scrollHeight, containerRef, redPositions]
  );

  return (
    <div className={classNames(className, styles.overview, darkMode ? styles.overviewDark : '')}>
      <div className={styles.overviewItemsGrid}>
        <div>
          {redPositions.map((stat, index) => (
            <div
              className={classNames(styles.overviewItem, styles.overviewItemRed)}
              key={index}
              style={{
                top: `${stat.from * 100}%`,
                height: `${(stat.to - stat.from) * 100}%`,
              }}
            ></div>
          ))}
        </div>
        <div>
          {greenPositions.map((stat, index) => (
            <div
              className={classNames(styles.overviewItem, styles.overviewItemGreen)}
              key={index}
              style={{
                top: `${stat.from * 100}%`,
                height: `${(stat.to - stat.from) * 100}%`,
              }}
            ></div>
          ))}
        </div>
      </div>

      <div
        ref={navigatorRef}
        data-diff-navigator="1"
        className={classNames({
          [styles.navigator]: true,
          [styles.navigatorDark]: darkMode,
        })}
      >
        <IconButton
          iconProps={{
            iconName: 'ChevronLeft',
          }}
          onClick={onBackButtonClick}
          color="secondary"
        />

        <IconButton
          iconProps={{
            iconName: 'ChevronRight',
          }}
          onClick={onForwardButtonClick}
          color="secondary"
        />
      </div>
    </div>
  );
};

export default DifferenceAsObjectsOverview;
