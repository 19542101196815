import * as _ from 'lodash';
import { IJSONSchema } from '@cp/base-types';

/**
 * Merges provided schema overwrites with the existing ones.
 * @param schemaOverwrites - The current schema overwrites.
 * @param schemaOverwritesToMerge - An object with paths as keys and JSON schemas as values to merge into current schemaOverwrites.
 */
export function mergeOverwrites(
  schemaOverwrites: Record<string, IJSONSchema>,
  schemaOverwritesToMerge: Record<string, IJSONSchema>
): Record<string, IJSONSchema> {
  const newSchemaOverwrites = _.cloneDeep(schemaOverwrites);

  for (const [path, schema] of Object.entries(schemaOverwritesToMerge)) {
    if (!schema || !Object.keys(schema).length) {
      delete newSchemaOverwrites[path];
    } else {
      newSchemaOverwrites[path] = schema;
    }
  }

  return newSchemaOverwrites;
}
