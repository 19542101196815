import { Action, handleActions } from 'redux-actions';
import { DataOperation, SupportedEvents } from '@cp/base-types';

import { IDataItem } from '../../types';

import { wsBatchOperationStatus, wsConnected, wsDisconnected, wsEntityModification } from './actions';

const MAX_EVENTS_COUNT = 500;

const DEFAULT_STATE: IWebsocketState = {
  connected: false,
  latestChanges: {},
  latestBatchOperationStatus: undefined,
};

export interface IWebsocketState {
  connected: boolean;
  latestChanges: Record<
    string,
    {
      latest: number;
      changes: { timestamp: number; affectedItems: IDataItem[]; operation: DataOperation }[];
    }
  >;
  latestBatchOperationStatus?: {
    identifier: string;
    latest: number;
    status: SupportedEvents['BATCH_OPERATION_STATUS']['status'];
  };
}

export default handleActions<IWebsocketState, unknown>(
  {
    [wsConnected.toString()]: (state) => {
      return {
        ...state,
        connected: true,
      };
    },
    [wsDisconnected.toString()]: (state) => ({
      ...state,
      connected: false,
    }),
    [wsEntityModification.toString()]: (
      state,
      { payload }: Action<{ subjectUri: string; affectedItems: IDataItem[]; operation: DataOperation; timestamp: number }>
    ) => {
      // 3000 - Delay compensation.
      const modificationTimestamp = payload.timestamp - 3000;
      const relatedChanges = state.latestChanges[payload.subjectUri]?.changes || [];
      const updatedChanges = [
        ...(relatedChanges.length > MAX_EVENTS_COUNT ? relatedChanges.slice(relatedChanges.length - MAX_EVENTS_COUNT) : relatedChanges),
        {
          timestamp: modificationTimestamp,
          affectedItems: payload.affectedItems,
          operation: payload.operation,
        },
      ];
      return {
        ...state,
        latestChanges: {
          ...state.latestChanges,
          [payload.subjectUri]: {
            latest: modificationTimestamp,
            changes: updatedChanges,
          },
        },
      };
    },
    [wsBatchOperationStatus.toString()]: (state, { payload }: Action<SupportedEvents['BATCH_OPERATION_STATUS']>) => {
      console.debug(`Detected BatchOperation update ${payload.identifier}. Timestamp: ${payload.timestamp}.`);

      if (payload.status.state === 'SUCCESS' || payload.status.state === 'ERROR') {
        return {
          ...state,
          latestBatchOperationStatus: undefined,
        };
      }
      // 3000 - Delay compensation.
      const modificationTimestamp = payload.timestamp - 3000;
      if (!state.latestBatchOperationStatus || state.latestBatchOperationStatus.latest < modificationTimestamp) {
        return {
          ...state,
          latestBatchOperationStatus: {
            identifier: payload.identifier,
            latest: modificationTimestamp,
            status: payload.status,
          },
        };
      } else return state;
    },
  },
  DEFAULT_STATE
);
