import { IHeading, useHeadings, useScrollSpy } from '@cpa/base-core/hooks';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { ScrollablePaneContext } from '../../../../../components/ScrollablePaneContextProvider/ScrollablePaneContextProvider';
import { LayoutContext } from '../../../../../components/Layout/Layout';
import ExpandButton from '../../../../../components/ExpandButton/ExpandButton';
import HoverTooltip from '../../../../../components/HoverTooltip/HoverTooltip';

import styles from './TableOfContents.module.scss';

interface ITableOfContentsProps {
  solutionWrapper: HTMLElement | null;
  solutionIdentifier?: string;
  disableAnchors?: boolean;
  renderHeader?: () => JSX.Element | null;
  extraDependency?: unknown;
  onItemClick?: () => void;
  expanded: boolean;
  onExpand: () => void;
  renderCloseButton?: boolean;
  additionalSelectorsRendered?: number;
  isMobileDevice: boolean;
}

const findTocItemByKey = (headings: IHeading[], hash: string): IHeading | undefined => {
  let matchedTocItem;
  for (const heading of headings) {
    if (heading.key === hash) {
      matchedTocItem = heading;
      break;
    }
    if (heading.children) {
      const matchedChildren = heading.children.find((children) => children.key === hash);
      if (matchedChildren) {
        matchedTocItem = matchedChildren;
        break;
      }
    }
  }
  return matchedTocItem;
};

const TableOfContents: React.FC<ITableOfContentsProps> = ({
  isMobileDevice,
  solutionWrapper,
  solutionIdentifier,
  disableAnchors = false,
  renderHeader,
  extraDependency,
  onItemClick,
  expanded,
  onExpand,
  renderCloseButton = false,
  additionalSelectorsRendered,
}) => {
  const [t] = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const isUrlHashHandled = useRef(false);
  const scrollablePaneContext = useContext(ScrollablePaneContext);
  const layoutContext = useContext(LayoutContext);
  const tocWrapper = useRef<HTMLUListElement>(null);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const headings = useHeadings(solutionWrapper, solutionIdentifier, extraDependency);
  const activeKey = useScrollSpy(headings, { rootMargin: '0% 0% 0% 0%', threshold: 0.5 }, scrollablePaneContext);
  useEffect(() => {
    if (!tocWrapper.current) return;
    const activeTocItem = tocWrapper.current.querySelector(`[data-key="${activeKey}"]`);
    if (!activeTocItem) return;
    activeTocItem.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
  }, [activeKey]);
  useEffect(() => {
    if (!isUrlHashHandled.current) {
      const hash = window.location.hash?.replace('#', '');
      if (!hash) return;
      const matchedTocItem = findTocItemByKey(headings, hash);
      if (!matchedTocItem) return;
      matchedTocItem.element.scrollIntoView();
      isUrlHashHandled.current = true;
    }
  }, [headings]);

  const handleExpand = useCallback(
    (key: string): void => {
      if (expandedKeys.includes(key)) {
        setExpandedKeys(expandedKeys.filter((expandedKey) => expandedKey !== key));
      } else {
        setExpandedKeys([...expandedKeys, key]);
      }
    },
    [expandedKeys]
  );

  const renderTocHeading = useCallback(
    (heading: IHeading, index: number | string, isChildren: boolean, isExpanded: boolean = false): JSX.Element => {
      const topMargin = 10 - heading.level * 4;
      return (
        <li
          data-key={heading.key}
          className={classNames({
            [styles.link]: !darkMode,
            [styles.active]: activeKey === heading.key,
            [styles.dark]: darkMode,
            [styles.light]: !darkMode,
            [styles.levelZero]: heading.level === 0,
            [styles.levelOne]: heading.level === 1,
          })}
          key={index}
          style={
            isChildren
              ? { marginLeft: `${heading.level - 2}em`, marginTop: `${topMargin >= 0 ? topMargin : 0}px` }
              : {
                  paddingLeft: '10px',
                  marginLeft: `${heading.level - 2}em`,
                  marginTop: `${topMargin >= 0 ? topMargin : 0}px`,
                }
          }
        >
          <a
            href={`${window.location.pathname}#${heading.key}`}
            onClick={(e) => {
              e.preventDefault();
              heading.element && heading.element.scrollIntoView();

              const url = window.location.pathname + window.location.search + `#${heading.key}`;

              if (!disableAnchors) {
                window.history.replaceState('', '', url);
              }
              onItemClick?.();
            }}
          >
            {heading.text}
          </a>
          {heading.children?.length ? (
            <ExpandButton className={styles.expandButton} isExpanded={isExpanded} onClick={() => handleExpand(heading.key)} size={12} />
          ) : null}
        </li>
      );
    },
    [activeKey, darkMode, disableAnchors, handleExpand, onItemClick]
  );

  const selectorsHeight = useMemo(() => {
    const menuHeight = layoutContext?.isHorizontalMenu ? 259 : 209;
    const additionalSelectorsHeight = (additionalSelectorsRendered || 0) * 80;
    return menuHeight + additionalSelectorsHeight;
  }, [additionalSelectorsRendered, layoutContext?.isHorizontalMenu]);

  return useMemo(() => {
    return (
      <div className={classNames({ [styles.headings]: true, [styles.collapsed]: !expanded })}>
        {renderCloseButton && (
          <HoverTooltip
            hostClassName={classNames({
              [styles.closeButton]: true,
              [styles.fadeIn]: expanded,
              [styles.fadeOut]: !expanded,
            })}
            content={t('common.hideToc')}
          >
            <IconButton iconProps={{ iconName: 'CalculatorMultiply' }} onClick={onExpand} />
          </HoverTooltip>
        )}
        {renderHeader?.()}
        <ul ref={tocWrapper} style={isMobileDevice ? {} : { maxHeight: `calc(100vh - ${selectorsHeight}px)` }}>
          {headings.slice(1).map((heading, index) => {
            const isChildrenActive = heading.children.length && heading.children.filter((child) => child.key === activeKey).length;
            const isExpanded = activeKey === heading.key || !!isChildrenActive || expandedKeys.includes(heading.key);
            return (
              <React.Fragment key={index}>
                {renderTocHeading(heading, index, false, isExpanded)}
                {isExpanded && heading.children.length
                  ? heading.children.map((children, childIIndex) => renderTocHeading(children, `${index}-${childIIndex}`, true))
                  : null}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    );
  }, [selectorsHeight, expanded, renderCloseButton, t, onExpand, renderHeader, headings, activeKey, expandedKeys, renderTocHeading]);
};

export default TableOfContents;
