import React, { useCallback, useEffect, useRef, useState } from 'react';
import { axiosDictionary, executeAggregationTemplate } from '@cpa/base-core/api';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { TypeConstants } from '@cp/base-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TargetWidgetSize } from '../../TargetWidget';
import HoverTooltip from '../../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './PlatformIcons.module.scss';

interface IProductIcon {
  icon: string;
  name: string;
}

interface IPlatformColorsProps {
  size: TargetWidgetSize;
  platforms?: string[];
}

const PlatformIcons: React.FC<IPlatformColorsProps> = ({ size, platforms }) => {
  const [icons, setIcons] = useState<IProductIcon[]>([]);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const [t] = useTranslation();

  const loadItems = useCallback(async () => {
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();

    try {
      const response = await executeAggregationTemplate(
        axiosDictionary.appDataService,
        TypeConstants.ProductPlatform,
        'solution-platform-icons',
        { platforms: platforms?.map((platform) => platform.toLowerCase()) || [] },
        undefined,
        cancelToken.current
      );
      setIcons(response.map((entity) => ({ icon: entity.image, name: entity.name })).filter((item) => !!item.icon) as IProductIcon[]);
    } catch (e) {
      console.error(`Response error.`, e as Error);
    }
  }, [platforms]);

  useEffect(() => {
    loadItems();

    return () => {
      cancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!icons.length) return null;

  return (
    <>
      {size === TargetWidgetSize.Default ? <h2 className={styles.title}>{t('common.productPlatforms')}:</h2> : null}
      <div
        className={classNames({
          [styles.iconsWrapper]: true,
          [styles.small]: size === TargetWidgetSize.Small,
          [styles.default]: size === TargetWidgetSize.Default,
        })}
      >
        {icons.map((icon, index) => {
          return (
            <HoverTooltip key={index} content={icon.name}>
              <div>
                <img key={icon.name} src={icon.icon} alt={icon.name} />
              </div>
            </HoverTooltip>
          );
        })}
      </div>
    </>
  );
};

export default PlatformIcons;
