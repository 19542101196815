import { parseMarkdown } from '@cpa/base-core/helpers';
import { replaceInternalLinks } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { IconButton, Text } from '@fluentui/react';
import { FieldProps } from '@rjsf/core';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import styles from './DescripionField.module.scss';

export interface IDescriptionFieldProps extends Partial<FieldProps> {
  description?: string;
  detailed?: boolean;
  hasDetails?: boolean;
}

const DescriptionField = ({ description, detailed, hasDetails }: IDescriptionFieldProps): JSX.Element | null => {
  const hideDescriptionsInForm = useSelector((state: IGlobalState) => state.settings.hideDescriptionsInForm);

  const textRef = useRef<HTMLSpanElement>(null);
  const [expandable, setExpandable] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { ref: resizeRef } = useResizeDetector({
    onResize: () => {
      const height = textRef.current?.getBoundingClientRect().height;
      if (height && height > 29) {
        setExpandable(true);
      } else {
        setExpandable(false);
      }
    },
  });

  useEffect(() => {
    if (!detailed) setExpanded(false);
  }, [detailed]);

  if (description && !hideDescriptionsInForm) {
    return (
      <div
        className={classNames(styles.wrapper, { [styles.short]: hasDetails && !detailed })}
        style={hasDetails && detailed ? { marginLeft: '32px' } : {}}
      >
        <div ref={resizeRef} className={classNames(styles.contentWrapper, { [styles.unlimited]: expanded })}>
          <span ref={textRef}>
            <Text className={styles.content} variant={'small'}>
              {ReactHtmlParser(parseMarkdown(replaceInternalLinks(description))) as React.ReactNode}
            </Text>
          </span>
          {detailed && expandable && !expanded && <div className={styles.gradient} />}
        </div>
        {detailed && expandable && !expanded && (
          <IconButton className={styles.icon} iconProps={{ iconName: 'MaximumValue' }} onClick={() => setExpanded(true)} />
        )}
        {detailed && expanded && <IconButton className={styles.icon} iconProps={{ iconName: 'MinimumValue' }} onClick={() => setExpanded(false)} />}
      </div>
    );
  }

  return null;
};

export default DescriptionField;
