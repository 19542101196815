import { flattenMapToObject, objectToFlattenMap } from '@cp/base-utils';
import { getEndpoint } from '@cpa/base-core/api';
import { IDataItem } from '@cpa/base-core/types';
import urlJoin from 'url-join';

export async function machineTranslateOnTheFly(args: {
  dataUrl: string;
  dataEndpointIdentifier: string;
  identifier: string;
  toLanguage: string;
  dataItem: IDataItem<unknown>;
}): Promise<IDataItem<unknown>> {
  const endpoint = getEndpoint(args.dataEndpointIdentifier);

  const url = new URL(args.dataUrl, window.location.origin);
  url.pathname = urlJoin(url.pathname, `${encodeURIComponent(args.identifier)}/localizations/machine-translate-on-the-fly`);

  const response = await endpoint.axios.get<{ translation: { language: string; item: IDataItem } }>(url.pathname, {
    params: {
      toLanguage: args.toLanguage,
    },
  });

  if (response.translation.item) {
    const sourceItemMap = objectToFlattenMap(args.dataItem);
    const translationItemMap = objectToFlattenMap(response.translation.item);

    const mergedItemMap = new Map<string, unknown>();

    for (const [key, value] of sourceItemMap) {
      mergedItemMap.set(key, value);
    }

    for (const [key, value] of translationItemMap) {
      mergedItemMap.set(key, value);
    }

    const resultDataItem = flattenMapToObject(mergedItemMap) as unknown as IDataItem<unknown>;

    return resultDataItem;
  }

  return args.dataItem;
}
