import { IGlobalState } from '@cpa/base-core/store';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingBar from './components/LoadingBar/LoadingBar';
import styles from './LoadingLine.module.scss';

interface ILoadingLineProps {
  top?: number | string;
}

const LoadingLine: React.FC<ILoadingLineProps> = ({ top = 0 }) => {
  const isLoading: boolean = useSelector((state: IGlobalState) => !!state.app.loadingCounter);

  const [loading, setLoading] = useState(isLoading);

  const updateLoading = useCallback(
    _.debounce((value: boolean) => {
      setLoading(value);
    }, 200),
    []
  );

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
      updateLoading(true);
    } else {
      updateLoading(false);
    }
    return (): void => {
      updateLoading.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div className={styles.wrapper} style={{ top: top }}>
      <LoadingBar loading={loading} height={2} color={'#b39c4d'} />
    </div>
  );
};

export default React.memo(LoadingLine);
