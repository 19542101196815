import React, { CSSProperties, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import { MessageBarType, ThemeContext } from '@fluentui/react';
import { Link } from 'react-router-dom';

import MessageBars from '../../../../components/MessageBars/MessageBars';
import { IKpiRenderProps } from '../../../../components/Kpi/Kpi';
import HtmlContent from '../../../../components/HtmlContent/HtmlContent';
import ShimmerGrid from '../../../../components/ShimmerGrid/ShimmerGrid';

import styles from './KpiView.module.scss';

const KpiView: React.FC<IKpiRenderProps> = ({ value, name, isFetching, errors, redirectPath, identifier }) => {
  const theme = useContext(ThemeContext);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const shimmerGridStyle = useMemo<CSSProperties>(() => ({ position: 'absolute', width: '100%', height: '100%' }), []);
  const content = (
    <div
      style={{ backgroundColor: darkMode ? theme?.palette.neutralSecondaryAlt : theme?.palette.white }}
      className={classNames(styles.tile, { [styles.clickable]: !!redirectPath, [styles.tileDark]: darkMode })}
    >
      <MessageBars messageBarType={MessageBarType.error} isMultiline={false} messages={errors} />
      {isFetching && <ShimmerGrid style={shimmerGridStyle} darkMode={darkMode} gridArea={'"a"'} gridGap={0} height={'100%'} />}
      <div className={styles.textWrapper} style={{ visibility: isFetching ? 'hidden' : 'unset', lineBreak: 'auto' }}>
        <div className={styles.titleWrapper}>
          <span className={styles.textContent}>
            <HtmlContent style={{ display: 'inline' }} html={name} />
          </span>
        </div>
        <div className={styles.valueWrapper}>
          <span className={styles.textContent}>{value}</span>
        </div>
      </div>
    </div>
  );
  if (!value) return null;
  if (redirectPath) {
    return (
      <Link to={redirectPath} key={identifier}>
        {content}
      </Link>
    );
  }
  return content;
};

export default KpiView;
