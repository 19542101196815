import { IDarkMode } from '@cpa/base-core/types';
import { Callout, DirectionalHint, IconButton, IButtonProps } from '@fluentui/react';
import classNames from 'classnames';
import React, { CSSProperties, useCallback, useState } from 'react';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './ActionBubbles.module.scss';

export interface IActionBubble {
  key: string;
  icon: string;
  tooltip: string;
  iconStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  layerClassName?: string;
  onRender: (bubble: IActionBubble, index: number, visible: boolean, onClose: () => void) => JSX.Element | null;
  onRenderButton?: (
    bubble: IActionBubble,
    index: number,
    iconButtonRender: (iconComponentProps?: Partial<IButtonProps>) => JSX.Element
  ) => JSX.Element | null;
}

export interface IActionBubblesProps extends IDarkMode {
  buttonSize?: number;
  iconSize?: number;
  className?: string;
  bubbles: IActionBubble[];
}

const ActionBubbles: React.FC<IActionBubblesProps> = ({ buttonSize = 52, iconSize = 20, className, bubbles }) => {
  const [popupState, setPopupState] = useState<Record<string, boolean>>({});

  const actionBubbleClickHandler = useCallback(
    (bubble: IActionBubble) => (): void => {
      setPopupState((state) => ({
        ...state,
        [bubble.key]: !state[bubble.key],
      }));
    },
    []
  );

  const popupDismissHandler = useCallback(
    (bubble: IActionBubble) => (): void => {
      setPopupState((state) => ({
        ...state,
        [bubble.key]: false,
      }));
    },
    []
  );

  return (
    <>
      <div className={classNames(styles.actionBubbleGroup, className)}>
        {bubbles.map((bubble, index) => {
          const iconButtonRender = (iconComponentProps: Partial<IButtonProps> = {}): JSX.Element => (
            <IconButton
              id={`action-bubble-${bubble.key}`}
              style={{
                height: buttonSize,
                width: buttonSize,
                ...(bubble.buttonStyle || {}),
              }}
              iconProps={{
                iconName: bubble.icon,
                className: styles.icon,
                style: { fontSize: iconSize, ...(bubble.iconStyle || {}) },
              }}
              className={styles.actionBubbleButton}
              onClick={actionBubbleClickHandler(bubble)}
              {...iconComponentProps}
            />
          );
          return (
            <div key={bubble.key}>
              <HoverTooltip
                hidden={popupState[bubble.key]}
                content={bubble.tooltip}
                calloutProps={{
                  role: 'dialog',
                  gapSpace: 4,
                  beakWidth: 10,
                  directionalHint: DirectionalHint.topCenter,
                  target: `#action-bubble-${bubble.key}`,
                }}
              >
                {bubble.onRenderButton ? bubble.onRenderButton(bubble, index, iconButtonRender) : iconButtonRender()}
              </HoverTooltip>
            </div>
          );
        })}
      </div>

      {bubbles.map((bubble, index) => {
        return (
          <React.Fragment key={bubble.key}>
            <Callout
              role={'dialog'}
              gapSpace={4}
              beakWidth={10}
              directionalHint={DirectionalHint.topAutoEdge}
              setInitialFocus={false}
              target={`#action-bubble-${bubble.key}`}
              onDismiss={popupDismissHandler(bubble)}
              hidden={!popupState[bubble.key]}
              preventDismissOnScroll={true}
              preventDismissOnResize={false}
              preventDismissOnLostFocus={false}
              layerProps={{
                className: bubble.layerClassName,
              }}
            >
              {bubble.onRender(bubble, index, popupState[bubble.key], popupDismissHandler(bubble))}
            </Callout>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ActionBubbles;
