import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

export function useQuery(): { [key: string]: string } {
  const location = useLocation();
  return useMemo(() => {
    const query = new URLSearchParams(location?.search || '');
    return Array.from((query as unknown as { entries: () => [string, unknown][] }).entries()).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    ) as { [key: string]: string };
  }, [location?.search]);
}

export const useItemIdParam = (): string | undefined => {
  const params = useParams<{ id: string | undefined } | undefined>();

  return useMemo(() => {
    if (!params?.id) {
      return;
    }

    return decodeURIComponent(params.id);
  }, [params]);
};
