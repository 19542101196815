import { IJSONSchema, Schemas } from '@cp/base-types';
import { TFunction } from 'i18next';
import { clearNulls, cloneDeepWithMetadata, ODataPropsFilter, resolveSchemaPath } from '@cp/base-utils';
import { Parser } from 'json2csv';
import * as _ from 'lodash';
import flatten from 'flat';

import { IDataItem, ITableProps } from '../types';

import { containsHtml, getSafeString } from './data/html';
import { downloadFile } from './data/files';

export const exportItems = async (
  page: Schemas.CpaPage,
  t: TFunction,
  schema: IJSONSchema | null,
  items: IDataItem[],
  selectedItems?: IDataItem[]
): Promise<void> => {
  const delimiter = navigator.appVersion.indexOf('Win') ? ';' : ',';
  const parser = new Parser({
    delimiter: delimiter,
  });

  if (items.length === 0) {
    throw new Error(t('common.noItems'));
  }

  if (selectedItems && selectedItems.length) {
    const selectedItemIdentifiers = selectedItems.map((item) => item.identifier);

    items = items.filter((item) => selectedItemIdentifiers.includes(item.identifier));
  }

  const preparedItems = items.map((item) => {
    return _.mapValues(flatten(clearNulls(cloneDeepWithMetadata(item))), (value) => {
      if (value && typeof value === 'string' && containsHtml(value)) {
        return getSafeString(value, true);
      }
      return value;
    });
  });

  // Call getSafeString on each value
  const csv = parser.parse(preparedItems);
  const fields = csv.substring(0, csv.indexOf('\n')).split(delimiter);
  const processedFields = fields.map((field) => {
    const path = _.toPath(field.replaceAll('"', ''));
    const pathToResolve: string[] = [];
    const updatedPath = schema
      ? path.reduce((acc, propertyPath) => {
          if (!isNaN(parseInt(propertyPath))) {
            return `${acc} - ${propertyPath}`;
          }
          pathToResolve.push(propertyPath);
          const resolvedPath = resolveSchemaPath(schema, pathToResolve.join('.'));
          if (!resolvedPath?.title) return `${acc} - ${propertyPath}`;
          if (!acc) return resolvedPath.title;
          return `${acc} - ${resolvedPath.title}`;
        }, '')
      : null;
    return schema ? `"${updatedPath}"` : field;
  });

  const processedCsv = processedFields.join(delimiter) + csv.substring(csv.indexOf('\n'));

  // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob/17879474#17879474
  downloadFile(`${page.name}.csv`, '\ufeff' + processedCsv, 'text/csv');
};

export const handleExport = async (
  loadItems: ITableProps['loadItems'],
  page: Schemas.CpaPage,
  t: TFunction,
  schema: IJSONSchema | null,
  odataFilter?: ODataPropsFilter,
  selectedItems?: IDataItem[]
): Promise<void> => {
  return loadItems?.({ filter: odataFilter }, { detachedRequest: true, disableTriggers: true }).then(({ items }) => {
    exportItems(page, t, schema, items, selectedItems);
  });
};
