// Cpa Environment
export abstract class Environment {
  public static get env(): NodeJS.ProcessEnv {
    return (this._env = this._env ?? this.loadEnv());
  }

  private static _env?: NodeJS.ProcessEnv;

  public static loadEnv(): NodeJS.ProcessEnv {
    const env = { ...process.env };
    const envMetaElements: HTMLMetaElement[] = Array.from(document.head.querySelectorAll('meta[name^="REACT_APP_"]'));
    for (const envMetaElement of envMetaElements) {
      env[envMetaElement.name] = envMetaElement.content;
    }
    this._env = env;
    return env;
  }
}
