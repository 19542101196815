const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpJobSchedule',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobSchedule' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobStatusLog?$filter=jobSchedule%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Job Schedule',
    'properties': {
        'active': {
            'title': 'Active',
            'description': '<div>If enabled, then the job is actively scheduled.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/active', 'rel': 'canonical' }],
        },
        'attempts': {
            'title': 'Attempts',
            'description': '<div>The number of attempts to run the job if a job run fails. Defaults to 1.</div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 46 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/attempts', 'rel': 'canonical' }],
        },
        'delay': {
            'title': 'Delay',
            'description': '<div>The delay between new attempts to run the job if it failed.</div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 45 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/delay', 'rel': 'canonical' }],
        },
        'jobDefinition': {
            'title': 'Job Definition',
            'description': '<div>The job to be run according to this schedule.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpJobDefinition',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobDefinition' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/jobDefinition', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Job Definition' } },
            'cp_ui': { 'sortOrderForm': 41 },
        },
        'parameters': {
            'title': 'Parameters',
            'description': '<div>Parameters to be passed to the job function. The parameters can then be used in the job definition function.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/KeyValue',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FKeyValue' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Key Value',
                'properties': {
                    'value': {
                        'title': 'Value',
                        'description': '<div>The value the parameter should have.</div>',
                        'type': 'string',
                        'format': 'cp:code',
                        'cp_ui': { 'sortOrderForm': 20 },
                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                    },
                    'key': {
                        'title': 'Key',
                        'description': '<div>The name/key of the parameter.</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 10 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                    },
                },
                'required': ['value', 'key'],
            },
            'cp_ui': { 'sortOrderForm': 47 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/parameters', 'rel': 'canonical' }],
        },
        'priority': {
            'title': 'Priority',
            'description': '<div>The priority to job is scheduled with. Higher order jobs are more likely to be scheduled accurately. Low priority jobs can wait longer until executed. </div>',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/priority', 'rel': 'canonical' }],
        },
        'removeOnComplete': {
            'title': 'Remove On Complete',
            'description': '<div>If enabled, old job runs will be removed from the logs.</div>',
            'anyOf': [
                { 'type': 'boolean', 'title': 'Boolean' },
                { 'type': 'number', 'title': 'Number' },
            ],
            'cp_ui': { 'sortOrderForm': 38 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/removeOnComplete', 'rel': 'canonical' }],
        },
        'removeOnFail': {
            'title': 'Remove On Fail',
            'description': '<div>Control if the job run should be removed if it failed.</div>',
            'anyOf': [
                { 'type': 'boolean', 'title': 'Boolean' },
                { 'type': 'number', 'title': 'Number' },
            ],
            'cp_ui': { 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/removeOnFail', 'rel': 'canonical' }],
        },
        'repeat': {
            'title': 'Repeat',
            'description': '<div>Define how often the job should be repeated.</div>',
            'anyOf': [
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpRepeatCronOptions',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpRepeatCronOptions' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Repeat Cron Options',
                    'properties': {
                        'startDate': {
                            'title': 'Start Date',
                            'description': '<div>The start date for the first scheduling of the job.</div>',
                            'type': 'string',
                            'format': 'date-time',
                            'cp_ui': { 'sortOrderForm': 50 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/startDate', 'rel': 'canonical' }],
                        },
                        'cron': {
                            'title': 'Cron',
                            'description': '<div>The scheduling pattern in cron notation. For more information see <a href="https://crontab.guru/" target="_blank" rel="noopener">https://crontab.guru/</a>.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 40 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/cron', 'rel': 'canonical' }],
                        },
                        'endDate': {
                            'title': 'End Date',
                            'description': '<div>\n<div>The end date after which the job should not be scheduled anymore.</div>\n</div>',
                            'type': 'string',
                            'format': 'date-time',
                            'cp_ui': { 'sortOrderForm': 20 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
                        },
                        'limit': {
                            'title': 'Limit',
                            'description': '<div>\n<div>The number of times the job should be scheduled.</div>\n</div>',
                            'type': 'number',
                            'cp_ui': { 'sortOrderForm': 30 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/limit', 'rel': 'canonical' }],
                        },
                        'timezone': {
                            'title': 'Timezone',
                            'description': '<div>The timezone for the scheduling in format "Europe/Berlin"</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 10 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/timezone', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpRepeatCronOptions',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpRepeatEveryOptions',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpRepeatEveryOptions' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Repeat Every Options',
                    'properties': {
                        'every': {
                            'title': 'Every',
                            'description': '<div>Time between executions in ms.</div>',
                            'type': 'number',
                            'cp_ui': { 'sortOrderForm': 40 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/every', 'rel': 'canonical' }],
                        },
                        'endDate': {
                            'title': 'End Date',
                            'description': '<div>The end date after which the job should not be scheduled anymore.</div>',
                            'type': 'string',
                            'format': 'date-time',
                            'cp_ui': { 'sortOrderForm': 20 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
                        },
                        'limit': {
                            'title': 'Limit',
                            'description': '<div>The number of times the job should be scheduled.</div>',
                            'type': 'number',
                            'cp_ui': { 'sortOrderForm': 30 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/limit', 'rel': 'canonical' }],
                        },
                        'timezone': {
                            'title': 'Timezone',
                            'description': '<div>The timezone for the scheduling in format "Europe/Berlin" or "America/Los_Angeles".</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 10 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/timezone', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpRepeatEveryOptions',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
            ],
            'cp_ui': { 'sortOrderForm': 48 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/repeat', 'rel': 'canonical' }],
        },
        'failureAlertEmails': {
            'title': 'Failure Alert Emails',
            'description': '<div>A list of emails that will be notified when the job fails to run</div>',
            'type': 'array',
            'items': { 'type': 'string' },
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/failureAlertEmail', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The display name for the scheduled job.</div>',
            'type': 'string',
            'format': 'cp:jobStatus',
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['jobDefinition'],
};
export const CpJobScheduleSchema = Schema;
