import { cpCollections, DataItemProperties, IJSONSchema, Schemas } from '@cp/base-types';
import { IDarkMode, IDataItem } from '@cpa/base-core/types';
import { DirectionalHint, FontIcon, Icon, Link, TooltipOverflowMode } from '@fluentui/react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { getMatchingPageByDataUrl } from '@cpa/base-core/helpers';
import { useTranslation } from 'react-i18next';
import { FormatSource } from '@cpa/base-core/constants';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './VersionInfo.module.scss';

interface IVersionInfoProps extends IDarkMode {
  item: IDataItem;
  srcFieldName: string | undefined;
  source: FormatSource;

  // Used only to display inside badge
  srcFieldValue: IDataItem;

  schema: IJSONSchema;
  style?: CSSProperties;
  page?: Schemas.CpaPage;
}

const VersionInfo: React.FC<IVersionInfoProps> = ({ item, srcFieldName, srcFieldValue, schema, style, darkMode, page, source }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const currenVersion = item[DataItemProperties.CURRENT_VERSION] as string | undefined;
  const publishedVersion = currenVersion ? currenVersion.split('.')[0] + '.0' : undefined;
  const itemStandard: boolean = !!item.standard;
  const publishedVersionIdentifier = item[DataItemProperties.PUBLISHED] as string | undefined;

  const allPages = useSelector((state: IGlobalState) => state.app.pages);
  const currentPage = useSelector((state: IGlobalState) => {
    return state.app.pages.find((p) => p.path && state.router.location.pathname === p.path);
  });
  const currentPageIsMajor: boolean = !!currentPage?.dataUrl?.includes(encodeURIComponent(schema.$id + '?cp_collection=' + cpCollections.publish));

  const latestMajorPage = useMemo(() => {
    if (currentPageIsMajor) {
      // Do not determine latestMajorPage if we already there (currentPage is latestMajorPage)
      return;
    }

    const targetPage = page || currentPage;

    if (!targetPage || !targetPage.dataUrl) {
      return;
    }

    let parsedCurrentPageDataUrl: URL;
    try {
      parsedCurrentPageDataUrl = new URL(targetPage.dataUrl, window.location.origin);
    } catch (e) {
      return;
    }
    parsedCurrentPageDataUrl.pathname += encodeURIComponent('?cp_collection=' + cpCollections.publish);
    const majorDataUrl = parsedCurrentPageDataUrl.pathname + parsedCurrentPageDataUrl.hash + parsedCurrentPageDataUrl.search;
    return getMatchingPageByDataUrl(majorDataUrl, allPages)?.matched;
  }, [currentPageIsMajor, page, currentPage, allPages]);

  const onMajorItemClick = useCallback(() => {
    if (latestMajorPage && latestMajorPage.path && publishedVersionIdentifier) {
      dispatch(push(latestMajorPage.path + `?filter=${encodeURIComponent(JSON.stringify({ identifier: publishedVersionIdentifier }))}`));
    }
  }, [dispatch, latestMajorPage, publishedVersionIdentifier]);

  const fieldValue = srcFieldValue && typeof srcFieldValue !== 'object' && !Array.isArray(srcFieldValue) ? srcFieldValue : '-';

  const standardIcon = useMemo(() => {
    return itemStandard ? (
      <span className={styles.standardIconWrapper}>
        <HoverTooltip
          content={`${t('common.standard')} ${schema.title?.toLowerCase() || ''}`}
          directionalHint={DirectionalHint.topCenter}
          style={{ pointerEvents: 'none' }}
          calloutProps={{ isBeakVisible: true, beakWidth: 10, gapSpace: 2, setInitialFocus: false, doNotLayer: false }}
        >
          <Icon iconName="LockSolid" className={styles.standardIcon} />
        </HoverTooltip>
      </span>
    ) : null;
  }, [itemStandard, schema.title, t]);

  const versionInfo = useMemo(() => {
    return !currentPageIsMajor ? (
      <span id={'versionBadge'}>
        <span className={styles.badgeWrapper}>
          <span className={styles.badge} style={{ minWidth: 95 }}>
            <span className={styles.versionName}>{currenVersion || '-'}</span>
            {publishedVersionIdentifier ? (
              <span>
                <span
                  className={classNames([
                    styles.major,
                    darkMode ? styles.majorDark : styles.majorLight,
                    publishedVersion !== currenVersion ? styles.outdated : styles.actual,
                  ])}
                >
                  <Link onClick={onMajorItemClick} className={styles.majorLink}>
                    <FontIcon iconName="Presentation" />
                    <span className={styles.majorVersionName}>{publishedVersion}</span>
                  </Link>
                </span>
              </span>
            ) : (
              <FontIcon className={styles.notPublishedIcon} iconName="UnpublishContent" />
            )}
          </span>
        </span>
      </span>
    ) : null;
  }, [currenVersion, currentPageIsMajor, darkMode, onMajorItemClick, publishedVersion, publishedVersionIdentifier]);

  const currentVersion = useMemo(() => {
    return srcFieldName !== DataItemProperties.CURRENT_VERSION ? (
      <div className={styles.wrapper}>
        <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={fieldValue}>
          <span>{fieldValue}</span>
        </HoverTooltip>
      </div>
    ) : null;
  }, [fieldValue, srcFieldName]);

  if (source === FormatSource.SimpleCard) {
    return (
      <span style={style} className={classNames(styles.versionWrapper, 'versionWrapper')}>
        <div className={styles.simpleCardTitleWrapper}>
          {currentVersion}
          {standardIcon}
        </div>
        {versionInfo}
      </span>
    );
  }

  return (
    <span style={style} className={classNames(styles.versionWrapper, 'versionWrapper')}>
      {versionInfo}
      {standardIcon}
      {currentVersion}
    </span>
  );
};

export default React.memo(VersionInfo);
