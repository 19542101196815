import React, { useCallback, useMemo, useState } from 'react';
import { Schemas } from '@cp/base-types';
import { DynamicDataUrlFunction } from '@cpa/base-core/types';

import Suggested from './components/Suggested';
import CrossSell from './components/CrossSell';
import UpSell from './components/UpSell';

export interface ISuggestedSolutionsProps {
  renderHeader?: () => JSX.Element;
  page: Schemas.CpaPage & { dynamicDataUrl?: DynamicDataUrlFunction };
  suggestedFilter?: string;
  suggestedLabel?: string;
  suggestedDescription?: string;
  crossSellFilter?: string;
  crossSellLabel?: string;
  crossSellDescription?: string;
  upsellFilter?: string;
  upsellLabel?: string;
  upsellDescription?: string;
  forceTocUpdate?: () => void;
}

const SuggestedSolutions: React.FC<ISuggestedSolutionsProps> = (props) => {
  const [renderedBlocks, setRenderedBlocks] = useState<number[]>([]);

  const handleOnRender = useCallback(
    (renderedCount: number, id: number) => {
      setRenderedBlocks((prev) => {
        const prevCopy = [...prev];
        prevCopy[id] = renderedCount;
        props.forceTocUpdate?.();
        return prevCopy;
      });
    },
    [props.forceTocUpdate]
  );

  const suggestedSolutions = useMemo(() => {
    return <Suggested {...props} onRender={handleOnRender} />;
  }, [handleOnRender, props]);
  const crossSellSolutions = useMemo(() => {
    return <CrossSell {...props} onRender={handleOnRender} />;
  }, [handleOnRender, props]);
  const upsellSolutions = useMemo(() => {
    return <UpSell {...props} onRender={handleOnRender} />;
  }, [handleOnRender, props]);

  return (
    <div>
      {Math.max(...renderedBlocks) !== 0 ? props.renderHeader?.() : null}
      {suggestedSolutions}
      {crossSellSolutions}
      {upsellSolutions}
    </div>
  );
};

export default SuggestedSolutions;
