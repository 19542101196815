const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/DevOpsOrganization',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDevOpsOrganization' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Floop%2FWorkItem?$filter=devOpsOrganization%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Floop%2FDevOpsOrganizationStatus?$filter=devOpsOrganization%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'DevOps Organization',
    'properties': {
        'crmAccount': {
            'title': 'Crm Account',
            '$id': 'http://platform.cosmoconsult.com/ontology/CrmAccount?allData=true',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCrmAccount%3FallData%3Dtrue' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/crmAccount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Crm Account' } },
            'cp_ui': { 'sortOrderForm': 30 },
            'cp_ignoreRelation': true,
        },
        'dockerSelfService': {
            'title': 'Docker Self Service',
            '$id': 'http://platform.cosmoconsult.com/ontology/DockerSelfService',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDockerSelfService' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/dockerSelfService', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'containerLimit': {
                    'title': 'Container Limit',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 40 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/containerLimit', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 40 },
        },
        'responsibility': {
            'title': 'Responsibility',
            'description': 'A person (alive, dead, undead, or fictional).',
            '$id': 'http://schema.org/Person',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/responsibility', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'email': {
                    'title': 'Email',
                    'description': 'Email address.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': '<div>The identifier of the DevOps organization.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The name of the DevOps organization.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const DevOpsOrganizationSchema = Schema;
