import { EventEmitter } from 'events';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataItem, IRelatedMessage } from '@cpa/base-core/types';
import * as _ from 'lodash';
import { executeBackendAction } from '@cpa/base-core/helpers';
import { axiosDictionary } from '@cpa/base-core/api';
import { IColumn, IObjectWithKey, ISelection, Selection } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { useMediaQuery } from 'react-responsive';

import MessageItem from './components/MessageItem/MessageItem';
import styles from './MessageContainer.module.scss';
import MessageInput from './components/MessageInput/MessageInput';

interface IMessageContainerProps {
  parentMessage: IDataItem;
  childItems: IDataItem[];
  isFetching: boolean;
  totalItems: number;
  pageIdentifier: string;
  lastRead?: string;
  columns: IColumn[];
  selection: ISelection<IObjectWithKey>;
  onItemsSelect?: (items: IDataItem<unknown>[], tableKey: string, unmount: boolean) => void;
  tableKey?: string;
  selectionEmitter?: EventEmitter;
}

const MessageContainer: React.FC<IMessageContainerProps> = ({
  columns,
  parentMessage,
  childItems,
  pageIdentifier,
  lastRead,
  totalItems,
  isFetching,
  onItemsSelect,
  tableKey,
  selectionEmitter,
}) => {
  const [relatedMessage, setRelatedMessage] = useState<IRelatedMessage | null>(null);
  const [fetchingMessages, setFetchingMessages] = useState<number>(totalItems);
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);
  const [selectedMessages, setSelectedMessages] = useState<number[]>([]);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });

  const selection = useMemo(() => {
    return new Selection<IDataItem>({
      onSelectionChanged: (): void => {
        onItemsSelect?.(selection.getSelection(), tableKey!, false);
      },
      getKey: (item, index): string | number => {
        return item.identifier || item.__identifier || index || JSON.stringify(item);
      },
    });
  }, [onItemsSelect, tableKey]);

  const deselectAll = useCallback(() => {
    setSelectedMessages([]);
    selection.setAllSelected(false);
  }, [selection]);

  useEffect(() => {
    selectionEmitter?.on('deselectAll', deselectAll);

    return () => {
      selectionEmitter?.off('deselectAll', deselectAll);
      onItemsSelect?.([], tableKey as string, true);
      setSelectedMessages([]);
    };
  }, [deselectAll, onItemsSelect, selectionEmitter, tableKey]);

  useEffect(() => {
    selection.setItems(childItems);
  }, [childItems, selection]);

  const onMessageReply = useCallback((relatedUserMessage: IRelatedMessage) => {
    setRelatedMessage(relatedUserMessage);
  }, []);

  const onMessageInView = useCallback(
    _.debounce(async (messageIdentifier: string) => {
      await executeBackendAction(axiosDictionary.appDataService, pageIdentifier, 'read-message', [messageIdentifier]);
    }, 1000),
    [childItems, pageIdentifier]
  );

  const handleContentLoaded = useCallback(() => {
    setFetchingMessages((prev) => (prev === 0 ? prev : prev - 1));
  }, []);

  const handleItemSelected = useCallback(
    (key: string) => {
      const isSelected = selection.isKeySelected(key);
      selection.setKeySelected(key, !isSelected, false);
      const updatedMessages = selection.getSelectedIndices();
      setSelectedMessages(updatedMessages);
    },
    [selection]
  );

  const childrenWidth = useMemo(() => {
    const column = columns.find((col) => col.key === '__simpleCard');
    if (column) return column.calculatedWidth;
    return undefined;
  }, [columns]);

  const messages = useMemo(() => {
    return childItems.map((message, index) => {
      const isOutgoingMessage = message.createdByUser === user;
      const isMessageUnread = !lastRead ? true : lastRead < (message.createdAt as string);
      const tableKey = selection.getKey(message);
      const tableIndex = selection.getItemIndex(tableKey);
      const isSelected = selectedMessages.includes(tableIndex);
      return (
        <MessageItem
          key={index}
          tableKey={tableKey}
          message={message}
          onMessageReply={onMessageReply}
          onMessageInView={onMessageInView}
          isRead={isOutgoingMessage || !isMessageUnread}
          isFetching={isFetching}
          isAllMessagesLoaded={fetchingMessages === 0}
          onContentLoaded={handleContentLoaded}
          onMessageSelected={handleItemSelected}
          isSelected={isSelected}
          width={childrenWidth}
        />
      );
    });
  }, [
    childItems,
    lastRead,
    user,
    selection,
    selectedMessages,
    onMessageReply,
    onMessageInView,
    isFetching,
    fetchingMessages,
    handleContentLoaded,
    handleItemSelected,
    childrenWidth,
  ]);

  const unsetRelatedMessage = useCallback(() => {
    setRelatedMessage(null);
  }, []);

  const width = useMemo(() => {
    if (isMobileDevice) {
      const screenWidth = window.screen.width;
      return `${screenWidth - 50}px`;
    }
    return '100%';
  }, [isMobileDevice]);

  return (
    <div className={styles.container} style={{ width: width }}>
      <div>{messages}</div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <MessageInput parentMessage={parentMessage} relatedMessage={relatedMessage} unsetRelatedMessage={unsetRelatedMessage} width={childrenWidth} />
      </div>
    </div>
  );
};

export default MessageContainer;
