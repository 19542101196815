import { containsHtml, getSafeString } from '@cpa/base-core/helpers';
import React from 'react';
import tinymce from 'tinymce/tinymce';

export const pastePreProcess = (
  plugin: never,
  args: React.SyntheticEvent & {
    content: string;
    internal: boolean;
    wordContent: boolean;
  }
): void => {
  if (!args.content || args.wordContent || !containsHtml(args.content)) {
    return;
  }

  const safeHtmlContent = getSafeString(args.content) || '';
  const dummyContainer = document.createElement('div');
  dummyContainer.innerHTML = safeHtmlContent;

  // Images
  const allowedPastedImagesAttributes = ['alt', 'class', 'height', 'src', 'style', 'width'];
  const imgs = Array.from(dummyContainer.querySelectorAll('img'));
  for (const img of imgs) {
    const imgAttrs = Array.from(img.attributes);
    for (const imgAttr of imgAttrs) {
      if (!allowedPastedImagesAttributes.includes(imgAttr.name)) {
        img.removeAttribute(imgAttr.name);
      }
    }
  }

  // Handle forced root block level element
  const forcedRootBlockLevelElemName = tinymce.activeEditor?.settings?.forced_root_block;
  if (forcedRootBlockLevelElemName && typeof forcedRootBlockLevelElemName === 'string') {
    // Paragraphs to root block level elem
    if (forcedRootBlockLevelElemName !== 'p') {
      const allowedPastedBlockLevelAttributes = ['class', 'style'];
      const paragraphs = Array.from(dummyContainer.querySelectorAll('p'));
      for (const paragraph of paragraphs) {
        const parentNode = paragraph.parentNode;
        if (parentNode) {
          const newRootBlock = document.createElement(forcedRootBlockLevelElemName);
          newRootBlock.innerHTML = paragraph.innerHTML;
          const paragraphAttrs = Array.from(paragraph.attributes);
          for (const paragraphAttr of paragraphAttrs) {
            if (allowedPastedBlockLevelAttributes.includes(paragraphAttr.name)) {
              newRootBlock.setAttribute(paragraphAttr.name, paragraphAttr.value);
            }
          }
          parentNode.replaceChild(newRootBlock, paragraph);
        }
      }
    }
  }

  args.content = dummyContainer.innerHTML;
};
