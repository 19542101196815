export class CultureInfo {
    constructor(name) {
        this.neutralCulturesMappings = [
            {
                'neutral': 'aa',
                'specificDefault': 'aa-ET',
                'threeLetterISO': 'aar',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'af',
                'specificDefault': 'af-ZA',
                'threeLetterISO': 'afr',
                'threeLetterWindows': 'AFK',
            },
            {
                'neutral': 'agq',
                'specificDefault': 'agq-CM',
                'threeLetterISO': 'agq',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ak',
                'specificDefault': 'ak-GH',
                'threeLetterISO': 'aka',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'am',
                'specificDefault': 'am-ET',
                'threeLetterISO': 'amh',
                'threeLetterWindows': 'AMH',
            },
            {
                'neutral': 'ar',
                'specificDefault': 'ar-SA',
                'threeLetterISO': 'ara',
                'threeLetterWindows': 'ARA',
            },
            {
                'neutral': 'arn',
                'specificDefault': 'arn-CL',
                'threeLetterISO': 'arn',
                'threeLetterWindows': 'MPD',
            },
            {
                'neutral': 'as',
                'specificDefault': 'as-IN',
                'threeLetterISO': 'asm',
                'threeLetterWindows': 'ASM',
            },
            {
                'neutral': 'asa',
                'specificDefault': 'asa-TZ',
                'threeLetterISO': 'asa',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ast',
                'specificDefault': 'ast-ES',
                'threeLetterISO': 'ast',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'az',
                'specificDefault': 'az-Latn-AZ',
                'threeLetterISO': 'aze',
                'threeLetterWindows': 'AZE',
            },
            {
                'neutral': 'az-Cyrl',
                'specificDefault': 'az-Cyrl-AZ',
                'threeLetterISO': 'aze',
                'threeLetterWindows': 'AZC',
            },
            {
                'neutral': 'az-Latn',
                'specificDefault': 'az-Latn-AZ',
                'threeLetterISO': 'aze',
                'threeLetterWindows': 'AZE',
            },
            {
                'neutral': 'ba',
                'specificDefault': 'ba-RU',
                'threeLetterISO': 'bak',
                'threeLetterWindows': 'BAS',
            },
            {
                'neutral': 'bas',
                'specificDefault': 'bas-CM',
                'threeLetterISO': 'bas',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'be',
                'specificDefault': 'be-BY',
                'threeLetterISO': 'bel',
                'threeLetterWindows': 'BEL',
            },
            {
                'neutral': 'bem',
                'specificDefault': 'bem-ZM',
                'threeLetterISO': 'bem',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'bez',
                'specificDefault': 'bez-TZ',
                'threeLetterISO': 'bez',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'bg',
                'specificDefault': 'bg-BG',
                'threeLetterISO': 'bul',
                'threeLetterWindows': 'BGR',
            },
            {
                'neutral': 'bin',
                'specificDefault': 'bin-NG',
                'threeLetterISO': 'bin',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'bm',
                'specificDefault': 'bm-Latn-ML',
                'threeLetterISO': 'bam',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'bm-Latn',
                'specificDefault': 'bm-Latn-ML',
                'threeLetterISO': 'bam',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'bn',
                'specificDefault': 'bn-BD',
                'threeLetterISO': 'ben',
                'threeLetterWindows': 'BNB',
            },
            {
                'neutral': 'bo',
                'specificDefault': 'bo-CN',
                'threeLetterISO': 'bod',
                'threeLetterWindows': 'BOB',
            },
            {
                'neutral': 'br',
                'specificDefault': 'br-FR',
                'threeLetterISO': 'bre',
                'threeLetterWindows': 'BRE',
            },
            {
                'neutral': 'brx',
                'specificDefault': 'brx-IN',
                'threeLetterISO': 'brx',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'bs',
                'specificDefault': 'bs-Latn-BA',
                'threeLetterISO': 'bos',
                'threeLetterWindows': 'BSB',
            },
            {
                'neutral': 'bs-Cyrl',
                'specificDefault': 'bs-Cyrl-BA',
                'threeLetterISO': 'bos',
                'threeLetterWindows': 'BSC',
            },
            {
                'neutral': 'bs-Latn',
                'specificDefault': 'bs-Latn-BA',
                'threeLetterISO': 'bos',
                'threeLetterWindows': 'BSB',
            },
            {
                'neutral': 'byn',
                'specificDefault': 'byn-ER',
                'threeLetterISO': 'byn',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ca',
                'specificDefault': 'ca-ES',
                'threeLetterISO': 'cat',
                'threeLetterWindows': 'CAT',
            },
            {
                'neutral': 'ce',
                'specificDefault': 'ce-RU',
                'threeLetterISO': 'che',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'cgg',
                'specificDefault': 'cgg-UG',
                'threeLetterISO': 'cgg',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'chr',
                'specificDefault': 'chr-Cher-US',
                'threeLetterISO': 'chr',
                'threeLetterWindows': 'CRE',
            },
            {
                'neutral': 'chr-Cher',
                'specificDefault': 'chr-Cher-US',
                'threeLetterISO': 'chr',
                'threeLetterWindows': 'CRE',
            },
            {
                'neutral': 'co',
                'specificDefault': 'co-FR',
                'threeLetterISO': 'cos',
                'threeLetterWindows': 'COS',
            },
            {
                'neutral': 'cs',
                'specificDefault': 'cs-CZ',
                'threeLetterISO': 'ces',
                'threeLetterWindows': 'CSY',
            },
            {
                'neutral': 'cu',
                'specificDefault': 'cu-RU',
                'threeLetterISO': 'chu',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'cy',
                'specificDefault': 'cy-GB',
                'threeLetterISO': 'cym',
                'threeLetterWindows': 'CYM',
            },
            {
                'neutral': 'da',
                'specificDefault': 'da-DK',
                'threeLetterISO': 'dan',
                'threeLetterWindows': 'DAN',
            },
            {
                'neutral': 'dav',
                'specificDefault': 'dav-KE',
                'threeLetterISO': 'dav',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'de',
                'specificDefault': 'de-DE',
                'threeLetterISO': 'deu',
                'threeLetterWindows': 'DEU',
            },
            {
                'neutral': 'dje',
                'specificDefault': 'dje-NE',
                'threeLetterISO': 'dje',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'dsb',
                'specificDefault': 'dsb-DE',
                'threeLetterISO': 'dsb',
                'threeLetterWindows': 'DSB',
            },
            {
                'neutral': 'dua',
                'specificDefault': 'dua-CM',
                'threeLetterISO': 'dua',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'dv',
                'specificDefault': 'dv-MV',
                'threeLetterISO': 'div',
                'threeLetterWindows': 'DIV',
            },
            {
                'neutral': 'dyo',
                'specificDefault': 'dyo-SN',
                'threeLetterISO': 'dyo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'dz',
                'specificDefault': 'dz-BT',
                'threeLetterISO': 'dzo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ebu',
                'specificDefault': 'ebu-KE',
                'threeLetterISO': 'ebu',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ee',
                'specificDefault': 'ee-GH',
                'threeLetterISO': 'ewe',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'el',
                'specificDefault': 'el-GR',
                'threeLetterISO': 'ell',
                'threeLetterWindows': 'ELL',
            },
            {
                'neutral': 'en',
                'specificDefault': 'en-US',
                'threeLetterISO': 'eng',
                'threeLetterWindows': 'ENU',
            },
            {
                'neutral': 'eo',
                'specificDefault': 'eo-001',
                'threeLetterISO': 'epo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'es',
                'specificDefault': 'es-ES',
                'threeLetterISO': 'spa',
                'threeLetterWindows': 'ESP',
            },
            {
                'neutral': 'et',
                'specificDefault': 'et-EE',
                'threeLetterISO': 'est',
                'threeLetterWindows': 'ETI',
            },
            {
                'neutral': 'eu',
                'specificDefault': 'eu-ES',
                'threeLetterISO': 'eus',
                'threeLetterWindows': 'EUQ',
            },
            {
                'neutral': 'ewo',
                'specificDefault': 'ewo-CM',
                'threeLetterISO': 'ewo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'fa',
                'specificDefault': 'fa-IR',
                'threeLetterISO': 'fas',
                'threeLetterWindows': 'FAR',
            },
            {
                'neutral': 'ff',
                'specificDefault': 'ff-Latn-SN',
                'threeLetterISO': 'ful',
                'threeLetterWindows': 'FUL',
            },
            {
                'neutral': 'ff-Latn',
                'specificDefault': 'ff-Latn-SN',
                'threeLetterISO': 'ful',
                'threeLetterWindows': 'FUL',
            },
            {
                'neutral': 'fi',
                'specificDefault': 'fi-FI',
                'threeLetterISO': 'fin',
                'threeLetterWindows': 'FIN',
            },
            {
                'neutral': 'fil',
                'specificDefault': 'fil-PH',
                'threeLetterISO': 'fil',
                'threeLetterWindows': 'FPO',
            },
            {
                'neutral': 'fo',
                'specificDefault': 'fo-FO',
                'threeLetterISO': 'fao',
                'threeLetterWindows': 'FOS',
            },
            {
                'neutral': 'fr',
                'specificDefault': 'fr-FR',
                'threeLetterISO': 'fra',
                'threeLetterWindows': 'FRA',
            },
            {
                'neutral': 'fur',
                'specificDefault': 'fur-IT',
                'threeLetterISO': 'fur',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'fy',
                'specificDefault': 'fy-NL',
                'threeLetterISO': 'fry',
                'threeLetterWindows': 'FYN',
            },
            {
                'neutral': 'ga',
                'specificDefault': 'ga-IE',
                'threeLetterISO': 'gle',
                'threeLetterWindows': 'IRE',
            },
            {
                'neutral': 'gd',
                'specificDefault': 'gd-GB',
                'threeLetterISO': 'gla',
                'threeLetterWindows': 'GLA',
            },
            {
                'neutral': 'gl',
                'specificDefault': 'gl-ES',
                'threeLetterISO': 'glg',
                'threeLetterWindows': 'GLC',
            },
            {
                'neutral': 'gn',
                'specificDefault': 'gn-PY',
                'threeLetterISO': 'grn',
                'threeLetterWindows': 'GRN',
            },
            {
                'neutral': 'gsw',
                'specificDefault': 'gsw-CH',
                'threeLetterISO': 'gsw',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'gu',
                'specificDefault': 'gu-IN',
                'threeLetterISO': 'guj',
                'threeLetterWindows': 'GUJ',
            },
            {
                'neutral': 'guz',
                'specificDefault': 'guz-KE',
                'threeLetterISO': 'guz',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'gv',
                'specificDefault': 'gv-IM',
                'threeLetterISO': 'glv',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ha',
                'specificDefault': 'ha-Latn-NG',
                'threeLetterISO': 'hau',
                'threeLetterWindows': 'HAU',
            },
            {
                'neutral': 'ha-Latn',
                'specificDefault': 'ha-Latn-NG',
                'threeLetterISO': 'hau',
                'threeLetterWindows': 'HAU',
            },
            {
                'neutral': 'haw',
                'specificDefault': 'haw-US',
                'threeLetterISO': 'haw',
                'threeLetterWindows': 'HAW',
            },
            {
                'neutral': 'he',
                'specificDefault': 'he-IL',
                'threeLetterISO': 'heb',
                'threeLetterWindows': 'HEB',
            },
            {
                'neutral': 'hi',
                'specificDefault': 'hi-IN',
                'threeLetterISO': 'hin',
                'threeLetterWindows': 'HIN',
            },
            {
                'neutral': 'hr',
                'specificDefault': 'hr-HR',
                'threeLetterISO': 'hrv',
                'threeLetterWindows': 'HRV',
            },
            {
                'neutral': 'hsb',
                'specificDefault': 'hsb-DE',
                'threeLetterISO': 'hsb',
                'threeLetterWindows': 'HSB',
            },
            {
                'neutral': 'hu',
                'specificDefault': 'hu-HU',
                'threeLetterISO': 'hun',
                'threeLetterWindows': 'HUN',
            },
            {
                'neutral': 'hy',
                'specificDefault': 'hy-AM',
                'threeLetterISO': 'hye',
                'threeLetterWindows': 'HYE',
            },
            {
                'neutral': 'ia',
                'specificDefault': 'ia-FR',
                'threeLetterISO': 'ina',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ibb',
                'specificDefault': 'ibb-NG',
                'threeLetterISO': 'ibb',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'id',
                'specificDefault': 'id-ID',
                'threeLetterISO': 'ind',
                'threeLetterWindows': 'IND',
            },
            {
                'neutral': 'ig',
                'specificDefault': 'ig-NG',
                'threeLetterISO': 'ibo',
                'threeLetterWindows': 'IBO',
            },
            {
                'neutral': 'ii',
                'specificDefault': 'ii-CN',
                'threeLetterISO': 'iii',
                'threeLetterWindows': 'III',
            },
            {
                'neutral': 'is',
                'specificDefault': 'is-IS',
                'threeLetterISO': 'isl',
                'threeLetterWindows': 'ISL',
            },
            {
                'neutral': 'it',
                'specificDefault': 'it-IT',
                'threeLetterISO': 'ita',
                'threeLetterWindows': 'ITA',
            },
            {
                'neutral': 'iu',
                'specificDefault': 'iu-Latn-CA',
                'threeLetterISO': 'iku',
                'threeLetterWindows': 'IUK',
            },
            {
                'neutral': 'iu-Cans',
                'specificDefault': 'iu-Cans-CA',
                'threeLetterISO': 'iku',
                'threeLetterWindows': 'IUS',
            },
            {
                'neutral': 'iu-Latn',
                'specificDefault': 'iu-Latn-CA',
                'threeLetterISO': 'iku',
                'threeLetterWindows': 'IUK',
            },
            {
                'neutral': 'ja',
                'specificDefault': 'ja-JP',
                'threeLetterISO': 'jpn',
                'threeLetterWindows': 'JPN',
            },
            {
                'neutral': 'jgo',
                'specificDefault': 'jgo-CM',
                'threeLetterISO': 'jgo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'jmc',
                'specificDefault': 'jmc-TZ',
                'threeLetterISO': 'jmc',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'jv',
                'specificDefault': 'jv-Latn-ID',
                'threeLetterISO': 'jav',
                'threeLetterWindows': 'JAV',
            },
            {
                'neutral': 'jv-Java',
                'specificDefault': 'jv-Java-ID',
                'threeLetterISO': 'jav',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'jv-Latn',
                'specificDefault': 'jv-Latn-ID',
                'threeLetterISO': 'jav',
                'threeLetterWindows': 'JAV',
            },
            {
                'neutral': 'ka',
                'specificDefault': 'ka-GE',
                'threeLetterISO': 'kat',
                'threeLetterWindows': 'KAT',
            },
            {
                'neutral': 'kab',
                'specificDefault': 'kab-DZ',
                'threeLetterISO': 'kab',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'kam',
                'specificDefault': 'kam-KE',
                'threeLetterISO': 'kam',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'kde',
                'specificDefault': 'kde-TZ',
                'threeLetterISO': 'kde',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'kea',
                'specificDefault': 'kea-CV',
                'threeLetterISO': 'kea',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'khq',
                'specificDefault': 'khq-ML',
                'threeLetterISO': 'khq',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ki',
                'specificDefault': 'ki-KE',
                'threeLetterISO': 'kik',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'kk',
                'specificDefault': 'kk-KZ',
                'threeLetterISO': 'kaz',
                'threeLetterWindows': 'KKZ',
            },
            {
                'neutral': 'kkj',
                'specificDefault': 'kkj-CM',
                'threeLetterISO': 'kkj',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'kl',
                'specificDefault': 'kl-GL',
                'threeLetterISO': 'kal',
                'threeLetterWindows': 'KAL',
            },
            {
                'neutral': 'kln',
                'specificDefault': 'kln-KE',
                'threeLetterISO': 'kln',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'km',
                'specificDefault': 'km-KH',
                'threeLetterISO': 'khm',
                'threeLetterWindows': 'KHM',
            },
            {
                'neutral': 'kn',
                'specificDefault': 'kn-IN',
                'threeLetterISO': 'kan',
                'threeLetterWindows': 'KDI',
            },
            {
                'neutral': 'ko',
                'specificDefault': 'ko-KR',
                'threeLetterISO': 'kor',
                'threeLetterWindows': 'KOR',
            },
            {
                'neutral': 'kok',
                'specificDefault': 'kok-IN',
                'threeLetterISO': 'kok',
                'threeLetterWindows': 'KNK',
            },
            {
                'neutral': 'kr',
                'specificDefault': 'kr-NG',
                'threeLetterISO': 'kau',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ks',
                'specificDefault': 'ks-Arab-IN',
                'threeLetterISO': 'kas',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ks-Arab',
                'specificDefault': 'ks-Arab-IN',
                'threeLetterISO': 'kas',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ks-Deva',
                'specificDefault': 'ks-Deva-IN',
                'threeLetterISO': 'kas',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ksb',
                'specificDefault': 'ksb-TZ',
                'threeLetterISO': 'ksb',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ksf',
                'specificDefault': 'ksf-CM',
                'threeLetterISO': 'ksf',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ksh',
                'specificDefault': 'ksh-DE',
                'threeLetterISO': 'ksh',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ku',
                'specificDefault': 'ku-Arab-IQ',
                'threeLetterISO': 'kur',
                'threeLetterWindows': 'KUR',
            },
            {
                'neutral': 'ku-Arab',
                'specificDefault': 'ku-Arab-IQ',
                'threeLetterISO': 'kur',
                'threeLetterWindows': 'KUR',
            },
            {
                'neutral': 'kw',
                'specificDefault': 'kw-GB',
                'threeLetterISO': 'cor',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ky',
                'specificDefault': 'ky-KG',
                'threeLetterISO': 'kir',
                'threeLetterWindows': 'KYR',
            },
            {
                'neutral': 'la',
                'specificDefault': 'la-001',
                'threeLetterISO': 'lat',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'lag',
                'specificDefault': 'lag-TZ',
                'threeLetterISO': 'lag',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'lb',
                'specificDefault': 'lb-LU',
                'threeLetterISO': 'ltz',
                'threeLetterWindows': 'LBX',
            },
            {
                'neutral': 'lg',
                'specificDefault': 'lg-UG',
                'threeLetterISO': 'lug',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'lkt',
                'specificDefault': 'lkt-US',
                'threeLetterISO': 'lkt',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ln',
                'specificDefault': 'ln-CD',
                'threeLetterISO': 'lin',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'lo',
                'specificDefault': 'lo-LA',
                'threeLetterISO': 'lao',
                'threeLetterWindows': 'LAO',
            },
            {
                'neutral': 'lrc',
                'specificDefault': 'lrc-IR',
                'threeLetterISO': 'lrc',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'lt',
                'specificDefault': 'lt-LT',
                'threeLetterISO': 'lit',
                'threeLetterWindows': 'LTH',
            },
            {
                'neutral': 'lu',
                'specificDefault': 'lu-CD',
                'threeLetterISO': 'lub',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'luo',
                'specificDefault': 'luo-KE',
                'threeLetterISO': 'luo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'luy',
                'specificDefault': 'luy-KE',
                'threeLetterISO': 'luy',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'lv',
                'specificDefault': 'lv-LV',
                'threeLetterISO': 'lav',
                'threeLetterWindows': 'LVI',
            },
            {
                'neutral': 'mas',
                'specificDefault': 'mas-KE',
                'threeLetterISO': 'mas',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'mer',
                'specificDefault': 'mer-KE',
                'threeLetterISO': 'mer',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'mfe',
                'specificDefault': 'mfe-MU',
                'threeLetterISO': 'mfe',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'mg',
                'specificDefault': 'mg-MG',
                'threeLetterISO': 'mlg',
                'threeLetterWindows': 'MLG',
            },
            {
                'neutral': 'mgh',
                'specificDefault': 'mgh-MZ',
                'threeLetterISO': 'mgh',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'mgo',
                'specificDefault': 'mgo-CM',
                'threeLetterISO': 'mgo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'mi',
                'specificDefault': 'mi-NZ',
                'threeLetterISO': 'mri',
                'threeLetterWindows': 'MRI',
            },
            {
                'neutral': 'mk',
                'specificDefault': 'mk-MK',
                'threeLetterISO': 'mkd',
                'threeLetterWindows': 'MKI',
            },
            {
                'neutral': 'ml',
                'specificDefault': 'ml-IN',
                'threeLetterISO': 'mal',
                'threeLetterWindows': 'MYM',
            },
            {
                'neutral': 'mn',
                'specificDefault': 'mn-MN',
                'threeLetterISO': 'mon',
                'threeLetterWindows': 'MON',
            },
            {
                'neutral': 'mn-Cyrl',
                'specificDefault': 'mn-MN',
                'threeLetterISO': 'mon',
                'threeLetterWindows': 'MNN',
            },
            {
                'neutral': 'mn-Mong',
                'specificDefault': 'mn-Mong-CN',
                'threeLetterISO': 'mon',
                'threeLetterWindows': 'MNG',
            },
            {
                'neutral': 'mni',
                'specificDefault': 'mni-IN',
                'threeLetterISO': 'mni',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'moh',
                'specificDefault': 'moh-CA',
                'threeLetterISO': 'moh',
                'threeLetterWindows': 'MWK',
            },
            {
                'neutral': 'mr',
                'specificDefault': 'mr-IN',
                'threeLetterISO': 'mar',
                'threeLetterWindows': 'MAR',
            },
            {
                'neutral': 'ms',
                'specificDefault': 'ms-MY',
                'threeLetterISO': 'msa',
                'threeLetterWindows': 'MSL',
            },
            {
                'neutral': 'mt',
                'specificDefault': 'mt-MT',
                'threeLetterISO': 'mlt',
                'threeLetterWindows': 'MLT',
            },
            {
                'neutral': 'mua',
                'specificDefault': 'mua-CM',
                'threeLetterISO': 'mua',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'my',
                'specificDefault': 'my-MM',
                'threeLetterISO': 'mya',
                'threeLetterWindows': 'MYA',
            },
            {
                'neutral': 'mzn',
                'specificDefault': 'mzn-IR',
                'threeLetterISO': 'mzn',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'naq',
                'specificDefault': 'naq-NA',
                'threeLetterISO': 'naq',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'nb',
                'specificDefault': 'nb-NO',
                'threeLetterISO': 'nob',
                'threeLetterWindows': 'NOR',
            },
            {
                'neutral': 'nd',
                'specificDefault': 'nd-ZW',
                'threeLetterISO': 'nde',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'nds',
                'specificDefault': 'nds-DE',
                'threeLetterISO': 'nds',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ne',
                'specificDefault': 'ne-NP',
                'threeLetterISO': 'nep',
                'threeLetterWindows': 'NEP',
            },
            {
                'neutral': 'nl',
                'specificDefault': 'nl-NL',
                'threeLetterISO': 'nld',
                'threeLetterWindows': 'NLD',
            },
            {
                'neutral': 'nmg',
                'specificDefault': 'nmg-CM',
                'threeLetterISO': 'nmg',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'nn',
                'specificDefault': 'nn-NO',
                'threeLetterISO': 'nno',
                'threeLetterWindows': 'NON',
            },
            {
                'neutral': 'nnh',
                'specificDefault': 'nnh-CM',
                'threeLetterISO': 'nnh',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'no',
                'specificDefault': 'nb-NO',
                'threeLetterISO': 'nob',
                'threeLetterWindows': 'NOR',
            },
            {
                'neutral': 'nqo',
                'specificDefault': 'nqo-GN',
                'threeLetterISO': 'nqo',
                'threeLetterWindows': 'NQO',
            },
            {
                'neutral': 'nr',
                'specificDefault': 'nr-ZA',
                'threeLetterISO': 'nbl',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'nso',
                'specificDefault': 'nso-ZA',
                'threeLetterISO': 'nso',
                'threeLetterWindows': 'NSO',
            },
            {
                'neutral': 'nus',
                'specificDefault': 'nus-SS',
                'threeLetterISO': 'nus',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'nyn',
                'specificDefault': 'nyn-UG',
                'threeLetterISO': 'nyn',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'oc',
                'specificDefault': 'oc-FR',
                'threeLetterISO': 'oci',
                'threeLetterWindows': 'OCI',
            },
            {
                'neutral': 'om',
                'specificDefault': 'om-ET',
                'threeLetterISO': 'orm',
                'threeLetterWindows': 'ORM',
            },
            {
                'neutral': 'or',
                'specificDefault': 'or-IN',
                'threeLetterISO': 'ori',
                'threeLetterWindows': 'ORI',
            },
            {
                'neutral': 'os',
                'specificDefault': 'os-GE',
                'threeLetterISO': 'oss',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'pa',
                'specificDefault': 'pa-IN',
                'threeLetterISO': 'pan',
                'threeLetterWindows': 'PAN',
            },
            {
                'neutral': 'pa-Arab',
                'specificDefault': 'pa-Arab-PK',
                'threeLetterISO': 'pan',
                'threeLetterWindows': 'PAP',
            },
            {
                'neutral': 'pa-Guru',
                'specificDefault': 'pa-IN',
                'threeLetterISO': 'pan',
                'threeLetterWindows': 'PAN',
            },
            {
                'neutral': 'pap',
                'specificDefault': 'pap-029',
                'threeLetterISO': 'pap',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'pl',
                'specificDefault': 'pl-PL',
                'threeLetterISO': 'pol',
                'threeLetterWindows': 'PLK',
            },
            {
                'neutral': 'prg',
                'specificDefault': 'prg-001',
                'threeLetterISO': 'prg',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'prs',
                'specificDefault': 'prs-AF',
                'threeLetterISO': 'prs',
                'threeLetterWindows': 'PRS',
            },
            {
                'neutral': 'ps',
                'specificDefault': 'ps-AF',
                'threeLetterISO': 'pus',
                'threeLetterWindows': 'PAS',
            },
            {
                'neutral': 'pt',
                'specificDefault': 'pt-BR',
                'threeLetterISO': 'por',
                'threeLetterWindows': 'PTB',
            },
            {
                'neutral': 'quc',
                'specificDefault': 'quc-Latn-GT',
                'threeLetterISO': 'quc',
                'threeLetterWindows': 'QUT',
            },
            {
                'neutral': 'quc-Latn',
                'specificDefault': 'quc-Latn-GT',
                'threeLetterISO': 'quc',
                'threeLetterWindows': 'QUT',
            },
            {
                'neutral': 'quz',
                'specificDefault': 'quz-BO',
                'threeLetterISO': 'qub',
                'threeLetterWindows': 'QUB',
            },
            {
                'neutral': 'rm',
                'specificDefault': 'rm-CH',
                'threeLetterISO': 'roh',
                'threeLetterWindows': 'RMC',
            },
            {
                'neutral': 'rn',
                'specificDefault': 'rn-BI',
                'threeLetterISO': 'run',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ro',
                'specificDefault': 'ro-RO',
                'threeLetterISO': 'ron',
                'threeLetterWindows': 'ROM',
            },
            {
                'neutral': 'rof',
                'specificDefault': 'rof-TZ',
                'threeLetterISO': 'rof',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ru',
                'specificDefault': 'ru-RU',
                'threeLetterISO': 'rus',
                'threeLetterWindows': 'RUS',
            },
            {
                'neutral': 'rw',
                'specificDefault': 'rw-RW',
                'threeLetterISO': 'kin',
                'threeLetterWindows': 'KIN',
            },
            {
                'neutral': 'rwk',
                'specificDefault': 'rwk-TZ',
                'threeLetterISO': 'rwk',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'sa',
                'specificDefault': 'sa-IN',
                'threeLetterISO': 'san',
                'threeLetterWindows': 'SAN',
            },
            {
                'neutral': 'sah',
                'specificDefault': 'sah-RU',
                'threeLetterISO': 'sah',
                'threeLetterWindows': 'SAH',
            },
            {
                'neutral': 'saq',
                'specificDefault': 'saq-KE',
                'threeLetterISO': 'saq',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'sbp',
                'specificDefault': 'sbp-TZ',
                'threeLetterISO': 'sbp',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'sd',
                'specificDefault': 'sd-Arab-PK',
                'threeLetterISO': 'snd',
                'threeLetterWindows': 'SIP',
            },
            {
                'neutral': 'sd-Arab',
                'specificDefault': 'sd-Arab-PK',
                'threeLetterISO': 'snd',
                'threeLetterWindows': 'SIP',
            },
            {
                'neutral': 'sd-Deva',
                'specificDefault': 'sd-Deva-IN',
                'threeLetterISO': 'snd',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'se',
                'specificDefault': 'se-NO',
                'threeLetterISO': 'sme',
                'threeLetterWindows': 'SME',
            },
            {
                'neutral': 'seh',
                'specificDefault': 'seh-MZ',
                'threeLetterISO': 'seh',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ses',
                'specificDefault': 'ses-ML',
                'threeLetterISO': 'ses',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'sg',
                'specificDefault': 'sg-CF',
                'threeLetterISO': 'sag',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'shi',
                'specificDefault': 'shi-Tfng-MA',
                'threeLetterISO': 'shi',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'shi-Latn',
                'specificDefault': 'shi-Latn-MA',
                'threeLetterISO': 'shi',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'shi-Tfng',
                'specificDefault': 'shi-Tfng-MA',
                'threeLetterISO': 'shi',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'si',
                'specificDefault': 'si-LK',
                'threeLetterISO': 'sin',
                'threeLetterWindows': 'SIN',
            },
            {
                'neutral': 'sk',
                'specificDefault': 'sk-SK',
                'threeLetterISO': 'slk',
                'threeLetterWindows': 'SKY',
            },
            {
                'neutral': 'sl',
                'specificDefault': 'sl-SI',
                'threeLetterISO': 'slv',
                'threeLetterWindows': 'SLV',
            },
            {
                'neutral': 'sma',
                'specificDefault': 'sma-SE',
                'threeLetterISO': 'sma',
                'threeLetterWindows': 'SMB',
            },
            {
                'neutral': 'smj',
                'specificDefault': 'smj-SE',
                'threeLetterISO': 'smj',
                'threeLetterWindows': 'SMK',
            },
            {
                'neutral': 'smn',
                'specificDefault': 'smn-FI',
                'threeLetterISO': 'smn',
                'threeLetterWindows': 'SMN',
            },
            {
                'neutral': 'sms',
                'specificDefault': 'sms-FI',
                'threeLetterISO': 'sms',
                'threeLetterWindows': 'SMS',
            },
            {
                'neutral': 'sn',
                'specificDefault': 'sn-Latn-ZW',
                'threeLetterISO': 'sna',
                'threeLetterWindows': 'SNA',
            },
            {
                'neutral': 'sn-Latn',
                'specificDefault': 'sn-Latn-ZW',
                'threeLetterISO': 'sna',
                'threeLetterWindows': 'SNA',
            },
            {
                'neutral': 'so',
                'specificDefault': 'so-SO',
                'threeLetterISO': 'som',
                'threeLetterWindows': 'SOM',
            },
            {
                'neutral': 'sq',
                'specificDefault': 'sq-AL',
                'threeLetterISO': 'sqi',
                'threeLetterWindows': 'SQI',
            },
            {
                'neutral': 'sr',
                'specificDefault': 'sr-Latn-RS',
                'threeLetterISO': 'srp',
                'threeLetterWindows': 'SRB',
            },
            {
                'neutral': 'sr-Cyrl',
                'specificDefault': 'sr-Cyrl-RS',
                'threeLetterISO': 'srp',
                'threeLetterWindows': 'SRO',
            },
            {
                'neutral': 'sr-Latn',
                'specificDefault': 'sr-Latn-RS',
                'threeLetterISO': 'srp',
                'threeLetterWindows': 'SRM',
            },
            {
                'neutral': 'ss',
                'specificDefault': 'ss-ZA',
                'threeLetterISO': 'ssw',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'ssy',
                'specificDefault': 'ssy-ER',
                'threeLetterISO': 'ssy',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'st',
                'specificDefault': 'st-ZA',
                'threeLetterISO': 'sot',
                'threeLetterWindows': 'SOT',
            },
            {
                'neutral': 'sv',
                'specificDefault': 'sv-SE',
                'threeLetterISO': 'swe',
                'threeLetterWindows': 'SVE',
            },
            {
                'neutral': 'sw',
                'specificDefault': 'sw-KE',
                'threeLetterISO': 'swa',
                'threeLetterWindows': 'SWK',
            },
            {
                'neutral': 'syr',
                'specificDefault': 'syr-SY',
                'threeLetterISO': 'syr',
                'threeLetterWindows': 'SYR',
            },
            {
                'neutral': 'ta',
                'specificDefault': 'ta-IN',
                'threeLetterISO': 'tam',
                'threeLetterWindows': 'TAI',
            },
            {
                'neutral': 'te',
                'specificDefault': 'te-IN',
                'threeLetterISO': 'tel',
                'threeLetterWindows': 'TEL',
            },
            {
                'neutral': 'teo',
                'specificDefault': 'teo-UG',
                'threeLetterISO': 'teo',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'tg',
                'specificDefault': 'tg-Cyrl-TJ',
                'threeLetterISO': 'tgk',
                'threeLetterWindows': 'TAJ',
            },
            {
                'neutral': 'tg-Cyrl',
                'specificDefault': 'tg-Cyrl-TJ',
                'threeLetterISO': 'tgk',
                'threeLetterWindows': 'TAJ',
            },
            {
                'neutral': 'th',
                'specificDefault': 'th-TH',
                'threeLetterISO': 'tha',
                'threeLetterWindows': 'THA',
            },
            {
                'neutral': 'ti',
                'specificDefault': 'ti-ER',
                'threeLetterISO': 'tir',
                'threeLetterWindows': 'TIR',
            },
            {
                'neutral': 'tig',
                'specificDefault': 'tig-ER',
                'threeLetterISO': 'tig',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'tk',
                'specificDefault': 'tk-TM',
                'threeLetterISO': 'tuk',
                'threeLetterWindows': 'TUK',
            },
            {
                'neutral': 'tn',
                'specificDefault': 'tn-ZA',
                'threeLetterISO': 'tsn',
                'threeLetterWindows': 'TSN',
            },
            {
                'neutral': 'to',
                'specificDefault': 'to-TO',
                'threeLetterISO': 'ton',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'tr',
                'specificDefault': 'tr-TR',
                'threeLetterISO': 'tur',
                'threeLetterWindows': 'TRK',
            },
            {
                'neutral': 'ts',
                'specificDefault': 'ts-ZA',
                'threeLetterISO': 'tso',
                'threeLetterWindows': 'TSO',
            },
            {
                'neutral': 'tt',
                'specificDefault': 'tt-RU',
                'threeLetterISO': 'tat',
                'threeLetterWindows': 'TTT',
            },
            {
                'neutral': 'twq',
                'specificDefault': 'twq-NE',
                'threeLetterISO': 'twq',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'tzm',
                'specificDefault': 'tzm-Latn-DZ',
                'threeLetterISO': 'tzm',
                'threeLetterWindows': 'TZA',
            },
            {
                'neutral': 'tzm-Arab',
                'specificDefault': 'tzm-Arab-MA',
                'threeLetterISO': 'tzm',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'tzm-Latn',
                'specificDefault': 'tzm-Latn-DZ',
                'threeLetterISO': 'tzm',
                'threeLetterWindows': 'TZA',
            },
            {
                'neutral': 'tzm-Tfng',
                'specificDefault': 'tzm-Tfng-MA',
                'threeLetterISO': 'tzm',
                'threeLetterWindows': 'TZM',
            },
            {
                'neutral': 'ug',
                'specificDefault': 'ug-CN',
                'threeLetterISO': 'uig',
                'threeLetterWindows': 'UIG',
            },
            {
                'neutral': 'uk',
                'specificDefault': 'uk-UA',
                'threeLetterISO': 'ukr',
                'threeLetterWindows': 'UKR',
            },
            {
                'neutral': 'ur',
                'specificDefault': 'ur-PK',
                'threeLetterISO': 'urd',
                'threeLetterWindows': 'URD',
            },
            {
                'neutral': 'uz',
                'specificDefault': 'uz-Latn-UZ',
                'threeLetterISO': 'uzb',
                'threeLetterWindows': 'UZB',
            },
            {
                'neutral': 'uz-Arab',
                'specificDefault': 'uz-Arab-AF',
                'threeLetterISO': 'uzb',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'uz-Cyrl',
                'specificDefault': 'uz-Cyrl-UZ',
                'threeLetterISO': 'uzb',
                'threeLetterWindows': 'UZC',
            },
            {
                'neutral': 'uz-Latn',
                'specificDefault': 'uz-Latn-UZ',
                'threeLetterISO': 'uzb',
                'threeLetterWindows': 'UZB',
            },
            {
                'neutral': 'vai',
                'specificDefault': 'vai-Vaii-LR',
                'threeLetterISO': 'vai',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'vai-Latn',
                'specificDefault': 'vai-Latn-LR',
                'threeLetterISO': 'vai',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'vai-Vaii',
                'specificDefault': 'vai-Vaii-LR',
                'threeLetterISO': 'vai',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 've',
                'specificDefault': 've-ZA',
                'threeLetterISO': 'ven',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'vi',
                'specificDefault': 'vi-VN',
                'threeLetterISO': 'vie',
                'threeLetterWindows': 'VIT',
            },
            {
                'neutral': 'vo',
                'specificDefault': 'vo-001',
                'threeLetterISO': 'vol',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'vun',
                'specificDefault': 'vun-TZ',
                'threeLetterISO': 'vun',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'wae',
                'specificDefault': 'wae-CH',
                'threeLetterISO': 'wae',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'wal',
                'specificDefault': 'wal-ET',
                'threeLetterISO': 'wal',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'wo',
                'specificDefault': 'wo-SN',
                'threeLetterISO': 'wol',
                'threeLetterWindows': 'WOL',
            },
            {
                'neutral': 'xh',
                'specificDefault': 'xh-ZA',
                'threeLetterISO': 'xho',
                'threeLetterWindows': 'XHO',
            },
            {
                'neutral': 'xog',
                'specificDefault': 'xog-UG',
                'threeLetterISO': 'xog',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'yav',
                'specificDefault': 'yav-CM',
                'threeLetterISO': 'yav',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'yi',
                'specificDefault': 'yi-001',
                'threeLetterISO': 'yid',
                'threeLetterWindows': 'ZZZ',
            },
            {
                'neutral': 'yo',
                'specificDefault': 'yo-NG',
                'threeLetterISO': 'yor',
                'threeLetterWindows': 'YOR',
            },
            {
                'neutral': 'zgh',
                'specificDefault': 'zgh-Tfng-MA',
                'threeLetterISO': 'zgh',
                'threeLetterWindows': 'ZHG',
            },
            {
                'neutral': 'zgh-Tfng',
                'specificDefault': 'zgh-Tfng-MA',
                'threeLetterISO': 'zgh',
                'threeLetterWindows': 'ZHG',
            },
            {
                'neutral': 'zh',
                'specificDefault': 'zh-CN',
                'threeLetterISO': 'zho',
                'threeLetterWindows': 'CHS',
            },
            {
                'neutral': 'zh-Hans',
                'specificDefault': 'zh-CN',
                'threeLetterISO': 'zho',
                'threeLetterWindows': 'CHS',
            },
            {
                'neutral': 'zh-Hant',
                'specificDefault': 'zh-HK',
                'threeLetterISO': 'zho',
                'threeLetterWindows': 'CHT',
            },
            {
                'neutral': 'zu',
                'specificDefault': 'zu-ZA',
                'threeLetterISO': 'zul',
                'threeLetterWindows': 'ZUL',
            },
            {
                'neutral': 'zh-CHS',
                'specificDefault': 'zh-CN',
                'threeLetterISO': 'zho',
                'threeLetterWindows': 'CHS',
            },
            {
                'neutral': 'zh-CHT',
                'specificDefault': 'zh-HK',
                'threeLetterISO': 'zho',
                'threeLetterWindows': 'CHT',
            },
        ];
        const result = /^(?:(\w{2})(?:[-_](\w{4}))?(?:[-_](\w{2}))?)?$/g.exec(name);
        if (result === null) {
            throw new RangeError(`Invalid language "${name}".`);
        }
        else if (result[0].length === 0) {
            this.language = '';
        }
        else {
            this.language = result[1].toLowerCase();
            if (result[2]) {
                this.script = result[2].charAt(0).toUpperCase() + result[2].slice(1).toLocaleLowerCase();
            }
            if (result[3]) {
                this.region = result[3].toUpperCase();
            }
        }
    }
    static get InvariantCulture() {
        if (!CultureInfo.invariantCulture) {
            CultureInfo.invariantCulture = new CultureInfo('');
        }
        return CultureInfo.invariantCulture;
    }
    get IsNeutralCulture() {
        return !this.region && !this.script;
    }
    get Name() {
        let result = this.language;
        if (this.script) {
            result += '-' + this.script;
        }
        if (this.region) {
            result += '-' + this.region;
        }
        return result;
    }
    get SpecificName() {
        if (this.region || !this.IsNeutralCulture) {
            return this.Name;
        }
        const mapping = this.getNeutralCultureMapping();
        if (mapping) {
            return mapping.specificDefault;
        }
        else {
            return this.language;
        }
    }
    get TwoLetterLanguageName() {
        return this.language;
    }
    get TwoLetterRegionName() {
        return this.region;
    }
    get ThreeLetterISOLanguageName() {
        const mapping = this.getNeutralCultureMapping();
        if (mapping) {
            return mapping.threeLetterISO;
        }
        else {
            return this.language;
        }
    }
    get ThreeLetterWindowsLanguageName() {
        const mapping = this.getNeutralCultureMapping();
        if (mapping) {
            return mapping.threeLetterWindows;
        }
        else {
            return this.language;
        }
    }
    get Parent() {
        if (this.IsNeutralCulture || this === CultureInfo.InvariantCulture) {
            return CultureInfo.InvariantCulture;
        }
        else {
            const nameParts = this.Name.split('-');
            return new CultureInfo(nameParts.slice(0, nameParts.length - 1).join('-'));
        }
    }
    toString() {
        return this.Name;
    }
    valueOf() {
        return this.Name;
    }
    getNeutralCultureMapping() {
        const mapping = this.neutralCulturesMappings.find((m) => m.neutral === this.language);
        return mapping;
    }
}
