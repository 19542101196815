import classNames from 'classnames';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './Animation.module.scss';

interface IAnimationProps {
  disable?: boolean;
  duration?: number;
  delay?: number;
  viewTriggerThreshold?: number;
  children?: React.ReactNode;
}

const Animation: React.FC<IAnimationProps> = ({ disable = false, duration = 500, delay = 0, viewTriggerThreshold = 0.00001, children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: viewTriggerThreshold,
  });

  return disable ? (
    <>{children}</>
  ) : (
    <div
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transition: `${duration}ms`,
        transitionProperty: 'opacity',
        transitionDelay: `${delay}ms`,
        transitionTimingFunction: 'ease-out',
        height: '100%',
      }}
    >
      <div
        className={classNames({ [styles.animated]: inView })}
        style={
          inView
            ? {
                animationDuration: `${duration}ms`,
                animationDelay: `${delay}ms`,
                height: '100%',
              }
            : {
                height: '100%',
              }
        }
      >
        {children}
      </div>
    </div>
  );
};

export default Animation;
