import { Url } from '../url';
export const convertResponse = (axiosResponse) => {
    if (!axiosResponse.config.url) {
        throw new Error('Could not convert request and response object. Request url is not defined.');
    }
    return {
        data: axiosResponse.data,
        status: axiosResponse.status,
        statusText: axiosResponse.statusText,
        headers: axiosResponse.headers,
        request: {
            url: Url.parse(axiosResponse.config.url),
            data: axiosResponse.config.data,
            headers: axiosResponse.config.headers,
        },
    };
};
