import { IJSONSchema } from '@cp/base-types';

/**
 * Removes schema overwrites for specific paths.
 * @param schemaOverwrites - The current schema overwrites.
 * @param pathsToRemove - An array of paths whose overwrites should be removed.
 */
export function removeOverwritePaths(schemaOverwrites: Record<string, IJSONSchema>, pathsToRemove: string[]) {
  const newSchemaOverwrites = { ...schemaOverwrites };

  for (let i = 0; i < pathsToRemove.length; i++) {
    const pathToRemove = pathsToRemove[i];
    for (const key in newSchemaOverwrites) {
      if (key.startsWith(pathToRemove)) {
        delete newSchemaOverwrites[key];
      }
    }
  }

  return newSchemaOverwrites;
}
