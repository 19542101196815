import React, { useMemo } from 'react';
import { CultureInfo } from '@cp/base-utils';

interface ILanguageProps {
  value: string | string[];
}

const Language: React.FC<ILanguageProps> = ({ value }) => {
  const formattedValue = useMemo(() => {
    return (Array.isArray(value) ? value : [value])
      .filter(Boolean)
      .map((locale) => {
        const parsedCulture = new CultureInfo(locale);
        return parsedCulture.TwoLetterLanguageName.toUpperCase();
      })
      .join(', ');
  }, [value]);
  return <div>{formattedValue}</div>;
};

export default Language;
