import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { Callout, DirectionalHint } from '@fluentui/react';

import styles from './LocalizationEditorWrapper.module.scss';

export interface LocalizationEditorWrapperProps {
  widgetRef: React.RefObject<HTMLDivElement | null>;
  children?: React.ReactNode;
  className?: string;
  isInForm?: boolean;
  centered?: boolean;
}

const LocalizationEditorWrapper: React.FC<LocalizationEditorWrapperProps> = ({
  widgetRef,
  children,
  className,
  isInForm = true,
  centered = false,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const onCalloutClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const targetElement = widgetRef.current as HTMLElement;

  if (!targetElement) {
    return null;
  }

  const mountTarget = isInForm ? targetElement.closest('.field') : targetElement;
  const wrapper = isInForm ? targetElement.closest('form') : targetElement;

  if (!mountTarget || !wrapper || !wrapper.parentElement) {
    return null;
  }

  const formBoundingRect = wrapper.getBoundingClientRect();

  const paddingX = 4;

  const width = isInForm ? formBoundingRect.width! + paddingX * 2 : '50vw';

  return (
    <div className={classNames(styles.root, className)}>
      <Callout
        
        doNotLayer={false}
        target={centered ? undefined : mountTarget}
        role={'dialog'}
        gapSpace={centered ? 200 : -1 * mountTarget.clientHeight - 50}
        directionalHint={DirectionalHint.bottomLeftEdge}
        isBeakVisible={false}
        style={{
          width: width,
        }}
        className={classNames({
          [styles.content]: true,
          [styles.contentDark]: darkMode,
          [styles.centered]: centered,
        })}
      >
        <div data-testid="LocalizationEditor" className={styles.inner} onClick={onCalloutClick}>
          {children}
        </div>
      </Callout>
    </div>
  );
};

export default LocalizationEditorWrapper;
