const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/FileAttachment',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FFileAttachment' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'File Attachment',
    'properties': {
        'file': {
            'title': 'File',
            'anyOf': [
                { 'type': 'string', 'format': 'uri', 'title': 'URL' },
                { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            ],
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/file', 'rel': 'canonical' }],
        },
        'about': {
            'title': 'About',
            'description': 'The subject matter of the content.',
            'anyOf': [
                { '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FThing&refDepth=0&derefDepth=0' },
                { '$id': 'http://platform.cosmoconsult.com/ontology/Undefined', 'type': 'null', 'title': 'Undefined' },
                {
                    '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAchievement&refDepth=0&derefDepth=0',
                },
            ],
            'links': [{ 'href': 'http://schema.org/about', 'rel': 'canonical' }],
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': "<div>Short description or summary that serves to convey the most important information on the subject or subject matter in a concise form (1-3 sentences). Often used for list, card or tiles. The idea is to capture the reader's or viewer's attention without going into detail. A short description can be compared with a teaser text.</div>\n<div> </div>",
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': {
                'anyOf': [
                    { 'type': 'string', 'title': 'List of String' },
                    {
                        '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FPerson&refDepth=0&derefDepth=0',
                    },
                    {
                        '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole&refDepth=0&derefDepth=0',
                    },
                    {
                        '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUser&refDepth=0&derefDepth=0',
                    },
                    {
                        '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCompanyUser%3FallData%3Dtrue&refDepth=0&derefDepth=0',
                    },
                ],
                'format': 'email',
                'transform': ['toLowerCase', 'trim'],
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'urlSlug': {
            'title': 'Url Slug',
            'description': '<div>https://solution-store.cosmoconsult.com/solutions/<span style="background-color: #ebe6dc; color: #464f57;"><strong>this-is-a-url-slug</strong></span>/<br />A URL slug or website slug is the last part of the URL address that is used to uniquely identify the item or page.</div>',
            'type': 'string',
            'pattern': '^[a-z0-9-_]*$',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/urlSlug', 'rel': 'canonical' }],
        },
        'stampText': {
            'title': 'Stamp Text',
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/stampText', 'rel': 'canonical' }],
        },
        'clientHint': {
            'title': 'Client Hint',
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientHint', 'rel': 'canonical' }],
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'company': {
            'title': 'Company',
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/company', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole&refDepth=0&derefDepth=0',
                    },
                    {
                        '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser&refDepth=0&derefDepth=0',
                    },
                ],
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'documents': {
            'title': 'Documents',
            'type': 'array',
            'items': {
                '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDocument&refDepth=0&derefDepth=0',
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/document', 'rel': 'canonical' }],
        },
        'blockLocalization': {
            'title': 'Block Localization',
            'description': '<div>Allows to block the whole localization feature for this item.</div>',
            'type': 'boolean',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/blockLocalization', 'rel': 'canonical' }],
        },
        'sameAs': {
            'title': 'Same As',
            'description': "URL of a reference Web page that unambiguously indicates the item's identity. E.g. the URL of the item's Wikipedia page, Wikidata entry, or official website.",
            'type': 'string',
            'format': 'uri',
            'links': [{ 'href': 'http://schema.org/sameAs', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'anyOf': [
                { 'type': 'string', 'title': 'String' },
                {
                    '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FPropertyValue&refDepth=0&derefDepth=0',
                },
                { 'type': 'string', 'format': 'uri', 'title': 'URL' },
            ],
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'additionalType': {
            'title': 'Additional Type',
            'description': "An additional type for the item, typically used for adding more specific types from external vocabularies in microdata syntax. This is a relationship between something and a class that the thing is in. In RDFa syntax, it is better to use the native RDFa syntax - the 'typeof' attribute - for multiple types. Schema.org tools may have only weaker understanding of extra types, in particular those defined externally.",
            'type': 'string',
            'format': 'uri',
            'links': [{ 'href': 'http://schema.org/additionalType', 'rel': 'canonical' }],
        },
        'alternateName': {
            'title': 'Alternate Name',
            'description': 'An alias for the item.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'disambiguatingDescription': {
            'title': 'Disambiguating Description',
            'description': 'A sub property of description. A short description of the item used to disambiguate from other, similar items. Information from other properties (in particular, name) may be necessary for the description to be useful for disambiguation.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/disambiguatingDescription', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'anyOf': [
                {
                    '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FImageObject&refDepth=0&derefDepth=0',
                },
                { 'type': 'string', 'format': 'uri', 'title': 'URL' },
                { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            ],
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
        'potentialAction': {
            'title': 'Potential Action',
            'description': "Indicates a potential Action, which describes an idealized action in which this thing would play an 'object' role.",
            '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FAction&refDepth=0&derefDepth=0',
            'links': [{ 'href': 'http://schema.org/potentialAction', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
            'type': 'string',
            'format': 'uri',
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
        'mainEntityOfPage': {
            'title': 'Main Entity Of Page',
            'description': 'Indicates a page (or other CreativeWork) for which this thing is the main entity being described. See [background notes](/docs/datamodel.html#mainEntityBackground) for details.',
            'anyOf': [
                {
                    '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FCreativeWork&refDepth=0&derefDepth=0',
                },
                { 'type': 'string', 'format': 'uri', 'title': 'URL' },
            ],
            'links': [{ 'href': 'http://schema.org/mainEntityOfPage', 'rel': 'canonical' }],
        },
        'subjectOf': {
            'title': 'Subject Of',
            'description': 'A CreativeWork or Event about this Thing.',
            'anyOf': [
                {
                    '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FCreativeWork&refDepth=0&derefDepth=0',
                },
                { '$ref': 'https://meta.platform.cosmoconsult.com/ontology/schemajson?subjectUri=http%3A%2F%2Fschema.org%2FEvent&refDepth=0&derefDepth=0' },
            ],
            'links': [{ 'href': 'http://schema.org/subjectOf', 'rel': 'canonical' }],
        },
    },
};
export const FileAttachmentSchema = Schema;
