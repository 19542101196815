import { IDarkMode } from '@cpa/base-core/types';
import React, { CSSProperties, useMemo } from 'react';

import { parseArea } from './helpers/parseArea';
import styles from './ShimmerGrid.module.scss';

export interface IShimmerGridProps extends IDarkMode {
  gridArea: string;
  gridGap?: number | string;
  height: number | string;
  style?: CSSProperties;
  shimmerStyle?: CSSProperties;
}

const ShimmerGrid: React.FC<IShimmerGridProps> = ({ gridArea, gridGap, height, style, shimmerStyle }) => {
  const elements = useMemo(() => {
    return parseArea(gridArea);
  }, []);

  return (
    <div data-testid="ShimmerGrid" style={style}>
      <div style={{ display: 'grid', gridTemplateAreas: gridArea, height, gridGap, ...shimmerStyle }}>
        {elements.map((el) => (
          <div data-testid="ShimmerGrid__block" key={el} style={{ gridArea: el }} className={styles.block} />
        ))}
      </div>
    </div>
  );
};

export default ShimmerGrid;
