export class BaseError extends Error {
    constructor(msg) {
        super(msg);
        this.name = this.constructor.name;
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        }
        else {
            this.__proto__ = actualProto;
        }
        Error.captureStackTrace(this, this.constructor);
    }
}
