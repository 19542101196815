import { ICardProps } from '@cpa/base-core/types';
import React, { useMemo } from 'react';
import { FormatSource } from '@cpa/base-core/constants';

import ReadonlyContent from '../../../components/ReadonlyContent/ReadonlyContent';
import SelectionButton from '../../../components/SelectionButton/SelectionButton';

import styles from './DefaultCard.module.scss';

const DefaultCard: React.FC<ICardProps> = ({ data, item, onClick, onItemSelect, isSelected, hideSelectionMarker, onRightClick }) => {
  // Deps only schema and page do not trigger while changing isLoading
  const readonlyContentData = useMemo(() => ({ ...data, items: [] }), [data]);

  const readonlyContentOptions = useMemo(
    () => ({
      useHovercardForHtml: true,
      source: FormatSource.Card,
      expandFunctionalityOptions: {
        expandedLevel: 1,
      },
    }),
    []
  );

  return (
    <div className={styles.defaultCard} onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
      {!hideSelectionMarker && <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} />}
      <div className={!hideSelectionMarker ? styles.body : styles.fullBody}>
        <ReadonlyContent item={item} data={readonlyContentData} options={readonlyContentOptions} />
      </div>
    </div>
  );
};

export default DefaultCard;
