import { ICardProps } from '@cpa/base-core/types';
import React, { useCallback } from 'react';

import SelectionButton from '../../../components/SelectionButton/SelectionButton';

import styles from './ArticlesCard.module.scss';

const ArticlesCard: React.FC<ICardProps> = ({ item, hideSelectionMarker, isSelected, onItemSelect }) => {
  const onRedirectClick = useCallback(() => {
    window.open(item.url as string, '_blank');
  }, [item]);

  return (
    <div
      key={String(item?.identifier)}
      className={styles.item}
      style={item.image && typeof item.image === 'string' ? { backgroundImage: `url(${CSS.escape(item.image)})` } : undefined}
      onClick={onRedirectClick}
    >
      {!hideSelectionMarker && <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} />}
      <div className={styles.inner}>
        <div className={styles.title}>{item.name}</div>
        <div className={styles.text}>{item.description}</div>
      </div>
    </div>
  );
};

export default ArticlesCard;
