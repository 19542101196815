const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/JobPosting',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'job-posting', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
        {
            'rel': 'related',
            'href': "job-application?$filter=jobPosting%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Job Posting',
    'properties': {
        'jobLocations': {
            'title': 'Job Locations',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/JobLocation',
                'type': 'object',
                'links': [
                    {
                        'rel': 'collection',
                        'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FJobLocation',
                        'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
                    },
                ],
                'cp_handledByApiGateway': true,
                'cp_disableTrash': true,
                'cp_disableLocalization': true,
                'cp_disableVersions': true,
                'cp_disableClone': true,
                'cp_disableLazyLoading': true,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Job Location',
                'properties': {
                    'address': {
                        'title': 'Address',
                        'description': 'The mailing address.',
                        '$id': 'http://schema.org/PostalAddress',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPostalAddress' },
                            { 'href': 'http://schema.org/address', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': {
                            'addressCountry': {
                                'title': 'Address Country',
                                'description': 'The country. For example, USA. You can also provide the two-letter [ISO 3166-1 alpha-2 country code](http://en.wikipedia.org/wiki/ISO_3166-1).',
                                '$id': 'http://platform.cosmoconsult.com/ontology/Country',
                                'type': 'object',
                                'links': [
                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCountry' },
                                    { 'href': 'http://schema.org/addressCountry', 'rel': 'canonical' },
                                ],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'properties': {
                                    'identifier': {
                                        'title': 'Identifier',
                                        'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                                        'type': 'string',
                                        'cp_ui': { 'sortOrderForm': 10 },
                                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                    },
                                    'name': {
                                        'title': 'Name (Title or Designation)',
                                        'description': 'The name of the thing or subject.',
                                        'type': 'string',
                                        'cp_ui': { 'sortOrderForm': 20 },
                                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                                    },
                                },
                                'cp_ui': { 'sortOrderForm': 30 },
                            },
                            'addressLocality': {
                                'title': 'Address Locality',
                                'description': 'The locality in which the street address is, and which is in the region. For example, Mountain View.',
                                'type': 'string',
                                'cp_ui': { 'sortOrderForm': 10 },
                                'links': [{ 'href': 'http://schema.org/addressLocality', 'rel': 'canonical' }],
                            },
                            'addressRegion': {
                                'title': 'Address Region',
                                'description': 'The region in which the locality is, and which is in the country. For example, California or another appropriate first-level [Administrative division](https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country) ',
                                'type': 'string',
                                'cp_ui': { 'sortOrderForm': 20 },
                                'links': [{ 'href': 'http://schema.org/addressRegion', 'rel': 'canonical' }],
                            },
                        },
                        'cp_ui': { 'sortOrderForm': 30 },
                    },
                    'identifier': {
                        'title': 'Identifier',
                        'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 10 },
                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                    },
                    'name': {
                        'title': 'Name (Title or Designation)',
                        'description': 'The name of the thing or subject.',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 20 },
                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 80 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/jobLocations', 'rel': 'canonical' }],
        },
        'fulltime': {
            'title': 'Fulltime',
            'type': 'boolean',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/fulltime', 'rel': 'canonical' }],
        },
        'homeoffice': {
            'title': 'Homeoffice',
            'type': 'boolean',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 93 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/homeoffice', 'rel': 'canonical' }],
        },
        'parttimeFlexible': {
            'title': 'Parttime Flexible',
            'type': 'boolean',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/parttimeFlexible', 'rel': 'canonical' }],
        },
        'parttimeMorning': {
            'title': 'Parttime Morning',
            'type': 'boolean',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 92 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/parttimeMorning', 'rel': 'canonical' }],
        },
        'header': {
            'title': 'Header',
            'type': 'string',
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 83 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/header', 'rel': 'canonical' }],
        },
        'definition': {
            'title': 'Definition',
            'type': 'string',
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 85 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/definition', 'rel': 'canonical' }],
        },
        'recruitingChannel': {
            'title': 'Recruiting Channel',
            '$id': 'http://platform.cosmoconsult.com/ontology/RecruitingChannel',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'recruiting-channels', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/recruitingChannel', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Recruiting Channel' } },
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 89 },
        },
        'alternateIdentifier': {
            'title': 'Job Reference',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 75 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/alternateIdentifier', 'rel': 'canonical' }],
        },
        'publishingType': {
            'title': 'Publishing Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/JobPostingPublishingType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FJobPostingPublishingType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/publishingType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Internal',
                'http://platform.cosmoconsult.com/ontology/External',
                'http://platform.cosmoconsult.com/ontology/ExternalAndInternal',
            ],
            'enumNames': ['Internal', 'External', 'External/ Internal'],
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 90 },
        },
        'workplaceType': {
            'title': 'Workplace Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/WorkplaceType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FWorkplaceType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/workplaceType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Hybrid',
                'http://platform.cosmoconsult.com/ontology/Onsite',
                'http://platform.cosmoconsult.com/ontology/Remote',
            ],
            'enumNames': ['Hybrid', 'Onsite', 'Remote'],
            'cp_ui': { 'sortOrderForm': 97 },
        },
        'nameSuffix': {
            'title': 'Name Suffix',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 78 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/nameSuffix', 'rel': 'canonical' }],
        },
        'applicationContact': {
            'title': 'Application Contact',
            'description': 'A contact point&#x2014;for example, a Customer Complaints department.',
            '$id': 'http://schema.org/ContactPoint',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FContactPoint' },
                { 'href': 'http://schema.org/applicationContact', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'email': {
                    'title': 'Email',
                    'description': 'Email address.',
                    'type': 'string',
                    'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 140 },
                    'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 130 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 95 },
        },
        'baseSalary': {
            'title': 'Base Salary',
            'description': 'A monetary value or range. This type can be used to describe an amount of money such as $50 USD, or a range as in describing a bank account being suitable for a balance between £1,000 and £1,000,000 GBP, or the value of a salary, etc. It is recommended to use [[PriceSpecification]] Types to describe the price of an Offer, Invoice, etc.',
            '$id': 'http://schema.org/MonetaryAmount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FMonetaryAmount' },
                { 'href': 'http://schema.org/baseSalary', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'maxValue': {
                    'title': 'Max Value',
                    'description': 'The upper value of some characteristic or property.',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/maxValue', 'rel': 'canonical' }],
                },
                'minValue': {
                    'title': 'Min Value',
                    'description': 'The lower value of some characteristic or property.',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/minValue', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 84 },
        },
        'jobBenefits': {
            'title': 'Job Benefits',
            'description': 'Description of benefits associated with the job.',
            'type': 'string',
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 88 },
            'links': [{ 'href': 'http://schema.org/jobBenefits', 'rel': 'canonical' }],
        },
        'jobStartDate': {
            'title': 'Job Start Date',
            'description': 'The date on which a successful applicant for this job would be expected to start work. Choose a specific date in the future or use the jobImmediateStart property to indicate the position is to be filled as soon as possible.',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 81 },
            'links': [{ 'href': 'http://schema.org/jobStartDate', 'rel': 'canonical' }],
        },
        'occupationalCategory': {
            'title': 'Category',
            'description': 'A category describing the job, preferably using a term from a taxonomy such as [BLS O*NET-SOC](http://www.onetcenter.org/taxonomy.html), [ISCO-08](https://www.ilo.org/public/english/bureau/stat/isco/isco08/) or similar, with the property repeated for each applicable value. Ideally the taxonomy should be identified, and both the textual label and formal code for the category should be provided.\\n\nNote: for historical reasons, any textual label and formal code provided as a literal may be assumed to be from O*NET-SOC.',
            'type': 'string',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 96 },
            'links': [{ 'href': 'http://schema.org/occupationalCategory', 'rel': 'canonical' }],
        },
        'qualifications': {
            'title': 'Qualifications',
            'description': 'Specific qualifications required for this role or Occupation.',
            'type': 'string',
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 86 },
            'links': [{ 'href': 'http://schema.org/qualifications', 'rel': 'canonical' }],
        },
        'skills': {
            'title': 'Skills',
            'description': 'A statement of knowledge, skill, ability, task or any other assertion expressing a competency that is desired or required to fulfill this role or to work in this occupation.',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Skill',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/cp:Skill%3FallData=true', 'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546' }],
                'cp_handledByApiGateway': true,
                'cp_disableTrash': true,
                'cp_disableLocalization': true,
                'cp_disableVersions': true,
                'cp_disableClone': true,
                'cp_disableLazyLoading': true,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Skill',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Skill' } },
            },
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 99 },
            'links': [{ 'href': 'http://schema.org/skills', 'rel': 'canonical' }],
        },
        'title': {
            'title': 'Title',
            'description': 'The title of the job.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 76 },
            'links': [{ 'href': 'http://schema.org/title', 'rel': 'canonical' }],
        },
        'validThrough': {
            'title': 'Valid Through',
            'description': 'The date after when the item is not valid. For example the end of an offer, salary period, or a period of opening hours.',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 82 },
            'links': [{ 'href': 'http://schema.org/validThrough', 'rel': 'canonical' }],
        },
        'workHours': {
            'title': 'Work Hours',
            'description': 'The typical working hours for this job (e.g. 1st shift, night shift, 8am-5pm).',
            'type': 'string',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://schema.org/workHours', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 74 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:htmlView',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 79 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 77 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 98 },
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
    },
    'cp_hasPermissions': true,
};
export const JobPostingSchema = Schema;
