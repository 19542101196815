import { Environment } from '@cp/base-types';
import { createDefaultConnections } from '..';
import { LogManager } from '../manager';
let logManager;
const globalConsole = Environment.console;
export const initLog = (options) => {
    var _a;
    try {
        if (!logManager) {
            logManager = new LogManager((_a = options === null || options === void 0 ? void 0 : options.connections) !== null && _a !== void 0 ? _a : createDefaultConnections(), globalConsole, options === null || options === void 0 ? void 0 : options.name);
            return globalConsole;
        }
        else {
            return logManager.bindConsole({ connections: options === null || options === void 0 ? void 0 : options.connections, name: options === null || options === void 0 ? void 0 : options.name });
        }
    }
    catch (err) {
        throw err;
    }
};
export const bindGlobalConsole = (options) => {
    if (!logManager) {
        initLog(options);
    }
    else {
        logManager.bindConsole({ connections: options === null || options === void 0 ? void 0 : options.connections, name: options === null || options === void 0 ? void 0 : options.name }, globalConsole);
    }
};
export const getLogger = (name) => initLog({ name });
export const ScopedLog = (options) => {
    return (target) => {
        initLog(options);
        return target;
    };
};
