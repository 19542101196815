import Konva from 'konva';
import { DefaultButton, Slider } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageEditorBrightnessContrast } from './helpers/ImageEditorBrightnessContrast';
import styles from './ImageEditorBrightnessContrastMenu.module.scss';

interface ImageEditorBrightnessContrastMenuProps {
  stage: Konva.Stage;

  onChange: (src: string) => void;
  onClose: () => void;
}

const ImageEditorBrightnessContrastMenu: React.FC<ImageEditorBrightnessContrastMenuProps> = ({ stage, onChange, onClose }) => {
  const [t] = useTranslation();

  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);

  useEffect(() => {
    ImageEditorBrightnessContrast.setBrightnessContrast(stage, brightness, contrast);
  }, [stage, brightness, contrast]);

  const onChangeBrightness = useCallback(
    (value: number) => {
      setBrightness(value);
    },
    [setBrightness]
  );

  const onChangeContrast = useCallback(
    (value: number) => {
      setContrast(value);
    },
    [setContrast]
  );

  const applyBrightnessContrast = useCallback(() => {
    const imageUrl = ImageEditorBrightnessContrast.saveBrightnessContrast(stage, brightness, contrast);

    onChange(imageUrl);
    onClose();
  }, [onChange, stage, brightness, contrast]);

  return (
    <div className={styles.brightnessContastOptions}>
      <div className={styles.sliders}>
        <Slider
          label={t('imageEditor.brightness')}
          min={0}
          max={200}
          step={1}
          showValue
          snapToStep
          value={brightness}
          onChange={onChangeBrightness}
        />
        <Slider label={t('imageEditor.contrast')} min={0} max={200} step={1} showValue snapToStep value={contrast} onChange={onChangeContrast} />
      </div>

      <DefaultButton className={styles.brightnessContastOptionsButton} text={t('imageEditor.goBack')} onClick={applyBrightnessContrast} />
    </div>
  );
};

export default ImageEditorBrightnessContrastMenu;
