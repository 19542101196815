import { IGlobalState } from '@cpa/base-core/store';
import { CommandBar, ICommandBarItemProps, ITooltipHostProps, ThemeContext } from '@fluentui/react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isTouchDevice } from '@cpa/base-core/helpers';

import dark from './styles/dark.module.scss';
import light from './styles/light.module.scss';

export enum SettingsItemType {
  EDIT = 'edit',
  SHARE = 'share',
  DELETE = 'delete',
  MORE = 'more',
  SETTINGS = 'settings',
  REFRESH = 'refresh',
  FILTER = 'filter',
  LINK = 'link',
  HELP = 'help',
  CLOSE = 'close',
  POWER_USER = 'powerUser',
  MODE = 'mode',
}

export type ISettingsItem = { type: SettingsItemType; url?: string } & Partial<ICommandBarItemProps>;

interface ISettingsProps {
  items: ISettingsItem[];
  additionalItems?: ICommandBarItemProps[];
  showButtons: boolean;
}

const defaultStyle = {
  backgroundColor: 'transparent',
};
const defaultStyles = {
  root: {
    backgroundColor: 'transparent',
  },
};

const getTooltipProps = (content: string): ITooltipHostProps => {
  const touchDevice: boolean = isTouchDevice();
  if (touchDevice) return {};
  return {
    calloutProps: {
      beakWidth: 10,
    },
    content: content,
  };
};

const Settings: React.FC<ISettingsProps> = ({ items, additionalItems, showButtons }) => {
  const theme = useContext(ThemeContext);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const styles = useMemo(() => {
    return darkMode ? dark : light;
  }, [darkMode]);

  const [t] = useTranslation();

  const settingsItems = useMemo(() => {
    return items
      .map((item) => {
        const { type, ...rest } = item;
        switch (type) {
          case SettingsItemType.EDIT: {
            return {
              key: 'edit',
              iconOnly: true,
              iconProps: { iconName: 'Edit' },
              tooltipHostProps: getTooltipProps(t('settingsButtons.edit')),
              href: item.url,
              ...rest,
            };
          }
          case SettingsItemType.DELETE: {
            return {
              key: 'delete',
              iconOnly: true,
              iconProps: { iconName: 'Delete' },
              tooltipHostProps: getTooltipProps(t('settingsButtons.delete')),
              ...rest,
            };
          }
          case SettingsItemType.SHARE: {
            return {
              key: 'share',
              iconOnly: true,
              iconProps: { iconName: 'Share', style: { color: theme?.palette.themePrimary } },
              tooltipHostProps: getTooltipProps(t('common.share')),
              ...rest,
            };
          }
          case SettingsItemType.CLOSE: {
            return {
              key: 'close',
              iconOnly: true,
              iconProps: { iconName: 'Cancel', style: { color: theme?.palette.themePrimary } },
              tooltipHostProps: getTooltipProps(t('settingsButtons.close')),
              href: item.url,
              ...rest,
            };
          }
          case SettingsItemType.MORE: {
            return {
              key: 'more',
              id: 'widget-showMore',
              iconProps: { iconName: 'More' },
              iconOnly: true,
              tooltipHostProps: getTooltipProps(t('settingsButtons.showMore')),
              href: item.url,
              ...rest,
            };
          }
          case SettingsItemType.SETTINGS: {
            return {
              key: 'Settings',
              iconProps: { iconName: 'Settings' },
              iconOnly: true,
              ariaLabel: 'Settings action',
              id: 'widget-settings',
              tooltipHostProps: getTooltipProps(t('settingsButtons.settings')),
              ...rest,
            };
          }
          case SettingsItemType.REFRESH: {
            return {
              key: 'Refresh',
              iconProps: { iconName: 'Refresh' },
              iconOnly: true,
              ariaLabel: 'Refresh action',
              tooltipHostProps: getTooltipProps(t('settingsButtons.refresh')),
              ...rest,
            };
          }
          case SettingsItemType.FILTER: {
            return {
              key: 'Filter',
              iconProps: { iconName: 'Filter' },
              iconOnly: true,
              ariaLabel: 'Filter',
              tooltipHostProps: getTooltipProps(t('settingsButtons.filter')),
              ...rest,
            };
          }
          case SettingsItemType.LINK: {
            return {
              key: 'Link',
              iconProps: { iconName: 'PageLink' },
              iconOnly: true,
              ariaLabel: 'Link',
              tooltipHostProps: getTooltipProps(t('settingsButtons.link')),
              ...rest,
            };
          }
          case SettingsItemType.HELP: {
            return {
              key: 'Help',
              iconProps: { iconName: 'Help' },
              iconOnly: true,
              ariaLabel: 'Help',
              tooltipHostProps: getTooltipProps(t('common.help')),
              href: item.url,
              ...rest,
            };
          }
          case SettingsItemType.POWER_USER: {
            return {
              key: 'PowerUser',
              iconProps: { iconName: 'UserGauge' },
              iconOnly: true,
              ariaLabel: 'PowerUser',
              tooltipHostProps: getTooltipProps(t('common.tempPowerUserMode')),
              ...rest,
            };
          }
          case SettingsItemType.MODE: {
            return {
              key: 'mode',
              iconOnly: true,
              ariaLabel: 'mode',
              tooltipHostProps: getTooltipProps(t('common.viewMode')),
              ...rest,
            };
          }
        }
      })
      .map((descriptor) => {
        descriptor.className = styles.item;
        return descriptor;
      })
      .reverse(); // for correct ordering with `dir={'rtl'}`
  }, [items, styles.item, t, theme?.palette.themePrimary]);

  const additionalSettingsItems = useMemo(() => {
    if (!additionalItems) return null;
    const items = additionalItems.map((descriptor) => {
      descriptor.className = styles.additionalItem;
      return descriptor;
    });
    return {
      key: 'additionalSettingsItems',
      iconOnly: true,
      ariaLabel: 'Additional Settings',
      tooltipHostProps: getTooltipProps(t('common.showMore')),
      subMenuProps: { items },
      className: styles.additionalItemsWrapper,
    } as ICommandBarItemProps;
  }, [additionalItems, styles.additionalItem, styles.additionalItemsWrapper, t]);

  const settingsItemsToRender = useMemo(() => {
    if (!settingsItems.length) return [];
    return additionalSettingsItems ? [...settingsItems, additionalSettingsItems] : settingsItems;
  }, [additionalSettingsItems, settingsItems]);

  return (
    <CommandBar
      className={classNames(styles.items, showButtons ? styles.showButtons : null)}
      items={settingsItemsToRender}
      style={defaultStyle}
      styles={defaultStyles}
      dir={'rtl'}
    />
  );
};

export default Settings;
