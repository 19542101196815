export const colorMap = [
  'B39C4D',
  'Gold',

  '1B212E',
  'Anthracite',

  '6A0040',
  'Dark Red',

  'FF671E',
  'Light Red',

  '002D6E',
  'Dark Blue',

  '3296DF',
  'Light Blue',

  '00423C',
  'Dark Green',

  '78BE20',
  'Light Green',

  'AA4628',
  'Dark Copper',

  'EBE6DC',
  'Light Copper',

  '464F57',
  'Dark Grey',

  'CED0D0',
  'Light Gray',
];
