const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Annotation',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAnnotation' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Annotation',
    'properties': {
        'value': { 'title': 'Value', 'type': 'string', 'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/value', 'rel': 'canonical' }] },
        'key': { 'title': 'Key', 'type': 'string', 'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }] },
    },
};
export const AnnotationSchema = Schema;
