import { IComponentWithOptions, ISingleItemTemplateProps, ISingleItemOptions } from '@cpa/base-core/types';
import React from 'react';
import classNames from 'classnames';
import { Schemas } from '@cp/base-types';
import { generateReducedSchema } from '@cpa/base-core/helpers';

import { Resizable } from '../../../components/Resizable/Resizable';

import styles from './SolutionConfigurationCanvasSingleItemTemplate.module.scss';
import LeftSide, { SolutionReducedFormFields } from './components/LeftSide/LeftSide';
import RightSide from './components/RightSide/RightSide';

const resizeDirections = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const resizableDefaultSize = {
  width: '50%',
  height: '100%',
};

const SolutionConfigurationCanvasSingleItemTemplate: React.FC<ISingleItemTemplateProps & { item: Schemas.Solution }> &
  IComponentWithOptions<ISingleItemOptions> = (props) => {
  return (
    <section className={styles.wrapper}>
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <Resizable
            className={classNames(styles.resizableBlock, styles.main)}
            defaultSize={resizableDefaultSize}
            maxWidth="100%"
            minWidth="1"
            enable={resizeDirections}
          >
            <div className={styles.content}>
              <LeftSide {...props} />
            </div>
          </Resizable>
          <div className={classNames(styles.resizableBlock, styles.secondary)}>
            <div className={styles.content}>
              <RightSide {...props} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionConfigurationCanvasSingleItemTemplate.options = {
  schemaModifier: (schema) => generateReducedSchema(schema, SolutionReducedFormFields),
};

export default SolutionConfigurationCanvasSingleItemTemplate;
