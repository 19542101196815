import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EndpointContext, PathContext } from '@cpa/base-core/constants';
import { IJSONSchema, IRelatedLink } from '@cp/base-types';
import { cloneValueAndCleanUpInternalProperties, getDataUrlForLookup, getMatchingEnum, getSafeString } from '@cpa/base-core/helpers';
import { isRelationSchema } from '@cp/base-utils';
import { getEntitiesFromEndpoint } from '@cpa/base-core/api';
import { Registry } from '@rjsf/core';
import { Shimmer } from '@fluentui/react';
import classNames from 'classnames';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';

import { compareSchemaSortOrder } from '../../helpers/data';
import TitleField, { TitleFieldType } from '../TitleField/TitleField';

import styles from './FieldTitleWithPreview.module.scss';

interface IFieldTitleWithPreviewProps {
  baseLabel: string;
  schema: IJSONSchema;
  formData: any;
  registry: Registry & { rootSchema?: IJSONSchema };
  fallbackPreview: any;
  isArrayItem: boolean;
  showExpandButton: boolean;
  onClick: () => void;
  required: boolean;
  fieldId: string;
  disableTitlePreview?: boolean;
}

const FieldTitleWithPreview: React.FC<IFieldTitleWithPreviewProps> = ({
  baseLabel,
  schema,
  formData,
  registry,
  fallbackPreview,
  isArrayItem,
  showExpandButton,
  onClick,
  required,
  fieldId,
  disableTitlePreview,
}) => {
  const endpointIdentifierFromContext = useContext(EndpointContext);
  const pathContextValue = useContext(PathContext);
  const [previewText, setPreviewText] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const cancelToken = useRef<CancelTokenSource | null>(null);

  const prevFetchedIdentifier = useRef<string | null>(null);

  const getPreviewText = useCallback(async (): Promise<void> => {
    const orderedPropertiesEntries: [string, IJSONSchema][] = schema.properties
      ? Object.entries(schema.properties as IJSONSchema).sort((a, b) => {
          return compareSchemaSortOrder(a[1], b[1]);
        })
      : [];
    const formDataEntries: string[][] = Object.entries(cloneValueAndCleanUpInternalProperties(formData, registry.rootSchema) || {});
    const orderedFormDataEntries = orderedPropertiesEntries
      .map((entry) => entry[0])
      .map((key) => {
        const matchedFormDataEntry = formDataEntries.find((entry) => entry[0] === key);
        if (!matchedFormDataEntry) return null;
        return matchedFormDataEntry;
      })
      .filter(Boolean) as [string, unknown][];

    if (!orderedFormDataEntries.length && typeof formData === 'string') {
      const matchedEnum = getMatchingEnum(schema as IJSONSchema, '', formData as string | undefined);
      if (matchedEnum) {
        setPreviewText(matchedEnum);
        return;
      }
    }

    for (const [property, value] of orderedFormDataEntries) {
      const matchingPropertyEntry = orderedPropertiesEntries.find((propertyEntry) => propertyEntry[0] === property);
      if (!matchingPropertyEntry) continue;

      const [, matchingPropertySchema] = matchingPropertyEntry;
      if (matchingPropertySchema.cp_ui?.hiddenInForm === true) continue;

      if (value && typeof value === 'string') {
        const matchedEnum = getMatchingEnum(schema as IJSONSchema, property, value as string | undefined);
        if (matchedEnum) {
          setPreviewText(matchedEnum);
          return;
        } else {
          setPreviewText(value);
          return;
        }
      }
      if (isRelationSchema(matchingPropertySchema) && formData[property]?.identifier) {
        if (formData[property]?.name) {
          setPreviewText(formData[property].name);
          return;
        }
        if (prevFetchedIdentifier.current === formData[property].identifier) {
          return;
        }
        const links: IRelatedLink[] = Array.isArray(matchingPropertySchema.links) ? matchingPropertySchema.links : [];
        const lookupLink = links.find((link) => link.rel === 'collection');
        if (lookupLink) {
          const endpointIdentifier = lookupLink.endpoint || endpointIdentifierFromContext;
          if (!endpointIdentifier) continue;
          setLoading(true);
          const dataUrl = await getDataUrlForLookup(lookupLink, schema, matchingPropertySchema, pathContextValue, registry);
          cancelToken.current?.cancel();
          cancelToken.current = createCancelToken();
          const response = await Promise.all(
            getEntitiesFromEndpoint(
              endpointIdentifier,
              `${dataUrl?.url}?$top=1&$filter=${encodeURIComponent(`identifier eq '${formData[property].identifier}'`)}`,
              undefined,
              undefined,
              cancelToken.current
            )
          )
            .then((r) => r.flatMap((response) => response.entities))
            .finally(() => {
              setLoading(false);
            });
          const resolvedName = response[0]?.name;
          if (resolvedName) {
            prevFetchedIdentifier.current = formData[property].identifier;
            setPreviewText(response[0]?.name as string);
            return;
          }
        }

        const formDataProperty = formData[property];
        setPreviewText(formDataProperty?.name || formDataProperty?.identifier);
        return;
      }
    }
    setPreviewText(undefined);
  }, [endpointIdentifierFromContext, formData, pathContextValue, registry, schema]);

  useEffect(() => {
    if (!disableTitlePreview) {
      getPreviewText();
    }
  }, [getPreviewText, formData, disableTitlePreview]);

  const itemLabel: string | undefined = previewText || fallbackPreview;
  const strippedFormDataName = itemLabel ? getSafeString(itemLabel, true)?.trim() : undefined;
  const objectLabel: string | undefined =
    isArrayItem && showExpandButton ? `${strippedFormDataName ? `${strippedFormDataName} - ` : ''}${baseLabel}` : baseLabel;

  const showPreview = useMemo(() => {
    return isArrayItem && showExpandButton && strippedFormDataName;
  }, [isArrayItem, showExpandButton, strippedFormDataName]);

  const updatedLabel = useMemo(() => {
    if (!baseLabel) return undefined;
    return (
      <>
        {isArrayItem && showExpandButton ? (
          loading ? (
            <span className={styles.shimmerWrapper}>
              <Shimmer width={70} styles={{ root: { display: 'flex', marginRight: '5px' } }}></Shimmer>
              {' - '}
            </span>
          ) : strippedFormDataName ? (
            <span className={styles.previewText}>{strippedFormDataName}</span>
          ) : null
        ) : null}
        <span
          className={classNames(styles.baseText, {
            [styles.loading]: loading,
            [styles.baseOnly]: !isArrayItem || !showExpandButton,
            [styles.withPreview]: showPreview,
          })}
        >
          {`${showPreview ? ' - ' : ''}${baseLabel}`}
        </span>
      </>
    );
  }, [baseLabel, isArrayItem, showExpandButton, loading, strippedFormDataName, showPreview]);

  return (
    <TitleField
      title={updatedLabel}
      required={required}
      type={fieldId === 'root' ? TitleFieldType.Root : showExpandButton ? TitleFieldType.Object : TitleFieldType.Primitive}
      onClick={onClick}
      registry={registry}
      isLoading={loading}
      tooltipText={objectLabel}
      customClassName={showPreview ? styles.wrapper : undefined}
    />
  );
};

export default FieldTitleWithPreview;
