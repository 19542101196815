import RefParser from '@apidevtools/json-schema-ref-parser';
import { IJSONSchema } from '@cp/base-types';
import axios from 'axios';
import urlJoin from 'url-join';

import { i18n } from '../app';

import { getAccessToken, IMatchedDataEndpointWithAxios } from './axios';
import { axiosDictionary } from './constants';
import { getEndpoint } from './endpoint';

const cachedSchemas = new Map<string, Promise<IJSONSchema>>();

export async function getSchema(url: string): Promise<IJSONSchema> {
  const schemaKey = `${i18n.language}-${url}`;

  if (cachedSchemas.has(schemaKey)) {
    const parallelDerefResult = await cachedSchemas.get(schemaKey);
    if (!parallelDerefResult) {
      throw new Error(`Failed to dereference ${url}`);
    }
    return parallelDerefResult as IJSONSchema;
  }

  let metaServiceEndpoint: undefined | IMatchedDataEndpointWithAxios = undefined;
  try {
    metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
  } catch (e) {}

  const parsedUrl = new URL(url);
  if (parsedUrl.protocol === 'http:' && window?.location?.protocol === 'https:') {
    parsedUrl.protocol = 'https:';
  }

  const cachedPromise = (async () => {
    let token: string;
    try {
      token = await getAccessToken();
    } catch (e) {}

    const resolverOptions: Partial<RefParser.ResolverOptions> & RefParser.HTTPResolverOptions = {
      read: async (resolveRequest): Promise<string> => {
        if (!resolveRequest.url) {
          return '';
        }

        const isMetaServiceRequest = !!metaServiceEndpoint && resolveRequest.url.startsWith(metaServiceEndpoint.url);
        const withCredentials: boolean = isMetaServiceRequest && !!metaServiceEndpoint!.withCredentials;

        const response = await axios.get(resolveRequest.url, {
          headers: {
            'Accept-Language': i18n.language,
            'Accept': 'application/json',
            ...(token && isMetaServiceRequest ? { 'Authorization': `Bearer ${token}` } : {}),
          },
          transformResponse: (response) => response,
          withCredentials: withCredentials,
        });
        return response.data;
      },
    };

    const dereferencedSchema = (await RefParser.dereference(parsedUrl.href, {
      resolve: {
        http: resolverOptions,
      },
    })) as IJSONSchema;

    const subjectUri = new URL(parsedUrl.searchParams.get('subjectUri') ?? parsedUrl.href);
    if (subjectUri.searchParams.has('cp_view') && metaServiceEndpoint) {
      subjectUri.searchParams.delete('cp_view');

      dereferencedSchema.cp_validationSchema = await getSchema(
        urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(subjectUri.href)}`)
      );
    }

    return dereferencedSchema;
  })();

  cachedSchemas.set(schemaKey, cachedPromise);

  const derefResult = await cachedPromise;
  if (!derefResult) {
    throw new Error(`Failed to dereference ${parsedUrl.href}`);
  }

  return derefResult;
}
