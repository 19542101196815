import { IconButton, Modal } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AuthErrorModal.module.scss';

interface IAuthErrorModalProps {
  text?: string | null;
  code?: string | null;
  onModalClose?: () => void;
}

const modalStyles = {
  layer: {
    zIndex: '100000010 !important',
  },
};
const closeIconProps = { iconName: 'Cancel' };

const AuthErrorModal: React.FC<IAuthErrorModalProps> = ({ text, code, onModalClose }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [errorModalOpened, setErrorModalOpened] = useState<boolean>(!!text);

  const handleErrorModalClose = useCallback(() => {
    setErrorModalOpened(false);
    if (onModalClose) {
      onModalClose();
    }
  }, [setErrorModalOpened, onModalClose]);

  if (!text) {
    return null;
  }

  return (
    <Modal isOpen={errorModalOpened} styles={modalStyles}>
      <div className={styles.modalBody}>
        <IconButton className={styles.close} iconProps={closeIconProps} onClick={handleErrorModalClose} />
        <span className={styles.title}>{i18n.language ? t('errors.auth.journeyFailed') : code}</span>
        <span className={styles.text}>{text}</span>
        {code && <span className={styles.code}>{code}</span>}
      </div>
    </Modal>
  );
};

export default AuthErrorModal;
