import { IGlobalState } from '@cpa/base-core/store';
import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SearchBox.module.scss';
import SearchModulesSelectorCallout from './SearchModulesSelectorCallout/SearchModulesSelectorCallout';

export interface ISearchModule {
  key: string;
  label: string;
}

export interface ISearchBoxProps {}

const SearchBox: React.FC<ISearchBoxProps> = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const pages = useSelector((state: IGlobalState) => state.app.pages);

  const searchModules: ISearchModule[] = useMemo(() => {
    return pages
      .filter((page) => page.searchable && page.dataUrl)
      .map((page) => ({
        key: page.identifier!,
        label: page.name,
      }));
  }, [pages, t]);

  const allSearchModulesKeys = useMemo(() => searchModules.map((module) => module.key), [searchModules]);
  const [selectedModulesKeys, setSelectedModulesKeys] = useState<string[]>(allSearchModulesKeys);

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const [isOpened, setIsOpened] = useState(false);
  const [isInteracted, setIsInteracted] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const showCalloutButtonRef = useRef<HTMLAnchorElement>(null);

  const handleMouseEnter = useCallback(() => {
    if (!isOpened) {
      setIsOpened(true);
      setIsInteracted(false);
      inputRef.current?.focus();
    }
  }, [isOpened, setIsOpened, inputRef, setIsInteracted]);

  const handleMouseLeave = useCallback(() => {
    if (!isInteracted) {
      setIsOpened(false);
    }
  }, [isInteracted, setIsOpened]);

  const handleInputClick = useCallback(() => {
    setIsInteracted(true);
  }, [setIsInteracted]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
      setIsInteracted(true);
    },
    [setSearchText, setIsInteracted]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const isClickedOutside = containerRef.current && !containerRef.current.contains(event.target as unknown as HTMLElement);

      if (isOpened && !searchText?.length && isClickedOutside) {
        setIsOpened(false);
        setIsInteracted(false);
      }

      if (isClickedOutside) {
        setIsCalloutVisible(false);
      }
    },
    [isOpened, searchText, setIsOpened, setIsInteracted]
  );

  useEffect(() => {
    setIsInteracted(true);
  }, [searchText]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, handleClickOutside]);

  const performSearch = useCallback(
    (query: string, selectedModules: string[]) => {
      const searchPage = pages.find((page) => page.customTemplate?.identifier === 'SearchResults');

      if (!searchPage) {
        console.warn('SearchResults page not found, available keys', pages.map((page) => page?.identifier).join(','));
        return;
      }

      dispatch(
        push({
          pathname: searchPage.path,
          search: `?query=${encodeURIComponent(query)}&modules=${encodeURIComponent(selectedModules.join(','))}`,
        })
      );
    },
    [dispatch, pages]
  );

  const handleSearchIconClick = useCallback(() => {
    setIsOpened(true);
    setIsInteracted(true);
  }, [setIsOpened, setIsInteracted]);

  const handleShowMoreIconClick = useCallback(() => {
    if (!isCalloutVisible) {
      inputRef.current?.focus();
    }
    setIsCalloutVisible(!isCalloutVisible);
  }, [inputRef, isCalloutVisible, setIsCalloutVisible]);

  const handleClearIconClick = useCallback(() => {
    setSearchText('');
    setSelectedModulesKeys(allSearchModulesKeys);
    inputRef.current?.focus();
  }, [setSearchText, setSelectedModulesKeys, allSearchModulesKeys]);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        performSearch(searchText, selectedModulesKeys);
      }
    },
    [performSearch, searchText, selectedModulesKeys]
  );

  return (
    <div className={styles.searchBoxWrapper}>
      <div
        ref={containerRef}
        className={classNames({
          [styles.searchBox]: true,
          [styles.searchBoxDark]: darkMode,
          [styles.searchBoxOpened]: isOpened,
        })}
        onClick={handleInputClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <input
          className={styles.searchInput}
          type="text"
          ref={inputRef}
          placeholder={t('header.controls.search.query')}
          value={searchText}
          onKeyDown={handleInputKeyDown}
          onChange={handleInputChange}
        />
        <Icon className={styles.searchInputIcon} iconName="Search" />
        <a
          onClick={handleSearchIconClick}
          className={classNames({
            [styles.searchButton]: true,
            [styles.searchButtonHidden]: isOpened,
          })}
        >
          <Icon iconName="Search" />
        </a>
        {!!searchText.length && (
          <a
            className={classNames({
              [styles.clearButton]: true,
            })}
            onClick={handleClearIconClick}
          >
            <Icon iconName="Clear" />
          </a>
        )}
        <a
          ref={showCalloutButtonRef}
          className={classNames({
            [styles.moreButton]: true,
            [styles.moreButtonReversed]: isCalloutVisible,
            [styles.moreButtonHidden]: !isOpened,
          })}
          onClick={handleShowMoreIconClick}
        >
          <Icon iconName="ChevronDown" />
        </a>
        {isCalloutVisible && (
          <SearchModulesSelectorCallout
            target={showCalloutButtonRef.current}
            searchModules={searchModules}
            selectedModulesKeys={selectedModulesKeys}
            setSelectedModulesKeys={setSelectedModulesKeys}
          />
        )}
      </div>
    </div>
  );
};

export default SearchBox;
