import { createUuidV4 } from '@cp/base-types';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { generateFileUploadUrl, uploadFileToAzureStorage } from '@cpa/base-core/api';
import { FormContext, PathContext } from '@cpa/base-core/constants';
import { createCancelToken, isCancelError } from '@cpa/base-http';
import { DefaultButton } from '@fluentui/react';
import { useBoolean, useUnmount } from '@fluentui/react-hooks';
import { WidgetProps } from '@rjsf/core';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DescriptionField from '../DescriptionField/DescriptionField';
import TitleField, { TitleFieldType } from '../TitleField/TitleField';
import { useSchemaOverwritesStore } from '../../stores/SchemaOverwrites';

import FilesInfo, { IUploadTask } from './components/FilesInfo/FilesInfo';
import styles from './FileWidget.module.scss';

export interface IFileWidgetProps extends WidgetProps {
  multiple: boolean;
  value: string | string[];
  autofocus: boolean;
}

const FileWidget: React.FC<IFileWidgetProps> = ({
  id,
  readonly,
  disabled,
  autofocus = false,
  multiple,
  options,
  registry,
  value,
  onChange,
  required,
  label,
  uiSchema,
  schema: baseSchema,
}) => {
  const currentValue = useRef<typeof value>(value);
  useEffect(() => {
    currentValue.current = cloneDeepWithMetadata(value);
  }, [value, value?.length]);

  const values = useMemo(() => {
    return (Array.isArray(value) ? [...value] : [value]).filter(Boolean);

    // Value can have the same ref if it's an array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, value?.length]);

  const [t] = useTranslation();
  const [uploadTasks, setUploadTasks] = useState<Record<string, IUploadTask>>({});
  const pathContext = useContext(PathContext);
  const { startAction, finishAction } = useContext(FormContext);
  const schemaOverwrites = useSchemaOverwritesStore((state) => state.schemaOverwrites);

  const schema = useMemo(() => {
    const schemaOverwrite = schemaOverwrites?.[pathContext];
    if (!schemaOverwrite) {
      return baseSchema;
    }

    return _.merge({}, baseSchema, schemaOverwrite);
  }, [baseSchema, schemaOverwrites, pathContext]);

  const removeUploadTask = useCallback(
    (id: string) => {
      if (uploadTasks[id]) {
        uploadTasks[id].cancelToken.cancel();
      }

      setUploadTasks((prevTasks) => {
        const newTasks = { ...prevTasks };
        delete newTasks[id];
        return newTasks;
      });
    },
    [uploadTasks]
  );

  const handleUploadDone = useCallback(
    (downloadUrl: string) => {
      if (multiple) {
        if (Array.isArray(currentValue.current)) {
          currentValue.current = [...currentValue.current, downloadUrl];
          onChange([...currentValue.current]);
        } else {
          onChange([downloadUrl]);
        }
      } else {
        onChange(downloadUrl);
      }
    },
    [multiple, onChange]
  );

  const startFileUpload = useCallback(
    async (file: File) => {
      // Unique id per upload operation
      const uploadId = createUuidV4();
      const cancelToken = createCancelToken();

      // Block form submit
      startAction();

      // Init upload
      setUploadTasks((prevTasks) => ({
        ...prevTasks,
        [uploadId]: {
          cancelToken: cancelToken,
          id: uploadId,
          file: file,
        },
      }));

      try {
        // Get upload url
        const fileUploadDetails = await generateFileUploadUrl(file.name, cancelToken.token);
        await uploadFileToAzureStorage(
          fileUploadDetails.uploadUrl,
          file,
          (progress: number) => {
            setUploadTasks((prevTasks) => {
              if (!prevTasks[uploadId]) {
                return prevTasks;
              }

              return {
                ...prevTasks,
                [uploadId]: {
                  ...prevTasks[uploadId],
                  progress: progress,
                },
              };
            });
          },
          cancelToken.token
        );

        handleUploadDone(fileUploadDetails.downloadUrl);

        removeUploadTask(uploadId);
      } catch (e) {
        if (isCancelError(e)) {
          return;
        }

        setUploadTasks((prevTasks) => {
          if (!prevTasks[uploadId]) {
            return prevTasks;
          }

          return {
            ...prevTasks,
            [uploadId]: {
              ...prevTasks[uploadId],
              error: e.message || 'Unknown Error',
            },
          };
        });
      } finally {
        finishAction();
      }
    },
    [finishAction, handleUploadDone, removeUploadTask, startAction]
  );

  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const files = Array.from(event.target.files);
      for (const file of files) {
        startFileUpload(file);
      }

      // Reset selected file, otherwise onChange won't work with the same selected file
      event.target.value = '';
    },
    [startFileUpload]
  );

  const [isDragActive, { setTrue: handleDragStarted, setFalse: handleDragFinished }] = useBoolean(false);

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      handleDragStarted();
    },
    [handleDragStarted]
  );

  const onFilesDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      let files: File[] = [];
      if (event.dataTransfer.items) {
        files.push(
          ...(Array.from(event.dataTransfer.items)
            .map((dataTransferItem) => {
              if (dataTransferItem.kind === 'file') {
                return dataTransferItem.getAsFile();
              }
              return null;
            })
            .filter(Boolean) as File[])
        );
      } else if (event.dataTransfer.files) {
        files.push(...Array.from(event.dataTransfer.files));
      }

      if (!multiple) {
        files = files.slice(0, 1);
      }

      for (const file of files) {
        startFileUpload(file);
      }

      handleDragFinished();
    },
    [handleDragFinished, multiple, startFileUpload]
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const onFileUploadButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileRemove = useCallback(
    (value: string) => {
      const newValues = values.filter((v) => v !== value);
      onChange(multiple ? newValues : newValues[0]);
    },
    [multiple, onChange, values]
  );

  const handleFileEdited = useCallback(
    (url: string, newUrl: string) => {
      const newValues = values.map((v) => (v === url ? newUrl : v));
      onChange(newValues);
    },
    [onChange, values]
  );

  const uploadTasksList = useMemo(() => Object.values(uploadTasks), [uploadTasks]);
  const valuesToDisplay = useMemo(() => (!multiple && uploadTasksList.length > 0 ? [] : values), [multiple, uploadTasksList.length, values]);

  useUnmount(() => {
    for (const uploadTask of uploadTasksList) {
      uploadTask.cancelToken.cancel();
    }
  });

  return (
    <div className={styles.wrapper} onDragEnter={handleDragStarted} onDragOver={handleDragOver} onDragLeave={handleDragFinished} onDrop={onFilesDrop}>
      <div className={classNames(styles.dropArea, { [styles.active]: isDragActive })}>{t(multiple ? 'common.filesUpload' : 'common.fileUpload')}</div>
      <TitleField
        title={uiSchema?.['ui:title'] || label || schema.title}
        localizable={uiSchema?.cp_localizable}
        required={required}
        registry={registry}
        type={TitleFieldType.Primitive}
      />
      <DescriptionField description={schema.description || ''} detailed />
      <input
        id={id}
        ref={fileInputRef}
        type="file"
        disabled={readonly || disabled || schema.readOnly}
        onChange={handleFileInputChange}
        defaultValue=""
        autoFocus={autofocus}
        multiple={multiple}
        accept={options.accept as string}
        className={styles.input}
        tabIndex={-1}
      />
      <div>
        <FilesInfo
          files={valuesToDisplay}
          uploadTasks={uploadTasksList}
          onFileRemove={handleFileRemove}
          onUploadTaskRemove={removeUploadTask}
          onFileEdited={handleFileEdited}
        />
        <DefaultButton
          disabled={readonly || disabled || schema.readOnly || (!multiple && uploadTasksList.length > 0)}
          text={t(multiple ? 'common.filesUpload' : 'common.fileUpload')}
          onClick={onFileUploadButtonClick}
        />
      </div>
    </div>
  );
};

export default React.memo(FileWidget);
