import { getSafeString } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import styles from './HtmlContent.module.scss';

export interface IHtmlContentProps extends HTMLAttributes<HTMLDivElement> {
  html?: string;
  allowScripts?: boolean;
  darkMode?: boolean;
}

const HtmlContent: React.FC<IHtmlContentProps> = ({ html = '', allowScripts, darkMode, style, className, ...rest }) => {
  const darkModeSetting = useSelector((state: IGlobalState) => state.settings.darkMode);
  const useDarkMode = darkMode ?? darkModeSetting;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) {
      return;
    }

    divRef.current.innerHTML = '';

    const parsedHTML = document.createRange().createContextualFragment(allowScripts ? html : (getSafeString(html) as string));
    divRef.current.appendChild(parsedHTML);
  }, [html, allowScripts]);

  if (!html) {
    return null;
  }

  return (
    <div ref={divRef} style={style} className={classNames(styles.wrapper, { [styles.dark]: useDarkMode }, className, 'html-content')} {...rest} />
  );
};

export default HtmlContent;
