import * as _ from 'lodash';
import { IComponentWithOptions, ITableRowOption, ITableRowProps } from '@cpa/base-core/types';
import React, { NamedExoticComponent, useCallback } from 'react';

import styles from './SearchResult.module.scss';

const SearchResult: React.FC<ITableRowProps> = ({ rowId, item }) => {
  const openLink = useCallback(() => {
    if (!item.url || typeof item.url !== 'string') {
      return;
    }
    window.open(item.url, '_blank');
  }, [item.url]);

  return (
    <div className={styles.rowWrapper}>
      <div>
        <div id={rowId}>
          <div className={styles.searchResultBlock}>
            <div className={styles.activeArea} onClick={openLink}>
              <span className={styles.title}>{item.name}</span>
              <span className={styles.url}>{item.url}</span>
              <span className={styles.description}>{item.description}</span>
              <span className={styles.contentPreview}>{item.contentPreview as string} ...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchResultMemoized: NamedExoticComponent & IComponentWithOptions<ITableRowOption> = React.memo(SearchResult, _.isEqual);
SearchResultMemoized.options = {
  table: {
    hideHeader: true,
    hideSelectedItemsCount: true,
    disabledManagedColumnsWidth: true,
  },
};
export default SearchResultMemoized;
