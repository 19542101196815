import { IJwtDetails } from '@cp/base-types';

import { axiosDictionary, getAccessToken, getEndpoint } from '../api';
import { store } from '../store';

let cachedJwtDetails:
  | {
      jwtDetails: Promise<IJwtDetails>;
      fetchedForToken: string;
    }
  | undefined = undefined;

export const getUserJwtDetails = async (disableCache?: boolean): Promise<IJwtDetails | undefined> => {
  if (!store.getState().auth.user) {
    cachedJwtDetails = undefined;
    return undefined;
  }

  const token = await getAccessToken();
  if (!disableCache && cachedJwtDetails && cachedJwtDetails.fetchedForToken === token) {
    return cachedJwtDetails.jwtDetails;
  }

  const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
  const jwtDetailsPromise = metaServiceEndpoint.axios.post<IJwtDetails>('/idm/broker/validate', { jwt: token });
  cachedJwtDetails = { jwtDetails: jwtDetailsPromise, fetchedForToken: token };

  return jwtDetailsPromise;
};
