import { ICompany } from '../types';

export const getAccountNo =
  <T>(getCompanies: (state: T) => ICompany[]) =>
  (state: T): string | null => {
    const companies = getCompanies(state);

    if (!Array.isArray(companies) || !companies.length) {
      return null;
    }

    const customerAccounts = companies.reduce((acc, company) => {
      if (!company.company) {
        return acc;
      }

      const accounts = company.accounts
        .filter((account) => account.type === 'Customer' && account.no)
        .map((account) => ({
          ...account,
          company,
        }));

      return [...acc, ...accounts];
    }, []);

    return customerAccounts.length > 0 ? customerAccounts[0].no : null;
  };
