import React, { CSSProperties, useContext, useEffect, useMemo } from 'react';
import { Schemas } from '@cp/base-types';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { useId } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { match } from 'react-router';

import { LayoutContext } from '../../Layout';
import ScrollablePaneContextProvider from '../../../ScrollablePaneContextProvider/ScrollablePaneContextProvider';
import Tour from '../Tour/Tour';
import Footer from '../Footer/Footer';

import styles from './CachedContent.module.scss';

export interface ICachedContentProps {
  page: Schemas.CpaPage;
  children: React.ReactNode;
  hasForcedLanguage?: boolean;
  routeMatch: match;
}

export interface IMainContentProps {
  children: React.ReactNode;
}

export const MainContent: React.FC<IMainContentProps> = ({ children }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const backgroundLogo = useSelector((state: IGlobalState) => state.app.cpa?.backgroundLogo);

  return (
    <main
      style={
        backgroundLogo
          ? ({
              '--url': `url(${backgroundLogo})`,
            } as CSSProperties)
          : {}
      }
      className={classNames(styles.main, { [styles.dark]: darkMode })}
    >
      {children}
    </main>
  );
};

const CachedContent: React.FC<ICachedContentProps> = ({ children, page, routeMatch, hasForcedLanguage = false }) => {
  const footerWrapperId = useId('footer');
  const layoutContext = useContext(LayoutContext);

  const layoutContent = useMemo(
    () => ({
      page,
      hasForcedLanguage,
      routeMatch,
    }),
    [hasForcedLanguage, page, routeMatch]
  );

  useEffect(() => {
    // Notify Layout about page change
    layoutContext?.setLayoutContent(layoutContent);
  });

  const customScrollablePaneStyles = useMemo(
    () => ({
      root: {
        marginTop: layoutContext?.isHorizontalMenu ? 147 : 101,
        maxWidth: '100%',
      },
      contentContainer: {
        overflowX: 'hidden',
      },
    }),
    [layoutContext?.isHorizontalMenu]
  );

  return (
    <ScrollablePaneContextProvider bottomElementSelector={`#${footerWrapperId} > footer`} customScrollablePaneStyles={customScrollablePaneStyles}>
      {!layoutContext?.hideBubbles && <Tour />}
      <MainContent>{children}</MainContent>
      <div id={footerWrapperId}>
        <Footer />
      </div>
    </ScrollablePaneContextProvider>
  );
};

export default CachedContent;
