import * as Expressions from './expressions';
import * as ArrayOrObject from './json';
import * as ODataUri from './odataUri';
import * as PrimitiveLiteral from './primitiveLiteral';
import * as Query from './query';
import * as ResourcePath from './resourcePath';
export const parserFactory = function (fn) {
    return function (source, options) {
        options = options || {};
        const raw = new Uint16Array(source.length);
        const pos = 0;
        for (let i = 0; i < source.length; i++) {
            raw[i] = source.charCodeAt(i);
        }
        const result = fn(raw, pos, options.metadata);
        if (!result) {
            throw new Error(`Fail at ${pos}`);
        }
        if (result.next < raw.length) {
            throw new Error(`Unexpected character at ${result.next}`);
        }
        return result;
    };
};
export class Parser {
    odataUri(source, options) {
        return parserFactory(ODataUri.odataUri)(source, options);
    }
    resourcePath(source, options) {
        return parserFactory(ResourcePath.resourcePath)(source, options);
    }
    query(source, options) {
        return parserFactory(Query.queryOptions)(source, options);
    }
    filter(source, options) {
        return parserFactory(Expressions.boolCommonExpr)(source, options);
    }
    keys(source, options) {
        return parserFactory(Expressions.keyPredicate)(source, options);
    }
    literal(source, options) {
        return parserFactory(PrimitiveLiteral.primitiveLiteral)(source, options);
    }
    arrayOrObject(source, index) {
        return parserFactory(ArrayOrObject.arrayOrObject)(source, index);
    }
}
export function odataUri(source, options) {
    return parserFactory(ODataUri.odataUri)(source, options);
}
export function resourcePath(source, options) {
    return parserFactory(ResourcePath.resourcePath)(source, options);
}
export function query(source, options) {
    return parserFactory(Query.queryOptions)(source, options);
}
export function filter(source, options) {
    return parserFactory(Expressions.boolCommonExpr)(source, options);
}
export function keys(source, options) {
    return parserFactory(Expressions.keyPredicate)(source, options);
}
export function literal(source, options) {
    return parserFactory(PrimitiveLiteral.primitiveLiteral)(source, options);
}
export * from './types';
