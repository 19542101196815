const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpTypeHotPropertyBlackList',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypeHotPropertyBlackList' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Type Hot Property Black List',
    'properties': {
        'cpTypeUrl': {
            'title': 'Cp Type Url',
            'description': '<div>The URL that indicates which type this is assigned to</div>',
            'type': 'string',
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
            ],
        },
        'propertyJsonPath': {
            'title': 'Property Json Path',
            'description': '<div>The name of the property (camelcase) in the Property Json</div>',
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/propertyJsonPath', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
    },
};
export const CpTypeHotPropertyBlackListSchema = Schema;
