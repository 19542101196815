import React, { useCallback, useMemo } from 'react';
import { useAboutValue } from '@cpa/base-core/hooks';
import { Icon } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { axiosDictionary, patchEntityToEndpoint, postEntityToEndpoint } from '@cpa/base-core/api';
import { TypeConstants } from '@cp/base-types';

import styles from '../../SimpleCard.module.scss';

interface IVotingProps {
  identifier: string;
  cpTypeUrl?: string;
  isVotingExists: boolean;
}

const Voting: React.FC<IVotingProps> = ({ identifier, cpTypeUrl, isVotingExists }) => {
  const email = useSelector((state: IGlobalState) => state.auth.user?.account.email);

  const [voting] = useAboutValue('http://platform.cosmoconsult.com/ontology/Voting', identifier, 'voting', 'value', cpTypeUrl, {}, !isVotingExists);
  const [userVote, originalItem] = useAboutValue(
    'http://platform.cosmoconsult.com/ontology/Voting',
    identifier,
    'voting-user-vote',
    'value',
    cpTypeUrl,
    { email: email },
    !isVotingExists
  );

  const isUserVoted = useMemo(() => {
    return userVote || null;
  }, [userVote]);

  const onVoteClick = useCallback(async () => {
    if (!isVotingExists) return;
    if (!originalItem) {
      // Post item
      await postEntityToEndpoint(
        axiosDictionary.appDataService,
        `data-store/${encodeURIComponent('http://platform.cosmoconsult.com/ontology/Voting')}`,
        {
          about: {
            _type: cpTypeUrl,
            identifier: identifier,
          },
          value: 1,
        }
      );
    } else {
      await patchEntityToEndpoint(
        axiosDictionary.appDataService,
        `data-store/${encodeURIComponent('http://platform.cosmoconsult.com/ontology/Voting')}`,
        {
          identifier: originalItem.identifier,
          value: originalItem.value,
          _eTag: originalItem['_eTag'],
        },
        { value: originalItem.value === 1 ? 0 : 1, identifier: originalItem.identifier, _eTag: originalItem['_eTag'] }
      );
    }
  }, [cpTypeUrl, identifier, isVotingExists, originalItem]);

  return (
    <div className={styles.comments} onClick={onVoteClick}>
      <Icon iconName={isUserVoted ? 'likeSolid' : 'like'} />
      <span className={styles.text}>{voting || 0}</span>
    </div>
  );
};

export default Voting;
