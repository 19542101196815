import * as _ from 'lodash';
import { IDataItem } from '@cpa/base-core/types';

export function buildFieldEditorFormData(rootFormData: IDataItem, fieldPath: string): IDataItem {
  const path = _.toPath(fieldPath);

  const fieldName = path.slice(-1)[0];
  const fieldValue = _.get(rootFormData, path!);

  const formData = {
    _eTag: rootFormData._eTag,
  } as IDataItem;

  if (fieldValue !== undefined) {
    formData[fieldName] = fieldValue;
  }

  return formData;
}
