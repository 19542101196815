import { useAdvancedInterval } from '@cpa/base-core/hooks';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

import styles from './LoadingBar.module.scss';

export interface ILoadingBarProps {
  loading: boolean;
  height?: number;
  step?: [number, number];
  color?: string;
}

const formatStep = (progress: number, from: number, to: number): [number, number] => [(from * 10) / progress, (to * 10) / progress];
const random = (from: number, to: number): number => Math.floor(Math.random() * (to - from) + to);

const LoadingBar: React.FC<ILoadingBarProps> = ({ loading, height = 2, color = '#b39c4d', step = [4, 8] }) => {
  const [progress, setProgress] = useState(0);

  const [restart, clear] = useAdvancedInterval(() => {
    if (!loading) {
      return;
    }
    const formattedStep = progress < 30 ? step : formatStep(progress, ...step);
    const increase = random(...formattedStep);
    if (progress + increase < 49) {
      flushSync(() => {
        setProgress(progress + increase);
      });
    } else if (progress !== 49) {
      flushSync(() => {
        setProgress(49);
      });
    }
  }, 100);

  useEffect(() => {
    if (!loading && progress !== 0) {
      clear();
      setProgress(0);
    }

    if (loading && progress === 0) {
      setProgress(5);
      restart();
    }
  }, [progress, loading]);

  return (
    <div className={styles.lineContainer} style={{ height }}>
      <div
        style={{
          background: `linear-gradient(90deg, transparent 0%, transparent 30%, ${color} 70%, ${color} 99%, rgb(255, 103, 30) 100%)`,
          height,
          width: `200%`,
          transition: loading ? (progress <= 1 ? 'all 0s' : 'all 1s') : 'all 2s',
          transform: `translateX(${progress - 100}%)`,
        }}
        className={classNames({ [styles.completed]: !loading })}
      />
    </div>
  );
};

export default React.memo(LoadingBar);
