const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpaFooter',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaFooter' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa Footer',
    'properties': {
        'socialMedia': {
            'title': 'Social Media',
            'description': '<div>The social media platform the footer link refers to.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaFooterSocialMediaIcon',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaFooterSocialMediaIcon' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/socialMedia', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Facebook',
                'http://platform.cosmoconsult.com/ontology/Instagram',
                'http://platform.cosmoconsult.com/ontology/Linkedin',
                'http://platform.cosmoconsult.com/ontology/NotSelected',
                'http://platform.cosmoconsult.com/ontology/Twitter',
                'http://platform.cosmoconsult.com/ontology/Xing',
                'http://platform.cosmoconsult.com/ontology/Youtube',
            ],
            'enumNames': ['Facebook', 'Instagram', 'Linkedin', 'Not Selected', 'X (formerly Twitter)', 'Xing', 'Youtube'],
        },
        'cpas': {
            'title': 'Cpas',
            'description': '<div>The CPA the footer belongs to.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Cpa',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': true,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cpa',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa' } },
                'required': ['identifier'],
            },
            'minItems': 1,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/cpas', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The name of the footer.</div>',
            'type': 'string',
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>The target URL, the link should refer to.</div>',
            'type': 'string',
            'format': 'uri',
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'url', 'cpas'],
};
export const CpaFooterSchema = Schema;
