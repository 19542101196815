import React, { useMemo } from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import styles from './LazyIframe.module.scss';

interface ILazyIframeProps {
  url: string;
}

const LazyIframe: React.FC<ILazyIframeProps> = ({ url }) => {
  const videoId = useMemo(() => {
    const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return regex.exec(url)?.[3];
  }, [url]);

  if (!videoId) return null;

  return (
    <div className={styles.videoWrapper}>
      <LiteYouTubeEmbed adNetwork={false} id={videoId} poster="hqdefault" title="YouTube Embed" noCookie={true} />
    </div>
  );
};

export default LazyIframe;
