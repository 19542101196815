import React from 'react';
import classNames from 'classnames';

import BpmnViewer from '../../../../../components/Form/components/BpmnEditorDialog/components/BpmnViewer/BpmnViewer';

import styles from './ProcessInformation.module.scss';

interface IProcessInformationProps {
  title: string;
  xml: string;
}

const ProcessInformation: React.FC<IProcessInformationProps> = ({ title, xml }) => {
  return (
    <>
      <h1 className={classNames({ [styles.schemaTitle]: true, [styles.titleHidden]: true })} data-heading={'process-description'}>
        {title}
      </h1>
      <BpmnViewer xml={xml}></BpmnViewer>
    </>
  );
};

export default ProcessInformation;
