import { DataItemProperties } from '@cp/base-types';
import escapeStringRegexp from 'escape-string-regexp';
import * as _ from 'lodash';
import { formatFilterValue, Operator } from './filters';
const prepare = (value) => {
    var _a;
    const formattedValue = formatFilterValue((_a = value === null || value === void 0 ? void 0 : value.toString()) !== null && _a !== void 0 ? _a : '');
    try {
        return decodeURIComponent(formattedValue);
    }
    catch (e) {
        return formattedValue;
    }
};
const compareValues = (compareConfiguration, targetItem, filterValue) => {
    if (Array.isArray(filterValue)) {
        if (filterValue.length === 0) {
            return true;
        }
        return filterValue.some((filterValueItem) => compareValues(compareConfiguration, targetItem, filterValueItem));
    }
    if (compareConfiguration.isLoose) {
        return !!new RegExp(escapeStringRegexp(prepare(filterValue).toString()), 'gi').exec(targetItem);
    }
    if (compareConfiguration.isGt) {
        if (targetItem === undefined || targetItem === null || filterValue === undefined || filterValue === null) {
            return false;
        }
        return targetItem > filterValue;
    }
    if (compareConfiguration.isLt) {
        if (targetItem === undefined || targetItem === null || filterValue === undefined || filterValue === null) {
            return false;
        }
        return targetItem < filterValue;
    }
    return prepare(filterValue) === prepare(targetItem);
};
export const getObjectValuesByPath = (propertyJsonPath, object) => {
    const path = _.toPath(propertyJsonPath);
    const property = path.shift();
    const value = _.get(object, property);
    if (value == null)
        return;
    if (!path.length)
        return value;
    if (Array.isArray(value)) {
        const results = [];
        for (const item of value) {
            const result = getObjectValuesByPath(path.join('.'), item);
            if (Array.isArray(result)) {
                results.push(...result);
            }
            else {
                results.push(result);
            }
        }
        return results.filter(Boolean);
    }
    return getObjectValuesByPath(path.join('.'), value);
};
const checkItemFilterMatch = (propertyJsonPath, filterValue, compareConfiguration, itemForFilter, alternativeItemForFilter) => {
    const valuesForFilter = [getObjectValuesByPath(propertyJsonPath, alternativeItemForFilter), getObjectValuesByPath(propertyJsonPath, itemForFilter)];
    return valuesForFilter.some((itemValue) => {
        if (Array.isArray(itemValue)) {
            return itemValue.some((arrayItem) => compareValues(compareConfiguration, arrayItem, filterValue));
        }
        return compareValues(compareConfiguration, itemValue, filterValue);
    });
};
export function filterItemsByClientFilter(items, schema, parsedFilter) {
    return items.filter((itemForFilter) => checkItemMatchingClientFilter(itemForFilter, schema, parsedFilter));
}
export function checkItemMatchingClientFilter(item, schema, parsedFilter) {
    const getEnumName = (propertyKey, enumValue) => {
        var _a;
        const propertySchema = (_a = schema === null || schema === void 0 ? void 0 : schema.properties) === null || _a === void 0 ? void 0 : _a[propertyKey];
        if (propertySchema && propertySchema.enum && propertySchema.enumNames) {
            const enumIndex = propertySchema.enum.findIndex((s) => s === enumValue);
            if (enumIndex !== -1) {
                return propertySchema.enumNames[enumIndex];
            }
        }
        return;
    };
    if (parsedFilter.global) {
        if (item[DataItemProperties.SEARCH_TEXT_KEY] &&
            typeof item[DataItemProperties.SEARCH_TEXT_KEY] === 'string' &&
            item[DataItemProperties.SEARCH_TEXT_KEY].includes(parsedFilter.global)) {
            return true;
        }
        return Object.entries(item).some(([key, value]) => {
            var _a;
            const altValue = (_a = getEnumName(key, value)) !== null && _a !== void 0 ? _a : value;
            return compareValues({ isLoose: true }, altValue, parsedFilter.global);
        });
    }
    if (parsedFilter.entries) {
        const altItem = _.mapValues(item, (value, key) => { var _a; return (_a = getEnumName(key, value)) !== null && _a !== void 0 ? _a : value; });
        return Object.entries(parsedFilter.entries).every(([filterType, filterEntriesValue]) => {
            if (filterType === Operator.AND) {
                return filterEntriesValue.every((secondaryFilter) => checkItemMatchingClientFilter(item, schema, secondaryFilter));
            }
            if (filterType === Operator.OR) {
                return filterEntriesValue.some((secondaryFilter) => checkItemMatchingClientFilter(item, schema, secondaryFilter));
            }
            const isContainsFilter = filterType === Operator.CONTAINS || filterType === Operator.NOT_CONTAINS;
            const isNotFilter = filterType === Operator.NOT_CONTAINS || filterType === Operator.NOT_EQUALS || filterType === Operator.NOT_NULL;
            const isGtFilter = filterType === Operator.GREATER_THAN;
            const isLtFilter = filterType === Operator.LESS_THAN;
            return Object.entries(filterEntriesValue).every(([key, value]) => {
                if (isContainsFilter && key === DataItemProperties.SEARCH_TEXT_KEY && !(DataItemProperties.SEARCH_TEXT_KEY in item)) {
                    return Object.values(altItem).some((altItemValue) => {
                        return compareValues({ isLoose: true }, altItemValue, value);
                    });
                }
                return (checkItemFilterMatch(key, value, {
                    isLoose: isContainsFilter,
                    isGt: isGtFilter,
                    isLt: isLtFilter,
                }, item, altItem) !== isNotFilter);
            });
        });
    }
    return true;
}
