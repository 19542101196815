import { Environment } from '../app/environment';

export class QueryFilterManager<T extends Record<string, string | string[]>> {
  constructor() {}

  public constructQuery(params: T): string {
    try {
      return JSON.stringify(params);
    } catch {
      return '';
    }
  }

  public parseQuery(query: string): T {
    try {
      return JSON.parse(query) as T;
    } catch {
      return {} as T;
    }
  }
}

export const getAppBasePath = (): string => {
  if (!Environment.env.PUBLIC_URL) {
    return '';
  }

  try {
    const url = new URL(Environment.env.PUBLIC_URL);
    return url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname;
  } catch (e) {
    return Environment.env.PUBLIC_URL.endsWith('/') ? Environment.env.PUBLIC_URL.slice(0, -1) : Environment.env.PUBLIC_URL;
  }
};
