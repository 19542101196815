import { join } from 'path';
import { parse } from 'querystring';
import normalize from 'normalize-path';
import qs from 'qs';
import URLParse from 'url-parse';
class UrlBuilder {
    host(host) {
        return this;
    }
    query(query) {
        return this;
    }
    path(...pathParts) {
        return this;
    }
}
const builder = new UrlBuilder();
builder
    .host('cosmo.com')
    .path(...['my', 'path', 'to', ''])
    .query({ p1: 'v1' });
export class Url {
    get auth() {
        return this._urlLib.auth;
    }
    set auth(auth) {
        if (!auth) {
            this._urlLib.auth = '';
            this._urlLib.username = '';
            this._urlLib.password = '';
        }
        else {
            const splittedAuth = auth.split(':');
            if (splittedAuth.length > 2) {
                throw new Error(`Auth should contain only one colon. Input was '${auth}'`);
            }
            this._urlLib.username = splittedAuth[0];
            this._urlLib.password = splittedAuth[1];
            this.updateAuth();
        }
    }
    get hash() {
        return this._urlLib.hash;
    }
    set hash(hash) {
        this._urlLib.hash = hash ? `#${hash.replace('#', '')}` : '';
    }
    get host() {
        return this._urlLib.host;
    }
    set host(host) {
        const splittedHost = host.split(':');
        if (splittedHost.length > 2) {
            throw new Error(`Error parsing host. Only one colon allowed. Input was '${host}'.`);
        }
        if (!splittedHost[0]) {
            throw new Error(`Error parsing host. Hostname is empty.`);
        }
        const hostname = splittedHost[0];
        const port = splittedHost[1];
        if (port) {
            if (!this.isNumber(port)) {
                throw new Error(`Error parsing host. Port is not a number. Input was '${host}'.`);
            }
        }
        this._urlLib.hostname = hostname;
        this._urlLib.port = port;
        this.updateHost();
    }
    get hostname() {
        return this._urlLib.hostname;
    }
    set hostname(hostname) {
        if (!hostname) {
            throw new Error(`Hostname is empty.`);
        }
        this._urlLib.hostname = hostname;
        this.updateHost();
        this.updateOrigin();
    }
    get href() {
        return this.toString();
    }
    get origin() {
        return this._urlLib.origin;
    }
    get password() {
        return this._urlLib.password;
    }
    set password(password) {
        if (!this._urlLib.username) {
            throw Error(`Password not set, you have to set both with the 'auth' property.`);
        }
        if (!password) {
            throw new Error(`Password is empty.`);
        }
        this._urlLib.password = password;
        this.updateAuth();
    }
    get pathname() {
        return this._urlLib.pathname;
    }
    set pathname(pathname) {
        if (!pathname) {
            this._urlLib.pathname = '';
        }
        else {
            this._urlLib.pathname = Url.normalizePath(pathname);
        }
    }
    get port() {
        return this._urlLib.port;
    }
    set port(port) {
        if (!port) {
            this._urlLib.port = '';
        }
        else {
            if (!this.isNumber(port)) {
                throw new Error(`Port is not a number.`);
            }
            this._urlLib.port = port;
        }
        this.updateHost();
        this.updateOrigin();
    }
    get protocol() {
        return this._urlLib.protocol;
    }
    set protocol(protocol) {
        this._urlLib.protocol = protocol;
        this.updateOrigin();
    }
    get query() {
        return this._urlLib.query;
    }
    set query(query) {
        this._urlLib.query = query;
    }
    get username() {
        return this._urlLib.username;
    }
    set username(username) {
        if (!this._urlLib.password) {
            throw Error(`Password not set, you have to set both with the 'auth' property.`);
        }
        if (!username) {
            throw new Error(`Password is empty.`);
        }
        this._urlLib.username = username;
        this.updateAuth();
    }
    constructor(auth, hash, host, hostname, origin, password, pathname, port, protocol, query, slashes, username) {
        this._urlLib = URLParse('', {});
        this._urlLib.auth = auth;
        this._urlLib.hash = hash;
        this._urlLib.host = host;
        this._urlLib.hostname = hostname;
        this._urlLib.origin = origin;
        this._urlLib.password = password;
        this._urlLib.pathname = pathname;
        this._urlLib.port = port;
        this._urlLib.protocol = protocol;
        this._urlLib.query = query;
        this._urlLib.slashes = slashes;
        this._urlLib.username = username;
    }
    static build(options) {
        var _a;
        const urlLib = URLParse('', {});
        const endpoint = typeof options.hostAndOrBasePath === 'string' ? options.hostAndOrBasePath : (_a = options.hostAndOrBasePath) === null || _a === void 0 ? void 0 : _a.toString();
        let urlLibTemp;
        if (endpoint) {
            urlLibTemp = URLParse(endpoint, {});
            urlLib.protocol = urlLibTemp.protocol;
            urlLib.host = urlLibTemp.host;
            urlLib.slashes = urlLibTemp.slashes;
            urlLib.pathname = urlLibTemp.pathname;
        }
        const path = options.path;
        if (path) {
            if (Array.isArray(path)) {
                urlLib.pathname = Url.normalizePath(urlLib.pathname, ...path);
            }
            else {
                const pathAsString = typeof path === 'string' ? path : path.toString();
                urlLib.pathname = Url.normalizePath(urlLib.pathname, pathAsString);
            }
        }
        const query = options.query;
        if (query) {
            if (typeof query === 'string') {
                urlLib.query = qs.parse(query);
            }
            else if (typeof query === 'object') {
                urlLib.query = query;
            }
        }
        urlLib.hash = options.hash ? '#' + options.hash.replace('#', '') : '';
        return Url.parse(urlLib.toString());
    }
    static parse(url) {
        const parsedUrl = URLParse(url.toString(), {});
        const hash = parsedUrl.hash ? `#${parsedUrl.hash.replace('#', '')}` : '';
        let query;
        if (typeof parsedUrl.query === 'string') {
            const queryString = parsedUrl.query.replace('?', '');
            query = parse(queryString);
        }
        else {
            query = parsedUrl.query;
        }
        return new Url(parsedUrl.auth, hash, parsedUrl.host, parsedUrl.hostname, parsedUrl.origin, parsedUrl.password, parsedUrl.pathname, parsedUrl.port, parsedUrl.protocol, query, parsedUrl.slashes, parsedUrl.username);
    }
    static normalizePath(...pathParts) {
        const joinedPath = join(...pathParts);
        const normalizedPath = normalize(joinedPath);
        if (!normalizedPath) {
            return '';
        }
        const prefixedPath = normalizedPath[0] === '/' ? normalizedPath : `/${normalizedPath}`;
        return prefixedPath;
    }
    toString() {
        return this._urlLib.toString();
    }
    toURL() {
        return new URL(this.toString());
    }
    updateOrigin() {
        const urlLibTemp = URLParse(this.toString(), {});
        this._urlLib.origin = urlLibTemp.origin;
    }
    updateHost() {
        const hostname = this.hostname;
        const port = this.port ? `:${this.port}` : '';
        this._urlLib.host = hostname + port;
    }
    updateAuth() {
        this._urlLib.auth = `${this.username}:${this.password}`;
    }
    isNumber(str) {
        return !isNaN(parseInt(str, 10));
    }
}
