import { hideDrawer } from '@cpa/base-core/store/app/actions';
import { GlobalDrawerType, IDataItem, IGlobalDrawerState } from '@cpa/base-core/types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BatchOperationItemResult, IJSONSchema, Schemas } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';

import Difference from '../Difference/Difference';
import Drawer from '../Drawer/Drawer';
import BatchedDifference from '../Difference/BatchedDifference';
import { IDiffSource } from '../Difference/DifferenceAsObjects/helpers/difference';

export const GlobalDrawer: React.FC<IGlobalDrawerState> = ({ type, drawerOptions, isMaximized }) => {
  const dispatch = useDispatch();
  const pages = useSelector((state: IGlobalState) => state.app.pages);

  const matchedPage = drawerOptions.subjectUri ? pages.find((page) => page.cpTypeUrl === drawerOptions.subjectUri) : undefined;

  const page: Schemas.CpaPage = matchedPage || {
    dataEndpoint: { identifier: '__' },
    identifier: '__',
    name: '__',
    cpas: [{ identifier: '__' }],
  };

  const itemsToCompare = useMemo(() => {
    if (!drawerOptions.itemA || !drawerOptions.itemB || !drawerOptions.schema) {
      return [];
    }

    const itemsToCompare: IDiffSource[] = [];

    const itemA = drawerOptions.itemA as IDataItem;
    const itemB = drawerOptions.itemB as IDataItem;

    itemsToCompare.push({
      itemA: itemA,
      itemB: itemB,
      schema: drawerOptions.schema as IJSONSchema,
      nameA: (itemA.name || itemA.identifier || '-') as unknown as string,
      nameB: (itemA.name || itemA.identifier || '-') as unknown as string,
    });

    return itemsToCompare;
  }, [drawerOptions.itemA, drawerOptions.itemB, drawerOptions.schema]);

  const dialogTypes = {
    [GlobalDrawerType.DIFFERENCE]: () => <Difference page={page} itemsToCompare={itemsToCompare} />,
    // wrap with component with next and previous buttons
    [GlobalDrawerType.BATCHED_DIFFERENCE]: () => (
      <BatchedDifference
        page={page}
        results={drawerOptions.results as BatchOperationItemResult<any>[]}
        schema={drawerOptions.schema as IJSONSchema}
      />
    ),
  };

  return (
    <Drawer isOpen={true} onClose={() => dispatch(hideDrawer())} defaultMaximized={isMaximized} disableMaximizing={isMaximized}>
      {dialogTypes[type]?.()}
    </Drawer>
  );
};
