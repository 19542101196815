import { createUuidV4 } from '@cp/base-types';
import Konva from 'konva';

import { ImageEditorAdaptivity } from './ImageEditorAdaptivity';
import { ImageEditorChildType, ImageEditorUtils } from './ImageEditorUtils';

export abstract class ImageEditorOperations {
  public static create(imageElement: HTMLImageElement, wrapper: HTMLDivElement): Konva.Stage {
    const { width, height, scale, x, y } = ImageEditorAdaptivity.getSizeDimensions(imageElement, wrapper);

    const container = document.createElement('div');
    container.id = createUuidV4();

    container.style.height = width + 'px';
    container.style.width = height + 'px';
    container.style.position = 'fixed';
    container.style.top = y + 'px';
    container.style.left = x + 'px';
    container.style.zIndex = '9999999';

    document.body.appendChild(container);

    const stage = new Konva.Stage({
      container: container.id,
      width: width,
      height: height,
      scaleX: scale,
      scaleY: scale,
    });

    const layer = new Konva.Layer();
    stage.add(layer);

    const image = new Konva.Image({
      image: imageElement,

      x: 0,
      y: 0,
      width: imageElement.width,
      height: imageElement.height,

      name: ImageEditorChildType[ImageEditorChildType.image],
    });
    layer.add(image);
    layer.draw();

    return stage;
  }

  public static save(stage: Konva.Stage): string {
    const image = ImageEditorUtils.getChild(stage, ImageEditorChildType.image) as Konva.Image;
    const imageToRender = image.image() as HTMLImageElement;

    const width = imageToRender.width;
    const height = imageToRender.height;

    return this.editAndSave(width, height, (renderedStage) => {
      const layer = new Konva.Layer();
      renderedStage.add(layer);

      const imageElement = new Konva.Image({
        x: 0,
        y: 0,
        image: imageToRender,
        width: width,
        height: height,
      });
      layer.add(imageElement);

      layer.batchDraw();
    });
  }

  public static editAndSave(width: number, height: number, draw: (stage: Konva.Stage) => void): string {
    const konvaContainerId = createUuidV4();

    const div = document.createElement('div');
    div.id = konvaContainerId;

    div.style.position = 'absolute';
    div.style.top = '-9999px';
    div.style.left = '-9999px';
    div.style.width = `${width}px`;
    div.style.height = `${height}px`;

    document.body.appendChild(div);

    const stage = new Konva.Stage({
      container: konvaContainerId,
      width: width,
      height: height,
    });

    draw(stage);

    const dataUrl = stage.toDataURL({
      height: height,
      pixelRatio: 1,
      width: width,
    });

    stage.destroy();

    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }

    return dataUrl;
  }

  public static remove(stage: Konva.Stage): void {
    if (!stage) {
      return;
    }

    const container = stage.container();

    if (container) {
      container.remove();
    }
  }
}
