import { IJSONSchema, Schemas } from '@cp/base-types';
import { IDataItem } from '@cpa/base-core/types';
import * as _ from 'lodash';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard, isDefined, prepareSchema } from '@cpa/base-core/helpers';
import { IconButton } from '@fluentui/react';
import classNames from 'classnames';
import { FormatSource } from '@cpa/base-core/constants';

import { formatValue } from '../../../Formatter/formatter';
import styles from '../../ReadonlyContent.module.scss';
import ArrayContent from '../ArrayContent/ArrayContent';
import ObjectContent from '../ObjectContent/ObjectContent';
import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

export interface IHighlightedProperty {
  key: string;
  backgroundColor: string;
  padding?: number;
}

export interface IPropertyContentProps {
  value?: IDataItem | IDataItem[];
  schema: IJSONSchema;
  rootSchema: IJSONSchema;
  parentSchemas?: IJSONSchema[];
  options: {
    source: FormatSource;
    onlySelectedFields?: string[];
    useHovercardForHtml?: boolean;
    gridView?: boolean;
    expandFunctionalityOptions?: {
      defaultExpanded?: boolean;
      expandedLevel?: number;
    };
    highlightOptions?: {
      properties: IHighlightedProperty[];
    };
    differences?: Map<string, string>;
    _originalItem: IDataItem;
    customPropertyRender?: (
      itemKey: string,
      formattedContent: JSX.Element | null,
      propertyStyles: CSSProperties,
      level: number,
      propertySchema?: IJSONSchema,
      parentSchema?: IJSONSchema,
      rootSchema?: IJSONSchema,
      value?: IDataItem | IDataItem[],
      rootItem?: IDataItem
    ) => JSX.Element | null;
    showEmptyValues?: boolean;
  };
  level?: number;
  dataPath?: string;
  itemKey?: string;
  onDownload?: (items: IDataItem[], propertyJsonPaths: string[]) => void | Promise<void>;
  page?: Schemas.CpaPage;
  darkMode: boolean;
}

const PropertyContent: React.FC<IPropertyContentProps> = ({
  value,
  schema,
  rootSchema,
  parentSchemas,
  options,
  onDownload,
  page,
  darkMode,
  level = 1,
  itemKey = '',
  dataPath = '',
}) => {
  const [t, i18n] = useTranslation();

  const [tooltipText, setTooltipText] = useState<string>(t('common.copyToClipboard'));

  const resolvedSchema = useMemo(() => {
    type Properties = Record<string, IJSONSchema>;

    const resultSchema = prepareSchema(schema, value);

    // If no dependencies - no need to modify schema
    if (!resultSchema || !resultSchema.dependencies || !resultSchema.properties) {
      return resultSchema;
    }

    // Modifying schema with dependencies inserted to PropertyContent level to support nested object
    const schemaCopy: IJSONSchema = _.cloneDeep(resultSchema);

    // Formatting deps into Array<[key: string, IJSONSchema[]]>
    const deps = Object.entries(schemaCopy.dependencies as object).map(([k, v]) => {
      return [k, v.oneOf.map((op: { properties: object }) => op.properties)];
    });

    // Defining new properties for schema for next filling in
    const properties: Properties = {};
    let i = 0;
    for (const [key, prop] of Object.entries(schemaCopy.properties as Properties)) {
      // __readonlyIndex is used for sorting properties if ui:schema doesn't exist
      properties[key] = { ...prop, __readonlyIndex: i };

      // Searching for dependencies of current property
      const foundDeps = deps.find(([k]) => k === key);

      if (foundDeps) {
        // Converting IJSONSchema[] into Record<string, IJSONSchema> to push into new properties
        const depsProps: Properties = foundDeps[1]
          .map((v: Properties) => {
            // Removing current property key
            const { [key]: _removedKey, ...otherProperties } = v;

            return otherProperties;
          })
          .reduce((acc: Properties, v: Properties) => ({ ...acc, ...v }), {});

        // Appending dependency properties after current property
        for (const [depKey, depProp] of Object.entries(depsProps)) {
          properties[depKey] = { ...depProp, __readonlyIndex: i };
          i += 1;
        }
      } else {
        i += 1;
      }
    }
    // Overriding for new properties
    schemaCopy.properties = properties;
    return schemaCopy;
  }, [schema, value]) as IJSONSchema;

  const propertyStyles: CSSProperties = useMemo(() => {
    const foundProp = options.highlightOptions?.properties?.find((p) => p.key === itemKey);
    if (foundProp) {
      const { key, ...props } = foundProp;
      return props;
    } else {
      return {};
    }
  }, [itemKey, options]);

  const formatted = useMemo(() => {
    const handleDownload = (): Promise<void> | void => onDownload?.([options._originalItem], [itemKey]);

    let valueToShow = value;
    let schemaToUse = resolvedSchema;

    if (options.differences) {
      if (options.differences.has(dataPath)) {
        const foundDifference = options.differences.get(dataPath);

        if (foundDifference) {
          if (resolvedSchema?.type === 'string' && resolvedSchema?.format !== 'date-time') {
            valueToShow = foundDifference as any;
            schemaToUse = { ...resolvedSchema, format: 'cp:html' };
          }
        }
      }
    }

    return formatValue(valueToShow, options.source, {
      schema: schemaToUse,
      locale: i18n.language,
      darkMode,
      t,
      itemKey,
      onDownload: handleDownload,
      rootSchema,
      originalItem: options._originalItem,
      page: page,
    });
  }, [
    value,
    options.source,
    options._originalItem,
    resolvedSchema,
    i18n.language,
    darkMode,
    t,
    itemKey,
    rootSchema,
    page,
    onDownload,
    dataPath,
    options.differences,
  ]);

  const valueToCopy = useMemo(() => {
    if (formatted && typeof formatted === 'string') return formatted;
    else if (value && typeof value === 'string' && !resolvedSchema.format?.startsWith('cp:html')) return value;
    return null;
  }, [formatted, resolvedSchema.format, value]);

  const handleCopyClick = useCallback(() => {
    copyToClipboard(valueToCopy as string);
    setTooltipText(t('common.copiedToClipboard'));
  }, [t, valueToCopy]);

  const handleTooltipToggle = useCallback(
    (isVisible: boolean) => {
      if (!isVisible) {
        setTooltipText(t('common.copyToClipboard'));
      }
    },
    [t]
  );

  const appendedParentSchema = useMemo(() => {
    if (resolvedSchema.type !== 'object' || !resolvedSchema.properties) {
      return parentSchemas;
    }

    if (
      parentSchemas &&
      parentSchemas.length &&
      parentSchemas[parentSchemas.length - 1] &&
      _.isEqual(parentSchemas[parentSchemas.length - 1], schema)
    ) {
      return parentSchemas;
    }

    return [...(parentSchemas || []), schema];
  }, [parentSchemas, schema, resolvedSchema]);

  if (schema?.cp_ui?.hiddenInReadonlyContent === true) {
    return null;
  }

  if (!isDefined(value) && !options.showEmptyValues) {
    return null;
  }

  const copyButtonClass = classNames({
    [styles.copyButtonLight]: !darkMode,
    [styles.copyButtonDark]: darkMode,
  });

  let propertyView: JSX.Element | null = (
    <div style={{ marginBottom: 10, ...propertyStyles }}>
      <div className={styles.title}>{resolvedSchema?.title}</div>
      <div className={styles.valueWrapper}>
        <div
          className={classNames({
            [styles.value]: true,
            [styles.valueDark]: darkMode,
            [styles.hoverCardValue]: (options.source = FormatSource.HoverCard),
          })}
        >
          {formatted}
        </div>
        {valueToCopy && (
          <HoverTooltip onTooltipToggle={handleTooltipToggle} content={tooltipText} closeDelay={1500}>
            <IconButton onClick={handleCopyClick} className={copyButtonClass} iconProps={{ iconName: 'Copy' }} />
          </HoverTooltip>
        )}
      </div>
    </div>
  );

  if (resolvedSchema.type === 'object' && resolvedSchema.properties) {
    // to hide properties with _ because they haven't got provided schema to avoid empty collapsing object
    const properties = Object.keys(resolvedSchema.properties);
    if (Object.keys(value || {}).every((k) => !properties.includes(k)) && !options.showEmptyValues) {
      return null;
    }

    propertyView =
      Object.keys(value || {}).length > 0 || options.showEmptyValues ? (
        <div style={{ marginBottom: 10, ...propertyStyles }}>
          <ObjectContent
            onDownload={onDownload}
            value={value}
            schema={resolvedSchema}
            rootSchema={rootSchema}
            parentSchemas={appendedParentSchema}
            options={options}
            level={level}
            itemKey={itemKey}
            dataPath={dataPath}
            page={page}
            darkMode={darkMode}
          />
        </div>
      ) : null;
  }

  if (resolvedSchema.type === 'array') {
    propertyView = (
      <div style={{ marginBottom: 10, ...propertyStyles }}>
        <ArrayContent
          onDownload={onDownload}
          value={value}
          schema={resolvedSchema}
          rootSchema={rootSchema}
          parentSchemas={appendedParentSchema}
          options={options}
          level={level}
          itemKey={itemKey}
          dataPath={dataPath}
          page={page}
          darkMode={darkMode}
        />
      </div>
    );
  }

  if (options.customPropertyRender) {
    return options.customPropertyRender(
      itemKey,
      propertyView,
      propertyStyles,
      level,
      resolvedSchema,
      appendedParentSchema,
      rootSchema,
      value,
      options._originalItem
    );
  }

  return propertyView;
};

export default React.memo(PropertyContent);
