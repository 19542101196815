import React, { useCallback, useEffect, useRef } from 'react';
import Viewer from 'bpmn-js/lib/NavigatedViewer';
import BaseViewer from 'bpmn-js/lib/BaseViewer';
import classNames from 'classnames';

import styles from '../BpmnEditor/BpmnEditor.module.scss';

interface IBpmnViewerProps {
  xml: string;
}

const BpmnViewer: React.FC<IBpmnViewerProps> = ({ xml }) => {
  const viewerWrapper = useRef<HTMLDivElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const viewer = useRef<BaseViewer | null>(null);

  useEffect(() => {
    if (container.current) {
      viewer.current = new Viewer({
        container: container.current,
        additionalModules: [],
      });
    }
    return () => {
      viewer.current?.destroy();
    };
  }, []);

  const openDiagram = useCallback(async (xml: string) => {
    try {
      await viewer.current?.importXML(xml);
      const canvas = viewer.current?.get('canvas') as { zoom: (type: string) => void };
      canvas?.zoom('fit-viewport');
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    openDiagram(xml);
  }, [xml]);

  return (
    <div className={classNames(styles.editorContainer, 'modeler')} ref={viewerWrapper}>
      <div id="canvas" style={{ width: '100%' }} ref={container}></div>
    </div>
  );
};

export default BpmnViewer;
