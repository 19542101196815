import React, { useMemo } from 'react';
import { IDataItem } from '@cpa/base-core/types';
import { FormatSource } from '@cpa/base-core/constants';
import { Schemas } from '@cp/base-types';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';

import { IPropertyDiff } from '../helpers/difference';
import ExpandButton from '../../../ExpandButton/ExpandButton';
import ReadonlyContent from '../../../ReadonlyContent/ReadonlyContent';

import styles from './DifferenceAsObjectsItem.module.scss';

export interface IDifferenceAsObjectsItemProps {
  page: Schemas.CpaPage;
  collapsedDataPaths: string[];
  item: IPropertyDiff;
  onToggleCollapsed: (e: React.MouseEvent<HTMLDivElement>, item: IPropertyDiff) => void;
}

const DifferenceAsObjectsItem: React.FC<IDifferenceAsObjectsItemProps> = ({ page, collapsedDataPaths, item, onToggleCollapsed }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const isBlockShown = useMemo(() => {
    if (item.dataPath === '') {
      return true;
    }

    if (collapsedDataPaths.includes('')) {
      return false;
    }

    if (collapsedDataPaths.filter((s) => item.dataPath.includes(s + '.')).length) {
      return false;
    }

    return true;
  }, [item, collapsedDataPaths]);

  const isBlockExpanded = useMemo(() => {
    return !collapsedDataPaths.includes(item.dataPath);
  }, [item, collapsedDataPaths]);

  const readOnlyContentData = useMemo(() => ({ page, totalItems: 0, schema: item.propertySchema }), [page, item.propertySchema]);

  const readOnlyContentOptionsFrom = useMemo(
    () => ({
      source: FormatSource.Drawer,
      differences: item.diffFrom ? new Map<string, string>([['', item.diffFrom!]]) : undefined,
      highlightOptions: item.isChanged
        ? {
            properties: [
              {
                key: '',
                backgroundColor: 'var(--difference-ins-block-color)',
              },
            ],
          }
        : undefined,
    }),
    [item.diffFrom, item.isChanged]
  );

  const readOnlyContentOptionsTo = useMemo(
    () => ({
      source: FormatSource.Drawer,
      differences: item.diffTo ? new Map<string, string>([['', item.diffTo!]]) : undefined,
      highlightOptions: item.isChanged
        ? {
            properties: [
              {
                key: '',
                backgroundColor: 'var(--difference-del-block-color)',
              },
            ],
          }
        : undefined,
    }),
    [item.diffTo, item.isChanged]
  );

  const itemIsStringArray = useMemo(() => {
    return item.propertySchema.type === 'array' && item.propertySchema.items?.type === 'string';
  }, [item.propertySchema]);

  const oldValueIsExpandable = useMemo(() => {
    if (itemIsStringArray) {
      return false;
    }

    return Array.isArray(item.oldValue) || typeof item.oldValue === 'object';
  }, [item.oldValue, itemIsStringArray]);

  const newValueIsExpandable = useMemo(() => {
    if (itemIsStringArray) {
      return false;
    }

    return Array.isArray(item.newValue) || typeof item.newValue === 'object';
  }, [item.newValue, itemIsStringArray]);

  if (!isBlockShown) {
    return null;
  } else
    return (
      <tr key={item.dataPath}>
        <td
          className={classNames({
            [styles.cell]: true,
            [styles.cellDark]: darkMode,
          })}
          style={{ paddingLeft: item.level * 20 }}
        >
          {(newValueIsExpandable || oldValueIsExpandable) && (
            <div
              className={styles.title}
              data-difference-del={!isBlockExpanded && item.isChanged ? '1' : '0'}
              style={{
                backgroundColor: !isBlockExpanded && item.isChanged ? 'var(--difference-del-block-color)' : '',
              }}
            >
              <ExpandButton
                className={classNames({
                  [styles.expandButton]: true,
                  [styles.expandButtonDark]: darkMode,
                })}
                isExpanded={isBlockExpanded}
                onClick={(e) => onToggleCollapsed(e, item)}
              />
              <div>{item.propertySchema.title ? item.propertySchema.title : item.parentPropertySchema ? item.parentPropertySchema.title : '—'}</div>
            </div>
          )}

          {!newValueIsExpandable && (
            <div data-difference-del={!item.diffFrom && item.isChanged ? '1' : '0'}>
              <ReadonlyContent item={item.newValue as IDataItem} data={readOnlyContentData} options={readOnlyContentOptionsTo} />
            </div>
          )}
        </td>
        <td
          className={classNames({
            [styles.cell]: true,
            [styles.cellDark]: darkMode,
          })}
          style={{ paddingLeft: item.level * 20 }}
        >
          {(newValueIsExpandable || oldValueIsExpandable) && (
            <div
              className={styles.title}
              data-difference-ins={!isBlockExpanded && item.isChanged ? '1' : '0'}
              style={{
                backgroundColor: !isBlockExpanded && item.isChanged ? 'var(--difference-ins-block-color)' : '',
              }}
            >
              <ExpandButton
                className={classNames({
                  [styles.expandButton]: true,
                  [styles.expandButtonDark]: darkMode,
                })}
                isExpanded={isBlockExpanded}
                onClick={(e) => onToggleCollapsed(e, item)}
              />
              <div>{item.propertySchema.title ? item.propertySchema.title : item.parentPropertySchema ? item.parentPropertySchema.title : '—'}</div>
            </div>
          )}

          {!oldValueIsExpandable && (
            <div data-difference-ins={!item.diffTo && item.isChanged ? '1' : '0'}>
              <ReadonlyContent item={item.oldValue as IDataItem} data={readOnlyContentData} options={readOnlyContentOptionsFrom} />
            </div>
          )}
        </td>
      </tr>
    );
};

export default DifferenceAsObjectsItem;
