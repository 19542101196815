import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import { urlOrPathRegex } from '@cp/base-types';
export function generateCommonAjv(options = {}, transformCode) {
    const ajv = new Ajv(Object.assign({ allErrors: true, schemaId: 'id', loadSchema: async (uri) => {
            throw new Error(`Schema validator is trying to load uri '${uri}'`);
        }, useDefaults: true, strict: false, multipleOfPrecision: 8, addUsedSchema: false, logger: {
            warn(...args) {
                const firstArg = args[0];
                if (typeof firstArg === 'string' && firstArg.startsWith('unknown format')) {
                    return;
                }
                console.warn(...args);
            },
            log: console.log,
            error: console.error,
        } }, options));
    addFormats(ajv);
    addKeywords(ajv);
    addCustomFormats(ajv, transformCode);
    return ajv;
}
function isValidUrl(string) {
    try {
        const url = new URL(string);
    }
    catch (e) {
        return false;
    }
    return true;
}
function isValidCode(string, transformCode) {
    try {
        transformCode(string);
    }
    catch (e) {
        new Ajv.ValidationError([{ message: 'Source code is invalid' }]);
        return false;
    }
    return true;
}
function addCustomFormats(ajv, transformCode) {
    ajv.addFormat('data-url', isValidUrl);
    ajv.addFormat('color', /^(#?([0-9A-Fa-f]{3}){1,2}\b|aqua|black|blue|fuchsia|gray|green|lime|maroon|navy|olive|orange|purple|red|silver|teal|white|yellow|(rgb\(\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*\))|(rgb\(\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*\)))$/);
    ajv.addFormat('cp:color', /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/i);
    ajv.addFormat('cp:colorAll', /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/i);
    ajv.addFormat('cp:UrlOrPath', urlOrPathRegex);
}
