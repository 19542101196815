import React, { useCallback, useMemo, useState } from 'react';
import { useAboutValue } from '@cpa/base-core/hooks';
import { TypeConstants } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import Comment from './components/Comment/Comment';
import styles from './Comments.module.scss';
import CommentInput from './components/CommentInput/CommentInput';

interface ICommentsProps {
  identifier: string;
  cpTypeUrl?: string;
  width?: number;
}

const Comments: React.FC<ICommentsProps> = ({ identifier, cpTypeUrl, width }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [allComments, setAllComments] = useState<boolean>(false);
  const [commentCount, originalItem, response] = useAboutValue(TypeConstants.Comment, identifier, 'comments', 'total', cpTypeUrl, {});
  const processedComments = useMemo(() => {
    return (
      response?.map((item) => {
        return {
          text: item.description,
          user: item.createdByUser,
          timestamp: item.createdAt,
        };
      }) || []
    );
  }, [response]);

  const handleExpandClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setAllComments(!allComments);
    },
    [allComments]
  );

  return (
    <div className={styles.commentsWrapper}>
      <div className={classNames(styles.divider, { [styles.dividerDark]: darkMode })} />
      {processedComments.length ? (
        <>
          {!allComments && processedComments.length > 2 ? (
            <div className={styles.expand} onClick={handleExpandClick}>
              {t('common.showPrevComments', { count: processedComments.length - 2 })}
            </div>
          ) : null}
          {allComments && processedComments.length > 2 ? (
            <div className={styles.expand} onClick={handleExpandClick}>
              {t('common.collapse')}
            </div>
          ) : null}
          <div className={styles.comments}>
            {processedComments.map((comment, index) => {
              return (
                <Comment
                  key={index}
                  user={comment.user as string}
                  text={comment.text as string}
                  timestamp={comment.timestamp as string}
                  hidden={!allComments && index < processedComments.length - 2}
                  width={width}
                />
              );
            })}
          </div>
        </>
      ) : null}
      <div className={styles.inputWrapper}>
        <CommentInput identifier={identifier} cpTypeUrl={cpTypeUrl} />
      </div>
    </div>
  );
};

export default Comments;
