import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

import styles from './LoadingArea.module.scss';

export interface ILoadingAreaProps {
  loading?: boolean;
}

const LoadingArea: React.FC<ILoadingAreaProps> = ({ loading = true }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={styles.loadingWrapper}>
      <Spinner size={SpinnerSize.large} />
    </div>
  );
};

export default LoadingArea;
