import axios from 'axios';
export const GuestJwtDetails = {
    user: `anonymous_guest@${process.env.ANON_GUEST_EMAIL_DOMAIN || 'platform.cosmoconsult.com'}`,
    companyAccounts: [],
    derivedRoles: [],
    internalAadMemberOrGuest: false,
    valid: true,
};
export const SysOpsJwtDetails = {
    user: `admin@platform.cosmoconsult.com`,
    companyAccounts: [],
    derivedRoles: ['group:cosmoconsult.com:G-T-CP-SysOps'],
    internalAadMemberOrGuest: false,
    valid: true,
};
export function fetchClientCredentialsToken({ apiClientId, apiClientSecret, apiScope, tenantId = 'platform.cosmoconsult.com', }) {
    if (process.env.USER_TOKEN) {
        return Promise.resolve(process.env.USER_TOKEN);
    }
    const tokenUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
    return axios
        .post(tokenUrl, new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: apiClientId,
        client_secret: apiClientSecret,
        scope: apiScope,
    }).toString(), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        },
    })
        .then(({ data: { access_token: jwt } }) => {
        if (!jwt) {
            console.error(`Missing jwt`);
            process.exit(1);
        }
        return jwt;
    });
}
export function generateAccessPermissionsFilter(user, roles) {
    var _a, _b;
    const filter = {
        $and: [
            {
                'accessPermissions.role': {
                    $nin: (roles === null || roles === void 0 ? void 0 : roles.map((role) => `!${role}`)) || [],
                },
            },
            {
                $or: [
                    { accessPermissions: { $exists: false } },
                    { accessPermissions: { $size: 0 } },
                    {
                        'accessPermissions.role': {
                            $in: [...(roles || []), '*'],
                        },
                    },
                ],
            },
        ],
    };
    if (user) {
        (_b = (_a = filter.$and) === null || _a === void 0 ? void 0 : _a[1].$or) === null || _b === void 0 ? void 0 : _b.push({
            'accessPermissions.email': user,
        });
    }
    return filter;
}
export function isValidTokenClaimValue(value) {
    return !!value && typeof value === 'string' && value !== 'unknown';
}
export function getPrimaryEmailFromToken(idTokenClaims) {
    if (isValidTokenClaimValue(idTokenClaims.email)) {
        return idTokenClaims.email.toLowerCase();
    }
    else if (idTokenClaims.emails && isValidTokenClaimValue(idTokenClaims.emails[0])) {
        return idTokenClaims.emails[0].toLowerCase();
    }
    else if (idTokenClaims.other_emails && isValidTokenClaimValue(idTokenClaims.other_emails[0])) {
        return idTokenClaims.other_emails[0].toLowerCase();
    }
    return undefined;
}
