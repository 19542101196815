const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CometAchievement',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAchievement' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAchievement?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAchievement?$filter=connectedAchievements%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAssociation?$filter=cometAchievement%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Comet Achievement',
    'properties': {
        'amount': {
            'title': 'Amount',
            'description': 'The amount of money.',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://schema.org/amount', 'rel': 'canonical' }],
        },
        'about': {
            'title': 'About',
            'description': 'The subject matter of the content.',
            'anyOf': [
                { '$id': 'http://platform.cosmoconsult.com/ontology/Undefined', 'type': 'null', 'title': 'Undefined' },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CometAchievement',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAchievement' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Comet Achievement',
                    'properties': {
                        'identifier': { 'type': 'string', 'title': 'Comet Achievement' },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CometAchievement',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
            ],
            'links': [{ 'href': 'http://schema.org/about', 'rel': 'canonical' }],
        },
        'connectedAchievements': {
            'title': 'Connected Achievements',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CometAchievement',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAchievement' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Comet Achievement',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Comet Achievement' } },
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/connectedAchievement', 'rel': 'canonical' }],
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': "<div>Short description or summary that serves to convey the most important information on the subject or subject matter in a concise form (1-3 sentences). Often used for list, card or tiles. The idea is to capture the reader's or viewer's attention without going into detail. A short description can be compared with a teaser text.</div>\n<div> </div>",
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const CometAchievementSchema = Schema;
