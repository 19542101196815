const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpJobStatusLog',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobStatusLog' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Job Status Log',
    'properties': {
        'endDate': {
            'title': 'End Date',
            'description': 'The end date of a thing',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 70 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
        },
        'startDate': {
            'title': 'Start Date',
            'description': 'The start date of a thing',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 60 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/startDate', 'rel': 'canonical' }],
        },
        'duration': {
            'title': 'Duration',
            'type': 'string',
            'format': 'cp:timespan',
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/duration', 'rel': 'canonical' }],
        },
        'jobSchedule': {
            'title': 'Job Schedule',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpJobSchedule',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobSchedule' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/jobSchedule', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Job Schedule' } },
            'cp_ui': { 'sortOrderForm': 20 },
        },
        'jobStatus': {
            'title': 'Job Status',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpJobStatus',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobStatus' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/jobStatus', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': ['http://platform.cosmoconsult.com/ontology/Error', 'http://platform.cosmoconsult.com/ontology/Success'],
            'enumNames': ['Error', 'Success'],
            'cp_ui': { 'sortOrderForm': 30 },
        },
        'message': {
            'title': 'Message',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/message', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
    },
};
export const CpJobStatusLogSchema = Schema;
