import { ICallToAction } from '@cpa/base-core/types';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { ThemeContext } from '@fluentui/react';

import HtmlContent from '../../../../components/HtmlContent/HtmlContent';

import styles from './CallToAction.module.scss';

interface ICallToActionProps {
  callToAction: ICallToAction;
}

const CallToAction: React.FC<ICallToActionProps> = ({ callToAction }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const theme = useContext(ThemeContext);
  return (
    <a href={callToAction.url} target="_blank" rel="noreferrer">
      <div
        className={classNames(styles.tile, { [styles.tileDark]: darkMode })}
        style={{ backgroundColor: darkMode ? theme?.palette.neutralSecondaryAlt : theme?.palette.white }}
      >
        <div className={classNames(styles.textContent, { [styles.textContentDark]: darkMode })}>
          <HtmlContent html={callToAction.description} allowScripts={false} />
        </div>
      </div>
    </a>
  );
};

export default CallToAction;
