import React, { useMemo } from 'react';
import { FormatSource } from '@cpa/base-core/constants';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import ExpertCard from '../../../../templates/singleItem/SolutionSingleItemTemplate/components/ExpertsList/components/ExpertCard/ExpertCard';
import HoverCardAutogrow from '../../../ScrollingContent/components/Table/components/HoverCardAutogrow/HoverCardAutogrow';

import styles from './Email.module.scss';

interface IEmailProps {
  email: string | string[];
  source: FormatSource;
}

const Email: React.FC<IEmailProps> = ({ email, source }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const users = useMemo(() => {
    return Array.isArray(email) ? email : [email];
  }, [email]);

  return (
    <HoverCardAutogrow
      isInsideTable={source === FormatSource.Table}
      previewContentRenderer={() => users.join(', ')}
      cardWrapperClassName={styles.cardWrapper}
      cardClassName={styles.card}
      calloutClassName={styles.callout}
      previewClassName={classNames(styles.email, 'email')}
      contentClassName={styles.cardContent}
    >
      <div className={styles.usersWrapper}>
        {users.map((user, index) => {
          return (
            <ExpertCard
              key={user}
              email={user}
              loaderClassName={styles.loader}
              contactPersonCardProps={{
                className: classNames(styles.expert, {
                  [styles.oddDark]: index % 2 && darkMode,
                  [styles.oddLight]: index % 2 && !darkMode,
                }),
                personCardProps: { isInHoverCard: true },
              }}
            />
          );
        })}
      </div>
    </HoverCardAutogrow>
  );
};

export default Email;
