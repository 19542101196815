import React, { CSSProperties, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { MessageBarType } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import MessageBars from '../../../MessageBars/MessageBars';
import ShimmerGrid from '../../../ShimmerGrid/ShimmerGrid';
import HtmlContent from '../../../HtmlContent/HtmlContent';
import { IKpiRenderProps } from '../../Kpi';

import styles from './KpiView.module.scss';

const KpiView: React.FC<IKpiRenderProps> = ({ value, name, isFetching, errors, redirectPath, identifier }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const shimmerGridStyle = useMemo<CSSProperties>(() => ({ position: 'absolute', width: 'calc(100% - 16px)' }), []);
  const content = (
    <div className={classNames(styles.tile, { [styles.clickable]: !!redirectPath })}>
      <MessageBars messageBarType={MessageBarType.error} isMultiline={false} messages={errors} />
      {isFetching && <ShimmerGrid style={shimmerGridStyle} darkMode={darkMode} gridArea={'"a"'} gridGap={0} height={'100%'} />}
      <div style={{ visibility: isFetching ? 'hidden' : 'unset', lineBreak: 'auto' }}>
        <h2 className={styles.textContent}>{value}</h2>
        <h4 className={styles.textContent}>
          <HtmlContent style={{ display: 'inline' }} html={name} />
        </h4>
      </div>
    </div>
  );
  if (!value) return null;
  if (redirectPath) {
    return (
      <Link to={redirectPath} key={identifier}>
        {content}
      </Link>
    );
  }
  return content;
};

export default KpiView;
