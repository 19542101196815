import { IJSONSchema, Schemas } from '@cp/base-types';
import { getCollectionNameFromSubjectUri } from '@cp/base-utils';
import { axiosDictionary, executeAggregationTemplate } from '@cpa/base-core/api';
import { dropRoutesCache, getLocationForBreadcrumb } from '@cpa/base-core/helpers';
import { IGlobalState, store } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import {
  Breadcrumb,
  DefaultButton,
  IBreadcrumbItem,
  IBreadcrumbStyleProps,
  IBreadcrumbStyles,
  IButtonProps,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuProps,
  IStyleFunctionOrObject,
} from '@fluentui/react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useMediaQuery } from 'react-responsive';
import urlJoin from 'url-join';

import { SingleItemContext } from '../../../../screens/GenericScreen/components/SingleItem/context';

import styles from './NavigationBreadcrumbs.module.scss';

const CHILD_LIMIT = 10;

interface INavigationBreadcrumbsProps {
  page: Schemas.CpaPage;
  currentItemTitle?: string;
  currentItemIdentifier?: string;
  schema?: IJSONSchema | null;
  customButtonClassName?: string;
}

function findParentPages(page: Schemas.CpaPage, allPages: Schemas.CpaPage[]): Schemas.CpaPage[] {
  if (!page.parentCpaPage?.identifier) {
    return [];
  }

  const parentPage = allPages.find(({ identifier }) => identifier && identifier === page.parentCpaPage?.identifier);
  if (!parentPage) {
    return [];
  }

  return [...findParentPages(parentPage, allPages), parentPage];
}

const getMenuItems = (page: Schemas.CpaPage, allPages: Schemas.CpaPage[]): IContextualMenuItem[] => {
  const children = allPages.filter((p) => {
    if (!p.parentCpaPage?.identifier) return false;
    if (!p.showInMenu) return false;
    if (!p.path && !allPages.some((childPage) => childPage?.parentCpaPage?.identifier === p.identifier)) return false;
    return page.identifier === p.parentCpaPage.identifier;
  });

  return children.map((child): IContextualMenuItem => {
    const nextChildren = allPages.filter((p) => {
      if (!p.parentCpaPage?.identifier) return false;
      return child.identifier === p.parentCpaPage.identifier;
    });
    const menuItem: IContextualMenuItem = {
      key: child.identifier!,
      text: child.name,
      iconProps: { iconName: child.icon },
      disabled: page.identifier === child.identifier,
      href: child.path ?? child.externalUrl,
      target: '_blank',
      subMenuProps: nextChildren.length
        ? {
            items: getMenuItems(child, allPages),
          }
        : undefined,
      onClick: (e) => {
        if (child.path) {
          e?.nativeEvent.preventDefault();
          store.dispatch(push(child.path));
        }
      },
    };
    if (nextChildren.length) {
      menuItem.subMenuProps = {
        items: getMenuItems(child, allPages),
      };
    }
    return menuItem;
  });
};

const getMenuProps = (page: Schemas.CpaPage, allPages: Schemas.CpaPage[]): IContextualMenuProps | undefined => {
  const menuItems = getMenuItems(page, allPages);
  if (!menuItems.length) {
    return undefined;
  }

  return {
    shouldFocusOnMount: true,
    items: menuItems,
  };
};

const OverflowButtonComponent = (
  props: IButtonProps,
  allPages: Schemas.CpaPage[],
  darkMode: boolean,
  parents: { name: string; identifier: string }[]
): JSX.Element => {
  const pages = props.menuProps?.items
    .map((item) => {
      const splittedKey = item.key.split(' ');
      const pageIdentifier = splittedKey[splittedKey.length - 1];
      const page = allPages.find((page) => page.identifier === pageIdentifier);
      return page || null;
    })
    .filter(Boolean);
  const overflowParents =
    props.menuProps?.items.filter((item) => {
      const matchingParent = parents.find((parent) => parent.identifier === item.key);
      return !!matchingParent;
    }) || [];
  const menuItems: IContextualMenuItem[] = pages
    ? pages.map((page: Schemas.CpaPage) => {
        const nextChildren = allPages.filter((p) => {
          if (!p.parentCpaPage?.identifier) return false;
          return page.identifier === p.parentCpaPage.identifier;
        });
        return {
          key: page.identifier!,
          text: page.name,
          iconProps: { iconName: page.icon },
          subMenuProps: nextChildren.length
            ? {
                items: getMenuItems(page, allPages),
              }
            : undefined,
          onClick: () => {
            if (page.path) {
              store.dispatch(push(page.path));
            }
          },
        };
      })
    : [];
  for (const parent of overflowParents) {
    menuItems.push({
      key: parent.key,
      text: parent.text,
      onClick: parent.onClick,
    });
  }
  return (
    <DefaultButton
      className={classNames({ [styles.breadcrumbButtonDark]: darkMode, [styles.breadcrumbButtonLight]: !darkMode })}
      iconProps={{ iconName: 'More' }}
      menuProps={{ items: menuItems }}
    />
  );
};

const breadcrumbButtonStyles: IButtonStyles = {
  label: { fontWeight: 400 },
};

const NavigationBreadcrumbs: React.FC<INavigationBreadcrumbsProps> = ({
  page,
  currentItemTitle,
  currentItemIdentifier,
  schema,
  customButtonClassName,
}) => {
  const currentLocation = useSelector((state: IGlobalState) => state.router.location);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const pages = useSelector((store: IGlobalState) => store.app.pages);
  const [parents, setParents] = useState<{ name: string; identifier: string }[]>([]);
  const [children, setChildren] = useState<{ name: string; identifier: string }[]>([]);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const singleItemContext = useContext(SingleItemContext);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const parentFilter = useMemo(() => {
    if (!page.breadcrumbHideTop) return null;
    const parentPath = schema?.cp_parentPropertyJsonPath;
    if (!parentPath) return null;
    const dataUrl = page.dataUrl;
    const url = new URL(dataUrl!, window.location.origin);
    const filter = url.searchParams.get('$filter');
    if (!filter) return null;
    return filter.includes(parentPath.replaceAll('.', '/')) ? filter : null;
  }, [page.breadcrumbHideTop, page.dataUrl, schema?.cp_parentPropertyJsonPath]);

  const currentLocationState = useMemo(
    () =>
      (currentLocation?.state || {}) as {
        originItem?: IDataItem;
        originPage?: Schemas.CpaPage;
        breadcrumbsStructure?: IDataItem[];
      },
    [currentLocation?.state]
  );

  const { ref: containerRef } = useResizeDetector({
    onResize: (width) => {
      setContainerWidth(width || 0);
    },
  });

  const isSmallScreen = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const isTouchDevice = useMediaQuery({
    query: '(hover: none)',
  });

  const getParents = useCallback(async () => {
    // Find parents here
    if (schema?.cp_parentPropertyJsonPath && page.cpTypeUrl && !currentLocationState.breadcrumbsStructure) {
      const data = await executeAggregationTemplate(page.dataEndpoint?.identifier || axiosDictionary.appDataService, page.cpTypeUrl, 'get-parents', {
        identifier: currentItemIdentifier,
        cpTypeUrl: getCollectionNameFromSubjectUri(page.cpTypeUrl),
        parentPropertyJsonPath: schema?.cp_parentPropertyJsonPath,
      });
      if (data?.[0]?.parents) {
        setParents(data[0].parents as { name: string; identifier: string }[]);
      }
    }
  }, [
    currentItemIdentifier,
    page.cpTypeUrl,
    page.dataEndpoint?.identifier,
    schema?.cp_parentPropertyJsonPath,
    currentLocationState.breadcrumbsStructure,
  ]);

  const getChildren = useCallback(async () => {
    // Find children here
    if (schema?.cp_parentPropertyJsonPath && page.cpTypeUrl && singleItemContext?.isSingleItem) {
      const data = await executeAggregationTemplate(page.dataEndpoint?.identifier || axiosDictionary.appDataService, page.cpTypeUrl, 'get-children', {
        identifier: currentItemIdentifier,
        cpTypeUrl: getCollectionNameFromSubjectUri(page.cpTypeUrl),
        parentPropertyJsonPath: schema?.cp_parentPropertyJsonPath,
        limit: CHILD_LIMIT + 1,
      });
      if (data) {
        setChildren(data as { name: string; identifier: string }[]);
      }
    }
  }, [currentItemIdentifier, page.cpTypeUrl, page.dataEndpoint?.identifier, schema?.cp_parentPropertyJsonPath, singleItemContext]);

  useEffect(() => {
    getParents();
    getChildren();
  }, [getParents, getChildren]);

  const breadcrumbs: IBreadcrumbItem[] = useMemo(() => {
    const baseBreadcrumbs: IBreadcrumbItem[] = [];
    const parentPages: Schemas.CpaPage[] = [];

    if (currentLocationState.originPage) {
      // Related page origin parents
      parentPages.push(...findParentPages(currentLocationState.originPage, pages), currentLocationState.originPage);
    } else if (!parentFilter) {
      // Normal page parents
      parentPages.push(...findParentPages(page, pages));
    }

    baseBreadcrumbs.push(
      ...parentPages.map((parentPage: Schemas.CpaPage) => {
        const menuProps = getMenuProps(parentPage, pages);
        const buttonClassName = classNames(customButtonClassName, {
          [styles.breadcrumbButtonDark]: darkMode,
          [styles.breadcrumbButtonLight]: !darkMode,
        });

        return {
          text: parentPage.name?.startsWith('_dictionary:') ? t(parentPage.name?.split(':')[1]) : parentPage.name,
          key: `_parent ${parentPage.identifier}`,
          onRenderContent: (item: IBreadcrumbItem | undefined) => {
            if (!item) return null;
            return <DefaultButton className={buttonClassName} styles={breadcrumbButtonStyles} text={item.text} menuProps={menuProps} />;
          },
          onClick: parentPage.path
            ? () => {
                if (parentPage.path) {
                  dispatch(push(parentPage.path.replace('/:id', '')));
                }
              }
            : undefined,
        };
      })
    );

    const buttonClassName = classNames(customButtonClassName, {
      [styles.breadcrumbButtonDark]: darkMode,
      [styles.breadcrumbButtonLight]: !darkMode,
    });
    // Origin page
    if (currentLocationState.originPage && currentLocationState.originItem) {
      baseBreadcrumbs.push({
        text: currentLocationState.originItem.name?.toString() || currentLocationState.originItem.identifier || '-',
        key: '_originItemTitle',
        onRenderContent: (item) => {
          if (!item) return null;
          return <DefaultButton className={buttonClassName} text={item.text} styles={breadcrumbButtonStyles} />;
        },
        onClick: () => {
          if (currentLocationState.originPage?.path && currentLocationState.originItem?.identifier) {
            dispatch(push(urlJoin(currentLocationState.originPage.path.replace('/:id', ''), currentLocationState.originItem.identifier)));
          }
        },
      });
    }

    // Current page
    baseBreadcrumbs.push({
      text: page.name,
      key: `_root ${page.identifier}`,
      onRenderContent: (props, defaultRender) => {
        return <div className={customButtonClassName}>{defaultRender?.(props)}</div>;
      },
      className: buttonClassName,
      onClick:
        page.path && (currentItemTitle || currentLocationState.breadcrumbsStructure?.length)
          ? () => {
              if (page.path) {
                dispatch(push(page.path.replace('/:id', '')));
              }
            }
          : undefined,
    });

    // Child breadcrumbs from page card view
    if (currentLocationState.breadcrumbsStructure) {
      baseBreadcrumbs.push(
        ...currentLocationState.breadcrumbsStructure.map((breadcrumbsStructureItem: IDataItem, index, breadcrumbsStructure) => ({
          text: breadcrumbsStructureItem.name?.toString() || breadcrumbsStructureItem.identifier || breadcrumbsStructureItem.__identifier || '-',
          key: `_breadcrumbsStructureItem ${breadcrumbsStructureItem.identifier || breadcrumbsStructureItem.__identifier}`,
          className: customButtonClassName,
          onRenderContent: (props: IBreadcrumbItem | undefined, defaultRender: (props: IBreadcrumbItem | undefined) => JSX.Element | null) => {
            return <div className={customButtonClassName}>{defaultRender?.(props)}</div>;
          },
          onClick:
            schema?.cp_parentPropertyJsonPath && (index < breadcrumbsStructure.length - 1 || currentItemTitle)
              ? () => {
                  const existingItemIndex = breadcrumbsStructure.findIndex(
                    (i) =>
                      i.identifier === breadcrumbsStructureItem.identifier ||
                      (i.__identifier && i.__identifier === breadcrumbsStructureItem.__identifier)
                  );

                  if (existingItemIndex === -1) {
                    return;
                  }

                  // Go back to a specific index
                  const updatedLocation = getLocationForBreadcrumb(page, schema, breadcrumbsStructure, existingItemIndex, currentLocation);
                  dispatch(push(updatedLocation));
                }
              : undefined,
        }))
      );
    }

    // Process parents here
    if (parents.length) {
      let parentsToProcess = parents;
      if (parentFilter) {
        let parentFound = false;
        parentsToProcess = parents
          .map((parent) => {
            if (parentFilter.includes(parent.identifier) || parentFound) {
              parentFound = true;
              return parent;
            }
            return null;
          })
          .filter(Boolean) as { name: string; identifier: string }[];
      }
      for (const parent of parentsToProcess) {
        baseBreadcrumbs.push({
          text: parent.name,
          key: parent.identifier,
          onRenderContent: (props: IBreadcrumbItem | undefined, defaultRender: (props: IBreadcrumbItem | undefined) => JSX.Element | null) => {
            return <div className={customButtonClassName}>{defaultRender?.(props)}</div>;
          },
          onClick: () => {
            if (currentItemIdentifier && page.path) {
              dispatch(push(page.path.replace(':id', encodeURIComponent(parent.identifier))));
            }
          },
        });
      }
    }

    if (currentItemTitle) {
      baseBreadcrumbs.push({
        text: currentItemTitle,
        key: '_currentItemTitle',
        onRenderContent: (props, defaultRender) => {
          if (!isSmallScreen)
            return (
              <div className={classNames(customButtonClassName, { [styles.currentItemTitle]: isTouchDevice })}>
                {defaultRender ? defaultRender(props) : null}
              </div>
            );
          const reservedWidth = children.length ? 110 : 50;
          return (
            <span
              className={classNames(customButtonClassName, styles.currentItemTitle)}
              style={{ width: containerWidth - reservedWidth, display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {props?.text}
            </span>
          );
        },
      });
    }

    // Process children here
    if (children.length) {
      baseBreadcrumbs.push({
        text: '',
        key: '_children',
        onRenderContent: () => {
          return (
            <div className={styles.childrenShowMore}>
              <DefaultButton
                className={classNames({ [styles.breadcrumbButtonDark]: darkMode, [styles.breadcrumbButtonLight]: !darkMode })}
                iconProps={{ iconName: 'More' }}
                menuProps={{
                  items: children
                    .map((child, index) => {
                      if (index > CHILD_LIMIT) return null;
                      if (index === CHILD_LIMIT) {
                        return {
                          key: '_showMoreChildren',
                          text: `${t('common.more')} ❯`,
                          className: styles.moreChildren,
                          onClick: () => {
                            const url =
                              page.path && currentItemIdentifier && schema?.cp_parentPropertyJsonPath
                                ? urlJoin(
                                    page.path.replace('/:id', ''),
                                    `?filter=${encodeURIComponent(JSON.stringify({ [schema.cp_parentPropertyJsonPath]: currentItemIdentifier }))}`
                                  )
                                : '';
                            dropRoutesCache().then(() => dispatch(push(url)));
                          },
                        };
                      }
                      return {
                        key: child.identifier,
                        text: child.name,
                        onClick: () => {
                          if (currentItemIdentifier && page.path) {
                            dispatch(push(page.path.replace(':id', encodeURIComponent(child.identifier))));
                          }
                        },
                      };
                    })
                    .filter(Boolean) as IContextualMenuItem[],
                }}
              />
            </div>
          );
        },
      });
    }

    return baseBreadcrumbs;
  }, [
    currentLocationState.originPage,
    currentLocationState.originItem,
    currentLocationState.breadcrumbsStructure,
    customButtonClassName,
    darkMode,
    page,
    currentItemTitle,
    parents,
    children,
    pages,
    t,
    dispatch,
    schema,
    currentLocation,
    currentItemIdentifier,
    isSmallScreen,
    isTouchDevice,
    containerWidth,
    parentFilter,
  ]);

  const breadcrumbsStyles: IStyleFunctionOrObject<IBreadcrumbStyleProps, IBreadcrumbStyles> = useMemo(
    () => ({
      item: {
        fontSize: isSmallScreen ? 14 : 24,
        fontWeight: '400 !important',
        maxHeight: 36,
      },
      itemLink: {
        fontSize: isSmallScreen ? 14 : 24,
        fontWeight: '400 !important',
        maxHeight: 36,
      },
      chevron: {
        marginTop: 5,
      },
    }),
    [isSmallScreen]
  );

  return (
    <div className={styles.breadcrumbsWrapper} ref={containerRef}>
      <Breadcrumb
        className={styles.breadcrumbs}
        items={breadcrumbs}
        styles={breadcrumbsStyles}
        overflowButtonAs={(props) => OverflowButtonComponent(props, pages, darkMode, parents)}
      />
    </div>
  );
};

export default NavigationBreadcrumbs;
