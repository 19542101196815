import { filterQueryKeyName, maxAmountOfCards } from '@cpa/base-core/constants';
import { dropRoutesCache, generateNewColumn, tableSort } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { IGenericComponentData, IDataItem, ITableProps, IOrderOptions } from '@cpa/base-core/types';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import { formatEntries, buildODataQuery } from '@cp/base-utils';

import GenericCard from '../GenericCard/GenericCard';

import FillCard from './components/FillCard/FillCard';
import styles from './WidgetCardView.module.scss';

export interface IWidgetCardViewProps extends Pick<ITableProps, 'isODataSupportedByEndpoint'> {
  data: Omit<IGenericComponentData, 'items'>;
  items: IDataItem[];
  onCardClick?: (item: IDataItem, forceTargetPagePath?: string) => unknown;
  pageCardView?: boolean;
  order?: IOrderOptions;
  onMoreClick?: () => void;
}

const MAX_WIDGET_CARD_COUNT = 18;

const WidgetCardView: React.FC<IWidgetCardViewProps> = ({
  data,
  items,
  onCardClick,
  pageCardView,
  order,
  isODataSupportedByEndpoint,
  onMoreClick,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const totalItems = useMemo(() => data.totalItems, [data.totalItems]);
  const pageSettings = useSelector((state: IGlobalState) => state.settings.pages[data.page.identifier!]);

  const widgetCardCount = useMemo(() => {
    return MAX_WIDGET_CARD_COUNT - (MAX_WIDGET_CARD_COUNT % (pageSettings?.amountOfColumns || 1)) - 1;
  }, [pageSettings?.amountOfColumns]);

  const isSmallScreen = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const columnAmount = useMemo(() => {
    return isSmallScreen ? 1 : pageSettings.amountOfColumns || data.page.maxNoOfCardsInSlider || maxAmountOfCards;
  }, [data.page.maxNoOfCardsInSlider, isSmallScreen, pageSettings.amountOfColumns]);

  const sortedItems = useMemo(() => {
    if (!order || !data.schema) {
      return items;
    }

    const column = generateNewColumn(order.columnKey, data.schema);

    return tableSort(items, {
      column: order.columnKey,
      isDesc: !order.isAscending,
      type: column.type || 'string',
      propertySchema: column.propertySchema,
      isODataSupportedByEndpoint: isODataSupportedByEndpoint,
    });
  }, [data.schema, items, order, isODataSupportedByEndpoint]);

  const groups = useMemo(() => {
    if (totalItems && items.length > widgetCardCount) {
      return _.chunk(
        totalItems > widgetCardCount ? [...sortedItems.slice(0, widgetCardCount), { _itemType: 'fillcard' }] : sortedItems,
        columnAmount
      );
    } else {
      return _.chunk(
        sortedItems.length > widgetCardCount ? [...sortedItems.slice(0, widgetCardCount), { _itemType: 'fillcard' }] : sortedItems,
        columnAmount
      );
    }
  }, [columnAmount, items.length, sortedItems, totalItems, widgetCardCount]);

  const renderDots = useCallback(
    (onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean, index: number, label: string) => {
      return (
        <li
          className={styles.dotWrapper}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role={'button'}
          tabIndex={0}
          aria-label={`${label} ${index + 1}`}
        >
          <span
            className={classNames({
              [styles.selectedDot]: isSelected && !darkMode,
              [styles.selectedDotDark]: isSelected && darkMode,
              [styles.dot]: !isSelected && !darkMode,
              [styles.dotDark]: !isSelected && darkMode,
            })}
          />
        </li>
      );
    },
    [darkMode]
  );

  const dispatch = useDispatch();

  const handleCardClick = useCallback(
    (item: IDataItem, isFolderItem?: boolean): void => {
      const targetPagePath = data.page.widgetRedirectPath || data.page.path;

      if (isFolderItem && targetPagePath && data.schema?.cp_parentPropertyJsonPath && item && (item.identifier || item.__identifier)) {
        const redirectPath = targetPagePath.replace('/:id', '');
        const parentPropertyJsonPath = data.schema.cp_parentPropertyJsonPath;

        dropRoutesCache().then(() =>
          dispatch(
            pageCardView
              ? // If page has page view we should open item's children
                push(redirectPath, {
                  breadcrumbsStructure: [item],
                  externalDataQuery: {
                    $filter: new URLSearchParams(
                      buildODataQuery({
                        filter: formatEntries({
                          [parentPropertyJsonPath]: item.identifier || item.__identifier,
                        }) as object,
                      })
                    ).get('$filter'),
                  },
                })
              : // If page has table view we filter for the item
                push({
                  search: `${filterQueryKeyName}=${encodeURIComponent(JSON.stringify({ identifier: item.identifier || item.__identifier }))}`,
                  pathname: redirectPath,
                })
          )
        );
        return;
      }

      onCardClick?.(item, data.page.widgetRedirectPath);
    },
    [data.page.path, data.page.widgetRedirectPath, data.schema?.cp_parentPropertyJsonPath, dispatch, onCardClick, pageCardView]
  );

  const carouselContent = useMemo(() => {
    return groups.map((group: IDataItem[], index: number) => (
      <div
        key={index}
        data-testid="WidgetCardView__card"
        className={styles.container}
        style={{ gridTemplateColumns: new Array(columnAmount).fill('1fr').join(' ') }}
      >
        {group.map((item, groupIndex) => {
          if (item && item._itemType === 'fillcard') {
            return (
              <FillCard
                key={groupIndex}
                page={data.page}
                remainingItems={totalItems ? totalItems - widgetCardCount : sortedItems.length - widgetCardCount}
                onMoreClick={onMoreClick}
              />
            );
          }

          return <GenericCard key={groupIndex} data={data} item={item} onClick={handleCardClick} hideSelectionMarker={true} />;
        })}
      </div>
    ));
  }, [groups, columnAmount, data, handleCardClick, totalItems, widgetCardCount, sortedItems.length]);

  const carousel = useMemo(() => {
    // Carousel clones and mounts content several for internal reasons
    // https://github.com/maxmarinich/react-alice-carousel/issues/54
    return (
      <Carousel showThumbs={false} autoPlay={true} showStatus={false} renderIndicator={renderDots} infiniteLoop={true} interval={columnAmount * 2500}>
        {carouselContent}
      </Carousel>
    );
  }, [carouselContent, renderDots, columnAmount]);

  return (
    <div data-testid="WidgetCardView" className={darkMode ? styles.viewDark : styles.view}>
      {carousel}
    </div>
  );
};

export default WidgetCardView;
