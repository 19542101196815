import { IJSONSchema, IRelatedLink, Schemas } from '@cp/base-types';
import * as _ from 'lodash';
import { DataServiceModules, escapeIllegalOdataValueChars, formatRelationSubjectUri, shortenSubjectUri } from '@cp/base-utils';
import { IFilter, Operator } from '@cp/base-utils';

import { IDataItem, IFieldInSchema, CustomColumn, IOrderOptions } from '../../types';
import { findFieldInSchema } from '../schema';
import { store } from '../../store';

import { isValid } from './ajv';
import { isDefined } from './data';
import { cloneValueAndCleanUpInternalProperties } from './form';

interface IOptions {
  column: string;
  isDesc: boolean;
  type: string;
  groupPropertyJsonPath?: string;
  propertySchema?: IJSONSchema;
  isODataSupportedByEndpoint?: boolean;
}

export const splitByGroup = (items: IDataItem[], groupPropertyJsonPath: string): [IDataItem[0], IDataItem[]][] => {
  const indexesMap = new Map<IDataItem[0], number>();
  const values: [IDataItem[0], IDataItem[]][] = [];
  let indexCounter = 0;

  items.forEach((item) => {
    const value = _.get(item, groupPropertyJsonPath);

    if (indexesMap.has(value)) {
      values[indexesMap.get(value) as number][1]?.push(item);
    } else {
      indexesMap.set(value, indexCounter);
      values[indexCounter] = [value, [item]];
      indexCounter++;
    }
  });

  return values;
};

const defaultCompare = (a: unknown, b: unknown): number => {
  if ((!isDefined(a) && isDefined(b)) || (!Number.isNaN(a) && Number.isNaN(b))) {
    return 1;
  } else if ((!isDefined(b) && isDefined(a)) || (!Number.isNaN(b) && Number.isNaN(a))) {
    return -1;
  } else if (a === b) {
    return 0;
  } else {
    return (a as number) > (b as number) ? 1 : -1;
  }
};

const sortItems = <T extends IDataItem>(a: T, b: T, options: IOptions): number => {
  const { column, isDesc, type, propertySchema, isODataSupportedByEndpoint } = options;

  let updatedA = a[column];
  let updatedB = b[column];

  if (type === 'number' || type === 'integer') {
    updatedA = parseFloat(a[column] as string);
    updatedB = parseFloat(b[column] as string);
  }

  // Handle columns with format 'cp:monetaryAmount'
  if (type === 'cp:monetaryAmount') {
    updatedA = (a[column] as { value: number })?.value;
    updatedB = (b[column] as { value: number })?.value;
  }

  if (typeof updatedA === 'string') {
    if (isODataSupportedByEndpoint === false && propertySchema && propertySchema.enum && propertySchema.enumNames) {
      const enumIndex = propertySchema.enum.findIndex((s) => s === updatedA);
      if (enumIndex !== -1) {
        updatedA = propertySchema.enumNames[enumIndex]?.toLowerCase();
      }
    } else {
      updatedA = updatedA.toLowerCase();
    }
  }

  if (typeof updatedB === 'string') {
    if (isODataSupportedByEndpoint === false && propertySchema && propertySchema.enum && propertySchema.enumNames) {
      const enumIndex = propertySchema.enum.findIndex((s) => s === updatedB);
      if (enumIndex !== -1) {
        updatedB = propertySchema.enumNames[enumIndex]?.toLowerCase();
      }
    } else {
      updatedB = updatedB.toLowerCase();
    }
  }

  return (isDesc ? 1 : -1) * defaultCompare(updatedB, updatedA);
};

export const tableSort = (items: IDataItem[], options: IOptions): IDataItem[] => {
  if (options.groupPropertyJsonPath) {
    return Array.from(splitByGroup(items, options.groupPropertyJsonPath).map((group) => group[1])).flatMap((value) =>
      value.sort((a, b) => sortItems(a, b, options))
    );
  }

  return [...items].sort((a, b) => sortItems(a, b, options));
};

export function findMatchingAnyOf(options: IJSONSchema[], item?: IDataItem[0]): IJSONSchema | null {
  if (!options || !options.length) {
    return null;
  }

  if (!item) {
    return null;
  }

  if (typeof item === 'object' && !Array.isArray(item) && item._type) {
    for (const option of options) {
      if (option.$id && item._type === option.$id) {
        return option;
      }
    }
  }

  const validOption = options.find((option) => isValid(option as IJSONSchema, item));
  if (validOption) {
    return validOption;
  }

  // Fallback to best fitting schema option based on keys
  let maxKeysAmount = 0;
  const itemKeys = new Set(Object.keys(item));
  return options.reduce((acc, option) => {
    const matchedKeysAmount = Object.keys(option.properties || {}).filter((schemaKey) => itemKeys.has(schemaKey)).length;
    if (matchedKeysAmount > maxKeysAmount) {
      maxKeysAmount = matchedKeysAmount;
      return option;
    }
    return acc;
  }, options[0]);
}

export const prepareSchema = (schema: IJSONSchema | undefined, value?: IDataItem[0]): IJSONSchema | undefined => {
  if (schema && schema.anyOf && schema.anyOf.length) {
    const matchedOption = findMatchingAnyOf(schema.anyOf, value) as IJSONSchema | null;
    if (!matchedOption) {
      return schema;
    }

    return { ...matchedOption, title: schema.title || matchedOption.title };
  } else {
    return schema;
  }
};

function findType({ value: field }: IFieldInSchema): string | undefined {
  if (!field) {
    return;
  }

  if (field.oneOf && Array.isArray(field.oneOf) && field.oneOf.length) {
    return findType({ value: field.oneOf[0] as IJSONSchema });
  }

  if (field.anyOf && Array.isArray(field.anyOf) && field.anyOf.length) {
    return findType({ value: field.anyOf[0] as IJSONSchema });
  }

  if (field.allOf && Array.isArray(field.allOf) && field.allOf.length) {
    return findType({ value: field.allOf[0] as IJSONSchema });
  }

  if (field.format) {
    return field.format;
  }

  return field.type && field.type.toString();
}

const getKeysFromSchema = (schema: IJSONSchema, a: CustomColumn, b: CustomColumn): number => {
  let keysFromSchema: string[] = [];

  const anyOf = schema.anyOf as IJSONSchema[] | undefined;
  const allOf = schema.allOf as IJSONSchema[] | undefined;

  if (schema.properties) {
    keysFromSchema = Object.keys(schema.properties);
  } else if (anyOf?.[0].properties) {
    keysFromSchema = Object.keys(anyOf[0].properties);
  } else if (allOf?.[0].properties) {
    keysFromSchema = Object.keys(allOf[0].properties);
  }

  if (keysFromSchema.length) {
    const firstColIndex = keysFromSchema.findIndex((v) => v === a.fieldName);
    if (firstColIndex === -1) {
      return 1;
    }

    const secondColIndex = keysFromSchema.findIndex((v) => v === b.fieldName);
    if (secondColIndex === -1) {
      return -1;
    }
    return firstColIndex - secondColIndex;
  }

  return 0;
};

const getUniqueValues = <T extends string[]>(a: T, b: T): string[] => {
  const bMap = b.reduce((acc, curr) => ({ ...acc, [curr]: true }), {} as Record<string, boolean>);
  return a.filter((keyA) => !bMap[keyA]);
};

const sortItemsWithExisting = (schema: IJSONSchema, items: CustomColumn[], firstColumns: string[] = [], lastColumn?: string): CustomColumn[] =>
  [...items].sort((a, b) => {
    const colsInFirstPlaces = [firstColumns.findIndex((v) => v === a.key), firstColumns.findIndex((v) => v === b.key)];
    if (colsInFirstPlaces[0] !== -1 && colsInFirstPlaces[1] !== -1) {
      return colsInFirstPlaces[0] - colsInFirstPlaces[1];
    }

    if (colsInFirstPlaces[0] !== -1) {
      return -1;
    } else if (colsInFirstPlaces[1] !== -1) {
      return 1;
    }

    if (a.key === lastColumn) {
      return 1;
    } else if (b.key === lastColumn) {
      return -1;
    }

    return getKeysFromSchema(schema, a, b);
  });

export const generateNewColumn = (
  key: string,
  schema: IJSONSchema,
  orderOptions?: IOrderOptions,
  isGrouped?: boolean,
  isFiltered?: boolean
): CustomColumn => {
  const fieldInSchema: IFieldInSchema = { value: null };
  findFieldInSchema(schema.properties ?? {}, key, fieldInSchema);
  if (fieldInSchema.value === null) {
    findFieldInSchema(schema.dependencies ?? {}, key, fieldInSchema);
  }

  return {
    key,
    fieldName: key,
    name: fieldInSchema.value ? fieldInSchema.value.title || key.substr(0, 1).toUpperCase() + key.substr(1).toLowerCase() : `${key} ꜝ`,
    isRowHeader: true,
    isResizable: true,
    isPadded: false,
    type: findType(fieldInSchema) as string,
    isSorted: orderOptions ? key === orderOptions.columnKey : false,
    isSortedDescending: orderOptions ? !orderOptions.isAscending : false,
    minWidth: key === 'name' ? 300 : 100,
    isGrouped: isGrouped,
    isFiltered: isFiltered,
    propertySchema: fieldInSchema.value as IJSONSchema | undefined,
  };
};

/**
 *  isHiddenFirstPart - validate if key's first part(before point) matches hiddenKeys
 *  isHiddenFullPath - validate if key's full path matches hiddenKeys
 *  isMatchValidationPattern - validate if key is matching patternProperties
 */
export enum KeyValidationOptions {
  isHiddenFirstPart = 'isHiddenFirstPart',
  isHiddenFullPath = 'isHiddenFullPath',
  isMatchValidationPattern = 'isMatchValidationPattern',
}

// Used together with getAllSchemaPaths in order to hide underscored and HiddenInTable properties
export const validateKeyFactory = (
  schema: IJSONSchema,
  hiddenKeys: string[],
  conditionsToCheck: KeyValidationOptions[]
): ((key: string) => boolean) => {
  const hiddenFirstPart = hiddenKeys.reduce<Record<string, true>>(
    (acc, curr) => ({
      ...acc,
      [_.toPath(curr)[0]]: true,
    }),
    {}
  );
  const hiddenFullPath = hiddenKeys.reduce<Record<string, true>>((acc, curr) => ({ ...acc, [curr]: true }), {});

  const validate = (key: string): boolean => {
    const { patternProperties } = _.get(schema, _.toPath(key).join('.properties.'), {}) as IJSONSchema;
    const [validationString] = Object.keys(patternProperties ?? []);
    const isHiddenFirstPart = hiddenFirstPart[key];
    const isHiddenFullPath = hiddenFullPath[key];
    const isMatchValidationPattern = !!validationString && key?.match(validationString);

    const validationMap = {
      [KeyValidationOptions.isHiddenFirstPart]: isHiddenFirstPart,
      [KeyValidationOptions.isHiddenFullPath]: isHiddenFullPath,
      [KeyValidationOptions.isMatchValidationPattern]: isMatchValidationPattern,
    };

    return conditionsToCheck.reduce((acc, option) => acc && !validationMap[option], true);
  };
  return validate;
};

export const getItemsKeys = (items: IDataItem[], schema: IJSONSchema): string[] => {
  const combinedItem = cloneValueAndCleanUpInternalProperties(
    items.reduce((acc, next) => _.mergeWith(acc, next, (objValue: unknown, srcValue: unknown): unknown => objValue || srcValue), {}),
    schema
  );
  return Object.entries(combinedItem)
    .map(([key, value]) => {
      if (value === null || value === undefined) {
        return null;
      }
      if (typeof value === 'object' && !Array.isArray(value)) {
        const valueWithoutInternalProperties: IDataItem | IDataItem[] = cloneValueAndCleanUpInternalProperties(value, schema);
        if (
          !Object.keys(valueWithoutInternalProperties).length ||
          Object.values(valueWithoutInternalProperties).every((v) => v === undefined || v === null)
        ) {
          return null;
        }
      }
      return key;
    })
    .filter(Boolean) as string[];
};

export const _generateTableHeader = ({
  schema,
  orderOptions,
  keys,
  sortedItemKeys,
  spanColumn,
  previousColumns = [],
  ignoredColumns = [],
  multilineFields = [],
  groupPropertyJsonPath,
  parsedFilters,
}: {
  schema: IJSONSchema;
  orderOptions?: IOrderOptions;
  keys: string[];
  sortedItemKeys?: string[];
  previousColumns?: CustomColumn[];
  spanColumn?: string;
  ignoredColumns?: string[];
  multilineFields?: string[];
  groupPropertyJsonPath?: string;
  parsedFilters?: IFilter[];
}): CustomColumn[] => {
  if (!schema) {
    return [];
  }
  const tableKeyValidator = validateKeyFactory(schema, ignoredColumns, [
    KeyValidationOptions.isHiddenFirstPart,
    KeyValidationOptions.isMatchValidationPattern,
  ]);
  const multilineFieldsMap = multilineFields.reduce<Record<string, boolean>>(
    (acc, filed) => ({
      ...acc,
      [filed]: true,
    }),
    {}
  );
  const spanColumnValue = spanColumn?.split(',') || [];
  if (!spanColumnValue.includes('name')) {
    spanColumnValue.push('name');
  }
  const firstColumns = sortedItemKeys?.length ? sortedItemKeys : spanColumnValue;
  const lastColumn = !firstColumns?.includes('identifier') ? 'identifier' : undefined;
  const previousColumnsKeys = Array.from(previousColumns.reduce((acc, { key }) => acc.add(key), new Set<string>()));
  const newColumns: CustomColumn[] = [];

  const newColumnsKeys = getUniqueValues(keys, previousColumnsKeys);

  const filteredColumns = new Set(
    (parsedFilters || [])
      .map((parsedFilter) =>
        Object.entries({ ...(parsedFilter?.entries?.[Operator.EQUALS] || {}), ...(parsedFilter?.entries?.[Operator.CONTAINS] || {}) }).map(
          ([path, filterValue]) => {
            if (!filterValue || (Array.isArray(filterValue) && !filterValue.length)) {
              return null;
            }
            return _.toPath(path)[0];
          }
        )
      )
      .flat(1)
      .filter(Boolean) as string[]
  );
  for (const key of newColumnsKeys) {
    if (!tableKeyValidator(key)) {
      continue;
    }

    const newColumn = generateNewColumn(
      key,
      schema,
      orderOptions,
      !!groupPropertyJsonPath && key === _.toPath(groupPropertyJsonPath)[0],
      filteredColumns.has(key)
    );
    newColumn.isMultiline = multilineFieldsMap[newColumn.key];
    newColumns[newColumn.type === 'file' ? 'unshift' : 'push'](newColumn);
  }

  const sortedNewItems = sortItemsWithExisting(schema, newColumns, firstColumns, lastColumn);

  return [
    ...previousColumns.map((previousColumn) => ({
      ...previousColumn,
      isFiltered: filteredColumns.has(previousColumn.key),
    })),
    ...sortedNewItems,
  ];
};

export const generateTableHeader = _.memoize(_generateTableHeader) as typeof _generateTableHeader;

export const filterSchemaKey = (schema: IJSONSchema, keyParts: string[]): void => {
  while (keyParts.length > 1) {
    schema = schema?.properties?.[keyParts.shift() as string] as IJSONSchema;
  }
  delete schema.properties?.[keyParts[0]];
  schema.required = schema.required?.filter((field) => field !== keyParts[0]);

  if (!Object.keys(schema.properties ?? {}).length) {
    schema = {};
  }
};

export const removeVoidSubSchemasRecursive = (schema: IJSONSchema): void => {
  for (const key of Object.keys(schema.properties ?? {})) {
    if ((schema.properties?.[key] as IJSONSchema)?.properties && !Object.keys((schema.properties?.[key] as IJSONSchema)?.properties ?? {}).length) {
      delete schema.properties?.[key];
      schema.required = schema.required?.filter((prop) => prop !== key);
    } else {
      removeVoidSubSchemasRecursive((schema.properties?.[key] ?? {}) as IJSONSchema);
    }
  }
};

export function filterSchema(
  schema: IJSONSchema,
  ignoredSchemaProperties: string[] = [],
  options: { filterVoidProperties?: boolean } = {}
): IJSONSchema {
  const clonedSchema = _.cloneDeep(schema);

  ignoredSchemaProperties.forEach((key) => {
    filterSchemaKey(clonedSchema, _.toPath(key));
  });

  if (options.filterVoidProperties) {
    removeVoidSubSchemasRecursive(clonedSchema);
  }

  return { ...clonedSchema };
}

export interface IMatchedPage {
  matched: Schemas.CpaPage;
  fullMatch: boolean;
  originalQuery?: string;
}

export interface IMatchedRelatedLink extends IRelatedLink, IMatchedPage {}

export interface IMatchedRelatedLinkStructure {
  [key: string]: { page: Schemas.CpaPage; links: IMatchedRelatedLink[] };
}

function comparePagePath(path: string, page: Schemas.CpaPage): boolean {
  if (!page.dataUrl) {
    return false;
  }
  const pagePathOnlyHref = decodeURI(page.dataUrl).split('?')[0];

  if (pagePathOnlyHref === path) {
    return true;
  }

  const pagePathOnlyHrefTrimmed = pagePathOnlyHref.startsWith('/') ? pagePathOnlyHref.slice(1) : pagePathOnlyHref;

  return pagePathOnlyHrefTrimmed === path;
}

const dataStorePrefix = `${DataServiceModules.DATA_STORE}/`;

function extractSubjectUriFromDataUrl(url: string): string {
  return shortenSubjectUri(decodeURIComponent(url.slice(dataStorePrefix.length)), store.getState().app.prefixMap).split('?')[0];
}

function prepareShortDataUrl(url: string): string {
  try {
    return url.startsWith(dataStorePrefix) ? dataStorePrefix + encodeURIComponent(extractSubjectUriFromDataUrl(url)) : url;
  } catch (e) {
    return url;
  }
}

export function getMatchingPageByDataUrl(url: string, pages: Schemas.CpaPage[], matchAll: true): IMatchedPage[];
export function getMatchingPageByDataUrl(url: string, pages: Schemas.CpaPage[], matchAll?: false): IMatchedPage | undefined;
export function getMatchingPageByDataUrl(url: string, pages: Schemas.CpaPage[], matchAll?: boolean): IMatchedPage[] | IMatchedPage | undefined {
  try {
    const originalUrl = url.startsWith('/') ? url.slice(1) : url;
    const fullHref = decodeURI(originalUrl);

    // Full Match
    const fullMatched = pages.find((page) => {
      if (!page.dataUrl) {
        return false;
      }
      const pageHref = decodeURI(page.dataUrl.startsWith('/') ? page.dataUrl.slice(1) : page.dataUrl);
      return pageHref === fullHref;
    });
    if (fullMatched) {
      const match = { matched: fullMatched, fullMatch: true };
      return matchAll ? [match] : match;
    }

    // Path Match
    const pathOnlyHref = fullHref.split('?')[0];
    const originalQuery = decodeURIComponent(originalUrl).split('?')[1] || '';

    const pathOnlyHrefAlternative = prepareShortDataUrl(pathOnlyHref);

    if (matchAll) {
      return pages
        .filter((page) => comparePagePath(pathOnlyHref, page) || comparePagePath(pathOnlyHrefAlternative, page))
        .map((pathMatched) => ({ matched: pathMatched, fullMatch: false, originalQuery }));
    }

    const pathMatched = pages.find((page) => comparePagePath(pathOnlyHref, page) || comparePagePath(pathOnlyHrefAlternative, page));
    if (pathMatched) {
      return { matched: pathMatched, fullMatch: false, originalQuery };
    }

    return undefined;
  } catch (e) {
    console.error(`Failed to parse url "${url}".`, e);
    return matchAll ? [] : undefined;
  }
}

export const checkIfRelatedLinkExist = (value: string, schema?: IJSONSchema): boolean => {
  if (schema?.links) {
    return schema.links
      .filter((link) => link?.rel === 'related')
      .some((link) => {
        const subjectUri = extractSubjectUriFromDataUrl(link.href);
        return subjectUri === value;
      });
  }
  return false;
};

export const getRelatedFromSchema = (
  selectedIdentifier: string,
  pages: Schemas.CpaPage[],
  schema: IJSONSchema,
  cpTypeUrl: string
): IMatchedRelatedLinkStructure => {
  if (schema.links) {
    return schema.links
      .filter((link: IRelatedLink) => link.rel === 'related')
      .reduce<IMatchedRelatedLinkStructure>((acc, link: IRelatedLink) => {
        const matchedPages = getMatchingPageByDataUrl(
          link.href
            .replace(/{identifier}/g, encodeURIComponent(escapeIllegalOdataValueChars(selectedIdentifier).replace(/'/g, "''")))
            .replace(/{type}/g, encodeURIComponent(escapeIllegalOdataValueChars(formatRelationSubjectUri(cpTypeUrl)).replace(/'/g, "''"))),
          pages,
          true
        );

        for (const matchedPage of matchedPages) {
          if (!matchedPage.matched.identifier || (!matchedPage.matched.path && !matchedPage.matched.displayRelatedPageAsDrawer)) {
            continue;
          }

          if (matchedPage.matched.identifier in acc) {
            acc[matchedPage.matched.identifier].links.push({ ...link, ...matchedPage });
          } else {
            acc[matchedPage.matched.identifier] = {
              page: matchedPage.matched,
              links: [{ ...link, ...matchedPage }],
            };
          }
        }

        return acc;
      }, {});
  }
  return {};
};

export interface ITableDataSerializer<T> {
  serialize: (data: T) => void;
  deserialize: () => T;
}

export class TableDataSerializer<T> implements ITableDataSerializer<T> {
  constructor(private readonly key: string, private readonly storage: Storage = localStorage) {}

  public serialize(data: T): void {
    this.storage.setItem(this.key, JSON.stringify(data));
  }

  public deserialize(): T {
    const derivedData = this.storage.getItem(this.key);
    return derivedData ? JSON.parse(derivedData) : [];
  }
}

export const resolveItemDownloadUrls = (
  items: IDataItem[],
  downloadableFilePropertyJsonPaths: {
    propertyJsonPath: string;
  }[]
): string[] => {
  return items
    .map((item) => {
      return downloadableFilePropertyJsonPaths.map((downloadableFilePropertyJsonPath) => {
        const downloadableValue = _.get(item, downloadableFilePropertyJsonPath.propertyJsonPath);

        if (Array.isArray(downloadableValue)) {
          return (downloadableValue as unknown[]).filter(
            (downloadableValueItem) => downloadableValueItem && typeof downloadableValueItem === 'string'
          ) as string[];
        }

        if (!downloadableValue || typeof downloadableValue !== 'string') {
          return [];
        }

        return [downloadableValue];
      });
    })
    .flat(2);
};
