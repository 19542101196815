import { BaseApi, IDataItem } from '@cpa/base-core/types';
import React from 'react';
import { getEndpoint } from '@cpa/base-core/api';
import { Schemas } from '@cp/base-types';

import ExternalKpiTile from './components/ExternalKpiTile/ExternalKpiTile';
import KpiTile from './components/KpiTile/KpiTile';
import KpiView from './components/KpiView/KpiView';

export interface IKpiRenderProps {
  value: string | null;
  name: string;
  isFetching: boolean;
  errors: string[];
  redirectPath?: string;
  identifier: string;
}

export interface IKpiProps {
  page: Schemas.CpaPage;
  kpi: Extract<Schemas.CpaPage['kpis'], {}>[number];
  lastUpdate?: number;
  items?: IDataItem[];
  clickable?: boolean;
  component?: React.ComponentType<IKpiRenderProps>;
}

const defaultItems: IDataItem[] = [];
const Kpi: React.FC<IKpiProps> = ({ page, kpi, lastUpdate, items, clickable = true, component: Component = KpiView }) => {
  if (page.dataEndpoint?.identifier && getEndpoint(page.dataEndpoint.identifier)?.dataType === BaseApi.DataService) {
    return (
      <ExternalKpiTile
        dataEndpointIdentifier={page.dataEndpoint!.identifier}
        redirectPath={clickable ? page.path : undefined}
        kpi={kpi}
        page={page}
        lastUpdate={lastUpdate}
      >
        {(props) => {
          return <Component {...props} />;
        }}
      </ExternalKpiTile>
    );
  }

  return (
    <KpiTile redirectPath={clickable ? page.path : undefined} kpi={kpi} items={items || defaultItems} isFetching={!Array.isArray(items)}>
      {(props) => {
        return <Component {...props} />;
      }}
    </KpiTile>
  );
};

export default Kpi;
