import { IJSONSchema } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { TextField } from '@fluentui/react';
import Form, { Registry, WidgetProps } from '@rjsf/core';
import * as _ from 'lodash';
import React, { lazy, Suspense, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getIntermediateAnyOfSchema, isRelationSchema } from '@cp/base-utils';
import { FormContext, PathContext } from '@cpa/base-core/constants';

import HtmlContent from '../../../HtmlContent/HtmlContent';
import LoadingArea from '../../../LoadingArea/LoadingArea';
import { ParentObjectContext } from '../ObjectFieldTemplate/ObjectFieldTemplate';
import DescriptionField from '../DescriptionField/DescriptionField';
import TitleField, { TitleFieldType } from '../TitleField/TitleField';
import DateTimeWidget from '../DateTimeWidget/DateTimeWidget';
import CheckboxWidget from '../CheckboxWidget/CheckboxWidget';
import { useSchemaOverwritesStore } from '../../stores/SchemaOverwrites';

import Color from './components/Color/Color';
import Rating from './components/Rating/Rating';
import Relation from './components/Relation/Relation';
import Suggestions from './components/Suggestions/Suggestions';
import Voting from './components/Voting/Voting';
import styles from './TextWidget.module.scss';
import Filter from './components/Filter/Filter';
import PropertySelector from './components/PropertySelector/PropertySelector';

const Code = lazy(() => import('./components/Code/Code'));
const Html = lazy(() => import('./components/Html/Html'));
const Bpmn = lazy(() => import('../BpmnEditorDialog/BpmnEditorDialog'));

const allowedProps = [
  'multiline',
  'resizable',
  'autoAdjustHeight',
  'underlined',
  'borderless',
  'label',
  'onRenderLabel',
  'description',
  'onRenderDescription',
  'prefix',
  'suffix',
  'onRenderPrefix',
  'onRenderSuffix',
  'iconProps',
  'defaultValue',
  'value',
  'disabled',
  'readOnly',
  'errorMessage',
  'onChange',
  'onNotifyValidationResult',
  'onGetErrorMessage',
  'deferredValidationTime',
  'className',
  'inputClassName',
  'ariaLabel',
  'validateOnFocusIn',
  'validateOnFocusOut',
  'validateOnLoad',
  'theme',
  'styles',
  'autoComplete',
  'mask',
  'maskChar',
  'maskFormat',
  'type',
];

const checkboxOptions = { props: { boxSide: 'end' } };

export interface TextWidgetProps extends WidgetProps {
  schema: IJSONSchema;
  onBlur: (fieldId: string, fieldValue: string, customOptions?: object) => void;
  registry: Registry & {
    rootSchema?: IJSONSchema;
    rootFormData?: IDataItem;
    formRef?: Form<unknown>;
  };
}

const TextWidget: React.FC<TextWidgetProps> = (props) => {
  const pathContext = useContext(PathContext);
  const formContext = useContext(FormContext);
  const parentObjectContext = useContext(ParentObjectContext);
  const { parentSchema, parentValue } = parentObjectContext || {};
  const {
    id,
    required,
    readonly,
    disabled,
    label,
    value,
    onChange,
    onBlur,
    onFocus,
    autofocus,
    options,
    schema: baseSchema,
    rawErrors,
    placeholder,
    registry,
    uiSchema,
  } = props;

  const schemaOverwrite = useSchemaOverwritesStore((state) => state.schemaOverwrites[pathContext]);

  const schema = useMemo(() => {
    if (!schemaOverwrite) {
      return baseSchema;
    }

    return _.merge({}, baseSchema, schemaOverwrite);
  }, [baseSchema, schemaOverwrite]);

  const [t, i18n] = useTranslation();
  const allLocales = useSelector((state: IGlobalState) => state.app.allLocales);
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const placeholderWithLanguageHint = useMemo(() => {
    if (!schema.cp_localizable && !uiSchema?.isLocalizableArray) {
      return placeholder;
    }

    const currentDataLanguage = dataLanguage && dataLanguage !== 'notSelected' ? dataLanguage : i18n.language;
    const matchedLocale = allLocales.find(({ identifier }) => identifier === currentDataLanguage);
    return placeholder || t('common.languageFormHint', { language: matchedLocale?.name || currentDataLanguage });
  }, [allLocales, dataLanguage, i18n.language, placeholder, schema.cp_localizable, t, uiSchema?.isLocalizableArray]);

  const onAfterBlur = useCallback(
    async (blurId: string, blurValue: string | number | boolean | null, item?: object) => {
      onBlur(blurId, blurValue as string, { fieldItem: item, schema: parentSchema || schema });
    },
    [onBlur, parentSchema, schema]
  );

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>): void => onChange(e.target.value === '' ? options.emptyValue : e.target.value);
  const _onBlur = (e: React.FocusEvent<HTMLInputElement>): Promise<void> => onAfterBlur(id, e.target.value);
  const _onFocus = (e: React.FocusEvent<HTMLInputElement>): void => onFocus(id, e.target.value);

  const uiProps = _.pick(options.props || {}, allowedProps) as Record<string, unknown>;

  const links = useMemo(() => {
    const links = [];
    // AnyOf case handling
    if (pathContext && parentSchema) {
      const intermediateAnyOfSchema = getIntermediateAnyOfSchema(pathContext, parentSchema, schema);
      if (intermediateAnyOfSchema && intermediateAnyOfSchema.links) {
        links.push(...intermediateAnyOfSchema.links);
      }
    }

    if (parentSchema && Array.isArray(parentSchema.links)) {
      links.push(...parentSchema.links);
    }

    if (Array.isArray(schema?.links)) {
      links.push(...schema.links);
    }

    return links;
  }, [parentSchema, pathContext, schema.links, schema.type]);

  let customInputElement: JSX.Element | null = null;
  if (links.length) {
    if (
      id.endsWith('_identifier') &&
      parentSchema &&
      isRelationSchema(parentSchema) &&
      !(id === 'root_identifier' && registry.rootSchema === parentSchema)
    ) {
      const lookupLink = links.find((link) => link.rel === 'collection');
      if (lookupLink) {
        customInputElement = (
          <Relation
            {...props}
            placeholder={placeholderWithLanguageHint}
            lookupLink={lookupLink}
            uiProps={uiProps}
            onBlur={onAfterBlur}
            emptyValue={options.emptyValue}
            parentSchema={parentSchema || undefined}
            hideLabel={true}
            value={value === null || value === undefined ? '' : value}
            expandedItem={parentValue && parentValue?.__expanded === true ? (parentValue as IDataItem) : undefined}
          />
        );
      }
    }

    const glossaryLink = links.find((link) => link.rel === 'glossary');
    if (glossaryLink) {
      customInputElement = (
        <Suggestions
          {...props}
          placeholder={placeholderWithLanguageHint}
          lookupLink={glossaryLink}
          uiProps={uiProps}
          onBlur={onAfterBlur}
          emptyValue={options.emptyValue}
          parentSchema={parentSchema || undefined}
          value={value === null || value === undefined ? '' : value}
        />
      );
    }
  }

  // Call initial Blur for primitive inputs with value
  useEffect(() => {
    // customInputElements can handle blur inside by themselves
    if (customInputElement) {
      return;
    }

    if (value) {
      onAfterBlur(id, value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (customInputElement) {
    return customInputElement;
  }

  switch (schema.format) {
    case 'cp:rating':
      return <Rating {...props} />;
    case 'cp:htmlView':
      return <HtmlContent html={value} allowScripts={false} />;
    case 'cp:voting':
      return <Voting {...props} />;
    case 'cp:html':
    case 'cp:htmlAttachmentReplacing':
      return <Html {...props} onBlur={onAfterBlur} placeholder={placeholderWithLanguageHint} />;
    case 'cp:code':
      return <Code {...props} />;
    case 'date-time-long-year':
      const modifiedSchema = { ...props.schema, format: 'date-time' };
      return <DateTimeWidget {...{ ...props, schema: modifiedSchema }} />;
    case 'cp:color':
    case 'cp:colorAll':
      return <Color {...props} />;
    case 'cp:filter':
      return <Filter {...props} />;
    case 'cp:property':
      return <PropertySelector {...props} />;
    case 'cp:stringToBoolean':
      return <CheckboxWidget {...props} value={value === 'true'} options={checkboxOptions} onChange={(value) => onChange(String(value))} />;
    case 'cp:bpmnDiagram': {
      return <Bpmn {...props} pathValue={pathContext} />;
    }
    default:
      return (
        <>
          <TitleField
            title={label || schema.title}
            required={label || schema.title ? required : false}
            localizable={schema?.cp_localizable}
            registry={registry}
            schema={schema}
            type={TitleFieldType.Primitive}
          />
          <DescriptionField description={schema.description || ''} detailed />
          <TextField
            className={classNames(styles.field, { [styles.required]: !(label || schema.title) && required })}
            id={id}
            required={label || schema.title ? false : required}
            autoFocus={autofocus}
            disabled={disabled || (schema as { disabled: boolean }).disabled || readonly || schema.readOnly}
            readOnly={readonly || schema.readOnly}
            type={schema.type as string}
            value={value === null || value === undefined ? '' : value}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            errorMessage={(rawErrors || []).join('\n')}
            multiline={schema.format === 'cp:multiline'}
            autoAdjustHeight={schema.format === 'cp:multiline'}
            placeholder={placeholderWithLanguageHint}
            {...uiProps}
          />
        </>
      );
  }
};

const loadingPlaceholder = <LoadingArea />;

const TextWidgetWithSuspense: React.FC<TextWidgetProps> = (props) => {
  return (
    <Suspense fallback={loadingPlaceholder}>
      <TextWidget {...props} />
    </Suspense>
  );
};

export default TextWidgetWithSuspense;
