import { map } from '@newdash/newdash/map';
import { createTraverser } from './visitor';
export function stringify(value, index, next) {
    return map(value.slice(index, next), (ch) => String.fromCharCode(ch)).join('');
}
export function is(value, compare) {
    for (let i = 0; i < compare.length; i++) {
        if (value === compare.charCodeAt(i)) {
            return true;
        }
    }
    return false;
}
export function equals(value, index, compare) {
    let i = 0;
    while (value[index + i] === compare.charCodeAt(i) && i < compare.length) {
        i++;
    }
    return i === compare.length ? i : 0;
}
export function required(value, index, comparer, min, max) {
    let i = 0;
    max = max || value.length - index;
    while (i < max && comparer(value[index + i])) {
        i++;
    }
    return i >= (min || 0) && i <= max ? index + i : 0;
}
export function isType(node, type) {
    return (node === null || node === void 0 ? void 0 : node.type) == type;
}
export function findOne(node, type) {
    let rt;
    createTraverser({
        [type]: (v) => {
            rt = v;
        },
    })(node);
    return rt;
}
export function findAll(node, type) {
    const rt = [];
    createTraverser({
        [type]: (v) => {
            rt.push(v);
        },
    })(node);
    return rt;
}
export default { stringify, is, equals, required };
