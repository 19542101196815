import React from 'react';
import { WidgetProps, utils } from '@rjsf/core';

const { localToUTC, utcToLocal } = utils;

// Original file
// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/fluent-ui/src/DateTimeWidget/DateTimeWidget.tsx
const DateTimeWidget: React.FC<WidgetProps> = (props: WidgetProps) => {
  const { registry } = props;
  const { TextWidget } = registry.widgets;
  const uiProps: any = props.options['props'] || {};

  const value = utcToLocal(props.value);
  const onChange = (value: any): void => {
    props.onChange(localToUTC(value));
  };
  const options = {
    ...props.options,
    props: {
      type: 'datetime-local',
      ...uiProps,
    },
  };
  return <TextWidget {...props} options={options} value={value} onChange={onChange} />;
};

export default DateTimeWidget;
