import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DialogType, IconButton, TooltipOverflowMode } from '@fluentui/react';
import { showDialog } from '@cpa/base-core/helpers';
import { GlobalDialogType, IDataItem } from '@cpa/base-core/types';
import { Schemas, TypeConstants } from '@cp/base-types';
import { axiosDictionary, deleteEntityFromEndpoint, postEntityToEndpoint, putEntityToEndpoint } from '@cpa/base-core/api';
import { cloneDeepWithMetadata, DataServiceModules, shortenSubjectUri } from '@cp/base-utils';
import notification from '@cpa/base-core/helpers/toast';
import { IGlobalState } from '@cpa/base-core/store';

import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import styles from './SavedFilters.module.scss';

interface ISavedFiltersProps {
  value?: Schemas.CpaSavedFilter;
  onChange: (value: Schemas.CpaSavedFilter | undefined) => void;
  filterValue: string;
  cpTypeUrl: string;
}

const SavedFilters: React.FC<ISavedFiltersProps> = ({ value, onChange, filterValue, cpTypeUrl }) => {
  const darkMode = useSelector((store: IGlobalState) => store.settings.darkMode);
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);
  const [t] = useTranslation();

  const handleOpenSavedFilter = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const selectedItem = (await showDialog({
        type: GlobalDialogType.ITEM_SELECTION,
        primaryButtonText: 'common.submit',
        secondaryButtonText: 'common.close',
        title: 'search.helper.open',
        dialogTypeOptions: {
          cpTypeUrl: TypeConstants.CpaSavedFilter,
          dataUrl: `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaSavedFilter)}?$filter=${encodeURIComponent(
            `cpTypeUrl eq '${encodeURIComponent(shortenSubjectUri(cpTypeUrl, prefixMap))}'`
          )}`,
        },
      })) as (Schemas.CpaSavedFilter & IDataItem) | undefined;

      if (selectedItem) {
        onChange((selectedItem.__originalItem as Schemas.CpaSavedFilter) || selectedItem);
      }
    },
    [cpTypeUrl, onChange, prefixMap]
  );

  const handleSaveAs = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const filterName = (await showDialog({
        type: GlobalDialogType.INPUT,
        primaryButtonText: 'common.submit',
        secondaryButtonText: 'common.close',
        title: t('search.helper.saveAs'),
        message: t('search.helper.namePrompt'),
        closeOnClickOutside: true,
        dialogContentProps: {
          type: DialogType.largeHeader,
        },
        dialogTypeOptions: {
          optional: true,
        },
      })) as string | undefined;

      if (!filterName) {
        return;
      }

      try {
        const savedFilter = await postEntityToEndpoint<Schemas.CpaSavedFilter & IDataItem>(
          axiosDictionary.appDataService,
          `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaSavedFilter)}`,
          {
            name: filterName,
            filter: filterValue,
            cpTypeUrl: shortenSubjectUri(cpTypeUrl, prefixMap),
          }
        );
        onChange(savedFilter);
        notification.success(t('search.helper.saved'));
      } catch (e) {
        notification.error(e.message);
      }
    },
    [t, filterValue, cpTypeUrl, prefixMap, onChange]
  );

  const handleCancelClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onChange(undefined);
    },
    [onChange]
  );

  const handleDelete = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const confirmed = await showDialog({
        type: GlobalDialogType.CONFIRMATION,
        message: '',
        dialogContentProps: {
          type: DialogType.largeHeader,
          title: t('common.delete'),
          subText: t('common.deleted', { amount: 1 }),
        },
        primaryButtonText: 'common.confirm',
        secondaryButtonText: 'common.cancel',
        closeOnClickOutside: true,
        closeOnAction: true,
      });

      if (value && confirmed) {
        try {
          await deleteEntityFromEndpoint(
            axiosDictionary.appDataService,
            `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaSavedFilter)}`,
            [value as IDataItem]
          );

          onChange(undefined);
          notification.success(t('search.helper.deleted'));
        } catch (e) {
          notification.error(e.message);
        }
      }
    },
    [t, value, onChange]
  );

  const handleSave = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!value) {
        return;
      }

      const newFilter = cloneDeepWithMetadata(value);

      newFilter.filter = filterValue;

      try {
        const updatedFilter = await putEntityToEndpoint<Schemas.CpaSavedFilter & IDataItem>(
          axiosDictionary.appDataService,
          `${DataServiceModules.DATA_STORE}${encodeURIComponent(TypeConstants.CpaSavedFilter)}`,
          value as Schemas.CpaSavedFilter & IDataItem,
          newFilter as Schemas.CpaSavedFilter & IDataItem
        );
        onChange(updatedFilter);
        notification.success(t('search.helper.saved'));
      } catch (e) {
        notification.error(e.message);
      }
    },
    [filterValue, onChange, t, value]
  );

  // Disabled icon buttons do not fire onMouseLeave, so we need to wrap when with div
  // https://github.com/microsoft/fluent-ui-react/issues/2021
  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.wrapperDark]: darkMode,
        [styles.withSelectedItem]: !!value,
      })}
    >
      {!value ? (
        <>
          <HoverTooltip content={t('search.helper.open')}>
            <IconButton iconProps={{ iconName: 'FabricOpenFolderHorizontal' }} onClick={handleOpenSavedFilter} />
          </HoverTooltip>
          <HoverTooltip content={t('search.helper.saveAs')}>
            <div>
              <IconButton
                iconProps={{ iconName: 'SaveAs' }}
                styles={{ rootDisabled: { background: 'transparent', pointerEvents: 'none' } }}
                onClick={handleSaveAs}
                disabled={!filterValue}
              />
            </div>
          </HoverTooltip>
        </>
      ) : (
        <>
          <span className={classNames(styles.selectedItemName, { [styles.textDark]: !darkMode })}>
            <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={value.name}>
              {value.name}
            </HoverTooltip>
          </span>

          <HoverTooltip content={t('search.helper.save')}>
            <IconButton iconProps={{ iconName: 'Save' }} onClick={handleSave} />
          </HoverTooltip>
          <HoverTooltip content={t('search.helper.delete')}>
            <IconButton iconProps={{ iconName: 'Delete' }} onClick={handleDelete} />
          </HoverTooltip>
          <HoverTooltip content={t('search.helper.clear')}>
            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={handleCancelClick} />
          </HoverTooltip>
        </>
      )}
    </div>
  );
};

export default SavedFilters;
