import { create } from 'zustand';
import { IJSONSchema } from '@cp/base-types';
import { createContext, useContext, useMemo } from 'react';

import { removeOverwritePaths } from './methods/removeOverwritePaths';
import { replaceOverwritePaths } from './methods/replaceOverwritePaths';
import { mergeOverwrites } from './methods/mergeOverwrites';
import { changeOverwritesOnArrayItemRemove } from './methods/changeOverwritesOnArrayItemRemove';
import { changeOverwritesOnArrayItemReorder } from './methods/changeOverwritesOnArrayItemReorder';

/**
 * Context for managing schema overwrites in form.
 */
export const SchemaOverwritesContext = createContext<ReturnType<typeof createSchemaOverwritesStore> | null>(null);

/**
 * State object for managing schema overwrites in form.
 */
export type SchemaOverwritesState = {
  /**
   * Allows schema overwrites for specific paths.
   * Keys represent path strings, and values are JSON schemas to be applied.
   */
  schemaOverwrites: Record<string, IJSONSchema>;

  /**
   * Removes schema overwrites for specific paths.
   * @param pathsToRemove - An array of paths whose overwrites should be removed.
   */
  removePaths: (pathsToRemove: string[]) => void;

  /**
   * Replaces schema overwrites from one path to another.
   * @param pathsToReplace - An array of objects defining the source path (pathFrom) and target path (pathTo) for each replacement.
   */
  replacePaths: (pathsToReplace: { pathFrom: string; pathTo: string }[]) => void;

  /**
   * Merges provided schema overwrites with the existing ones.
   * @param schemaOverwritesToMerge - An object with paths as keys and JSON schemas as values to merge into current schemaOverwrites.
   */
  mergeWith: (schemaOverwritesToMerge: Record<string, IJSONSchema>) => void;

  /**
   * Handles the removal of an item from an array schema path.
   * @param arrayPath - The path to the array in which an item is dropped.
   * @param indexToDrop - The index of the item to be removed.
   * @param itemsCount - The number of items currently in the array.
   */
  handleArrayItemRemove: (arrayPath: string, indexToDrop: number, itemsCount: number) => void;

  /**
   * Handles the reorder items in an array schema path.
   * @param arrayPath - The path to the array in which items are reordered.
   * @param indexFrom - The original index of the item.
   * @param indexTo - The new index for the item.
   */
  handleArrayItemReorder: (arrayPath: string, indexFrom: number, indexTo: number) => void;
};

/**
 * Creates a store for managing schema overwrites with a set of utility functions.
 * This store includes functions for removing, replacing, merging paths, and handling array item manipulation.
 * @returns A store object with state management functions for schema overwrites.
 */
const createSchemaOverwritesStore = () => {
  const store = create<SchemaOverwritesState>((set) => ({
    schemaOverwrites: {},
    removePaths: (pathsToRemove: string[]) => set((state) => ({ schemaOverwrites: removeOverwritePaths(state.schemaOverwrites, pathsToRemove) })),
    replacePaths: (pathsToReplace: { pathFrom: string; pathTo: string }[]) =>
      set((state) => ({ schemaOverwrites: replaceOverwritePaths(state.schemaOverwrites, pathsToReplace) })),
    mergeWith: (schemaOverwritesToMerge: Record<string, IJSONSchema>) =>
      set((state) => ({ schemaOverwrites: mergeOverwrites(state.schemaOverwrites, schemaOverwritesToMerge) })),
    handleArrayItemRemove: (arrayPath: string, indexToRemove: number, itemsCount: number) =>
      set((state) => ({ schemaOverwrites: changeOverwritesOnArrayItemRemove(state.schemaOverwrites, arrayPath, indexToRemove, itemsCount) })),
    handleArrayItemReorder: (arrayPath: string, indexFrom: number, indexTo: number) =>
      set((state) => ({ schemaOverwrites: changeOverwritesOnArrayItemReorder(state.schemaOverwrites, arrayPath, indexFrom, indexTo) })),
  }));

  return store;
};

/**
 * Custom hook to create new schema overwrites store.
 * Ensures the hook is used within the correct context provider.
 * @param selector - A function to select a specific slice of the schema overwrites state.
 * @returns The selected slice of schema overwrites state.
 * @throws Error if used outside the `StoreProvider`.
 */
export const useNewSchemaOverwritesStore = () => useMemo(() => createSchemaOverwritesStore(), []);

/**
 * Custom hook to access the schema overwrites store.
 * Ensures the hook is used within the correct context provider.
 * @param selector - A function to select a specific slice of the schema overwrites state.
 * @returns The selected slice of schema overwrites state.
 * @throws Error if used outside the `StoreProvider`.
 */
export const useSchemaOverwritesStore = <T>(selector: (state: SchemaOverwritesState) => T): T => {
  const contextStore = useContext(SchemaOverwritesContext);
  if (!contextStore) {
    throw new Error('useSchemaOverwritesStore must be used within a StoreProvider');
  }
  return contextStore(selector);
};
