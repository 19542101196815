import { IJSONSchema, Schemas } from '@cp/base-types';
import { isRelationSchema } from '@cp/base-utils';
import { IDataItem } from '@cpa/base-core/types';
import { Checkbox } from '@fluentui/react';
import React, { CSSProperties, useMemo } from 'react';
import { FormatSource } from '@cpa/base-core/constants';

import ReadonlyContent from '../../../../../../ReadonlyContent/ReadonlyContent';

import styles from './MergeDifference.module.scss';

export interface IDifferenceProps {
  page: Schemas.CpaPage;
  schema: IJSONSchema;
  itemA: IDataItem;
  itemB: IDataItem;
  movedProperties: string[];
  onPropertiesChange: React.Dispatch<React.SetStateAction<string[]>>;
}

const MergeDifference: React.FC<IDifferenceProps> = ({ page, schema, itemA, itemB, onPropertiesChange, movedProperties }) => {
  const readonlyContentData = useMemo(
    () => ({
      page,
      items: [],
      isFetching: false,
      totalItems: 0,
      schema: schema,
    }),
    [page, schema]
  );

  return (
    <div>
      <div className={styles.differenceContainer}>
        <div className={styles.differenceBlock}>
          <p>{itemA.name || itemA.identifier || '-'}</p>
          <ReadonlyContent
            item={itemA}
            data={readonlyContentData}
            options={useMemo(
              () => ({
                source: FormatSource.Drawer,
                expandFunctionalityOptions: {
                  defaultExpanded: true,
                },
                showEmptyValues: true,
                customPropertyRender: (
                  itemKey: string,
                  formattedContent: JSX.Element | null,
                  propertyStyles: CSSProperties,
                  level: number,
                  propertySchema?: IJSONSchema,
                  parentSchemas?: IJSONSchema[]
                ): JSX.Element | null => {
                  const isLocatedInStaticObjects: boolean = !!parentSchemas?.every((parentSchema) => parentSchema.type === 'object');
                  const isLocatedInRelatedSchema: boolean = !!parentSchemas?.some((parentSchema) => isRelationSchema(parentSchema));

                  if (itemKey === 'identifier' && level === 2) {
                    return null;
                  }

                  if (propertySchema?.type === 'object' && isLocatedInStaticObjects && !isRelationSchema(propertySchema)) {
                    return formattedContent;
                  }

                  if (
                    level === 1 ||
                    (level > 2 && (!parentSchemas?.every((parentSchema) => parentSchema.type === 'object') || isLocatedInRelatedSchema))
                  ) {
                    return formattedContent;
                  }

                  return (
                    <div className={styles.property} style={{ marginBottom: 10, ...propertyStyles }}>
                      {formattedContent}
                      <div className={styles.moveCheckboxWrapper}>
                        <Checkbox
                          className={styles.moveCheckbox}
                          checked={movedProperties.includes(itemKey)}
                          onChange={(ev, checked) => {
                            if (checked) {
                              onPropertiesChange((prevState) => [...prevState, itemKey]);
                            } else {
                              onPropertiesChange((prevState) => prevState.filter((item) => item !== itemKey));
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                },
              }),
              [movedProperties, onPropertiesChange]
            )}
          />
        </div>
        <div className={styles.differenceBlock}>
          <p>{itemB.name || itemB.identifier || '-'}</p>
          <ReadonlyContent
            item={itemB}
            data={readonlyContentData}
            options={useMemo(
              () => ({
                source: FormatSource.Drawer,
                expandFunctionalityOptions: {
                  defaultExpanded: true,
                },
              }),
              []
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default MergeDifference;
