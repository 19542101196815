import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import { getAllSchemaPaths, resolveSubjectUri } from '@cp/base-utils';
import { axiosDictionary, getEndpoint, getSchema } from '@cpa/base-core/api';
import { IJSONSchema } from '@cp/base-types';
import urlJoin from 'url-join';
import { DefaultButton, IContextualMenuProps, ThemeContext } from '@fluentui/react';
import { useFormField } from '@cpa/base-core/hooks/form';
import { useDebouncedValue } from '@cpa/base-core/hooks';
import { generatePropertiesContextMenuStyles } from '@cpa/base-core/helpers';
import { listPropertiesAsContextMenu } from '@cpa/base-core/helpers';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TextWidgetProps } from '../../TextWidget';
import TitleField, { TitleFieldType } from '../../../TitleField/TitleField';

import styles from './PropertySelector.module.scss';

const PropertySelector: React.FC<TextWidgetProps> = ({ onChange, value, label, required, registry, schema: fieldSchema }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);
  const [dropdownMenuItems, setDropdownMenuItems] = useState<IContextualMenuProps>({
    items: [],
  });
  const unExpandedType = useFormField<string>('cpTypeUrl');
  const resolvedCpTypeUrl = useMemo(() => {
    if (!unExpandedType) {
      return undefined;
    }

    try {
      return resolveSubjectUri(unExpandedType, prefixMap);
    } catch (e) {
      return undefined;
    }
  }, [prefixMap, unExpandedType]);
  const [debouncedResolvedCpTypeUrl] = useDebouncedValue(resolvedCpTypeUrl, 500);

  const [schema, setSchema] = useState<IJSONSchema | undefined>(undefined);

  useEffect(() => {
    setSchema(undefined);

    if (!debouncedResolvedCpTypeUrl) {
      return;
    }

    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);

    getSchema(urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(debouncedResolvedCpTypeUrl)}`))
      .then(setSchema)
      .catch((e) => {
        console.error(e);
        setSchema(undefined);
      });
  }, [debouncedResolvedCpTypeUrl]);

  const theme = useContext(ThemeContext);
  useEffect(() => {
    if (schema) {
      let schemaPaths = getAllSchemaPaths(schema);

      if (fieldSchema?.cp_ui?.propertySelectorType) {
        const propertySelectorTypes = fieldSchema?.cp_ui?.propertySelectorType.split(',');

        schemaPaths = schemaPaths.filter(
          (prop) =>
            propertySelectorTypes.includes(prop.type) || (prop.propertySchema?.format && propertySelectorTypes.includes(prop.propertySchema?.format))
        );
      }

      const properties = listPropertiesAsContextMenu(
        schemaPaths,
        1,
        (property) => onChange(property),
        () => false,
        theme
      );

      setDropdownMenuItems({
        items: properties,
        styles: generatePropertiesContextMenuStyles(theme),
      });
    }
  }, [fieldSchema, schema, onChange, theme]);

  return (
    <>
      <TitleField
        title={label || fieldSchema!.title}
        localizable={fieldSchema!.cp_localizable}
        registry={registry}
        schema={fieldSchema}
        required={required}
        type={TitleFieldType.Primitive}
      />

      <DefaultButton
        className={classNames(styles.dropdown, { [styles.dark]: darkMode })}
        text={value ? value : t('common.notSet')}
        menuProps={dropdownMenuItems}
      />
    </>
  );
};

export default PropertySelector;
