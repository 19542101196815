import { Schemas } from '@cp/base-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import LoadingArea from '../../LoadingArea/LoadingArea';

import { IDiffSource, IPropertyDiff as IFieldDifference, getFieldDifferences } from './helpers/difference';
import styles from './DifferenceAsObjects.module.scss';
import DifferenceAsObjectsWrapper from './DifferenceAsObjectsWrapper/DifferenceAsObjectsWrapper';
import DifferenceAsObjectsItem from './DifferenceAsObjectsItem/DifferenceAsObjectsItem';

export interface IDifferenceAsObjectsProps {
  page: Schemas.CpaPage;

  items: IDiffSource[];

  nameA: string;
  nameB: string;

  isOnlyChangedFields: boolean;
}

const DifferenceAsObjects: React.FC<IDifferenceAsObjectsProps> = ({ page, items, nameA, nameB, isOnlyChangedFields }) => {
  const [isLoading, setLoading] = useState(true);
  const [fieldDifferences, setFieldDifferences] = useState<IFieldDifference[]>([]);
  const [collapsedDataPaths, setCollapsedDataPaths] = useState<string[]>([]);

  const handleToggleCollapsed = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, item: IFieldDifference) => {
      e.stopPropagation();
      setCollapsedDataPaths((items) => {
        return items.includes(item.dataPath) ? items.filter((s) => s !== item.dataPath) : [...items, item.dataPath];
      });
    },
    [setCollapsedDataPaths]
  );

  const calculateFieldDifferences = useCallback(
    async (sourceItems: IDiffSource[]) => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 100));

      const allFieldDifferences: IFieldDifference[] = [];

      for (let i = 0; i < sourceItems.length; i++) {
        const item = sourceItems[i];
        if (!item.schema) {
          continue;
        }

        const fieldDifferences = await getFieldDifferences(item.schema, item.itemA, item.itemB);

        for (const fieldDifference of fieldDifferences) {
          allFieldDifferences.push({
            ...fieldDifference,
            dataPath: fieldDifference.dataPath === '' ? `item${i}` : `item${i}.` + fieldDifference.dataPath,
          });
        }
      }

      const pathsToCollapse: string[] = [];

      for (const fieldDifference of allFieldDifferences) {
        if (!fieldDifference.isChanged) {
          pathsToCollapse.push(fieldDifference.dataPath);
        }
      }

      setFieldDifferences(allFieldDifferences);
      setCollapsedDataPaths(pathsToCollapse);
      setLoading(false);
    },
    [setFieldDifferences, setCollapsedDataPaths, setLoading]
  );

  useEffect(() => {
    calculateFieldDifferences(items);
  }, [items, calculateFieldDifferences]);

  const filteredFieldDifferences = useMemo(() => {
    if (!isOnlyChangedFields) {
      return fieldDifferences;
    }

    return fieldDifferences.filter((item) => item.isChanged);
  }, [fieldDifferences, isOnlyChangedFields]);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingArea />}

      {!isLoading && (
        <DifferenceAsObjectsWrapper className={styles.differenceWrapper}>
          <div className={styles.differenceContainer}>
            <table className={styles.differenceTable}>
              <tbody>
                <tr>
                  <td>
                    <p className={styles.header}>{nameB}</p>
                  </td>
                  <td>
                    <p className={styles.header}>{nameA}</p>
                  </td>
                </tr>
                {filteredFieldDifferences.map((item) => (
                  <DifferenceAsObjectsItem
                    page={page}
                    collapsedDataPaths={collapsedDataPaths}
                    item={item}
                    key={item.dataPath}
                    onToggleCollapsed={handleToggleCollapsed}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </DifferenceAsObjectsWrapper>
      )}
    </div>
  );
};

export default DifferenceAsObjects;
