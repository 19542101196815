import { IGlobalState } from '@cpa/base-core/store';
import { ICardProps } from '@cpa/base-core/types';
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PersonCard from './components/PersonCard/PersonCard';
import styles from './ContactPersonCard.module.scss';

interface IContactPersonCardProps {
  className?: string;
  personCardProps?: {
    isInHoverCard?: boolean;
  };
  topicAdditionalUsers?: string[];
  topicName?: string;
  topicMessage?: string | ((personName: string) => Promise<string | null>);
}

const ContactPersonCard: React.FC<ICardProps & IContactPersonCardProps> = ({
  data: {
    page: { dataEndpoint },
  },
  onClick,
  item,
  className,
  personCardProps,
  topicName,
  topicAdditionalUsers,
  topicMessage,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  return (
    <div className={classNames(styles.personCardWrapper, className)}>
      <PersonCard
        key={item.name as string}
        person={{
          image: (item.image || item._image) as string,
          name: item.name as string,
          position: item.jobTitle as string,
          phone: item.telephone as string,
          email: item.email as string,
          teamsLink: item.teamsLink as string,
        }}
        darkMode={darkMode}
        company={item['__company'] as string}
        personData={item}
        onNameClick={onClick}
        endpointId={dataEndpoint?.identifier}
        topicName={topicName}
        topicMessage={topicMessage}
        topicAdditionalUsers={topicAdditionalUsers}
        {...personCardProps}
      />
    </div>
  );
};

export default React.memo(ContactPersonCard);
