const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/ContactPerson',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FContactPerson' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Contact Person',
    'properties': {
        'userPrincipalName': {
            'title': 'User Principal Name',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 85 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/userPrincipalName', 'rel': 'canonical' }],
        },
        'address': {
            'title': 'Address',
            'description': 'The mailing address.',
            '$id': 'http://schema.org/PostalAddress',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPostalAddress' },
                { 'href': 'http://schema.org/address', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'addressLocality': {
                    'title': 'Address Locality',
                    'description': 'The locality in which the street address is, and which is in the region. For example, Mountain View.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/addressLocality', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 78 },
        },
        'email': {
            'title': 'Email',
            'description': 'Email address.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 79 },
            'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
        },
        'familyName': {
            'title': 'Family Name',
            'description': '<div>Last name, surname, or family name.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 80 },
            'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
        },
        'givenName': {
            'title': 'Given Name',
            'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 81 },
            'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
        },
        'jobTitle': {
            'title': 'Job Title',
            'description': 'The job title of the person (for example, Financial Manager).',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 83 },
            'links': [{ 'href': 'http://schema.org/jobTitle', 'rel': 'canonical' }],
        },
        'telephone': {
            'title': 'Telephone',
            'description': '<div>The telephone number at which a company or person can be reached during business hours.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 84 },
            'links': [{ 'href': 'http://schema.org/telephone', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 86 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 82 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 77 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const ContactPersonSchema = Schema;
