import React, { useCallback, useEffect, useMemo } from 'react';
import { useLoadableData } from '@cpa/base-core/hooks';
import { axiosDictionary } from '@cpa/base-core/api';
import { IDataItem, IGenericComponentData } from '@cpa/base-core/types';
import { Schemas } from '@cp/base-types';
import { getODataFilter, parseFilterMessage } from '@cp/base-utils';
import { Environment } from '@cpa/base-core/app/environment';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import urlJoin from 'url-join';
import { push } from 'connected-react-router';

import WidgetCardView from '../../../../../../components/ScrollingContent/components/WidgetCardView/WidgetCardView';
import { ISuggestedSolutionsProps } from '../SuggestedSolutions';
import HtmlContent from '../../../../../../components/HtmlContent/HtmlContent';
import dark from '../../../styles/dark.module.scss';
import light from '../../../styles/light.module.scss';

interface ISuggestedItemProps {
  onRender: (renderedCount: number, id: number) => void;
}

const Suggested: React.FC<ISuggestedSolutionsProps & ISuggestedItemProps> = ({
  page,
  suggestedFilter,
  suggestedLabel,
  suggestedDescription,
  onRender,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const styles = useMemo<Record<string, string>>(() => (darkMode ? dark : light), [darkMode]);

  const dispatch = useDispatch();

  const {
    loadItems,
    schema: loadedSchema,
    isFetching,
    items,
    totalItems,
    forceSetItems,
  } = useLoadableData(
    page?.dynamicDataUrl ? page.dynamicDataUrl : page?.dataUrl || '/',
    page.dataEndpoint?.identifier || axiosDictionary.appDataService,
    page.groupPropertyJsonPath,
    page.schemaUrl as string | undefined,
    undefined,
    undefined,
    undefined,
    undefined
  );

  useEffect(() => {
    if (suggestedFilter) {
      loadItems({
        filter: getODataFilter(parseFilterMessage(suggestedFilter)),
        orderBy: [['name', 'asc']],
      });
    } else {
      forceSetItems([]);
    }
  }, [suggestedFilter]);

  const onMoreClick = useCallback(() => {
    if (suggestedFilter && page.path) {
      dispatch(push(urlJoin(page.path?.replace('/:id', '') || '/', `?filter={${encodeURIComponent(suggestedFilter)}}`)));
    }
  }, [dispatch, page.path, suggestedFilter]);

  const componentData = useMemo<IGenericComponentData>((): IGenericComponentData => {
    return {
      items: items,
      page: {
        ...page,
        customCardTemplate: {
          identifier: 'ImageItem',
        },
        path: page.path?.replace('/:id', ''),
      } as Schemas.CpaPage,
      schema: loadedSchema,
      isFetching,
      totalItems,
    };
  }, [items, page, loadedSchema, isFetching, totalItems]);

  const handleItemOpen = useCallback(
    (item: IDataItem) => {
      const keyProp = item.identifier ? 'identifier' : '__identifier';
      const pathname = urlJoin(page.path?.replace('/:id', '') || '/', encodeURIComponent(item[keyProp]?.toString() || '-'));
      window.open(urlJoin(window.location.origin, pathname), '_blank');
    },
    [page]
  );

  const onCardClick = useCallback(
    (item: IDataItem) => {
      handleItemOpen(item);
    },
    [handleItemOpen]
  );

  useEffect(() => {
    onRender(items.length, 0);
  }, [items.length, onRender]);

  if (!items.length) return null;

  return (
    <div>
      <h2 className={classNames(styles.schemaTitle, styles.sdcTitle)}>{suggestedLabel}</h2>
      <div style={{ marginBottom: '15px' }}>
        <HtmlContent html={suggestedDescription} allowScripts={Environment.env.REACT_APP_ALLOW_SCRIPTS_IN_STATIC_CONTENT === 'true'} />
      </div>
      <WidgetCardView data={componentData} items={items} onCardClick={onCardClick} pageCardView={true} onMoreClick={onMoreClick} />
    </div>
  );
};

export default Suggested;
