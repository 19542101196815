import escapeStringRegexp from 'escape-string-regexp';
import { Literal } from 'odata-v4-literal';
export class Visitor {
    constructor() {
        this.query = {};
        this.sort = {};
        this.projection = {};
        this.includes = [];
        let _ast;
        Object.defineProperty(this, 'ast', {
            get: () => {
                return _ast;
            },
            set: (v) => {
                _ast = v;
            },
            enumerable: false,
        });
    }
    Visit(node, context) {
        this.ast = this.ast || node;
        context = context || {};
        if (node) {
            const visitor = this[`Visit${node.type}`];
            if (visitor)
                visitor.call(this, node, context);
        }
        return this;
    }
    VisitODataUri(node, context) {
        this.Visit(node.value.resource, context);
        this.Visit(node.value.query, context);
    }
    VisitEntitySetName(node, context) {
        this.collection = node.value.name;
    }
    VisitExpand(node, context) {
        const innerContexts = {};
        node.value.items.forEach((item) => {
            const expandPath = item.value.path.raw;
            let innerVisitor = this.includes.filter((v) => v.navigationProperty === expandPath)[0];
            if (!innerVisitor) {
                innerVisitor = new Visitor();
                innerContexts[expandPath] = {
                    query: {},
                    sort: {},
                    projection: {},
                    options: {},
                };
                this.includes.push(innerVisitor);
            }
            const innerContext = innerContexts[expandPath] || {};
            innerVisitor.Visit(item, innerContext);
            innerVisitor.query = innerContext.query || innerVisitor.query || {};
            innerVisitor.sort = innerContext.sort || innerVisitor.sort;
            innerVisitor.projection = innerContext.projection || innerVisitor.projection;
        });
    }
    VisitExpandItem(node, context) {
        this.Visit(node.value.path, context);
        node.value.options && node.value.options.forEach((item) => this.Visit(item, context));
    }
    VisitExpandPath(node, context) {
        this.navigationProperty = node.raw;
    }
    VisitQueryOptions(node, context) {
        const self = this;
        context.options = {};
        node.value.options.forEach((option) => this.Visit(option, context));
        this.query = context.query || {};
        delete context.query;
        this.sort = context.sort;
        delete context.sort;
    }
    VisitInlineCount(node, context) {
        this.inlinecount = Literal.convert(node.value.value, node.value.raw);
    }
    VisitFilter(node, context) {
        context.query = {};
        this.Visit(node.value, context);
        delete context.identifier;
        delete context.literal;
    }
    VisitOrderBy(node, context) {
        context.sort = {};
        node.value.items.forEach((item) => this.Visit(item, context));
    }
    VisitSkip(node, context) {
        this.skip = +node.value.raw;
    }
    VisitTop(node, context) {
        this.limit = +node.value.raw;
    }
    VisitOrderByItem(node, context) {
        this.Visit(node.value.expr, context);
        if (context.identifier)
            context.sort[context.identifier] = node.value.direction;
        delete context.identifier;
        delete context.literal;
    }
    VisitSelect(node, context) {
        context.projection = {};
        node.value.items.forEach((item) => this.Visit(item, context));
        this.projection = context.projection;
        delete context.projection;
    }
    VisitSelectItem(node, context) {
        context.projection[node.raw.replace(/\//g, '.')] = 1;
    }
    VisitAndExpression(node, context) {
        const query = context.query;
        const leftQuery = {};
        context.query = leftQuery;
        this.Visit(node.value.left, context);
        const rightQuery = {};
        context.query = rightQuery;
        this.Visit(node.value.right, context);
        if (Object.keys(leftQuery).length > 0 && Object.keys(rightQuery).length > 0) {
            query.$and = [leftQuery, rightQuery];
        }
        context.query = query;
    }
    VisitOrExpression(node, context) {
        const query = context.query;
        const leftQuery = {};
        context.query = leftQuery;
        this.Visit(node.value.left, context);
        const rightQuery = {};
        context.query = rightQuery;
        this.Visit(node.value.right, context);
        if (Object.keys(leftQuery).length > 0 && Object.keys(rightQuery).length > 0) {
            query.$or = [leftQuery, rightQuery];
        }
        context.query = query;
    }
    VisitBoolParenExpression(node, context) {
        this.Visit(node.value, context);
    }
    VisitCommonExpression(node, context) {
        this.Visit(node.value, context);
    }
    VisitFirstMemberExpression(node, context) {
        this.Visit(node.value, context);
    }
    VisitMemberExpression(node, context) {
        this.Visit(node.value, context);
    }
    VisitPropertyPathExpression(node, context) {
        if (node.value.current && node.value.next) {
            this.Visit(node.value.current, context);
            if (context.identifier)
                context.identifier += '.';
            this.Visit(node.value.next, context);
        }
        else
            this.Visit(node.value, context);
    }
    VisitSingleNavigationExpression(node, context) {
        if (node.value.current && node.value.next) {
            this.Visit(node.value.current, context);
            this.Visit(node.value.next, context);
        }
        else
            this.Visit(node.value, context);
    }
    VisitODataIdentifier(node, context) {
        context.identifier = (context.identifier || '') + node.value.name;
    }
    VisitNotExpression(node, context) {
        this.Visit(node.value, context);
        if (context.query) {
            for (const prop in context.query) {
                context.query[prop] = { $not: context.query[prop] };
            }
        }
    }
    VisitEqualsExpression(node, context) {
        this.Visit(node.value.left, context);
        this.Visit(node.value.right, context);
        if (context.identifier)
            context.query[context.identifier] = { $eq: context.literal };
        delete context.identifier;
        delete context.literal;
    }
    VisitNotEqualsExpression(node, context) {
        const left = this.Visit(node.value.left, context);
        const right = this.Visit(node.value.right, context);
        if (context.identifier) {
            if (context.literal === null) {
                context.query[context.identifier] = { $exists: true };
            }
            else {
                context.query[context.identifier] = { $ne: context.literal };
            }
        }
        delete context.identifier;
        delete context.literal;
    }
    VisitLesserThanExpression(node, context) {
        const left = this.Visit(node.value.left, context);
        const right = this.Visit(node.value.right, context);
        if (context.identifier)
            context.query[context.identifier] = { $lt: context.literal };
        delete context.identifier;
        delete context.literal;
    }
    VisitLesserOrEqualsExpression(node, context) {
        const left = this.Visit(node.value.left, context);
        const right = this.Visit(node.value.right, context);
        if (context.identifier)
            context.query[context.identifier] = { $lte: context.literal };
        delete context.identifier;
        delete context.literal;
    }
    VisitGreaterThanExpression(node, context) {
        const left = this.Visit(node.value.left, context);
        const right = this.Visit(node.value.right, context);
        if (context.identifier)
            context.query[context.identifier] = { $gt: context.literal };
        delete context.identifier;
        delete context.literal;
    }
    VisitGreaterOrEqualsExpression(node, context) {
        const left = this.Visit(node.value.left, context);
        const right = this.Visit(node.value.right, context);
        if (context.identifier)
            context.query[context.identifier] = { $gte: context.literal };
        delete context.identifier;
        delete context.literal;
    }
    VisitLiteral(node, context) {
        context.literal = Literal.convert(node.value, node.raw);
    }
    VisitMethodCallExpression(node, context) {
        const method = node.value.method;
        const params = (node.value.parameters || []).forEach((p) => this.Visit(p, context));
        if (context.identifier) {
            switch (method) {
                case 'substringof':
                    context.query[context.identifier] = { $regex: escapeStringRegexp(context.literal), $options: 'i' };
                    break;
                case 'contains':
                    context.query[context.identifier] = { $regex: escapeStringRegexp(context.literal), $options: 'i' };
                    break;
                case 'endswith':
                    context.query[context.identifier] = { $regex: escapeStringRegexp(context.literal) + '$', $options: 'i' };
                    break;
                case 'startswith':
                    context.query[context.identifier] = { $regex: '^' + escapeStringRegexp(context.literal), $options: 'i' };
                    break;
                default:
                    throw new Error('Method call not implemented.');
            }
            delete context.identifier;
        }
        else {
            throw new Error(`Failed to parse property parameter in ${method} method`);
        }
    }
}
