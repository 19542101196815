import { IJSONSchema } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { IScreenProps } from '@cpa/base-core/types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { EndpointContext } from '@cpa/base-core/constants';

import Animation from '../../components/Animation/Animation';
import Form from '../../components/Form/Form';
import Widget from '../../components/Widget/Widget';

import styles from './Account.module.scss';

const Account: React.FC<IScreenProps> = ({ page }) => {
  const [t] = useTranslation();
  const disableAnimation = useSelector((store: IGlobalState) => store.settings.disableAnimation);
  const form = useMemo(
    () =>
      ({
        type: 'object',
        properties: {
          name: {
            type: 'string',
            title: t('account.fullName'),
            placeholder: t('account.fullNamePlaceholder'),
          },
          email: {
            type: 'string',
            title: t('account.email'),
            placeholder: t('account.emailPlaceholder'),
            format: 'email',
          },
          country: {
            type: 'string',
            title: t('account.country'),
            placeholder: t('account.countryPlaceholder'),
            enum: ['Australia', 'Belarus', 'Germany', 'USA'],
          },
          subscription: {
            type: 'boolean',
            title: t('account.subscription'),
          },
        },
      } as IJSONSchema),
    [t]
  );

  const data = useSelector((state: IGlobalState) => {
    return {
      name: state.auth.user?.account.name || '-',
    };
  });

  const onProfileEdit = useCallback(() => {
    /* send data to api */
  }, []);

  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });

  const computedDuration = useMemo(() => {
    return desktopOnly ? 800 : 1000;
  }, [desktopOnly]);

  return (
    <>
      <section className={styles.account}>
        <Animation duration={computedDuration} disable={disableAnimation} delay={0}>
          <Widget title={page.name} subtitle={page.description}>
            <div className={styles.inner}>
              <EndpointContext.Provider value={page.dataEndpoint?.identifier || null}>
                <Form page={page} schema={form} onSubmit={onProfileEdit} btnText={t('account.save')} formData={data} />
              </EndpointContext.Provider>
            </div>
          </Widget>
        </Animation>
      </section>
    </>
  );
};

export default Account;
