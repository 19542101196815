const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Attachment',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachment' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachment?$filter=identifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Attachment (Upload)',
    'description': '<div>Attachment is a uploaded copy of a file OR a link OR a wrapper of many files bundled together as one attachment.</div>',
    'properties': {
        'category': {
            'title': 'Category',
            '$id': 'http://platform.cosmoconsult.com/ontology/AttachmentCategory',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachmentCategory' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/category', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parent.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Attachment Category' } },
            'cp_ui': { 'sortOrderForm': 30 },
            'cp_localizable': true,
        },
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'sortOrderForm': 33 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'tags': {
            'title': 'Tags',
            'type': 'array',
            'items': { 'type': 'string' },
            'cp_ui': { 'sortOrderForm': 34 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tags', 'rel': 'canonical' }],
        },
        'about': {
            'title': 'About',
            'description': 'The most generic type of item.',
            '$id': 'http://schema.org/Thing',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FThing' },
                { 'href': 'http://schema.org/about', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 29 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 28 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 32 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 31 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 35 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
    },
    'cp_restrictablePaths': ['about', 'category', 'description', 'files', 'name', 'tags', 'url'],
    'cp_hasPermissions': true,
};
export const AttachmentSchema = Schema;
