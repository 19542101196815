import { IJSONSchema } from '@cp/base-types';

/**
 * Replaces schema overwrites from one path to another.
 * @param schemaOverwrites - The current schema overwrites.
 * @param pathsToReplace - An array of objects defining the source path (pathFrom) and target path (pathTo) for each replacement.
 */
export function replaceOverwritePaths(schemaOverwrites: Record<string, IJSONSchema>, pathsToReplace: { pathFrom: string; pathTo: string }[]) {
  const newSchemaOverwrites = { ...schemaOverwrites };
  const tempStorage: Record<string, IJSONSchema> = {};

  for (let i = 0; i < pathsToReplace.length; i++) {
    const { pathFrom, pathTo } = pathsToReplace[i];
    for (const key in newSchemaOverwrites) {
      if (key.startsWith(pathFrom)) {
        const tempKey = key.replace(pathFrom, pathTo);
        tempStorage[tempKey] = newSchemaOverwrites[key];
        delete newSchemaOverwrites[key];
      }
    }
  }

  for (const tempKey in tempStorage) {
    newSchemaOverwrites[tempKey] = tempStorage[tempKey];
  }

  return newSchemaOverwrites;
}
