import { IJSONSchema, Schemas } from '@cp/base-types';

type SolutionTypeDetail = NonNullable<Schemas.Solution['solutionTypeDetails']>[number];
type SolutionTypeDetailTypes = NonNullable<SolutionTypeDetail['_type']>;

type SolutionTypeDetailSchema = typeof Schemas.SolutionSchema.properties.solutionTypeDetails.items.anyOf[number];
type SolutionTypeDetailSchemaTypes = NonNullable<SolutionTypeDetailSchema['$id']>;

export const isType = <T extends SolutionTypeDetailTypes>(solutionDetailType: T) => {
  return (solutionDetail: SolutionTypeDetail): solutionDetail is Extract<SolutionTypeDetail, { _type?: T }> =>
    solutionDetail._type === solutionDetailType;
};

const isSchemaType = <T extends SolutionTypeDetailSchemaTypes>(solutionDetailType: T) => {
  return (
    solutionDetail: SolutionTypeDetailSchema
  ): solutionDetail is Extract<
    SolutionTypeDetailSchema,
    {
      '$id': T;
    }
  > => solutionDetail['$id'] === solutionDetailType;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const buildItem = (item: Schemas.Solution) => {
  const solutionTypeDetails = item.solutionTypeDetails ?? [];
  const experts = solutionTypeDetails.filter(isType('http://platform.cosmoconsult.com/ontology/Expert'));
  const hints = solutionTypeDetails.filter(isType('http://platform.cosmoconsult.com/ontology/Hint'));
  const saleable = solutionTypeDetails.find(isType('http://platform.cosmoconsult.com/ontology/Saleable'));
  const components = solutionTypeDetails.find(isType('http://platform.cosmoconsult.com/ontology/Components'));
  const target = solutionTypeDetails.find(isType('http://platform.cosmoconsult.com/ontology/Target'));
  const solutionScopes = solutionTypeDetails.filter(isType('http://platform.cosmoconsult.com/ontology/ScopeOfSolution'));
  const questions = solutionTypeDetails.filter(isType('http://platform.cosmoconsult.com/ontology/QuestionsAndAnswers'));
  const processInformation = solutionTypeDetails.filter(isType('http://platform.cosmoconsult.com/ontology/ProcessInformation'));

  return {
    url: item.url,
    tags: item.tags ?? [],
    owners: item.owners,
    reviewers: item.reviewers,
    experts: experts,
    hints: hints.map((h) => ({ hint: h.internalHint, solutionTypeDetailsIndex: solutionTypeDetails.indexOf(h) })),
    saleable: saleable,
    components: components,
    target: target,
    solutionScopes: solutionScopes[0],
    questions: questions[0],
    processInformation: processInformation,
  };
};

export const buildSchema = (schema: IJSONSchema): IJSONSchema => {
  const solutionTypeDetails = schema.properties?.solutionTypeDetails;
  const solutionTypeDetailsItems = solutionTypeDetails?.items?.anyOf as unknown as SolutionTypeDetailSchema[];
  const expert = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/Expert'));
  const hints = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/Hint'));
  const saleable = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/Saleable'));
  const components = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/Components'));
  const target = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/Target'));
  const solutionScopes = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/ScopeOfSolution'));
  const questions = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/QuestionsAndAnswers'));
  const processInformation = solutionTypeDetailsItems?.find(isSchemaType('http://platform.cosmoconsult.com/ontology/ProcessInformation'));

  const expertEnum = expert?.properties?.expertType.enum;
  const expertEnumNames = expert?.properties?.expertType.enumNames;

  return {
    urlDescription: schema.properties?.url?.description,
    tagsTitle: schema.properties?.tags?.title,
    ownersTitle: schema.properties?.owners?.title,
    reviewersTitle: schema.properties?.reviewers?.title,
    expertsTitle: expert?.title,
    hintsTitle: hints?.title,
    hints: hints,
    expertTypeTitles: expertEnum && expertEnumNames ? Object.fromEntries(expertEnum.map((e, i: number) => [e, expertEnumNames[i]]) || []) : {},
    saleable: saleable,
    components: components,
    target: target,
    questions: questions,
    technicalRequirementsTitle: target?.properties?.technicalRequirements?.title,
    processInformation: {
      title: processInformation?.title,
    },
    solutionScopes: {
      jobRoleId: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.jobRole.$id,
      solutionScopeTitle: solutionScopes?.title,
      solutionDomainTitle: solutionScopes?.properties.solutionScopes.items.properties.solutionDomain.title,
      jobRoleTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.jobRole.title,
      shortDescriptionTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.shortDescription.title,
      shortDescription: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.shortDescription,
      descriptionTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.description.title,
      description: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.description,
      scdJobRoleTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.title,
      uspsTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.usps.title,
      usps: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.usps,
      customerVoicesTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.customerVoices.title,
      customerVoices: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.customerVoices,
      successStoriesTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.successStories.title,
      successStories: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.successStories,
      insightsTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.insights.title,
      insights: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.insights,
      solutionVideosTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.solutionVideos.title,
      solutionVideos: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.solutionVideos,
      solutionWebinarsTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.solutionWebinars.title,
      solutionWebinars: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.solutionWebinars,
      assumptionsTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.assumptions.title,
      assumptions: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.assumptions,
      intendedOutcomesTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.intendedOutcomesInfo.title,
      intendedOutcomes:
        solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.intendedOutcomesInfo.properties.intendedOutcomes,
      solutionInfosTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.solutionInfos.title,
      solutionInfos: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.solutionInfos,
      challengesTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.challengesInfo.properties.challenges.title,
      challenges: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.challengesInfo.properties.challenges,
      supportDescriptionTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.descriptionOfSupport.title,
      supportDescription: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.descriptionOfSupport,
      featureInfoTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.featureInfo.properties.features.title,
      featureInfo: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.featureInfo.properties.features,
      howToProceedTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.howToProceed.title,
      howToProceed: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.howToProceed,
      alternateNameTitle: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.alternateName.title,
      alternateName: solutionScopes?.properties.solutionScopes.items.properties.sdcs.items.properties.alternateName,
    },
  };
};
