import * as _ from 'lodash';
import { CpaPage } from '@cp/base-types/src/ontology/schemas';
import { getEndpoint } from '@cpa/base-core/api';
import { IDataItem } from '@cpa/base-core/types';
import { IJSONSchema } from '@cp/base-types';

enum OperationTypes {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
  MOVE = 'move',
  COPY = 'copy',
  TEST = 'test',
}

export async function saveLocalizedField(args: {
  page: CpaPage;
  identifier: string | undefined;
  fieldPath: string;
  fieldSchema: IJSONSchema;
  formData: IDataItem;
  language: string;
}): Promise<{ _eTag: string }> {
  if (!args.page.dataUrl || !args.identifier || !args.page.dataEndpoint) {
    const errorMessages = [];

    if (!args.page.dataUrl) {
      errorMessages.push('CpaPage.dataUrl is missing.');
    }

    if (!args.identifier) {
      errorMessages.push('Identifier is missing.');
    }

    if (!args.page.dataEndpoint) {
      errorMessages.push('CpaPage.dataEndpoint is missing.');
    }

    throw new Error('Could save field localization. ' + errorMessages.join(' '));
  }

  const fieldName = _.toPath(args.fieldPath).slice(-1)[0];
  let fieldValue = args.formData![fieldName];

  if (fieldValue === undefined) {
    if (args.fieldSchema.type === 'array') {
      fieldValue = [];
    } else if (args.fieldSchema.type === 'string') {
      fieldValue = '';
    } else {
      fieldValue = null;
    }
  }

  const endpoint = getEndpoint(args.page.dataEndpoint.identifier!);

  const url = new URL(args.page.dataUrl!, window.location.origin);

  if (!url.pathname.endsWith('/')) {
    url.pathname += '/';
  }

  url.pathname += `${encodeURIComponent(args.identifier)}`;

  const path = `/${_.toPath(args.fieldPath).join('/')}`;

  const patch = [{ op: OperationTypes.ADD, path, value: fieldValue }];

  const response = await endpoint.axios.patch(url.pathname, patch, {
    headers: {
      'Content-Language': args.language,
      'Content-Type': 'application/json-patch+json',
    },
  });

  return { _eTag: response.value._eTag };
}
