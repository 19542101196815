const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpFunction',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFunction' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFieldTrigger?$filter=function%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypeTrigger?$filter=function%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpJobDefinition?$filter=jobType%2Ffunction%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=actions%2Ffunction%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Function',
    'properties': {
        'sourceCode': {
            'title': 'Source Code',
            'description': '<div>The definition of the function source code. Written in Vanilla JS.</div>',
            'type': 'string',
            'format': 'cp:code',
            'default': 'trigger.execute = function(executionContext, utils) {\n  // Here we write code that will be called on every execute\n};\n',
            'cp_ui': { 'sortOrderForm': 0 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sourceCode', 'rel': 'canonical' }],
        },
        'domains': {
            'title': 'Domains',
            'description': '<div>The domain of a function like class, e.g. cp:CpFunction.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpClass',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpClass' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': true,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cp Class',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Class' } },
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/domain', 'rel': 'canonical' }],
        },
        'isManagedExternally': {
            'title': 'Is Managed Externally',
            'description': '<div>An externally managed function is managed in some GIT repositiory and can not be modified through the Admin Center.</div>',
            'type': 'boolean',
            'default': false,
            'cp_ui': { 'hiddenInForm': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isManagedExternally', 'rel': 'canonical' }],
        },
        'allowedTriggerTypes': {
            'title': 'Allowed Trigger Types',
            'description': '<div>A type of trigger that is compatible with the CpFunction.</div>',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpFieldTriggerType',
                        'type': 'string',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFieldTriggerType' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Field Trigger Type',
                        'enum': [
                            'http://platform.cosmoconsult.com/ontology/OnInputBlur',
                            'http://platform.cosmoconsult.com/ontology/OnLookup',
                            'http://platform.cosmoconsult.com/ontology/OnValidate',
                            'http://platform.cosmoconsult.com/ontology/OnMonacoGetType',
                            'http://platform.cosmoconsult.com/ontology/OnInputInit',
                        ],
                        'enumNames': ['On Input Blur', 'On Lookup', 'On Validate', 'On Monaco Get Type', 'On Input Init'],
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpTypeTriggerType',
                        'type': 'string',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypeTriggerType' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Type Trigger Type',
                        'enum': [
                            'http://platform.cosmoconsult.com/ontology/OnAfterCreate',
                            'http://platform.cosmoconsult.com/ontology/OnAfterDelete',
                            'http://platform.cosmoconsult.com/ontology/OnAfterFormChange',
                            'http://platform.cosmoconsult.com/ontology/OnAfterFormClose',
                            'http://platform.cosmoconsult.com/ontology/OnAfterFormSubmit',
                            'http://platform.cosmoconsult.com/ontology/OnAfterPublishMajorVersion',
                            'http://platform.cosmoconsult.com/ontology/OnAfterRead',
                            'http://platform.cosmoconsult.com/ontology/OnAfterUiItemRead',
                            'http://platform.cosmoconsult.com/ontology/OnAfterUpdate',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeCreate',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeDelete',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeFormInit',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeFormSubmit',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeFormClose',
                            'http://platform.cosmoconsult.com/ontology/OnBeforePublishMajorVersion',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeRead',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeUiCreate',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeUiDelete',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeUiDrop',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeUiUpdate',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeUpdate',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeUiUserMachineTranslate',
                            'http://platform.cosmoconsult.com/ontology/OnBeforeVectorize',
                        ],
                        'enumNames': [
                            'On After Create',
                            'On After Delete',
                            'On After Form Change',
                            'On After Form Close',
                            'On After Form Submit',
                            'On After Publish Major Version',
                            'On After Read',
                            'On After Ui Item Read',
                            'On After Update',
                            'On Before Create',
                            'On Before Delete',
                            'On Before Form Init',
                            'On Before Form Submit',
                            'On Before Form Close',
                            'On Before Publish Major Version',
                            'On Before Read',
                            'On Before Ui Create',
                            'On Before Ui Delete',
                            'On Before Ui Drop',
                            'On Before Ui Update',
                            'On Before Update',
                            'On Before Ui User Machine Translate',
                            'On Before Vectorize',
                        ],
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/OtherTriggerType',
                        'type': 'string',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FOtherTriggerType' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'enum': ['http://platform.cosmoconsult.com/ontology/OnPageAction', 'http://platform.cosmoconsult.com/ontology/ExecuteCpFunction'],
                        'enumNames': ['On Page Action', 'Execute Cp Function'],
                        'title': 'Other Trigger Type',
                    },
                ],
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/allowedTriggerType', 'rel': 'canonical' }],
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'] },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div><strong>Naming convention</strong>: <span style="background-color: rgba(0, 0, 0, 0.1);">&lt;TypeName&gt;.&lt;FictitiousFunctionName&gt;</span>. If the function can be used for all types, use <span style="background-color: rgba(0, 0, 0, 0.1);">Thing.&lt;FictitiousFunctionName&gt;</span>. \'FictitiousFunctionName\' should describe (as best as possible) what the function does.</div>',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['sourceCode', 'name'],
};
export const CpFunctionSchema = Schema;
