import React, { useEffect, CSSProperties, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISingleItemTemplateProps } from '@cpa/base-core/types';
import IframeResizer from 'iframe-resizer-react';

import styles from './DocumentationSingleItemTemplate.module.scss';

const iframeStyle: CSSProperties = { width: '1px', minWidth: '100%' };

function pushHistoryState(url: URL): void {
  window.history.cpaSpecialHistoryHandling = true;
  try {
    if (url.href && url.origin === window.location.origin && url.href !== window.location.href) {
      window.history.replaceState({ replaceLocationWithUrl: window.location.href }, '', url);
    }
  } catch {}
}

const DocumentationSingleItemTemplate: React.FC<ISingleItemTemplateProps> = ({ item }) => {
  const [, i18n] = useTranslation();
  const [iframeLocation, setIframeLocation] = useState(() => {
    const originalUrl = new URL(window.location.href).searchParams.get('originalUrl');

    // Try add or replace culture
    const itemUrl = new URL(item.url as string);
    if (itemUrl.pathname) {
      // Add or replace culture - only in front of path is supported here
      const regEx = new RegExp(/^\/(?<culture>[a-z]{2}-[a-zA-Z]{2}|[a-z]{2})\/(?<path>.*)$/);
      const culturePath = '/' + i18n.language.toLowerCase(); // Lower case is common for Docs
      // Add CPA culture in front
      if (!itemUrl.pathname.match(regEx)) {
        itemUrl.pathname = culturePath + itemUrl.pathname;
      } else {
        itemUrl.pathname = itemUrl.pathname.replace(regEx, culturePath + '/$<path>');
      }
    }

    // Use originalUrl with priority
    return new URL(originalUrl || itemUrl);
  });

  useEffect(() => {
    const handleWindowMessage = (event: MessageEvent): void => {
      if (!event.data || typeof event.data !== 'string') {
        return;
      }

      try {
        const parsedData = JSON.parse(event.data);
        if (typeof parsedData !== 'object') {
          return;
        }
        if (Array.isArray(parsedData) && parsedData[0] === 'locationChange') {
          // We track iframe location and update browser location in realtime
          const url = new URL((parsedData[1] as Location)?.href);
          pushHistoryState(url);
        }
      } catch (e) {}
    };

    window.addEventListener('message', handleWindowMessage);
    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, []);

  useEffect(() => {
    const handlePopState = (): void => {
      setIframeLocation(new URL(window.location.href));
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return useMemo(() => {
    return (
      <IframeResizer
        heightCalculationMethod={'documentElementScroll'}
        bodyBackground={'transparent'}
        bodyMargin={0}
        bodyPadding={0}
        className={styles.wrapper}
        src={iframeLocation.href}
        style={iframeStyle}
      />
    );
  }, [iframeLocation]);
};

export default DocumentationSingleItemTemplate;
