import { generateFileUploadUrl, uploadFileToAzureStorage } from '@cpa/base-core/api';
import { isCancelError } from '@cpa/base-http';

export const imageUpload = async (
  blobInfo: {
    filename: () => string;
    blob: () => Blob;
  },
  success: (url: string) => void,
  failure: (message: string, options?: { remove: boolean }) => void,
  progress: (n: number) => void
): Promise<void> => {
  try {
    // File upload to Azure
    const fileUploadDetails = await generateFileUploadUrl(blobInfo.filename());
    await uploadFileToAzureStorage(fileUploadDetails.uploadUrl, blobInfo.blob(), (p: number) => {
      progress(p);
    });

    success(fileUploadDetails.downloadUrl);
  } catch (e) {
    if (isCancelError(e)) {
      return;
    }

    console.error('Failed to upload image', e);
    failure(e.message || 'Unknown Error');
  }
};
