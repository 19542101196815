import { MessageBarType, Sticky, StickyPositionType } from '@fluentui/react';
import { ErrorListProps } from '@rjsf/core';
import React from 'react';
import { useMemo } from 'react';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import MessageBars from '../../../MessageBars/MessageBars';

import styles from './ErrorList.module.scss';

const ErrorList: FC<ErrorListProps> = ({ errors }) => {
  const errorMessages = useMemo(() => errors.map(({ stack }) => stack), [errors]);
  const mobileOnly = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <Sticky stickyPosition={StickyPositionType.Header} stickyClassName={styles.sticky}>
      <MessageBars
        isClosable={false}
        messageBarType={MessageBarType.error}
        rootProps={{ style: { maxHeight: mobileOnly ? '20vh' : 200, overflowY: 'scroll', marginBottom: 20 } }}
        messages={errorMessages}
      />
    </Sticky>
  );
};

export default ErrorList;
