import { IJSONSchema, IRelatedLink, Schemas } from '@cp/base-types';
import { getIntermediateAnyOfSchema } from '@cp/base-utils';
import { axiosDictionary, postEntityToEndpoint } from '@cpa/base-core/api';
import { EndpointContext, FormContext, GenericScreenContext, ItemInfoContext, PathContext, TableKeyPrefix } from '@cpa/base-core/constants';
import { getDataUrlForLookup, getMatchingPageByDataUrl } from '@cpa/base-core/helpers';
import { useLiveUpdates, useLoadableData, usePowerUser, useUrlForSubscription } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { wsSubscribeToEntityModification } from '@cpa/base-core/store/websocket/actions';
import { DynamicDataUrlFunction, IDataItem, IDataUrlDetails, IGenericComponentData, IScrollableContent, OrderDirection } from '@cpa/base-core/types';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import {
  CommandBarButton,
  DialogType,
  DirectionalHint,
  IObjectWithKey,
  IconButton,
  MessageBarType,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import Form, { Registry, WidgetProps } from '@rjsf/core';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getSchemaUIOptions } from '../../../../../../screens/GenericScreen/utils';
import DrawerOrCallout from '../../../../../DrawerOrCallout/DrawerOrCallout';
import ItemInfo from '../../../../../ItemInfo/ItemInfo';
import { IItemSelectionDialogRef, ItemSelectionDialog } from '../../../../../ItemSelectionDialog/ItemSelectionDialog';
import ScrollingContent from '../../../../../ScrollingContent/ScrollingContent';
import TitleField, { TitleFieldType } from '../../../TitleField/TitleField';

import styles from './Relation.module.scss';

interface IRelationProps extends WidgetProps {
  schema: IJSONSchema;
  uiProps: Record<string, unknown>;
  registry: Registry & {
    rootSchema?: IJSONSchema;
    rootFormData?: IDataItem;
    formRef?: Form<unknown>;
  };
  lookupLink: IRelatedLink;
  emptyValue?: boolean | number | string | object | null;
  parentSchema?: IJSONSchema;
  hideLabel?: boolean;
  onBlur: (id: string, value: boolean | number | string | null, item?: IDataItem) => void;
  expandedItem?: IDataItem;
}

const searchBoxIcon = { iconName: 'BulletedList' };

const Relation: React.FC<IRelationProps> = ({
  registry,
  id,
  readonly,
  required,
  disabled,
  value, // Value contains a string value with related identifier
  expandedItem,
  onChange,
  schema: fieldSchema,
  onBlur,
  onFocus,
  lookupLink,
  emptyValue,
  parentSchema,
  label,
  hideLabel,
  placeholder,
}) => {
  const { startAction, finishAction, page } = useContext(FormContext);
  const itemInfoContext = useContext(ItemInfoContext);
  const pathContextValue = useContext(PathContext);
  const genericScreenContext = useContext(GenericScreenContext);

  const intermediateAnyOfSchema = useMemo(
    () => (parentSchema ? getIntermediateAnyOfSchema(pathContextValue, parentSchema, fieldSchema) : undefined),
    [fieldSchema, parentSchema, pathContextValue]
  );

  const dispatch = useDispatch();

  const dialogLookup = parentSchema?.cp_ui?.dialogLookup || fieldSchema.cp_ui?.dialogLookup || intermediateAnyOfSchema?.cp_ui?.dialogLookup;

  const disableLookupAdvancedFilter =
    parentSchema?.cp_ui?.disableLookupAdvancedFilter ||
    fieldSchema.cp_ui?.disableLookupAdvancedFilter ||
    intermediateAnyOfSchema?.cp_ui?.disableLookupAdvancedFilter;

  const inputDisabled =
    disabled ||
    (fieldSchema as { disabled: boolean }).disabled ||
    readonly ||
    (
      fieldSchema as {
        readonly: boolean;
      }
    ).readonly;

  const advancedFilterEnabled = !disableLookupAdvancedFilter && !inputDisabled && !dialogLookup;

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const pages = useSelector((state: IGlobalState) => state.app.pages);
  const powerUser = usePowerUser();
  const tableRef = useRef<IScrollableContent>(null);
  const [t] = useTranslation();
  const endpointIdentifierFromContext = useContext(EndpointContext);
  const endpointIdentifier = lookupLink.endpoint || endpointIdentifierFromContext;

  // Input width
  const inputFieldDivRef = useRef<HTMLDivElement>(null);

  const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] = useBoolean(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const addButtonId = useId('add-button');

  // Value that's displayed inside ui input, name or identifier
  const [inputValue, setInputValue] = useState(value);
  const [savedInputValue, setSavedInputValue] = useState(value);
  const selectedValue = useRef(value);
  const selectedItem = useRef<IDataItem | null>(null);

  // Data
  const [virtualData, setVirtualData] = useState<{ schema: IJSONSchema; items: IDataItem[]; totalItems: number }>();
  const wrappedGetDataUrl = useCallback(async (): Promise<IDataUrlDetails | null> => {
    return await getDataUrlForLookup(
      lookupLink,
      parentSchema,
      fieldSchema,
      pathContextValue,
      registry,
      id,
      itemInfoContext?.type,
      page,
      itemInfoContext?.showMessage,
      setVirtualData
    );
  }, [pathContextValue, parentSchema, fieldSchema, id, lookupLink, itemInfoContext?.showMessage, itemInfoContext?.type, page, registry]);

  const matchedPage: Schemas.CpaPage | undefined = useMemo(() => {
    const url = new URL(lookupLink.href, window.location.origin);
    return getMatchingPageByDataUrl(url.pathname + url.search, pages)?.matched;
  }, [lookupLink.href, pages]);

  const {
    loadItems,
    isFetching: isFetchingLoadable,
    items: itemsLoadable,
    totalItems: totalItemsLoadable,
    schema: schemaLoadable,
    cancelLoading,
    isODataSupportedByEndpoint,
    forceSetItems,
    errors,
    forceSetTotalItems,
  } = useLoadableData(
    wrappedGetDataUrl,
    endpointIdentifier || axiosDictionary.appDataService,
    matchedPage?.groupPropertyJsonPath,
    matchedPage?.schemaUrl as string | undefined
  );
  const isFetching = virtualData ? false : isFetchingLoadable;
  const items = virtualData?.items ?? itemsLoadable;
  const totalItems = virtualData?.totalItems ?? totalItemsLoadable;
  const schema = virtualData?.schema ?? schemaLoadable;

  useEffect(() => {
    if (errors.length && itemInfoContext) {
      for (const error of errors) {
        itemInfoContext.showMessage(error, MessageBarType.error);
      }
    }
  }, [errors, itemInfoContext]);

  const uiOptions = useMemo(() => getSchemaUIOptions(schema), [schema]);
  const nameRestored = useRef<boolean>(false);

  const fallbackPage = useMemo(() => {
    return {
      identifier: 'relation-table',
      name: schema?.title || 'Relation',
      dataEndpoint: endpointIdentifier
        ? {
            identifier: endpointIdentifier,
          }
        : undefined,
    } as Schemas.CpaPage;
  }, [schema, endpointIdentifier]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchText = useCallback(
    _.debounce((v: string) => {
      tableRef.current?.setSearchText(v);
    }, 500),
    []
  );

  useEffect((): void => {
    // Restore name in input
    const canRestoreName = !nameRestored.current;

    if (items.length === 1 && value && value === items[0]?.identifier && canRestoreName) {
      nameRestored.current = true;
      const fetchedItemName = items[0].name?.toString() || items[0].identifier?.toString() || '';
      setInputValue(fetchedItemName);
      setSavedInputValue(fetchedItemName);
      setSearchText(fetchedItemName);
      selectedItem.current = items[0];
      selectedValue.current = items[0].identifier;
    } else if (
      items.length === 1 &&
      inputValue &&
      (items[0].name?.toString()?.toLowerCase() === inputValue?.toLowerCase() ||
        items[0].identifier?.toString()?.toLowerCase() === inputValue?.toLowerCase())
    ) {
      selectedValue.current = items[0].identifier;
      selectedItem.current = items[0];
      onChange(items[0].identifier);
      setSavedInputValue(items[0].name?.toString() || items[0].identifier?.toString() || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    // Cancel previous request
    cancelLoading(cancelToken.current);
    nameRestored.current = true;

    cancelToken.current = createCancelToken();

    if (value !== selectedValue.current) {
      if (!value) {
        selectedValue.current = emptyValue;
        selectedItem.current = null;
        setInputValue('');
      }
      // Value change from outside
      if (value && typeof value === 'string') {
        nameRestored.current = false;
        loadItems(
          { top: 1, filter: { identifier: encodeURIComponent(value) } },
          {
            overwriteExistingData: true,
            cancelToken: cancelToken.current || undefined,
          }
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (expandedItem && expandedItem.name) {
      // No need to restore name, relation is expanded
      nameRestored.current = true;
      const expandedItemName = expandedItem.name.toString();
      setInputValue(expandedItemName);
      setSavedInputValue(expandedItemName);
      setSearchText(expandedItemName);
      selectedItem.current = expandedItem;
      selectedValue.current = expandedItem.identifier;
    } else if (value && typeof value === 'string') {
      setInputValue(value);
      setSavedInputValue(value);

      // Restore name by fetching item
      nameRestored.current = false;
      loadItems(
        {
          top: 1,
          filter: { identifier: encodeURIComponent(value) },
        },
        { overwriteExistingData: true }
      ).then(({ items }) => {
        onBlur?.(id, selectedValue.current, items[0]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointIdentifier, lookupLink]);

  const windowBlurred = useRef(false);
  const focusHandlingDisabled = useRef(false);
  const onBoxFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (focusHandlingDisabled.current || windowBlurred.current) {
        if (focusHandlingDisabled.current) {
          event.target.blur();
        }
        focusHandlingDisabled.current = false;
        windowBlurred.current = false;
        return;
      }

      // On callout open

      if (selectedValue.current) {
        if (selectedItem.current && schema) {
          cancelLoading(cancelToken.current);
          forceSetItems([selectedItem.current.__originalItem || selectedItem.current]);
        } else {
          cancelLoading(cancelToken.current);
          cancelToken.current = createCancelToken();
          loadItems(
            { top: 1, filter: { identifier: encodeURIComponent(selectedValue.current) } },
            { overwriteExistingData: true, cancelToken: cancelToken.current || undefined }
          );
        }
      } else {
        cancelLoading(cancelToken.current);
        cancelToken.current = createCancelToken();
        loadItems(
          {
            top: 10,
            orderBy: matchedPage?.initialOrderPropertyJsonPath
              ? [[matchedPage.initialOrderPropertyJsonPath, matchedPage.initialOrderDirection === OrderDirection.DESCENDING ? 'desc' : 'asc']]
              : undefined,
          },
          { overwriteExistingData: true, cancelToken: cancelToken.current || undefined }
        );
      }

      showCallout();
      onFocus?.(id, value);
    },
    [
      showCallout,
      onFocus,
      id,
      value,
      cancelLoading,
      forceSetItems,
      loadItems,
      matchedPage?.initialOrderPropertyJsonPath,
      matchedPage?.initialOrderDirection,
      schema,
    ]
  );

  const onBoxBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (focusHandlingDisabled.current) {
        return;
      }

      // Do not reset filter if form is open
      if (event?.relatedTarget?.id === addButtonId) {
        return;
      }

      if (event.target === document.activeElement) {
        windowBlurred.current = true;
        return;
      }

      if (inputValue !== savedInputValue && !items.some((el) => el.name === inputValue || el.identifier === inputValue)) {
        setInputValue(savedInputValue);
        setSearchText(savedInputValue);
      }

      // Let form state update after changes
      // Issue example:
      // OnChange with empty value -> SetState -> OnBlur which takes old value -> SetState -> React StateUpdate with state from OnBlur)
      startAction();
      setTimeout(async () => {
        try {
          await onBlur?.(id, selectedValue.current, selectedItem.current || undefined);
        } finally {
          finishAction();
        }
      }, 150);
    },
    [inputValue, savedInputValue, items, startAction, setSearchText, onBlur, id, finishAction]
  );

  const onCalloutDismiss = useCallback(() => {
    hideCallout();
    if (value === undefined) {
      // @all: clear displayed input value
      setInputValue('');
      setSearchText('');
    } else if (inputValue && inputValue !== savedInputValue) {
      const target = items.find((el) => el.name === inputValue || el.identifier === inputValue);
      if (target) {
        setSavedInputValue(target.name?.toString() || '');
        setInputValue(target.name?.toString() || '');
        setSearchText(target.name?.toString() || '');
        selectedValue.current = target.identifier;
        selectedItem.current = target;
        onChange(target.identifier);
      }
    }
  }, [hideCallout, value, inputValue, savedInputValue, setSearchText, items, onChange]);

  const onBoxChange = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
      setSearchText(inputValue);
      setInputValue(inputValue);

      if (!inputValue) {
        setSavedInputValue(inputValue);
        selectedValue.current = emptyValue;
        selectedItem.current = null;
        onChange(emptyValue);
      }

      if (!isCalloutVisible && !dialogLookup) {
        showCallout();
      }
    },
    [setSearchText, isCalloutVisible, dialogLookup, emptyValue, onChange, showCallout]
  );

  const handleItemClick = useCallback(
    (item: IDataItem, selection?: Selection<IObjectWithKey & IDataItem>, schema?: IJSONSchema | null, disableFocus: boolean = true) => {
      if (!item?.identifier) {
        return;
      }

      const newSuggestionsValue = item.name?.toString() || item.identifier.toString();
      setInputValue(newSuggestionsValue);
      setSearchText(newSuggestionsValue);
      setSavedInputValue(newSuggestionsValue);
      tableRef.current?.setSearchText(newSuggestionsValue);
      selectedValue.current = item.identifier;
      selectedItem.current = item;
      onChange(item.identifier);

      hideCallout();

      if (disableFocus) {
        focusHandlingDisabled.current = true;
        inputFieldDivRef.current?.blur();
      }
    },
    [setSearchText, onChange, hideCallout]
  );

  const handleKeyDown = useCallback(() => {
    /* Need to keep it empty to prevent the callout from being closed by escape */
  }, []);

  const tableData: IGenericComponentData = useMemo(
    () => ({
      items: items,
      schema: schema,
      isFetching: isFetching,
      totalItems: totalItems,
      page: matchedPage || fallbackPage,
    }),
    [fallbackPage, isFetching, items, matchedPage, schema, totalItems]
  );

  const urlForSubscription: string | null = useUrlForSubscription(tableData.page.cpTypeUrl);
  // Cp Type subscription
  useEffect(() => {
    if (!urlForSubscription) {
      return;
    }

    dispatch(wsSubscribeToEntityModification(urlForSubscription));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlForSubscription]);

  useLiveUpdates(
    loadItems,
    items,
    (items) => {
      forceSetItems(items);
    },
    forceSetTotalItems,
    totalItems,
    tableData.page,
    () => {
      return true;
    },
    false
  );

  const handleFilterReset = useCallback(() => {
    onBoxChange(undefined, '');
    loadItems(
      {
        top: 10,
        orderBy: matchedPage?.initialOrderPropertyJsonPath ? [[matchedPage.initialOrderPropertyJsonPath, 'asc']] : undefined,
      },
      { overwriteExistingData: true }
    );
  }, [loadItems, matchedPage?.initialOrderPropertyJsonPath, onBoxChange]);

  const itemSelectionDialogRef = useRef<IItemSelectionDialogRef>(null);
  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: label || t('common.relation'),
    }),
    [label, t]
  );
  const openItemSelectionDialog = useCallback((event?: React.MouseEvent<unknown>) => {
    event?.preventDefault();

    itemSelectionDialogRef.current?.openDialog();
  }, []);

  const handleEnterKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (dialogLookup && (event.key === 'Enter' || event.key === ' ')) {
        openItemSelectionDialog();
      }
    },
    [openItemSelectionDialog, dialogLookup]
  );

  const itemSelectionDialogPage = useMemo(
    () =>
      ({
        ...(matchedPage || fallbackPage),
        dynamicDataUrl: wrappedGetDataUrl,
      } as Schemas.CpaPage & { dynamicDataUrl?: DynamicDataUrlFunction }),
    [fallbackPage, matchedPage, wrappedGetDataUrl]
  );
  const handleItemSelectionDialogSelect = useCallback(
    (item: IDataItem) => {
      handleItemClick(item, undefined, schema, !!dialogLookup);
    },
    [dialogLookup, handleItemClick, schema]
  );

  const handleFormOpen = useCallback(() => {
    setIsFormOpen((isOpen) => !isOpen);
  }, []);

  const handleItemAdd = useCallback(
    async (item: IDataItem) => {
      if (!tableData.page.dataUrl || !schema) {
        return;
      }

      await postEntityToEndpoint(
        tableData.page.dataEndpoint?.identifier || axiosDictionary.appDataService,
        tableData.page.dataUrl,
        { ...item },
        schema
      );
    },
    [schema, tableData.page.dataEndpoint?.identifier, tableData.page.dataUrl]
  );

  const calloutContent = useCallback(() => {
    if (!schema && isFetching) {
      return (
        <div className={styles.loader}>
          <Spinner size={SpinnerSize.large} />
        </div>
      );
    }
    if (!schema) {
      return null;
    }

    const showResetFilter = !!selectedValue.current;

    return (
      <aside>
        <div style={{ padding: 0, zIndex: 1000 }} className={darkMode ? styles.relationDark : styles.relation}>
          <ScrollingContent
            tableKey={`${TableKeyPrefix.Relation}.${schema.$id || 'default'}`}
            ref={tableRef}
            hideHeader={false}
            hideActions={true}
            hideSelectedLabel={true}
            disableDoubleClick={true}
            selectionMode={SelectionMode.none}
            hideFilter={true}
            isWidget={true}
            pageSize={10}
            loadItems={loadItems}
            hiddenInTable={uiOptions.hiddenInTable}
            onItemClick={handleItemClick}
            disabledManagedColumnsWidth={false}
            parentPropertyJsonPath={parentSchema?.cp_parentPropertyJsonPath ?? schema?.cp_parentPropertyJsonPath}
            isODataSupportedByEndpoint={isODataSupportedByEndpoint}
            data={tableData}
            disableDragDrop={true}
            disableAddButtonInTable={true}
            initialFilterValue={inputValue}
          />
          {(showResetFilter || advancedFilterEnabled) && (
            <div>
              <div className={styles.footerContainer}>
                {tableData.page?.allowCreate ? (
                  <div>
                    <CommandBarButton
                      className={styles.footerButton}
                      iconProps={{
                        iconName: 'CalculatorAddition',
                        className: classNames(styles.addIcon, 'addIcon'),
                      }}
                      id={addButtonId}
                      text={t('common.newItem', { name: schema.title })}
                      onClick={handleFormOpen}
                    />
                  </div>
                ) : null}
                <div className={styles.filterWrapper}>
                  {advancedFilterEnabled && (
                    <CommandBarButton
                      className={styles.footerButton}
                      iconProps={{ iconName: 'search', className: classNames(styles.addIcon, 'addIcon') }}
                      text={t('common.advanced')}
                      onClick={openItemSelectionDialog}
                    />
                  )}
                </div>
              </div>
              {showResetFilter && (
                <p className={styles.footerMessage}>
                  {t('common.itemsFiltered')}{' '}
                  {handleFilterReset && (
                    <span className={styles.link} onClick={handleFilterReset}>
                      {t('common.resetFilter')}
                    </span>
                  )}
                </p>
              )}
            </div>
          )}
          <ItemInfo isOpened={isFormOpen} onClose={handleFormOpen} data={tableData} onAdd={handleItemAdd} readonly={false} item={null} type={'add'} />
        </div>
      </aside>
    );
  }, [
    addButtonId,
    handleItemAdd,
    handleFormOpen,
    isFormOpen,
    schema,
    isFetching,
    darkMode,
    loadItems,
    uiOptions.hiddenInTable,
    handleItemClick,
    parentSchema?.cp_parentPropertyJsonPath,
    isODataSupportedByEndpoint,
    tableData,
    advancedFilterEnabled,
    openItemSelectionDialog,
    t,
    handleFilterReset,
    inputValue,
  ]);

  const input = useCallback(() => {
    const eventHandlingProps = dialogLookup
      ? {
          onClick: openItemSelectionDialog,
          style: {
            cursor: 'pointer',
          },
          onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
          },
        }
      : {
          onChange: onBoxChange,
          onFocus: onBoxFocus,
          onBlur: onBoxBlur,
        };
    return (
      <div ref={inputFieldDivRef} style={{ display: 'flex' }}>
        <div
          style={{
            flex: 1,
          }}
          tabIndex={dialogLookup ? 0 : undefined}
          onKeyDown={handleEnterKeyDown}
        >
          <SearchBox
            disabled={inputDisabled}
            autoComplete={'off'}
            spellCheck={false}
            value={inputValue}
            iconProps={searchBoxIcon}
            disableAnimation={false}
            onEscape={handleKeyDown}
            title={label || fieldSchema.title}
            placeholder={placeholder}
            onClear={handleFilterReset}
            {...eventHandlingProps}
          />
        </div>
        {advancedFilterEnabled && powerUser && (
          <IconButton
            iconProps={{
              iconName: 'QueryList',
              style: {
                color: darkMode ? '#fefefe' : '#383838',
              },
            }}
            onClick={openItemSelectionDialog}
          />
        )}
      </div>
    );
  }, [
    handleEnterKeyDown,
    inputDisabled,
    inputValue,
    onBoxChange,
    onBoxFocus,
    onBoxBlur,
    handleKeyDown,
    label,
    fieldSchema.title,
    placeholder,
    handleFilterReset,
    dialogLookup,
    openItemSelectionDialog,
    advancedFilterEnabled,
    darkMode,
    powerUser,
  ]);

  const calloutProps = useMemo(
    () => ({
      hidden: !isCalloutVisible || items.length === 0,
      target: inputFieldDivRef as unknown as React.RefObject<Element>,
      isBeakVisible: false,
      alignTargetEdge: true,
      gapSpace: 2,
      directionalHint: DirectionalHint.bottomLeftEdge,
      onDismiss: onCalloutDismiss,
      setInitialFocus: false,
      preventDismissOnScroll: false,
      calloutWidth: inputFieldDivRef.current?.getBoundingClientRect().width,
      shouldUpdateWhenHidden: true,
      className: darkMode ? styles.calloutDark : styles.callout,
      calloutMaxHeight: 300,
      hideOverflow: true,
    }),
    [items.length, isCalloutVisible, onCalloutDismiss, darkMode]
  );

  const handleDrawerClose = useCallback(() => {
    focusHandlingDisabled.current = true;
    hideCallout();
  }, [hideCallout]);

  const itemSelectionDialogGenericScreenProps = useMemo(
    () => ({
      genericComponentProps: { scrollingContentProps: { defaultFilterExpanded: dialogLookup } },
    }),
    [dialogLookup]
  );

  return (
    <div className={classNames(styles.wrapper)}>
      {!hideLabel && (
        <TitleField
          title={label || fieldSchema.title}
          required={required}
          localizable={fieldSchema?.cp_localizable}
          registry={registry}
          schema={fieldSchema}
          type={TitleFieldType.Primitive}
        />
      )}
      <DrawerOrCallout
        title={t('common.relation')}
        renderBody={calloutContent}
        renderInput={input}
        calloutProps={calloutProps}
        isOpened={isCalloutVisible}
        onDrawerClose={handleDrawerClose}
      />
      <ItemSelectionDialog
        ref={itemSelectionDialogRef}
        page={itemSelectionDialogPage}
        onSubmit={handleItemSelectionDialogSelect}
        dialogContentProps={dialogContentProps}
        genericScreenProps={itemSelectionDialogGenericScreenProps}
      />
    </div>
  );
};

export default Relation;
