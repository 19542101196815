import React from 'react';
import { getSafeString } from '@cpa/base-core/helpers';
import { ICardProps, IGenericComponentData } from '@cpa/base-core/types';

import bgUrl1 from '../../../assets/illustrations/itemOfMany.svg';
import bgUrl2 from '../../../assets/illustrations/fastLoading.svg';
import bgUrl3 from '../../../assets/illustrations/featuresOverview.svg';
import SelectionButton from '../../../components/SelectionButton/SelectionButton';

import styles from './PromotedItemCard.module.scss';

const PromotedItemCard: React.FC<ICardProps> = ({ data, item, onClick, onItemSelect, isSelected, hideSelectionMarker }) => {
  const fullComponentData = data as IGenericComponentData;

  const i = fullComponentData.items.findIndex((arrItem) => arrItem.identifier === item.identifier);
  let bgImg;
  const noOfImages = 3;
  switch (true) {
    case i % noOfImages === 1:
      bgImg = bgUrl1;
      break;
    case i % noOfImages === 2:
      bgImg = bgUrl2;
      break;
    default:
      // i % noOfImages === noOfImages - noOfImages (= 0)
      bgImg = bgUrl3;
      break;
  }

  return (
    <div
      key={String(item?.identifier)}
      className={styles.item}
      onClick={onClick}
      style={{
        ...(bgImg && typeof bgImg === 'string' ? { backgroundImage: `url(${CSS.escape(bgImg)})` } : undefined),
        backgroundSize: '50%',
        backgroundPosition: 'center 20px',
        backgroundRepeat: 'no-repeat',
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      {!hideSelectionMarker && <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} />}
      <div className={styles.inner}>
        <div className={styles.title}>{item.name}</div>
        <div className={styles.text}>{getSafeString(item.description as string, true)}</div>
      </div>
    </div>
  );
};

export default PromotedItemCard;
