import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { Schemas, TypeConstants } from '@cp/base-types';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { DateLanguageMapping } from '@cpa/base-core/constants';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon, PersonaCoin, Shimmer } from '@fluentui/react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem, IRelatedMessage } from '@cpa/base-core/types';
import { useInView } from 'react-intersection-observer';
import { getFilenameFromURL } from '@cpa/base-core/helpers';

import { personImagesGlobalCache } from '../../../../../../card/ContactPersonCard/components/PersonCard/PersonCard';
import ShowMore from '../../../../../../../components/ShowMore/ShowMore';
import HtmlContent from '../../../../../../../components/HtmlContent/HtmlContent';
import HoverTooltip from '../../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './MessageItem.module.scss';
import RelatedMessage from './components/RelatedMessage/RelatedMessage';

interface IMessageItemProps {
  tableKey: string;
  message: IDataItem;
  isRead: boolean;
  onMessageReply: (message: IRelatedMessage) => void;
  onMessageInView: (messageIdentifier: string) => void;
  isFetching: boolean;
  isAllMessagesLoaded: boolean;
  onContentLoaded: () => void;
  onMessageSelected: (key: string) => void;
  isSelected: boolean;
  width?: number;
}

const MessageItem: React.FC<IMessageItemProps> = ({
  tableKey,
  message,
  onMessageReply,
  onMessageInView,
  isRead,
  isFetching,
  isAllMessagesLoaded,
  onContentLoaded,
  onMessageSelected,
  isSelected,
  width,
}) => {
  const [t, i18n] = useTranslation();
  const [timeFromNow, setTimeFromNow] = useState<string>(`(${moment(message.createdAt as string).fromNow()})`);
  const [userDetails, setUserDetails] = useState<Schemas.User | null>(null);
  const [imageUrl, setImageUrl] = useState<string>();
  const [loading, setLoading] = useState(true);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const appUser = useSelector((state: IGlobalState) => state.auth.user?.account.email);

  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });

  const cancelToken = useRef<CancelTokenSource | null>(null);

  const [viewMarker, viewMarkerInView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  useEffect(() => {
    if (!isFetching && !loading && !isRead && viewMarkerInView && message.createdByUser !== appUser) {
      onMessageInView(message.identifier as string);
    }
  }, [isRead, isFetching, loading, message.description, message.identifier, onMessageInView, viewMarkerInView, message.createdByUser, appUser]);

  useEffect(() => {
    moment.locale(DateLanguageMapping[i18n.language]);
    setTimeFromNow(`${moment(message.createdAt as string).fromNow()}`);
  }, [i18n.language, message.createdAt]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeFromNow(`${moment(message.createdAt as string).fromNow()}`);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [message.createdAt]);

  const getDetails = useCallback(async (): Promise<void> => {
    const dataServiceEndpoint = getEndpoint(axiosDictionary.appDataService);
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();
    try {
      setLoading(true);

      const response = await dataServiceEndpoint.axios.get(
        `data-store/${encodeURIComponent(TypeConstants.CompanyUser)}%3FallData=true/${encodeURIComponent(message.createdByUser as string)}`,
        {
          cancelToken: cancelToken.current.token,
        }
      );
      setUserDetails(response.value);
    } catch (e) {
      setUserDetails(null);
    } finally {
      onContentLoaded();
      setLoading(false);
    }
  }, [message.createdByUser, onContentLoaded]);

  useEffect(() => {
    getDetails();
    return () => {
      cancelToken.current?.cancel();
    };
  }, [cancelToken, getDetails]);

  useEffect(() => {
    if (!userDetails?.image || isMobileDevice) {
      return;
    }

    let cancelled = false;

    if (!personImagesGlobalCache.has(userDetails.image)) {
      personImagesGlobalCache.set(
        userDetails.image,
        getEndpoint(axiosDictionary.appDataService)
          .axios.get(userDetails.image, {
            responseType: 'blob',
          })
          .then((response: Blob) => {
            return URL.createObjectURL(response);
          })
      );
    }

    personImagesGlobalCache.get(userDetails.image)?.then((url: string) => {
      if (!cancelled) {
        setImageUrl(url);
      }
    });

    return (): void => {
      cancelled = true;
    };
  }, [userDetails?.image, isMobileDevice]);

  const isIncomingMessage = useMemo(() => {
    return message.createdByUser !== appUser;
  }, [appUser, message.createdByUser]);

  const handleMessageReply = useCallback(() => {
    onMessageReply({
      userName: userDetails?.name || (message.createdByUser as string),
      identifier: message.identifier as string,
      message: message.description as string,
    });
  }, [message.createdByUser, message.description, message.identifier, onMessageReply, userDetails?.name]);

  const messageClickHandler = useCallback(
    (e?: React.FormEvent<HTMLElement | HTMLInputElement>) => {
      e?.preventDefault();
      onMessageSelected(tableKey);
    },
    [onMessageSelected, tableKey]
  );

  const handleFileClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  if (loading) {
    return <Shimmer />;
  }

  return (
    <div className={styles.container}>
      <div onClick={messageClickHandler}>
        <Checkbox checked={isSelected} className={styles.checkbox} />
      </div>
      <div
        className={styles.messageWrapper}
        style={{ flexDirection: isIncomingMessage ? undefined : 'row-reverse', maxWidth: isMobileDevice ? '90%' : width }}
      >
        {!isMobileDevice ? (
          <PersonaCoin imageUrl={imageUrl} imageAlt={message.createdByUser as string} coinSize={35} style={{ cursor: 'default' }} />
        ) : null}
        <div
          className={classNames(styles.message, { [styles.light]: !darkMode, [styles.unreadMark]: !isRead, [styles.hidden]: isRead })}
          onClick={messageClickHandler}
        >
          <div className={styles.user}>
            <HoverTooltip content={message.createdByUser as string}>{userDetails?.name || (message.createdByUser as string)}</HoverTooltip>{' '}
            <strong>{timeFromNow}</strong>
          </div>
          {message.relatedTo ? <RelatedMessage relatedMessage={message.relatedTo as IDataItem} /> : null}
          {isAllMessagesLoaded ? <div ref={viewMarker} /> : null}
          <ShowMore maxHeight={150}>
            <HtmlContent className={styles.title} html={message.description as string} />
          </ShowMore>
          {(message.files as string[] | undefined)?.length ? (
            <div className={styles.files}>
              {((message.files as string[]) || []).map((file, index) => {
                return (
                  <div key={index} className={styles.file}>
                    <Icon iconName={'fileaspx'} />
                    <a onClick={handleFileClick} href={file} target="_blank" rel="noreferrer" className={styles.name}>
                      {getFilenameFromURL(file)}
                    </a>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <Icon className={styles.replyIcon} iconName={'reply'} onClick={handleMessageReply} />
        <HoverTooltip content={t(isRead ? 'messages.read' : 'common.unread')}>
          <div className={classNames(styles.unreadMark, { [styles.hidden]: isRead })}></div>
        </HoverTooltip>
      </div>
    </div>
  );
};

export default MessageItem;
