import { CultureInfo, verifyLanguage } from '@cp/base-utils';

import { i18n } from '../../app';
import { store } from '../../store';

export function getLocalized(message: { [key: string]: string }): string {
  if (!Object.keys(message).length) {
    return '';
  }

  const appLanguages: string[] = store.getState().app.locales.map((language) => language.identifier);

  // unify message language keys
  const sanitizedMessage: typeof message = {};
  Object.entries(message).forEach(([key, value]) => {
    const defaultCulture = 'xx-XX';
    const language = verifyLanguage(key, appLanguages, new CultureInfo(defaultCulture));
    if (language.specificName === defaultCulture) {
      // Key doesn't match app languages, skip it
      return;
    }
    sanitizedMessage[language.specificName] = value;
  });

  if (!Object.keys(sanitizedMessage).length) {
    // It can happen if 'message' locales do not match with appLanguages
    return Object.values(message)[0];
  }

  if (sanitizedMessage.hasOwnProperty(i18n.language)) {
    return sanitizedMessage[i18n.language];
  } else if (sanitizedMessage.hasOwnProperty('en-US')) {
    // fallback to english if current language is not in message
    return sanitizedMessage['en-US'];
  } else {
    // fallback to first value in message if english is not in message either
    return Object.values(sanitizedMessage)[0];
  }
}
