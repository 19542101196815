import { Json, Url } from '@cp/base-types';
import { Environment } from '@cpa/base-core/app/environment';
import { ICardProps } from '@cpa/base-core/types';
import React, { useCallback } from 'react';

import ImageItemCard from '../ImageItemCard';

const DocumentItemCard: React.FC<ICardProps> = ({ data, item, onItemSelect, isSelected, hideSelectionMarker }) => {
  // If defined open 'documentUrl' in new tab with 'contentDisposition' query parameter set to 'inline'
  const onClick = useCallback(() => {
    if (Json.isStr(item.documentUrl)) {
      const fileName = Json.isStr(item.name) ? encodeURIComponent(item.name) : 'document';
      window.open(
        Url.build({
          hostAndOrBasePath: Environment.env.REACT_APP_ROOT_DATA_SERVICE_URL,
          path: ['document-viewer', 'file-proxy', fileName],
          query: {
            url: Url.build({
              hostAndOrBasePath: item.documentUrl,
              query: {
                contentDisposition: 'inline',
              },
            }).toString(),
          },
        }).toString(),
        '_blank'
      );
    }
  }, [item.documentUrl, item.name]);

  // Resuse ImageItemCard with custom onClick
  return (
    <ImageItemCard
      data={data}
      item={item}
      onClick={onClick}
      onItemSelect={onItemSelect}
      isSelected={isSelected}
      hideSelectionMarker={hideSelectionMarker}
    />
  );
};

export default DocumentItemCard;
