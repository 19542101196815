import React, { useCallback, useMemo } from 'react';
import { ProgressIndicator, Rating, Text } from '@fluentui/react';
import { IDataItem } from '@cpa/base-core/types';

import styles from '../../Statement.module.scss';
import HoverTooltip from '../../../../components/HoverTooltip/HoverTooltip';

interface IRatingProps {
  data?: IDataItem<unknown>;
  labels: {
    total?: string;
    active?: string;
    revoked?: string;
    noData?: string;
  };
}

const RatingStatement: React.FC<IRatingProps> = ({ data, labels }) => {
  const getProgressPercentage = useCallback(
    (ratingLevel: number) => {
      return ratingLevel / (data?.count as number);
    },
    [data?.count]
  );

  const getTooltipContent = useCallback(
    (ratingLevel: number) => {
      return ratingLevel > 0 ? `${ratingLevel} (${getProgressPercentage(ratingLevel) * 100}%)` : '';
    },
    [getProgressPercentage]
  );

  const processedValue = useMemo(() => {
    const value = (data?.value as number) || 0;
    return +value.toFixed(1);
  }, [data?.value]);

  return (
    <>
      <div className={styles.left}>
        <div className={styles.ratings}>
          <Text variant={'medium'}>5</Text>
          <HoverTooltip content={getTooltipContent(data?.five as number)}>
            <ProgressIndicator barHeight={8} percentComplete={getProgressPercentage(data?.five as number)} />
          </HoverTooltip>
        </div>
        <div className={styles.ratings}>
          <Text variant={'medium'}>4</Text>
          <HoverTooltip content={getTooltipContent(data?.four as number)}>
            <ProgressIndicator barHeight={8} percentComplete={getProgressPercentage(data?.four as number)} />
          </HoverTooltip>
        </div>
        <div className={styles.ratings}>
          <Text variant={'medium'}>3</Text>
          <HoverTooltip content={getTooltipContent(data?.three as number)}>
            <ProgressIndicator barHeight={8} percentComplete={getProgressPercentage(data?.three as number)} />
          </HoverTooltip>
        </div>
        <div className={styles.ratings}>
          <Text variant={'medium'}>2</Text>
          <HoverTooltip content={getTooltipContent(data?.two as number)}>
            <ProgressIndicator barHeight={8} percentComplete={getProgressPercentage(data?.two as number)} />
          </HoverTooltip>
        </div>
        <div className={styles.ratings}>
          <Text variant={'medium'}>1</Text>
          <HoverTooltip content={getTooltipContent(data?.one as number)}>
            <ProgressIndicator barHeight={8} percentComplete={getProgressPercentage(data?.one as number)} />
          </HoverTooltip>
        </div>
      </div>
      <div className={styles.right}>
        <Text variant={'mega'}>{processedValue}</Text>
        <Rating allowZeroStars={true} max={5} rating={processedValue} readOnly={true} />
        <Text variant={'medium'}>
          {(data?.count as number) || 0} {labels.total}
        </Text>
      </div>
    </>
  );
};

export default RatingStatement;
