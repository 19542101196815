import { hideDialog } from '@cpa/base-core/store/app/actions';
import { GlobalDialogType, IDataItem, IGlobalDialogState } from '@cpa/base-core/types';
import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField, IChoiceGroupOption } from '@fluentui/react';
import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { Schemas } from '@cp/base-types';
import { useMediaQuery } from 'react-responsive';

import { IItemSelectionDialogRef, ItemSelectionDialog } from '../ItemSelectionDialog/ItemSelectionDialog';
import HtmlContent from '../HtmlContent/HtmlContent';

import FormDialog from './components/FormDialog/FormDialog';
import TableDialog from './components/TableDialog/TableDialog';

const modalProps = {
  styles: {
    layer: {
      zIndex: '100000010 !important',
    },
  },
};

export const GlobalDialog: React.FC<IGlobalDialogState> = ({
  type,
  dialogContentProps,
  modalProps,
  message,
  title,
  primaryOption,
  primaryButtonText,
  secondaryButtonText,
  closeOnClickOutside,
  closeOnAction,
  onClose,
  dialogTypeOptions,
  styles,
  isHtml,
  width,
  isMaximizable,
  className,
}) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' });
  const [maximized, setMaximized] = useState<boolean>(false);

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const itemSelectionDialogRef = useRef<IItemSelectionDialogRef>(null);

  const toggleMaximizeButton = useCallback(() => {
    setMaximized(!maximized);
  }, [maximized]);

  const mergedDialogContentProps = useMemo(() => {
    const propsWithTitle = _.merge(
      { subText: isHtml ? '' : message, title: title ? t(title) : undefined, styles: styles?.dialogContent },
      dialogContentProps
    );
    if (!isMaximizable) return propsWithTitle;
    return _.merge(propsWithTitle, {
      topButtonsProps: isSmallScreen
        ? undefined
        : [
            {
              iconProps: {
                iconName: maximized ? 'BackToWindow' : 'FullScreen',
              },
              onClick: toggleMaximizeButton,
            },
          ],
    });
  }, [isHtml, message, title, t, styles?.dialogContent, dialogContentProps, isMaximizable, isSmallScreen, maximized, toggleMaximizeButton]);

  const mergedModalProps = useMemo(() => {
    const defaultModalProps = [GlobalDialogType.FORM, GlobalDialogType.TABLE].includes(type)
      ? { layerProps: { styles: { root: { zIndex: '10000000 !important' } } } }
      : modalProps;
    return _.merge(defaultModalProps, modalProps || {});
  }, [modalProps, type]);

  const closeDialog = useCallback(
    (payload?: unknown) => {
      closeOnAction && dispatch(hideDialog());
      onClose(payload);
    },
    [closeOnAction, dispatch, onClose]
  );

  const onGlobalDialogYes = useCallback(() => {
    closeDialog(true);
  }, [closeDialog]);

  const onGlobalDialogNo = useCallback(() => {
    closeDialog(false);
  }, [closeDialog]);

  const primaryText = primaryButtonText ? t(primaryButtonText) : !primaryOption ? t('common.no') : t('common.yes');
  const secondaryText = secondaryButtonText ? t(secondaryButtonText) : primaryOption ? t('common.no') : t('common.yes');

  const [dialogValue, setDialogValue] = useState<string>('');
  useEffect(() => {
    if (typeof dialogTypeOptions?.initialValue === 'string' || typeof dialogTypeOptions?.initialValue === 'number') {
      setDialogValue(dialogTypeOptions.initialValue.toString());
    }
    itemSelectionDialogRef.current?.openDialog();
  }, [dialogTypeOptions?.initialValue]);

  const onInputDialogSubmit = useCallback(() => {
    closeDialog(dialogValue);
    setDialogValue('');
  }, [closeDialog, dialogValue]);
  const onInputDialogClose = useCallback(() => {
    closeDialog();
    setDialogValue('');
  }, [closeDialog]);
  const onInputDialogChange = useCallback((e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
    // We don't want linebreaks from multiline input
    setDialogValue(value?.replace(/[\n\r]/g, '') || '');
  }, []);
  const handleEnterPress: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        onInputDialogSubmit();
      }
    },
    [onInputDialogSubmit]
  );

  const [selectedChoiceGroupKey, setSelectedChoiceGroupKey] = React.useState<string>('0');
  const onSelectedChoiceGroupChange = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
    setSelectedChoiceGroupKey(option.key);
  }, []);
  const onOptionsDialogSubmit = useCallback(() => {
    closeDialog(+selectedChoiceGroupKey);
    setSelectedChoiceGroupKey('0');
  }, [closeDialog, selectedChoiceGroupKey]);
  const onOptionsDialogClose = useCallback(() => {
    closeDialog();
    setSelectedChoiceGroupKey('0');
  }, [closeDialog]);
  const choiceGroupOptions: IChoiceGroupOption[] = useMemo(() => {
    if (!dialogTypeOptions || !Array.isArray(dialogTypeOptions.options)) {
      return [];
    }

    return dialogTypeOptions.options.map(
      (option, index): IChoiceGroupOption => ({
        key: index.toString(),
        text: option.toString(),
      })
    );
  }, [dialogTypeOptions]);

  useEffect(() => {
    const initialValue = dialogTypeOptions?.initialValue;
    if (typeof initialValue === 'string' || typeof initialValue === 'number') {
      setSelectedChoiceGroupKey(initialValue.toString());
    }
  }, [choiceGroupOptions, dialogTypeOptions?.initialValue]);

  const handleItemSelectionDialogSelect = useCallback(
    (item: IDataItem) => {
      closeDialog(item);
    },
    [closeDialog]
  );
  const handleItemSelectionDialogCancel = useCallback(() => {
    closeDialog();
  }, [closeDialog]);
  const itemSelectionDialogContentProps = useMemo(
    () =>
      _.merge(
        {
          type: DialogType.largeHeader,
        },
        mergedDialogContentProps
      ),
    [mergedDialogContentProps]
  );
  const itemSelectionDialogPage = useMemo(
    (): Schemas.CpaPage =>
      ({
        identifier: '__global_dialog',
        name: message,
        ...dialogTypeOptions,
      } as Schemas.CpaPage),
    [dialogTypeOptions, message]
  );

  const dialogWidth = useMemo(() => {
    if (isMaximizable && maximized) return '98vw';
    return width;
  }, [isMaximizable, maximized, width]);

  if (type === GlobalDialogType.ITEM_SELECTION) {
    return (
      <ItemSelectionDialog
        ref={itemSelectionDialogRef}
        page={itemSelectionDialogPage}
        onSubmit={handleItemSelectionDialogSelect}
        onCancel={handleItemSelectionDialogCancel}
        dialogContentProps={itemSelectionDialogContentProps}
      />
    );
  }

  const dialogTypes = {
    [GlobalDialogType.CONFIRMATION]: () => (
      <DialogFooter>
        <PrimaryButton styles={styles?.primaryButton} text={primaryText} onClick={!primaryOption ? onGlobalDialogNo : onGlobalDialogYes} />
        <DefaultButton styles={styles?.secondaryButton} text={secondaryText} onClick={primaryOption ? onGlobalDialogNo : onGlobalDialogYes} />
      </DialogFooter>
    ),
    [GlobalDialogType.MESSAGE]: () => (
      <DialogFooter>
        <PrimaryButton styles={styles?.primaryButton} text={primaryText} onClick={!primaryOption ? onGlobalDialogNo : onGlobalDialogYes} />
      </DialogFooter>
    ),
    [GlobalDialogType.INPUT]: () => (
      <div>
        <div style={{ marginTop: '-14px' }}>
          <TextField
            styles={_.merge(
              {
                fieldGroup: {
                  minHeight: 30,
                },
                field: {
                  height: 30,
                },
              },
              styles?.textField
            )}
            onChange={onInputDialogChange}
            value={dialogValue}
            onKeyDown={handleEnterPress}
            multiline={true}
            autoAdjustHeight={true}
            resizable={true}
          />
        </div>
        <DialogFooter>
          <PrimaryButton styles={styles?.primaryButton} text={primaryText} onClick={onInputDialogSubmit} />
          {!!dialogTypeOptions?.optional && <DefaultButton styles={styles?.secondaryButton} text={secondaryText} onClick={onInputDialogClose} />}
        </DialogFooter>
      </div>
    ),
    [GlobalDialogType.OPTIONS]: () => (
      <div>
        <ChoiceGroup
          styles={styles?.choiceGroup}
          options={choiceGroupOptions}
          selectedKey={selectedChoiceGroupKey}
          onChange={onSelectedChoiceGroupChange}
        />
        <DialogFooter>
          <PrimaryButton styles={styles?.primaryButton} text={primaryText} onClick={onOptionsDialogSubmit} />
          <DefaultButton styles={styles?.secondaryButton} text={secondaryText} onClick={onOptionsDialogClose} />
        </DialogFooter>
      </div>
    ),
    [GlobalDialogType.FORM]: () => (
      <FormDialog
        dialogTypeOptions={dialogTypeOptions}
        styles={styles}
        onInputDialogClose={onInputDialogClose}
        closeOnAction={closeOnAction}
        onClose={onClose}
        secondaryText={secondaryText}
      />
    ),
    [GlobalDialogType.TABLE]: () => (
      <TableDialog dialogTypeOptions={dialogTypeOptions} onClose={closeDialog} secondaryText={secondaryText} dialogMaximized={maximized} />
    ),
    [GlobalDialogType.CUSTOM]: () => {
      if (!dialogTypeOptions?.renderBody) return null;
      return (dialogTypeOptions.renderBody as () => JSX.Element)();
    },
  };

  return (
    <Dialog
      minWidth={dialogWidth}
      hidden={false}
      className={className}
      dialogContentProps={mergedDialogContentProps}
      onDismiss={closeOnClickOutside ? onGlobalDialogNo : undefined}
      modalProps={mergedModalProps}
      styles={styles?.dialog}
    >
      {isHtml ? <HtmlContent html={message} /> : null}
      {dialogTypes[type]?.() || null}
    </Dialog>
  );
};
