import React, { useMemo } from 'react';
import { IJSONSchema } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

import { Expert } from '../../SolutionSingleItemTemplate';

import styles from './ExpertsList.module.scss';
import ExpertCard from './components/ExpertCard/ExpertCard';

interface IExpertsListProps {
  schemaData: IJSONSchema;
  experts: Expert[];
  owners: string[] | undefined;
}

const ExpertsList: React.FC<IExpertsListProps> = ({ schemaData, experts, owners }) => {
  const [t] = useTranslation();
  const [duplicatedUsers, ignoredExperts] = useMemo(() => {
    const ignoredExperts: string[] = [];
    const combinedExperts: { email: string; role: string }[] = [];
    const groupedExperts = _.groupBy(experts || [], (item) => item.email.toLowerCase());
    for (const expertEmail of Object.keys(groupedExperts)) {
      if (groupedExperts[expertEmail].length > 1) {
        ignoredExperts.push(expertEmail);
        const roles = groupedExperts[expertEmail].map((item) => schemaData.expertTypeTitles?.[item.expertType]);
        if (owners?.find((owner) => owner.toLowerCase() === expertEmail)) {
          roles.unshift(t('common.owner'));
        }
        combinedExperts.push({ email: expertEmail, role: roles.join(', ') });
      }
    }
    return [combinedExperts, ignoredExperts];
  }, [experts, owners, schemaData.expertTypeTitles, t]);
  return (
    <div className={styles.expertsWrapper}>
      <h1 data-heading="Experts" id={'experts'}>
        Experts
      </h1>
      <div className={styles.experts}>
        {duplicatedUsers.map((expert, index) => {
          return <ExpertCard key={`${expert.email}-${index}`} email={expert.email} role={expert.role} />;
        })}
        {experts
          .filter((expert) => !ignoredExperts.includes(expert.email))
          .map((expert, index) => {
            return <ExpertCard key={`${expert.email}-${index}`} email={expert.email} role={schemaData.expertTypeTitles?.[expert.expertType]} />;
          })}
        {owners
          ?.filter((owner) => !ignoredExperts.find((expert) => expert === owner.toLowerCase()))
          .map((owner, index) => {
            return <ExpertCard key={`${owner}-${index}`} email={owner} role={t('common.owner')} />;
          })}
      </div>
    </div>
  );
};

export default ExpertsList;
