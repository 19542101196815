import { Environment, LogLevel } from '@cp/base-types';
export class LogMessage {
    constructor(logMessage) {
        var _a, _b, _c, _d, _e;
        this.timestamp = (_a = logMessage.timestamp) !== null && _a !== void 0 ? _a : new Date();
        this.level = (_b = logMessage.level) !== null && _b !== void 0 ? _b : LogLevel.Info;
        this.sender = (_c = logMessage.sender) !== null && _c !== void 0 ? _c : Environment.appName;
        this.message = (_d = logMessage.message) !== null && _d !== void 0 ? _d : '';
        this.optionalParams = (_e = logMessage.optionalParams) !== null && _e !== void 0 ? _e : [];
    }
}
