import { IODataProps, cloneDeepWithMetadata, formatEntries, sortItemsByIdentifiers } from '@cp/base-utils';
import { i18n } from '@cpa/base-core/app';
import { itemExpandColumnKey } from '@cpa/base-core/constants';
import { showDialog } from '@cpa/base-core/helpers';
import notification from '@cpa/base-core/helpers/toast';
import { useCancellableLoadEntities, useCardWidth, useLiveUpdates, usePowerUser } from '@cpa/base-core/hooks';
import { IComponentWithOptions, IDataItem, ITableRowOption, ITableRowProps } from '@cpa/base-core/types';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import {
  AnimationClassNames,
  CommandBar,
  DetailsRow,
  DialogType,
  DirectionalHint,
  IButtonStyles,
  IColumn,
  ICommandBarItemProps,
  IDetailsRowBaseProps,
  ThemeContext,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { NamedExoticComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import LongPress from 'react-long';

import ParentingIndicator from '../TableRow/components/ParentingIndicator/ParentingIndicator';
import { simplifiedRowRenderer } from '../TableRow/components/SimpleCard/SimpleCard';

import styles from './SolutionExplorer.module.scss';
import { addAsComponent } from './utils';

const ColorizeFirstRowContext = React.createContext(true);

enum ExpandGroup {
  Children = 'CHILDREN',
  Components = 'COMPONENTS',
}

export function getButtonStyles(iconColor: string): IButtonStyles {
  return {
    icon: { color: iconColor, margin: 0 },
    iconHovered: { color: iconColor },
    root: {
      padding: 0,
      backgroundColor: 'transparent',
      minWidth: 'initial',
    },
    rootHovered: {
      backgroundColor: 'transparent',
    },
  };
}

export function getDefaultItemProps(iconName: string, iconColor: string): Partial<ICommandBarItemProps> {
  return {
    tooltipHostProps: {
      calloutProps: {
        gapSpace: 0,
        beakWidth: 10,
        directionalHint: DirectionalHint.topCenter,
      },
    },
    split: true,
    buttonStyles: {
      splitButtonMenuButton: { backgroundColor: 'transparent', width: 18, border: 'none' },
      splitButtonMenuIcon: { fontSize: '7px' },
      splitButtonDivider: { backgroundColor: '#c8c8c8', width: 1, right: 17, position: 'absolute', top: 4, bottom: 4 },
      splitButtonContainer: { height: 'initial' },
      ...getButtonStyles(iconColor),
    },
    iconProps: {
      iconName: iconName,
      styles: {
        root: {
          color: iconColor,
          margin: 7,
        },
      },
    },
    iconOnly: true,
  };
}

export enum SolutionExplorerRowActionsMode {
  Default,
  ComponentsPromoted,
  ComponentsOnly,
}

const SolutionExplorer: React.FC<ITableRowProps & { rowActionsMode?: SolutionExplorerRowActionsMode }> = ({
  page,
  rowId,
  detailedRowProps,
  columns,
  fireInvokeEvent,
  colorizedRows,
  darkMode,
  onDoubleClick,
  onContextMenu,
  item,
  parentPropertyJsonPath,
  childTableRender,
  level,
  loadItems: loadItemsFromProps,
  reloadEmitter,
  order,
  onAddClick,
  onNewChildItemsLoaded,
  pageSize,
  rowActionsMode,
  dataOperations,
}) => {
  const defaultIconColor = darkMode ? '#fefefe' : '#383838';
  const powerUser = usePowerUser();
  const [t] = useTranslation();
  const theme = useContext(ThemeContext);
  const loadItems = useCancellableLoadEntities(loadItemsFromProps);
  const [isExpanded, setIsExpanded] = useState<ExpandGroup | null>(null);
  const [solutionComponentsMap, solutionComponents]: [Record<string, string | undefined>, string[]] = useMemo(() => {
    const identifiers: { identifier: string; positionNumber: number }[] = [];

    const solutionComponentsMap = (
      (((item.solutionTypeDetails || []) as IDataItem[]).filter(
        (solutionTypeDetail) => solutionTypeDetail?._type === 'http://platform.cosmoconsult.com/ontology/Components'
      )[0]?.components || []) as IDataItem[]
    )
      .filter(Boolean)
      .reduce<Record<string, string | undefined>>((acc, component) => {
        const solutionIdentifier = (component.solution as IDataItem)?.identifier;
        if (solutionIdentifier) {
          acc[solutionIdentifier as string] = component._componentId as string | undefined;

          identifiers.push({
            identifier: solutionIdentifier,
            positionNumber: 'positionNumber' in component && typeof component.positionNumber === 'number' ? component.positionNumber : 0,
          });
        }
        return acc;
      }, {});

    return [solutionComponentsMap, _.chain(identifiers).sortBy('positionNumber').map('identifier').value()];
  }, [item]);

  const colorizeFirstRow = useContext(ColorizeFirstRowContext);
  const paintRow = useMemo(() => {
    return !!colorizedRows && !!detailedRowProps && (colorizeFirstRow ? detailedRowProps.itemIndex % 2 === 0 : detailedRowProps.itemIndex % 2 !== 0);
  }, [colorizedRows, detailedRowProps, colorizeFirstRow]);

  const cardMargin = useCardWidth();

  const classes = classNames({
    [styles.highlightedRow]: paintRow && !darkMode,
    [styles.highlightedRowDark]: paintRow && darkMode,
    [styles.defaultRow]: !paintRow && !darkMode && !powerUser,
    [styles.defaultRowDark]: !paintRow && darkMode && !powerUser,
    [styles.colors]: !powerUser,
  });

  const [childItems, setChildItems] = useState<IDataItem[]>([]);
  useEffect(() => {
    // We need to update __parentItem on child items if parent is updated.
    // It helps to prevent 'Item modified by another user' errors.
    for (const childItem of childItems) {
      Object.defineProperty(childItem, '__parentItem', {
        enumerable: false,
        configurable: false,
        writable: true,
        value: item,
      });
    }
    // eslint-disable-next-line
  }, [item]);

  const [isFetching, { setTrue: startFetching, setFalse: finishFetching }] = useBoolean(false);

  const childrenCount = item.__originalItem?.__childrenCount || item.__childrenCount || 0;

  const [totalChildItems, setTotalChildItems] = useState<number>(childrenCount);
  const lazyLoadingEnabled = useMemo(
    () => isExpanded === ExpandGroup.Children && totalChildItems > childItems.length && !isFetching,
    [childItems.length, isExpanded, totalChildItems, isFetching]
  );

  useLiveUpdates(
    loadItems,
    isExpanded === ExpandGroup.Components ? childItems : [],
    (newItems) => {
      if (!isExpanded) return;
      processLoadedItems(newItems, isExpanded);
      setChildItems(newItems);
    },
    () => {},
    isExpanded === ExpandGroup.Components ? childItems.length : 0,
    page,
    (childItem: IDataItem) =>
      isExpanded === ExpandGroup.Components && solutionComponents.includes(childItem.__originalItem?.identifier || childItem.identifier!),
    true
  );

  useLiveUpdates(
    loadItems,
    isExpanded === ExpandGroup.Children ? childItems : [],
    (newItems) => {
      if (!isExpanded || isExpanded === ExpandGroup.Components) return;
      processLoadedItems(newItems, isExpanded);
      setChildItems(newItems);
    },
    (newState) => {
      const originalItem = item.__originalItem || item;
      if (typeof newState === 'number') {
        originalItem.__childrenCount = newState;
        setTotalChildItems(newState);
      } else {
        setTotalChildItems((prevTotalItems) => {
          const newTotalItems = newState(prevTotalItems);
          originalItem.__childrenCount = newTotalItems;
          return newTotalItems;
        });
      }
    },
    totalChildItems,
    page,
    (childItem: IDataItem) =>
      !!parentPropertyJsonPath && (item.__originalItem?.identifier || item.identifier) === _.get(childItem, parentPropertyJsonPath),
    true
  );

  const [lazyLoadingMarker, lazyLoadingMarkerInView] = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const processLoadedItems = useCallback(
    (loadedItems: IDataItem[], expandGroup: ExpandGroup): void => {
      loadedItems.splice(0, loadedItems.length, ...sortItemsByIdentifiers(loadedItems, solutionComponents));
      loadedItems.forEach((loadedItem) => {
        if (expandGroup === ExpandGroup.Components) {
          Object.defineProperty(loadedItem, '__component', {
            enumerable: false,
            configurable: false,
            writable: true,
            value: true,
          });
          const loadedComponentIdentifier = loadedItem.__originalItem?.identifier || loadedItem.identifier;
          if (loadedComponentIdentifier) {
            Object.defineProperty(loadedItem, '__componentId', {
              enumerable: false,
              configurable: false,
              writable: true,
              value: solutionComponentsMap[loadedComponentIdentifier],
            });
          }
        }
        Object.defineProperty(loadedItem, '__parentItem', {
          enumerable: false,
          configurable: false,
          writable: true,
          value: item,
        });
      });
    },
    [item, solutionComponents, solutionComponentsMap]
  );

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const loadChildItems = useCallback(
    (
      expandGroup: ExpandGroup,
      withAnimation: boolean = true,
      resetItems: boolean = true,
      appendLoadedItems: boolean = false,
      extraOptions: IODataProps = {}
    ) => {
      if (!loadItems || (expandGroup === ExpandGroup.Children && !parentPropertyJsonPath) || !item.identifier) {
        return;
      }

      if (withAnimation) {
        startFetching();
      }
      if (resetItems) {
        setChildItems([]);
      }

      cancelToken.current?.cancel();
      cancelToken.current = createCancelToken();

      let loadOptions: IODataProps = {};
      if (expandGroup === ExpandGroup.Components) {
        if (!solutionComponents.length) {
          if (!appendLoadedItems) {
            setChildItems([]);
          }
          onNewChildItemsLoaded?.([]);
          finishFetching();
          return;
        }
        // Load solution components

        loadOptions = {
          filter: formatEntries({
            identifier: solutionComponents,
          }) as object,
          orderBy: order && [[order.columnKey, order.isAscending ? 'asc' : 'desc']],
        };
      } else if (parentPropertyJsonPath) {
        // Load child solutions
        loadOptions = {
          filter: formatEntries({
            [parentPropertyJsonPath]: item.identifier,
          }) as object,
          orderBy: order && [[order.columnKey, order.isAscending ? 'asc' : 'desc']],
          top: pageSize,
        };
      }

      loadItems(
        {},
        {
          detachedRequest: true,
          disableTriggers: true,
          cancelToken: cancelToken.current || undefined,
          interceptors: {
            beforeRequest: (endpointId, path, queryOptions, ...other) => {
              // Ignore filter from page level. We always fetch all children / components.
              return [
                endpointId,
                path,
                {
                  ...(queryOptions?.$expand ? { $expand: queryOptions.$expand } : {}),
                  ...loadOptions,
                  ...extraOptions,
                },
                ...other,
              ];
            },
          },
        }
      )
        .then(({ items, responses }) => {
          processLoadedItems(items, expandGroup);

          if (responses?.[0]?.totalItems && expandGroup === ExpandGroup.Children) {
            setTotalChildItems(responses?.[0]?.totalItems);
          }
          setChildItems(
            !appendLoadedItems
              ? items
              : (currentItems): IDataItem[] => {
                  if (!currentItems) {
                    return items;
                  }
                  return [...currentItems, ...items];
                }
          );
          onNewChildItemsLoaded?.(items);
        })
        .finally(() => {
          finishFetching();
        });
    },
    [
      loadItems,
      parentPropertyJsonPath,
      item.identifier,
      startFetching,
      solutionComponents,
      order,
      onNewChildItemsLoaded,
      finishFetching,
      pageSize,
      processLoadedItems,
    ]
  );
  useEffect(() => {
    if (isExpanded !== ExpandGroup.Components) {
      return;
    }

    loadChildItems(isExpanded, false, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionComponents.join()]);

  const reloadChildItems = useCallback(() => {
    if (!isExpanded) {
      setChildItems([]);
      return;
    }

    loadChildItems(
      isExpanded,
      false,
      false,
      false,
      isExpanded === ExpandGroup.Children ? { top: pageSize <= childItems.length ? childItems.length + 1 : pageSize } : {}
    );
  }, [isExpanded, loadChildItems, pageSize, childItems.length]);
  const mountedReloadEventHandler = useRef<typeof reloadChildItems>();
  useEffect(() => {
    if (mountedReloadEventHandler.current) {
      reloadEmitter?.off('reload', mountedReloadEventHandler.current);
    }

    // Handle reload
    mountedReloadEventHandler.current = reloadChildItems;
    reloadEmitter?.on('reload', mountedReloadEventHandler.current);

    return (): void => {
      // Unmount
      if (mountedReloadEventHandler.current) {
        reloadEmitter?.off('reload', mountedReloadEventHandler.current);
      }
      reloadEmitter?.off('reload', reloadChildItems);
    };
  }, [reloadEmitter, reloadChildItems]);

  // Lazy loading
  useEffect(() => {
    if (!lazyLoadingMarkerInView || !lazyLoadingEnabled || isFetching || childItems.length === 0 || !isExpanded) {
      return;
    }

    loadChildItems(isExpanded, true, false, true, {
      skip: childItems.length,
    });
  });

  const preventOtherEvents = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onExpandChildrenIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);

      if (isExpanded === ExpandGroup.Children) {
        setIsExpanded(null);
        return;
      }

      setIsExpanded(ExpandGroup.Children);
      loadChildItems(ExpandGroup.Children);
    },
    [preventOtherEvents, isExpanded, loadChildItems]
  );

  const onExpandSolutionsIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);

      if (isExpanded === ExpandGroup.Components) {
        setIsExpanded(null);
        return;
      }

      setIsExpanded(ExpandGroup.Components);
      loadChildItems(ExpandGroup.Components);
    },
    [preventOtherEvents, isExpanded, loadChildItems]
  );

  const onAddIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);
      onAddClick?.();
    },
    [preventOtherEvents, onAddClick]
  );

  const onAddComponentIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);
      if (!onAddClick) {
        return;
      }
      addAsComponent(item, onAddClick, dataOperations.onEditRow);
    },
    [preventOtherEvents, onAddClick, item, dataOperations]
  );

  const rowActions = useMemo(() => {
    const activeIconColor = theme?.palette.themePrimary || 'unset';
    const componentsActions: ICommandBarItemProps[] = [];
    const parentingActions: ICommandBarItemProps[] = [];
    if (onAddClick) {
      parentingActions.push({
        key: 'add',
        id: 'solution-add',
        name: t('common.addChild'),
        onClick: onAddIconClick,
        ...getDefaultItemProps('CalculatorAddition', defaultIconColor),
      });
    }
    if (totalChildItems > 0) {
      parentingActions.push(
        _.merge(
          {
            key: 'expandChildren',
            id: 'children-expand',
            name: t(isExpanded === ExpandGroup.Children ? 'common.hideChildren' : 'common.expandChildren'),
            onClick: onExpandChildrenIconClick,
            iconProps: {
              className: isExpanded === ExpandGroup.Children ? '' : AnimationClassNames.rotateN90deg,
            },
          },
          getDefaultItemProps('ChevronDown', isExpanded === ExpandGroup.Children ? activeIconColor : defaultIconColor)
        )
      );
    }
    if (onAddClick) {
      componentsActions.push({
        key: 'addComponent',
        id: 'component-add',
        name: t('common.addComponent'),
        onClick: onAddComponentIconClick,
        ...getDefaultItemProps('AddLink', defaultIconColor),
      });
    }
    if (solutionComponents.length > 0) {
      componentsActions.push({
        key: 'exploreComponents',
        id: 'component-explore',
        name: t(isExpanded === ExpandGroup.Components ? 'common.hideComponents' : 'common.exploreComponents'),
        onClick: onExpandSolutionsIconClick,
        ...getDefaultItemProps('RowsGroup', isExpanded === ExpandGroup.Components ? activeIconColor : defaultIconColor),
      });
    }

    switch (rowActionsMode) {
      case SolutionExplorerRowActionsMode.ComponentsPromoted:
        return [...componentsActions, ...parentingActions];
      case SolutionExplorerRowActionsMode.ComponentsOnly:
        return [...componentsActions];
      case SolutionExplorerRowActionsMode.Default:
      default:
        return [...parentingActions, ...componentsActions];
    }
  }, [
    totalChildItems,
    theme,
    defaultIconColor,
    isExpanded,
    onAddClick,
    onAddIconClick,
    onExpandChildrenIconClick,
    onExpandSolutionsIconClick,
    solutionComponents.length,
    t,
    rowActionsMode,
    onAddComponentIconClick,
  ]);

  const onExpandColumnRender = useCallback(() => {
    const overflowButtonStyles = getButtonStyles(defaultIconColor);
    return (
      <div className={styles.expandButtonWrapper} onDoubleClick={preventOtherEvents} onMouseDown={preventOtherEvents}>
        <CommandBar
          // Key is used here as a workaround.
          // FluentUI doesn't pass updated row actions to underlying OverflowSet for some reason.
          key={rowActions.length}
          styles={{
            root: {
              padding: 0,
              backgroundColor: 'transparent',
              height: 'initial',
            },
          }}
          overflowButtonProps={{
            styles: {
              ...overflowButtonStyles,
              root: {
                ...((overflowButtonStyles.root as object) || {}),
                padding: '7px 2px',
              },
            },
          }}
          items={rowActions}
        />
      </div>
    );
  }, [preventOtherEvents, defaultIconColor, rowActions]);

  const columnsToRender = useMemo(() => {
    if (!parentPropertyJsonPath || !loadItems) {
      return columns;
    }

    let firstColumn: IColumn;

    return columns.map((column) => {
      if (column.key === itemExpandColumnKey) {
        return {
          ...column,
          onRender: onExpandColumnRender,
        };
      }

      if (!firstColumn && column.key && !column.key.startsWith('__file-')) {
        firstColumn = { ...column };

        const oldOnRender = firstColumn.onRender;
        firstColumn.onRender = (...args): JSX.Element | null => {
          if (!firstColumn.fieldName) {
            return null;
          }

          const child = oldOnRender ? oldOnRender?.(...args) : args[0][firstColumn.fieldName] || null;
          return (
            <div
              style={{
                paddingLeft: level * 15,
              }}
            >
              {child}
            </div>
          );
        };

        return firstColumn;
      }

      return column;
    });
  }, [parentPropertyJsonPath, columns, onExpandColumnRender, level, loadItems]);

  useEffect(() => {
    setIsExpanded(null);
    setChildItems([]);
    setTotalChildItems(childrenCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowId, item?.identifier, order]);

  const onLongPress = useCallback((): void => {
    // For some reason LongPress library did not provide any event for handler
    // to make possible to open context menu it is passed as empty function
    // TODO: Fix
  }, []);

  const colorsStyle = useMemo(() => {
    const originalItem: IDataItem = item.__originalItem || item;
    const colors = (originalItem?.['__workloadInfo'] as IDataItem<unknown> | null)?.colors as string[];
    if (colors?.length) {
      if (colors.length === 1) {
        return colors[0];
      }
      return `linear-gradient(to bottom, ${colors.join(', ')})`;
    }
    return null;
  }, [item]);

  return (
    <div
      className={classNames({
        [styles.rowWrapper]: true,
        [styles.expandableRow]: !!parentPropertyJsonPath,
        [styles.goldGradient]: !powerUser,
        [styles.goldGradientHighlightedLight]: !powerUser && paintRow && !darkMode,
        [styles.goldGradientHighlightedDark]: !powerUser && paintRow && darkMode,
        [styles.goldGradientVisible]: !powerUser && detailedRowProps.selection.isIndexSelected(detailedRowProps.itemIndex),
      })}
      style={{ margin: powerUser ? undefined : '10px 0', marginLeft: powerUser ? undefined : `${level !== 0 ? cardMargin : 0}px` }}
    >
      <div>
        <LongPress time={1000} onLongPress={onLongPress}>
          <div data-selection-invoke={fireInvokeEvent} id={rowId} onDoubleClick={onDoubleClick} onContextMenu={onContextMenu}>
            <DetailsRow
              {...(detailedRowProps as IDetailsRowBaseProps)}
              columns={columnsToRender}
              styles={{
                root: {
                  borderRadius: powerUser ? undefined : '12px',
                  '&::after': {
                    background: powerUser ? undefined : colorsStyle,
                  },
                },
              }}
              className={classes}
              onRenderField={powerUser ? undefined : (props, defaultRender) => simplifiedRowRenderer(props, defaultRender, level, page)}
            />
          </div>
        </LongPress>
      </div>
      {isExpanded && (childItems.length > 0 || isFetching) && (
        <ParentingIndicator level={level} color={isExpanded === ExpandGroup.Components ? 'rgba(179, 156, 77, .7)' : undefined} />
      )}
      <div onClick={preventOtherEvents} onDoubleClick={preventOtherEvents} data-selection-disabled={true}>
        {isExpanded && (childItems.length > 0 || isFetching) && (
          <ColorizeFirstRowContext.Provider value={!paintRow}>
            <div style={{ marginBottom: 5 }}>
              {childTableRender?.(
                columns,
                rowId || 'item',
                childItems,
                isFetching,
                isExpanded === ExpandGroup.Children ? totalChildItems : solutionComponents.length
              )}
            </div>
            {lazyLoadingEnabled && <div ref={lazyLoadingMarker} />}
          </ColorizeFirstRowContext.Provider>
        )}
      </div>
    </div>
  );
};

const SolutionExplorerMemoized: NamedExoticComponent & IComponentWithOptions<ITableRowOption> = React.memo(SolutionExplorer, _.isEqual);
SolutionExplorerMemoized.options = {
  table: {
    dragDrop: {
      allowDropToParent: true,
    },
    dynamicRowActions: true,
    customActions: [
      {
        key: 'remove_component',
        text: 'common.remove',
        icon: 'RemoveLink',
        async onClick(selectedItems, { onEdit, setErrors, clearTableSelection, reloadCurrentItems }): Promise<boolean> {
          setErrors([]);

          const parentItem = selectedItems[0]?.__parentItem?.__originalItem || selectedItems[0]?.__parentItem;
          if (!parentItem || !parentItem.solutionTypeDetails || !onEdit) {
            return false;
          }

          const removeConfirmed = await showDialog({
            message: '',
            dialogContentProps: {
              type: DialogType.largeHeader,
              title: i18n.t('common.remove'),
              subText: i18n.t('common.deletedComponent', { amount: selectedItems.length }),
            },
            primaryButtonText: 'common.confirm',
            secondaryButtonText: 'common.cancel',
            closeOnClickOutside: true,
            closeOnAction: true,
          });
          if (removeConfirmed) {
            const toastId = notification.info(i18n.t('common.deletingComponent'));
            try {
              const updatedItem = cloneDeepWithMetadata(parentItem);
              updatedItem.solutionTypeDetails = updatedItem.solutionTypeDetails || [];
              const componentsSolutionTypeDetail = (updatedItem.solutionTypeDetails as IDataItem[]).find(
                (solutionTypeDetail) => solutionTypeDetail?._type === 'http://platform.cosmoconsult.com/ontology/Components'
              );
              if (componentsSolutionTypeDetail) {
                const componentIdentifiersToRemove = new Set(selectedItems.map(({ identifier }) => identifier).filter(Boolean) as string[]);

                componentsSolutionTypeDetail.components = componentsSolutionTypeDetail.components || [];
                for (const component of componentsSolutionTypeDetail.components as IDataItem[]) {
                  if (
                    component &&
                    component.solution &&
                    (component.solution as IDataItem).identifier &&
                    componentIdentifiersToRemove.has((component.solution as IDataItem).identifier!)
                  ) {
                    delete component.solution;
                  }
                }
                componentsSolutionTypeDetail.components = (componentsSolutionTypeDetail.components as IDataItem[]).filter(
                  (component) => component && component.solution
                );
              }
              await onEdit(updatedItem, parentItem);
              notification.dismiss(toastId);
              notification.success(i18n.t('common.componentDeleted'));
              clearTableSelection();
              await reloadCurrentItems();
              return true;
            } catch (error) {
              if (toastId) {
                notification.dismiss(toastId);
              }
              setErrors([error.message]);
              notification.error(error.message);
            }
          }
          return false;
        },
        canShow(selectedItems, page): boolean {
          return (
            selectedItems.length > 0 &&
            !!page.allowModify &&
            selectedItems.every(
              (selectedItem: IDataItem) =>
                selectedItem &&
                selectedItem.__component === true &&
                selectedItem.__parentItem &&
                selectedItem.__parentItem === selectedItems[0].__parentItem
            )
          );
        },
      },
    ],
  },
};
export default SolutionExplorerMemoized;
