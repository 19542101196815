import * as path from 'path';

import * as _ from 'lodash';
import { sleep } from '@cp/base-utils';

import { IDataItem } from '../../types';

export const getFilenameFromURL = (url: string): string => {
  return decodeURIComponent(url.split('/').slice(-1)[0]);
};

export const getFileExtensionFromURL = (url: string): string => {
  const filename = getFilenameFromURL(url);

  return filename && filename.includes('.') ? filename.split('.').slice(-1)[0].toLowerCase() : '';
};

export const isAzureStorageResource = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    const isAzureStorageResource = parsedUrl.hostname.endsWith('blob.core.windows.net');
    if (isAzureStorageResource) {
      return true;
    }
  } catch (e) {}
  return false;
};

export type DownloadMiddleware = (file: string, openExtensions: string[], item: IDataItem) => Promise<[string, string]>;
export const defaultExtension = 'pdf';
export const openExt = ['html', 'jpeg', 'jpg', 'svg', 'png'];

// Returns [ext without dot, canOpen]
export const getFileExtension = (link?: string): [string, boolean] => {
  if (!link) {
    return [defaultExtension, false];
  }

  const file = path.basename(link);
  if (!file) {
    return [defaultExtension, false];
  }

  const fileExt = path.extname(file);
  const ext = fileExt.startsWith('.') ? fileExt.substr(1).trim() : fileExt.trim();

  if (!ext) {
    return [defaultExtension, false];
  }

  return [ext, openExt.includes(ext)];
};

export const multipleDownload = async (items: IDataItem[], propertyJsonPath: string, middleware?: DownloadMiddleware): Promise<void> => {
  for (const item of items) {
    const file = propertyJsonPath ? _.get(item, propertyJsonPath) : item;

    const filesToDownload = (
      (Array.isArray(file) ? file : [file]).filter((fileToDownload) => !!fileToDownload && typeof fileToDownload === 'string') as string[]
    ).map((fileToDownload) => {
      // ApiGateway issue workaround
      if (fileToDownload.startsWith('undefined')) {
        return fileToDownload.substring(9);
      }
      return fileToDownload;
    });

    if (!filesToDownload.length) {
      continue;
    }

    try {
      for (const fileToDownload of filesToDownload) {
        const [downloadUrl, newName] = middleware ? await middleware(fileToDownload, openExt, item) : [fileToDownload];

        const originalName = path.basename(fileToDownload);
        const name = newName || originalName;

        const [, canOpen] = getFileExtension(name);
        const [, canOpenOriginal] = getFileExtension(fileToDownload);

        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('href', downloadUrl);
        document.body.appendChild(link);

        if (canOpen && canOpenOriginal) {
          link.setAttribute('target', '_blank');
        } else {
          link.setAttribute('download', name);
        }

        link.click();
        await sleep(100);
        document.body.removeChild(link);
        await sleep(550);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export function downloadFile(filename: string, text: string, dataType: string = 'text/plain'): void {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${dataType};charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
