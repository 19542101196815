const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CrmTicket',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCrmTicket' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Crm Ticket',
    'properties': {
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'creator': {
            'title': 'Creator',
            'description': 'A person (alive, dead, undead, or fictional).',
            '$id': 'http://schema.org/Person',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' },
                { 'href': 'http://schema.org/creator', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 122 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 142 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 45 },
        },
        'contactId': {
            'title': 'Contact Id',
            'description': 'The UUID/GUID to identify a contact.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/contactId', 'rel': 'canonical' }],
        },
        'customerId': {
            'title': 'Customer Id',
            'description': 'The UUID/GUID to identify a customer.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/customerId', 'rel': 'canonical' }],
        },
        'jobNo': {
            'title': 'Job No',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/jobNo', 'rel': 'canonical' }],
        },
        'jobUniqueNo': {
            'title': 'Job Unique No',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/jobUniqueNo', 'rel': 'canonical' }],
        },
        'tableId': {
            'title': 'Table Id',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tableId', 'rel': 'canonical' }],
        },
        'erpCreateServiceDesk': {
            'title': 'Erp Create Service Desk',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 46 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/erpCreateServiceDesk', 'rel': 'canonical' }],
        },
        'crmSymptomCode': {
            'title': 'Crm Symptom Code',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 47 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/crmSymptomCode', 'rel': 'canonical' }],
        },
        'crmJobSalesCode': {
            'title': 'Crm Job Sales Code',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 48 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/crmJobSalesCode', 'rel': 'canonical' }],
        },
        'crmTicketStatus': {
            'title': 'Crm Ticket Status',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/crmTicketStatus', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 38 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 49 },
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
    },
};
export const CrmTicketSchema = Schema;
