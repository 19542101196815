import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { axiosDictionary, executeAggregationTemplate } from '@cpa/base-core/api';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { TypeConstants } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './TargetWidget.module.scss';
import PlatformIcons from './сomponents/PlatformIcons/PlatformIcons';

export enum TargetWidgetSize {
  Small = 'small',
  Default = 'default',
}

interface ITargetWidgetProps {
  size: TargetWidgetSize;
  platforms?: string[];
  supportedCountries?: string[];
  supportedLanguages?: string[];
  technicalRequirements?: string[];
  technicalRequirementsTitle?: string;
}

const TargetWidget: React.FC<ITargetWidgetProps> = ({
  size,
  platforms,
  supportedLanguages,
  supportedCountries,
  technicalRequirements,
  technicalRequirementsTitle,
}) => {
  const [languages, setLanguages] = React.useState<string[]>([]);

  const [t] = useTranslation();

  const flagStyle = useMemo(
    () => ({
      width: '20px',
      height: 'unset',
      backgroundSize: 'contain',
    }),
    []
  );

  const cancelToken = useRef<CancelTokenSource | null>(null);

  const loadItems = useCallback(async () => {
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();
    const langs = supportedLanguages?.map((language) => language.toLowerCase()) || [];
    try {
      const result = await executeAggregationTemplate(
        axiosDictionary.appDataService,
        TypeConstants.Language,
        'solution-language',
        {
          languages: langs,
        },
        undefined,
        cancelToken.current
      );
      setLanguages(result.map((entity) => entity.name) as string[]);
    } catch (e) {
      console.error(`Response error.`, e as Error);
    }
  }, [supportedLanguages]);

  useEffect(() => {
    loadItems();

    return () => {
      cancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {Array.isArray(supportedCountries) && size !== TargetWidgetSize.Small ? (
        <div className={styles.contentWrapper}>
          <h2 className={styles.title}>{t('common.supportedCountries')}:</h2>
          <div className={styles.countriesContainer}>
            {supportedCountries.includes('**')
              ? t('common.allCountries')
              : supportedCountries.map((country, index) => <ReactCountryFlag key={index} style={flagStyle} countryCode={country} svg={true} />)}
          </div>
        </div>
      ) : null}
      {Array.isArray(languages) && languages.length && size !== TargetWidgetSize.Small ? (
        <div className={styles.contentWrapper}>
          <h2 className={styles.title}>{t('common.supportedLanguages')}:</h2>
          <span className={styles.languages}>{languages.join(', ')}</span>
        </div>
      ) : null}
      {Array.isArray(platforms) && platforms.length ? (
        <div className={classNames({ [styles.contentWrapper]: true, [styles.small]: size === TargetWidgetSize.Small })}>
          <PlatformIcons size={size} platforms={platforms} />
        </div>
      ) : null}
      {Array.isArray(technicalRequirements) && technicalRequirements.length && size !== TargetWidgetSize.Small ? (
        <div className={styles.contentWrapper}>
          <h2 className={styles.title}>{technicalRequirementsTitle}:</h2>
          <ul>
            {technicalRequirements.map((technicalRequirement, index) => {
              return <li key={index}>{technicalRequirement}</li>;
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default TargetWidget;
