import { IJSONSchema, TypeConstants } from '@cp/base-types';
import { DataServiceModules } from '@cp/base-utils';
import { axiosDictionary, getEndpoint, getSchema, postEntityToEndpoint } from '@cpa/base-core/api';
import { showDialog } from '@cpa/base-core/helpers';
import notification from '@cpa/base-core/helpers/toast';
import { IGlobalState } from '@cpa/base-core/store';
import { GlobalDialogType, IGenericComponentProps } from '@cpa/base-core/types';
import { DefaultButton, Dialog, DialogFooter, DialogType, IconButton, SelectionMode } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import urlJoin from 'url-join';

import GenericScreen from '../../../../../../screens/GenericScreen/GenericScreen';
import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import styles from './Notifications.module.scss';

interface INotificationProps {
  cpType?: string;
  showQuickAction?: boolean;
  filter: string;
}

const VISIBLE_FORM_FIELDS = ['dataOperationTypes', 'filter'];
const HIDDEN_TABLE_FIELDS = ['accessPermissions', 'url'];

const Notifications: React.FC<INotificationProps> = ({ cpType, filter, showQuickAction }) => {
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const [t] = useTranslation();

  const webhookUrl = useMemo(() => {
    return `https://prod-73.westeurope.logic.azure.com/workflows/457da6cb7d994f1a9ed5deff66a67015/triggers/manual/paths/invoke/${user?.account.email}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=24dv0JoDRrpSmGs-jPVG6a7gUB3d8geO2TF-1OHQrRs`;
  }, [user?.account.email]);

  const [webhookSchema, setWebhookSchema] = useState<IJSONSchema | null>(null);

  useEffect(() => {
    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
    getSchema(urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(TypeConstants.WebHook)}`)).then((schema) => {
      if (schema?.properties) {
        const hiddenInFormProperty = {
          cp_ui: {
            hiddenInForm: true,
          },
        };
        Object.keys(schema.properties).forEach((key) => {
          if (!VISIBLE_FORM_FIELDS.includes(key)) {
            if (schema.properties![key]) {
              schema.properties![key] = {
                ...schema.properties![key],
                ...hiddenInFormProperty,
              };
            }
          }
          if (HIDDEN_TABLE_FIELDS.includes(key)) {
            if (schema.properties![key]) {
              schema.properties![key] = {
                ...schema.properties![key],
                ...(schema.properties![key].cp_ui
                  ? { cp_ui: { ...schema.properties![key].cp_ui, hiddenInTable: true } }
                  : { cp_ui: { hiddenInTable: true } }),
              };
            }
          }
        });
        setWebhookSchema(schema);
      }
    });
  }, []);

  const handleQuickAdd = useCallback(async () => {
    if (!webhookSchema) return;
    try {
      const formData: { dataOperationTypes: string[] } = (await showDialog({
        message: t('common.addNotification'),
        type: GlobalDialogType.FORM,
        title: t('common.notifications'),
        dialogTypeOptions: {
          className: styles.dialogForm,
          optional: true,
          initialValue: { filter: filter },
          schema: {
            type: 'object',
            properties: {
              'filter': { 'title': t('settingsButtons.filter'), 'type': 'string', 'readOnly': true },
              'dataOperationTypes': webhookSchema?.properties?.dataOperationTypes,
            },
          },
        },
        dialogContentProps: {
          type: DialogType.largeHeader,
        },
        secondaryButtonText: t('common.cancel'),
        width: '50vw',
      })) as { dataOperationTypes: string[] };
      if (!formData?.dataOperationTypes?.length) return;
      await postEntityToEndpoint(
        axiosDictionary.appDataService,
        `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.WebHook)}`,
        {
          cpTypeUrl: cpType,
          dataOperationTypes: formData.dataOperationTypes as string[],
          filter: filter,
          url: webhookUrl,
        },
        webhookSchema
      );
      notification.success(t('common.notificationAdded'));
    } catch (e) {
      notification.error(t('common.notificationFailed'));
    }
  }, [cpType, filter, t, webhookSchema, webhookUrl]);

  const genericComponentProps = useMemo<Partial<IGenericComponentProps>>(
    () => ({
      pageSize: 10,
      hideSettings: true,
      hideMoreButton: true,
      withoutAnimation: true,
      showFilterInWidget: true,
      isWidget: true,
      disableFacetFilters: true,
      widgetSettings: {
        hideIcon: true,
        hideTitle: true,
        hideDescription: true,
      },
      scrollingContentProps: {
        isWidget: true,
        hideHeader: false,
        hideActions: false,
        hideFilter: false,
        hideSelectedLabel: true,
        disableDoubleClick: true,
        selectionMode: SelectionMode.multiple,
        disableDragDrop: true,
        preFillItems: {
          cpTypeUrl: cpType,
          filter: filter,
          url: webhookUrl,
        },
      },
    }),
    [cpType, filter, webhookUrl]
  );

  const page = useMemo(
    () => ({
      cpas: [],
      dataEndpoint: { identifier: getEndpoint(axiosDictionary.appDataService).identifier },
      name: 'Webhooks',
      cpTypeUrl: TypeConstants.WebHook,
      dataUrl: `${DataServiceModules.DATA_STORE}/cp%3AWebHook`,
      allowCreate: true,
      allowDelete: true,
      allowModify: true,
      identifier: 'Webhooks',
      icon: 'SetAction',
      spanColumn: 'dataOperationTypes',
    }),
    []
  );

  const handleIconClick = useCallback(
    async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      await showDialog({
        type: GlobalDialogType.TABLE,
        title: t('common.notifications'),
        message: '',
        dialogContentProps: {
          type: DialogType.largeHeader,
        },
        secondaryButtonText: 'common.close',
        closeOnClickOutside: true,
        dialogTypeOptions: {
          page: page,
          genericComponentProps: genericComponentProps,
        },
        width: 'min(98vw, 600px)',
        isMaximizable: true,
      });
    },
    [genericComponentProps, page, t]
  );

  return (
    <>
      {showQuickAction ? (
        <HoverTooltip content={t('common.addNotification')}>
          <IconButton iconProps={{ iconName: 'CpAddRinger' }} onClick={handleQuickAdd} />
        </HoverTooltip>
      ) : null}
      <HoverTooltip content={t('common.notifications')}>
        <IconButton onClick={handleIconClick} iconProps={{ iconName: 'Ringer' }}></IconButton>
      </HoverTooltip>
    </>
  );
};

export default Notifications;
