import toast from 'react-hot-toast';
import { Icon, MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';

const notification = {
  show: (message: string, type: MessageBarType, duration = 3000) => {
    return toast(
      (t) => (
        <div>
          <MessageBar messageBarType={type} isMultiline={true}>
            <div style={{ display: 'flex' }}>
              <div>{message}</div>
              {type === MessageBarType.error ? (
                <Icon style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} iconName="Cancel" />
              ) : null}
            </div>
          </MessageBar>
        </div>
      ),
      {
        duration: duration,
        position: 'top-right',
        style: {
          borderRadius: 0,
          backgroundColor: 'transparent',
        },
      }
    );
  },
  info: (message: string, duration?: number) => {
    return notification.show(message, MessageBarType.info, duration);
  },
  error: (message: string) => {
    return notification.show(message, MessageBarType.error, Infinity);
  },
  warning: (message: string, duration?: number) => {
    return notification.show(message, MessageBarType.warning, duration);
  },
  success: (message: string, duration?: number) => {
    return notification.show(message, MessageBarType.success, duration);
  },
  dismiss: (toastId: string) => {
    toast.dismiss(toastId);
  },
  dismissAll: () => toast.dismiss(),
};

export default notification;
