import { IGlobalState } from '@cpa/base-core/store';
import { ICardProps, IDataItem } from '@cpa/base-core/types';
import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import React, { ElementType, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomCardTemplates } from '../../../../mapping';

import styles from './GenericCard.module.scss';

export interface IWorkloadInfo {
  colors: string[];
  icons: { name: string; identifier: string; image: string }[];
}

export interface IGenericCardProps extends ICardProps {
  onClick?: (item: IDataItem, isFolderItem?: boolean) => void;
}

const GenericCard: React.FC<IGenericCardProps> = (props) => {
  const { data, item, onClick } = props;

  const [t] = useTranslation();

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const CardComponent = useMemo<ElementType<ICardProps>>(() => {
    if (data?.page.customCardTemplate?.identifier) {
      return CustomCardTemplates[data.page.customCardTemplate.identifier] || CustomCardTemplates.Default;
    }
    return CustomCardTemplates.Default;
  }, [data]);

  const isFolderItem: boolean = useMemo(
    () => !!data.schema?.cp_parentPropertyJsonPath && !!item && !!(item.__originalItem?.__childrenCount || item.__childrenCount || 0),
    [item, data.schema]
  );

  const handleCardClick = useCallback(() => {
    if (
      data.page.cardAction === 'http://platform.cosmoconsult.com/ontology/OpenItemOnly' ||
      !data.schema?.cp_parentPropertyJsonPath ||
      !item.identifier
    ) {
      onClick?.(item, false);
      return;
    }

    onClick?.(item, isFolderItem);
  }, [data.page.cardAction, data.schema?.cp_parentPropertyJsonPath, isFolderItem, item, onClick]);

  const color = useMemo(() => {
    const workloadInfo = item?.__workloadInfo as unknown as IWorkloadInfo;
    if (workloadInfo?.colors?.length) {
      return workloadInfo.colors[0];
    } else return '--color-cosmo-gray-7';
  }, [item]);

  const folderContent = useMemo(() => {
    if (data?.page.customCardTemplate?.identifier === 'SolutionImageItem') {
      return (
        <div className={styles.folderWrapper} style={{ backgroundColor: color }}>
          {t('common.folder')}
        </div>
      );
    } else return <Icon className={classNames(styles.folderIcon, { [styles.dark]: darkMode })} iconName="FabricFolder" />;
  }, [t, color, darkMode, data?.page.customCardTemplate?.identifier]);

  return (
    <div className={classNames({ [styles.folderCard]: isFolderItem })}>
      {isFolderItem && data.page.cardAction !== 'http://platform.cosmoconsult.com/ontology/OpenItemOnly' && folderContent}
      <CardComponent {...props} onClick={handleCardClick} />
    </div>
  );
};

export default GenericCard;
