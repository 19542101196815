import { get, set, unset, mergeWith, isEqual } from 'lodash';
const isDefined = (value) => {
    return value !== undefined && value !== null;
};
export const Json = {
    isObj: (json) => {
        return isDefined(json) && typeof json === 'object' ? true : false;
    },
    isArr: (json) => {
        return isDefined(json) && Array.isArray(json) ? true : false;
    },
    isStr: (json) => {
        return isDefined(json) && typeof json === 'string';
    },
    isNum: (json) => {
        return isDefined(json) && typeof json === 'number';
    },
    isBool: (json) => {
        return isDefined(json) && typeof json === 'boolean';
    },
    isEqual: (json1, json2) => {
        return isEqual(json1, json2);
    },
    merge: (json, options) => {
        const customizer = (objValue, srcValue) => {
            if (options === null || options === void 0 ? void 0 : options.concatArrays) {
                if (Array.isArray(objValue)) {
                    return objValue.concat(srcValue);
                }
            }
            return;
        };
        let merged = {};
        for (const j of json.filter((j) => j !== undefined)) {
            merged = mergeWith(merged, j, customizer);
        }
        return merged;
    },
    get: (json, path, pathExceptionPatterns) => {
        if (path === undefined || path === '') {
            return json;
        }
        else {
            let pathParts = [];
            if (pathExceptionPatterns === null || pathExceptionPatterns === void 0 ? void 0 : pathExceptionPatterns.length) {
                let replacedPath = path;
                for (const pattern of pathExceptionPatterns) {
                    replacedPath = path.replace(new RegExp(pattern, 'g'), (match) => {
                        return match.replace(/\./g, '__DOT__');
                    });
                }
                pathParts = replacedPath.split('.').map((part) => part.replace(/__DOT__/g, '.'));
            }
            else {
                pathParts = path.split('.');
            }
            return get(json, pathParts);
        }
    },
    set: (json, path, value) => {
        if (!json) {
            throw new Error('Cannot set value on JSON null value.');
        }
        if (path === '') {
            Object.assign(json, value);
            return json;
        }
        else {
            return set(json, path, value);
        }
    },
    delete: (json, path) => {
        if (!json) {
            return false;
        }
        return unset(json, path);
    },
};
