import React, { useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';
import {
  CheckboxVisibility,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  Dropdown,
  GroupHeader,
  IColumn,
  Icon,
  IconButton,
  IContextualMenuProps,
  IDetailsGroupRenderProps,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IGroup,
  IRenderFunction,
  ITooltipHost,
  SelectAllVisibility,
  Text,
  TextField,
  ThemeContext,
  TooltipOverflowMode,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DynamicDataUrlFunction } from '@cpa/base-core/types';
import { Operator, IFilter, isDefinedAndNotEmpty } from '@cp/base-utils';
import { filterPaths, generatePropertiesContextMenuStyles, listPropertiesAsContextMenu } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { IPathMeta, JSONSchemaTypes } from '@cp/base-types';

import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import JsonSchemaForm from '../../../../../Form/components/JsonSchemaForm/JsonSchemaForm';
import formTheme from '../../../../../Form/helpers/theme';
import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import {
  filterLayerColumnDescriptor,
  generateDescriptorsFromParsedFilter,
  generateRawJsonFilterFromDescriptors,
  getNextDescriptorIndexForPath,
  IFilterDescriptor,
  IFilterExpression,
  searchTextColumnDescriptor,
  SuggestionValue,
} from './utils';
import styles from './FilterLayer.module.scss';

type GlobalFilterDetailsListItem = {
  globalFilter: {
    value: string;
  };
};
type PropertyDetailsListItem = {
  propertyFilter: {
    descriptor: IFilterDescriptor;
    propertyJsonPath: string;
    title: string;
    subtitle: string;
    operatorDescriptors: { key: Operator; text: string; data: { description: string } }[];
  };
};
type LayerDetailsListItem = {
  layer: {
    filter: IFilter;
    index: number;
  };
};

type DetailsListItem = GlobalFilterDetailsListItem | PropertyDetailsListItem | LayerDetailsListItem;

export interface IFilterLayerProps {
  schemaPaths: IPathMeta[];
  parsedFilter: IFilter;
  onChange: (value: Record<string, IFilterExpression> | string) => void;
  defaultGroup?: { property: string; title: string };
  dataUrl: string | DynamicDataUrlFunction;
  level?: number;
}

function extractTitlesFromSchemaPath(schemaPath: IPathMeta): { title: string; subtitle: string } {
  const titleChain = schemaPath.items
    .map((p) => p.title)
    .slice(1)
    .filter(Boolean);
  return {
    title: titleChain.length > 0 ? titleChain[titleChain.length - 1] : schemaPath.propertyJsonPath,
    subtitle: titleChain.slice(0, titleChain.length - 1).join(' - '),
  };
}

function isDateFormat(format: string): boolean {
  return ['date', 'date-time', 'date-time-long-year', 'cp:timespan'].includes(format.toLowerCase());
}

const getAvailableOperators = (keyType?: string, format?: string): Operator[] => {
  switch (keyType) {
    case 'array':
    case 'string':
      return [
        Operator.EQUALS,
        Operator.NOT_EQUALS,
        Operator.CONTAINS,
        Operator.NOT_CONTAINS,
        ...(format && isDateFormat(format) ? [Operator.GREATER_THAN, Operator.LESS_THAN] : []),
        Operator.NULL,
        Operator.NOT_NULL,
      ];
    case 'number':
    case 'integer':
      return [Operator.EQUALS, Operator.NOT_EQUALS, Operator.GREATER_THAN, Operator.LESS_THAN, Operator.NULL, Operator.NOT_NULL];
    case 'boolean':
      return [Operator.EQUALS, Operator.NOT_EQUALS, Operator.NULL, Operator.NOT_NULL];
    case 'object':
    default:
      return [Operator.EQUALS, Operator.NOT_EQUALS, Operator.NULL, Operator.NOT_NULL];
  }
};

function createGlobalFilterItem(globalFilter: string): GlobalFilterDetailsListItem {
  return { globalFilter: { value: globalFilter } };
}

function createFilterLayerItem(serializedFilter: string, index: number): LayerDetailsListItem {
  return { layer: { filter: JSON.parse(serializedFilter), index } };
}

const groupOperatorDropdownOptions = [
  { key: Operator.AND, text: 'AND' },
  { key: Operator.OR, text: 'OR' },
];

const OperatorDescriptionIcon: React.FC<{
  operator: Operator;
  value: SuggestionValue | SuggestionValue[];
  text?: string | null;
}> = ({ operator, value, text }) => {
  const [t] = useTranslation();
  const operatorDescription: string = useMemo(() => {
    let description = t(`search.helper.operators.${operator}.long`);
    if (value) {
      if (Array.isArray(value) && value.length) {
        description += ` "${value.join(', ')}"`;
      } else {
        description += ` "${value}"`;
      }
    }
    return description;
  }, [t, operator, value]);

  const tooltipRef = useRef<ITooltipHost>(null);
  const showTooltip = useCallback(() => {
    tooltipRef.current?.show();
  }, []);

  return (
    <HoverTooltip content={text || operatorDescription} componentRef={tooltipRef}>
      <Icon iconName="infoSolid" className={styles.operatorDescriptionIcon} onClick={showTooltip} />
    </HoverTooltip>
  );
};

const FilterLayer: React.FC<IFilterLayerProps> = ({ schemaPaths, onChange, parsedFilter, dataUrl, level = 0 }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const operatorDescriptions: Record<Operator, { text: string; data: { description: string } }> = useMemo(
    () => ({
      [Operator.EQUALS]: { text: '=', data: { description: t('search.helper.operators.equals.short') } },
      [Operator.NOT_EQUALS]: { text: '≠', data: { description: t('search.helper.operators.not_equals.short') } },
      [Operator.CONTAINS]: { text: '⊂', data: { description: t('search.helper.operators.contains.short') } },
      [Operator.NOT_CONTAINS]: { text: '⊄', data: { description: t('search.helper.operators.not_contains.short') } },
      [Operator.LESS_THAN]: { text: '<', data: { description: t('search.helper.operators.less_than.short') } },
      [Operator.GREATER_THAN]: { text: '>', data: { description: t('search.helper.operators.greater_than.short') } },
      [Operator.NULL]: { text: '∅', data: { description: t('search.helper.operators.null.short') } },
      [Operator.NOT_NULL]: { text: '⦿', data: { description: t('search.helper.operators.not_null.short') } },
      [Operator.AND]: { text: 'AND', data: { description: '' } },
      [Operator.OR]: { text: 'OR', data: { description: '' } },
    }),
    [t]
  );

  const filteredSchemaPaths = useMemo(() => {
    const anyOfPropertyJsonPaths: string[] = [];
    return schemaPaths
      .map((schemaPath): IPathMeta | null => {
        if (anyOfPropertyJsonPaths.includes(schemaPath.propertyJsonPath)) return null;
        if (!schemaPath.items.some((p) => p.anyOf)) return schemaPath;
        const anyOfIndex = schemaPath.items.findIndex((item) => !!item.anyOf);
        if (anyOfIndex !== -1 && schemaPath.items[anyOfIndex + 1]?.shortcutOption) {
          const lastItem = schemaPath.items[schemaPath.items.length - 1];
          anyOfPropertyJsonPaths.push(schemaPath.propertyJsonPath);
          return { ...schemaPath, items: filterPaths(schemaPath.items).concat({ ...lastItem, title: lastItem.title }) };
        }
        return schemaPath;
      })
      .filter(Boolean) as IPathMeta[];
  }, [schemaPaths]);

  const schemaPathsMap = useMemo<Record<string, IPathMeta>>(() => _.keyBy(filteredSchemaPaths, 'propertyJsonPath'), [filteredSchemaPaths]);

  const propertiesFilterDescriptors = useMemo<IFilterDescriptor[]>(
    () => generateDescriptorsFromParsedFilter(parsedFilter, schemaPathsMap),
    [schemaPathsMap, parsedFilter]
  );
  const groupOperator: Operator.AND | Operator.OR = useMemo(() => {
    if (!isDefinedAndNotEmpty(parsedFilter.entries)) {
      return level > 0 ? Operator.OR : Operator.AND;
    }
    return Operator.OR in parsedFilter.entries! ? Operator.OR : Operator.AND;
  }, [level, parsedFilter.entries]);

  const detailsListItems = useMemo<DetailsListItem[]>(() => {
    const globalFilters: DetailsListItem[] = [];
    const propertyFilters: DetailsListItem[] = [];
    const filterLayers: DetailsListItem[] = [];

    for (const descriptor of propertiesFilterDescriptors) {
      const { schemaPath, value, index } = descriptor;
      const { propertyJsonPath } = schemaPath;
      if (propertyJsonPath === searchTextColumnDescriptor.propertyJsonPath) {
        globalFilters.push(createGlobalFilterItem(value.toString()));
        continue;
      }

      if (propertyJsonPath === filterLayerColumnDescriptor.propertyJsonPath) {
        filterLayers.push(createFilterLayerItem(value.toString(), index));
        continue;
      }

      const { title, subtitle } = extractTitlesFromSchemaPath(schemaPath);
      const operatorDescriptors = getAvailableOperators(schemaPath.type, schemaPath.propertySchema.format).map((op) => ({
        key: op,
        ...operatorDescriptions[op],
      }));
      propertyFilters.push({
        propertyFilter: {
          descriptor,
          propertyJsonPath,
          title,
          subtitle,
          operatorDescriptors,
        },
      });
    }

    return [...globalFilters.slice(0, 1), ...propertyFilters, ...filterLayers];
  }, [operatorDescriptions, propertiesFilterDescriptors]);

  const detailsListGroups = useMemo(() => {
    return [
      {
        startIndex: 0,
        count: 1,
        key: 'globalFilter',
        name: 'globalFilter',
        isCollapsed: false,
      },
      ...propertiesFilterDescriptors
        .filter((d) => d.schemaPath.propertyJsonPath !== searchTextColumnDescriptor.propertyJsonPath)
        .map(({ schemaPath, index: descriptorPathIndex }, index): IGroup => {
          const { title, subtitle } = extractTitlesFromSchemaPath(schemaPath);

          return {
            startIndex: index + 1,
            count: 1,
            key: schemaPath.propertyJsonPath + descriptorPathIndex,
            name: title,
            data: {
              property: schemaPath.propertyJsonPath,
              index: descriptorPathIndex,
              subtitle: subtitle,
            },
          };
        }),
    ];
  }, [propertiesFilterDescriptors]);

  const detailsListColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'filterPropertyColumn',
        name: t('search.helper.filterOptionColumnName'),
        fieldName: 'propertyJsonPath',
        minWidth: 100,
        maxWidth: 200,
        isResizable: false,
      },
      {
        key: 'operatorColumn',
        name: t('search.helper.filterOperatorColumnName'),
        fieldName: 'operator',
        minWidth: 82,
        maxWidth: 82,
        isResizable: false,
      },
      {
        key: 'valueColumn',
        name: t('search.helper.filterValueColumnName'),
        filterName: 'value',
        minWidth: 150,
        isResizable: false,
      },
    ],
    [t]
  );

  const onRenderRow = useCallback<IRenderFunction<IDetailsRowProps>>(
    (props, defaultRenderer) => {
      if (props?.item && 'layer' in props.item) {
        const layerDescriptor: LayerDetailsListItem = props.item;
        return (
          <div style={{ paddingLeft: '35px' }}>
            <FilterLayer
              schemaPaths={schemaPaths}
              parsedFilter={layerDescriptor.layer.filter}
              onChange={(subFilterValue) => {
                onChange(
                  generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, groupOperator, {
                    propertyJsonPath: filterLayerColumnDescriptor.propertyJsonPath,
                    index: layerDescriptor.layer.index,
                    processor: (d) => ({ ...d, value: JSON.stringify(subFilterValue) }),
                  })
                );
              }}
              dataUrl={dataUrl}
              level={level + 1}
            />
          </div>
        );
      }

      return defaultRenderer?.(props) || null;
    },
    [dataUrl, groupOperator, onChange, propertiesFilterDescriptors, schemaPaths, level]
  );

  const onRenderItemColumn = useCallback(
    (item: DetailsListItem, index: number, column: IColumn) => {
      switch (column.key) {
        case 'filterPropertyColumn':
          const chipValue = 'propertyFilter' in item ? item.propertyFilter.propertyJsonPath : t('search.helper.global');
          return (
            <div className={classNames(styles.filterChip, darkMode ? styles.dark : styles.light)}>
              <code>
                <HoverTooltip
                  calloutProps={{ beakWidth: 8, directionalHint: DirectionalHint.topCenter }}
                  overflowMode={TooltipOverflowMode.Parent}
                  content={chipValue}
                >
                  {chipValue}
                </HoverTooltip>
              </code>
            </div>
          );
        case 'operatorColumn':
          if ('propertyFilter' in item) {
            const { operatorDescriptors } = item.propertyFilter;
            return (
              <div className={styles.operatorContainer}>
                <Dropdown
                  placeholder={''}
                  selectedKey={item.propertyFilter.descriptor.operator}
                  options={operatorDescriptors}
                  onRenderOption={(option): JSX.Element => <span>{option?.data.description}</span>}
                  onRenderTitle={(option): JSX.Element => <span title={option?.[0].data.description}>{option?.[0].text}</span>}
                  onChange={(e, option) => {
                    if (!option) {
                      return;
                    }

                    // Field operator change
                    onChange(
                      generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, groupOperator, {
                        propertyJsonPath: item.propertyFilter.descriptor.schemaPath.propertyJsonPath,
                        index: item.propertyFilter.descriptor.index,
                        processor: (d) => ({ ...d, operator: option.key as Operator }),
                      })
                    );
                  }}
                  dropdownWidth={150}
                />
                <OperatorDescriptionIcon operator={item.propertyFilter.descriptor.operator} value={item.propertyFilter?.descriptor.value!} />
              </div>
            );
          } else if ('globalFilter' in item) {
            const value = item.globalFilter.value;
            return (
              <>
                <span className={styles.operatorText} title={operatorDescriptions[Operator.CONTAINS].data.description}>
                  {operatorDescriptions[Operator.CONTAINS].text}
                </span>
                <OperatorDescriptionIcon operator={Operator.CONTAINS} value={value!} text={!value ? t('search.helper.globalOperatorEmpty') : null} />
              </>
            );
          }
          return null;
        case 'valueColumn':
          if ('propertyFilter' in item) {
            if ([Operator.NULL, Operator.NOT_NULL].includes(item.propertyFilter.descriptor.operator)) {
              return null;
            }

            const filteredValues = (
              Array.isArray(item.propertyFilter.descriptor.value) ? item.propertyFilter.descriptor.value : [item.propertyFilter.descriptor.value]
            ).filter((v) => v !== null && v !== undefined);

            if (
              item.propertyFilter.descriptor.schemaPath.type === 'string' &&
              item.propertyFilter.descriptor.schemaPath.propertySchema.format &&
              isDateFormat(item.propertyFilter.descriptor.schemaPath.propertySchema.format)
            ) {
              return (
                <JsonSchemaForm
                  schema={{ type: 'string', format: item.propertyFilter.descriptor.schemaPath.propertySchema.format }}
                  noHtml5Validate={true}
                  noValidate={true}
                  className={styles.datePicker}
                  formData={filteredValues[0]}
                  onChange={(e) => {
                    // Field value change
                    onChange(
                      generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, groupOperator, {
                        propertyJsonPath: item.propertyFilter.descriptor.schemaPath.propertyJsonPath,
                        index: item.propertyFilter.descriptor.index,
                        processor: (d) => ({ ...d, value: e.formData as string }),
                      })
                    );
                  }}
                  {...formTheme}
                >
                  <></>
                </JsonSchemaForm>
              );
            }

            const searchSuggestionsValues: [SuggestionValue, SuggestionValue][] = filteredValues.map((selectedIdentifier) => [
              selectedIdentifier,
              selectedIdentifier,
            ]);
            return (
              <SearchSuggestions
                disabled={!item.propertyFilter.descriptor.operator}
                value={searchSuggestionsValues}
                parsedFilter={parsedFilter}
                onChange={(selectedItems: [SuggestionValue, SuggestionValue][]): void => {
                  const selectedIdentifiers = selectedItems
                    .map(([selectedIdentifier]) => selectedIdentifier)
                    .filter((selectedIdentifier) => !!selectedIdentifier || selectedIdentifier === false);

                  let newValue: SuggestionValue | SuggestionValue[] = selectedIdentifiers;
                  switch (selectedIdentifiers.length) {
                    case 0:
                      newValue = '';
                      break;
                    case 1:
                      switch (item.propertyFilter.descriptor.schemaPath.type) {
                        case 'number':
                        case 'integer':
                          newValue = Number.isNaN(+selectedIdentifiers[0]) ? selectedIdentifiers[0] : +selectedIdentifiers[0];
                          break;
                        case 'boolean':
                          newValue =
                            typeof selectedIdentifiers[0] === 'string' && ['true', 'false'].includes(selectedIdentifiers[0])
                              ? selectedIdentifiers[0] === 'true'
                              : selectedIdentifiers[0];
                          break;
                        default:
                          newValue = selectedIdentifiers[0];
                      }
                      break;
                  }

                  // Field value change
                  onChange(
                    generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, groupOperator, {
                      propertyJsonPath: item.propertyFilter.descriptor.schemaPath.propertyJsonPath,
                      index: item.propertyFilter.descriptor.index,
                      processor: (d) => ({ ...d, value: newValue }),
                    })
                  );
                }}
                dataUrl={dataUrl}
                label={item.propertyFilter.title}
                dataPath={item.propertyFilter.descriptor.schemaPath.propertyJsonPath}
                pathMetaItems={item.propertyFilter.descriptor.schemaPath.items}
                enumMapping={item.propertyFilter.descriptor.schemaPath.enumMapping}
                multiselect={
                  !!item.propertyFilter.descriptor.operator &&
                  [Operator.EQUALS, Operator.CONTAINS, Operator.NOT_CONTAINS, Operator.NOT_EQUALS].includes(item.propertyFilter.descriptor.operator)
                }
                lowercase={
                  !item.propertyFilter.descriptor.operator ||
                  [Operator.NOT_CONTAINS, Operator.CONTAINS].includes(item.propertyFilter.descriptor.operator)
                }
              />
            );
          } else if ('globalFilter' in item) {
            const handleChange = (value: string | undefined): void => {
              // Global filter change
              onChange(
                generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, groupOperator, {
                  propertyJsonPath: searchTextColumnDescriptor.propertyJsonPath,
                  index: 0,
                  processor: (d) => ({ ...d, value: value || '' }),
                })
              );
            };
            return <TextField value={item.globalFilter?.value} onChange={(e, value): void => handleChange(value)} />;
          }
          return null;
        default:
          return null;
      }
    },
    [t, darkMode, onChange, propertiesFilterDescriptors, groupOperator, operatorDescriptions, dataUrl]
  );

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = useCallback(
    (props, render) =>
      level > 0
        ? null
        : render!({
            ...props!,
            selectAllVisibility: SelectAllVisibility.none,
            styles: { collapseButton: { display: 'none !important' } },
            onToggleCollapseAll: () => undefined,
          }),
    [level]
  );

  const onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = (props) =>
    props?.groupIndex ? (
      <GroupHeader
        {...props}
        styles={{ root: { height: 34, overflowY: 'hidden' }, groupHeaderContainer: { height: '100%' } }}
        compact={true}
        onRenderTitle={(): JSX.Element => (
          <aside className={styles.groupHeaderRoot}>
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              onClick={(): void => {
                // Field filter delete
                onChange(
                  generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, groupOperator, {
                    propertyJsonPath: props.group?.data.property as string,
                    index: props.group?.data.index as number,
                    processor: () => null,
                  })
                );
              }}
              className={styles.removingButton}
            />
            <div className={classNames(styles.groupHeader, { [styles.groupHeaderWrap]: !!props?.group?.data.subtitle })}>
              <Text>{props?.group?.name}</Text>
              <Text className={styles.smallText} variant="xSmall">
                {props?.group?.data.subtitle}
              </Text>
            </div>
          </aside>
        )}
      />
    ) : null;

  const handleAddFilterLayer = useCallback(() => {
    onChange(
      generateRawJsonFilterFromDescriptors(
        [
          ...propertiesFilterDescriptors,
          {
            operator: groupOperator,
            value: '{}',
            schemaPath: filterLayerColumnDescriptor,
            index: getNextDescriptorIndexForPath(propertiesFilterDescriptors, filterLayerColumnDescriptor.propertyJsonPath),
          },
        ],
        groupOperator
      )
    );
  }, [groupOperator, onChange, propertiesFilterDescriptors]);

  const theme = useContext(ThemeContext);
  const footerContent = useMemo(() => {
    const onSelectSuggestion = (property: string, type: JSONSchemaTypes): void => {
      onChange(
        generateRawJsonFilterFromDescriptors(
          [
            ...propertiesFilterDescriptors,
            {
              operator: type === 'string' ? Operator.CONTAINS : Operator.EQUALS,
              value: '',
              schemaPath: schemaPathsMap[property],
              index: getNextDescriptorIndexForPath(propertiesFilterDescriptors, property),
            },
          ],
          groupOperator
        )
      );
    };

    const dropdownMenuItems: IContextualMenuProps = {
      items: [
        {
          key: 'addField',
          text: t('search.helper.selectFields'),
          subMenuProps: {
            items: listPropertiesAsContextMenu(
              filteredSchemaPaths,
              1,
              (property, type) => onSelectSuggestion(property, type),
              (property) => propertiesFilterDescriptors.some((d) => d.schemaPath.propertyJsonPath === property),
              theme
            ),
            styles: generatePropertiesContextMenuStyles(theme),
          },
          iconProps: {
            iconName: 'AddToShoppingList',
          },
        },
        {
          key: 'addFilterLayer',
          text: t('search.helper.addFilterLayer'),
          onClick: handleAddFilterLayer,
          iconProps: {
            iconName: 'RowsGroup',
          },
        },
      ],
      styles: generatePropertiesContextMenuStyles(theme),
    };

    return (
      <section className={styles.footerActions}>
        <div className={styles.buttons}>
          {isDefinedAndNotEmpty(parsedFilter.entries) && (
            <Dropdown
              options={groupOperatorDropdownOptions}
              selectedKey={groupOperator}
              className={styles.dropdown}
              onChange={(e, option) => {
                if (!option) {
                  return;
                }

                onChange(generateRawJsonFilterFromDescriptors(propertiesFilterDescriptors, option.key as Operator));
              }}
            />
          )}
          <DefaultButton className={styles.add} text={t('common.add')} menuProps={dropdownMenuItems} iconProps={{ iconName: 'CalculatorAddition' }} />
        </div>
      </section>
    );
  }, [
    theme,
    t,
    filteredSchemaPaths,
    handleAddFilterLayer,
    groupOperator,
    parsedFilter.entries,
    onChange,
    propertiesFilterDescriptors,
    schemaPathsMap,
  ]);

  return (
    <aside className={styles.filterLayerWrapper}>
      <DetailsList
        className={styles.additionalPropsRoot}
        columns={detailsListColumns}
        items={detailsListItems}
        compact={true}
        onRenderDetailsHeader={onRenderDetailsHeader}
        checkboxVisibility={CheckboxVisibility.hidden}
        groupProps={{
          headerProps: {
            selectAllButtonProps: { hidden: true },
            compact: true,
            onGroupHeaderClick: (): null => null,
          },
          onRenderHeader: onRenderGroupHeader,
        }}
        layoutMode={DetailsListLayoutMode.justified}
        groups={detailsListGroups}
        onRenderItemColumn={onRenderItemColumn}
        selectionPreservedOnEmptyClick={true}
        onRenderRow={onRenderRow}
      />
      {footerContent}
    </aside>
  );
};

export default React.memo(FilterLayer);
