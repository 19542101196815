import React, { Suspense, lazy } from 'react';
import { IDataItem } from '@cpa/base-core/types';

import LoadingArea from '../../LoadingArea/LoadingArea';

const TextDifference = lazy(() => import('../../TextDifference/TextDifference'));

export interface IDifferenceAsJsonProps {
  itemA: IDataItem;
  itemB: IDataItem;
}

const DifferenceAsJson: React.FC<IDifferenceAsJsonProps> = ({ itemA, itemB }) => {
  const contentsA = JSON.stringify(itemA, null, 2);

  const contentsB = JSON.stringify(itemB, null, 2);

  return (
    <Suspense fallback={<LoadingArea />}>
      <TextDifference contentsA={contentsA} contentsB={contentsB} />
    </Suspense>
  );
};

export default React.memo(DifferenceAsJson);
