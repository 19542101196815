import { ConnectionType, LogLevel } from '@cp/base-types';
export class Connection {
    constructor(level) {
        this.level = level;
    }
}
export class ConsoleConnection extends Connection {
    constructor(level = LogLevel.Info) {
        super(level);
        this.type = ConnectionType.Console;
    }
}
export class ServerConnection extends Connection {
    constructor(url, level = LogLevel.Info) {
        super(level);
        this.type = ConnectionType.Server;
        this.url = url;
    }
}
export class BusConnection extends Connection {
    constructor(sender, level = LogLevel.Info) {
        super(level);
        this.type = ConnectionType.Bus;
        this.sender = sender;
    }
}
