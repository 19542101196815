import { IJSONSchema, Schemas } from '@cp/base-types';
import { useLoadableData } from '@cpa/base-core/hooks';
import { IDataItem } from '@cpa/base-core/types';
import { DialogType, MessageBarType } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { IGlobalState } from '@cpa/base-core/store';
import { showDialog } from '@cpa/base-core/helpers';
import { TableKeyPrefix } from '@cpa/base-core/constants';

import Drawer from '../../../Drawer/Drawer';
import MessageBars from '../../../MessageBars/MessageBars';
import ShimmerGrid from '../../../ShimmerGrid/ShimmerGrid';
import Widget from '../../../Widget/Widget';
import ScrollingContent from '../../../ScrollingContent/ScrollingContent';
import { SettingsItemType } from '../../../Widget/components/Settings/Settings';

import styles from './LocalizationsDrawer.module.scss';

export interface ILocalizationsDrawerProps {
  isOpen: boolean;
  page: Schemas.CpaPage;
  pageSchema: IJSONSchema;
  item: IDataItem | null;
  onClose: (...args: unknown[]) => void;
  onUpdate?: (...args: unknown[]) => void;
}

export const LocalizationsDrawer: React.FC<ILocalizationsDrawerProps> = ({ isOpen, item, page, onClose, onUpdate }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [t] = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);

  const localizationsUrl = useMemo(() => {
    if (!page.dataUrl || !item?.identifier) {
      return;
    }
    const url = new URL(page.dataUrl, window.location.origin);

    if (!url.pathname.endsWith('/')) {
      url.pathname += '/';
    }

    url.pathname += `${encodeURIComponent(item.identifier)}/localizations`;

    return url.pathname;
  }, [page.dataUrl, item?.identifier]);

  const {
    loadItems,
    schema,
    totalItems,
    isFetching,
    errors: loadableErrors,
    items: loadedLocalizations,
  } = useLoadableData(
    localizationsUrl || '/',
    page.dataEndpoint?.identifier || axiosDictionary.appDataService,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    {
      afterRequest(response) {
        const localizations: IDataItem[] = [];

        Object.entries(response.entities).forEach(([language, localization]) => {
          localizations.push({
            identifier: language,
            item: localization,
          });
        });

        response.entities = localizations;

        return response;
      },
    }
  );

  useEffect(() => {
    loadItems({}, { resetExistingData: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizationsUrl, page.dataEndpoint?.identifier]);

  const onPageRefresh = useCallback(() => {
    loadItems({}, { resetExistingData: true });
  }, [loadItems]);

  const localizationSchema: IJSONSchema | null = useMemo(() => {
    if (!schema) {
      return null;
    }

    return {
      type: 'object',
      properties: {
        identifier: schema.properties?.identifier || {
          type: 'string',
        },
        item: schema,
      },
    };
  }, [schema]);

  const handleDelete = useCallback(
    async (itemsToDelete: IDataItem[]) => {
      if (!page.dataEndpoint?.identifier) {
        return;
      }

      const endpoint = getEndpoint(page.dataEndpoint.identifier);
      try {
        if (itemsToDelete.length && localizationsUrl) {
          await endpoint.axios.delete(localizationsUrl, {
            data: {
              locales: itemsToDelete.map((item) => item.identifier),
            },
          });

          onUpdate?.();
        }
      } catch (e) {
        setErrors([e.message]);
      } finally {
        onPageRefresh();
      }
    },
    [page.dataEndpoint, onPageRefresh, localizationsUrl, onUpdate]
  );

  const handleDeleteClick = useCallback(
    async (items?: IDataItem[]) => {
      if (!items || !items.length) {
        return;
      }

      const deleteConfirmed = await showDialog({
        message: '',
        dialogContentProps: {
          type: DialogType.largeHeader,
          title: t('common.delete'),
          subText: t('common.deleted'),
        },
        primaryButtonText: 'common.confirm',
        secondaryButtonText: 'common.cancel',
        closeOnClickOutside: true,
        closeOnAction: true,
      });
      if (!deleteConfirmed) {
        return;
      }

      await handleDelete(items);
    },
    [handleDelete, t]
  );

  const widgetSettings = useMemo(() => {
    return [
      {
        type: SettingsItemType.REFRESH,
        onClick: onPageRefresh,
        disabled: isFetching,
      },
    ];
  }, [isFetching, onPageRefresh]);

  const scrollingContentData = useMemo(
    () => ({
      items: loadedLocalizations,
      schema: localizationSchema,
      isFetching,
      totalItems: totalItems || 0,
      page: { identifier: 'localizations', name: t('common.localizations'), spanColumn: 'identifier' } as Schemas.CpaPage,
    }),
    [isFetching, loadedLocalizations, localizationSchema, t, totalItems]
  );

  const customActions = useMemo(
    () => [
      {
        onClick: handleDeleteClick,
        button: {
          key: 'delete',
          title: t('common.delete'),
          icon: 'Delete',
          condition: (selected: IDataItem[]): boolean => !!selected.length,
        },
      },
    ],
    [handleDeleteClick, t]
  );

  const allErrors = useMemo(() => [...loadableErrors, ...errors], [errors, loadableErrors]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div className={styles.drawerRoot}>
        <MessageBars messageBarType={MessageBarType.error} isMultiline={true} messages={allErrors} />
        <Widget title={t('common.localizations')} settingsItems={widgetSettings} headerIconName={'DependencyAdd'}>
          <div style={{ padding: 20 }}>
            {isFetching && loadedLocalizations.length === 0 && !localizationSchema ? (
              <ShimmerGrid darkMode={darkMode} gridArea={'"a" "b" "c" "d" "e"'} gridGap={10} height={250} />
            ) : (
              <ScrollingContent
                tableKey={TableKeyPrefix.Localizations}
                disableItemSharing={true}
                disableStickyHeader={true}
                disableStickyActions={true}
                disableDoubleClick={false}
                isWidget={false}
                pageSize={5}
                data={scrollingContentData}
                customActions={customActions}
              />
            )}
          </div>
        </Widget>
      </div>
    </Drawer>
  );
};

export default LocalizationsDrawer;
