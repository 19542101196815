const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Deliverable',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDeliverable' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2Fdeliverables%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2Foffers%2FofferException%2Flicensing%2Fdeliverables%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FLicense?$filter=deliverable%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDeliverable?$filter=parentDeliverable%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FLicenseProvisioning?$filter=licenseItems%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parentDeliverable.identifier',
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Deliverable',
    'properties': {
        'licensable': {
            'title': 'Licensable',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 60 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/licensable', 'rel': 'canonical' }],
        },
        'parentDeliverable': {
            'title': 'Parent Deliverable',
            '$id': 'http://platform.cosmoconsult.com/ontology/Deliverable',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDeliverable' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentDeliverable', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentDeliverable.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Deliverable' } },
            'cp_ui': { 'sortOrderForm': 50 },
        },
        'productCode': {
            'title': 'Product Code',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 45 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/productCode', 'rel': 'canonical' }],
        },
        'productPlatform': {
            'title': 'Product Platform or Workload',
            '$id': 'http://platform.cosmoconsult.com/ontology/ProductPlatform',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FProductPlatform' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/productPlatform', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parent.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Product Platform or Workload' } },
            'cp_ui': { 'sortOrderForm': 40 },
        },
        'trialPeriod': {
            'title': 'Custom Trial Period (in Days)',
            'description': '<div>The period a trial license for this deliverable is valid for. If no value is specified, the trial period defaults to 30 days.</div>',
            'type': 'number',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/trialPeriod', 'rel': 'canonical' }],
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': "<div>Short description or summary that serves to convey the most important information on the subject or subject matter in a concise form (1-3 sentences). Often used for list, card or tiles. The idea is to capture the reader's or viewer's attention without going into detail. A short description can be compared with a teaser text.</div>\n<div> </div>",
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 70 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['productPlatform'],
};
export const DeliverableSchema = Schema;
