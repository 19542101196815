const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/playwright/TestPerson',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Fplaywright%2FTestPerson' }],
    'cp_parentPropertyJsonPath': 'parentTestPerson.identifier',
    'cp_helpLink': 'https://google.com',
    'cp_handledByApiGateway': false,
    'cp_disableTrash': true,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Test Person',
    'properties': {
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url', 'cp_ui': { 'downloadable': true } },
            'cp_ui': { 'sortOrderForm': 95 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'oneFile': {
            'title': 'One File',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'downloadable': true, 'sortOrderForm': 96 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/oneFile', 'rel': 'canonical' }],
        },
        'parentTestPerson': {
            'title': 'Parent (Test)',
            '$id': 'http://platform.cosmoconsult.com/ontology/TestPerson',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTestPerson' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentTestPerson', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentTestPerson.identifier',
            'cp_helpLink': 'https://google.com',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': true,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Test Person' } },
            'readOnly': true,
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 97, 'disableLookupAdvancedFilter': true },
        },
        'annotations': {
            'title': 'Annotations',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotation',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotation' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cp Annotation',
                'properties': {
                    'value': {
                        'title': 'Value',
                        'type': 'string',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/value', 'rel': 'canonical' }],
                    },
                    'annotationLanguage': {
                        'title': 'Annotation Language',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpLanguage',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpLanguage' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/annotationLanguage', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': true,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Language' } },
                    },
                    'annotationPropertyType': {
                        'title': 'Annotation Property Type',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotationProperty',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotationProperty' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/annotationPropertyType', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': true,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': {
                            'identifier': {
                                'title': 'Identifier',
                                'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                                'type': 'string',
                                'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                            },
                        },
                    },
                },
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/annotations', 'rel': 'canonical' }],
        },
        'additionalName': {
            'title': 'Additional Name',
            'description': 'An additional name for a Person, can be used for a middle name.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 98 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/additionalName', 'rel': 'canonical' }],
        },
        'deathDate': {
            'title': 'Death Date',
            'description': 'Date of death.',
            'type': 'string',
            'format': 'date',
            'default': '2021-10-01',
            'cp_ui': { 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://schema.org/deathDate', 'rel': 'canonical' }],
        },
        'email': {
            'title': 'Email',
            'description': 'Email address.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 99 },
            'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
        },
        'height': {
            'title': 'Height',
            'description': ' A point value or interval for product characteristics and other purposes.',
            '$id': 'http://schema.org/QuantitativeValue',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FQuantitativeValue' },
                { 'href': 'http://schema.org/height', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 100 },
        },
        'homeLocation': {
            'title': 'Home Location',
            'description': "A contact location for a person's residence.",
            'anyOf': [
                {
                    '$id': 'http://schema.org/Place',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPlace' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Place',
                    'description': 'Entities that have a somewhat fixed, physical extension.',
                    'properties': {
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://schema.org/Place',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
                {
                    '$id': 'http://schema.org/ContactPoint',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FContactPoint' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Contact Point',
                    'description': 'A contact point&#x2014;for example, a Customer Complaints department.',
                    'properties': {
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_ui': { 'hiddenInReadonlyContent': true },
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Url',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'data-url',
                            'cp_ui': { 'downloadable': true },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://schema.org/ContactPoint',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
            ],
            'cp_ui': { 'sortOrderForm': 101 },
            'links': [{ 'href': 'http://schema.org/homeLocation', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'alternateName': {
            'title': 'Alternate Name',
            'description': '<div>HERE</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 93 },
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>HERE1</div>',
            'type': 'string',
            'format': 'cp:versionInfo',
            'cp_ui': { 'sortOrderForm': 92 },
            'cp_localizable': true,
            'links': [
                {
                    'rel': 'glossary',
                    'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUnitOfMeasure%3Fcp_suggestionPropertyJsonPath%3Dname',
                },
                { 'href': 'http://schema.org/name', 'rel': 'canonical' },
            ],
        },
        'mainEntityOfPage': {
            'title': 'Main Entity Of Page',
            'description': 'The most generic kind of creative work, including books, movies, photographs, software programs, etc.',
            '$id': 'http://schema.org/CreativeWork',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FCreativeWork' },
                { 'href': 'http://schema.org/mainEntityOfPage', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'datePublished': {
                    'title': 'Date Published',
                    'description': 'Date of first broadcast/publication.',
                    'type': 'string',
                    'format': 'date-time',
                    'default': '2021-10-01T05:00:00.000Z',
                    'links': [{ 'href': 'http://schema.org/datePublished', 'rel': 'canonical' }],
                },
                'isFamilyFriendly': {
                    'title': 'Is Family Friendly',
                    'description': 'Indicates whether this content is family friendly.',
                    'type': 'boolean',
                    'links': [{ 'href': 'http://schema.org/isFamilyFriendly', 'rel': 'canonical' }],
                },
            },
            'required': ['datePublished'],
            'cp_ui': { 'sortOrderForm': 102 },
        },
    },
    'required': ['deathDate', 'name'],
};
export const TestPersonSchema = Schema;
