import { IJSONSchema, Schemas } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicDataUrlFunction, IDataItem, IGenericComponentProps } from '@cpa/base-core/types';
import { DefaultButton, Dialog, DialogFooter, SelectionMode, IDialogContentProps, IObjectWithKey, Selection } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import * as _ from 'lodash';

import GenericScreen, { IGenericScreenProps } from '../../screens/GenericScreen/GenericScreen';

import styles from './ItemSelectionDialog.module.scss';

export interface IItemSelectionDialogProps {
  onSubmit: (item: IDataItem, schema: IJSONSchema) => void;
  onCancel?: () => void;
  page: Schemas.CpaPage & { dynamicDataUrl?: DynamicDataUrlFunction };
  dialogContentProps: IDialogContentProps;
  genericScreenProps?: Partial<IGenericScreenProps>;
}

export interface IItemSelectionDialogRef {
  openDialog(): void;

  closeDialog(): void;
}

const modalProps = { containerClassName: styles.dialogWrapper };

export const ItemSelectionDialog = React.forwardRef(function ItemSelectionDialog(
  { onSubmit, page, dialogContentProps, onCancel, genericScreenProps }: IItemSelectionDialogProps,
  ref: React.Ref<IItemSelectionDialogRef>
) {
  const [dialogOpened, { setTrue: openDialog, setFalse: closeDialog }] = useBoolean(false);
  const [maximized, setMaximized] = useState<boolean>(false);
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' });
  useImperativeHandle(
    ref,
    () => ({
      openDialog(): void {
        openDialog();
      },
      closeDialog(): void {
        closeDialog();
      },
    }),
    [closeDialog, openDialog]
  );

  const handleItemClick = useCallback(
    (item: IDataItem, selection: Selection<IObjectWithKey & IDataItem>, schema: IJSONSchema | null) => {
      if (!schema) {
        return;
      }
      onSubmit(item, schema);
      closeDialog();
    },
    [closeDialog, onSubmit]
  );
  const handleCancel = useCallback(() => {
    closeDialog();
    onCancel?.();
  }, [closeDialog, onCancel]);

  const toggleMaximizeButton = useCallback(() => {
    setMaximized(!maximized);
  }, [maximized]);

  const dialogWidth = useMemo(() => {
    if (maximized) return '98vw';
    return 'min(98vw, 600px)';
  }, [maximized]);

  const itemSelectionPage = useMemo<Schemas.CpaPage>(
    () => ({
      ...(page as Schemas.CpaPage),
      chart: undefined,
      staticContent: undefined,
      customCardTemplate: undefined,
      customTemplate: undefined,
      customSwitchText: undefined,
      maxNoOfCardsInSlider: undefined,
      singleItemTemplate: undefined,
      singleItemComponents: undefined,
      actions: undefined,
      identifier: `item_selection.${page.identifier || 'default'}`,
    }),
    [page]
  );

  const genericComponentProps = useMemo<Partial<IGenericComponentProps>>(
    () => ({
      pageSize: 10,
      hideSettings: true,
      hideMoreButton: true,
      withoutAnimation: true,
      showFilterInWidget: true,
      isWidget: true,
      disableFacetFilters: true,
      widgetSettings: {
        hideIcon: true,
        hideTitle: true,
        hideDescription: true,
      },
      scrollingContentProps: {
        isWidget: true,
        hideHeader: false,
        hideActions: false,
        hideFilter: false,
        hideSelectedLabel: true,
        disableDoubleClick: true,
        selectionMode: SelectionMode.none,
        onItemClick: handleItemClick,
        disableDragDrop: true,
        widgetHeight: maximized ? '52vh' : undefined,
      },
    }),
    [handleItemClick, maximized]
  );

  const props = useMemo(() => {
    return _.merge(
      {
        page: itemSelectionPage,
        isWidget: true,
        withoutAnimation: true,
        resetFilterOnRefresh: true,
        genericComponentProps: genericComponentProps,
      },
      genericScreenProps
    );
  }, [genericComponentProps, genericScreenProps, itemSelectionPage]);

  return (
    <Dialog
      modalProps={modalProps}
      hidden={!dialogOpened}
      onDismiss={handleCancel}
      dialogContentProps={{
        ...dialogContentProps,
        topButtonsProps: isSmallScreen
          ? undefined
          : [
              {
                iconProps: {
                  iconName: maximized ? 'BackToWindow' : 'FullScreen',
                },
                onClick: toggleMaximizeButton,
              },
            ],
      }}
      maxWidth={dialogWidth}
      minWidth={dialogWidth}
    >
      <div className={classNames(styles.targetSelection, { [styles.dark]: darkMode })}>
        <GenericScreen {...props} showEmptyMessage />
      </div>
      <DialogFooter>
        <DefaultButton onClick={handleCancel} text={t('common.cancel')} />
      </DialogFooter>
    </Dialog>
  );
});
