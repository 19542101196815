import { __rest } from "tslib";
import * as _ from 'lodash';
import { createQuery } from '@cp/base-odata';
import buildQuery from 'odata-query';
import { DataItemProperties } from '@cp/base-types';
import { DataServiceModules, isDefinedAndNotEmpty } from '../data';
import { isRelationSchema } from '../ontology';
import { cloneDeepWithMetadata } from './copy';
import { escapeIllegalOdataValueChars } from './odata';
export var Operator;
(function (Operator) {
    Operator["EQUALS"] = "equals";
    Operator["NOT_EQUALS"] = "not_equals";
    Operator["CONTAINS"] = "contains";
    Operator["NOT_CONTAINS"] = "not_contains";
    Operator["GREATER_THAN"] = "greater_than";
    Operator["LESS_THAN"] = "less_than";
    Operator["NULL"] = "null";
    Operator["NOT_NULL"] = "not_null";
    Operator["AND"] = "and";
    Operator["OR"] = "or";
})(Operator || (Operator = {}));
export const createQueryFilter = (values) => { var _a; return (_a = values === null || values === void 0 ? void 0 : values.slice(1, values.length - 1)) !== null && _a !== void 0 ? _a : ''; };
export const formatFilterValue = (value) => value.trim();
const formatNestedProps = (prop) => prop
    .replace(/[<>,=*+\-?^${}()|[\]\\]/g, '')
    .replace(/\./g, '/')
    .replace(/\$/g, '');
const parseFilterRecursive = (filter, keyPath = '') => {
    if (filter === null) {
        return { [Operator.NULL]: { [keyPath]: null } };
    }
    else if (typeof filter === 'string' || typeof filter === 'number' || typeof filter === 'boolean' || Array.isArray(filter)) {
        return { [Operator.EQUALS]: { [keyPath]: filter } };
    }
    else if (filter && typeof filter === 'object') {
        if ('$not' in filter) {
            const notExpr = filter.$not;
            if (notExpr === null) {
                return { [Operator.NOT_NULL]: { [keyPath]: null } };
            }
            else if (typeof notExpr === 'string' || typeof notExpr === 'number' || typeof notExpr === 'boolean' || Array.isArray(notExpr)) {
                return { [Operator.NOT_EQUALS]: { [keyPath]: notExpr } };
            }
            else if (typeof notExpr === 'object' && '$contains' in notExpr) {
                return { [Operator.NOT_CONTAINS]: { [keyPath]: notExpr.$contains } };
            }
            else {
                throw new Error(`$not expression should contain string or { $contains: string }, got '${JSON.stringify(notExpr)}' instead`);
            }
        }
        else if ('$contains' in filter) {
            return { [Operator.CONTAINS]: { [keyPath]: filter.$contains } };
        }
        else if ('$gt' in filter) {
            return { [Operator.GREATER_THAN]: { [keyPath]: filter.$gt } };
        }
        else if ('$lt' in filter) {
            return { [Operator.LESS_THAN]: { [keyPath]: filter.$lt } };
        }
        else if ('$and' in filter) {
            if (!Array.isArray(filter.$and) || !filter.$and.length) {
                return {};
            }
            return {
                [Operator.AND]: filter.$and.map((filterPart) => typeof filterPart === 'object'
                    ? {
                        entries: parseFilterRecursive(filterPart),
                        entriesOrder: Object.keys(filterPart),
                        raw: JSON.stringify(filterPart),
                    }
                    : { global: filterPart, raw: JSON.stringify(filterPart) }),
            };
        }
        else if ('$or' in filter) {
            if (!Array.isArray(filter.$or) || !filter.$or.length) {
                return {};
            }
            return {
                [Operator.OR]: filter.$or.map((filterPart) => typeof filterPart === 'object'
                    ? {
                        entries: parseFilterRecursive(filterPart),
                        entriesOrder: Object.keys(filterPart),
                        raw: JSON.stringify(filterPart),
                    }
                    : { global: filterPart, raw: JSON.stringify(filterPart) }),
            };
        }
        else {
            const result = {};
            for (const [key, value] of Object.entries(filter)) {
                if (key === '$eq') {
                    _.merge(result, parseFilterRecursive(value, keyPath));
                }
                else {
                    const newKeyPath = keyPath + (keyPath.length > 0 ? '.' : '') + key;
                    _.merge(result, parseFilterRecursive(value, newKeyPath));
                }
            }
            return result;
        }
    }
    return {};
};
export const parseFilterMessage = (text) => {
    if (!text) {
        return { raw: JSON.stringify(text) };
    }
    try {
        const textWrappedAsObject = `{${text}}`;
        const parsed = JSON.parse(textWrappedAsObject);
        if (typeof parsed === 'object') {
            const entries = parseFilterRecursive(parsed);
            if (entries && Object.keys(entries).length > 0) {
                return { entries, entriesOrder: Object.keys(parsed), raw: textWrappedAsObject };
            }
            else {
                return { raw: textWrappedAsObject };
            }
        }
        return { global: text, raw: JSON.stringify(text) };
    }
    catch (_a) {
        return { global: text, raw: JSON.stringify(text) };
    }
};
const filterMergingCustomizer = (objValue, srcValue) => {
    if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
    }
};
const formatEntriesRecursive = (filterPart, options) => {
    if (Array.isArray(filterPart)) {
        return filterPart.map((entry) => formatEntriesRecursive(entry, options));
    }
    if (typeof filterPart === 'object' && filterPart) {
        for (const [key, value] of Object.entries(filterPart)) {
            delete filterPart[key];
            const formattedPropertyName = formatNestedProps(key);
            if (!formattedPropertyName) {
                delete filterPart[formattedPropertyName];
            }
            else {
                const formattedEntry = formatEntriesRecursive(value, options);
                if (key === '$eq') {
                    return formattedEntry;
                }
                if (Array.isArray(formattedEntry)) {
                    if (formattedEntry.length === 0) {
                        delete filterPart[key];
                        delete filterPart[formattedPropertyName];
                        continue;
                    }
                    if (formattedEntry.length === 1) {
                        _.mergeWith(filterPart, { [formattedPropertyName]: formattedEntry[0] }, filterMergingCustomizer);
                        continue;
                    }
                    if (formattedEntry.length > 1) {
                        _.mergeWith(filterPart, {
                            or: formattedEntry.map((formattedArrayItem) => (options === null || options === void 0 ? void 0 : options.isLoose) && options.isNot
                                ? { not: { [formattedPropertyName]: formattedArrayItem } }
                                : { [formattedPropertyName]: formattedArrayItem }),
                        }, filterMergingCustomizer);
                        continue;
                    }
                }
                if (typeof value === 'string' && (options === null || options === void 0 ? void 0 : options.isNot) && (options === null || options === void 0 ? void 0 : options.isLoose)) {
                    _.mergeWith(filterPart, { not: { [formattedPropertyName]: formattedEntry } }, filterMergingCustomizer);
                }
                else {
                    _.mergeWith(filterPart, { [formattedPropertyName]: formattedEntry }, filterMergingCustomizer);
                }
            }
        }
        return filterPart;
    }
    if (typeof filterPart === 'string') {
        const nestedValue = escapeIllegalOdataValueChars((options === null || options === void 0 ? void 0 : options.isLoose) ? formatFilterValue(filterPart) : filterPart);
        if (options === null || options === void 0 ? void 0 : options.isGt) {
            return { gt: nestedValue };
        }
        else if (options === null || options === void 0 ? void 0 : options.isLt) {
            return { lt: nestedValue };
        }
        else if (options === null || options === void 0 ? void 0 : options.isLoose) {
            return { contains: nestedValue };
        }
        else if (options === null || options === void 0 ? void 0 : options.isNot) {
            return { ne: nestedValue };
        }
        else {
            return nestedValue;
        }
    }
    if (typeof filterPart === 'boolean' || filterPart === null) {
        return (options === null || options === void 0 ? void 0 : options.isNot) ? { ne: filterPart } : filterPart;
    }
    if (typeof filterPart === 'number') {
        if (options === null || options === void 0 ? void 0 : options.isGt) {
            return { gt: filterPart };
        }
        else if (options === null || options === void 0 ? void 0 : options.isLt) {
            return { lt: filterPart };
        }
        else if (options === null || options === void 0 ? void 0 : options.isNot) {
            return { ne: filterPart };
        }
        else {
            return filterPart;
        }
    }
    return filterPart;
};
export const formatEntries = (entries, options) => {
    const clonedEntries = cloneDeepWithMetadata(entries);
    return formatEntriesRecursive(clonedEntries, options);
};
export function combineMultipleFilters(filters, operator = 'and') {
    switch (filters.length) {
        case 0:
            return {};
        case 1:
            return filters[0];
        default:
            return {
                [operator]: filters,
            };
    }
}
export const getODataFilter = (parsedFilter) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (parsedFilter.entries) {
        if ((_a = parsedFilter.entries[Operator.AND]) === null || _a === void 0 ? void 0 : _a.length) {
            return combineMultipleFilters(parsedFilter.entries[Operator.AND].map((f) => getODataFilter(f)), 'and');
        }
        if ((_b = parsedFilter.entries[Operator.OR]) === null || _b === void 0 ? void 0 : _b.length) {
            return combineMultipleFilters(parsedFilter.entries[Operator.OR].map((f) => getODataFilter(f)), 'or');
        }
        const composedFilter = [
            formatEntries(Object.assign({}, ((_c = parsedFilter.entries[Operator.EQUALS]) !== null && _c !== void 0 ? _c : {}))),
            formatEntries(Object.assign({}, ((_d = parsedFilter.entries[Operator.NOT_EQUALS]) !== null && _d !== void 0 ? _d : {})), {
                isNot: true,
            }),
            formatEntries(Object.assign({}, ((_e = parsedFilter.entries[Operator.CONTAINS]) !== null && _e !== void 0 ? _e : {})), {
                isLoose: true,
            }),
            formatEntries(Object.assign({}, ((_f = parsedFilter.entries[Operator.NOT_CONTAINS]) !== null && _f !== void 0 ? _f : {})), {
                isLoose: true,
                isNot: true,
            }),
            formatEntries(Object.assign({}, ((_g = parsedFilter.entries[Operator.GREATER_THAN]) !== null && _g !== void 0 ? _g : {})), {
                isGt: true,
            }),
            formatEntries(Object.assign({}, ((_h = parsedFilter.entries[Operator.LESS_THAN]) !== null && _h !== void 0 ? _h : {})), {
                isLt: true,
            }),
            formatEntries(Object.assign({}, ((_j = parsedFilter.entries[Operator.NULL]) !== null && _j !== void 0 ? _j : {}))),
            formatEntries(Object.assign({}, ((_k = parsedFilter.entries[Operator.NOT_NULL]) !== null && _k !== void 0 ? _k : {})), {
                isNot: true,
            }),
        ].filter(isDefinedAndNotEmpty);
        return combineMultipleFilters(composedFilter, 'and');
    }
    if (parsedFilter.global) {
        return Object.assign({}, formatEntries({ [DataItemProperties.SEARCH_TEXT_KEY]: parsedFilter.global }, { isLoose: true }));
    }
    return undefined;
};
export const buildODataQuery = (params) => {
    if (!params) {
        return '';
    }
    const { top, skip, filter, orderBy, select } = params, rest = __rest(params, ["top", "skip", "filter", "orderBy", "select"]);
    const restQueryString = new URLSearchParams(rest).toString();
    const oDataString = buildQuery({ top, skip, filter, orderBy, select }).substr(1).replaceAll('not(', 'not (');
    if (!oDataString) {
        return restQueryString;
    }
    if (!restQueryString) {
        return oDataString;
    }
    return `${oDataString}&${restQueryString}`;
};
export const getPathFromQuery = (filter) => {
    return Object.keys(formatEntries(filter)).map((p) => {
        const newPath = p.split('/');
        return newPath.at(-1) === 'identifier' ? newPath.slice(0, -1).join(' - ') : newPath.join(' - ');
    });
};
export const isRelatedError = (errorsMap, error) => {
    var _a;
    const matchedAnyOfInstancePath = Object.keys(errorsMap).find((key) => { var _a; return (_a = error.instancePath) === null || _a === void 0 ? void 0 : _a.startsWith(key); });
    if (!matchedAnyOfInstancePath)
        return false;
    const mapItem = errorsMap[matchedAnyOfInstancePath];
    const errorPathArray = _.toPath(((_a = error.schemaPath) === null || _a === void 0 ? void 0 : _a.replaceAll('/', '.')) || '');
    return mapItem.some((path) => {
        const pathArray = _.toPath(path.replaceAll('/', '.'));
        const errorPath = errorPathArray.slice(0, pathArray.length).join('.');
        const updatedPath = pathArray.join('.');
        return errorPath === updatedPath;
    });
};
export const generateErrorsInstanceToSchemaPathMap = (errors) => {
    return errors
        .filter((e) => { var _a; return (_a = e.instancePath) === null || _a === void 0 ? void 0 : _a.endsWith('_type'); })
        .reduce((acc, e) => {
        var _a;
        if (!e.instancePath || !e.schemaPath)
            return acc;
        const instancePath = e.instancePath.replace('/_type', '');
        const schemaPath = (_a = e.schemaPath) === null || _a === void 0 ? void 0 : _a.replace('/properties/_type/const', '');
        if (acc[instancePath]) {
            acc[instancePath].push(schemaPath);
        }
        else {
            acc[instancePath] = [schemaPath];
        }
        return acc;
    }, {});
};
export function getFilterValuesMap(parsedFilter) {
    var _a, _b;
    let filterToUse = parsedFilter;
    if ((_a = parsedFilter.entries) === null || _a === void 0 ? void 0 : _a[Operator.AND]) {
        filterToUse = parsedFilter.entries[Operator.AND][0];
    }
    else if ((_b = parsedFilter.entries) === null || _b === void 0 ? void 0 : _b[Operator.OR]) {
        filterToUse = parsedFilter.entries[Operator.OR].reduce((acc, filter) => _.merge(acc, filter), {});
    }
    if (!filterToUse.entries) {
        return {};
    }
    const entries = _.merge({}, filterToUse.entries[Operator.CONTAINS], filterToUse.entries[Operator.EQUALS]);
    return Object.entries(entries).reduce((acc, [path, value]) => {
        if (!value) {
            return acc;
        }
        return Object.assign(Object.assign({}, acc), { [path]: Array.isArray(value) ? value.map((v) => [v, v]) : [[value, value]] });
    }, {});
}
export async function buildMongoMatchFilterFromFrontendFilter(parsedFilter) {
    let composedFilters = [];
    if (parsedFilter === null || parsedFilter === void 0 ? void 0 : parsedFilter.raw) {
        const parsedFilters = [parsedFilter].filter(isDefinedAndNotEmpty);
        composedFilters = [...(parsedFilters || []).map((parsedFilter) => getODataFilter(parsedFilter || {}))].filter(isDefinedAndNotEmpty);
    }
    const mongoFilters = [];
    if (composedFilters.length) {
        const odataFilter = composedFilters.length > 1 ? { and: composedFilters } : composedFilters[0];
        const buildedOData = decodeURIComponent(buildODataQuery({ filter: odataFilter }));
        const visitor = createQuery(buildedOData);
        if ((visitor === null || visitor === void 0 ? void 0 : visitor.query) && Object.keys(visitor.query).length) {
            mongoFilters.push(visitor.query);
        }
    }
    return combineMultipleFilters(mongoFilters, '$and');
}
export function getRelationSubjectUri(pathMetaItems) {
    var _a;
    let relationSubjectUri = null;
    let lookupLinkEndpoint = undefined;
    try {
        const isRelation = !!((_a = pathMetaItems[pathMetaItems.length - 1]) === null || _a === void 0 ? void 0 : _a.shortcutOption);
        let relationSchema = isRelation && pathMetaItems.length >= 2 ? pathMetaItems[pathMetaItems.length - 2].propertySchema : null;
        if (relationSchema === null || relationSchema === void 0 ? void 0 : relationSchema.items) {
            relationSchema = relationSchema.items;
        }
        if (!relationSchema || !isRelationSchema(relationSchema) || !relationSchema.links || !relationSchema.links.length) {
            return { relationSubjectUri, lookupLinkEndpoint, relationSchema };
        }
        const lookupLink = relationSchema.links.find((link) => link.rel === 'collection');
        if (!lookupLink || !lookupLink.href) {
            return { relationSubjectUri, lookupLinkEndpoint, relationSchema };
        }
        lookupLinkEndpoint = lookupLink.endpoint;
        const lookupLinkParts = lookupLink.href.split('/');
        if (!lookupLinkParts.some((part) => part === DataServiceModules.DATA_STORE)) {
            return { relationSubjectUri, lookupLinkEndpoint, relationSchema };
        }
        relationSubjectUri = decodeURIComponent(lookupLinkParts.pop());
        return { relationSubjectUri, lookupLinkEndpoint, relationSchema };
    }
    catch (e) {
        return { relationSubjectUri, lookupLinkEndpoint, relationSchema: null };
    }
}
