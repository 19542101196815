const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/License',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FLicense' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'License',
    'properties': {
        'customer': {
            'title': 'Customer',
            'description': '<div>The customer to whom the license is issued.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Customer',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'customer/all', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://schema.org/customer', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Customer' } },
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 28 },
            'cp_ignoreRelation': true,
        },
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date-time-long-year',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 35 },
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'purchaseDate': {
            'title': 'Purchase Date',
            'description': 'The date the item e.g. vehicle was purchased by the current owner.',
            'type': 'string',
            'format': 'date-time-long-year',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://schema.org/purchaseDate', 'rel': 'canonical' }],
        },
        'validThrough': {
            'title': 'Valid Through',
            'description': '<div>The DateTime until which the license is valid.</div>',
            'type': 'string',
            'format': 'date-time-long-year',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://schema.org/validThrough', 'rel': 'canonical' }],
        },
        'crmAccount': {
            'title': 'CRM Account',
            'description': '<div>The CRM Account to whom the license is issued.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CrmAccount?allData=true',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCrmAccount%3FallData%3Dtrue' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/crmAccount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Crm Account' } },
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 29 },
        },
        'deliverable': {
            'title': 'Deliverable',
            'description': '<div>The app granule that is licensed.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Deliverable',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDeliverable' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/deliverable', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentDeliverable.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Deliverable' } },
            'cp_ui': { 'sortOrderForm': 33 },
        },
        'isPartner': {
            'title': 'Is Partner',
            'description': '<div>Specifies whether the license is issued to a partner.</div>',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isPartner', 'rel': 'canonical' }],
        },
        'licenseType': {
            'title': 'License Type',
            'description': '<div>Information specific to Business Central product licenses.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/LicenseTypeBusinessCentral',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FLicenseTypeBusinessCentral' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/licenseType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'companyName': {
                    'title': 'Company Name',
                    'description': '<div>The Business Central company the license is issued for. Empty, if the license is issued for all companies of a tenant.</div>',
                    'type': 'string',
                    'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 70 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/companyName', 'rel': 'canonical' }],
                },
                'aadTenant': {
                    'title': 'Aad Tenant',
                    'description': '<div>Information about the Azure AD Tenant the license is issued for.</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/AadTenant',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAadTenant' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/aadTenant', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'sku': {
                            'title': 'Sku',
                            '$id': 'http://platform.cosmoconsult.com/ontology/Sku',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSku' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/sku', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'consumed': {
                                    'title': 'Consumed',
                                    'description': '<div>The number of consumed SKU.</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 140 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/consumed', 'rel': 'canonical' }],
                                },
                                'enabled': {
                                    'title': 'Enabled',
                                    'description': '<div>The number of enabled SKU.</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 150 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/enabled', 'rel': 'canonical' }],
                                },
                                'rawSkuData': {
                                    'title': 'Raw SKU Data',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/rawSkuData', 'rel': 'canonical' }],
                                },
                            },
                        },
                        'identifier': {
                            'title': 'Identifier',
                            'description': '<div>The Azure AD Tenant ID.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 120 },
                            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': '<div>The Azure AD Tenant Name.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 130 },
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'sortOrderForm': 110 },
                },
                'customerName': {
                    'title': 'Customer Name',
                    'description': '<div>Name of the customer as self-disclosed on registration.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 95 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/customerName', 'rel': 'canonical' }],
                },
                'deleteInInstance': {
                    'title': 'Delete In Instance',
                    'type': 'boolean',
                    'cp_ui': { 'sortOrderForm': 450 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/deleteInInstance', 'rel': 'canonical' }],
                },
                'includedInLicenseFile': {
                    'title': 'Included In License File',
                    'description': '<div>Programmatically set when a license gets included in an offline license file. Enables us to filter for such licenses to e. g. exclude them when cleaning up old/unused license entries.</div>',
                    'type': 'boolean',
                    'cp_ui': { 'sortOrderForm': 1000 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/includedInLicenseFile', 'rel': 'canonical' }],
                },
                'instanceInformation': {
                    'title': 'Instance Information',
                    'description': '<div>Information about the Business Central Instance the license is used in.</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/InstanceInformation',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FInstanceInformation' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/instanceInformation', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'type': {
                            'title': 'Type',
                            'description': '<div>The Business Central environment type.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 205 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/type', 'rel': 'canonical' }],
                        },
                        'deploymentType': {
                            'title': 'Deployment Type',
                            'description': '<div>The Business Central deployment type.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 206 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/deploymentType', 'rel': 'canonical' }],
                        },
                        'identifierHash': {
                            'title': 'Identifier Hash',
                            'description': '<div>The ID Hash of the instance.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 211 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/identifierHash', 'rel': 'canonical' }],
                        },
                        'licenseAppVersion': {
                            'title': 'License App Version',
                            'description': '<div>The COSMO Licensing app version.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 250 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/licenseAppVersion', 'rel': 'canonical' }],
                        },
                        'licenseSerialNumber': {
                            'title': 'License Serial Number',
                            'description': '<div>The Serial No. of the Business Central License (VOICE Customer Account No. / Database Serial No.).</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 212 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/licenseSerialNumber', 'rel': 'canonical' }],
                        },
                        'registrationCompanyName': {
                            'title': 'Registration Company Name',
                            'description': '<div>The name of the Busines Central company the license was registered in.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 230 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/registrationCompanyName', 'rel': 'canonical' }],
                        },
                        'systemVersion': {
                            'title': 'System Version',
                            'description': '<div>The Business Central version (System App).</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 240 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/systemVersion', 'rel': 'canonical' }],
                        },
                        'tenantLicenseState': {
                            'title': 'Tenant License State',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 260 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tenantLicenseState', 'rel': 'canonical' }],
                        },
                        'userInformation': {
                            'title': 'User Information',
                            '$id': 'http://platform.cosmoconsult.com/ontology/UserInformation',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserInformation' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/userInformation', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'assigned': {
                                    'title': 'Assigned',
                                    'description': '<div>The number of Business Central users assigned to this license (only relevant for "Named User Assignment" licenses).</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 300 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/assigned', 'rel': 'canonical' }],
                                },
                                'deviceOnly': {
                                    'title': 'Device Only',
                                    'description': '<div>Number of "Device Only" users in Business Central.</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 290 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/deviceOnly', 'rel': 'canonical' }],
                                },
                                'full': {
                                    'title': 'Full',
                                    'description': '<div>Number of "Full" users in Business Central.</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 270 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/full', 'rel': 'canonical' }],
                                },
                                'limited': {
                                    'title': 'Limited',
                                    'description': '<div>Number of "Limited" users in Business Central.</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 280 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/limited', 'rel': 'canonical' }],
                                },
                                'total': {
                                    'title': 'Total',
                                    'description': '<div>Total number users in Business Central.</div>',
                                    'type': 'number',
                                    'cp_ui': { 'sortOrderForm': 260 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/total', 'rel': 'canonical' }],
                                },
                                'rawUsersInPlansData': {
                                    'title': 'Raw Users in Plans Data',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/rawUsersInPlansData', 'rel': 'canonical' }],
                                },
                            },
                        },
                        'businessCentralApps': {
                            'title': 'Installed Apps',
                            'description': '<div>The Apps installed in the Business Central Environment</div>',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/BusinessCentralApp',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessCentralApp' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Business Central App',
                                'properties': {
                                    'version': {
                                        'title': 'Version',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                    },
                                    'publisher': {
                                        'title': 'Publisher',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/publisher', 'rel': 'canonical' }],
                                    },
                                    'identifier': {
                                        'title': 'Identifier',
                                        'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                    },
                                    'name': {
                                        'title': 'Name (Title or Designation)',
                                        'description': 'The name of the thing or subject.',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/businessCentralApp', 'rel': 'canonical' }],
                        },
                        'identifier': {
                            'title': 'Identifier',
                            'description': '<div>The ID of the instance.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 210 },
                            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Url',
                            'description': '<div>The Business Central service URL.</div>',
                            'type': 'string',
                            'format': 'cp:UrlOrPath',
                            'cp_ui': { 'sortOrderForm': 220 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'sortOrderForm': 200 },
                },
                'lastClientSync': {
                    'title': 'Last Client Sync',
                    'description': '<div>The last time information from the Business Central tenant has been synchronized with the license server.</div>',
                    'type': 'string',
                    'format': 'date-time',
                    'cp_ui': { 'sortOrderForm': 500 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/lastClientSync', 'rel': 'canonical' }],
                },
                'licensedUsers': {
                    'title': 'Licensed Users',
                    'description': '<div>The number of users included in the license.</div>',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 80 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/licensedUsers', 'rel': 'canonical' }],
                },
                'namedUserAssignment': {
                    'title': 'Named User Assignment',
                    'description': '<div>Specifies whether Business Central users must be explicitly assigned to the license.</div>',
                    'type': 'boolean',
                    'cp_ui': { 'sortOrderForm': 90 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/namedUserAssignment', 'rel': 'canonical' }],
                },
                'pushNotification': {
                    'title': 'Push Notification',
                    'description': '<div>Configure a push notification to be shown in Business Central.</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/PushNotification',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FPushNotification' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/pushNotification', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'message': {
                            'title': 'Message',
                            'description': '<div>The message that is shown to the Business Central users.</div>',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 410 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/message', 'rel': 'canonical' }],
                        },
                        'link': {
                            'title': 'Link',
                            'description': '<div>Optional: A link that is included in the notification for the user to click on.</div>',
                            '$id': 'http://platform.cosmoconsult.com/ontology/Link',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FLink' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/link', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'name': {
                                    'title': 'Name (Title or Designation)',
                                    'description': '<div>The caption of the link as displayed in the notification.</div>',
                                    'type': 'string',
                                    'cp_ui': { 'sortOrderForm': 422 },
                                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                                },
                                'url': {
                                    'title': 'Url',
                                    'description': '<div>The URL of the link.</div>',
                                    'type': 'string',
                                    'format': 'uri',
                                    'cp_ui': { 'sortOrderForm': 421 },
                                    'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 420 },
                        },
                        'identifier': {
                            'title': 'Identifier',
                            'description': '<div>The GUID of the Business Central notification. This ID is used to identify the notification in Business Central, to enable users to dismiss individual notifications.</div>',
                            'type': 'string',
                            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 401 },
                            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'sortOrderForm': 400 },
                },
                'registeredBy': {
                    'title': 'Registered By',
                    'description': '<div>The account that registered the license.</div>',
                    'type': 'string',
                    'format': 'email',
                    'transform': ['toLowerCase', 'trim'],
                    'cp_ui': { 'sortOrderForm': 100 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/registeredBy', 'rel': 'canonical' }],
                },
                'registrationType': {
                    'title': 'Registration Type',
                    'description': '<div>Indicates whether the license has been registered as part of an automated test run.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 510 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/registrationType', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 38 },
        },
        'crmAccountName': {
            'title': 'CRM Account Name',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/crmAccountName', 'rel': 'canonical' }],
        },
        'activationCode': {
            'title': 'Activation Code',
            'description': '<div>The code to activate the license with in the customers system.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/ActivationCode',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FActivationCode' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/activationCode', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'used': {
                    'title': 'Used',
                    'description': '<div>Idicates if the activation code has already been used.</div>',
                    'type': 'boolean',
                    'cp_ui': { 'sortOrderForm': 1120 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/used', 'rel': 'canonical' }],
                },
                'identifier': {
                    'title': 'Identifier',
                    'description': '<div>The code used to activate the license.</div>',
                    'type': 'string',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 1110 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 40 },
        },
        'marketplaceSubscription': {
            'title': 'Marketplace Subscription',
            'description': '<div>The marketplace subscription for which the license is issued.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/MarketplaceSubscription',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FMarketplaceSubscription' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceSubscription', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'orderQuantity': {
                    'title': 'Order Quantity',
                    'description': '<div>The quantity of the marketplace subscription.</div>',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 975 },
                    'links': [{ 'href': 'http://schema.org/orderQuantity', 'rel': 'canonical' }],
                },
                'subscriptionId': {
                    'title': 'Subscription Id',
                    'description': '<div>The ID of the subscription from the marketplace platform.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 960 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/subscriptionId', 'rel': 'canonical' }],
                },
                'marketplaceSubscriptionStatus': {
                    'title': 'Marketplace Subscription Status',
                    'description': '<div>The status of the marketplace subscription.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 965 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceSubscriptionStatus', 'rel': 'canonical' }],
                },
                'marketplaceAccountId': {
                    'title': 'Marketplace Account Id',
                    'description': '<div>The ID of the marketplace platform account assigned to the subscription.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 980 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceAccountId', 'rel': 'canonical' }],
                },
                'marketplaceAccountName': {
                    'title': 'Marketplace Account Name',
                    'description': '<div>The name of the marketplace platform account assigned to the subscription.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 985 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceAccountName', 'rel': 'canonical' }],
                },
                'marketplaceAccountEmail': {
                    'title': 'Marketplace Account Email',
                    'description': '<div>The email address of the marketplace platform account assigned to the subscription.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 990 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceAccountEmail', 'rel': 'canonical' }],
                },
                'identifier': {
                    'title': 'Identifier',
                    'description': '<div>The internal COSMO ID of the subscription.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 955 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': '<div>The user friendly name of the subscription from the marketplace platform.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 962 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 39 },
        },
        'hasMissingLicenseUsersBc': {
            'title': 'Has Missing License Users (BC)',
            'type': 'boolean',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/hasMissingLicenseUsersBc', 'rel': 'canonical' }],
        },
        'missingLicenseUsersBc': {
            'title': 'Missing License Users (BC)',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/missingLicenseUsersBc', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': '<div>The ID of the license.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Additional notes.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
    },
    'required': ['deliverable'],
};
export const LicenseSchema = Schema;
