const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/IdeaAiCamp',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIdeaAiCamp' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FComment?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FRating?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FVoting?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Idea Ai Camp',
    'properties': {
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'sortOrderForm': 45 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'tags': {
            'title': 'Tags',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'cp:tags' },
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tags', 'rel': 'canonical' }],
        },
        'referringTo': {
            'title': 'Referring To',
            '$id': 'http://platform.cosmoconsult.com/ontology/Solution',
            'type': 'object',
            'links': [
                {
                    'rel': 'collection',
                    'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution',
                    'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
                },
                { 'href': 'http://platform.cosmoconsult.com/ontology/referringTo', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentSolution.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Solution' } },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'motivation': {
            'title': 'Motivation',
            'description': '<div><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">How can you quantify the benefit of your </span>idea<span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">? </span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">What is the value of this improvement?</span></div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/motivation', 'rel': 'canonical' }],
        },
        'challenges': {
            'title': 'Challenges',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Challenge',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FChallenge' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Challenge',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Challenge' } },
            },
            'cp_ui': { 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/challenge', 'rel': 'canonical' }],
        },
        'targetGroups': {
            'title': 'Target Groups',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/TargetGroup',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTargetGroup' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Target Group',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Target Group' } },
            },
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/targetGroup', 'rel': 'canonical' }],
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': "<div>Short description or summary that serves to convey the most important information on the subject or subject matter in a concise form (1-3 sentences). Often used for list, card or tiles. The idea is to capture the reader's or viewer's attention without going into detail. A short description can be compared with a teaser text.</div>\n<div> </div>",
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 37 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'] },
            'minItems': 1,
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 46 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 54 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 58 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 59 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Describe your idea in a simple &amp; clear language. Provide examples where applicable. If you would like to share images, insert here. Files can be uploaded below.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 38 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': '<div>Image for the card/tile of your idea in the overview of all ideas.</div>',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 36 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['shortDescription', 'owners', 'name', 'referringTo'],
};
export const IdeaAiCampSchema = Schema;
