import React, { ElementType } from 'react';

import RebrushedDashboard from './RebrushedDashboard';

function withoutTitle(WrappedComponent: ElementType): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} hideTitle={true} />;
  };
}

export default withoutTitle(RebrushedDashboard);
