import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint } from '@fluentui/react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTourBubble } from '@cpa/base-core/store/app/actions';
import { history } from '@cpa/base-core/app/history';
import { IGlobalState } from '@cpa/base-core/store';
import * as _ from 'lodash';
import { useSetInterval } from '@fluentui/react-hooks';
import { useIsCached } from '@cpa/base-core/hooks';

import TourCallout from '../TourCallout/TourCallout';
import { PageContext, TourContext } from '../../Layout';
import { ScrollablePaneContext } from '../../../ScrollablePaneContextProvider/ScrollablePaneContextProvider';

import steps, { ITourStep } from './tourSteps';

interface ITourProps {}

const Tour: React.FC<ITourProps> = () => {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState<[ITourStep, Element] | null>(null);
  const location = useSelector((state: IGlobalState) => state.router.location.pathname);
  const loadingCounter = useSelector((state: IGlobalState) => state.app.loadingCounter);
  const isCached = useIsCached();
  const dispatch = useDispatch();

  const { setInterval, clearInterval } = useSetInterval();

  const pageContext = useContext(PageContext);
  const scrollablePaneContext = useContext(ScrollablePaneContext);
  const tourContext = useContext(TourContext);

  useEffect(() => {
    if (isCached) {
      setCurrentStep(null);
    }
  }, [isCached]);

  useEffect(() => {
    const bubble = currentStep
      ? {
          step: currentStep[0].name,
          isWidget: currentStep[0].isWidget,
        }
      : null;
    dispatch(setCurrentTourBubble({ bubble }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep?.[0].name]);

  const getCurrentStep = useCallback(
    _.debounce(() => {
      let selectedStep: [ITourStep, Element] | null = null;
      for (const step of steps as ITourStep[]) {
        if (selectedStep) continue;
        if (!pageContext?.isSignInHintCancelled || localStorage.getItem(`hide${step.name}Bubble`) !== 'false') continue;
        const targets = Array.isArray(step.target) ? step.target : [step.target];
        for (const target of targets) {
          const elements = Array.from(document.querySelectorAll(target));
          const matchedElement = elements.find((element) => {
            if (!element) return false;
            const rect = element.getBoundingClientRect();
            if (rect.height === 0 && rect.width === 0) return false;
            return (
              rect.top - (step.offset || 0) >= 0 &&
              rect.left >= 0 &&
              rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
          });
          if (matchedElement) {
            selectedStep = [step, matchedElement];
            break;
          }
        }
      }
      setCurrentStep(selectedStep);
    }, 100),
    [pageContext?.isSignInHintCancelled]
  );

  useEffect(() => {
    getCurrentStep();
  }, [getCurrentStep, location, loadingCounter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getCurrentStep();
    }, 2000);
    window.addEventListener('resize', getCurrentStep);
    scrollablePaneContext?.scrollEventEmitter.current?.on('onFullBodyScroll', () => {
      getCurrentStep();
    });
    tourContext?.tourEventEmitter.current?.on('forceBubbleCheck', () => {
      getCurrentStep();
    });
    const unlisten = history.listen((location) => {
      for (const step of steps) {
        if (step.pathname && step.pathname === location.pathname && step.dismissOnPathHit) {
          localStorage.setItem(`hide${step?.name}Bubble`, 'true');
        }
      }
      getCurrentStep();
    });

    return () => {
      window.removeEventListener('resize', getCurrentStep);
      clearInterval(intervalId);
      unlisten();
    };
  }, [clearInterval, getCurrentStep, scrollablePaneContext?.scrollEventEmitter, setInterval, tourContext?.tourEventEmitter]);

  const currentBubble = useMemo(() => {
    if (!currentStep) return null;
    const [step, targetElement] = currentStep;
    const closeFunc = (): void => {
      localStorage.setItem(`hide${step?.name}Bubble`, 'true');
      getCurrentStep();
    };
    const bubble = {
      visible: true,
      title: t(step.title),
      text: t(step.text),
      actionText: step.actionText ? t(step.actionText) : undefined,
      primaryBtn: step.primaryBtn
        ? {
            label: t(step.primaryBtn.label),
            onClick: () => {
              step.primaryBtn?.onClick();
              closeFunc();
            },
          }
        : undefined,
      cancelBtn: {
        label: t('feedback.bubble.cancelBtn'),
        onClick: closeFunc,
      },
    };
    return <TourCallout bubble={bubble} target={targetElement} directionalHint={DirectionalHint.bottomRightEdge} doNotLayer={step.doNotLayer} />;
  }, [currentStep, getCurrentStep, t]);

  if (isCached || !currentStep || !currentBubble) return null;

  return <div>{currentBubble}</div>;
};

export default Tour;
