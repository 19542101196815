import { EnvironmentType, Schemas } from '@cp/base-types';
import ReactGA from 'react-ga';
import { Action } from 'redux-actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';

import { IGlobalState } from '..';
import { Environment } from '../../app/environment';
import { bootstrapDarkThemeHref, bootstrapLightThemeHref, StorageKey } from '../../constants';
import { disableGA, initializeGA, isDefined, loadColorTheme } from '../../helpers';
import { MatchedCpa } from '../../types';
import { getUserSettings, IUserProfileResponse } from '../../api';
import { setUserSettings, setUserSettingsEtag } from '../auth/actions';

import { applySettings, setCookieAgreement, settingsChanged } from './actions';

function* setGASaga({ payload }: Action<Record<string, boolean>>): Generator {
  const user = (yield select((store: IGlobalState) => store.auth.user)) as IGlobalState['auth']['user'];

  const updatedFields: Partial<Record<string, unknown>> = { ...payload, [StorageKey.HideCookie]: true };
  Object.entries(updatedFields).forEach(([key, value]) => {
    window?.localStorage.setItem(key, JSON.stringify(value));
  });

  const matchedCpa = (yield select((store: IGlobalState) => store.app.cpa)) as MatchedCpa;
  if (!matchedCpa.configuration.googleAnalyticsTrackingId) {
    return;
  }

  if (updatedFields[StorageKey.AcceptGA] || updatedFields[StorageKey.AcceptGAWithUserId]) {
    // Init GA
    initializeGA(
      matchedCpa.configuration.googleAnalyticsTrackingId,
      Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Local || Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Dev
    );

    if (updatedFields[StorageKey.AcceptGAWithUserId]) {
      // Important: It's forbidden by Google's terms of service to overhand PPI (personally identifiable information)
      // https://developers.google.com/analytics/solutions/crm-integration#user_id

      // GA User Id
      ReactGA.set({ userId: user?.account?.accountIdentifier });
      // GA Dimension 1
      if (user?.account?.email) {
        const emailRegEx = /^[a-zA-Z0-9.!#$%&'^_`{}~-]+@([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+))*$/;
        const matchArr = user.account.email.match(emailRegEx);
        if (matchArr && matchArr.length === 2) {
          ReactGA.set({ dimension1: matchArr[1] }); // Use only company's domain or (n-ld).sld.tld
        }
      }
      // GA Dimension 2
      ReactGA.set({
        dimension2: user?.companies
          .map((ca) => {
            return ca.accounts
              .filter((a) => a?.type !== 'Contact' && a?.type.length > 0 && a?.no && a?.no.length > 0)
              .map((a) => {
                return `${ca?.company?.replace(/\s|:/gi, '_')}:${a?.type?.replace(/\s|:/gi, '_')}:${a?.no?.replace(/\s|:/gi, '_')}`;
              });
          })
          .flat(1)
          .join(',')
          .substr(0, 150),
      });
      // GA Dimension 3 (reseved; see below)
    } else {
      ReactGA.set({ userId: undefined });
      ReactGA.set({ dimension1: undefined });
      ReactGA.set({ dimension2: undefined });
    }
    // GA Dimension 3
    ReactGA.set({ dimension3: localStorage?.getItem('i18nextLng') || undefined });
  } else {
    disableGA(matchedCpa.configuration.googleAnalyticsTrackingId);
  }
}

const bootstrapThemeLink = document.createElement('link');
bootstrapThemeLink.id = 'bootstrap-styles';
bootstrapThemeLink.type = 'text/css';
bootstrapThemeLink.rel = 'stylesheet';
document.head.insertBefore(bootstrapThemeLink, document.head.firstChild);

let currentMode: boolean | null = null;

function setBootstrapThemeLink(darkMode: boolean): void {
  document.documentElement.style.setProperty('--theme', darkMode ? 'dark' : 'light');

  if (currentMode === darkMode) {
    return;
  }
  currentMode = darkMode;

  if (darkMode) {
    bootstrapThemeLink.href = bootstrapDarkThemeHref;
  } else {
    bootstrapThemeLink.href = bootstrapLightThemeHref;
  }

  console.debug(`Switched bootstrap theme to ${bootstrapThemeLink.href}`);
}

function setBootstrapTheme({ payload }: Action<{ settings: Record<string, boolean> }>): void {
  if (!('darkMode' in payload.settings)) {
    return;
  }

  const darkMode: boolean = payload.settings.darkMode;
  setBootstrapThemeLink(darkMode);
}

export function* syncSettings() {
  const user = (yield select((store: IGlobalState) => store.auth.user)) as IGlobalState['auth']['user'];
  const { userSettings, eTag } = (yield call(getUserSettings, user?.account.email)) as IUserProfileResponse;
  const prevUserSettings = (yield select((store: IGlobalState) => store.auth.userSettings)) as IGlobalState['auth']['user'];
  const darkModeFromSettings = (yield select((store: IGlobalState) => store.settings.darkMode)) as IGlobalState['settings']['darkMode'];
  yield put(setUserSettings(userSettings || null));
  yield put(setUserSettingsEtag({ eTag }));
  const cpa = (yield select((store: IGlobalState) => store.app.cpa)) as IGlobalState['app']['cpa'];
  if (userSettings && cpa) {
    const matchingCpaSettings = userSettings?.cpaUserConfigurations?.find((cpaConfig) => cpaConfig?.cpa?.identifier === cpa.identifier);
    if (matchingCpaSettings) {
      const omitPaths = ['cpa'];
      if (prevUserSettings) {
        omitPaths.push('dataLanguage');
      }
      if (cpa?.configuration?.disableDarkMode) {
        omitPaths.push('darkMode');
      }
      const cpaSettings = _.omit(matchingCpaSettings, omitPaths);
      yield put(applySettings({ settings: { ...cpaSettings, settingsForThisAppOnly: true } }));
      loadColorTheme(
        cpa.colorPalette,
        cpa?.configuration?.disableDarkMode ? false : (cpaSettings.darkMode as boolean | undefined) || false,
        (cpaSettings.highContrast as boolean | undefined) || false
      );
    } else {
      const omitPaths = [];
      if (prevUserSettings) {
        omitPaths.push('dataLanguage');
      }
      if (cpa?.configuration?.disableDarkMode) {
        omitPaths.push('darkMode');
      }
      yield put(applySettings({ settings: _.omit(userSettings, omitPaths) }));
      const isDarkMode = isDefined(userSettings.darkMode) ? userSettings.darkMode : darkModeFromSettings;
      loadColorTheme(cpa.colorPalette, cpa?.configuration?.disableDarkMode ? false : isDarkMode, userSettings.highContrast || false);
    }
  }
}

export default function* (): Generator {
  yield takeEvery(setCookieAgreement, setGASaga);
  yield takeEvery(applySettings, setBootstrapTheme);
  yield takeEvery(settingsChanged, syncSettings);

  // Bootstrap theme initialization
  const darkMode = (yield select((store: IGlobalState) => store.settings.darkMode)) as IGlobalState['settings']['darkMode'];
  setBootstrapThemeLink(darkMode);
}
