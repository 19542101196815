// Should be the first lines of the app
import 'react-app-polyfill/stable';
import { runApp } from '@cpa/generic-components';
import '@cpa/generic-components/dist/lib/styles/global.scss';

/*
    /\_____/\
   /  o   o  \
  ( ==  ^  == )
   )         (
  (           )
 ( (  )   (  ) )
(__(__)___(__)__)
 */

// Components mapping sample:
/*
  runApp('root', {
    mapping: {
      singleItemTemplates: {
        SolutionSingleItemTemplate: SolutionSingleItemTemplate
      }
    }
  });
 */

// User agreements modification sample:
/*
  runApp('root', {
    overwriteAgreements: (defaultAgreements) => [
      ...defaultAgreements,
      { fieldName: 'customKey', title: 'Custom Key' },
      { fieldName: 'customROKey', title: 'Custom Read Only Key', isChecked: true, disabled: true }
    ]
  });
 */

runApp('root');
