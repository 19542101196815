import { Filter, Document } from 'mongodb';

export function prepareFilterForSuggestions(filterObj: Filter<Document>, dataPath: string): Filter<Document> {
  if (filterObj instanceof RegExp) {
    return filterObj;
  }

  if (Array.isArray(filterObj)) {
    for (let i = 0; i < filterObj.length; i++) {
      filterObj[i] = prepareFilterForSuggestions(filterObj[i], dataPath);
    }

    // Remove empty objects from the filter
    return filterObj.filter((s) => !isEmptySubFilter(s));
  } else if (typeof filterObj === 'object') {
    for (const key of Object.keys(filterObj)) {
      if (key === dataPath) {
        // Remove current field values from the filter
        delete filterObj[key];
      } else if (key === '$eq' && filterObj['$eq'] === '') {
        // Remove "$eq: ''" values from the filter
        delete filterObj[key];
      } else {
        filterObj[key] = prepareFilterForSuggestions(filterObj[key], dataPath);
      }
    }

    for (const key of Object.keys(filterObj)) {
      if (isEmptySubFilter(filterObj[key])) {
        // Remove empty objects from the filter
        delete filterObj[key];
      }
    }

    return filterObj;
  } else {
    return filterObj;
  }
}

function isEmptySubFilter(filterObj: Filter<Document>): boolean {
  if (filterObj instanceof RegExp) {
    return false;
  } else if (Array.isArray(filterObj)) {
    return !filterObj.length;
  } else if (typeof filterObj === 'object') {
    return !Object.keys(filterObj).length;
  } else {
    return false;
  }
}
