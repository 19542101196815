import React, { useCallback, useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { createCancelToken } from '@cpa/base-http';
import { generateFileUploadUrl, uploadFileToAzureStorage } from '@cpa/base-core/api';
import { DefaultButton, Icon, PrimaryButton } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { useTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';

import { ImageEditorOperations } from '../../../helpers/ImageEditorOperations';
import { ImageEditorMenuType } from '../ImageEditorMenu';

import styles from './ImageEditorStartMenu.module.scss';

interface ImageEditorStartMenuProps {
  filename: string;
  stage: Konva.Stage;

  onSubmit: (url: string) => void;
  onClose: () => void;

  setActiveMenu: (menu: ImageEditorMenuType) => void;
}

const ImageEditorStartMenu: React.FC<ImageEditorStartMenuProps> = ({ stage, filename, onSubmit, onClose, setActiveMenu }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [t] = useTranslation();

  const startCropping = useCallback(() => {
    setActiveMenu(ImageEditorMenuType.crop);
  }, [setActiveMenu]);

  const showBrightnessOptions = useCallback(() => {
    setActiveMenu(ImageEditorMenuType.brightnessContrast);
  }, [setActiveMenu]);

  const [isSaving, setIsSaving] = useState(false);

  const uploadingCancelToken = useRef<CancelTokenSource | null>(null);

  const saveChanges = useCallback(async () => {
    setIsSaving(true);

    const imageContents = ImageEditorOperations.save(stage);

    const imageResponse = await axios.get(imageContents, { responseType: 'blob' });
    const blob = await imageResponse.data;

    // Unique id per upload operation
    uploadingCancelToken.current = createCancelToken();

    // Get upload url
    const fileUploadDetails = await generateFileUploadUrl(filename, uploadingCancelToken.current.token);
    await uploadFileToAzureStorage(fileUploadDetails.uploadUrl, blob!, () => {}, uploadingCancelToken.current.token);

    uploadingCancelToken.current = null;

    onSubmit(fileUploadDetails.downloadUrl);
  }, [stage, onSubmit, setIsSaving]);

  const cancelEdit = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    return () => {
      if (uploadingCancelToken.current) {
        uploadingCancelToken.current.cancel();
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.panelButton, { [styles.panelButtonDark]: darkMode })} onClick={startCropping}>
        <div className={styles.panelButtonIcon}>
          <Icon iconName={'Crop'} />
        </div>
        {t('imageEditor.crop')}
      </div>

      <div className={classNames(styles.panelButton, { [styles.panelButtonDark]: darkMode })} onClick={showBrightnessOptions}>
        <div className={styles.panelButtonIcon}>
          <Icon iconName={'Brightness'} />
        </div>
        {t('imageEditor.brightnessContrast')}
      </div>

      <div className={styles.ctaButtons}>
        <PrimaryButton text={isSaving ? t('imageEditor.saving') : t('imageEditor.saveChanges')} onClick={saveChanges} />
        <DefaultButton text={t('imageEditor.discardChanges')} onClick={cancelEdit} />
      </div>
    </div>
  );
};

export default ImageEditorStartMenu;
