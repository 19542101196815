import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Schemas, TypeConstants } from '@cp/base-types';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { IconButton, Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { DataServiceModules } from '@cp/base-utils';

import ContactPersonCard from '../../../../../../card/ContactPersonCard/ContactPersonCard';

import styles from './ExpertCard.module.scss';

interface IExpertCardProps {
  email: string;
  role?: string;
  loaderClassName?: string;
  customErrorMessage?: string;
  contactPersonCardProps?: {
    className?: string;
    personCardProps?: {
      isInHoverCard?: boolean;
    };
  };
  topicAdditionalUsers?: string[];
  topicName?: string;
  topicMessage?: string | ((personName: string) => Promise<string | null>);
}

const ExpertCard: React.FC<IExpertCardProps> = ({
  email,
  role,
  loaderClassName,
  customErrorMessage,
  contactPersonCardProps,
  topicAdditionalUsers,
  topicMessage,
  topicName,
}) => {
  const [expertDetails, setExpertDetails] = useState<Schemas.User | null>(null);
  const [expertsError, setExpertsError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const cancelToken = useRef<CancelTokenSource | null>(null);

  const getDetails = useCallback(async (): Promise<void> => {
    const dataServiceEndpoint = getEndpoint(axiosDictionary.appDataService);
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();
    try {
      setLoading(true);

      const response = await dataServiceEndpoint.axios.get(
        `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CompanyUser)}%3FallData=true/${encodeURIComponent(email)}`,
        {
          cancelToken: cancelToken.current.token,
        }
      );
      setExpertDetails(response.value);
      setExpertsError(null);
    } catch (e) {
      setExpertDetails(null);
      setExpertsError(e);
    } finally {
      setLoading(false);
    }
  }, [email]);

  useEffect(() => {
    getDetails();
    return () => {
      cancelToken.current?.cancel();
    };
  }, [cancelToken, getDetails]);

  const handleExpertReload = useCallback(() => {
    getDetails();
  }, [getDetails]);

  const loader = useMemo(() => {
    return (
      <div className={classNames(styles.loaderWrapper, loaderClassName)}>
        <Shimmer
          width={300}
          customElementsGroup={
            <div className={styles.loader}>
              <ShimmerElementsGroup
                shimmerElements={[
                  { type: ShimmerElementType.circle, height: 100 },
                  { type: ShimmerElementType.gap, width: 10, height: 80 },
                ]}
              />
              <ShimmerElementsGroup
                flexWrap
                shimmerElements={[
                  { type: ShimmerElementType.line, width: 200, height: 20 },
                  { type: ShimmerElementType.gap, width: 200, height: 5 },
                  { type: ShimmerElementType.line, width: 130, height: 20 },
                  { type: ShimmerElementType.gap, width: 200, height: 5 },
                  { type: ShimmerElementType.line, width: 150, height: 20 },
                ]}
              />
            </div>
          }
        />
      </div>
    );
  }, []);

  if (loading) {
    return loader;
  }

  if (expertsError) {
    return (
      <div className={styles.errorWrapper}>
        <p>{customErrorMessage || t('errors.connection.expertError')}</p>
        <IconButton iconProps={{ iconName: 'Refresh' }} onClick={handleExpertReload} />
      </div>
    );
  }

  if (!expertDetails) {
    // Fallback to email if there is no expert details
    return (
      <ContactPersonCard
        item={{
          image: '',
          name: email,
          jobTitle: role,
          phone: '',
          email: email,
        }}
        data={{
          page: {
            dataEndpoint: { identifier: axiosDictionary.appDataService },
            identifier: '__',
            name: '__',
            cpas: [{ identifier: '__' }],
          },
          schema: {},
          isFetching: false,
          totalItems: 0,
        }}
        topicName={topicName}
        topicMessage={topicMessage}
        topicAdditionalUsers={topicAdditionalUsers}
        {...contactPersonCardProps}
      />
    );
  }

  return (
    <ContactPersonCard
      item={{
        image: expertDetails.image,
        name: expertDetails.name,
        jobTitle: role || expertDetails.jobTitle,
        phone: expertDetails.telephone,
        email: expertDetails.email,
      }}
      data={{
        page: {
          dataEndpoint: { identifier: axiosDictionary.appDataService },
          identifier: '__',
          name: '__',
          cpas: [{ identifier: '__' }],
        },
        schema: {},
        isFetching: false,
        totalItems: 0,
      }}
      topicName={topicName}
      topicMessage={topicMessage}
      topicAdditionalUsers={topicAdditionalUsers}
      {...contactPersonCardProps}
    />
  );
};

export default ExpertCard;
