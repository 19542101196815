import { IJSONSchema } from '@cp/base-types';
import { isFormDataEqual } from '@cpa/base-core/helpers';
import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import Form, { IFormChangeEvent } from '../../components/Form/Form';
import { getParsedFilter } from '../GenericScreen/utils';

import { CustomAggregationTemplate } from './Statement';
import styles from './Statement.module.scss';
import { IStatementItemProps } from './StatementItem';
import Rating from './components/Rating/Rating';
import Voting from './components/Voting/Voting';

export const useStatementItem = ({
  targetItem,
  schema,
  onAdd,
  onEdit,
  onFormSubmit,
  submitOnFormChange = true,
  aggregatedData,
  isAggregationFetching,
  labels,
  filter,
  page,
}: IStatementItemProps): [JSX.Element | null, JSX.Element | null] => {
  const data = useMemo(() => {
    return _.merge({}, targetItem, getParsedFilter(filter)[0]);
  }, [targetItem, filter]);

  const formattedData = useMemo(() => {
    return (
      <div className={styles.widgetData}>
        {isAggregationFetching && ''}
        {page.customTemplate?.identifier?.toUpperCase() === CustomAggregationTemplate.RATING && <Rating data={aggregatedData} labels={labels} />}
        {page.customTemplate?.identifier?.toUpperCase() === CustomAggregationTemplate.VOTING && <Voting data={aggregatedData} labels={labels} />}
      </div>
    );
  }, [aggregatedData, isAggregationFetching, labels, page.customTemplate?.identifier]);

  const onChangeHandler = useCallback(
    ({ formData, errors }: IFormChangeEvent) => {
      if ((errors && errors.length) || !formData || !Object.keys(formData).length || !submitOnFormChange || isFormDataEqual(formData, data)) {
        return;
      }

      if (targetItem) {
        onEdit?.(formData, targetItem);
        return;
      }
      onAdd?.(formData);
    },
    [submitOnFormChange, data, targetItem, onAdd, onEdit]
  );

  const form = useMemo(() => {
    return schema ? (
      <Form
        page={page}
        schema={schema as IJSONSchema}
        hideSubmit={submitOnFormChange}
        onChange={onChangeHandler}
        formData={data}
        onSubmit={!submitOnFormChange ? onFormSubmit : undefined}
      />
    ) : null;
  }, [schema, page, submitOnFormChange, onChangeHandler, data, onFormSubmit]);

  return [form, formattedData];
};
