import React, { useCallback } from 'react';
import { Icon } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { push } from 'connected-react-router';
import { useAboutValue } from '@cpa/base-core/hooks';
import classNames from 'classnames';

import styles from './MessageCount.module.scss';

const MessageCount: React.FC = () => {
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);
  const dispatch = useDispatch();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const [count] = useAboutValue(
    'cp:CpMessage',
    null,
    'message-count',
    'count',
    'http://platform.cosmoconsult.com/ontology/CpMessage',
    { user: user },
    false,
    'cp:CpMessageState',
    true
  );

  const handleClick = useCallback(() => {
    dispatch(push('/general/cp/messages/all'));
  }, [dispatch]);

  return (
    <div className={classNames(styles.wrapper, { [styles.light]: !darkMode })}>
      <div className={styles.linkWrapper} onClick={handleClick}>
        <Icon iconName={'message'} className={styles.globe} />
      </div>
      {count ? <span className={styles.count}>{count}</span> : null}
    </div>
  );
};

export default MessageCount;
