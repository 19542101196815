import React from 'react';
import urlJoin from 'url-join';
import { getAppBasePath } from '@cpa/base-core/helpers';

import HoverTooltip from '../../../../../components/HoverTooltip/HoverTooltip';

import styles from './Tags.module.scss';

interface ITagsProps {
  tags?: string[];
  path?: string;
}

const Tags: React.FC<ITagsProps> = ({ tags, path }) => {
  return (
    <>
      {tags?.length && (
        <div>
          <div className={styles.tagsWrapper}>
            {tags?.map((tag, index) => {
              const uri: string = urlJoin(
                `${window.location.origin}${getAppBasePath()}`,
                path?.replace('/:id', '') || '/',
                `?filter={"publicTags": {"$contains":"${tag}"}'`
              );
              return (
                <HoverTooltip hostClassName={styles.tagTooltip} key={index} content={tag.toUpperCase()}>
                  <a href={uri} target={'_blank'} className={styles.tag} rel="noreferrer">
                    {tag}
                  </a>
                </HoverTooltip>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Tags;
