import { getSafeString } from '@cpa/base-core/helpers';
import { ICardProps } from '@cpa/base-core/types';
import React from 'react';

import SelectionButton from '../../../components/SelectionButton/SelectionButton';

import styles from './ImageItemCard.module.scss';

const ImageItemCard: React.FC<ICardProps> = ({ item, onClick, onItemSelect, isSelected, hideSelectionMarker }) => (
  <div
    key={String(item?.identifier)}
    className={styles.item}
    onClick={onClick}
    style={
      item.image && typeof item.image === 'string'
        ? {
            backgroundImage: `url(${CSS.escape(item.image)})`,
            backgroundRepeat: 'no-repeat',
            cursor: onClick ? 'pointer' : 'default',
          }
        : undefined
    }
  >
    {!hideSelectionMarker && <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} />}
    <div className={styles.inner}>
      <div className={styles.title}>{item.name}</div>
      {item.shortDescription ? (
        <div className={styles.text}>{getSafeString(item.shortDescription as string, true)}</div>
      ) : item.description ? (
        <div className={styles.text}>{getSafeString(item.description as string, true)}</div>
      ) : undefined}
    </div>
    {typeof item.stampText === 'string' && item.stampText !== '' && <div className={styles.stamp}>{getSafeString(item.stampText)}</div>}
  </div>
);

export default ImageItemCard;
