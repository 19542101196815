import React from 'react';
import ReactDOM from 'react-dom';

const constructableStylesheetsSupported =
  window &&
  window.ShadowRoot &&
  window.ShadowRoot.prototype.hasOwnProperty('adoptedStyleSheets') &&
  window.CSSStyleSheet &&
  window.CSSStyleSheet.prototype.hasOwnProperty('replace');

const shadowRootSupported = window && window.Element && window.Element.prototype.hasOwnProperty('attachShadow');

export interface IShadowRootProps {
  delegatesFocus: boolean;
  mode: 'open' | 'closed';
  onInitialized?: () => void;
  children?: React.ReactNode;
}

interface IShadowRootState {
  initialized: boolean;
}

export default class extends React.PureComponent<IShadowRootProps, IShadowRootState> {
  public placeholder: React.RefObject<HTMLElement>;
  public shadowRoot: ShadowRoot | null;
  static constructableStylesheetsSupported = constructableStylesheetsSupported;
  static constructibleStylesheetsSupported = constructableStylesheetsSupported;
  static defaultProps = {
    delegatesFocus: false,
    mode: 'open',
  };
  static displayName = 'ShadowRoot';
  static shadowRootSupported = shadowRootSupported;

  constructor(props: IShadowRootProps) {
    super(props);
    this.placeholder = React.createRef();
    this.state = { initialized: false };
    this.shadowRoot = null;
  }

  public componentDidMount(): void {
    const { delegatesFocus, mode } = this.props;

    this.shadowRoot = (this.placeholder.current?.parentNode as HTMLElement).attachShadow({
      delegatesFocus,
      mode,
    });

    this.setState(
      {
        initialized: true,
      },
      () => {
        this.props.onInitialized?.();
      }
    );
  }

  render(): JSX.Element {
    if (!this.state.initialized) {
      return <span ref={this.placeholder} />;
    }

    return ReactDOM.createPortal(this.props.children, this.shadowRoot as unknown as Element);
  }
}
