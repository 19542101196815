import { getSafeString } from '@cpa/base-core/helpers';
import { ICardProps } from '@cpa/base-core/types';
import React, { useMemo } from 'react';

import SelectionButton from '../../../components/SelectionButton/SelectionButton';
import { IWorkloadInfo } from '../../../components/ScrollingContent/components/GenericCard/GenericCard';

import styles from './SolutionImageItemCard.module.scss';

const SolutionImageItemCard: React.FC<ICardProps> = ({ item, onClick, onItemSelect, isSelected, hideSelectionMarker }) => {
  const color = useMemo(() => {
    const workloadInfo = item?.__workloadInfo as unknown as IWorkloadInfo;
    if (workloadInfo.colors?.length) {
      return workloadInfo.colors[0];
    } else return 'color-cosmo-gray-7';
  }, [item]);
  const icons = useMemo(() => {
    const workloadInfo = item?.__workloadInfo as unknown as IWorkloadInfo;
    if (workloadInfo.icons) {
      return workloadInfo.icons;
    } else return [];
  }, [item]);
  return (
    <div
      key={String(item?.identifier)}
      className={styles.item}
      onClick={onClick}
      style={
        item.image && typeof item.image === 'string'
          ? {
              backgroundImage: `url(${CSS.escape(item.image)})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionY: 'top',
              backgroundSize: '70%',
              backgroundColor: color,
              cursor: onClick ? 'pointer' : 'default',
            }
          : undefined
      }
    >
      <div className={styles.platformIcons}>
        {icons.map((icon, identifier) => {
          return <img key={identifier} src={icon.image} />;
        })}
      </div>
      {!hideSelectionMarker && <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} />}
      <div className={styles.inner}>
        <div className={styles.title}>{item.name}</div>
        {item.shortDescription ? (
          <div className={styles.text}>{getSafeString(item.shortDescription as string, true)}</div>
        ) : item.description ? (
          <div className={styles.text}>{getSafeString(item.description as string, true)}</div>
        ) : undefined}
      </div>
      {typeof item.stampText === 'string' && item.stampText !== '' && <div className={styles.stamp}>{getSafeString(item.stampText)}</div>}
    </div>
  );
};

export default SolutionImageItemCard;
