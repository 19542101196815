import { createActions } from 'redux-actions';

export const {
  openSettings,
  closeSettings,
  applySettings,
  setPageSettings,
  setExpertMode,
  openPageDrawer,
  closePageDrawer,
  changePageSetting,
  getStateFromStorage,
  setCookieAgreement,
  showCookieAgreement,
  settingsChanged,
} = createActions(
  'OPEN_SETTINGS',
  'CLOSE_SETTINGS',
  'APPLY_SETTINGS',
  'SET_PAGE_SETTINGS',
  'SET_EXPERT_MODE',
  'OPEN_PAGE_DRAWER',
  'CLOSE_PAGE_DRAWER',
  'CHANGE_PAGE_SETTING',
  'GET_STATE_FROM_STORAGE',
  'SET_COOKIE_AGREEMENT',
  'SHOW_COOKIE_AGREEMENT',
  'SETTINGS_CHANGED'
);
