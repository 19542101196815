import { IGlobalState } from '@cpa/base-core/store';
import { IScreenProps } from '@cpa/base-core/types';
import { signIn } from '@cpa/base-core/helpers';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import * as _ from 'lodash';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, StaticContext } from 'react-router';
import urlJoin from 'url-join';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { verifyLanguage, CultureInfo } from '@cp/base-utils';
import { Schemas } from '@cp/base-types';

import Layout from '../../../../components/Layout/Layout';
import GenericScreen from '../../../../screens/GenericScreen/GenericScreen';
import CachedContent from '../../../Layout/components/CachedContent/CachedContent';
import Navigation from '../../../Navigation/Navigation';

type Screen = React.ComponentClass<IScreenProps> | React.FunctionComponent<IScreenProps>;

let warningShown = false;

function detectRouterIssues(routes: JSX.Element[]): void {
  // Paths crash detection
  const paths = routes
    .map(({ props }) => props.path)
    .filter(Boolean)
    .map((path: string) => {
      let formattedPath = path.trim();
      formattedPath = formattedPath.startsWith('/') ? formattedPath.slice(1) : formattedPath;
      formattedPath = formattedPath.endsWith('/') ? formattedPath.slice(0, -1) : formattedPath;
      return formattedPath;
    });

  const pathCrashes: Set<string> = new Set();
  paths.forEach((pathA, indexA) =>
    paths.forEach((pathB, indexB) => {
      if (indexA === indexB) {
        return;
      }

      if (pathA === pathB) {
        console.error(`🧭 Route path duplicate detected, please look for pages on path "${pathA}"`);
      }
      if ((pathA.endsWith(':id') || pathB.endsWith(':id')) && pathA.split('/').slice(0, -1).join('') === pathB.split('/').slice(0, -1).join('')) {
        const [sortedA, sortedB] = [pathA, pathB].sort();
        pathCrashes.add(`"${sortedA}" -> "${sortedB}"`);
      }
    })
  );
  if (pathCrashes.size && !warningShown) {
    warningShown = true;
    console.warn(`🧭 Route path crash detected!\nPlease look for pages on paths:\n${Array.from(pathCrashes).join('\n')}`);
  }
}

interface IFlexibleRoutesProps {
  pages: Schemas.CpaPage[];
  configuration: { [type: string]: Screen | { [page: string]: Screen } };
}

const FlexibleRoutes: React.FC<IFlexibleRoutesProps> = <T extends Schemas.CpaPage>({
  pages,
  configuration,
}: PropsWithChildren<IFlexibleRoutesProps>) => {
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const msalProvider = useSelector((state: IGlobalState) => state.app.msalProvider);
  const cpaConfiguration = useSelector((state: IGlobalState) => state.app.cpa?.configuration);
  const [, i18n] = useTranslation();

  const matchedPages: (T & { screen: Screen })[] = useMemo(() => {
    return pages
      .map((page) => {
        if (!page.customTemplate?.identifier) {
          return Object.defineProperty({ ...page }, 'screen', { value: GenericScreen, enumerable: false });
        }

        if (page.customTemplate?.identifier) {
          if (!configuration[page.customTemplate.identifier]) {
            console.warn(`Screen '${page.customTemplate.identifier}' not found. Registered screens:`, Object.keys(configuration));
            return null;
          }

          return Object.defineProperty({ ...page }, 'screen', {
            value: configuration[page.customTemplate.identifier],
            enumerable: false,
          });
        }

        console.warn(`Invalid type configuration for type ${page.identifier}. Registered types:`, Object.keys(configuration));
        return null;
      })
      .filter((page) => {
        return !(!page || !page.path);
      }) as (T & { screen: Screen })[];
  }, [pages, configuration]);

  const homePage = useMemo(() => {
    return matchedPages.find((page) => page.isDashboard && page.path && !page.parentCpaPage) ?? matchedPages[0];
  }, [matchedPages]);

  const pagesWithIds: (T & { screen: Screen; isItemRoute?: boolean })[] = useMemo(() => {
    return [
      ...matchedPages,
      ...matchedPages
        .filter((p) => !!p.path)
        .map((p) => {
          const clonedPage: T & { screen: Screen; isItemRoute?: boolean } = cloneDeepWithMetadata(p);
          clonedPage.path = urlJoin(p.path!, '/:id');
          clonedPage.isItemRoute = true;

          return clonedPage;
        }),
    ];
  }, [matchedPages]);

  const locales = useSelector((state: IGlobalState) => state.app.locales);
  const localesIdentifiers = useMemo(() => {
    return locales.map(({ identifier }) => identifier);
  }, [locales]);

  const renderScreen = useCallback(
    (page: T, Component: Screen) =>
      (
        props: RouteComponentProps<
          { locale?: string },
          StaticContext,
          | {
              externalDataQuery?: Record<string, string>;
            }
          | null
          | undefined
        >
      ): JSX.Element | null => {
        let hasForcedLanguage = false;
        // Obtain locale from url
        if (props.match.params.locale) {
          const targetLocale = verifyLanguage(props.match.params.locale, localesIdentifiers).specificName;
          if (localesIdentifiers.includes(targetLocale)) {
            hasForcedLanguage = true;
            if (targetLocale !== i18n.language) {
              i18n.changeLanguage(targetLocale);
              // Stop rendering if language needs to be changed
              return null;
            }
          } else {
            console.error(`Incorrect target locale from url - ${targetLocale}`, localesIdentifiers);
          }
        }

        return (
          <CachedContent page={page} hasForcedLanguage={hasForcedLanguage} routeMatch={props.match}>
            <Component {...props} page={page} />
          </CachedContent>
        );
      },
    [i18n, localesIdentifiers]
  );

  const routes = useMemo(() => {
    if (!matchedPages.length) {
      console.warn(
        'No matched pages. Received pages:',
        pages.map((page) => `${page.customTemplate}`)
      );
    }
    console.debug(`Matched pages -`, matchedPages.length);

    const routes = pagesWithIds.map((page) => {
      if (page.isItemRoute) {
        return <Route key={`route-${page.path}-${page.identifier}`} path={page.path} exact={true} render={renderScreen(page, page.screen)} />;
      }

      return (
        <CacheRoute
          key={`route-${page.path}-${page.identifier}`}
          cacheKey={`route-${page.path}-${page.identifier}`}
          when="forward"
          path={page.path}
          exact={true}
          render={renderScreen(page, page.screen)}
        />
      );
    });

    detectRouterIssues(routes);
    return routes;
  }, [matchedPages, pages, pagesWithIds, renderScreen]);

  const currentLocation = useSelector((state: IGlobalState) => state.router.location);

  const onNoMatchRouteRender = useCallback(() => {
    if (!msalProvider || !cpaConfiguration) {
      console.warn(`Failed to start signin. Msal is not initialized.`);
      return;
    }

    console.debug(`Executing signin`);
    signIn(msalProvider, cpaConfiguration.msalAuthScopes ?? [], currentLocation);
    return null;
  }, [msalProvider, cpaConfiguration, currentLocation]);

  const localizedRoutes = useMemo(() => {
    // Creating a unique array of 2 letter languages
    const languages = Array.from(
      new Set(
        localesIdentifiers
          .map((locale) => {
            try {
              const parsedCulture = new CultureInfo(locale);
              return parsedCulture.TwoLetterLanguageName;
            } catch (e) {
              return;
            }
          })
          .filter(Boolean) as string[]
      )
    );

    return languages
      .map((language) =>
        pagesWithIds.map((page) => {
          if (page.isItemRoute) {
            return (
              <Route
                key={`localized-route-${page.path}-${page.identifier}-${language}`}
                path={urlJoin(`/:locale(${language}|${language}-\\w+)/`, page.path!)}
                exact={true}
                render={renderScreen(page, page.screen)}
              />
            );
          }
          return (
            <CacheRoute
              key={`localized-route-${page.path}-${page.identifier}-${language}`}
              cacheKey={`localized-route-${page.path}-${page.identifier}-${language}`}
              when="forward"
              path={urlJoin(`/:locale(${language}|${language}-\\w+)/`, page.path!)}
              exact={true}
              render={renderScreen(page, page.screen)}
            />
          );
        })
      )
      .flat();
  }, [localesIdentifiers, pagesWithIds, renderScreen]);

  const localizedRedirects = useMemo(
    () =>
      localesIdentifiers.map((identifier) => {
        let prefix = identifier;
        if (!matchedPages.length || !homePage.path) {
          return null;
        }

        try {
          const parsedCulture = new CultureInfo(identifier);
          prefix = parsedCulture.TwoLetterLanguageName;
        } catch (e) {
          return null;
        }

        return (
          <Route
            key={`redirect-${prefix}`}
            exact={true}
            path={`/:locale(${prefix}|${prefix}-\\w+)/`}
            render={(props): React.ReactNode => {
              return <Redirect to={urlJoin('/', props.match.params.locale, homePage.path!)} />;
            }}
          />
        );
      }),
    [homePage.path, localesIdentifiers, matchedPages.length]
  );

  return (
    <Layout>
      <CacheSwitch>
        {routes}
        <Route key={`route-navigation`} path={'/_navigation/:id'} exact={true} render={() => <Navigation />} />
        {localizedRoutes}
        {matchedPages.length && homePage.path && <Redirect from={'/'} to={homePage.path} exact={true} />}
        {localizedRedirects}
        {matchedPages.length && homePage.path && !!user && <Redirect from={'*'} to={homePage.path} exact={true} />}
        <Route render={onNoMatchRouteRender} />
      </CacheSwitch>
    </Layout>
  );
};

export default FlexibleRoutes;
