import { useCallback, useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';

import { IDataItem } from '../types';
import { FormContext } from '../constants';

export function useFormField<T>(propertyJsonPath: string): T | undefined {
  const formContext = useContext(FormContext);
  const [value, setValue] = useState<T | undefined>(() => {
    return _.get(formContext.currentFormData.current, propertyJsonPath) as T | undefined;
  });

  const handleFormChange = useCallback(
    (newFormData: IDataItem) => {
      setValue(_.get(newFormData, propertyJsonPath) as T | undefined);
    },
    [propertyJsonPath]
  );

  useEffect(() => {
    formContext.formEventEmitter?.on('change', handleFormChange);
    return () => {
      formContext.formEventEmitter?.off('change', handleFormChange);
    };
  }, [formContext.formEventEmitter, handleFormChange]);

  return value;
}

export function useFormTimestamp(isFormOpened: boolean): [timestamp: number | undefined, resetTimestamp: () => void] {
  const [timestamp, setTimestamp] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isFormOpened) setTimestamp(Date.now());
    else setTimestamp(undefined);
  }, [isFormOpened]);

  const resetTimestamp = useCallback(() => {
    setTimestamp(Date.now());
  }, []);
  return [timestamp, resetTimestamp];
}
