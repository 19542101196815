import React, { useCallback, useRef, useState } from 'react';
import { Icon } from '@fluentui/react';
import { createUuidV4 } from '@cp/base-types';
import { createCancelToken, isCancelError } from '@cpa/base-http';
import { generateFileUploadUrl, uploadFileToAzureStorage } from '@cpa/base-core/api';
import { useTranslation } from 'react-i18next';

import { IUploadTask } from '../../../../../../../../../components/Form/components/FileWidget/components/FilesInfo/FilesInfo';

import styles from './FileUpload.module.scss';

export enum FileUploadMode {
  button = 'button',
  text = 'text',
}

interface IFileUploadProps {
  mode: FileUploadMode;
  onUploadStart: () => void;
  onUploadComplete: () => void;
  onFileAdded: (files: string[]) => void;
}

const FileUpload: React.FC<IFileUploadProps> = ({ mode, onUploadStart, onUploadComplete, onFileAdded }) => {
  const [uploadTasks, setUploadTasks] = useState<Record<string, IUploadTask>>({});
  const currentValue = useRef<string[] | undefined>(undefined);

  const [t] = useTranslation();

  const removeUploadTask = useCallback(
    (id: string) => {
      if (uploadTasks[id]) {
        uploadTasks[id].cancelToken.cancel();
      }

      setUploadTasks((prevTasks) => {
        const newTasks = { ...prevTasks };
        delete newTasks[id];
        return newTasks;
      });
    },
    [uploadTasks]
  );

  const handleUploadDone = useCallback(
    (downloadUrl: string) => {
      if (Array.isArray(currentValue.current)) {
        currentValue.current = [...currentValue.current, downloadUrl];
        onFileAdded([...currentValue.current]);
      } else {
        onFileAdded([downloadUrl]);
      }
    },
    [onFileAdded]
  );

  const startFileUpload = useCallback(
    async (file: File) => {
      // Unique id per upload operation
      const uploadId = createUuidV4();
      const cancelToken = createCancelToken();

      // Block input send
      onUploadStart();

      // Init upload
      setUploadTasks((prevTasks) => ({
        ...prevTasks,
        [uploadId]: {
          cancelToken: cancelToken,
          id: uploadId,
          file: file,
        },
      }));

      try {
        // Get upload url
        const fileUploadDetails = await generateFileUploadUrl(file.name, cancelToken.token);
        await uploadFileToAzureStorage(
          fileUploadDetails.uploadUrl,
          file,
          (progress: number) => {
            setUploadTasks((prevTasks) => {
              if (!prevTasks[uploadId]) {
                return prevTasks;
              }

              return {
                ...prevTasks,
                [uploadId]: {
                  ...prevTasks[uploadId],
                  progress: progress,
                },
              };
            });
          },
          cancelToken.token
        );

        handleUploadDone(fileUploadDetails.downloadUrl);

        removeUploadTask(uploadId);
      } catch (e) {
        if (isCancelError(e)) {
          return;
        }

        setUploadTasks((prevTasks) => {
          if (!prevTasks[uploadId]) {
            return prevTasks;
          }

          return {
            ...prevTasks,
            [uploadId]: {
              ...prevTasks[uploadId],
              error: e.message || 'Unknown Error',
            },
          };
        });
      } finally {
        onUploadComplete();
      }
    },
    [handleUploadDone, onUploadComplete, onUploadStart, removeUploadTask]
  );

  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const files = Array.from(event.target.files);
      for (const file of files) {
        startFileUpload(file);
      }

      // Reset selected file, otherwise onChange won't work with the same selected file
      event.target.value = '';
    },
    [startFileUpload]
  );

  const handleInputClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleIconClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        onClick={handleInputClick}
        onChange={handleFileInputChange}
        disabled={false}
        defaultValue=""
        autoFocus={true}
        multiple={true}
        className={styles.input}
        tabIndex={-1}
      />
      {mode === FileUploadMode.button ? (
        <Icon iconName={'attach'} onClick={handleIconClick} />
      ) : (
        <div className={styles.uploadText} onClick={handleIconClick}>
          {t('messages.attachFiles')}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
