import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { useResizeDetector } from 'react-resize-detector';

import DifferenceAsObjectsOverview from './DifferenceAsObjectsOverview/DifferenceAsObjectsOverview';
import styles from './DifferenceAsObjectsWrapper.module.scss';

export interface IDifferenceAsObjectsWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const DifferenceAsObjectsWrapper: React.FC<IDifferenceAsObjectsWrapperProps> = ({ className, children }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const touchStartPosition = useRef<number | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const scrollHandleWrapRef = useRef<HTMLDivElement>(null);
  const scrollHandleRef = useRef<HTMLDivElement>(null);

  const isDraggingRef = useRef<boolean>(false);
  const shiftYRef = useRef<number>(0);
  const sourceScrollTopRef = useRef<number>(0);

  const [noScrollbarNeeded, setNoScrollbarNeeded] = useState<boolean>(false);

  const [scrollTop, setScrollTop] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [clientHeight, setClientHeight] = useState<number>(0);

  const [scrollHandleTop, setScrollHandleTop] = useState<string>('');
  const [scrollHandleHeight, setScrollHandleHeight] = useState<string>('');

  const refresh = useCallback(() => {
    if (isDraggingRef.current) {
      return;
    }

    if (!containerRef.current || !scrollbarRef.current || !scrollHandleRef.current) {
      return;
    }

    let _scrollTop: number = containerRef.current.scrollTop;
    const _scrollHeight: number = containerRef.current.scrollHeight;
    const _clientHeight: number = containerRef.current.clientHeight;

    if (_scrollTop + _clientHeight > _scrollHeight) {
      _scrollTop = _scrollHeight - _clientHeight;
    }

    if (scrollTop == _scrollTop && scrollHeight == _scrollHeight && clientHeight == _clientHeight) {
      return;
    }

    setScrollTop(_scrollTop);
    setScrollHeight(_scrollHeight);
    setClientHeight(_clientHeight);

    const _scrollbarHeight = containerRef.current.clientHeight;
    const _scrollHandleHeight = Math.round((_scrollbarHeight * _clientHeight) / _scrollHeight);

    setNoScrollbarNeeded(Math.abs(_scrollHandleHeight - _scrollbarHeight) < 10);

    const _scrollHandleTop = Math.round(_scrollbarHeight * (_scrollTop / _scrollHeight));

    setScrollHandleHeight(`${_scrollHandleHeight}px`);
    setScrollHandleTop(`${_scrollHandleTop}px`);
  }, [scrollTop, scrollHeight, clientHeight]);

  const { ref: bodyRef } = useResizeDetector({
    onResize: () => {
      refresh();
    },
  });

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      refresh();
    });
  }, [refresh]);

  const handleDragStart = useCallback(() => {
    return false;
  }, []);

  const updateScrollHandleTop = useCallback((_scrollHandleTop: number) => {
    if (!containerRef.current || !scrollbarRef.current || !scrollHandleRef.current) {
      return;
    }

    const scrollHeight: number = containerRef.current.scrollHeight;
    const clientHeight: number = containerRef.current.clientHeight;

    const minScrollHandleTop = 0;
    const maxScrollHandleTop = scrollbarRef.current.clientHeight - scrollHandleRef.current.clientHeight;

    if (_scrollHandleTop < minScrollHandleTop) {
      _scrollHandleTop = minScrollHandleTop;
    }

    if (_scrollHandleTop > maxScrollHandleTop) {
      _scrollHandleTop = maxScrollHandleTop;
    }

    let _scrollTop = Math.round((_scrollHandleTop / clientHeight) * scrollHeight);

    if (_scrollTop + containerRef.current.clientHeight > containerRef.current.scrollHeight) {
      _scrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
    }

    setScrollHandleTop(_scrollHandleTop + 'px');
    containerRef.current.scrollTop = _scrollTop;
  }, []);

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!scrollHandleRef.current || !scrollbarRef.current) {
        return;
      }

      const target = e.target as HTMLElement;

      if (scrollbarRef.current !== target && !scrollbarRef.current.contains(target)) {
        return;
      }

      if (target.closest('[data-diff-navigator="1"]')) {
        return;
      }

      const viewCenter = e.pageY - (scrollbarRef.current.getBoundingClientRect().top + scrollY);
      const scrollHandleTop = Math.round(viewCenter - scrollHandleRef.current.clientHeight / 2);

      updateScrollHandleTop(scrollHandleTop);
      sourceScrollTopRef.current = scrollHandleTop;
      isDraggingRef.current = true;
      shiftYRef.current = e.pageY;

      return false;
    },
    [updateScrollHandleTop]
  );

  const onMouseUp = useCallback(() => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false;
      shiftYRef.current = 0;
      sourceScrollTopRef.current = 0;
    }
  }, []);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDraggingRef.current) {
        updateScrollHandleTop(sourceScrollTopRef.current + e.pageY - shiftYRef.current);
      }
    },
    [updateScrollHandleTop]
  );

  const onTouchStart = useCallback((e: TouchEvent) => {
    touchStartPosition.current = e.touches[0].pageY;
  }, []);

  const onTouchMove = useCallback((e: TouchEvent) => {
    if (!containerRef.current || !scrollbarRef.current || !touchStartPosition.current) {
      return;
    }
    const delta = touchStartPosition.current - e.touches[0].pageY;
    touchStartPosition.current = e.touches[0].pageY;

    const _scrollTop = containerRef.current.scrollTop + delta;

    if (_scrollTop < 0) {
      containerRef.current.scrollTop = 0;
    } else if (_scrollTop > containerRef.current.scrollHeight) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    } else {
      containerRef.current.scrollTop = _scrollTop;
    }
  }, []);

  const onMouseWheel = useCallback((e: WheelEvent) => {
    if (!containerRef.current || !scrollbarRef.current) {
      return;
    }

    if (scrollbarRef.current !== e.target && !scrollbarRef.current.contains(e.target as Node)) {
      return;
    }

    const _scrollTop = containerRef.current.scrollTop + e.deltaY;

    if (_scrollTop < 0) {
      containerRef.current.scrollTop = 0;
    } else if (_scrollTop > containerRef.current.scrollHeight) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    } else {
      containerRef.current.scrollTop = _scrollTop;
    }

    e.preventDefault();
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, { passive: true, capture: true });
    document.addEventListener('mouseup', onMouseUp, { passive: true, capture: true });
    document.addEventListener('mousemove', onMouseMove, { passive: true, capture: true });
    document.addEventListener('wheel', onMouseWheel, { passive: false, capture: true });
    document.addEventListener('touchstart', onTouchStart, { passive: false, capture: true });
    document.addEventListener('touchmove', onTouchMove, { passive: false, capture: true });

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('wheel', onMouseWheel);
      document.removeEventListener('touchmove', onTouchStart);
      document.removeEventListener('touchstart', onTouchMove);
    };
  }, [onMouseDown, onMouseMove, onMouseUp, onMouseWheel, onTouchMove, onTouchStart]);

  return (
    <div className={classNames(className, styles.wrapper)}>
      <div
        ref={containerRef}
        className={classNames({
          [styles.container]: true,
          [styles.containerDark]: darkMode,
          [styles.containerDragging]: isDraggingRef.current,
        })}
        onScroll={handleScroll}
      >
        <div ref={bodyRef}>{children}</div>
      </div>

      <div
        ref={scrollbarRef}
        className={classNames({
          [styles.scrollbar]: true,
          [styles.scrollbarDark]: darkMode,
        })}
      >
        <DifferenceAsObjectsOverview className={styles.overview} containerRef={containerRef} scrollHeight={scrollHeight} />

        <div ref={scrollHandleWrapRef} className={styles.scrollHandleWrap}>
          <div
            ref={scrollHandleRef}
            className={classNames({
              [styles.scrollHandle]: true,
              [styles.scrollHandleDark]: darkMode,
            })}
            onDragStart={handleDragStart}
            style={{
              transform: scrollHandleTop ? `translateY(${scrollHandleTop})` : '',
              height: scrollHandleHeight,
              display: noScrollbarNeeded ? 'none' : '',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default DifferenceAsObjectsWrapper;
