const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpMessage',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMessage' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMessage?$filter=parentMessage%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMessage?$filter=relatedTo%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parentMessage.identifier',
    'cp_aggregationPipeline': [
        '[{"$lookup":{"from":"platform_cosmoconsult_com_ontology_CpMessageState","localField":"identifier","foreignField":"about.identifier","pipeline":[{"$match":{"email":"{{operationContext.jwtDetails.user}}"}}],"as":"result"}},{"$unwind":{"path":"$result","preserveNullAndEmptyArrays":true}},{"$set":{"__deleted":{"$ifNull":["$result.deleted",false]}}},{"$graphLookup":{"from":"platform_cosmoconsult_com_ontology_CpMessage","startWith":"$identifier","connectFromField":"identifier","connectToField":"parentMessage.identifier","as":"replies","depthField":"level","restrictSearchWithMatch":{}}},{"$set":{"__latestMessage":{"$function":{"body":"function (replies) { const sorted = replies.filter((reply) => reply.createdByUser !== \'{{operationContext.jwtDetails.user}}\').sort((a, b) => b._id.toString().localeCompare(a._id.toString())); return sorted[0]?.createdAt }","args":["$replies"],"lang":"js"}}}},{"$lookup":{"from":"platform_cosmoconsult_com_ontology_CpMessage","localField":"relatedTo.identifier","foreignField":"identifier","as":"relatedTo","pipeline":[{"$project":{"identifier":1,"createdAt":1,"createdByUser":1,"description":1, "files": 1}}]}},{"$unwind":{"path":"$relatedTo","preserveNullAndEmptyArrays":true}},{"$set":{"__lastRead":"$result.lastRead","__lastReadTimestamp":"$result.lastReadTimestamp"}},{"$unset":["result","replies"]}]',
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_aggergationPipelineSequence': 'BeforePaging',
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Message',
    'properties': {
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'hiddenInTable': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'parentMessage': {
            'title': 'Parent Message',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpMessage',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMessage' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentMessage', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentMessage.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Message' } },
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true },
        },
        'relatedTo': {
            'title': 'Related To',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpMessage',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMessage' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/relatedTo', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentMessage.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Message' } },
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
        },
        'createdAt': {
            'title': 'Date',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'From',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Recipients',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 28, 'wizardGroup': 0 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Message',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 29, 'wizardGroup': 0 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Subject',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'format': 'cp:messageTitle',
            'cp_ui': { 'sortOrderForm': 27, 'wizardGroup': 0 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['description', 'name'],
};
export const CpMessageSchema = Schema;
