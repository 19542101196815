import * as _ from 'lodash';
import { IJSONSchema } from '@cp/base-types';
import { CpaPage } from '@cp/base-types/src/ontology/schemas';
import { IDataItem, ILanguage } from '@cpa/base-core/types';

import { getAllLocalizations } from './getAllLocalizations';
import { getLocalizedFormData } from './getLocalizedFormData';
import { buildFieldEditorSchema } from './buildFieldEditorSchema';
import { buildFieldEditorFormData } from './buildFieldEditorFormData';

export interface IFieldLocalizationInfo {
  language: ILanguage;
  isBaseLanguage: boolean;
  isSet: boolean;
  formData: IDataItem;
}

export async function getLocalizedFields(args: {
  page: CpaPage;
  schema: IJSONSchema;
  identifier: string;

  fieldPath: string;
  fieldSchema: IJSONSchema;

  baseLanguage: string;
  languages: ILanguage[];
}): Promise<{ schema: IJSONSchema; localizedForms: IFieldLocalizationInfo[] } | undefined> {
  if (!args.page.dataUrl || !args.identifier || !args.page.dataEndpoint) {
    const errorMessages = [];

    if (!args.page.dataUrl) {
      errorMessages.push('CpaPage.dataUrl is missing.');
    }

    if (!args.identifier) {
      errorMessages.push('Identifier is missing.');
    }

    if (!args.page.dataEndpoint) {
      errorMessages.push('CpaPage.dataEndpoint is missing.');
    }

    throw new Error('Could not fields localizations. ' + errorMessages.join(' '));
  }

  const localizedFormData = await getLocalizedFormData(args.page.dataUrl, args.page.dataEndpoint.identifier!, args.identifier, args.baseLanguage);

  if (!localizedFormData) {
    throw new Error('Could not field localization. Could not get localized form data.');
  }

  const schema = buildFieldEditorSchema(args.schema, args.fieldSchema, args.fieldPath);
  const baseLanguageFormData = buildFieldEditorFormData(localizedFormData, args.fieldPath);

  const _eTag = baseLanguageFormData._eTag;
  const emptyFormDataJson = JSON.stringify({ _eTag: _eTag });

  const allLocalizations = await getAllLocalizations(args.page.dataUrl, args.page.dataEndpoint.identifier!, args.identifier);

  const localizations: IFieldLocalizationInfo[] = [];

  for (const language of args.languages) {
    if (args.baseLanguage === language.identifier) {
      localizations.push({
        language,
        isBaseLanguage: true,
        isSet: true,
        formData: baseLanguageFormData,
      });
    } else {
      let localizedFormData = allLocalizations?.[language.identifier];

      if (localizedFormData) {
        localizedFormData._eTag = _eTag;
        localizedFormData = buildFieldEditorFormData(localizedFormData, args.fieldPath);

        const localizedFormDataJson = JSON.stringify(localizedFormData);

        const isSet = localizedFormDataJson !== emptyFormDataJson;

        localizations.push({
          language,
          isBaseLanguage: false,
          isSet: isSet,
          formData: isSet ? localizedFormData : baseLanguageFormData,
        });
      } else {
        localizations.push({
          language,
          isBaseLanguage: false,
          isSet: false,
          formData: baseLanguageFormData,
        });
      }
    }
  }

  return {
    schema: schema,
    localizedForms: localizations,
  };
}
