import { IGlobalState } from '@cpa/base-core/store';
import { Callout, Checkbox, DirectionalHint } from '@fluentui/react';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './SearchModulesSelectorCallout.module.scss';

export interface ISearchModule {
  key: string;
  label: string;
}

export interface ISearchModulesSelectorCalloutProps {
  target: HTMLAnchorElement | null;
  searchModules: ISearchModule[];
  selectedModulesKeys: string[];
  setSelectedModulesKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

const SearchModulesSelectorCallout: React.FC<ISearchModulesSelectorCalloutProps> = ({
  target,
  searchModules,
  selectedModulesKeys,
  setSelectedModulesKeys,
}) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const onCheckboxChange = useCallback(
    (module: string) =>
      (event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        if (typeof checked !== 'boolean') {
          return;
        }

        if (checked) {
          setSelectedModulesKeys([...selectedModulesKeys, module]);
        } else {
          setSelectedModulesKeys(selectedModulesKeys.filter((key) => key !== module));
        }
      },
    [selectedModulesKeys, setSelectedModulesKeys]
  );

  const onDeselectAll = useCallback(() => {
    setSelectedModulesKeys([]);
  }, [setSelectedModulesKeys]);

  const onSelectAll = useCallback(() => {
    setSelectedModulesKeys(searchModules.map((module) => module.key));
  }, [setSelectedModulesKeys, searchModules]);

  return (
    <Callout
      className={classNames({
        [styles.callout]: true,
        [styles.calloutDark]: darkMode,
      })}
      role="dialog"
      gapSpace={6}
      isBeakVisible={true}
      beakWidth={10}
      directionalHint={DirectionalHint.topAutoEdge}
      setInitialFocus={false}
      target={target}
    >
      <div className={styles.body}>
        <h3 style={{ color: darkMode ? '#fefefe' : '#323130' }} className={styles.title}>
          {t('header.controls.search.searchInto')}
        </h3>

        {!selectedModulesKeys.length && (
          <p className={styles.deselect} onClick={onSelectAll}>
            {t('header.controls.search.selectAll')}
          </p>
        )}

        {!!selectedModulesKeys.length && (
          <p className={styles.deselect} onClick={onDeselectAll}>
            {t('header.controls.search.deselectAll')}
          </p>
        )}

        <div className={styles.checkboxWrapper}>
          {searchModules.map((module, index) => (
            <Checkbox
              onChange={onCheckboxChange(module.key)}
              key={module.key}
              label={module.label}
              styles={{ root: { marginTop: index === 0 ? 0 : 20 } }}
              checked={selectedModulesKeys.includes(module.key)}
            />
          ))}
        </div>
      </div>
    </Callout>
  );
};

export default SearchModulesSelectorCallout;
