import { getEndpoint } from '@cpa/base-core/api';
import urlJoin from 'url-join';

export interface ICheckDataItemLocalizedResult {
  isFullyLocalized: boolean;
  isPartiallyLocalized: boolean;
}

export function checkIsLocalized(args: {
  dataUrl: string;
  dataEndpointIdentifier: string;
  identifier: string;
  toLanguage: string;
}): Promise<ICheckDataItemLocalizedResult> {
  const endpoint = getEndpoint(args.dataEndpointIdentifier);

  const url = new URL(args.dataUrl, window.location.origin);
  url.pathname = urlJoin(url.pathname, `${encodeURIComponent(args.identifier)}/localizations/check-is-localized`);

  return endpoint.axios.get<ICheckDataItemLocalizedResult>(url.pathname, {
    params: {
      toLanguage: args.toLanguage,
    },
  });
}
