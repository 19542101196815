import { apm } from '@elastic/apm-rum';

export function instantApmTransaction(name: string, type: string, context?: object, labels?: Record<string, string | number | boolean>): void {
  const transaction = apm.startTransaction(name, type);
  if (labels) {
    transaction?.addLabels(labels);
  }
  if (context) {
    transaction?.addContext({
      custom: context,
    });
  }
  transaction?.end(performance.now() + 1);
}
