import React, { useMemo, useState } from 'react';
import { DefaultButton, DialogFooter, ScrollablePane } from '@fluentui/react';
import { IDataItem, IGlobalDialogStyles } from '@cpa/base-core/types';
import { IChangeEvent } from '@rjsf/core';
import { hideDialog } from '@cpa/base-core/store/app/actions';
import { useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import Form from '../../../Form/Form';

interface IFormDialogProps {
  dialogTypeOptions?: Record<string, unknown>;
  onClose: (result: unknown) => void;
  closeOnAction: boolean;
  secondaryText?: string;
  onInputDialogClose: () => void;
  styles?: IGlobalDialogStyles;
}

const FormDialog: React.FC<IFormDialogProps> = ({ dialogTypeOptions, onClose, closeOnAction, secondaryText, onInputDialogClose, styles }) => {
  const dispatch = useDispatch();

  const [height, setHeight] = useState(0);

  const { ref: resizeRef } = useResizeDetector({
    onResize: (width, height) => {
      setHeight(height ? height + 6 : 0);
    },
  });

  const scrollablePaneStyles = useMemo(() => {
    const minHeight = Math.max(height, 120);
    return {
      root: {
        position: 'relative' as const,
        marginTop: 40,
        minHeight,
      },
      stickyAbove: { zIndex: 5000 },
      contentContainer: { maxWidth: '100%', overflowX: 'hidden' },
    };
  }, [height]);

  return (
    <div>
      <div style={{ marginTop: '-14px' }}>
        {dialogTypeOptions?.schema ? (
          <ScrollablePane className={dialogTypeOptions.className as string | undefined} styles={scrollablePaneStyles}>
            <div ref={resizeRef}>
              <Form
                formData={dialogTypeOptions?.initialValue as IDataItem | undefined}
                schema={dialogTypeOptions.schema}
                onSubmit={({ formData }: IChangeEvent) => {
                  closeOnAction && dispatch(hideDialog());
                  onClose(formData);
                }}
              />
            </div>
          </ScrollablePane>
        ) : null}
      </div>
      <DialogFooter>
        {!!dialogTypeOptions?.optional && <DefaultButton styles={styles?.secondaryButton} text={secondaryText} onClick={onInputDialogClose} />}
      </DialogFooter>
    </div>
  );
};

export default FormDialog;
