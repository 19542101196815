import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalDialogType, IDataItem, IMenuItem } from '@cpa/base-core/types';
import { DefaultButton, DialogFooter, DialogType, IContextualMenuProps, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { Schemas } from '@cp/base-types';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { push } from 'connected-react-router';
import { menuItemsToCommandBarItems, menuLinksToMenuItems, pagesToMenuLinks, showDialog } from '@cpa/base-core/helpers';
import { hideDialog } from '@cpa/base-core/store/app/actions';

import { transformItemForAnotherEntity } from '../../../utils/copy';

export interface ICopyDialogRef {
  openDialog(item: IDataItem): void;

  closeDialog(): void;
}

const copyDialogInitialState = {
  item: null as IDataItem | null,
  error: undefined as string | undefined,
};

export const CopyDialog = React.forwardRef(function CopyDialog(props, ref: React.Ref<ICopyDialogRef>) {
  const appPages = useSelector((state: IGlobalState) => state.app.pages);
  const dispatch = useDispatch();

  const pages: (Schemas.CpaPage & { key: string; text: string })[] = useMemo(
    () =>
      appPages
        .filter((page) => page.allowCreate && page.dataUrl && page.path)
        .map((page) => {
          const matchedPage = appPages.find((p) => p.identifier === page.parentCpaPage?.identifier);
          const text = matchedPage?.name ? page.name + ' - ' + matchedPage.name : page.name;
          return { ...page, key: page.identifier!, text } as Schemas.CpaPage & { key: string; text: string };
        }),
    [appPages]
  );

  const [dialogState, setDialogState] = useState(copyDialogInitialState);
  const [t] = useTranslation();

  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: t('common.copyType'),
      subText: t('common.copyTypeSubtext'),
    }),
    [t]
  );

  const onDismissDialog = useCallback(() => {
    setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    dispatch(hideDialog());
  }, [dispatch]);

  const onDialogSubmit = useCallback(
    async (selectedItem: IMenuItem) => {
      const targetPage = pages.find((page) => page.identifier === selectedItem.key);
      if (!targetPage || !targetPage.path) {
        setDialogState((state) => ({ ...state, error: t('errors.pages.unavailablePage') }));
        return;
      }

      try {
        const { item } = await transformItemForAnotherEntity(dialogState.item!, targetPage, t, true);
        setDialogState(copyDialogInitialState);
        dispatch(hideDialog());
        dispatch(push(targetPage.path + `?action=add&preset=${encodeURIComponent(JSON.stringify(item))}`));
      } catch (e) {
        setDialogState((state) => ({ ...state, error: e.message }));
      }
    },
    [dialogState.item, dispatch, pages, t]
  );

  useImperativeHandle(
    ref,
    () => ({
      openDialog(item: IDataItem): void {
        setDialogState((state) => ({ ...state, item }));
      },
      closeDialog(): void {
        onDismissDialog();
      },
    }),
    [onDismissDialog]
  );

  const menuStructure = useMemo(() => {
    const pages = new Set<Schemas.CpaPage>();
    for (const page of appPages.filter((page) => page.showInMenu)) {
      if (page.showInMenu && page.allowCreate && page.dataUrl && page.path) {
        pages.add(page);

        // Add all parents
        let childPage: Schemas.CpaPage | undefined = page;
        while (childPage) {
          childPage = appPages.find((page) => page.identifier === childPage!.parentCpaPage?.identifier);
          if (childPage) {
            pages.add(childPage);
          }
        }
      }
    }

    // We don't need any actions, only pages
    const links = pagesToMenuLinks(Array.from(pages)).map((link) => ({ ...link, actions: [] }));

    return menuItemsToCommandBarItems(menuLinksToMenuItems(links, t), onDialogSubmit);
  }, [t, onDialogSubmit]);

  const menuProps: IContextualMenuProps = React.useMemo(
    () => ({
      items: menuStructure,
    }),
    [menuStructure]
  );

  useEffect(() => {
    if (!dialogState.item) return;
    showDialog({
      type: GlobalDialogType.CUSTOM,
      dialogContentProps: dialogContentProps,
      onClose: onDismissDialog,
      width: 360,
      dialogTypeOptions: {
        renderBody: () => {
          return (
            <>
              {!!dialogState.error && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                  {dialogState.error}
                </MessageBar>
              )}
              <DialogFooter>
                <PrimaryButton text={t('common.pages')} menuProps={menuProps} />
                <DefaultButton onClick={onDismissDialog} text={t('common.cancel')} />
              </DialogFooter>
            </>
          );
        },
      },
    });
  }, [dialogContentProps, dialogState.error, dialogState.item, onDismissDialog, pages, t, menuProps]);

  return null;
});
