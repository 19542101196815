import { store } from '../store';

export function perAccount<T>(cb: (company: string, accountNo: string) => T): T[] {
  const companies = store.getState().auth?.user?.companies || [];

  return companies
    .reduce((acc, company) => {
      if (!company.company) {
        return acc;
      }

      const accounts = company.accounts
        .filter((account) => account.type === 'Customer' && account.no)
        .map((account) => ({
          ...account,
          company,
        }));

      return [...acc, ...accounts];
    }, [])
    .map((account) => {
      return cb(encodeURIComponent(account.company.company.toString()), encodeURIComponent(account.no.toString()));
    })
    .filter((result) => result !== null) as T[];
}
