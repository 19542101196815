import fs from 'fs';
import os from 'os';
import path from 'path';
import unixify from 'unixify';
import { isDefined } from './typeGuards';
import { createUuidV4 } from './uuid';
export var EnvironmentType;
(function (EnvironmentType) {
    EnvironmentType["Local"] = "local";
    EnvironmentType["Dev"] = "dev";
    EnvironmentType["Staging"] = "staging";
    EnvironmentType["Prod"] = "prod";
})(EnvironmentType || (EnvironmentType = {}));
export class Environment {
    static get env() {
        var _a;
        return (this._env = (_a = this._env) !== null && _a !== void 0 ? _a : this.loadEnv());
    }
    static get safeEnv() {
        return new Proxy(Environment.env, {
            get: function (target, propertyName) {
                const envValue = target[propertyName.toString()];
                if (!isDefined(envValue)) {
                    throw new Error(`Expected environment variable '${propertyName.toString()}' to be defined.`);
                }
                return envValue;
            },
        });
    }
    static loadEnv(...envPaths) {
        if (this.isNode() && !this._env) {
            if (envPaths.length > 0) {
                for (const envPath of envPaths) {
                    if (fs.existsSync(envPath)) {
                        this.parseEnv(envPath);
                    }
                    else {
                        this.loadSpecificEnv(path.dirname(envPath));
                    }
                }
            }
            else {
                this.loadSpecificEnv();
            }
        }
        this._env = process.env;
        return process.env;
    }
    static loadSpecificEnv(appDirectory = fs.realpathSync(process.cwd())) {
        const dotenvPath = path.resolve(appDirectory, '.env');
        const NODE_ENV = process.env.NODE_ENV || 'development';
        const dotenvFiles = [
            `${dotenvPath}.${NODE_ENV}.local`,
            `${dotenvPath}.${NODE_ENV}`,
            NODE_ENV !== 'test' && `${dotenvPath}.local`,
            dotenvPath,
        ].filter(Boolean);
        const files = [];
        files.concat(dotenvFiles).forEach((dotenvFile) => {
            if (fs.existsSync(dotenvFile)) {
                this.parseEnv(dotenvFile);
            }
        });
    }
    static parseEnv(path) {
        const dotenvExpand = require('dotenv-expand');
        const dotenv = require('dotenv');
        const env = dotenv.config({
            path: path,
        });
        const envExpanded = dotenvExpand.expand(env);
        return envExpanded;
    }
    static isBrowser() {
        return typeof window !== 'undefined' && typeof window.document !== 'undefined';
    }
    static isWebWorker() {
        return typeof self === 'object' && self.constructor && self.constructor.name === 'DedicatedWorkerGlobalScope';
    }
    static isNode() {
        return typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
    }
    static get console() {
        return console;
    }
    static get appName() {
        var _a, _b, _c;
        let app = 'Unknown';
        if (Environment.isBrowser()) {
            app = document === null || document === void 0 ? void 0 : document.title;
        }
        else if (Environment.isNode()) {
            app = (_a = process.env.npm_package_name) !== null && _a !== void 0 ? _a : '';
            if (!app) {
                if ((_b = require.main) === null || _b === void 0 ? void 0 : _b.filename) {
                    app = unixify(path.dirname((_c = require.main) === null || _c === void 0 ? void 0 : _c.filename));
                }
            }
        }
        return app;
    }
    static get hostName() {
        return os.hostname();
    }
}
Environment.processUuid = createUuidV4();
