const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Entitlement',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'customer/{{accountNo}}/entitlement', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
        {
            'rel': 'related',
            'href': "customer/{{accountNo}}/ticket?$filter=entitlement%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
        {
            'rel': 'related',
            'href': "customer/{{accountNo}}/complex-ticket?$filter=entitlement%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Arrangement',
    'properties': {
        'crmBusinessUnit': {
            'title': 'Crm Business Unit',
            '$id': 'http://platform.cosmoconsult.com/ontology/BusinessUnit',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'businessunits', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/crmBusinessUnit', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Business Unit' } },
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
        },
        'businessUnit': {
            'title': 'Business Unit',
            '$id': 'http://platform.cosmoconsult.com/ontology/BusinessUnit?allData=true',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessUnit%3FallData%3Dtrue' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/businessUnit', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Business Unit' } },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const EntitlementSchema = Schema;
