import { IJSONSchema, Schemas, TypeConstants } from '@cp/base-types';
import { axiosDictionary, getEndpoint, getSchema, postEntityToEndpoint } from '@cpa/base-core/api';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { Icon } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import { DataServiceModules } from '@cp/base-utils';

import Form from '../../../../../Form/Form';
import LoadingArea from '../../../../../LoadingArea/LoadingArea';

import styles from './Feedback.module.scss';

interface IFeedbackProps {
  thankYouText: string;
  currentPage: Schemas.CpaPage;
  visible: boolean;
  onClose?: () => unknown;
}

const Feedback: React.FC<IFeedbackProps> = ({ thankYouText, currentPage, visible, onClose }) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    if (visible) {
      setInitialized(true);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && feedbackSent) {
      setFeedbackSent(false);
      // reset form data
      setInitialFormData({});
    }
  }, [feedbackSent, visible]);

  const cpaIdentifier = useSelector((state: IGlobalState) => state.app?.cpa?.identifier);
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const [feedbackSchema, setFeedbackSchema] = useState<IJSONSchema>();

  const userFormSchema = useMemo(() => {
    return feedbackSchema?.properties?.feedback;
  }, [feedbackSchema]);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
    getSchema(urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(TypeConstants.CpFeedback)}`)).then((schema) => {
      setFeedbackSchema(schema);
      setLoading(false);
    });
  }, [initialized]);

  const onFeedbackSubmit = useCallback(
    async ({ formData: userFeedback }: { formData: IDataItem }) => {
      setLoading(true);

      const feedback: IDataItem = {
        cpa: { identifier: cpaIdentifier },
        cpaPage: { identifier: currentPage.identifier },
        creator: { email: user?.account?.email, identifier: user?.account?.accountIdentifier, name: user?.account?.name },
        feedback: userFeedback,
      };

      await postEntityToEndpoint(
        axiosDictionary.appDataService,
        `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpFeedback)}`,
        feedback,
        feedbackSchema
      );

      setLoading(false);
      setFeedbackSent(true);
    },
    [currentPage.identifier, user, cpaIdentifier, feedbackSchema]
  );

  return (
    <>
      <LoadingArea loading={loading} />
      <div style={{ position: 'absolute', right: 0, zIndex: 1 }}>
        <Icon
          iconName={'Cancel'}
          style={{
            cursor: 'pointer',
            fontSize: 20,
            margin: 10,
            color: darkMode ? '#fefefe' : '#383838',
          }}
          onClick={onClose}
        />
      </div>
      {feedbackSent && (
        <div className={styles.thankYou}>
          <Icon className={darkMode ? styles.iconDark : styles.icon} iconName="ReceiptCheck" />
          <p className={darkMode ? styles.textDark : styles.text}>{thankYouText}</p>
        </div>
      )}
      <div className={styles.popupContent} style={{ visibility: feedbackSent ? 'hidden' : 'visible' }}>
        {!!userFormSchema && <Form schema={userFormSchema} formData={initialFormData} disableStickySubmitButton={true} onSubmit={onFeedbackSubmit} />}
      </div>
    </>
  );
};

export default Feedback;
