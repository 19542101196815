import * as _ from 'lodash';
import DiffMatchPatch from 'diff-match-patch';

export interface ITagToIgnore {
  openTag: string;
  closeTag: string;
  flag: boolean;
}

export function htmlDiff(
  html1: string,
  html2: string,
  diffTimeout = 0,
  tagsToIgnore: ITagToIgnore[] = []
): { time: number; oldDiffHtml: string; newDiffHtml: string } {
  // Our script is not compatible with custom XML.
  // Only text and HTML content is supported.
  if (html1?.startsWith('<?xml ') && html2?.startsWith('<?xml ')) {
    html1 = _.escape(html1);
    html2 = _.escape(html2);
  }

  const text1 = convertTextFromHtml(html1, tagsToIgnore);
  const text2 = convertTextFromHtml(html2, tagsToIgnore);

  const dmp = new DiffMatchPatch.diff_match_patch();
  dmp.Diff_Timeout = diffTimeout;
  const ms_start = new Date().getTime();
  const diff = dmp.diff_main(text1, text2, true);
  const ms_end = new Date().getTime();

  const time = ms_end - ms_start;

  const oldDiff: DiffMatchPatch.Diff[] = [];
  const newDiff: DiffMatchPatch.Diff[] = [];
  for (let i = 0, len = diff.length; i < len; i++) {
    if (diff[i][0] === 0) {
      oldDiff.push({ ...diff[i] });
      newDiff.push({ ...diff[i] });
    } else if (diff[i][0] === -1) {
      oldDiff.push({ ...diff[i] });
    } else {
      newDiff.push({ ...diff[i] });
    }
  }
  const oldDiffHtml = restoreToHtml(html1, oldDiff, tagsToIgnore);
  const newDiffHtml = restoreToHtml(html2, newDiff, tagsToIgnore);
  return { time, oldDiffHtml, newDiffHtml };
}

function restoreToHtml(originalHtml: string, diffResultList: DiffMatchPatch.Diff[], tagsToIgnore: ITagToIgnore[] = []): string {
  let diffHtml = '';
  while (true) {
    const tagAndText = getOneTextFromHtml(originalHtml, tagsToIgnore);
    const tag = tagAndText.tag;
    let text = tagAndText.text;

    diffHtml += tag;
    originalHtml = originalHtml.substr(tag.length + text.length);
    for (let i = 0, len = diffResultList.length; i < len; i++) {
      const diffType = diffResultList[i][0];
      const diffText = diffResultList[i][1];
      if (diffText === text) {
        diffHtml += formatText(diffType, diffText);
        diffResultList.splice(i, 1);
        break;
      }
      if (diffText.length > text.length) {
        diffHtml += formatText(diffType, text);
        diffResultList[i][1] = diffText.substr(text.length);
        break;
      }
      if (text.length > diffText.length) {
        diffHtml += formatText(diffType, diffText);
        text = text.substr(diffText.length);
        diffResultList.splice(i, 1);
        i--;
        len--;
      }
    }
    if (!originalHtml || !diffResultList || diffResultList.length <= 0) {
      break;
    }
  }
  return diffHtml + originalHtml;
}

function convertTextFromHtml(html: string, tagsToIgnore: ITagToIgnore[] = []): string {
  let text = '';
  let tagFlag = false;
  tagsToIgnore.map((item) => {
    item.flag = false;
  });
  for (let i = 0, len = html.length; i < len; i++) {
    if (!tagFlag && html[i] === '<') {
      tagFlag = true;
      tagsToIgnore.map((item) => {
        if (html.substr(i + 1, item.openTag.length) == item.openTag) {
          item.flag = true;
        }
      });
    } else if (tagFlag && html[i] === '>') {
      tagFlag = false;
      tagsToIgnore.map((item) => {
        if (item.flag && html.substring(i - item.closeTag.length, i) == item.closeTag) {
          item.flag = false;
        }
      });
      continue;
    }
    let notDiffFlag = false;
    tagsToIgnore.map((item) => {
      if (item.flag) {
        notDiffFlag = true;
      }
    });
    if (!tagFlag && !notDiffFlag) {
      text += html[i];
    }
  }
  return text;
}

function getOneTextFromHtml(html: string, tagsToIgnore: ITagToIgnore[] = []): { tag: string; text: string } {
  let tag = '';
  let text = '';
  let tagFlag = false;
  tagsToIgnore.map((item) => {
    item.flag = false;
  });
  for (let i = 0, len = html.length; i < len; i++) {
    if (!tagFlag && html[i] === '<') {
      tagFlag = true;
      if (text) {
        return { tag, text };
      }
      tagsToIgnore.map((item) => {
        if (html.substr(i + 1, item.openTag.length) == item.openTag) {
          item.flag = true;
        }
      });
    } else if (tagFlag && html[i] === '>') {
      tagFlag = false;
      tag += html[i];
      tagsToIgnore.map((item) => {
        if (item.flag && html.substring(i - item.closeTag.length, i) == item.closeTag) {
          item.flag = false;
        }
      });
      continue;
    }
    let notDiffFlag = false;
    tagsToIgnore.map((item) => {
      if (item.flag) {
        notDiffFlag = true;
      }
    });
    if (!tagFlag && !notDiffFlag) {
      text += html[i];
    } else {
      tag += html[i];
    }
  }
  return { tag, text };
}

function formatText(diffType: number, diffText: string): string {
  if (diffType === 0) {
    return diffText;
  } else if (diffType === -1) {
    return '<del>' + diffText + '</del>';
  } else {
    return '<ins>' + diffText + '</ins>';
  }
}
