const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartEntity',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartEntity' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa Page Chart Entity',
    'properties': {
        'color': { 'title': 'Color', 'type': 'string', 'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/color', 'rel': 'canonical' }] },
        'entityType': {
            'title': 'Entity Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageChartEntityType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageChartEntityType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/entityType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/AreaChart',
                'http://platform.cosmoconsult.com/ontology/BarChart',
                'http://platform.cosmoconsult.com/ontology/DotChart',
                'http://platform.cosmoconsult.com/ontology/LineChart',
            ],
            'enumNames': ['Area Chart', 'Bar Chart', 'Dot Chart', 'Line Chart'],
        },
        'valueProperty': {
            'title': 'Value Property',
            'type': 'string',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/valueProperty', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['valueProperty', 'name', 'entityType'],
};
export const CpaPageChartEntitySchema = Schema;
