import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment/moment';
import { DateLanguageMapping } from '@cpa/base-core/constants';
import { useTranslation } from 'react-i18next';
import { axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { Schemas, TypeConstants } from '@cp/base-types';
import { PersonaCoin, Shimmer } from '@fluentui/react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';

import HtmlContent from '../../../../../../../../../components/HtmlContent/HtmlContent';
import { personImagesGlobalCache } from '../../../../../../../../card/ContactPersonCard/components/PersonCard/PersonCard';
import ShowMore from '../../../../../../../../../components/ShowMore/ShowMore';

import styles from './Comment.module.scss';

interface ICommentProps {
  user: string;
  text: string;
  timestamp: string;
  hidden: boolean;
  width?: number;
}

const Comment: React.FC<ICommentProps> = ({ user, text, timestamp, hidden, width }) => {
  const [t, i18n] = useTranslation();
  const [expertDetails, setExpertDetails] = useState<Schemas.User | null>(null);
  const [timeFromNow, setTimeFromNow] = useState<string>(`(${moment(timestamp).fromNow()})`);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [showMore, setShowMore] = useState<boolean>(false);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });

  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    moment.locale(DateLanguageMapping[i18n.language]);
    setTimeFromNow(`${moment(timestamp).fromNow()}`);
  }, [i18n.language, timestamp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeFromNow(`${moment(timestamp as string).fromNow()}`);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [timestamp]);

  const getDetails = useCallback(async (): Promise<void> => {
    const dataServiceEndpoint = getEndpoint(axiosDictionary.appDataService);
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();
    try {
      setLoading(true);

      const response = await dataServiceEndpoint.axios.get(
        `data-store/${encodeURIComponent(TypeConstants.CompanyUser)}%3FallData=true/${encodeURIComponent(user)}`,
        {
          cancelToken: cancelToken.current.token,
        }
      );
      setExpertDetails(response.value);
    } catch (e) {
      setExpertDetails(null);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    getDetails();
    return () => {
      cancelToken.current?.cancel();
    };
  }, [cancelToken, getDetails]);

  useEffect(() => {
    if (!expertDetails?.image || isMobileDevice) {
      return;
    }

    let cancelled = false;

    if (!personImagesGlobalCache.has(expertDetails.image)) {
      personImagesGlobalCache.set(
        expertDetails.image,
        getEndpoint(axiosDictionary.appDataService)
          .axios.get(expertDetails.image, {
            responseType: 'blob',
          })
          .then((response: Blob) => {
            return URL.createObjectURL(response);
          })
      );
    }

    personImagesGlobalCache.get(expertDetails.image)?.then((url: string) => {
      if (!cancelled) {
        setImageUrl(url);
      }
    });

    return (): void => {
      cancelled = true;
    };
  }, [expertDetails?.image, isMobileDevice]);

  if (hidden) return null;

  if (loading) {
    return <Shimmer />;
  }

  return (
    <div className={styles.commentWrapper} style={{ maxWidth: width }}>
      {!isMobileDevice ? <PersonaCoin imageUrl={imageUrl} imageAlt={user} coinSize={35} style={{ cursor: 'default' }} /> : null}
      <div className={classNames(styles.comment, { [styles.light]: !darkMode })}>
        <div className={styles.user}>
          {expertDetails?.name || user} <strong>{timeFromNow}</strong>
        </div>
        <ShowMore maxHeight={150}>
          <HtmlContent className={styles.title} html={text} />
        </ShowMore>
      </div>
    </div>
  );
};

export default Comment;
