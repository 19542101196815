import { IGlobalState } from '@cpa/base-core/store';
import { Callout, DefaultButton, ICalloutProps, ProgressIndicator } from '@fluentui/react';
import { useBoolean, useSetInterval } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './FeatureCallout.module.scss';

const HIDE_CALLOUT_KEY = 'hideFeatureCallout';

export interface IFeatureCalloutProps {
  calloutProps: ICalloutProps;
  children: ReactNode;
  featureKey: string;
  animated?: boolean;
  timeout?: number;
  // needed for hover
  forceOpen?: boolean;
}

const FeatureCallout: React.FC<IFeatureCalloutProps> = ({ calloutProps, children, featureKey, animated, timeout, forceOpen }) => {
  const HIDE_CALLOUT_FEATURE_KEY = `${HIDE_CALLOUT_KEY}.${featureKey}`;
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [t] = useTranslation();
  const intervalRef = useRef<number | undefined>(undefined);
  const { setInterval, clearInterval } = useSetInterval();
  const [timeoutProgress, setTimeoutProgress] = useState(1);
  const [isProgressIndicatorVisible, { setFalse: hideProgressIndicator }] = useBoolean(true);
  const [isCalloutClosed, { setTrue: closeCallout }] = useBoolean(localStorage.getItem(HIDE_CALLOUT_FEATURE_KEY) === 'true');

  const userCloseCallout = useCallback(() => {
    if (!isCalloutClosed) {
      closeCallout();
      localStorage.setItem(HIDE_CALLOUT_FEATURE_KEY, `${!isCalloutClosed}`);
    }
  }, []);

  useEffect(() => {
    if (timeout && timeout > 0 && !isCalloutClosed) {
      const startTime = Date.now();
      intervalRef.current = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const progress = 1 - elapsedTime / timeout;
        if (progress <= 0 && intervalRef.current) {
          closeCallout();
          clearInterval(intervalRef.current);
        } else {
          setTimeoutProgress(progress);
        }
      }, 100);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        setTimeoutProgress(1);
      }
    };
  }, [isCalloutClosed, timeout]);

  const handlePointerEnter = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      hideProgressIndicator();
    }
  }, []);

  return !isCalloutClosed || forceOpen ? (
    <Callout
      {...calloutProps}
      className={classNames(styles.calloutBubble, {
        [styles.dark]: darkMode,
        [styles.calloutAnimated]: animated,
      })}
      onPointerEnter={handlePointerEnter}
    >
      {timeout && isProgressIndicatorVisible && <ProgressIndicator percentComplete={timeoutProgress} barHeight={8} />}
      <div className={styles.wraper}>
        {children}
        {!isCalloutClosed && (
          <div className={styles.bubbleButtons}>
            <DefaultButton onClick={userCloseCallout} className={darkMode ? styles.cancelBtnDark : styles.cancelBtn}>
              {t('common.gotIt')}
            </DefaultButton>
          </div>
        )}
      </div>
    </Callout>
  ) : null;
};

export default FeatureCallout;
