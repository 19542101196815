import React, { useMemo } from 'react';
import { Sector, Cell, PieChart, Pie, Tooltip } from 'recharts';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';

import ActiveSection from '../ActiveSection/ActiveSection';

export interface IGaugeSection {
  range: number;
  name: string;
  colors: {
    dark: string;
    light: string;
  };
}

interface IGaugeProps {
  sections: IGaugeSection[];
  value: number;
}

const WIDTH = 500;

const PIE_PROPS = {
  startAngle: 180,
  endAngle: 0,
  cx: WIDTH / 2,
  cy: WIDTH / 2,
};

const PIE_RADIUS = {
  innerRadius: (WIDTH / 2) * 0.35,
  outerRadius: (WIDTH / 2) * 0.4,
};

const Gauge: React.FC<IGaugeProps> = ({ sections, value = 0 }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const correctedValue = useMemo(() => {
    const val = +value <= 0 ? 1 : +value > 100 ? 100 : +value;
    return 180 * (val / 100);
  }, [value]);

  const colorData = useMemo(() => {
    return sections.map((section) => {
      const color = darkMode ? section.colors.dark : section.colors.light;
      return {
        value: section.range,
        color: color,
        name: section.name,
      };
    });
  }, []);

  const activeSectorIndex = useMemo(
    () =>
      colorData
        .map((cur, index, arr) => {
          const curMax = [...arr].splice(0, index + 1).reduce((a, b) => ({ value: a.value + b.value }), { value: 0 }).value;
          return correctedValue > curMax - cur.value && correctedValue <= curMax;
        })
        .findIndex((cur) => cur),
    [correctedValue]
  );

  const arrowData = useMemo(() => {
    return [{ value: correctedValue }, { value: 0 }, { value: colorData.map((cur) => cur.value).reduce((a, b) => a + b) - correctedValue }];
  }, [correctedValue]);

  const Arrow = useMemo(
    () =>
      ({ cx, cy, midAngle, outerRadius }: { cx: number; cy: number; midAngle: number; outerRadius: number }) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + (outerRadius + WIDTH * 0.03) * cos;
        const my = cy + (outerRadius + WIDTH * 0.03) * sin;
        return (
          <g>
            <circle cx={cx} cy={cy} r={10} fill="#b39c4d" stroke="none" />
            <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="6" stroke="#b39c4d" fill="none" strokeLinecap="round" />
          </g>
        );
      },
    []
  );

  const ActiveSectorMark = ({
    cx,
    cy,
    innerRadius,
    payload,
    name,
    midAngle,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    payload: {
      name: string;
      nameDetail: string;
    };
    name: string;
    darkMode?: boolean;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill?: string;
  }) => {
    const legOffset = window.innerWidth > 767 ? 15 : 0;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + 25 + legOffset) * cos;
    const my = cy + (outerRadius + 25 + legOffset) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 15;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius * 1.05} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        {value > 0 && (
          <>
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${my}`} stroke={fill} strokeWidth={2} fill="none" />
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={my}
              dy={payload.name ? undefined : 4}
              textAnchor={textAnchor}
              fill={darkMode ? '#fff' : '#333'}
            >
              {`${name}`}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={my} dy={20} textAnchor={textAnchor} fill={darkMode ? '#b4b4b4' : '#999'}>
              {payload.name}
            </text>
            {payload.nameDetail && (
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={my} dy={40} textAnchor={textAnchor} fill={darkMode ? '#b4b4b4' : '#999'}>
                {payload.nameDetail}
              </text>
            )}
          </>
        )}
      </g>
    );
  };

  return (
    <PieChart width={WIDTH} height={WIDTH / 2 + 30}>
      {/*<Tooltip*/}
      {/*  content={(props) => {*/}
      {/*    if (props.payload?.length) {*/}
      {/*      return (*/}
      {/*        <div>*/}
      {/*          <p>{props.payload?.[0]?.name}</p>*/}
      {/*        </div>*/}
      {/*      );*/}
      {/*    }*/}
      {/*    return null;*/}
      {/*  }}*/}
      {/*/>*/}
      {/*@ts-ignore */}
      <Pie
        activeIndex={activeSectorIndex}
        activeShape={(props): React.ReactElement =>
          ActiveSectorMark({
            ...props,
            darkMode,
            disablePluralEnding: true,
            name: props.payload.name,
            payload: {
              ...props.payload,
              name: null,
            },
          }) as React.ReactElement<SVGElement>
        }
        data={colorData}
        fill="#8884d8"
        {...PIE_RADIUS}
        {...PIE_PROPS}
      >
        {colorData.map((entry, index) => (
          <>
            <Cell key={`cell-${index}`} fill={colorData[index].color} />
          </>
        ))}
      </Pie>
      {/*@ts-ignore */}
      <Pie stroke="none" activeIndex={1} activeShape={Arrow} data={arrowData} outerRadius={PIE_RADIUS.innerRadius} fill="none" {...PIE_PROPS} />
    </PieChart>
  );
};

export default Gauge;
