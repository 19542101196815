import { IJSONSchema } from '@cp/base-types';
import { DefaultButton } from '@fluentui/react';
import { WidgetProps } from '@rjsf/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import styles from './Voting.module.scss';

interface IVotingProps extends WidgetProps {
  schema: IJSONSchema;
}

const Voting: React.FC<IVotingProps> = ({ required, readonly, disabled, registry, label, value, onChange, schema }) => {
  const [t] = useTranslation();

  const onVoteClick = useCallback(() => {
    onChange(value === 1 ? 0 : 1);
  }, [value, onChange]);

  return (
    <HoverTooltip content={value === 0 ? t('common.voteTooltipSet') : t('common.voteTooltipRevoke')}>
      <div>
        <DefaultButton
          toggle={true}
          iconProps={{ iconName: value === 1 ? 'LikeSolid' : 'Like' }}
          disabled={disabled || (schema as { disabled: boolean }).disabled}
          readOnly={readonly || (schema as { readonly: boolean }).readonly}
          onClick={onVoteClick}
          className={styles.voteBtn}
        />
      </div>
    </HoverTooltip>
  );
};

export default Voting;
