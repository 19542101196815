const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Customer',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'customer/all', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=documents%2Fcustomer%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometTransaction?$filter=customer%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "/crmAccounts?$filter=customers%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCometAssociation?$filter=customer%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSalesInvoice?$filter=customer%2Fidentifier%20eq%20'{identifier}'",
        },
        { 'rel': 'related', 'href': "documents?$filter=customer%2Fidentifier%20eq%20'{identifier}'", 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Customer',
    'description': '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
    'properties': {
        'address': {
            'title': 'Address',
            'description': 'The mailing address.',
            '$id': 'http://schema.org/PostalAddress',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPostalAddress' },
                { 'href': 'http://schema.org/address', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'addressCountry': {
                    'title': 'Address Country',
                    'description': 'The country. For example, USA. You can also provide the two-letter [ISO 3166-1 alpha-2 country code](http://en.wikipedia.org/wiki/ISO_3166-1).',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/addressCountry', 'rel': 'canonical' }],
                },
                'addressLocality': {
                    'title': 'Address Locality',
                    'description': 'The locality in which the street address is, and which is in the region. For example, Mountain View.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/addressLocality', 'rel': 'canonical' }],
                },
                'postalCode': {
                    'title': 'Postal Code',
                    'description': 'The postal code. For example, 94043.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/postalCode', 'rel': 'canonical' }],
                },
                'streetAddress': {
                    'title': 'Street Address',
                    'description': 'The street address. For example, 1600 Amphitheatre Pkwy.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/streetAddress', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 88 },
        },
        'contactPoint': {
            'title': 'Contact Point',
            'description': 'A contact point&#x2014;for example, a Customer Complaints department.',
            '$id': 'http://schema.org/ContactPoint',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FContactPoint' },
                { 'href': 'http://schema.org/contactPoint', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Contact Point' } },
            'cp_ui': { 'sortOrderForm': 89 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 84 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'alternateName': {
            'title': 'Alternate Name',
            'description': 'An alias for the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 86 },
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 85 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const CustomerSchema = Schema;
