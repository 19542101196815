import Konva from 'konva';

import { ImageEditorOperations } from '../../../../helpers/ImageEditorOperations';
import { ImageEditorChildType, ImageEditorUtils } from '../../../../helpers/ImageEditorUtils';

export abstract class ImageEditorCrop {
  public static showCropZone(stage: Konva.Stage): void {
    const layer = stage.children[0];

    if (ImageEditorUtils.getChild(stage, ImageEditorChildType.crop)) {
      return;
    }

    const scale = stage.scaleX();

    const cropWrapperGroup = new Konva.Group({
      clipHeight: stage.height() / scale,
      clipWidth: stage.width() / scale,
      clipX: 0,
      clipY: 0,
      name: ImageEditorChildType[ImageEditorChildType.crop],
    });
    layer.add(cropWrapperGroup);

    const width = cropWrapperGroup.clipWidth();
    const height = cropWrapperGroup.clipHeight();

    const cropWidth = Math.round(width * 0.6);
    const cropHeight = Math.round(height * 0.6);

    const cropBackground = new Konva.Group({
      draggable: false,
    });

    cropWrapperGroup.add(cropBackground);

    const cropRect1 = new Konva.Rect({
      fill: 'rgba(0, 0, 0, 0.6)',
    });
    cropBackground.add(cropRect1);

    const cropRect2 = new Konva.Rect({
      fill: 'rgba(0, 0, 0, 0.6)',
    });
    cropBackground.add(cropRect2);

    const cropRect3 = new Konva.Rect({
      fill: 'rgba(0, 0, 0, 0.6)',
    });
    cropBackground.add(cropRect3);

    const cropRect4 = new Konva.Rect({
      fill: 'rgba(0, 0, 0, 0.6)',
    });
    cropBackground.add(cropRect4);

    const cropGroup = new Konva.Group({
      draggable: true,
    });

    cropWrapperGroup.add(cropGroup);

    const cropRect = new Konva.Rect({
      x: Math.round((width - cropWidth) / 2),
      y: Math.round((height - cropHeight) / 2),
      width: cropWidth,
      height: cropHeight,
      fill: 'rgba(0, 0, 0, 0.1)',
    });

    cropGroup.add(cropRect);

    const transformer = new Konva.Transformer({
      // Konva supports applying transformer not only to rects, but the type is Konva.Rect.
      node: cropGroup as unknown as Konva.Rect,
      name: ImageEditorChildType[ImageEditorChildType.cropTransformer],
      rotateEnabled: false,
    });

    cropWrapperGroup.add(transformer);

    const refreshCropRect = (): void => {
      const scale = stage.scaleX();

      const elementWidth = transformer.width() / scale;
      const elementHeight = transformer.height() / scale;

      const elementPosition = transformer.position();

      const elementLeft = elementPosition.x / scale;
      const elementTop = elementPosition.y / scale;
      const elementRight = elementLeft + elementWidth;
      const elementBottom = elementTop + elementHeight;

      cropRect1.setAttrs({
        x: 0,
        y: 0,
        height: height,
        width: elementLeft,
      });

      cropRect2.setAttrs({
        x: elementLeft,
        y: 0,
        height: elementTop,
        width: elementWidth,
      });

      cropRect3.setAttrs({
        x: elementLeft,
        y: elementBottom,
        height: height - elementBottom,
        width: elementWidth,
      });

      cropRect4.setAttrs({
        x: elementRight,
        y: 0,
        height: height,
        width: width - elementRight,
      });

      layer.draw();
    };

    layer.draw();

    cropGroup.on('dragmove', () => {
      refreshCropRect();
    });
    cropGroup.on('transform', () => {
      refreshCropRect();
    });

    refreshCropRect();
  }

  public static hideCropZone(stage: Konva.Stage): void {
    const cropWrapperGroup = ImageEditorUtils.getChild(stage, ImageEditorChildType.crop);

    if (cropWrapperGroup) {
      cropWrapperGroup.remove();
    }
  }

  public static applyCrop(stage: Konva.Stage): string {
    const scale = stage.scaleX();

    const image = ImageEditorUtils.getChild(stage, ImageEditorChildType.image) as Konva.Image;
    const transformer = ImageEditorUtils.getChild(stage, ImageEditorChildType.cropTransformer) as Konva.Transformer;

    const cropWidth = transformer.width() / scale;
    const cropHeight = transformer.height() / scale;

    const transformerPosition = transformer.position();

    const cropX = transformerPosition.x / scale;
    const cropY = transformerPosition.y / scale;

    return ImageEditorOperations.editAndSave(cropWidth, cropHeight, (renderedStage) => {
      const layer = new Konva.Layer();
      renderedStage.add(layer);

      const imageElement = new Konva.Image({
        x: 0,
        y: 0,
        image: image.image(),
        width: cropWidth,
        height: cropHeight,
        cropX: cropX,
        cropY: cropY,
        cropWidth: cropWidth,
        cropHeight: cropHeight,
      });
      layer.add(imageElement);

      layer.batchDraw();
    });
  }
}
