import { isValidMenuItem } from '@cpa/base-core/helpers';
import { IMenuItem } from '@cpa/base-core/types';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FocusTrapZone, FocusZone, FocusZoneDirection, FocusZoneTabbableElements } from '@fluentui/react';
import { useMediaQuery } from 'react-responsive';

import { isActivePathInside, isCurrentActive } from '../../helpers/activePathSearch';
import MenuButton from '../MenuButton/MenuButton';

interface ICollapsibleItemProps {
  items: IMenuItem[];
  onItemClick: (key: string) => void;
  activePath: string;
  level?: number;
  isFiltered?: boolean;
  disableFirstFocus?: boolean;
}

const CollapsibleItem: React.FC<ICollapsibleItemProps> = ({ items, onItemClick, activePath, level = 0, isFiltered, disableFirstFocus }) => {
  const [buttons, setButtons] = useState<IMenuItem[]>(() => {
    const localItems = _.cloneDeep(items);
    localItems.forEach((item: IMenuItem) => {
      if ('expanded' in item && isActivePathInside(item, activePath)) {
        item.expanded = true;
      }
    });

    return localItems;
  });

  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });

  const toggleKey = useCallback(
    (key: string) => {
      setButtons(
        buttons.map((btn) => {
          if (btn.key === key) {
            if ('expanded' in btn) {
              btn.expanded = !btn.expanded;
            }
            return btn;
          } else {
            return btn;
          }
        })
      );
    },
    [buttons]
  );

  useEffect(() => {
    const localItems = _.cloneDeep(items);
    localItems.forEach((item: IMenuItem) => {
      if ('expanded' in item && isActivePathInside(item, activePath)) {
        item.expanded = true;
      }
      if (isFiltered) {
        item.expanded = true;
      }
    });
    setButtons(localItems);
  }, [items, activePath, isFiltered]);

  const content = useMemo(() => {
    return (
      <>
        {buttons.map((button) => {
          const displayMarker = isCurrentActive(button, activePath);

          if (!isValidMenuItem(button)) {
            return null;
          }

          return (
            <React.Fragment key={button.key}>
              <MenuButton
                text={button.name}
                onClick={(e) => {
                  if (button.onClick) {
                    e?.preventDefault();
                    button.onClick();
                  } else if (button.url) {
                    onItemClick(button.key);
                  }
                }}
                isActive={displayMarker}
                isExpandable={!!button.links}
                expanded={!!button.expanded}
                onExpandClick={button.links ? (): void => toggleKey(button.key) : (): void => {}}
                iconName={button.icon}
                url={button.url}
                external={button.external}
                level={level}
              />
              {!!button.links && (
                <div
                  style={{
                    display: !button.expanded ? 'none' : undefined,
                  }}
                >
                  <CollapsibleItem level={level + 1} items={button.links} onItemClick={onItemClick} activePath={activePath} isFiltered={isFiltered} />
                </div>
              )}
              {button.divider && <hr />}
            </React.Fragment>
          );
        })}
      </>
    );
  }, [activePath, buttons, isFiltered, level, onItemClick, toggleKey]);

  return level === 0 ? (
    <FocusTrapZone disableFirstFocus={isMobileDevice || disableFirstFocus}>
      <FocusZone direction={FocusZoneDirection.vertical} shouldRaiseClicksOnEnter={true} handleTabKey={FocusZoneTabbableElements.all} role="menubar">
        {content}
      </FocusZone>
    </FocusTrapZone>
  ) : (
    content
  );
};

export default CollapsibleItem;
