import { getAppBasePath } from '../helpers/location';
import { Environment } from '../app/environment';

export const systemDarkMode: boolean =
  Environment.env.REACT_APP_DISABLE_USER_THEME_DETECTION === 'true'
    ? false
    : !!window.matchMedia && !!window.matchMedia('(prefers-color-scheme: dark)').matches;

export const bootstrapLightThemeHref = `${getAppBasePath()}/vendor/tinymce/templates/bootstrap/assets/css/light/bootstrap-cpa-sc.min.css`;
export const bootstrapDarkThemeHref = `${getAppBasePath()}/vendor/tinymce/templates/bootstrap/assets/css/dark/bootstrap-cpa-sc.min.css`;
