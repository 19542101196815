import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ItemInfoContext } from '@cpa/base-core/constants';
import { CpaPage } from '@cp/base-types/src/ontology/schemas';
import { IJSONSchema } from '@cp/base-types';
import { IconButton, MessageBarType } from '@fluentui/react';
import { IDataItem } from '@cpa/base-core/types';

import Form from '../../../../../Form';
import { saveLocalizedField } from '../helpers/saveLocalizedField';
import MessageBars from '../../../../../../MessageBars/MessageBars';

import styles from './LocalizationEditorForm.module.scss';
import { IFieldLocalizationInfo } from '../helpers/getLocalizedFields';
import { IChangeEvent } from '@rjsf/core';
import HoverTooltip from '../../../../../../HoverTooltip/HoverTooltip';

export interface LocalizationEditorFormProps {
  localization: IFieldLocalizationInfo;
  allLocalizations: IFieldLocalizationInfo[];
  page: CpaPage;
  identifier: string;
  fieldSchema: IJSONSchema;
  editorSchema: IJSONSchema;
  path: string;

  onChanged: (_eTag: string, formData: IDataItem) => void;
}

const LocalizationEditorForm: React.FC<LocalizationEditorFormProps> = ({
  localization,
  allLocalizations,
  page,
  identifier,
  fieldSchema,
  editorSchema,
  path,
  onChanged,
}) => {
  const [t] = useTranslation();
  const itemInfoContext = useContext(ItemInfoContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const isDefaultLanguage = useMemo(
    () => localization.language.identifier?.toUpperCase().startsWith(itemInfoContext?.currentLanguage!),
    [localization, itemInfoContext?.currentLanguage!]
  );

  const handleBeforeSubmit: (obj: { formData: unknown }) => Promise<{
    cancelSubmit: boolean;
  }> = useCallback(async () => {
    return { cancelSubmit: false };
  }, []);

  const handleSubmit = useCallback(
    async ({ formData }: IChangeEvent) => {
      setIsSaving(true);
      setErrorMessage(undefined);

      try {
        const { _eTag } = await saveLocalizedField({
          page: page,
          identifier: identifier,
          fieldPath: path,
          fieldSchema: fieldSchema,
          language: localization.language.identifier,
          formData: formData!,
        });

        setIsSaving(false);
        setIsResetting(false);

        onChanged(_eTag, formData!);
      } catch (e) {
        setIsSaving(false);
        setIsResetting(false);
        setErrorMessage(e.message);
      }
    },
    [onChanged, page, identifier, fieldSchema, localization, path]
  );
  const resetLocalization = useCallback(() => {
    const baseLocalization = allLocalizations.find((s) => s.isBaseLanguage);

    if (baseLocalization) {
      setIsResetting(true);

      handleSubmit({ formData: baseLocalization.formData } as IChangeEvent);
    }
  }, [allLocalizations, localization, onChanged]);

  return (
    <div data-testid="LocalizationEditor__form" className={classNames(styles.form, { [styles.formDisabled]: isDefaultLanguage })}>
      {!!errorMessage && <MessageBars className={styles.error} messageBarType={MessageBarType.error} isMultiline={false} messages={[errorMessage]} />}

      <Form
        disableStickySubmitButton={true}
        schema={editorSchema}
        formData={localization.formData}
        btnText={isResetting ? t('localizationEditor.resetting') : isSaving ? t('localizationEditor.saving') : t('localizationEditor.saveChanges')}
        onSubmit={handleSubmit}
        onBeforeSubmit={handleBeforeSubmit}
      />

      {!localization.isBaseLanguage && localization.isSet && !isDefaultLanguage && !isResetting && (
        <div className={styles.resetIcon}>
          <HoverTooltip content={t('localizationEditor.reset')}>
            <IconButton
              onClick={resetLocalization}
              iconProps={{
                iconName: 'Delete',
              }}
            />
          </HoverTooltip>
        </div>
      )}
    </div>
  );
};

export default LocalizationEditorForm;
