import React, { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { parseMarkdown, replaceInternalLinks } from '@cpa/base-core/helpers';
import { TooltipOverflowMode } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import styles from './RootDescription.module.scss';

interface IRootDescriptionProps {
  description: string;
}

const RootDescription: React.FC<IRootDescriptionProps> = ({ description }) => {
  const hideDescriptionsInForm = useSelector((state: IGlobalState) => state.settings.hideDescriptionsInForm);

  const formattedDescription = useMemo(() => {
    return ReactHtmlParser(parseMarkdown(replaceInternalLinks(description))) as unknown as JSX.Element;
  }, [description]);
  if (hideDescriptionsInForm) return null;
  return (
    <span className={styles.rootDescription}>
      <HoverTooltip content={formattedDescription} overflowMode={TooltipOverflowMode.Parent}>
        {formattedDescription}
      </HoverTooltip>
    </span>
  );
};

export default RootDescription;
