import { ActionButton, Callout, DirectionalHint, Icon, IconButton, IIconProps, Target } from '@fluentui/react';
import React, { useCallback, useMemo, useRef } from 'react';
import { IDataItem } from '@cpa/base-core/types';
import classNames from 'classnames';
import { getRelevantEndpoint } from '@cp/base-utils';
import { IValidatedJwt } from '@cp/base-types';
import * as _ from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';
import { useMediaQuery } from 'react-responsive';
import { Environment } from '@cpa/base-core/app/environment';

import LoadingArea from '../../../../LoadingArea/LoadingArea';

import styles from './UserCallout.module.scss';

interface IAppLink extends IDataItem {
  name: string;
  icon: string;
  endpoints: {
    developmentEndpoint: string;
    localEndpoint: string;
    productionEndpoint: string;
    stagingEndpoint: string;
  };
  accessPermissions?: {}[];
}

export interface IUserCalloutProps {
  target: Target;
  hidden?: boolean;
  onDismiss?: () => void;
  user?: Pick<IValidatedJwt, 'name' | 'email'>;
  onSignOut: () => void;
  onSignIn: () => void;
  signInLabel: string;
  signOutLabel: string;
  isFetching?: boolean;
  apps?: IAppLink[];
  darkMode?: boolean;
  backgroundBlur?: boolean;
  isBeakVisible?: boolean;
  isUserEmailVisible?: boolean;
  children?: React.ReactNode;
}

const signInIcon: IIconProps = { iconName: 'Signin' };
const signOutIcon: IIconProps = { iconName: 'SignOut' };

const createApp = (appLink: IAppLink, darkMode?: boolean): JSX.Element => (
  <a
    className={classNames(styles.appLink, darkMode ? styles.dark : styles.light)}
    href={getRelevantEndpoint(appLink.endpoints, Environment.env.REACT_APP_ENVIRONMENT!)}
    target="_blank"
    rel="noopener noreferrer"
    key={appLink.identifier}
  >
    <Icon iconName={appLink.icon} />
    <p>{appLink.name}</p>
  </a>
);

const UserCallout: React.FC<IUserCalloutProps> = ({
  target,
  hidden = false,
  onDismiss,
  user,
  signInLabel,
  signOutLabel,
  onSignIn,
  onSignOut,
  isFetching = false,
  apps,
  darkMode,
  backgroundBlur,
  isBeakVisible = false,
  children,
  isUserEmailVisible = false,
}) => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });
  const userDetailsRef = useRef<HTMLDivElement>(null);
  const [calloutOpen, { setFalse: closeCallout, toggle: toggleCallout }] = useBoolean(false);

  const [baseApps, restrictedApps] = useMemo((): [JSX.Element[], JSX.Element[]] => {
    const { true: restrictedApps = [], false: baseApps = [] } = _.groupBy(apps, (app) => {
      // All apps should be in baseApps (return false) if user is signed out
      if (!user) {
        return false;
      }

      return Array.isArray(app.accessPermissions) && !!app.accessPermissions.length;
    });

    return [
      _.sortBy(baseApps, 'name').map((appLink) => createApp(appLink, darkMode)),
      _.sortBy(restrictedApps, 'name').map((appLink) => createApp(appLink, darkMode)),
    ];
  }, [darkMode, apps, user]);

  const onUserCalloutDismiss = useCallback(() => {
    closeCallout();
    onDismiss?.();
  }, [closeCallout, onDismiss]);

  return (
    <Callout
      role={'dialog'}
      beakWidth={8}
      gapSpace={0}
      directionalHint={DirectionalHint.topAutoEdge}
      target={target}
      onDismiss={onUserCalloutDismiss}
      setInitialFocus={false}
      preventDismissOnScroll={true}
      preventDismissOnResize={true}
      preventDismissOnLostFocus={false}
      hidden={hidden}
      className={classNames(styles.callout, { [styles.dark]: darkMode, [styles.blur]: backgroundBlur })}
      {...(isMobileDevice
        ? {
            isBeakVisible: false,
            minPagePadding: 0,
            coverTarget: true,
            styles: {
              root: {
                width: '100%',
                height: '100dvh',
                maxHeight: '100vh',
              },
            },
          }
        : { isBeakVisible })}
    >
      <div className={classNames(styles.wrapper, { [styles.mobile]: isMobileDevice })}>
        <div className={styles.userArea}>
          {isMobileDevice && (
            <div className={styles.closeArea}>
              <IconButton iconProps={{ iconName: 'Cancel' }} className={styles.close} onClick={onUserCalloutDismiss} />
            </div>
          )}
          <div className={styles.actions}>
            {user ? (
              <>
                <div ref={userDetailsRef} className={classNames(styles.user, { [styles.active]: !!children })} onClick={toggleCallout}>
                  <div className={styles.details}>
                    <span className={styles.name}>{user.name || '-'}</span>
                    {isUserEmailVisible && <span className={styles.email}>{user.email || '-'}</span>}
                  </div>
                  {!!children && <Icon iconName={'ChevronDown'} className={styles.icon} />}
                </div>
                <ActionButton className={styles.action} iconProps={signOutIcon} onClick={onSignOut}>
                  {signOutLabel}
                </ActionButton>
                {!!children && (
                  <Callout
                    role={'dialog'}
                    gapSpace={1}
                    isBeakVisible={false}
                    hidden={!calloutOpen || hidden}
                    target={userDetailsRef}
                    onDismiss={closeCallout}
                    directionalHint={DirectionalHint.bottomAutoEdge}
                  >
                    {children}
                  </Callout>
                )}
              </>
            ) : (
              <ActionButton id={'sign-in-button'} className={styles.action} iconProps={signInIcon} onClick={onSignIn}>
                {signInLabel}
              </ActionButton>
            )}
          </div>
          <div className={styles.curved}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 40">
              <path strokeWidth={0} d="m130,18.89c47.09-.31,90.34,8.22,130,21.11V0H0v40h0c38.41-12.53,84.61-20.82,130-21.11Z" />
            </svg>
          </div>
        </div>
        <div className={styles.appsArea}>
          {isFetching && <LoadingArea loading={true} />}
          <div className={styles.linksWrapper}>{baseApps}</div>
          {!!restrictedApps.length && (
            <>
              <div className={styles.divider} />
              <div className={styles.linksWrapper}>{restrictedApps}</div>
            </>
          )}
        </div>
      </div>
    </Callout>
  );
};

export default UserCallout;
