import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import AuthErrorModal from './components/AuthErrorModal/AuthErrorModal';
import ConnectionErrorModal from './components/ConnectionErrorModal/ConnectionErrorModal';

const boundReload = window.location.reload.bind(window.location);

const AppErrors: React.FC = () => {
  const errorCode = useSelector((state: IGlobalState) => state.app.connectionError);
  const configuration = useSelector((state: IGlobalState) => state.app.cpa?.configuration);
  const authError = useSelector((state: IGlobalState) => state.auth.error) as
    | (Error & {
        errorCode?: string;
        errorMessage?: string;
        name?: string;
      })
    | null;

  const ignoreError: boolean = useMemo(() => {
    if (!authError || !authError.errorCode) {
      return false;
    }
    return !!configuration?.msalIgnoreCodes?.includes(authError.errorCode);
  }, [authError, configuration?.msalIgnoreCodes]);

  const reloadPage: boolean = useMemo(() => {
    if (!authError || !authError.errorCode) {
      return false;
    }
    return !!configuration?.msalReloadCodes?.includes(authError.errorCode);
  }, [authError, configuration?.msalReloadCodes]);

  if (ignoreError) {
    console.info(`Ignoring error. Code: ${authError?.errorCode}.`);
    return null;
  }

  if (reloadPage) {
    console.info(`Reloading page. Error code: ${authError?.errorCode}`);
    window.location.reload();
    return null;
  }

  return (
    <>
      {!!authError?.errorMessage && <AuthErrorModal text={authError?.errorMessage} code={authError?.errorCode} onModalClose={boundReload} />}
      {errorCode !== null && <ConnectionErrorModal errorCode={errorCode} onModalClose={boundReload} />}
    </>
  );
};

export default AppErrors;
