const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/SalesQuote',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSalesQuote' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Quote',
    'properties': {
        'amount': {
            'title': 'Amount',
            'description': 'A monetary value or range. This type can be used to describe an amount of money such as $50 USD, or a range as in describing a bank account being suitable for a balance between £1,000 and £1,000,000 GBP, or the value of a salary, etc. It is recommended to use [[PriceSpecification]] Types to describe the price of an Offer, Invoice, etc.',
            '$id': 'http://schema.org/MonetaryAmount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FMonetaryAmount' },
                { 'href': 'http://schema.org/amount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'currency': {
                    'title': 'Currency',
                    'description': 'The currency in which the monetary amount is expressed.\\n\\nUse standard formats: [ISO 4217 currency format](http://en.wikipedia.org/wiki/ISO_4217) e.g. "USD"; [Ticker symbol](https://en.wikipedia.org/wiki/List_of_cryptocurrencies) for cryptocurrencies e.g. "BTC"; well known names for [Local Exchange Tradings Systems](https://en.wikipedia.org/wiki/Local_exchange_trading_system) (LETS) and other currency types e.g. "Ithaca HOUR".',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/currency', 'rel': 'canonical' }],
                },
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'format': 'cp:monetaryAmount',
            'cp_ui': { 'sortOrderForm': 70 },
        },
        'eligibleQuantity': {
            'title': 'Eligible Quantity',
            'description': ' A point value or interval for product characteristics and other purposes.',
            '$id': 'http://schema.org/QuantitativeValue',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FQuantitativeValue' },
                { 'href': 'http://schema.org/eligibleQuantity', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'unitText': {
                    'title': 'Unit Text',
                    'description': "A string or text indicating the unit of measurement. Useful if you cannot provide a standard unit code for\n<a href='unitCode'>unitCode</a>.",
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/unitText', 'rel': 'canonical' }],
                },
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 71 },
        },
        'price': {
            'title': 'Price',
            'description': 'A monetary value or range. This type can be used to describe an amount of money such as $50 USD, or a range as in describing a bank account being suitable for a balance between £1,000 and £1,000,000 GBP, or the value of a salary, etc. It is recommended to use [[PriceSpecification]] Types to describe the price of an Offer, Invoice, etc.',
            '$id': 'http://schema.org/MonetaryAmount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FMonetaryAmount' },
                { 'href': 'http://schema.org/price', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'currency': {
                    'title': 'Currency',
                    'description': 'The currency in which the monetary amount is expressed.\\n\\nUse standard formats: [ISO 4217 currency format](http://en.wikipedia.org/wiki/ISO_4217) e.g. "USD"; [Ticker symbol](https://en.wikipedia.org/wiki/List_of_cryptocurrencies) for cryptocurrencies e.g. "BTC"; well known names for [Local Exchange Tradings Systems](https://en.wikipedia.org/wiki/Local_exchange_trading_system) (LETS) and other currency types e.g. "Ithaca HOUR".',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/currency', 'rel': 'canonical' }],
                },
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'format': 'cp:monetaryAmount',
            'cp_ui': { 'sortOrderForm': 72 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 73 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 68 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 69 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const SalesQuoteSchema = Schema;
