export function copyToClipboard(text: string): void {
  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
}

export function copyToClipboardAsync(func: () => Promise<Blob>, onSuccess?: () => void, onError?: () => void): void {
  const clipboardItem = new ClipboardItem({ 'text/plain': func() });
  if (navigator.clipboard?.write) {
    navigator.clipboard
      .write([clipboardItem])
      .then(function () {
        onSuccess?.();
      })
      .catch(function (error) {
        if (onError) {
          onError();
        } else {
          console.error(error);
        }
      });
  }
}
