import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { configureAxios } from '@cpa/base-http';

import { getEndpoint, wrapAxiosWithLoadingInterceptors } from '../api';
import { DownloadMiddleware, getFilenameFromURL, isAzureStorageResource, multipleDownload } from '../helpers';
import { IDataItem } from '../types';
import notification from '../helpers/toast';

const azureStorageAxios = wrapAxiosWithLoadingInterceptors(configureAxios('/', undefined, undefined, undefined, false));

function generateFilenameFromItem(item: IDataItem, type: string): string {
  let extension: string | undefined;
  switch (type) {
    case 'application/pdf':
      extension = 'pdf';
      break;
    case 'image/png':
      extension = 'png';
      break;
    case 'image/jpeg':
      extension = 'jpeg';
      break;
  }

  const baseFilename = `${item.name || item.identifier || 'unnamed'}`;

  if (extension) {
    if (baseFilename.endsWith(`.${extension}`)) {
      return baseFilename;
    }

    if (baseFilename.includes('.')) {
      return baseFilename.substring(0, baseFilename.lastIndexOf('.')) + `.${extension}`;
    }
  }

  return [baseFilename, extension].filter(Boolean).join('.');
}

export const useDownload = (endpointIdentifier?: string): ((rows: IDataItem[], propertyJsonPath: string[]) => void) => {
  const [t] = useTranslation();

  const downloadMiddleware: DownloadMiddleware = useCallback(
    async (file: string, openExt: string[], item: IDataItem): Promise<[string, string]> => {
      const isAzureResource = isAzureStorageResource(file);
      if (!endpointIdentifier) {
        throw new Error(`Missing endpoint for file ${file}`);
      }

      const endpoint = getEndpoint(endpointIdentifier);
      const matchedAxios = !isAzureResource ? endpoint.axios : azureStorageAxios;
      const blob: Blob = await matchedAxios.get(file, {
        headers: !isAzureResource
          ? {
              'x-cp-data-company': item['__company'],
            }
          : {},
        responseType: 'blob',
      });
      const fileName = !isAzureResource ? generateFilenameFromItem(item, blob.type) : getFilenameFromURL(file);
      return [URL.createObjectURL(blob), fileName as string];
    },
    [endpointIdentifier]
  );

  return useCallback(
    (rows: IDataItem[], propertyJsonPaths: string[]) => {
      const toastId = notification.info(t('notifications.downloadInProgress'), Infinity);
      Promise.all(
        propertyJsonPaths.map(async (propertyJsonPath) => {
          await multipleDownload(rows, propertyJsonPath, downloadMiddleware);
        })
      )
        .then(() => {
          notification.success(t('notifications.downloadFinished'));
        })
        .catch(() => {
          notification.error(t('notifications.downloadFailed'));
        })
        .finally(() => {
          notification.dismiss(toastId);
        });
    },
    [downloadMiddleware, t]
  );
};
