import { IMenuItem } from '@cpa/base-core/types';

export const isActivePathInside = (item: IMenuItem, path: string): boolean => {
  if (item.url === path) {
    return true;
  }

  if (!item.links) {
    return false;
  }

  return item.links.some((link) => {
    return isActivePathInside(link, path);
  });
};

export const isCurrentActive = (item: IMenuItem, path: string): boolean => {
  return item.url === path;
};
