import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { IJSONSchema } from '@cp/base-types';
import { IconButton } from '@fluentui/react';
import { IGlobalState } from '@cpa/base-core/store';
import { cloneDeepWithMetadata, localizationMergingCustomizer } from '@cp/base-utils';
import { IDataItem } from '@cpa/base-core/types';
import classNames from 'classnames';
import { CpaPage } from '@cp/base-types/src/ontology/schemas';
import { ItemInfoContext } from '@cpa/base-core/constants';
import notification from '@cpa/base-core/helpers/toast';

import LoadingArea from '../../../../../LoadingArea/LoadingArea';

import LocalizationEditorTabs from './LocalizationEditorTabs/LocalizationEditorTabs';
import LocalizationEditorForm from './LocalizationEditorForm/LocalizationEditorForm';
import LocalizationEditorWrapper from './LocalizationEditorWrapper/LocalizationEditorWrapper';
import { IFieldLocalizationInfo, getLocalizedFields } from './helpers/getLocalizedFields';
import styles from './LocalizationEditor.module.scss';
import { LocalizationEditorContainerProps } from './LocalizationEditorContainer';

export interface LocalizationEditorProps extends LocalizationEditorContainerProps {
  page?: CpaPage;
  baseLanguage?: string;
  identifier?: string;
  onFieldLocalizationChanged?: (_eTag: string, language: string, dataPath: string, updatedValue: IDataItem) => void;
  path: string;
  currentLanguage?: string;
  editorWrapperProps?: {
    isInForm?: boolean;
    className?: string;
    centered?: boolean;
  };
}

const LocalizationEditor: React.FC<LocalizationEditorProps> = ({
  schema,
  rootSchema,
  widgetRef,
  onClose,
  page,
  baseLanguage,
  identifier,
  onFieldLocalizationChanged,
  path,
  currentLanguage,
  editorWrapperProps,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const locales = useSelector((state: IGlobalState) => state.app.locales);

  const currentLocale = useMemo(() => locales.filter((s) => s.identifier.toUpperCase().startsWith(currentLanguage!))[0], [locales, currentLanguage]);

  const [localizations, setLocalizations] = useState<IFieldLocalizationInfo[]>([]);
  const [selectedLocalization, setSelectedLocalization] = useState<IFieldLocalizationInfo>();

  const selectLocalization = useCallback(
    (language: string) => {
      setSelectedLocalization(localizations.find((s) => s.language.identifier === language));
    },
    [localizations]
  );

  const [isLoading, setIsLoading] = useState(true);
  const [editorSchema, setEditorSchema] = useState<IJSONSchema>();

  useEffect(() => {
    getLocalizedFields({
      page: page!,
      fieldSchema: schema,
      fieldPath: path,
      schema: rootSchema,
      baseLanguage: baseLanguage!,
      identifier: identifier!,
      languages: locales,
    })
      .then((response) => {
        const localizedFormToSelect = response?.localizedForms!.filter((s) => s.language.identifier !== currentLocale.identifier)[0];
        const currentLanguageLocalization = response?.localizedForms!.find((s) => s.language.identifier === currentLocale.identifier);

        setEditorSchema(response?.schema);
        const localizedForms = response?.localizedForms!;
        // Merge localization with base
        const baseLanguageLocale = localizedForms.find((form) => form.isBaseLanguage);
        if (baseLanguageLocale) {
          const mergedLocales = localizedForms.map((form) => {
            if (form.isBaseLanguage) return form;
            const mergedValue = _.mergeWith({}, baseLanguageLocale.formData!, form.formData, localizationMergingCustomizer);
            _.set(form, 'formData', mergedValue);
            return form;
          });
          setLocalizations(mergedLocales);
        } else {
          setLocalizations(localizedForms);
        }
        setSelectedLocalization(
          editorWrapperProps && !editorWrapperProps.isInForm && currentLanguageLocalization ? currentLanguageLocalization : localizedFormToSelect
        );
      })
      .catch((error) => {
        notification.error(error.message);
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale, path]);

  const handleLocalizationChanged = useCallback(
    (_eTag: string, formData: IDataItem) => {
      const updatedLocalizations = cloneDeepWithMetadata(localizations);

      const baseLocalization = updatedLocalizations.find((s) => s.isBaseLanguage);
      const localizationToUpdate = updatedLocalizations.find((s) => s.language.identifier === selectedLocalization?.language.identifier);

      if (!selectedLocalization || !localizationToUpdate || !baseLocalization) {
        return;
      }

      localizationToUpdate.formData = {
        ...formData,
      };

      if (!localizationToUpdate.isBaseLanguage) {
        // Flagging localization as set if it's different from base value
        localizationToUpdate.isSet = JSON.stringify(localizationToUpdate.formData) !== JSON.stringify(baseLocalization.formData);
      } else {
        // Updating up all unset localizations to base value
        for (const localization of updatedLocalizations) {
          if (!localization.isSet) {
            localization.formData = {
              ...formData,
            };
          }
        }
      }

      // Setting new eTag for all localizations
      for (const localization of updatedLocalizations) {
        localization.formData = {
          ...localization.formData,
          _eTag: _eTag,
        };
      }

      setLocalizations(updatedLocalizations);
      setSelectedLocalization(updatedLocalizations.filter((s) => s.language.identifier === selectedLocalization?.language.identifier)[0]);

      onFieldLocalizationChanged?.(_eTag, localizationToUpdate.language.identifier, path, localizationToUpdate.formData);
    },
    [localizations, selectedLocalization, onFieldLocalizationChanged, path]
  );

  return (
    <LocalizationEditorWrapper widgetRef={widgetRef} {...editorWrapperProps}>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingArea />
        </div>
      )}
      {!isLoading && selectedLocalization && (
        <div>
          <div
            data-testid="LocalizationEditor__tabs"
            className={classNames({
              [styles.tabs]: true,
              [styles.tabsDark]: darkMode,
            })}
          >
            <LocalizationEditorTabs
              localizations={localizations}
              selectedLanguage={selectedLocalization.language.identifier}
              onSelectedLanguageChanged={selectLocalization}
            />
          </div>

          <IconButton
            data-testid="LocalizationEditor__closeButton"
            className={styles.closeButton}
            iconProps={{ iconName: 'Cancel' }}
            onClick={onClose}
          />

          <ItemInfoContext.Provider
            value={{
              showMessage: () => null,
              dismissMessage: () => null,
              type: 'edit',
              baseLanguage: baseLanguage || null,
              currentLanguage: selectedLocalization.language.identifier,
            }}
          >
            <LocalizationEditorForm
              localization={selectedLocalization}
              allLocalizations={localizations}
              fieldSchema={schema}
              editorSchema={editorSchema!}
              identifier={identifier!}
              page={page!}
              path={path}
              onChanged={handleLocalizationChanged}
            />
          </ItemInfoContext.Provider>
        </div>
      )}
    </LocalizationEditorWrapper>
  );
};

export default LocalizationEditor;
