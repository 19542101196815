import React from 'react';
import {
  Stack,
  IStackTokens,
  DocumentCard,
  DocumentCardDetails,
  DocumentCardPreview,
  DocumentCardTitle,
  IDocumentCardPreviewProps,
  DocumentCardType,
} from '@fluentui/react';
import { ICardProps } from '@cpa/base-core/types';

import styles from './DocumentationCard.module.scss';

const stackTokens: IStackTokens = { childrenGap: 20 };

const DocumentationCard: React.FC<ICardProps> = ({ data, item, onClick, onItemSelect, isSelected, hideSelectionMarker, onRightClick }) => {
  const previewProps: IDocumentCardPreviewProps = {
    getOverflowDocumentCountText: (overflowCount: number) => `+${overflowCount} more`,
    previewImages: [
      {
        name: item.name as string,
        previewImageSrc: 'https://cp.cosmoconsult.com/shared/images/products/' + item.image ? (item.image as string) : '',
        // iconSrc: path.join("https://cp.cosmoconsult.com/shared/images/products/", item.image as string),
        width: 80,
        height: 80,
      },
    ],
  };

  return (
    <Stack tokens={stackTokens}>
      <DocumentCard type={DocumentCardType.compact} onClick={onClick}>
        <DocumentCardPreview className={styles.img} previewImages={[previewProps.previewImages[0]]} />
        <DocumentCardDetails className={styles.title}>
          <DocumentCardTitle title={item.name as string} shouldTruncate />
          <DocumentCardTitle title={item.shortDescription as string} className={styles.subtitle} showAsSecondaryTitle />
        </DocumentCardDetails>
      </DocumentCard>
    </Stack>
  );
};

export default DocumentationCard;
