const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Idea',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIdea' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FComment?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FRating?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FVoting?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_aggregationPipeline': [
        '[{"$lookup":{"from":"platform_cosmoconsult_com_ontology_Voting","let":{"idea_identifier":"$identifier"},"pipeline":[{"$match":{"$expr":{"$and":[{"$eq":["$about._type","http://platform.cosmoconsult.com/ontology/Idea"]},{"$eq":["$about.identifier","$$idea_identifier"]}]}}}],"as":"__votings"}},{"$lookup":{"from":"platform_cosmoconsult_com_ontology_Comment","let":{"idea_identifier":"$identifier"},"pipeline":[{"$match":{"$expr":{"$and":[{"$eq":["$about._type","http://platform.cosmoconsult.com/ontology/Idea"]},{"$eq":["$about.identifier","$$idea_identifier"]}]}}}],"as":"__comments"}},{"$set":{"__noOfVotings":{"$size": "$__votings"},"__noOfComments":{"$size": "$__comments"}}}]',
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Idea',
    'properties': {
        'status': {
            'title': 'Status',
            'description': '<div>\n<div>Product owner do set up the latest status here.</div>\n</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/IdeaStatus',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIdeaStatus' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/New',
                'http://platform.cosmoconsult.com/ontology/UnderReview',
                'http://platform.cosmoconsult.com/ontology/NeedsVotes',
                'http://platform.cosmoconsult.com/ontology/Accepted',
                'http://platform.cosmoconsult.com/ontology/Planned',
                'http://platform.cosmoconsult.com/ontology/Completed',
                'http://platform.cosmoconsult.com/ontology/Rejected',
            ],
            'enumNames': ['New (Waiting for Review)', 'Under Review', 'Needs Votes', 'Accepted (Backlog)', 'Planned', 'Completed', 'Rejected'],
            'cp_enumDescriptions': [
                '<div>The item has been created and the person responsible will review it as soon as possible.</div>',
                '<div>The item is currently being checked by the person responsible.</div>',
                '<div>The item needs votes to be taken into account.</div>',
                '<div>\n<div>The item has been reviewed and accepted.</div>\n</div>',
                '<div>The item will be realized or implemented soon (by a specific date).</div>',
                '<div>Item has been resolved. Please review, use or recommend using it.</div>',
                '<div>The person responsible appreciated the item but rejected it. There are several possible reasons for this, e.g: It already exists, it is too costly to implement, the need for improvement could not be demonstrated, the solution will not be further improved, etc.</div>',
            ],
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 53 },
        },
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'sortOrderForm': 48 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'referringTo': {
            'title': 'Referring To',
            '$id': 'http://platform.cosmoconsult.com/ontology/Solution',
            'type': 'object',
            'links': [
                {
                    'rel': 'collection',
                    'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution',
                    'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
                },
                { 'href': 'http://platform.cosmoconsult.com/ontology/referringTo', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentSolution.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Solution' } },
            'cp_ui': { 'sortOrderForm': 43 },
        },
        'motivation': {
            'title': 'Motivation',
            'description': '<div><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">How can you quantify the benefit of your </span>idea<span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">? </span>What type of user is your idea aimed at? What problem/need does the user have that this idea addresses? <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">What is the value of this improvement?</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> </span></div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 47 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/motivation', 'rel': 'canonical' }],
        },
        '__noOfVotings': {
            'title': 'No. of Votings',
            'type': 'number',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInFilter': true, 'sortOrderForm': 55 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/__noOfVotings', 'rel': 'canonical' }],
        },
        '__noOfComments': {
            'title': 'No. of Comments',
            'type': 'number',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInFilter': true, 'sortOrderForm': 56 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/__noOfComments', 'rel': 'canonical' }],
        },
        'statusComment': {
            'title': 'Status Comment',
            'description': '<div>Product owner add here additional hints regarding last status change.</div>',
            'type': 'string',
            'readOnly': true,
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 54 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/statusComment', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 49 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInTable': true, 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 51 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInTable': true, 'sortOrderForm': 52 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Describe your idea in a simple &amp; clear language. Provide examples where applicable. If you would like to share images, insert here. Files can be uploaded below.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 46 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 45 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'referringTo'],
    'cp_restrictablePaths': ['modifiedAt', 'modifiedByUser'],
    'cp_hasPermissions': true,
};
export const IdeaSchema = Schema;
