import { IGlobalState } from '@cpa/base-core/store';
import { AnimationClassNames, IconButton } from '@fluentui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const ExpandButton: React.FC<{
  isExpanded: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  size?: number;
  className?: string;
}> = ({ isExpanded, onClick, size = 14, className }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  return (
    <IconButton
      data-testid="ExpandButton"
      className={className}
      iconProps={{
        iconName: 'ChevronDown',
        style: {
          cursor: 'pointer',
          color: darkMode ? '#fefefe' : '#383838',
          fontSize: size,
        },
        className: isExpanded ? '' : AnimationClassNames.rotateN90deg,
      }}
      onClick={onClick}
    />
  );
};

export default ExpandButton;
