const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/SalesOpportunity',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': '/sales-opportunities', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2FsalesOpportunity%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Opportunity',
    'properties': {
        'status': {
            'title': 'Status',
            'description': 'The status of a thing',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 27 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' }],
        },
        'crmAccount': {
            'title': 'Crm Account',
            '$id': 'http://platform.cosmoconsult.com/ontology/CrmAccount?allData=true',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCrmAccount%3FallData%3Dtrue' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/crmAccount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Crm Account' } },
            'cp_ui': { 'sortOrderForm': 28 },
        },
        'contact': {
            'title': 'Contact',
            'description': 'A person (alive, dead, undead, or fictional).',
            '$id': 'http://schema.org/Person',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/contact', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'email': {
                    'title': 'Email',
                    'description': 'Email address.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                },
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 30 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 30 },
        },
        'owner': {
            'title': 'Owner',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/User',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'users', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'User' } },
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'sortOrderForm': 31 },
        },
        'estimatedAmount': {
            'title': 'Estimated Amount',
            'description': 'A monetary value or range. This type can be used to describe an amount of money such as $50 USD, or a range as in describing a bank account being suitable for a balance between £1,000 and £1,000,000 GBP, or the value of a salary, etc. It is recommended to use [[PriceSpecification]] Types to describe the price of an Offer, Invoice, etc.',
            '$id': 'http://schema.org/MonetaryAmount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FMonetaryAmount' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/estimatedAmount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'currency': {
                    'title': 'Currency',
                    'description': 'The currency in which the monetary amount is expressed.\\n\\nUse standard formats: [ISO 4217 currency format](http://en.wikipedia.org/wiki/ISO_4217) e.g. "USD"; [Ticker symbol](https://en.wikipedia.org/wiki/List_of_cryptocurrencies) for cryptocurrencies e.g. "BTC"; well known names for [Local Exchange Tradings Systems](https://en.wikipedia.org/wiki/Local_exchange_trading_system) (LETS) and other currency types e.g. "Ithaca HOUR".',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/currency', 'rel': 'canonical' }],
                },
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'format': 'cp:monetaryAmount',
            'cp_ui': { 'sortOrderForm': 32 },
        },
        'customerType': {
            'title': 'Customer Type',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 29 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/customerType', 'rel': 'canonical' }],
        },
        'productRevenue': {
            'title': 'Product Revenue',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/productRevenue', 'rel': 'canonical' }],
        },
        'serviceRevenue': {
            'title': 'Service Revenue',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/serviceRevenue', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 35 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 26 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 25 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const SalesOpportunitySchema = Schema;
