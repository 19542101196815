import { IJSONSchema } from '@cp/base-types';

import { replaceOverwritePaths } from './replaceOverwritePaths';

/**
 * Changes schema overwrites on the reorder items in an array schema path.
 * @param schemaOverwrites - The current schema overwrites.
 * @param arrayPath - The path to the array in which items are reordered.
 * @param indexFrom - The original index of the item.
 * @param indexTo - The new index for the item.
 */
export function changeOverwritesOnArrayItemReorder(
  schemaOverwrites: Record<string, IJSONSchema>,
  arrayPath: string,
  indexFrom: number,
  indexTo: number
): Record<string, IJSONSchema> {
  return replaceOverwritePaths(schemaOverwrites, [
    {
      pathFrom: arrayPath + `[${indexFrom}]`,
      pathTo: arrayPath + `[${indexTo}]`,
    },
    {
      pathFrom: arrayPath + `[${indexTo}]`,
      pathTo: arrayPath + `[${indexFrom}]`,
    },
  ]);
}
