import { chunkWidgets } from '@cpa/base-core/helpers';
import { IDataItem, ISingleItemTemplateProps } from '@cpa/base-core/types';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Schemas } from '@cp/base-types';

import GenericScreen from '../../../screens/GenericScreen/GenericScreen';
import HtmlContent from '../../../components/HtmlContent/HtmlContent';
import Widget from '../../../components/Widget/Widget';
import { ScreenTypesConfiguration } from '../../../mapping';

import styles from './CometRewardSingleItemTemplate.module.scss';

const CometRewardSingleItemTemplate: React.FC<ISingleItemTemplateProps> = ({ schema, item, subPages, subPageFilter, subPagePrefill }) => {
  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });
  const widgetsChunked: Schemas.CpaPage[][] = useMemo(() => chunkWidgets(subPages, desktopOnly ? 2 : 1, true), [desktopOnly, subPages]);

  const cometAssociation: Schemas.CometAssociation = item as Schemas.CometAssociation;
  const cometAchievement: Schemas.CometAchievement = item.cometAchievement as Schemas.CometAchievement;

  item = cometAchievement as IDataItem;

  return (
    <section className={styles.wrapper}>
      <div className={classNames(styles.item, styles.info)}>
        <Widget title={item.name as string} titleClassName={styles.widgetTitle}>
          <div style={{ padding: 20 }}>
            <HtmlContent html={item.description as string} />
          </div>
        </Widget>
      </div>

      {widgetsChunked.map((pagesPart: Schemas.CpaPage[], i: number) => {
        return (
          <div
            key={i}
            className={classNames(styles.row)}
            style={{ gridTemplateColumns: desktopOnly ? `repeat(${pagesPart.length}, ${100 / pagesPart.length}%)` : '100%' }}
          >
            {pagesPart.map((p: Schemas.CpaPage) => {
              if (p.customTemplate?.identifier) {
                const _Component = ScreenTypesConfiguration[p.customTemplate.identifier];
                return (
                  <div key={p.identifier} className={classNames(styles.item)}>
                    <_Component
                      item={item}
                      itemSchema={schema}
                      page={p}
                      externalODataFilter={subPageFilter}
                      isWidget={true}
                      externalPrefill={subPagePrefill}
                    />
                  </div>
                );
              }
              return (
                <div key={p?.identifier} className={classNames(styles.item)}>
                  <GenericScreen
                    item={item}
                    itemSchema={schema}
                    page={p}
                    externalODataFilter={subPageFilter}
                    isWidget={true}
                    externalPrefill={subPagePrefill}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </section>
  );
};

export default CometRewardSingleItemTemplate;
