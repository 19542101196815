import ReactGA from 'react-ga';

interface IGAStatus {
  initialized: boolean;
}

export const gaStatus: IGAStatus = {
  initialized: false,
};

export const initializeGA = (trackingId: string, debug: boolean = false): void => {
  // @ts-ignore
  window[`ga-disable-${trackingId}`] = false;

  if (gaStatus.initialized) {
    console.info('GA already initialized.');
    return;
  }

  ReactGA.initialize(trackingId, {
    debug: debug,
    titleCase: true,
  });

  console.debug(`GA initialized.`);

  gaStatus.initialized = true;
};

export const disableGA = (trackingId: string): void => {
  // @ts-ignore
  window[`ga-disable-${trackingId}`] = true;

  // Reset ga cookies
  document.cookie = '_ga=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = '_gat=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = '_gid=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';

  gaStatus.initialized = false;

  console.debug(`GA disabled.`);
};
