export var PlatformType;
(function (PlatformType) {
    PlatformType["Meta"] = "meta";
    PlatformType["Data"] = "data";
    PlatformType["ApiGateway"] = "api-gateway";
    PlatformType["Cdn"] = "cdn";
    PlatformType["AzureSearch"] = "azure-search";
    PlatformType["KeyVault"] = "key-vault";
    PlatformType["ElasticKibana"] = "elastic-kibana";
    PlatformType["PlatformGraph"] = "platform-graph";
})(PlatformType || (PlatformType = {}));
