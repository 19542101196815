import { IJSONSchema, IPathMetaItem, Schemas } from '@cp/base-types';
import { axiosDictionary, getEndpoint, getEntitiesFromEndpoint } from '@cpa/base-core/api';
import { getMatchingPageByDataUrl } from '@cpa/base-core/helpers';
import { useLoadableData, useTempFlag } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import {
  BaseApi,
  DynamicDataUrlFunction,
  IDataItem,
  IGenericComponentData,
  ILoadEntitiesOptions,
  IScrollableContent,
  ITableProps,
  LoadItemsFunction,
} from '@cpa/base-core/types';
import { DirectionalHint, IObjectWithKey, SearchBox, Selection, SelectionMode } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import notification from '@cpa/base-core/helpers/toast';
import { EndpointContext, TableKeyPrefix } from '@cpa/base-core/constants';
import { IFilter, buildMongoMatchFilterFromFrontendFilter, getRelationSubjectUri, getSubjectLocalizedCacheCollectionName } from '@cp/base-utils';

import DrawerOrCallout from '../../../../../DrawerOrCallout/DrawerOrCallout';
import ScrollingContent from '../../../../ScrollingContent';
import { TableComponentType } from '../../../Table/Table';
import { SuggestionValue } from '../FilterLayer/utils';
import LoadingArea from '../../../../../LoadingArea/LoadingArea';

import styles from './SearchSuggestions.module.scss';
import { prepareFilterForSuggestions } from './helpers';

interface ISearchSuggestionsProps {
  value?: [SuggestionValue, SuggestionValue][];
  onChange: (value: [SuggestionValue, SuggestionValue][]) => void;
  dataUrl: string | DynamicDataUrlFunction;
  label?: string;
  placeholder?: string;
  parsedFilter?: IFilter;
  dataPath: string;
  pathMetaItems: IPathMetaItem[];
  enumMapping?: Record<string, string>;
  disabled?: boolean;
  multiselect?: boolean;
  lowercase?: boolean;
  isSticky?: boolean;
  noBorder?: boolean;
}

const searchBoxIcon = { iconName: undefined };

const schema: IJSONSchema = { type: 'object', properties: { _id: { title: 'Suggestion', type: 'string' } } };

const hiddenColumns = ['identifier'];

const searchSuggestionsTableProps: Partial<ITableProps> = {
  disableMarqueeSelection: true,
};

const SearchSuggestions: React.FC<ISearchSuggestionsProps> = ({
  disabled,
  value,
  onChange,
  dataUrl,
  label,
  placeholder,
  dataPath,
  parsedFilter,
  enumMapping,
  multiselect,
  pathMetaItems,
  lowercase = false,
  isSticky,
  noBorder,
}) => {
  const [t, i18n] = useTranslation();

  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const currentLanguage = useMemo(() => dataLanguage ?? i18n.language, [dataLanguage, i18n.language]);

  const relationSubjectUri = useMemo(() => {
    const { relationSubjectUri, lookupLinkEndpoint } = getRelationSubjectUri(pathMetaItems);

    if (lookupLinkEndpoint) {
      try {
        const endpoint = getEndpoint(lookupLinkEndpoint);
        if (endpoint.dataType !== BaseApi.DataService) {
          // We want to ignore lookup links from other services
          return null;
        }
      } catch (e) {
        return null;
      }
    }

    return relationSubjectUri;
  }, [pathMetaItems]);

  const relationCollection = useMemo(
    () => (relationSubjectUri ? getSubjectLocalizedCacheCollectionName(relationSubjectUri, currentLanguage) : null),
    [relationSubjectUri, currentLanguage]
  );

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const scrollableContentRef = useRef<IScrollableContent>(null);

  const endpointIdentifier = useContext(EndpointContext);

  const userTypingRef = useRef(false);

  // Search box div wrapper ref
  const inputFieldDivRef = useRef<HTMLDivElement>(null);

  const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] = useBoolean(false);

  const pages = useSelector((state: IGlobalState) => state.app.pages);
  const matchedPage = useMemo(
    () => (dataUrl && typeof dataUrl === 'string' ? getMatchingPageByDataUrl(dataUrl, pages)?.matched : undefined),
    [dataUrl, pages]
  );

  const { loadItems, isFetching, items, isODataSupportedByEndpoint, errors } = useLoadableData(
    dataUrl,
    endpointIdentifier || axiosDictionary.appDataService
  );

  useEffect(() => {
    for (const error of errors) {
      notification.error(error);
    }
  }, [errors]);

  const getEnumMappingValue = useCallback(
    (value: string): string | undefined => {
      if (!enumMapping) return;
      const matchedKey = Object.keys(enumMapping).find((key) => key.toLowerCase() === value.toLowerCase());
      if (!matchedKey) return;
      return enumMapping[matchedKey];
    },
    [enumMapping]
  );

  const suggestionItems = useMemo(() => {
    if (!isODataSupportedByEndpoint) {
      return _.uniq(_.toPath(dataPath).reduce((acc, property) => acc.map((item) => _.get(item, property)).flat(), items))
        .filter((itemValue) => itemValue !== null && itemValue !== undefined)
        .sort()
        .map((itemValue) => ({
          _id: enumMapping ? getEnumMappingValue(itemValue as string) : itemValue,
          identifier: JSON.stringify(itemValue),
        }));
    } else {
      return items.map(({ _id: itemValue, _name: relationName }) => ({
        _id: enumMapping ? getEnumMappingValue(itemValue as string) : relationName || itemValue,
        identifier: JSON.stringify(itemValue),
      }));
    }
  }, [dataPath, enumMapping, getEnumMappingValue, isODataSupportedByEndpoint, items]);

  const lastFetchLimit = useRef(0);

  // stop fetching if last fetch didn't return items
  const totalItems = !isODataSupportedByEndpoint || lastFetchLimit.current > suggestionItems.length ? suggestionItems.length : 1e9;

  const loadSuggestionEntities = useCallback<LoadItemsFunction>(
    (odataOptions, loadOptions) => {
      const { top, skip } = odataOptions ?? {};
      const loadOptionsMerged: ILoadEntitiesOptions = {
        ...loadOptions,
        disableTriggers: true,
        silentFetching: true,
        interceptors: {
          ...(loadOptions?.interceptors ?? {}),
          beforeRequest: async (
            endpointId,
            path,
            queryOptions,
            disableTriggers,
            cancelToken,
            method,
            body
          ): Promise<Parameters<typeof getEntitiesFromEndpoint>> => {
            if (!isODataSupportedByEndpoint) {
              return [endpointId, path, queryOptions, disableTriggers, cancelToken, method, body];
            }

            let limit: number | undefined = undefined;
            if (top) {
              limit = top + (skip ?? 0);
              lastFetchLimit.current = limit;
            }

            let composedMongoFilter = await buildMongoMatchFilterFromFrontendFilter(parsedFilter);
            if (composedMongoFilter && Object.keys(composedMongoFilter).length) {
              composedMongoFilter = prepareFilterForSuggestions(composedMongoFilter, dataPath);
            }

            return [
              endpointId,
              urlJoin(path, 'aggregation-templates/search-suggestions/execute'),
              relationSubjectUri
                ? {
                    ..._.pick(queryOptions, 'filter'),
                    affectedSubjectUris: relationSubjectUri,
                  }
                : _.pick(queryOptions, 'filter'),
              disableTriggers,
              cancelToken,
              'POST',
              {
                globalFilterString:
                  composedMongoFilter && Object.keys(composedMongoFilter).length
                    ? JSON.stringify(composedMongoFilter, (key: string, value: unknown): unknown => {
                        if (value instanceof RegExp) {
                          return { $regex: value.source, $options: value.flags };
                        }

                        return value;
                      })
                    : undefined,
                dataPath,
                lowercase,
                limit,
                skip,
                filterValue: scrollableContentRef.current?.filterValue,
                relationCollection: relationCollection,
              },
            ];
          },
        },
      };

      return loadItems({}, loadOptionsMerged);
    },
    [dataPath, isODataSupportedByEndpoint, loadItems, relationCollection, relationSubjectUri, parsedFilter, lowercase]
  );

  const fallbackPage = useMemo(() => {
    return {
      identifier: 'suggestions-table',
      name: schema?.title || 'Suggestions',
      dataEndpoint: endpointIdentifier
        ? {
            identifier: endpointIdentifier,
          }
        : undefined,
    } as Schemas.CpaPage;
  }, [endpointIdentifier]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchText = useCallback(
    _.debounce((v: string) => {
      scrollableContentRef.current?.setSearchText(v);
    }, 500),
    []
  );

  const windowBlurred = useRef(false);
  const focusHandlingDisabled = useTempFlag(600);
  const onBoxFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (focusHandlingDisabled.current || windowBlurred.current) {
        if (focusHandlingDisabled.current) {
          event.target.blur();
        }
        focusHandlingDisabled.current = false;
        windowBlurred.current = false;
        return;
      }

      // Made not to load all data from mounting form
      loadSuggestionEntities({ top: 10 }, { overwriteExistingData: true });

      showCallout();
    },
    [focusHandlingDisabled, loadSuggestionEntities, showCallout]
  );

  const onBoxBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (focusHandlingDisabled.current) {
        return;
      }

      if (event.target === document.activeElement) {
        windowBlurred.current = true;
      }
    },
    [focusHandlingDisabled]
  );

  const onBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
      userTypingRef.current = true;
      setSearchText(inputValue);

      onChange(inputValue ? [[inputValue, inputValue]] : []);

      const underlyingRootTable = scrollableContentRef.current?.tableRef?.current as TableComponentType | undefined;
      underlyingRootTable?.selection?.setAllSelected?.(false);

      if (!isCalloutVisible) {
        showCallout();
      }
    },
    [setSearchText, isCalloutVisible, onChange, showCallout]
  );

  const handleHide = useCallback(() => {
    focusHandlingDisabled.current = true;
    hideCallout();
  }, [focusHandlingDisabled, hideCallout]);

  const handleItemsSelect = useCallback(
    (items: IDataItem[], offloadedSelectedKeys?: string[]) => {
      const clearUserInput: boolean = userTypingRef.current;

      if (items.length) {
        userTypingRef.current = false;
      }
      if (userTypingRef.current) return;

      if (multiselect) {
        onChange(
          items
            .map((item: IDataItem) => {
              if (!item.identifier) {
                return null;
              }

              return [JSON.parse(item.identifier), !!item._id || item._id === (false as unknown) ? item._id : item.identifier];
            })
            .concat(
              clearUserInput
                ? []
                : offloadedSelectedKeys?.map((offloadedSelectedKey) => [JSON.parse(offloadedSelectedKey), offloadedSelectedKey]) || []
            )
            .filter(Boolean) as [string, string][]
        );
      }
    },
    [multiselect, onChange]
  );

  const handleItemClick = useCallback(
    (item: IDataItem, selection: Selection<IObjectWithKey & IDataItem>) => {
      if (!item.identifier) {
        return;
      }

      if (multiselect) {
        // Select item by clicking on it
        selection.toggleKeySelected(selection.getKey(item));
      } else {
        onChange([[JSON.parse(item.identifier), item._id?.toString() || item.identifier]]);

        // We allow only one item, so we can close the callout
        handleHide();
        inputFieldDivRef.current?.blur();
      }
    },
    [onChange, handleHide, multiselect]
  );

  const handleKeyDown = useCallback(() => {
    /* Need to keep it empty to prevent the callout from being closed by escape */
  }, []);

  const tableData: IGenericComponentData = useMemo(
    (): IGenericComponentData => ({
      items: suggestionItems,
      schema: schema,
      isFetching: isFetching,
      totalItems: totalItems,
      page: matchedPage
        ? ({
            ...matchedPage,
            groupPropertyJsonPath: undefined,
            spanColumn: undefined,
            customRowTemplate: undefined,
          } as Schemas.CpaPage)
        : fallbackPage,
    }),
    [fallbackPage, isFetching, matchedPage, suggestionItems, totalItems]
  );

  const selectedKeys: string[] = useMemo(() => value?.map(([v]) => JSON.stringify(v)) || [], [value]);

  const calloutContent = useCallback(() => {
    if (isFetching) {
      return (
        <div style={{ height: 120 }}>
          <LoadingArea loading={true}></LoadingArea>
        </div>
      );
    }
    if (!schema) {
      return null;
    }

    return (
      <aside>
        <div style={{ padding: 0, zIndex: 1000 }} className={darkMode ? styles.suggestionsDark : styles.suggestions}>
          <ScrollingContent
            tableKey={`${TableKeyPrefix.SearchSuggestions}.${dataPath}.${dataUrl}`}
            ref={scrollableContentRef}
            hideHeader={true}
            hideActions={true}
            hideSelectedLabel={true}
            disableDoubleClick={true}
            selectionMode={multiselect ? SelectionMode.multiple : SelectionMode.none}
            hideFilter={true}
            isWidget={true}
            pageSize={10}
            loadItems={loadSuggestionEntities}
            hiddenInTable={hiddenColumns}
            onItemClick={handleItemClick}
            onItemsSelect={handleItemsSelect}
            disabledManagedColumnsWidth={true}
            isODataSupportedByEndpoint={isODataSupportedByEndpoint}
            data={tableData}
            disableDragDrop={true}
            disableAddButtonInTable={true}
            disableItemSharing={true}
            forceKeySelection={selectedKeys}
            tableProps={searchSuggestionsTableProps}
          />
        </div>
      </aside>
    );
  }, [
    isFetching,
    selectedKeys,
    dataPath,
    dataUrl,
    darkMode,
    multiselect,
    loadSuggestionEntities,
    handleItemClick,
    handleItemsSelect,
    isODataSupportedByEndpoint,
    tableData,
  ]);

  const input = useCallback(
    () => (
      <div
        ref={inputFieldDivRef}
        className={classNames({
          [styles.input]: true,
          [styles.inputWithBadge]: value && value.length > 1,
          [styles.inputWithLargeBadge]: value && value.length > 10,
          [styles.inputWithCalloutVisible]: isCalloutVisible,
        })}
      >
        <SearchBox
          disabled={disabled}
          autoComplete={'off'}
          spellCheck={false}
          value={`${value?.[0]?.[1] ?? ''}`}
          onChange={onBoxChange}
          iconProps={searchBoxIcon}
          onFocus={onBoxFocus}
          onBlur={onBoxBlur}
          disableAnimation={true}
          onEscape={handleKeyDown}
          title={label}
          placeholder={placeholder}
        />

        {!!value && value.length > 1 && (
          <div
            className={classNames({
              [styles.inputBadge]: true,
              [styles.inputBadgeDark]: darkMode,
            })}
          >{`+${value.length - 1}`}</div>
        )}
      </div>
    ),
    [isCalloutVisible, disabled, value, darkMode, onBoxChange, onBoxFocus, onBoxBlur, handleKeyDown, label, placeholder]
  );

  const calloutProps = useMemo(
    () => ({
      hidden: !isCalloutVisible || items.length === 0,
      target: inputFieldDivRef as unknown as React.RefObject<Element>,
      isBeakVisible: false,
      gapSpace: 2,
      directionalHint: DirectionalHint.bottomRightEdge,
      onDismiss: handleHide,
      setInitialFocus: false,
      preventDismissOnScroll: isSticky,
      calloutWidth: inputFieldDivRef.current?.getBoundingClientRect().width,
      shouldUpdateWhenHidden: true,
      className: styles.callout,
      calloutMinWidth: 300,
    }),
    [isCalloutVisible, items.length, handleHide, isSticky]
  );

  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.wrapperDark]: darkMode,
        [styles.wrapperNoBorder]: noBorder,
      })}
    >
      <DrawerOrCallout
        title={t('common.suggestion')}
        hintText={t('common.suggestionHint')}
        renderBody={calloutContent}
        renderInput={input}
        calloutProps={calloutProps}
        isOpened={isCalloutVisible}
        onDrawerClose={handleHide}
      />
    </div>
  );
};

export default SearchSuggestions;
