import { Filter, Document } from 'mongodb';
import {
  getFilterValuesMap,
  parseFilterMessage,
  IFacetFilterFieldItemData,
  FacetFilterPropertyValue,
  IFacetFilterField,
  getSchemaPathsMeta,
} from '@cp/base-utils';
import { IJSONSchema, IPathMeta, Schemas } from '@cp/base-types';
import { axiosDictionary, executeFacetFilters } from '@cpa/base-core/api';

export interface IFacetFilterData {
  key: string;
  property: IPathMeta;
  propertyJsonPath: string;
  title?: string;
  hasAccess: boolean;
  items: IFacetFilterFieldItemData[];
  uncheckedItems: IFacetFilterFieldItemData[];
  selectedItems?: FacetFilterPropertyValue[];
  totalItemsCount: number;
  maxItemsCount: number;
  areMoreItemsLoading: boolean;
  areAllItemsLoaded: boolean;
  areUnselectedItemsShown: boolean;
}

const defaultFacetItemsCount = 10;

export async function getFacetFilters(
  page: Schemas.CpaPage,
  schema: IJSONSchema,
  filter: string,
  fields: IFacetFilterField[],
  dataUrlMongoFilter?: Filter<Document>,
  previousResults?: IFacetFilterData[]
): Promise<{
  facetFilters: IFacetFilterData[];
  fields: IFacetFilterField[];
}> {
  const dataUrlMongoFilterString = JSON.stringify(dataUrlMongoFilter, (key: string, value: unknown): unknown => {
    if (value instanceof RegExp) {
      return { $regex: value.source, $options: value.flags };
    }

    return value;
  });

  try {
    const response = await executeFacetFilters(axiosDictionary.appDataService, page.cpTypeUrl!, {
      filter,
      dataUrlMongoFilter: dataUrlMongoFilterString,
      cpaPageIdentifier: page.identifier!,
      fieldsString: JSON.stringify(fields),
    });

    const responseEntities = response?.facetFilters;
    fields = response?.fields;

    const allPaths = fields.map((field) => field.propertyJsonPath);
    const allPathsMeta = getSchemaPathsMeta(schema, allPaths);

    const resultFacetFilters: IFacetFilterData[] = [];

    for (const responseEntity of responseEntities) {
      const previousResult = previousResults?.find((previousResult) => previousResult.propertyJsonPath === responseEntity.propertyJsonPath);

      const facetFilter: IFacetFilterData = {
        key: responseEntity.key,
        property: allPathsMeta.filter((pathMeta) => pathMeta.propertyJsonPath === responseEntity.propertyJsonPath)[0],
        propertyJsonPath: responseEntity.propertyJsonPath,
        hasAccess: previousResult?.hasAccess ?? true,
        items: [],
        uncheckedItems: [],
        totalItemsCount: previousResult?.totalItemsCount ?? 0,
        maxItemsCount: previousResult?.maxItemsCount ?? defaultFacetItemsCount,
        areAllItemsLoaded: previousResult?.areAllItemsLoaded ?? false,
        areMoreItemsLoading: false,
        areUnselectedItemsShown: false,
      };

      facetFilter.hasAccess = responseEntity.hasAccess;

      facetFilter.areAllItemsLoaded = responseEntity.items.length < facetFilter.maxItemsCount;

      facetFilter.items = responseEntity.items;
      facetFilter.items = facetFilter.items.filter((item) => !!item._id);
      facetFilter.items = facetFilter.items.sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;

        if (previousResult?.items) {
          const aIndex = previousResult.items.findIndex((item) => item._id === a._id);
          const bIndex = previousResult.items.findIndex((item) => item._id === b._id);

          if (aIndex > -1 && bIndex > -1) {
            return aIndex - bIndex;
          }

          if (aIndex > -1) {
            return -1;
          }

          if (bIndex > -1) {
            return 1;
          }
        }

        if (a.title || b.title) {
          return a.title?.localeCompare(b.title || '') || 0;
        }

        if (a._id && b._id && typeof a._id === 'string' && typeof b._id === 'string') {
          return a._id?.localeCompare(b._id || '') || 0;
        }

        return 0;
      });

      if (facetFilter.totalItemsCount && facetFilter.items.length === facetFilter.totalItemsCount) {
        facetFilter.areAllItemsLoaded = true;
      }

      const enumMapping = (facetFilter.property as IJSONSchema).enumMapping;

      if (facetFilter.items && enumMapping) {
        for (const item of facetFilter.items) {
          if (!item.title) {
            item.title = enumMapping[item._id as string];
          }
        }
      }

      resultFacetFilters.push(facetFilter);
    }

    if (page.promotedFilters?.length) {
      for (const facetFilter of resultFacetFilters) {
        const hotProperty = page.promotedFilters.find((hotProperty) => hotProperty.propertyJsonPath === facetFilter.propertyJsonPath);

        if (hotProperty?.name) {
          facetFilter.title = hotProperty.name;
        }
      }
    }

    fillSelectedItems(resultFacetFilters, filter, previousResults);

    return {
      facetFilters: resultFacetFilters,
      fields,
    };
  } catch (e) {
    console.error(e);
    return {
      facetFilters: [],
      fields: [],
    };
  }
}

function fillSelectedItems(facetFilters: IFacetFilterData[], filterValue: string, previousResults?: IFacetFilterData[]): void {
  const filterValuesMapRecord = getFilterValuesMap(parseFilterMessage(filterValue || ''));
  const filterValuesMap: { propertyJsonPath: string; values: FacetFilterPropertyValue[] }[] = [];

  for (const [propertyJsonPath, values] of Object.entries(filterValuesMapRecord)) {
    const selectedValues: string[] = [];

    for (const value of values) {
      if (Array.isArray(value)) {
        selectedValues.push(value[0] as string);
      } else {
        selectedValues.push(value as string);
      }
    }

    if (selectedValues.length) {
      filterValuesMap.push({ propertyJsonPath, values: selectedValues });
    }
  }

  for (const facetFilter of facetFilters) {
    const filterValuesMapItem = filterValuesMap.find((s) => s.propertyJsonPath === facetFilter.propertyJsonPath);

    facetFilter.selectedItems = filterValuesMapItem?.values ?? [];

    if (facetFilter.selectedItems.length) {
      const previousResult = previousResults?.find((previousResult) => previousResult.propertyJsonPath === facetFilter.propertyJsonPath);

      if (previousResult?.items) {
        const items: IFacetFilterFieldItemData[] = [];
        const uncheckedItems: IFacetFilterFieldItemData[] = [];

        if (!previousResult.areUnselectedItemsShown) {
          for (const item of facetFilter.items) {
            if (facetFilter.selectedItems.includes(item._id)) {
              items.push(item);
            }
          }

          for (const item of previousResult.items) {
            const currentItem = items.find((facetFilterItem) => facetFilterItem._id === item._id);

            if (!currentItem) {
              uncheckedItems.push({
                ...item,
                count: 0,
              });
            }
          }

          for (const item of previousResult.uncheckedItems) {
            const currentItem = items.find((facetFilterItem) => facetFilterItem._id === item._id);

            if (!currentItem) {
              uncheckedItems.push({
                ...item,
                count: 0,
              });
            }
          }

          facetFilter.items = items;
          facetFilter.uncheckedItems = uncheckedItems;
          facetFilter.areAllItemsLoaded = true;
        } else {
          for (const item of previousResult.items) {
            const currentItem = facetFilter.items.find((facetFilterItem) => facetFilterItem._id === item._id);

            if (currentItem) {
              items.push(currentItem);
            } else {
              items.push({
                ...item,
                count: 0,
              });
            }
          }

          facetFilter.items = items;
          facetFilter.uncheckedItems = [];
          facetFilter.areAllItemsLoaded = true;
          facetFilter.areUnselectedItemsShown = true;
        }
      }
    } else {
      facetFilter.uncheckedItems = [];
    }
  }
}
