import { registerIcons } from '@fluentui/react';
import React from 'react';

import AddRinger from './icons/FabricIconExts/AddRinger.svg';
import AdminCenter from './icons/FabricIconExts/AdminCenter.svg';
import Customer from './icons/FabricIconExts/Customer.svg';
import EmployeePortal from './icons/FabricIconExts/EmployeePortal.svg';
import EmployeePortalMulticolor from './icons/FabricIconExts/EmployeePortalMulticolor.svg';
import EmployeePortalMulticolorNeg from './icons/FabricIconExts/EmployeePortalMulticolorNeg.svg';
import EmployerBranding from './icons/FabricIconExts/EmployerBranding.svg';
import GenderFemale from './icons/FabricIconExts/GenderFemale.svg';
import GenderMale from './icons/FabricIconExts/GenderMale.svg';
import GenderThird from './icons/FabricIconExts/GenderThird.svg';
import SkillLevel0 from './icons/FabricIconExts/SkillLevel0.svg';
import SkillLevel1 from './icons/FabricIconExts/SkillLevel1.svg';
import SkillLevel2 from './icons/FabricIconExts/SkillLevel2.svg';
import SkillLevel3 from './icons/FabricIconExts/SkillLevel3.svg';
import SkillLevel4 from './icons/FabricIconExts/SkillLevel4.svg';

type CpIconBase = { name: string };
type CpFileIcon = { file: string; pickId?: string };
type CpSvgPathIcon = { svgPath: string };

type CpIcon = CpIconBase & (CpFileIcon | CpSvgPathIcon);

// Register new CP icons here:
// Before you register, keep in mind that @cpa/ui-components already contains a set of icons - search there for 'COSMO SVG Icons'
// To register, please note that:
// - Icons have to have the dimensions 2048 x 2048
// - Consider the structure of an existing SVG file - SVG inside SVG with ID 'Default' is default structure
// - ID of symbol, glyph or SVG inside file can be used via 'pickId'
// - All names are getting prefixed with 'Cp' automatically here in - means: 'MyIcon' becomes 'CpMyIcon'.
const cpIcons: Array<CpIcon> = [
  {
    file: AddRinger,
    name: 'AddRinger',
  },
  {
    file: Customer,
    name: 'Customer',
  },
  {
    file: AdminCenter,
    name: 'AdminCenter',
  },
  {
    file: SkillLevel0,
    name: 'SkillLevel0',
  },
  {
    file: SkillLevel1,
    name: 'SkillLevel1',
  },
  {
    file: SkillLevel2,
    name: 'SkillLevel2',
  },
  {
    file: SkillLevel3,
    name: 'SkillLevel3',
  },
  {
    file: SkillLevel4,
    name: 'SkillLevel4',
  },
  {
    file: GenderFemale,
    name: 'GenderFemale',
  },
  {
    file: GenderMale,
    name: 'GenderMale',
  },
  {
    file: GenderThird,
    name: 'GenderThird',
  },
  {
    file: EmployeePortal,
    name: 'EmployeePortal',
  },
  {
    file: EmployeePortalMulticolor,
    name: 'EmployeePortalMulticolor',
  },
  {
    file: EmployeePortalMulticolorNeg,
    name: 'EmployeePortalMulticolorNeg',
  },
  {
    file: EmployerBranding,
    name: 'EmployerBranding',
  },
];

// Initialize CP icons
export const initializeCpIcons = (): void => {
  const cpIcons2 = cpIcons.map((cpIcon) => {
    const cpIconFriendlyNamePrefix = 'Cp';
    if ('file' in cpIcon) {
      const useHref = `${cpIcon.file}#${cpIcon.pickId ? cpIcon.pickId : 'Default'}`; // Anchor to (or ID of) symbol, glyph or SVG inside file
      return {
        [`${cpIconFriendlyNamePrefix}${cpIcon.name}`]: React.createElement(
          'span',
          { style: { display: 'block', height: '1em', lineHeight: '1em' } },
          React.createElement(
            'svg',
            {
              xmlns: 'http://www.w3.org/2000/svg',
              style: { width: 'auto', height: '100%', aspectRatio: '1 / 1', display: 'block', maxWidth: '100%' },
            },
            React.createElement('use', { href: useHref, xlinkHref: useHref })
          )
        ),
      };
    } else if ('svgPath' in cpIcon) {
      return {
        [`${cpIconFriendlyNamePrefix}${cpIcon.name}`]: React.createElement(
          'span',
          { style: { display: 'block', height: '1em', lineHeight: '1em' } },
          React.createElement(
            'svg',
            {
              xmlns: 'http://www.w3.org/2000/svg',
              style: { width: 'auto', height: '100%', aspectRatio: '1 / 1', display: 'block', maxWidth: '100%' },
              viewBox: '0 0 2048 2048',
            },
            React.createElement('path', {
              fill: 'currentColor',
              d: cpIcon.svgPath,
            })
          )
        ),
      };
    } else {
      throw new Error(`Either 'file' or 'svgPath' needs to be specified in ${cpIcon}`);
    }
  });
  const iconsObj = {};
  for (const cpIcon of cpIcons2) {
    Object.assign(iconsObj, cpIcon);
  }

  registerIcons({ icons: iconsObj });

  console.debug('CP icons loaded', iconsObj);
};
