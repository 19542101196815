import { i18n } from '../../app';

export const formatDate = (date: string | Date, locale: string): string =>
  new Date(date).toLocaleDateString(locale, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });

export const formatDateTime = (date: string | Date, locale: string, withSeconds: boolean = true): string =>
  new Date(date).toLocaleDateString(locale, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: withSeconds ? '2-digit' : undefined,
  });

export const formatDateTimeLongYear = (date: string | Date, locale: string, withSeconds: boolean = true): string =>
  new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: withSeconds ? '2-digit' : undefined,
  });

export const formatTimespan = (date: string | Date): string => {
  const parsedDate = new Date(date);

  const totalMilliseconds = Number(parsedDate);
  const milliseconds = Math.floor(totalMilliseconds) % 1000;

  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  const seconds = totalSeconds % 60;

  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;

  const totalHours = Math.floor(totalMinutes / 60);
  const hours = totalHours % 24;

  const totalDays = Math.floor(totalHours / 24);

  // * Return in format 3d 5h 20m 36s 255ms
  return [
    totalDays !== 0 && `${totalDays}d`,
    hours !== 0 && `${hours}h`,
    minutes !== 0 && `${minutes}m`,
    seconds !== 0 && `${seconds}s`,
    milliseconds !== 0 && `${milliseconds}ms`,
  ]
    .filter(Boolean)
    .join(' ');
};

export const formatStringOrNumber = (value: number | string | null | undefined, locale: string): string => {
  if (value === null || value === undefined) {
    return '-';
  }

  if (typeof value === 'number' || typeof value === 'string') {
    if (!value?.toString()?.includes('.')) {
      return value?.toLocaleString(locale, {
        maximumFractionDigits: 0,
      });
    }

    return value?.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return value;
};

export const floorNumber = (value: number, locale: string, digits: number): string => {
  return value.toLocaleString(locale, { maximumFractionDigits: digits });
};

export const replaceInternalLinks = (text: string): string => {
  const found = text.matchAll(/\[\[(.*?)]]/gm);
  if (!found) return text;
  let result: string = text;
  for (const link of Array.from(found)) {
    const name = link[1];
    result = result.replace(link[0], `[${name}](https://schema.org/${name})`);
  }
  return result;
};

export const formatCurrency = (value: number, currencyCode: string): string => {
  try {
    const formatter = new Intl.NumberFormat(i18n.language, { currency: currencyCode, style: 'currency' });
    return formatter.format(value);
  } catch (e) {
    console.error(e);
    return `${value} ${currencyCode}`;
  }
};
