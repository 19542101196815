import { createFilter, ODataParamOrderField } from '@cp/base-odata';
import { Schemas } from '@cp/base-types';
import { formatEntries, ODataPropsFilter } from '@cp/base-utils';
import { Icon } from '@fluentui/react';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import urljoin from 'url-join';

import { getParsedFilter } from '../../../../../../screens/GenericScreen/utils';

import styles from './FillCard.module.scss';

interface IFillCardProps {
  remainingItems: number;
  page: Schemas.CpaPage;
  onMoreClick?: () => void;
}

const FillCard: React.FC<IFillCardProps> = ({ remainingItems, page, onMoreClick }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (onMoreClick) {
      onMoreClick();
      return;
    }
    let pagePath = page.widgetRedirectPath || page.path;

    if (!pagePath) {
      return;
    }

    if (page.dataUrl && page.dataUrl.includes('$filter')) {
      const filterString = new URLSearchParams(new URL(page.dataUrl, window.location.origin).search).get('$filter');
      const filter = createFilter(filterString!);
      const filterEntities = formatEntries(filter) as unknown as ODataPropsFilter;
      const [parsedFilter] = getParsedFilter(filterEntities);

      pagePath = urljoin(pagePath, `?filter=` + encodeURIComponent(JSON.stringify(parsedFilter)));
    }

    dispatch(push(pagePath));
  }, [page, dispatch]);

  return (
    <div className={styles.fillCard} onClick={onClick}>
      <div className={styles.inner}>
        <Icon iconName={'DocumentSet'} styles={{ root: { fontSize: 40 } }} />
        <p>{t('common.moreItemsOnPage', { num: remainingItems })}</p>
        <p>{t('common.moreItemsAction')}</p>
      </div>
    </div>
  );
};

export default FillCard;
