const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/MarketplaceProductType',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'productTypes', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2FmarketplaceProductType%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2Foffers%2FofferException%2FsalesChannelMarketplace%2FmarketplaceProductType%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Marketplace Product Type',
    'properties': {
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const MarketplaceProductTypeSchema = Schema;
