const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpaDataEndpoint',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaDataEndpoint' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa?$filter=dataServiceEndpoint%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa?$filter=metaServiceEndpoint%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=dataEndpoint%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa Data Endpoint',
    'properties': {
        'dataType': {
            'title': 'Data Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPageDataType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPageDataType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/dataType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/ApiGateway',
                'http://platform.cosmoconsult.com/ontology/DataService',
                'http://platform.cosmoconsult.com/ontology/loop/LoopApi',
                'http://platform.cosmoconsult.com/ontology/loop/LoopApiSi',
            ],
            'enumNames': ['Api Gateway', 'Data Service', 'COSMO Loop API', 'COSMO Loop API (COSMO Consult SI)'],
        },
        'endpoints': {
            'title': 'Endpoints',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaEndpointEnvironments',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaEndpointEnvironments' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/endpoints', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'developmentEndpoint': {
                    'title': 'Development Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/developmentEndpoint', 'rel': 'canonical' }],
                },
                'localEndpoint': {
                    'title': 'Local Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/localEndpoint', 'rel': 'canonical' }],
                },
                'productionEndpoint': {
                    'title': 'Production Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/productionEndpoint', 'rel': 'canonical' }],
                },
                'stagingEndpoint': {
                    'title': 'Staging Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/stagingEndpoint', 'rel': 'canonical' }],
                },
            },
            'required': ['developmentEndpoint', 'localEndpoint', 'productionEndpoint', 'stagingEndpoint'],
        },
        'withCredentials': {
            'title': 'With Credentials',
            'type': 'boolean',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/withCredentials', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'dataType'],
};
export const CpaDataEndpointSchema = Schema;
