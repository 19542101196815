import * as _ from 'lodash';
import { IJSONSchema, IPathMeta, IPathMetaItem, Schemas } from '@cp/base-types';
import { buildODataQuery, formatEntries } from '@cp/base-utils';
import { RouterLocation } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';

import { IDataItem } from '../../types';

export const filterPaths = (items: IPathMetaItem[]): IPathMetaItem[] => {
  const anyOfIndex = items.findIndex((item) => !!item.anyOf);
  if (anyOfIndex !== -1 && items[anyOfIndex + 1]?.shortcutOption) {
    return items.slice(0, anyOfIndex);
  }
  return items;
};

export function getPathTitle(path: IPathMeta, removeSameStrings: boolean): string {
  let filteredPaths = filterPaths(path.items);

  // Sometimes we got ugly results such as "Something - Something - Field", this code removes duplicates.
  if (removeSameStrings) {
    filteredPaths = _.uniqBy(filteredPaths, (d) => `${d.title}`.toLowerCase());
  }

  return filteredPaths
    .filter((p) => !!p.title)
    .map((p) => p.title)
    .slice(1)
    .join(' - ');
}

export function generateParentingExternalDataQuery(
  schema: IJSONSchema | null,
  parentItem: IDataItem
): {
  $filter?: string | null;
} {
  if (!schema || !schema.cp_parentPropertyJsonPath || !parentItem || (!parentItem.identifier && !parentItem.__identifier)) {
    return {};
  }

  return {
    $filter: new URLSearchParams(
      buildODataQuery({
        filter: formatEntries({
          [schema.cp_parentPropertyJsonPath]: parentItem.identifier || parentItem.__identifier,
        }),
      })
    ).get('$filter'),
  };
}

export const getLocationForBreadcrumb = (
  page: Schemas.CpaPage,
  schema: IJSONSchema,
  breadcrumbsStructure: IDataItem[],
  itemIndex: number,
  currentLocation: RouterLocation<unknown>
): LocationDescriptorObject => {
  const newStructure = breadcrumbsStructure.slice(0, itemIndex + 1);
  const newParentItem = newStructure[newStructure.length - 1];
  const previousState = currentLocation.state && typeof currentLocation.state === 'object' ? currentLocation.state : {};

  return {
    pathname: page.path?.replace?.('/:id', ''),
    state: {
      ...previousState,
      breadcrumbsStructure: newStructure,
      externalDataQuery: generateParentingExternalDataQuery(schema, newParentItem),
    },
  };
};
