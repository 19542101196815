import { EnvironmentType } from '@cp/base-types';
export const getRelevantEndpoint = (endpoints, env) => {
    let endpointName;
    switch (env) {
        case EnvironmentType.Local:
            endpointName = 'localEndpoint';
            break;
        case EnvironmentType.Dev:
            endpointName = 'developmentEndpoint';
            break;
        case EnvironmentType.Staging:
            endpointName = 'stagingEndpoint';
            break;
        case EnvironmentType.Prod:
            endpointName = 'productionEndpoint';
            break;
        default:
            endpointName = 'productionEndpoint';
            break;
    }
    if (!endpointName)
        return undefined;
    return endpoints[endpointName];
};
