import { BaseError } from './';
export class ArgumentNullError extends BaseError {
    constructor(msg) {
        super(msg ? `Argument null or undefined. ${msg}` : 'Argument null or undefined.');
        this.name = this.constructor.name;
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        }
        else {
            this.__proto__ = actualProto;
        }
        Error.captureStackTrace(this, this.constructor);
    }
}
