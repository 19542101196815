import { IJSONSchema } from '@cp/base-types';
import { IDataItem } from '@cpa/base-core/types';
import { IGlobalState } from '@cpa/base-core/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatStringOrNumber, getSafeString } from '@cpa/base-core/helpers';
import * as _ from 'lodash';
import { isRelationSchema } from '@cp/base-utils';

import styles from './ObjectPreviewContent.module.scss';

interface IObjectPreviewContentProps {
  // content inside table line
  value: IDataItem | IDataItem[];
  locale: string;
  schema: IJSONSchema | null;
}

const ObjectPreviewContent: React.FC<IObjectPreviewContentProps> = ({ value, locale, schema }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  if (schema && isRelationSchema(schema) && value && !Array.isArray(value)) {
    return <span>{value.name || value.identifier || '...'}</span>;
  }

  const values = Object.values(value);
  if (!values.map((v) => v?.toString()).some((v) => v?.includes('[object Object]'))) {
    // If can be converted to text - do it
    const inner = values
      .map((v: string | number) => {
        return getSafeString(formatStringOrNumber(v, locale), true);
      })
      .join(' ');
    return <span className={darkMode ? styles.showMoreDark : styles.showMore}>{inner}</span>;
  }

  return <span>...</span>;
};

export default React.memo(ObjectPreviewContent, _.isEqual);
