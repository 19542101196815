import { IDataItem, IGenericComponentProps, ITableRowProps } from '@cpa/base-core/types';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { IJSONSchema, Schemas } from '@cp/base-types';
import * as _ from 'lodash';

export function addAsComponent(
  item: IDataItem,
  onAddClick?: ITableRowProps['onAddClick'],
  onEdit?: IGenericComponentProps['onEditRow'],
  skipReload?: boolean
): void {
  onAddClick?.(async (defaultAddHandler, childItem, reloadCurrentItems) => {
    const rootSolution = resolveRootSolution(item as Schemas.Solution & IDataItem);
    if (rootSolution.identifier && rootSolution.identifier !== childItem.identifier) {
      childItem = cloneDeepWithMetadata(childItem);
      (childItem as Schemas.Solution).parentSolution = { identifier: rootSolution.identifier };
    }

    const addResult = (await defaultAddHandler?.(childItem)) as IDataItem;
    if (!addResult || !addResult.identifier) {
      return addResult;
    }
    const parentItem = item.__originalItem || item;
    const updatedItem = cloneDeepWithMetadata(parentItem);
    updatedItem.solutionTypeDetails = updatedItem.solutionTypeDetails || [];
    let componentsSolutionTypeDetail = (updatedItem.solutionTypeDetails as IDataItem[]).find(
      (solutionTypeDetail) => solutionTypeDetail?._type === 'http://platform.cosmoconsult.com/ontology/Components'
    );
    if (!componentsSolutionTypeDetail) {
      componentsSolutionTypeDetail = { _type: 'http://platform.cosmoconsult.com/ontology/Components' };
      (updatedItem.solutionTypeDetails as IDataItem[]).push(componentsSolutionTypeDetail);
    }

    componentsSolutionTypeDetail.components = componentsSolutionTypeDetail.components || [];
    (componentsSolutionTypeDetail.components as IDataItem[]).push({
      solution: { identifier: addResult.identifier },
      quantity: {
        unitCode: 'C62',
        value: 1,
      },
    });

    await onEdit?.(updatedItem, parentItem);

    if (!skipReload) {
      await reloadCurrentItems();
    }

    return addResult;
  });
}

export function resolveRootSolution(solution: Schemas.Solution & IDataItem): Schemas.Solution & IDataItem {
  const parent = (solution.__originalItem?.__parentItem || solution.__parentItem) as Schemas.Solution & IDataItem;
  return parent ? resolveRootSolution(parent) : solution;
}

export function checkIfSolutionCreatedInRootContext(item: IDataItem, rootItem: IDataItem, schema: IJSONSchema): boolean {
  if (item.identifier === rootItem.identifier) {
    return true;
  }

  if (!schema.cp_parentPropertyJsonPath) {
    return false;
  }
  const parentItemIdentifier = _.get(item, schema.cp_parentPropertyJsonPath);
  if (parentItemIdentifier === rootItem.identifier) {
    return true;
  }

  if (!item.__parentItem) {
    return false;
  }
  const contextParentItemIdentifier = item.__parentItem.__originalItem?.identifier || item.__parentItem.identifier;
  if (!parentItemIdentifier || !contextParentItemIdentifier || parentItemIdentifier !== contextParentItemIdentifier) {
    return false;
  }

  return checkIfSolutionCreatedInRootContext(item.__parentItem, rootItem, schema);
}
