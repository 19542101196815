import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@fluentui/react';

import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import styles from './ShowFacetFiltersButton.module.scss';

interface IShowFacetFiltersButtonProps {
  isMobileDevice: boolean;
  isWidget?: boolean;
  onClick: () => void;
}

const ShowFacetFiltersButton: React.FC<IShowFacetFiltersButtonProps> = ({ isMobileDevice, isWidget, onClick }) => {
  const [t] = useTranslation();

  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        [styles.wrapperMobile]: isMobileDevice,
        [styles.wrapperOnWidget]: isWidget,
      })}
    >
      <HoverTooltip content={t('facetFilters.show')}>
        <div className={styles.iconContainer} onClick={onClick}>
          <Icon className={styles.icon} iconName="PageListFilter" />
        </div>
      </HoverTooltip>
    </div>
  );
};

export default React.memo(ShowFacetFiltersButton);
