import React, { useCallback, useMemo } from 'react';
import { Registry } from '@rjsf/core';
import { IJSONSchema } from '@cp/base-types';

import OriginalGauge, { IGaugeSection } from '../../../../../../Chart/components/Gauge/Gauge';
import TitleField, { TitleFieldType } from '../../../../TitleField/TitleField';
import DescriptionField from '../../../../DescriptionField/DescriptionField';

import styles from './Gauge.module.scss';

interface IGaugeProps {
  label?: string;
  schema: IJSONSchema;
  registry: Registry;
  formData: {
    sections: IGaugeSection[];
    value: number;
  };
}

const Gauge: React.FC<IGaugeProps> = ({ label, schema, registry, formData }) => {
  const processedSections = useMemo(() => {
    if (!formData?.sections) return [];
    return formData.sections.map((section): IGaugeSection => {
      const range = 180 * (section.range / 100);
      return {
        ...section,
        range,
      };
    }) as IGaugeSection[];
  }, [formData]);

  const totalSectionsValue = useMemo(() => {
    if (!formData?.sections?.length) return 0;
    return formData.sections.reduce((acc, section) => (acc += section.range), 0);
  }, [formData]);

  if (!processedSections.length) return null;

  if (totalSectionsValue !== 100) {
    console.error('Unable to render chart. Total sections value is not equal to 100');
    return null;
  }

  return (
    <>
      <TitleField title={label || schema.title} type={TitleFieldType.Primitive} registry={registry} required={false} />
      <DescriptionField description={schema.description || ''} detailed />
      <div className={styles.chartWrapper}>
        <OriginalGauge sections={processedSections} value={formData.value}></OriginalGauge>
      </div>
    </>
  );
};

export default Gauge;
