import * as _ from 'lodash';
import { IDataItem } from '@cpa/base-core/types';
import { IJSONSchema } from '@cp/base-types';
import { getAllSchemaPaths } from '@cp/base-utils';

import { htmlDiff } from './html';

export interface IDiffSource {
  nameA?: string;
  nameB?: string;

  itemA: IDataItem;
  itemB: IDataItem;

  schema: IJSONSchema | null;
}

export interface IPropertyDiff {
  dataPath: string;
  propertyJsonPath: string;
  oldValue: unknown;
  newValue: unknown;
  propertySchema: IJSONSchema;
  parentPropertySchema?: IJSONSchema;
  level: number;
  diffFrom?: string;
  diffTo?: string;
  isChanged?: boolean;
}

const getPropertiesWithSchemas = (
  pageSchema: IJSONSchema
): { propertyJsonPath: string; propertySchema: IJSONSchema; parentPropertySchema?: IJSONSchema }[] => {
  const results: { propertyJsonPath: string; propertySchema: IJSONSchema; parentPropertySchema?: IJSONSchema }[] = [];

  const schemaPaths = getAllSchemaPaths(pageSchema);

  for (const schemaPath of schemaPaths) {
    if (!schemaPath.propertySchema) {
      continue;
    }

    results.push({
      propertyJsonPath: schemaPath.propertyJsonPath,
      propertySchema: schemaPath.propertySchema,
      parentPropertySchema: schemaPath.items.length > 1 ? schemaPath.items[schemaPath.items.length - 2].propertySchema : undefined,
    });
  }

  for (const schemaPath of schemaPaths) {
    if (!schemaPath?.items?.length) {
      continue;
    }

    let currentPath: string | undefined = undefined;

    for (const item of schemaPath.items) {
      if (!item.property) {
        continue;
      }

      currentPath = currentPath ? `${currentPath}.${item.property}` : item.property;

      if (!item.propertySchema) {
        continue;
      }

      if (!results.find((r) => r.propertyJsonPath === currentPath)) {
        results.push({
          propertyJsonPath: currentPath,
          propertySchema: item.propertySchema,
        });
      }
    }
  }

  results.sort((a, b) => a.propertyJsonPath.localeCompare(b.propertyJsonPath));

  return _.uniqBy(results, (r) => r.propertyJsonPath);
};

const getPropertiesWithValuesAndSchemas = (
  pageSchema: IJSONSchema,
  dataItem: IDataItem
): {
  dataPath: string;
  propertyJsonPath: string;
  propertySchema: IJSONSchema;
  propertyValue: unknown;
  parentPropertySchema?: IJSONSchema;
}[] => {
  const propertiesWithSchemas = getPropertiesWithSchemas(pageSchema);

  const propertiesWithValuesAndSchemas = getPropertiesWithValuesAndSchemasRecursive('', '', dataItem, propertiesWithSchemas);

  propertiesWithValuesAndSchemas.push({
    dataPath: '',
    propertyJsonPath: '',
    propertySchema: pageSchema,
    propertyValue: dataItem,
  });

  propertiesWithValuesAndSchemas.sort((a, b) => a.dataPath.localeCompare(b.dataPath));

  // No internal properties please
  const patternRegexps = Object.keys(pageSchema?.patternProperties ?? {}).map((p) => new RegExp(p));
  return propertiesWithValuesAndSchemas.filter((property) => {
    const propertyPathsSplit = property.propertyJsonPath.split('.');

    return !(
      propertyPathsSplit.length &&
      propertyPathsSplit[propertyPathsSplit.length - 1] &&
      patternRegexps.some((regexp) => regexp.test(propertyPathsSplit[propertyPathsSplit.length - 1]))
    );
  });
};

const getPropertiesWithValuesAndSchemasRecursive = (
  parentDataPath: string | undefined,
  parentJsonPropertyPath: string | undefined,
  dataItem: IDataItem,
  propertiesWithSchemas: { propertyJsonPath: string; propertySchema: IJSONSchema; parentPropertySchema?: IJSONSchema }[]
): {
  dataPath: string;
  propertyJsonPath: string;
  propertySchema: IJSONSchema;
  parentPropertySchema?: IJSONSchema;
  propertyValue: unknown;
}[] => {
  const results: {
    dataPath: string;
    propertyJsonPath: string;
    propertySchema: IJSONSchema;
    parentPropertySchema?: IJSONSchema;
    propertyValue: unknown;
  }[] = [];

  if (!dataItem) {
    return results;
  }

  if (Array.isArray(dataItem)) {
    for (const [i, item] of dataItem.entries()) {
      const dataPath = parentDataPath !== '' ? `${parentDataPath}.${i}` : `${i}`;
      const jsonPropertyPath = parentJsonPropertyPath;

      results.push(...getPropertiesWithValuesAndSchemasRecursive(dataPath, jsonPropertyPath, item, propertiesWithSchemas));
    }
  } else if (typeof dataItem === 'object') {
    for (const [key, value] of Object.entries(dataItem)) {
      const dataPath = parentDataPath !== '' ? `${parentDataPath}.${key}` : key;
      const jsonPropertyPath = parentJsonPropertyPath !== '' ? `${parentJsonPropertyPath}.${key}` : key;

      let propertiesWithSchemaItem = propertiesWithSchemas.find((p) => p.propertyJsonPath === jsonPropertyPath);
      let propertySchema = propertiesWithSchemaItem?.propertySchema;

      if (!propertySchema && parentJsonPropertyPath !== '') {
        const parentPropertySchema = propertiesWithSchemas.find((p) => p.propertyJsonPath === parentJsonPropertyPath)?.propertySchema;

        if (parentPropertySchema?.items?.properties?.[key]) {
          propertySchema = parentPropertySchema.items.properties[key];
          propertiesWithSchemaItem = {
            propertyJsonPath: jsonPropertyPath,
            propertySchema,
            parentPropertySchema,
          };
        }
      }

      if (!propertySchema) {
        continue;
      }

      results.push({
        dataPath,
        propertyJsonPath: jsonPropertyPath,
        propertySchema: propertySchema as IJSONSchema,
        parentPropertySchema: propertiesWithSchemaItem?.parentPropertySchema,
        propertyValue: value,
      });

      if (propertySchema?.items?.anyOf?.length && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const subItem = value[i] as IDataItem;

          if (!subItem || !subItem._type) {
            continue;
          }

          const subItemSchema = propertySchema.items.anyOf.find((s) => s.$id === subItem._type);

          if (!subItemSchema) {
            continue;
          }

          results.push({
            dataPath: `${dataPath}.${i}`,
            propertyJsonPath: `${jsonPropertyPath}`,
            propertySchema: subItemSchema,
            propertyValue: subItem,
          });

          results.push(
            ...getPropertiesWithValuesAndSchemasRecursive(`${dataPath}.${i}`, `${jsonPropertyPath}`, subItem as IDataItem, propertiesWithSchemas)
          );
        }
      } else if (typeof value === 'object' || Array.isArray(value)) {
        results.push(...getPropertiesWithValuesAndSchemasRecursive(dataPath, jsonPropertyPath, value as IDataItem, propertiesWithSchemas));
      }
    }
  }

  return results;
};

export const getFieldDifferences = async (pageSchema: IJSONSchema, item1: IDataItem, item2: IDataItem): Promise<IPropertyDiff[]> => {
  const results: {
    dataPath: string;
    propertyJsonPath: string;
    oldValue: unknown;
    newValue: unknown;
    propertySchema: IJSONSchema;
    parentPropertySchema?: IJSONSchema;
    level: number;
    diffFrom?: string;
    diffTo?: string;
    isChanged?: boolean;
  }[] = [];
  const propertiesWithValuesAndSchemas1 = getPropertiesWithValuesAndSchemas(pageSchema, item1);
  const propertiesWithValuesAndSchemas2 = getPropertiesWithValuesAndSchemas(pageSchema, item2);

  await new Promise((resolve) => setTimeout(resolve, 0));

  for (const property1 of propertiesWithValuesAndSchemas1) {
    const property2 = propertiesWithValuesAndSchemas2.find((p) => p.dataPath === property1.dataPath);

    results.push({
      dataPath: property1.dataPath,
      propertyJsonPath: property1.propertyJsonPath,
      oldValue: property1.propertyValue,
      newValue: property2?.propertyValue,
      propertySchema: property1.propertySchema,
      parentPropertySchema: property1.parentPropertySchema,
      level: property1.dataPath.split('.').length,
    });
  }

  for (const property2 of propertiesWithValuesAndSchemas2) {
    if (results.find((r) => r.dataPath === property2.dataPath)) {
      continue;
    }

    const property1 = propertiesWithValuesAndSchemas1.find((p) => p.dataPath === property2.dataPath);

    results.push({
      dataPath: property2.dataPath,
      propertyJsonPath: property2.propertyJsonPath,
      oldValue: property1?.propertyValue,
      newValue: property2.propertyValue,
      propertySchema: property2.propertySchema,
      parentPropertySchema: property2.parentPropertySchema,
      level: property2.dataPath.split('.').length,
    });
  }

  results.sort((a, b) => a.dataPath.localeCompare(b.dataPath));

  for (const result of results) {
    if (
      typeof result.oldValue === 'string' &&
      typeof result.newValue === 'string' &&
      result.propertySchema?.type === 'string' &&
      result.propertySchema?.format !== 'date-time'
    ) {
      const value1 = (result.oldValue as string) ?? '';
      const value2 = (result.newValue as string) ?? '';

      if (value1 === value2) {
        result.isChanged = false;

        result.diffFrom = value1;
        result.diffTo = value2;
      } else {
        result.isChanged = true;
        const diffResult = htmlDiff(value2, value1);

        result.diffFrom = diffResult.newDiffHtml;
        result.diffTo = diffResult.oldDiffHtml;
      }
    }
  }

  for (const result of results) {
    const itemIsArrayOfStrings = result.propertySchema?.type === 'array' && result.propertySchema?.items?.type === 'string';

    if (itemIsArrayOfStrings) {
      if (result.isChanged === undefined) {
        result.isChanged = !_.isEqual(result.oldValue, result.newValue);
      }
    } else {
      const value1IsArrayOrObject = result.oldValue && (Array.isArray(result.oldValue) || typeof result.oldValue === 'object');
      const value2IsArrayOrObject = result.newValue && (Array.isArray(result.newValue) || typeof result.newValue === 'object');

      if (!value1IsArrayOrObject && !value2IsArrayOrObject) {
        if (result.isChanged === undefined) {
          result.isChanged = result.oldValue !== result.newValue;
        }
      }
    }
  }

  for (const result of results) {
    const subItems =
      result.dataPath === '' ? results.filter((r) => r.dataPath !== '') : results.filter((r) => r.dataPath.startsWith(result.dataPath + '.'));

    if (subItems) {
      result.isChanged = result.isChanged || subItems.some((s) => s.isChanged);
    }
  }

  return results;
};
