import { DirectionalHint, ICalloutProps } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import FeatureCallout from '../../../FeatureCallout/FeatureCallout';

import styles from './InputLanguage.module.scss';

const CALLOUT_FEATURE_KEY = 'InputLanguage';

interface IInputLanguageProps {
  baseLanguage: { locale: string | null; icon: string | undefined };
  currentLanguage: { locale: string; icon: string | undefined };
  readonly: boolean;
  type: 'add' | 'edit';
}

const InputLanguage: React.FC<IInputLanguageProps> = (props) => {
  const [t] = useTranslation();
  const [isTargetHoverd, { setTrue: enterTarget, setFalse: leaveTarget }] = useBoolean(false);
  const localisationRef = useRef<HTMLDivElement>(null);

  const calloutProps = useMemo<ICalloutProps>(
    () => ({
      target: localisationRef,
      role: 'dialog',
      gapSpace: 5,
      isBeakVisible: true,
      beakWidth: 10,
      directionalHint: DirectionalHint.bottomCenter,
      calloutMaxHeight: undefined,
    }),
    []
  );

  return (
    <div>
      <div
        ref={localisationRef}
        onPointerEnter={enterTarget}
        onPointerLeave={leaveTarget}
        className={classNames(styles.languageContainer, {
          [styles.animated]: props.baseLanguage.locale !== props.currentLanguage.locale && !props.readonly,
        })}
      >
        {props.type !== 'add' && (
          <span>
            {t('common.baseLanguage')}:{' '}
            <span className={styles.language}>
              {props.baseLanguage.icon ? (
                <ReactCountryFlag countryCode={props.baseLanguage.icon} svg style={{ marginRight: '10px', width: '20px' }} />
              ) : (
                props.baseLanguage.locale
              )}
            </span>
          </span>
        )}
        {t('common.currentInput')}:{' '}
        <span className={styles.language}>
          {props.currentLanguage?.icon ? (
            <ReactCountryFlag countryCode={props.currentLanguage.icon} svg style={{ width: '20px' }} />
          ) : (
            props.currentLanguage.locale
          )}
        </span>
      </div>
      {props.baseLanguage.locale !== props.currentLanguage.locale && !props.readonly && (
        <FeatureCallout calloutProps={calloutProps} featureKey={CALLOUT_FEATURE_KEY} forceOpen={isTargetHoverd}>
          <h3>{t('header.controls.currentInputLanguage.bubble.title')}</h3>
          <p>{t('header.controls.currentInputLanguage.bubble.text')}</p>
          <p>{t('header.controls.currentInputLanguage.bubble.note')}</p>
        </FeatureCallout>
      )}
    </div>
  );
};

export default InputLanguage;
