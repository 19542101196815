import tinymce, { Editor, Plugin } from 'tinymce/tinymce';

export default () => {
  tinymce.PluginManager.add('insertContent', function (editor: Editor): void | Plugin {
    const openDialog = (): unknown => {
      return editor.windowManager.open({
        title: 'Embed code',
        body: {
          type: 'panel',
          items: [
            {
              type: 'textarea',
              name: 'value',
              label: 'Code: ',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Close',
          },
          {
            type: 'submit',
            text: 'Insert',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const data = api.getData() as { value: string };

          // Special preparation here:
          // 1. Handle iFrame with nested html: Remove all nested tags + text
          const htmlDocument = editor.getDoc();
          const containerElem = htmlDocument.createElement('div');
          containerElem.id = editor.dom.uniqueId();
          containerElem.innerHTML = data.value;

          const iFrames = Array.from(containerElem.querySelectorAll('iframe'));
          for (const iFrame of iFrames) {
            if (iFrame.innerHTML) {
              iFrame.innerHTML = '';
            }
          }

          // Insert content when the window form is submitted
          editor.insertContent(containerElem.innerHTML);
          api.close();
        },
      });
    };

    editor.ui.registry.addIcon(
      'cp-embed-code',
      '<svg height="24" width="24"><path d="M 8.75 10.908203 A 1.157246 1.157246 0 0 0 7.6015625 12.066406 A 1.157246 1.157246 0 0 0 8.7578125 13.222656 A 1.157246 1.157246 0 0 0 9.9160156 12.066406 A 1.157246 1.157246 0 0 0 8.7578125 10.908203 A 1.157246 1.157246 0 0 0 8.75 10.908203 z M 12.160156 10.908203 A 1.157246 1.157246 0 0 0 11.011719 12.066406 A 1.157246 1.157246 0 0 0 12.167969 13.222656 A 1.157246 1.157246 0 0 0 13.326172 12.066406 A 1.157246 1.157246 0 0 0 12.167969 10.908203 A 1.157246 1.157246 0 0 0 12.160156 10.908203 z M 15.576172 10.908203 A 1.157246 1.157246 0 0 0 14.427734 12.066406 A 1.157246 1.157246 0 0 0 15.585938 13.222656 A 1.157246 1.157246 0 0 0 16.742188 12.066406 A 1.157246 1.157246 0 0 0 15.585938 10.908203 A 1.157246 1.157246 0 0 0 15.576172 10.908203 z " /><path style="fill:#222f3e;fill-opacity:0.5" d="M 16.074219 4.8359375 C 15.747999 4.8348275 15.437015 4.9745565 15.220703 5.21875 C 14.795082 5.6443709 14.795082 6.3556291 15.220703 6.78125 L 20.613281 12.029297 L 15.220703 17.277344 C 14.795082 17.702965 14.795082 18.411566 15.220703 18.695312 C 15.788197 19.262806 16.50016 19.262806 16.925781 18.695312 L 23.167969 12.880859 C 23.59359 12.455238 23.59359 11.603355 23.167969 11.177734 L 16.925781 5.21875 C 16.710109 4.9752818 16.399474 4.8356224 16.074219 4.8359375 z M 8.1269531 4.9003906 C 7.8077371 4.9003906 7.4901538 5.0059389 7.2773438 5.21875 L 1.0332031 11.177734 C 0.52168835 11.629519 0.52168846 12.429074 1.0332031 12.880859 L 7.2773438 18.695312 C 7.7029647 19.262806 8.5548466 19.262808 8.9804688 18.695312 C 9.4060888 18.411565 9.4060887 17.702965 8.9804688 17.277344 L 3.5898438 12.029297 L 8.9804688 6.78125 C 9.4060888 6.3556289 9.4060887 5.6443709 8.9804688 5.21875 C 8.7676577 5.0059393 8.4461681 4.9003899 8.1269531 4.9003906 z " /></g></svg>'
    );

    editor.ui.registry.addButton('insertContent', {
      tooltip: 'Embed code',
      icon: 'cp-embed-code',
      onAction: openDialog,
    });
  });
};
