const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpMember',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMember' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMember?$filter=parent%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parent.identifier',
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Member',
    'properties': {
        'tags': {
            'title': 'Tags',
            'type': 'array',
            'items': { 'type': 'string' },
            'cp_ui': { 'sortOrderForm': 38 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tags', 'rel': 'canonical' }],
        },
        'parent': {
            'title': 'Parent',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpMember',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMember' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cp Member',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Member' } },
            },
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/parent', 'rel': 'canonical' }],
        },
        'referringTo': {
            'title': 'Referring To',
            '$id': 'http://platform.cosmoconsult.com/ontology/Solution',
            'type': 'object',
            'links': [
                {
                    'rel': 'collection',
                    'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution',
                    'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
                },
                { 'href': 'http://platform.cosmoconsult.com/ontology/referringTo', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentSolution.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Solution' } },
            'cp_ui': { 'sortOrderForm': 39 },
        },
        'internal': {
            'title': 'Internal',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internal', 'rel': 'canonical' }],
        },
        'cpMemberType': {
            'title': 'Cp Member Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpMemberType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMemberType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpMemberType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Integration',
                'http://platform.cosmoconsult.com/ontology/DigitalValueAddedService',
                'http://platform.cosmoconsult.com/ontology/Process',
                'http://platform.cosmoconsult.com/ontology/Project',
            ],
            'enumNames': ['Integration', 'Digital Value Added Service (DVAS)', 'Process', 'Project'],
            'cp_ui': { 'sortOrderForm': 34 },
        },
        'cpNamespace': {
            'title': 'Cp Namespace',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpNamespace',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpNamespace' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpNamespace', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Namespace' } },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': "<div>Short description or summary that serves to convey the most important information on the subject or subject matter in a concise form (1-3 sentences). Often used for list, card or tiles. The idea is to capture the reader's or viewer's attention without going into detail. A short description can be compared with a teaser text.</div>\n<div> </div>",
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'] },
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const CpMemberSchema = Schema;
