import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { axiosDictionary, postEntityToEndpoint } from '@cpa/base-core/api';
import { TypeConstants } from '@cp/base-types';
import notification from '@cpa/base-core/helpers/toast';
import classNames from 'classnames';
import { Icon, ITextField, PrimaryButton, TextField } from '@fluentui/react';
import { IDataItem, IRelatedMessage } from '@cpa/base-core/types';
import { getFilenameFromURL, getSafeString } from '@cpa/base-core/helpers';
import { useMediaQuery } from 'react-responsive';

import TinyEditor from '../../../../../../../components/Form/components/TextWidget/components/Html/components/TinyEditor/TinyEditor';
import HoverTooltip from '../../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './MessageInput.module.scss';
import FileUpload, { FileUploadMode } from './components/FileUpload/FileUpload';

interface IMessageInputProps {
  parentMessage: IDataItem;
  relatedMessage: IRelatedMessage | null;
  unsetRelatedMessage: () => void;
  width?: number;
}

const MessageInput: React.FC<IMessageInputProps> = ({ parentMessage, relatedMessage, unsetRelatedMessage, width }) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState<string | boolean | number | object | null | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [sendBlocked, setSendBlocked] = useState(false);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const textInputRef = useRef<ITextField>(null);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });
  const [attachedFiles, setAttachedFiles] = useState<string[]>([]);
  const isInputTouched = useRef<boolean>(false);

  const toggleExpanded = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (expanded) {
        isInputTouched.current = true;
        setInputValue((prevValue) => getSafeString(prevValue as string, true));
      }
      setExpanded(!expanded);
    },
    [expanded]
  );

  const handleInputChange = useCallback((content: string | boolean | number | object | null | undefined) => {
    setInputValue(content);
  }, []);

  const handlePost = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (sendBlocked) {
        notification.warning(t('messages.waitForUpload'));
        return;
      }
      setLoading(true);
      // Post item
      await postEntityToEndpoint(axiosDictionary.appDataService, `data-store/${encodeURIComponent(TypeConstants.CpMessage)}`, {
        name: `Re: ${parentMessage.name}`,
        description: inputValue as string,
        accessPermissions: parentMessage.accessPermissions,
        parentMessage: { identifier: parentMessage.identifier },
        files: attachedFiles,
        ...(relatedMessage ? { relatedTo: { identifier: relatedMessage.identifier } } : {}),
      })
        .then(() => {
          setInputValue('');
          setExpanded(false);
          unsetRelatedMessage();
          setAttachedFiles([]);
          textInputRef.current?.focus();
        })
        .catch((e) => {
          notification.error('Failed to send message');
          console.error('Failed to send message', e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      sendBlocked,
      parentMessage.name,
      parentMessage.accessPermissions,
      parentMessage.identifier,
      inputValue,
      attachedFiles,
      relatedMessage,
      unsetRelatedMessage,
    ]
  );

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent) => {
      if (loading) return;
      if (event.shiftKey && event.key === 'Enter') {
        setExpanded(true);
      } else if (event.key === 'Enter') {
        await handlePost(event as unknown as React.MouseEvent<HTMLButtonElement>);
      }
    },
    [handlePost, loading]
  );

  const handleFileAdded = useCallback((files: string[]) => {
    setAttachedFiles((currentFiles) => [...currentFiles, ...files]);
  }, []);

  const handleFileUploadStart = useCallback(() => {
    setSendBlocked(true);
  }, []);

  const handleFileUploadComplete = useCallback(() => {
    setSendBlocked(false);
  }, []);

  const unsetAttachedFiles = useCallback(() => {
    setAttachedFiles([]);
  }, []);

  const renderSuffix = useMemo(() => {
    return () => (
      <div className={styles.suffix}>
        <HoverTooltip content={t('messages.attachFiles')}>
          <FileUpload
            mode={FileUploadMode.button}
            onUploadComplete={handleFileUploadComplete}
            onUploadStart={handleFileUploadStart}
            onFileAdded={handleFileAdded}
          />
        </HoverTooltip>
        <HoverTooltip content={t('messages.format')}>
          <Icon iconName={'fabrictexthighlight'} onClick={toggleExpanded} />
        </HoverTooltip>
        <HoverTooltip content={t('messages.send')}>
          <Icon iconName={'send'} onClick={handlePost} />
        </HoverTooltip>
      </div>
    );
  }, [handleFileAdded, handleFileUploadComplete, handleFileUploadStart, handlePost, t, toggleExpanded]);

  const removeFileFromAttachments = useCallback((file: string) => {
    setAttachedFiles((current) => {
      return current.filter((attachedFile) => attachedFile !== file);
    });
  }, []);

  const filesTooltipContent = useMemo(() => {
    return (
      <div className={styles.fileTooltipWrapper}>
        {attachedFiles.map((file, index) => {
          const name = getFilenameFromURL(file);
          return (
            <div key={index} className={styles.fileInfo}>
              <Icon iconName={'openfile'} className={styles.fileIcon} />
              <span>{name}</span>
              <Icon className={styles.removeFileIcon} iconName={'close'} onClick={() => removeFileFromAttachments(file)} />
            </div>
          );
        })}
      </div>
    );
  }, [attachedFiles, removeFileFromAttachments]);

  if (!expanded) {
    return (
      <div style={{ marginTop: 10, width: isMobileDevice ? '90%' : width }}>
        {attachedFiles.length > 0 ? (
          <div className={styles.relatedWrapper}>
            <span>{t('messages.filesAttached', { count: attachedFiles.length })}</span>
            <HoverTooltip content={filesTooltipContent}>
              <Icon iconName={'info'} className={styles.filesInfo} />
            </HoverTooltip>
            <Icon iconName={'close'} onClick={unsetAttachedFiles} />
          </div>
        ) : null}
        {relatedMessage ? (
          <div className={classNames(styles.relatedWrapper, { [styles.withAttached]: attachedFiles.length > 0 })}>
            <span>{t('messages.replyingTo', { fromUser: relatedMessage.userName })}</span>
            <Icon iconName={'close'} onClick={unsetRelatedMessage} />
          </div>
        ) : null}
        <TextField
          componentRef={textInputRef}
          className={classNames(styles.box, { [styles.light]: !darkMode, [styles.withRelated]: relatedMessage || attachedFiles.length > 0 })}
          borderless
          value={inputValue as string}
          onChange={(e, value) => handleInputChange(value)}
          placeholder={t('messages.reply')}
          autoFocus={isInputTouched.current}
          onFocus={() => {
            isInputTouched.current = true;
          }}
          onRenderSuffix={renderSuffix}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper} style={{ width: isMobileDevice ? '90%' : width }}>
      <div className={styles.editor}>
        {attachedFiles.length > 0 ? (
          <div className={styles.relatedWrapper}>
            <span>Attached {attachedFiles.length} files</span>
            <Icon iconName={'close'} onClick={unsetAttachedFiles} />
          </div>
        ) : null}
        {relatedMessage ? (
          <div className={classNames(styles.relatedWrapper, { [styles.withAttached]: attachedFiles.length > 0 })}>
            <span>{t('messages.replyingTo', { fromUser: relatedMessage.userName })}</span>
            <Icon iconName={'close'} onClick={unsetRelatedMessage} />
          </div>
        ) : null}
        <TinyEditor value={inputValue as string} onChange={handleInputChange} emptyValue={''} readOnly={loading} autoFocus={true} />
        <div className={styles.buttons}>
          <FileUpload
            mode={FileUploadMode.text}
            onUploadComplete={handleFileUploadComplete}
            onUploadStart={handleFileUploadStart}
            onFileAdded={handleFileAdded}
          />
          <div className={styles.collapse} onClick={toggleExpanded}>
            {t('common.cancel')}
          </div>
          <PrimaryButton disabled={loading} onClick={handlePost}>
            {t('messages.send')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
