import { IActionDescriptor } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { ICustomAction, IGenericComponentData } from '@cpa/base-core/types';
import { MessageBar } from '@fluentui/react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomRowTemplates } from '../../../../mapping';
import styles from '../../ScrollingContent.module.scss';
import Actions from '../Actions/Actions';
import { ActionType } from '../Actions/constants/ActionType';
import Filter from '../Filter/Filter';

interface ITopBarContentProps {
  isSticky: boolean;
  data: IGenericComponentData;
  hideFilter?: boolean;
  filterValue: string;
  hideActions?: boolean;
  isChart?: boolean | null;
  availableActions: IActionDescriptor[];
  onActionClick: (action: ActionType | string, payload?: unknown) => void;
  selectedItemsCount?: number | null;
  customActionsBound?: ICustomAction[];
  setFilterValue: (value: string) => void;
  onClearSelection: () => void;
  isODataSupportedByEndpoint?: boolean;
  defaultFilterExpanded?: boolean;
}

const TopBarContent: React.FC<ITopBarContentProps> = ({
  isSticky,
  data,
  hideFilter,
  filterValue,
  hideActions,
  isChart,
  customActionsBound,
  availableActions,
  onActionClick,
  selectedItemsCount,
  setFilterValue,
  onClearSelection,
  isODataSupportedByEndpoint,
  defaultFilterExpanded,
}) => {
  const wsConnected = useSelector((store: IGlobalState) => store.websocket.connected);
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  if (!data.schema) {
    return null;
  }

  return (
    <div className={classNames(styles.screenBar, { [styles.isSticky]: isSticky })}>
      {!wsConnected && <MessageBar>{t('errors.connection.websocketError')}</MessageBar>}
      <div
        className={classNames({
          [styles.filterWrapper]: true,
          [styles.filterWrapperDark]: darkMode,
        })}
        style={{ display: hideFilter ? 'none' : undefined }}
      >
        <div className={styles.mainFilter}>
          <Filter
            value={filterValue}
            onChange={setFilterValue}
            dataUrl={data.page.dynamicDataUrl ? data.page.dynamicDataUrl : data.page.dataUrl || '/'}
            disableAdvancedFilter={data.page.disableFilterHelper || data.schema.cp_disableFilterHelper}
            page={data.page}
            schema={data.schema}
            isSticky={isSticky}
            disableNotifications={!isODataSupportedByEndpoint}
            defaultExpanded={defaultFilterExpanded}
          />
        </div>
      </div>
      {!hideActions && (
        <div>
          <Actions
            availableActions={availableActions}
            isInsideContextMenu={false}
            onActionClick={onActionClick}
            isChart={isChart}
            selectedItemsCount={
              data.page.customRowTemplate?.identifier &&
              CustomRowTemplates[data.page.customRowTemplate.identifier]?.options?.table?.hideSelectedItemsCount === true
                ? null
                : selectedItemsCount
            }
            customActions={customActionsBound}
            onClearSelection={onClearSelection}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(TopBarContent);
