import { Schemas } from '@cp/base-types';

import { KpiOp } from './subjects';

export enum GraphicsAlign {
  BottomLeft = 'http://platform.cosmoconsult.com/ontology/BottomLeft',
  BottomRight = 'http://platform.cosmoconsult.com/ontology/BottomRight',
  TopLeft = 'http://platform.cosmoconsult.com/ontology/TopLeft',
  TopRight = 'http://platform.cosmoconsult.com/ontology/TopRight',
}

export interface IPieOptions {
  nameProperty: string; // chart.nameProperty
  operationType: KpiOp; // chart.operationType

  // Options with type 'sum'
  axisProperty?: string; // chart.axisProperty

  // Options with type 'count'
  name?: string; // chart.name
}

export interface IHorizontalOptions {
  nameProperty: string; // chart.nameProperty
  selectionProperty: string; // chart.selectionProperty
  name: string; // chart.name
}

export interface IVerticalOptions {
  nameProperty: string; // chart.nameProperty
  dateProperty: string; // chart.dateProperty
  axisProperty: string; // chart.axisProperty
  multiplierSymbol?: string; // chart.multiplierSymbol
  operationType: KpiOp; // chart.operationType
}

export interface IAreaOptions {
  nameProperty: string; // chart.nameProperty
  axisProperty: string; // chart.axisProperty
  operationType: KpiOp; // chart.operationType
}

export enum ChartEntity {
  Bar = 'http://platform.cosmoconsult.com/ontology/BarChart',
  Line = 'http://platform.cosmoconsult.com/ontology/LineChart',
  Area = 'http://platform.cosmoconsult.com/ontology/AreaChart',
  Dot = 'http://platform.cosmoconsult.com/ontology/DotChart',
}

export interface IComposedOptions {
  axisProperty: string; // chart.axisProperty
  entities: {
    entityType: ChartEntity;
    valueProperty: string;
    name: string;
    color?: string;
  }[];
}

export interface IRadialOptions extends IPieOptions {}

export enum ChartType {
  PIE = 'http://platform.cosmoconsult.com/ontology/CpaPageChartPie',
  VERTICAL = 'http://platform.cosmoconsult.com/ontology/CpaPageChartVertical',
  HORIZONTAL = 'http://platform.cosmoconsult.com/ontology/CpaPageChartHorizontal',
  AREA = 'http://platform.cosmoconsult.com/ontology/CpaPageChartArea',
  RADIAL = 'http://platform.cosmoconsult.com/ontology/CpaPageChartRadial',
  COMPOSED = 'http://platform.cosmoconsult.com/ontology/CpaPageChartComposed',
}

export enum OrderDirection {
  ASCENDING = 'http://platform.cosmoconsult.com/ontology/Ascending',
  DESCENDING = 'http://platform.cosmoconsult.com/ontology/Descending',
}

export type ChartOptionsType = IPieOptions | IHorizontalOptions | IVerticalOptions | IAreaOptions | IComposedOptions;

export interface ITooltipOptions {
  tooltipPreviewProperty: string; // chart.tooltipPreviewProperty
}

export enum BaseApi {
  ApiGateway = 'http://platform.cosmoconsult.com/ontology/ApiGateway',
  DataService = 'http://platform.cosmoconsult.com/ontology/DataService',
  LoopApi = 'loop:LoopApi',
  LoopApiSi = 'loop:LoopApiSi',
}

export interface IColumnDetails {
  title: string;
  fieldName: string;
}

export interface IPageSetting {
  showOnDashboards: string[];
  // For pages only
  displayFilter: boolean;
  displayChart: boolean;
  displayTable: boolean;
  disabledColumns: string[];
  customView: boolean;
  displayAsCards: boolean;
  chartOptions: Schemas.CpaPage['chart'];
  facetFiltersWidth?: number;

  // For dashboard only
  widgetCustomView: boolean;
  widgetDisplayTable: boolean;
  widgetDisabledColumns: string[];
  widgetDisplayAsCards: boolean;
  amountOfColumns: number | undefined;
}
