import { getLogger } from '@cp/base-logging';
import { routerMiddleware } from 'connected-react-router';
import i18next from 'i18next';
import { Action, applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { Environment } from '../app/environment';
import history from '../app/history';

import { languageChanged } from './app/actions';
import rootReducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const reduxLogger = createLogger({
  collapsed: true,
  duration: false,
  timestamp: false,
  level: 'info',
  logger: getLogger('Redux store'),
});

const getEnhancers = (): ReturnType<typeof applyMiddleware> => {
  const middleware = [sagaMiddleware, routerMiddleware(history)];
  if (Environment.env.NODE_ENV === 'development') {
    middleware.push(reduxLogger);
  }
  return applyMiddleware(...middleware);
};

export type IGlobalState = ReturnType<typeof rootReducer>;

const store: Store<IGlobalState, Action> = createStore(rootReducer, composeWithDevTools(getEnhancers()));

i18next.on('languageChanged', (language) => {
  store.dispatch(languageChanged(language));
});

export function runSaga(): void {
  sagaMiddleware.run(rootSaga);
}

export { store, sagaMiddleware };
