import { ICommandBarItemProps } from '@fluentui/react';
import urlJoin from 'url-join';
import React from 'react';
import { Schemas } from '@cp/base-types';
import * as _ from 'lodash';
import { TFunction } from 'i18next';

import { IBase, IMenuItem, IMenuLink } from '../types';

import { isValidMenuItem } from './ui';
import { getAppBasePath } from './location';
import { executeBackendAction, executeFrontendAction } from './data/triggers';

function getImplicitPort(): string {
  if (window.location.port) {
    return window.location.port;
  }

  switch (window.location.protocol) {
    case 'http:':
      return '80';
    case 'https:':
      return '443';
    default:
      return '80';
  }
}

function removeEmptyItems(items: IMenuItem[], allLinks: IMenuLink[]): IMenuItem[] {
  return items.filter((item) => {
    if (!item.url && !item.links) {
      return false;
    }

    if (Array.isArray(item.links)) {
      item.links = removeEmptyItems(item.links, allLinks);
    }

    if (item.url && item.url.startsWith('/_navigation/') && !item.links?.length) {
      return false;
    }

    if (!item.links?.length) {
      delete item.links;
      delete item.expanded;
    }

    return true;
  });
}

export function pagesToMenuLinks(pages: Schemas.CpaPage[]): IMenuLink[] {
  return pages.map(
    (page): IMenuLink => ({
      key: page.identifier!,
      text: page.name,
      icon: page.icon,
      path: page.path || `/_navigation/${page.identifier}`,
      divider: page.divider || false,
      parentMenuItemKey: page.parentCpaPage as IBase,
      sortOrderMenu: page.sortOrderMenu,
      externalUrl: page.externalUrl,
      cpType: page.cpTypeUrl,
      actions: page.actions,
      pageIdentifier: page.identifier,
      dataEndpointIdentifier: page.dataEndpoint?.identifier,
    })
  );
}

export function menuLinksToMenuItems(linksToConvert: IMenuLink[], t: TFunction): IMenuItem[] {
  const sortedLinks: IMenuLink[] = [...linksToConvert].sort(
    ({ sortOrderMenu: sortOrderLeft = Infinity }, { sortOrderMenu: sortOrderRight = Infinity }) =>
      sortOrderLeft < sortOrderRight ? -1 : sortOrderLeft > sortOrderRight ? 1 : 0
  );

  const localLinks: IMenuItem[] = sortedLinks.map((link) => {
    const filteredActions = link.actions?.filter((action) => action?.showInMenu);
    const itemsWithoutGroup = filteredActions?.filter((action) => !action.group);
    const groupedCustomActions: Record<string, Schemas.CpaPage['actions']> = _.groupBy(
      filteredActions?.filter((action) => action.group) || [],
      (action) => action.group
    );

    const onActionClick = async (action: NonNullable<Schemas.CpaPage['actions']>[0]): Promise<void> => {
      if (action.runOnBackend) {
        if (!link.pageIdentifier || !link.dataEndpointIdentifier || !action.identifier) {
          return;
        }
        await executeBackendAction(link.dataEndpointIdentifier, link.pageIdentifier, action.identifier, []);
      } else {
        await executeFrontendAction(action, { event: 'Action' });
      }
    };

    const groupedActions = Object.keys(groupedCustomActions).map((groupKey, index) => {
      return {
        key: groupKey + index,
        name: groupKey,
        expanded: false,
        externalUrl: false,
        onClick: undefined,
        links:
          groupedCustomActions[groupKey]?.map((action, index) => {
            return {
              key: `${action.identifier}-${index}`,
              name: action.name,
              url: '#',
              icon: action.icon,
              onClick: () => onActionClick(action),
            };
          }) || [],
      };
    });

    const actionsWithoutGroup =
      itemsWithoutGroup?.map((action, index) => {
        return {
          key: `${action.identifier}-${index}`,
          name: action.name,
          icon: action.icon,
          url: '#',
          external: false,
          onClick: () => onActionClick(action),
        };
      }) || [];

    const actionsLinks = [
      {
        key: `actions-${link.key}`,
        name: t('common.actions'),
        external: !!link.externalUrl,
        expanded: false,
        links: [...groupedActions, ...actionsWithoutGroup],
        icon: 'LightningBolt',
      },
    ];

    return {
      key: link.key,
      name: link.text.startsWith('_dictionary:') ? t(link.text.split(':')[1]) : link.text,
      url: link.externalUrl?.replace(/{CPA_CURRENT_PORT}/g, getImplicitPort()) || link.path,
      external: !!link.externalUrl,
      expanded: false,
      links: actionsLinks[0].links.length ? actionsLinks : [],
      icon: link.icon,
      divider: link.divider,
      cpType: link.cpType,
    };
  });

  for (const link of localLinks) {
    const childrenKeys = sortedLinks.filter((l) => !!l.parentMenuItemKey && l.parentMenuItemKey.identifier === link.key).map((l) => l.key);
    for (const key of childrenKeys) {
      const foundLink = localLinks.find((l) => l.key === key);

      // TODO: Filter
      if (foundLink) {
        link.links?.push(foundLink);
      }
    }
  }
  for (const link of localLinks) {
    if (!link.links?.length) {
      delete link.links;
      delete link.expanded;
    }
  }

  return removeEmptyItems(localLinks, sortedLinks).filter((l) => {
    const localLink = sortedLinks.find((link) => link.key === l.key);
    return !(localLink && !!localLink.parentMenuItemKey);
  });
}

export function menuItemsToCommandBarItems(
  items: IMenuItem[],
  onItemClick?: (item: IMenuItem) => void,
  addIcons: boolean = false,
  level: number = 0
): ICommandBarItemProps[] {
  return items.filter(isValidMenuItem).map((item, i) => {
    const childLinks = item.links && item.links.length ? menuItemsToCommandBarItems(item.links, onItemClick, true, level + 1) : [];

    const isCalculatedLink = item.url && item.url.startsWith('/_navigation/');

    return {
      key: item.key,
      text: item.name,
      split: isCalculatedLink ? false : !!childLinks.length && !!item.url,
      iconProps: addIcons ? { iconName: item.icon } : undefined,
      href: item.url && !item.external ? urlJoin(getAppBasePath(), item.url) : item.url,
      onClick: (e: React.MouseEvent<HTMLElement>): void => {
        // e.defaultPrevented = false is a workaround to close sub-menu on click
        // https://github.com/microsoft/fluentui/issues/13339

        if (isCalculatedLink) {
          e.preventDefault();
          return;
        }

        if (item.onClick) {
          e?.preventDefault();
          e.defaultPrevented = false;
          item.onClick(e);
        } else if (onItemClick) {
          e?.preventDefault();
          e.defaultPrevented = false;
          onItemClick(item);
        }
      },
      subMenuProps: childLinks.length
        ? {
            items: childLinks,
          }
        : undefined,
      style: {
        borderBottom: item.divider && level > 0 && i < items.length - 1 ? '1px solid #9EA2A2' : undefined,
      },
    };
  });
}
