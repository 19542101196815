import { isUuid } from '@cp/base-types';
import { getEntitiesFromEndpoint } from '@cpa/base-core/api';
import notification from '@cpa/base-core/helpers/toast';
import { IScreenProps } from '@cpa/base-core/types';
import { createCancelToken, IAxiosInstance } from '@cpa/base-http';
import { useBoolean } from '@fluentui/react-hooks';
import { CancelTokenSource } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Widget from '../../components/Widget/Widget';

import RelatedWorkItems from './components/RelatedWorkItems/RelatedWorkItems';
import { IGroupedLinks, IRelatedWork, IRelatedWorkItem, ITargetWorkItem, IWorkItem } from './helpers/types';
import { loopApi, loopApiSi } from './helpers/utils';
import styles from './RelatedWork.module.scss';

interface RelatedWorkProps extends IScreenProps {
  item: IWorkItem;
}

const RelatedWork: React.FC<RelatedWorkProps> = ({ page, item }) => {
  const [context, setContext] = useState<IGroupedLinks[] | undefined>();
  const [loading, { setTrue: startLoading, setFalse: stopLoading }] = useBoolean(true);
  const [error, { setTrue: setError }] = useBoolean(false);
  const [t] = useTranslation();
  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    startLoading();

    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();

    // Generous approach to determine DevOps name (which is ID there) as special treatment
    // TODO: Ensure in data that we always use identifier instead of name
    const devOpsOrganizationId = isUuid(item.devOpsOrganization.identifier) ? item.devOpsOrganization.name : item.devOpsOrganization.identifier;

    const callAPI = async (api: IAxiosInstance) => {
      let relatedWorkItemResponse: IRelatedWork;

      if (!page.dataEndpoint?.identifier) {
        stopLoading();
        setError();
        console.error('Data endpoint not defined.');
        return;
      }

      try {
        relatedWorkItemResponse = await api.get<IRelatedWork>(`/api/related-work?id=${item.workItemId}&organization=${devOpsOrganizationId}`, {
          cancelToken: cancelToken.current?.token,
        });

        const workItemIds = relatedWorkItemResponse.value[0].Links.map((link) => link.TargetWorkItem.WorkItemId);

        const filter = [
          `devOpsOrganization/identifier eq '${item.devOpsOrganization.identifier}'`,
          '(' + workItemIds.map((id) => `workItemId eq ${id}`).join(' or ') + ')',
        ].join(' and ');

        const [platformWorkItems] = await Promise.all(
          getEntitiesFromEndpoint(page.dataEndpoint.identifier, `data-store/loop:WorkItem?$filter=${filter}&$select=identifier,workItemId`)
        );

        relatedWorkItemResponse.value[0].Links.forEach((link) => {
          const currentPlatformWorkItem = (platformWorkItems.entities as IWorkItem[]).find(
            (workItem) => workItem.workItemId === link.TargetWorkItem.WorkItemId
          );
          link.TargetWorkItem.identifier = currentPlatformWorkItem?.identifier;
        });
      } catch (err) {
        console.error(err);
        notification.error(err.response?.data);
        notification.error(t('errors.connection.internalErrorTitle'));
        return;
      } finally {
        stopLoading();
      }

      const groupBy = (items: IRelatedWorkItem[], getKey: Function) => {
        return items.reduce((memo: Record<string, ITargetWorkItem[]>, item: IRelatedWorkItem) => {
          const key = getKey(item);
          (memo[key] ||= []).push(item.TargetWorkItem);
          return memo;
        }, {});
      };

      const groupedLinks = groupBy(relatedWorkItemResponse.value[0].Links, ({ LinkTypeName }: IRelatedWorkItem) => LinkTypeName);
      const results: IGroupedLinks[] = [];

      for (const key in groupedLinks) {
        const groupedLink: IGroupedLinks = {
          linkTypeName: key,
          targetItems: groupedLinks[key],
        };
        results.push(groupedLink);
      }

      setContext(results);
    };

    if (devOpsOrganizationId === 'cosmoconsultsi') {
      callAPI(loopApiSi).catch((err) => {
        console.error(`Could not request Loop Si API. ${err}`, err);
      });
    } else {
      callAPI(loopApi).catch((err) => {
        console.error(`Could not request Loop API. ${err}`, err);
      });
    }

    return () => {
      cancelToken.current?.cancel();
    };
  }, [
    item.workItemId,
    item.devOpsOrganization.identifier,
    item.devOpsOrganization.name,
    startLoading,
    stopLoading,
    t,
    page.dataEndpoint?.identifier,
    setError,
  ]);

  return (
    <div className={styles.relatedWorkContainer}>
      <Widget className={styles.widget} title={page.name as string} headerIconName={page.icon as string} hideSettings={true}>
        <div className={styles.relatedWorkContent}>
          {loading
            ? t('common.loading')
            : error
            ? t('errors.connection.internalErrorTitle')
            : context?.map((rel: IGroupedLinks) => (
                <RelatedWorkItems key={rel.targetItems.map((item: ITargetWorkItem) => item.WorkItemId)[0]} item={item} groupedLinks={rel} />
              ))}
        </div>
      </Widget>
    </div>
  );
};

export default RelatedWork;
