import { createActions } from 'redux-actions';

export const {
  appReady,
  appNotReady,
  languageChanged,
  startLoading,
  finishLoading,
  setLocales,
  setCpa,
  setNamespaces,
  setPropertyFormats,
  setDataEndpoints,
  authReady,
  showDialog,
  hideDialog,
  showDrawer,
  hideDrawer,
  setCurrentTourBubble,
  connectionError,
  nameSpaceChange,
  setCookieAvailable,
} = createActions(
  'APP_READY',
  'APP_NOT_READY',
  'LANGUAGE_CHANGED',
  'START_LOADING',
  'FINISH_LOADING',
  'SET_LOCALES',
  'SET_CPA',
  'SET_NAMESPACES',
  'SET_PROPERTY_FORMATS',
  'SET_DATA_ENDPOINTS',
  'AUTH_READY',
  'SHOW_DIALOG',
  'HIDE_DIALOG',
  'SHOW_DRAWER',
  'HIDE_DRAWER',
  'SET_CURRENT_TOUR_BUBBLE',
  'CONNECTION_ERROR',
  'NAME_SPACE_CHANGE',
  'SET_COOKIE_AVAILABLE'
);
