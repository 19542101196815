import { store } from '../store';

import { IMatchedDataEndpointWithAxios } from './axios';

export function getEndpoint(id: string): IMatchedDataEndpointWithAxios {
  const dataEndpoints = store.getState().app.dataEndpoints;
  if (!dataEndpoints || !dataEndpoints.size) {
    throw new Error('No initialized endpoints');
  }

  const endpoint = dataEndpoints.get(id);
  if (!endpoint) {
    throw new Error(`Endpoint with id ${id} not found`);
  }
  return endpoint;
}
