import { DialogFooter, DialogType, Link, PrimaryButton } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IDataItem } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { showDialog } from '@cpa/base-core/helpers';
import { GlobalDialogType } from '@cpa/base-core/types';

import Picker from '../Picker/Picker';

import styles from './WelcomeDialog.module.scss';

interface IWelcomeDialogProps {
  hidden: boolean;
  domains: IDataItem[];
  renderedDomains: string[];
  jobRoles: IDataItem[];
  solutionScopes: any[];
  domainsTitle: string;
  jobRoleTitle: string;
  onDismiss: () => void;
  onSubmit: (selectedDomains: string[], selectedJobRoles: string[]) => void;
}

const WelcomeDialog: React.FC<IWelcomeDialogProps> = ({
  hidden,
  domains,
  renderedDomains,
  solutionScopes,
  jobRoles,
  domainsTitle,
  jobRoleTitle,
  onDismiss,
  onSubmit,
}) => {
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>([]);
  const [t] = useTranslation();

  const renderedJobRoles = useMemo((): string[] => {
    const jobRolesIdentifiers: Set<string> = new Set();
    if (!solutionScopes) return [];
    const filteredScopes = solutionScopes.filter((scope) => scope.solutionDomain && selectedDomains.includes(scope.solutionDomain));
    for (const domain of filteredScopes) {
      for (const sdc of domain.sdcs) {
        if (!sdc.jobRole) continue;
        const sdcFilteredContentLength = Object.keys(cloneDeepWithMetadata(sdc)).filter((key) => key !== 'jobRole').length;
        if (!sdcFilteredContentLength || !sdc.jobRole.identifier) continue;
        jobRolesIdentifiers.add(sdc.jobRole.identifier);
      }
    }
    return Array.from(jobRolesIdentifiers) as string[];
  }, [selectedDomains, solutionScopes]);

  const onDomainChange = useCallback((selected: string[]): void => {
    setSelectedDomains(selected);
  }, []);

  useEffect(() => {
    if (renderedDomains.length === 1) {
      localStorage.setItem('trySolutionDomains', renderedDomains[0]);
      onDomainChange([renderedDomains[0]]);
    }
  }, [onDomainChange, renderedDomains, domains]);

  const solutionDomainSelectorRender = useCallback(() => {
    return (
      <Picker
        title={domainsTitle}
        items={domains}
        renderedItems={renderedDomains}
        selectedItems={selectedDomains}
        onChange={onDomainChange}
        urlQueryKey={'solutionDomains'}
        localStorageKey={'trySolutionDomains'}
        submitOnCheck={true}
        disabled={renderedDomains.length === 1}
      />
    );
  }, [domains, domainsTitle, onDomainChange, renderedDomains, selectedDomains]);

  const onJobRoleChange = useCallback((selected: string[]) => {
    setSelectedJobRoles(selected);
  }, []);

  const jobRoleSelectorRender = useCallback(() => {
    return (
      <Picker
        title={jobRoleTitle}
        items={jobRoles as IDataItem[]}
        renderedItems={renderedJobRoles}
        onChange={onJobRoleChange}
        selectedItems={selectedJobRoles}
        urlQueryKey={'jobRoles'}
        localStorageKey={'tryJobRoles'}
        disabled={!selectedDomains.length}
        submitOnCheck={true}
        renderCalloutOnTop={true}
      />
    );
  }, [jobRoleTitle, jobRoles, onJobRoleChange, renderedJobRoles, selectedDomains.length, selectedJobRoles]);

  const handleDialogResult = useCallback(async () => {
    await showDialog({
      type: GlobalDialogType.CUSTOM,
      title: ' ',
      dialogContentProps: {
        type: DialogType.largeHeader,
        showCloseButton: true,
      },
      modalProps: {
        isBlocking: true,
      },
      onClose: onDismiss,
      closeOnClickOutside: true,
      dialogTypeOptions: {
        isBlocking: true,
        renderBody: () => {
          return (
            <div className={styles.welcomeDialog}>
              <span className={styles.welcomeText}>{t('common.welcomeWindowText')}</span>
              <div className={styles.selectors}>
                {solutionDomainSelectorRender()}
                {jobRoleSelectorRender()}
              </div>
              <DialogFooter>
                <div className={styles.footer}>
                  <Link className={styles.skipButton} onClick={onDismiss}>
                    {t('common.skipWelcomeWindow')}
                  </Link>
                  <PrimaryButton
                    disabled={!selectedJobRoles.length}
                    onClick={() => onSubmit(selectedDomains, selectedJobRoles)}
                    text={t('common.confirm')}
                  />
                </div>
              </DialogFooter>
            </div>
          );
        },
      },
      width: 'min(800px, 98vw)',
    });
  }, [jobRoleSelectorRender, onDismiss, onSubmit, selectedDomains, selectedJobRoles, solutionDomainSelectorRender, t]);

  useEffect(() => {
    if (!hidden) {
      handleDialogResult();
    }
  }, [handleDialogResult, hidden]);

  return null;
};

export default WelcomeDialog;
