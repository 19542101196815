import classNames from 'classnames';
import React, { useEffect, useCallback, HTMLAttributeAnchorTarget, MouseEventHandler, MouseEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { Icon, TooltipOverflowMode } from '@fluentui/react';
import { push } from 'connected-react-router';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './NavigationButton.module.scss';
import {getSafeString} from "@cpa/base-core/helpers";

interface INavigationButtonProps {
  children?: React.ReactNode;
  target?: HTMLAttributeAnchorTarget;
  description?: string;
  url?: string;
  image?: string;
  buttonIndex?: number;
  isComponent?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  className?: string;
  highlighted?: boolean;
  hasChildren?: boolean;
  identifier?: string;
  hasParent?: boolean;
}

const NavigationButton: React.FC<INavigationButtonProps> = ({
  children,
  description,
  url,
  target,
  image,
  buttonIndex,
  isComponent,
  onClick,
  className,
  highlighted,
  hasChildren,
  identifier,
  hasParent,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const dispatch = useDispatch();

  const [buttonShown, setButtonShown] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setButtonShown(true);
    }, 50 * (buttonIndex || 0));

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onChevronClick = useCallback(
    (e: MouseEvent, identifier: string | undefined) => {
      e.preventDefault();
      e.stopPropagation();

      if (!identifier) return;

      dispatch(push({ pathname: `/_navigation/${identifier}` }));
    },
    [dispatch]
  );

  const splitButton = useMemo(() => {
    return hasChildren && url;
  }, [hasChildren, url]);

  const descriptionText = useMemo(() => {
    return getSafeString(description, true);
  }, [description]);

  return (
    <div
      className={classNames(styles.buttonWrapper, {
        [styles.buttonWrapperDark]: darkMode,
        [styles.split]: splitButton,
        [styles.default]: !splitButton && !hasChildren,
        [styles.defaultWithChevron]: !splitButton && hasChildren,
        [styles.defaultWithBack]: hasParent,
      })}
    >
      <a
        className={classNames(
          {
            [styles.ctaButton]: true,
            [styles.ctaButtonDark]: darkMode,
            [styles.ctaButtonShown]: buttonShown,
            [styles.ctaButtonComponent]: isComponent,
            [styles.highlighted]: highlighted && !darkMode,
            [styles.highlightedDark]: highlighted && darkMode,
          },
          className
        )}
        target={url ? target : undefined}
        href={url}
        rel={url ? 'noreferrer' : undefined}
        onClick={onClick}
      >
        <div className={styles.bodyWrapper}>
          <div className={styles.body}>
            <div className={styles.bodyIn}>
              {image && <Icon className={classNames({ [styles.icon]: !darkMode, [styles.iconDark]: darkMode })} iconName={image}></Icon>}

              <div className={styles.details}>
                <div className={styles.name}>
                  <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={descriptionText}>
                    {children}
                  </HoverTooltip>
                </div>
                {descriptionText && (
                  <div className={styles.description}>
                    <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={descriptionText}>
                      {descriptionText}
                    </HoverTooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
      {hasChildren && (
        <div
          className={classNames(styles.chevronContainer, { [styles.chevronContainerDark]: darkMode, [styles.splitContainer]: hasChildren && url })}
          onClick={(e) => onChevronClick(e, identifier)}
        >
          <Icon
            className={classNames({
              [styles.chevron]: true,
              [styles.chevronDark]: darkMode,
            })}
            iconName={'ChevronRightMed'}
            style={{ fontSize: 16 }}
          />
        </div>
      )}
      {hasParent && (
        <div
          className={classNames(styles.chevronContainer, {
            [styles.chevronContainerDark]: darkMode,
            [styles.splitContainer]: hasChildren && url,
            [styles.chevronHighlighted]: true,
          })}
          onClick={(e) => onChevronClick(e, identifier)}
        >
          <Icon
            className={classNames({
              [styles.chevron]: true,
              [styles.chevronDark]: darkMode,
            })}
            iconName={'ChevronLeftMed'}
            style={{ fontSize: 16 }}
          />
        </div>
      )}
    </div>
  );
};

export default NavigationButton;
