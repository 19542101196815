import * as _ from 'lodash';
import { TFunction } from 'i18next';
import { getEntitiesFromEndpoint, getSchema, postEntityToEndpoint } from '@cpa/base-core/api';
import {
  cleanUpInternalProperties,
  cleanupRecursive,
  clearVirtualProperties,
  generateValidationAjv,
  transformCodeSync,
  validateFormData,
} from '@cpa/base-core/helpers';
import { formatEntries, makeSchemaPartial } from '@cp/base-utils';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { IDataItem } from '@cpa/base-core/types';
import { createFilter } from '@cp/base-odata';
import { IJSONSchema, Schemas } from '@cp/base-types';

import { getParsedFilter } from '../../../screens/GenericScreen/utils';

export async function transformItemForAnotherEntity(
  item: IDataItem,
  targetPage: Schemas.CpaPage,
  t: TFunction,
  tolerateValidationErrors = false
): Promise<{ item: IDataItem; schema: IJSONSchema }> {
  if (!targetPage.dataEndpoint?.identifier || !targetPage.dataUrl) {
    throw new Error(t('errors.pages.unavailablePage'));
  }

  let schema: IJSONSchema;
  if ('schemaUrl' in targetPage) {
    schema = await getSchema(targetPage.schemaUrl as string);
  } else {
    const response = await getEntitiesFromEndpoint(targetPage.dataEndpoint?.identifier, targetPage.dataUrl, {
      top: 1,
      skip: 0,
      filter: {},
    })[0];
    if (!response.schema || !Object.keys(response.schema).length) {
      throw new Error(t('errors.pages.pageWithoutSchema'));
    }
    schema = response.schema;
  }

  const transferItem = _.omit(cloneDeepWithMetadata(item), 'identifier');
  clearVirtualProperties(transferItem);
  cleanUpInternalProperties(transferItem, schema);
  cleanupRecursive(transferItem);

  // merging prefillData for targetPage
  const filterUrl = new URLSearchParams(new URL(targetPage.dataUrl, window.location.origin).search).get('$filter');
  if (filterUrl) {
    const [prefill] = getParsedFilter(formatEntries(createFilter(filterUrl)) as object, undefined, true);
    _.merge(transferItem, prefill);
  }

  try {
    const validationResult = validateFormData(transferItem, schema, generateValidationAjv({ removeAdditional: false }, transformCodeSync));
    if (validationResult.errors.length > 0) {
      throw new Error(t('errors.pages.validation'));
    }
  } catch (e) {
    const validationResult = validateFormData(
      transferItem,
      makeSchemaPartial(schema),
      generateValidationAjv({ removeAdditional: true }, transformCodeSync)
    );
    if (validationResult.errors.length > 0 && !tolerateValidationErrors) {
      console.warn(`Failed to transform item for ${schema.$id}`, validationResult.errors, transferItem, item);
      throw new Error(t('errors.pages.validation'));
    }
  }

  return { item: transferItem, schema };
}

export const copyItemToPage = async (item: IDataItem, targetPage: Schemas.CpaPage | undefined, t: TFunction): Promise<void> => {
  if (!targetPage || !targetPage.dataEndpoint?.identifier || !targetPage.dataUrl || !item) {
    throw Error(t('errors.pages.unavailablePage'));
  }

  const { item: newItem, schema } = await transformItemForAnotherEntity(item, targetPage, t);

  await postEntityToEndpoint(targetPage.dataEndpoint?.identifier, targetPage.dataUrl, newItem, schema);
};
