import { WidgetProps } from '@rjsf/core';
import { Theme as FluentUITheme } from '@rjsf/fluent-ui';
import React from 'react';

import AnyOfField from '../components/AnyOfField/AnyOfField';
import ArrayFieldTemplate from '../components/ArrayFieldTemplate/ArrayFieldTemplate';
import DateTimeWidget from '../components/DateTimeWidget/DateTimeWidget';
import DateWidget from '../components/DateWidget/DateWidget';
import CheckboxWidget from '../components/CheckboxWidget/CheckboxWidget';
import DescriptionField from '../components/DescriptionField/DescriptionField';
import ErrorList from '../components/ErrorList/ErrorList';
import FieldTemplate from '../components/FieldTemplate/FieldTemplate';
import FileWidget from '../components/FileWidget/FileWidget';
import ObjectFieldTemplate from '../components/ObjectFieldTemplate/ObjectFieldTemplate';
import RadioWidget from '../components/RadioWidget/RadioWidget';
import SelectWidget from '../components/SelectWidget/SelectWidget';
import TextWidget from '../components/TextWidget/TextWidget';
import TitleField from '../components/TitleField/TitleField';
import withBlurEventHandling from '../hocs/withBlurEventHandling';

const widgetsWithoutBlurSupport = ['CheckboxWidget', 'CheckboxesWidget'];

export default {
  ...FluentUITheme,
  // Override widgets based on https://github.com/rjsf-team/react-jsonschema-form/tree/master/packages/fluent-ui/src
  widgets: Object.entries({
    ...FluentUITheme.widgets,
    CheckboxWidget,
    TextWidget,
    SelectWidget,
    URLWidget: TextWidget,
    FileWidget: FileWidget,
    RadioWidget: RadioWidget as React.FC<WidgetProps>,
    DateTimeWidget: DateTimeWidget,
    DateWidget: DateWidget,
  }).reduce<typeof FluentUITheme.widgets>((acc, [key, value]) => {
    return {
      ...acc,
      [key]: withBlurEventHandling(value, widgetsWithoutBlurSupport.includes(key)),
    };
  }, {}),
  fields: {
    ...FluentUITheme.fields,
    DescriptionField,
    TitleField,
    AnyOfField,
  },
  FieldTemplate,
  ObjectFieldTemplate,
  ArrayFieldTemplate,
  ErrorList,
};
