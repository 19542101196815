export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
        throw new Error('Invalid rgb');
    }
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
}
export function hexToHsl(hex) {
    let { r, g, b } = hexToRgb(hex);
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s;
    let l = (max + min) / 2;
    if (max == min) {
        h = s = 0;
    }
    else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);
    return { h, s, l };
}
export function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;
    let c = (1 - Math.abs(2 * l - 1)) * s, x = c * (1 - Math.abs(((h / 60) % 2) - 1)), m = l - c / 2, r = 0, g = 0, b = 0;
    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    }
    else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    }
    else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    }
    else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    }
    else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    }
    else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    let hexR = Math.round((r + m) * 255).toString(16);
    let hexG = Math.round((g + m) * 255).toString(16);
    let hexB = Math.round((b + m) * 255).toString(16);
    if (hexR.length == 1)
        hexR = '0' + r;
    if (hexG.length == 1)
        hexG = '0' + g;
    if (hexB.length == 1)
        hexB = '0' + b;
    return '#' + hexR + hexG + hexB;
}
export function adjustHexColorLightness(hex, amount) {
    if (amount === 0) {
        return hex;
    }
    const { h, s, l } = hexToHsl(hex);
    return hslToHex(h, s, Math.max(Math.min(l + amount, 100), 0));
}
export function findColorsInBetween(hex1, hex2, amount = 1) {
    const { h: h1, s: s1, l: l1 } = hexToHsl(hex1);
    const { h: h2, s: s2, l: l2 } = hexToHsl(hex2);
    const result = [];
    for (let i = 1; i <= amount; i++) {
        result.push(hslToHex(h1 + ((h2 - h1) / (amount + 1)) * i, s1 + ((s2 - s1) / (amount + 1)) * i, l1 + ((l2 - l1) / (amount + 1)) * i));
    }
    return result;
}
