import { EnvironmentType } from '@cp/base-types';
import { apm } from '@elastic/apm-rum';
import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ReactGA from 'react-ga';
import { initReactI18next } from 'react-i18next';

import { generateLanguageFallbackMap, getAppBasePath } from '../helpers';
import { ILanguage } from '../types';

import { Environment } from './environment';

export const initLanguages = async (languages: ILanguage[]): Promise<boolean | TFunction> =>
  i18n.isInitialized ||
  i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: generateLanguageFallbackMap(languages),
      supportedLngs: languages.map(({ identifier }) => identifier),
      debug: Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Local || Environment.env.REACT_APP_ENVIRONMENT === EnvironmentType.Dev,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${getAppBasePath()}/locales/{{lng}}.json`,
        crossDomain: false,
        withCredentials: false,
      },
      load: 'currentOnly',
    });

i18n.on('missingKey', (lngs, namespace, key, res) => {
  console.warn(`Missing localization key. Languages: ${lngs}. Namespace: ${namespace}. Key: ${key}. Resource: ${res}.`);
});

// @ts-ignore
window.changeLang = (lng: string): void => {
  i18n.changeLanguage(lng);
};

i18n.on('languageChanged', (language) => {
  ReactGA.set({ language });
  apm.setCustomContext({ language });
});

export { i18n };
