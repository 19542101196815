import { AuthenticationResult, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { RouterLocation } from 'connected-react-router';
import { Location } from 'history';

import { getAppBasePath } from './location';

export const generateJourneyUrl = (journey: string, authAuthority: string): string => {
  return authAuthority.replace(/{journey}/g, encodeURIComponent(journey));
};

export type AuthError = Error & { errorMessage?: string };

export interface ParsedError {
  code: string;
  text: string;
  error: AuthError;
}

export const parseErrorMessage = (error?: AuthError): ParsedError | null => {
  if (!error) {
    return null;
  }

  const message = error.message || error.errorMessage;

  if (!message) {
    console.error(`Failed to parse error. No message. ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`, error);
    return { code: '0', text: 'Unhandled error', error };
  }

  const [main] = message.split('\n');

  if (!main) {
    console.error('Failed to parse error', error);
    return { code: '0', text: message, error };
  }

  const [code, text] = main.split(': ');

  if (!code || !text) {
    return { code: '0', text: main, error };
  }

  return { code, text, error };
};

export async function generateMsalProvider({
  journeySignIn,
  clientId,
  authority,
  redirectLocation,
  authorityMetadata,
}: {
  authority: string;
  journeySignIn: string;
  clientId: string;
  redirectLocation: string;
  authorityMetadata?: string;
}): Promise<PublicClientApplication> {
  const authorityJourney = new URL(generateJourneyUrl(journeySignIn, authority));
  const msalProvider = new PublicClientApplication({
    auth: {
      authority: authorityJourney.href,
      clientId,
      postLogoutRedirectUri: `${redirectLocation}${getAppBasePath()}`,
      redirectUri: `${redirectLocation}${getAppBasePath()}`,
      knownAuthorities: [authorityJourney.origin, 'https://sts.windows.net'],
      navigateToLoginRequestUrl: false,
      authorityMetadata,
    },
    system: {
      loggerOptions: {
        loggerCallback: (logLevel, message): void => {
          console.debug(`[MSAL] ${message}`);
        },
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  });

  await msalProvider.initialize();
  return msalProvider;
}

export const generateRedirectState = (location: RouterLocation<unknown> | Location): string => {
  return btoa(JSON.stringify({ pathname: location.pathname + (location.search || '') + (location.hash || '') }));
};

export async function signOut(msalProvider: PublicClientApplication, authenticationResult: AuthenticationResult, location?: Location): Promise<void> {
  // Clear CpAuthToken cookie. Used for server-side authentication.
  document.cookie = 'CpAuthToken=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=None; Secure';

  if (!msalProvider || !authenticationResult?.account?.homeAccountId) {
    console.warn(`Failed to start logout. Not authenticated.`);
    return;
  }

  const accountInfo = msalProvider.getAccountByHomeId(authenticationResult?.account?.homeAccountId);
  if (!accountInfo) {
    console.warn(`Cannot get account info for starting logout, but we will try.`);
    await msalProvider.logoutRedirect({
      state: location && generateRedirectState(location),
    });
    return;
  }

  console.debug(`Executing logout`);
  await msalProvider.logoutRedirect({
    account: accountInfo,
    state: location && generateRedirectState(location),
  });
}

export async function signIn(msalProvider: PublicClientApplication, scopes: string[], location: Location): Promise<void> {
  console.debug(`Executing signin`);
  await msalProvider.loginRedirect({
    scopes: scopes,
    state: generateRedirectState(location),
  });
}
