import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { signIn, signOut } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import { useLoadableData } from '@cpa/base-core/hooks';
import { TypeConstants } from '@cp/base-types';
import { axiosDictionary } from '@cpa/base-core/api';
import { DataServiceModules } from '@cp/base-utils';

import UserCallout, { IUserCalloutProps } from './UserCallout';

export interface IUserCalloutContainerProps extends Pick<IUserCalloutProps, 'target' | 'hidden' | 'onDismiss' | 'children'> {}

const UserCalloutContainer: React.FC<IUserCalloutContainerProps> = (props) => {
  const [t] = useTranslation();
  const msalProvider = useSelector((state: IGlobalState) => state.app.msalProvider);
  const configuration = useSelector((state: IGlobalState) => state.app.cpa?.configuration);
  const currentLocation = useSelector((state: IGlobalState) => state.router.location);
  const user = useSelector((state: IGlobalState) => state.auth.user);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!props.hidden) {
      setInitialized(true);
    }
  }, [props.hidden]);

  const { loadItems, items, isFetching } = useLoadableData(
    `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpaLink)}`,
    axiosDictionary.appDataService
  );
  useEffect(() => {
    if (!initialized) {
      return;
    }

    loadItems({}, { overwriteExistingData: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const startSignIn = useCallback(async () => {
    if (!msalProvider || !configuration?.msalAuthScopes) {
      console.warn(`Failed to start signin. Msal is not initialized.`);
      return;
    }

    await signIn(msalProvider, configuration.msalAuthScopes ?? [], currentLocation);
  }, [configuration?.msalAuthScopes, currentLocation, msalProvider]);

  const startSignOut = useCallback(async () => {
    if (!msalProvider || !user?.authenticationResult) {
      console.warn(`Failed to start signin. Msal is not initialized.`);
      return;
    }

    await signOut(msalProvider, user.authenticationResult);
  }, [msalProvider, user?.authenticationResult]);

  return (
    <UserCallout
      {...props}
      user={user?.account}
      onSignIn={startSignIn}
      onSignOut={startSignOut}
      signInLabel={t('header.controls.signin.tooltip')}
      signOutLabel={t('header.controls.logout.tooltip')}
      darkMode={darkMode}
      isFetching={isFetching}
      apps={items as IUserCalloutProps['apps']}
      backgroundBlur={false}
      isUserEmailVisible={true}
    />
  );
};

export default UserCalloutContainer;
