import { Dropdown, IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { WidgetProps } from '@rjsf/core';
import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { IJSONSchema } from '@cp/base-types';

import TitleField, { TitleFieldType } from '../TitleField/TitleField';
import DescriptionField from '../DescriptionField/DescriptionField';

import styles from './SelectWidget.module.scss';
import ChoiceGroup from './ChoiceGroup/ChoiceGroup';

const allowedProps = [
  'placeHolder',
  'options',
  'onChange',
  'onChanged',
  'onRenderLabel',
  'onRenderPlaceholder',
  'onRenderPlaceHolder',
  'onRenderTitle',
  'onRenderCaretDown',
  'dropdownWidth',
  'responsiveMode',
  'defaultSelectedKeys',
  'selectedKeys',
  'multiselectDelimiter',
  'notifyOnReselect',
  'isDisabled',
  'keytipProps',
  'theme',
  'styles',

  // ISelectableDroppableTextProps
  'componentRef',
  'label',
  'ariaLabel',
  'id',
  'className',
  'defaultSelectedKey',
  'selectedKey',
  'multiSelect',
  'options',
  'onRenderContainer',
  'onRenderList',
  'onRenderItem',
  'onRenderOption',
  'onDismiss',
  'disabled',
  'required',
  'calloutProps',
  'panelProps',
  'errorMessage',
  'placeholder',
  'openOnKeyboardFocus',
];

const SelectWidget = ({
  schema,
  uiSchema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  registry,
  onChange,
  rawErrors,
  onBlur,
  onFocus,
}: WidgetProps & { schema: IJSONSchema }): JSX.Element => {
  const { enumOptions, enumDisabled } = options;

  const _onChange = useCallback(
    (_ev?: React.FormEvent<HTMLElement>, item?: IDropdownOption): void => {
      if (!item) {
        return;
      }
      if (multiple) {
        const valueOrDefault = value || [];
        if (item.selected) {
          onChange([...valueOrDefault, item.key]);
        } else {
          onChange(valueOrDefault.filter((key: string) => key !== item.key));
        }
      } else {
        onChange(item.key);
      }
    },
    [multiple, onChange, value]
  );
  const _onBlur = useCallback(
    (e: React.FocusEvent): void =>
      onBlur(
        id,
        (
          e.target as unknown as {
            value: string;
          }
        ).value
      ),
    [id, onBlur]
  );

  const _onFocus = useCallback(
    (e: React.FocusEvent): void =>
      onFocus(
        id,
        (
          e.target as unknown as {
            value: string;
          }
        ).value
      ),
    [id, onFocus]
  );

  const newOptions = (enumOptions as { value: string; label: string }[]).map((option, index) => ({
    key: option.value,
    text: option.label,
    disabled: ((enumDisabled as string[]) || []).indexOf(option.value) !== -1,
    icon: schema.cp_enumIcons?.[index] ?? schema.items?.anyOf?.[index]?.cp_enumIcons?.[0] ?? undefined,
    description: schema.cp_enumDescriptions?.[index] ?? schema.items?.anyOf?.[index]?.description ?? undefined,
  }));

  const defaultProps = multiple ? { defaultSelectedKeys: value } : { defaultSelectedKey: value };

  const uiProps = _.pick(options.props || {}, allowedProps) as object;

  const onChoiceGroupChange = useCallback(
    (selectedKey?: string): void => {
      if (!selectedKey) {
        return;
      }
      if (multiple) {
        const valueOrDefault = value || [];
        if (!valueOrDefault.includes(selectedKey)) {
          onChange([...valueOrDefault, selectedKey]);
        } else {
          onChange(valueOrDefault.filter((key: string) => key !== selectedKey));
        }
      } else {
        onChange(selectedKey);
      }
    },
    [multiple, onChange, value]
  );

  const selector = useMemo(() => {
    if (schema.format === 'cp:TileOption' || (schema.items as IJSONSchema | null)?.format === 'cp:TileOption') {
      const options = newOptions.map((option) => ({
        key: option.key,
        text: option.text,
        disabled: option.disabled,
        iconName: option.icon ?? 'ReceiptCheck',
        description: option.description,
      }));
      const iconDisabled = schema.cp_disableIcon || schema.items?.cp_disableIcon;
      return (
        <ChoiceGroup
          multiple={multiple}
          disableIcon={iconDisabled}
          options={options}
          required={required}
          selectedKeys={Array.isArray(value) ? value : [value]}
          disabled={disabled || readonly || schema.readOnly}
          onChange={onChoiceGroupChange}
        />
      );
    }
    return (
      <Dropdown
        multiSelect={multiple}
        required={required}
        options={newOptions}
        errorMessage={(rawErrors || []).join('\n')}
        disabled={disabled || readonly || schema.readOnly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        {...defaultProps}
        {...uiProps}
      />
    );
  }, [
    _onBlur,
    _onChange,
    _onFocus,
    defaultProps,
    disabled,
    multiple,
    newOptions,
    onChoiceGroupChange,
    rawErrors,
    readonly,
    required,
    schema.items,
    schema.format,
    schema.readOnly,
    schema.cp_disableIcon,
    schema.items?.cp_disableIcon,
    uiProps,
    value,
  ]);

  return (
    <div className={classNames(styles.select, { [styles.selectWrapper]: !label && !schema.title })}>
      <TitleField
        title={label || schema.title}
        type={options.anyOfSelector ? TitleFieldType.Object : TitleFieldType.Primitive}
        onClick={uiSchema?.onLabelClick}
        localizable={uiSchema?.cp_localizable}
        registry={registry}
        required={required}
      />
      <DescriptionField description={schema.description || ''} detailed />
      {selector}
    </div>
  );
};

export default SelectWidget;
