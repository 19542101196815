import { Schemas } from '@cp/base-types';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalDialogType, IDataItem } from '@cpa/base-core/types';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IDropdownOption, PrimaryButton } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { axiosDictionary, getEndpoint, IDataStoreItemDto } from '@cpa/base-core/api';
import notification from '@cpa/base-core/helpers/toast';
import { DataServiceModules } from '@cp/base-utils';
import { showDialog } from '@cpa/base-core/helpers';
import { hideDialog } from '@cpa/base-core/store/app/actions';

import styles from './ChangeBaseLanguageDialog.module.scss';

export interface IChangeBaseLanguageDialogProps {
  page: Schemas.CpaPage;
}

export interface IChangeBaseLanguageDialogRef {
  openDialog(item: IDataItem): void;

  closeDialog(): void;

  setError(error: string): void;
}

const changeBaseLanguageDialogInitialState = {
  item: null as IDataItem | null,
  selectedKey: undefined as string | undefined,
  error: undefined as string | undefined,
};

export const ChangeBaseLanguageDialog = React.forwardRef(function ChangeBaseLanguageDialog(
  { page }: IChangeBaseLanguageDialogProps,
  ref: React.Ref<IChangeBaseLanguageDialogRef>
) {
  const [dialogState, setDialogState] = useState(changeBaseLanguageDialogInitialState);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [t] = useTranslation();
  const allLocales = useSelector((state: IGlobalState) => state.app.allLocales);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const currentBaseLanguage = useMemo(() => {
    if (dialogState.item?._baseLanguage) {
      const matchedLocale = allLocales.find((locale) => locale.identifier === dialogState.item?._baseLanguage);
      return matchedLocale || null;
    }
    return null;
  }, [dialogState?.item, allLocales]);

  const dropdownOptions = useMemo(() => {
    return [
      ...allLocales
        .filter((locale) => locale.identifier !== currentBaseLanguage?.identifier)
        .map((locale) => ({
          key: locale?.identifier,
          text: locale?.name,
          data: locale,
        })),
    ];
  }, [allLocales, currentBaseLanguage]);

  const onDialogClose = useCallback(() => {
    setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    setLoading(false);
    dispatch(hideDialog());
  }, [dispatch]);

  const onSubmit = useCallback(async () => {
    if (page.cpTypeUrl && dialogState.item?.identifier && selectedLanguage) {
      const endpoint = getEndpoint(axiosDictionary.appDataService);
      const notificationId = notification.info(t('common.changingBaseLanguage'), Infinity);
      setLoading(true);
      await endpoint.axios
        .patch<IDataStoreItemDto>(
          `${DataServiceModules.DATA_STORE}/${encodeURIComponent(page.cpTypeUrl)}/${encodeURIComponent(
            dialogState.item.identifier
          )}/base-language/${selectedLanguage}`
        )
        .then(() => {
          notification.success(t('common.baseLanguageChanged'));
        })
        .catch(() => {
          notification.error(t('errors.connection.internalErrorTitle'));
        })
        .finally(() => {
          notification.dismiss(notificationId);
          onDialogClose();
          setLoading(false);
        });
    }
  }, [page?.cpTypeUrl, dialogState.item?.identifier, selectedLanguage]);

  const handleLanguageChange = useCallback((e: React.FormEvent<HTMLElement | HTMLInputElement>, option: IDropdownOption) => {
    setSelectedLanguage(option.key as string);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openDialog(item: IDataItem): void {
        setDialogState((state) => ({ ...state, item, error: undefined }));
      },
      closeDialog(): void {
        onDialogClose();
      },
      setError(error: string): void {
        setDialogState((state) => ({ ...state, error }));
      },
    }),
    [onDialogClose]
  );

  useEffect(() => {
    if (!dialogState.item) return;
    showDialog({
      type: GlobalDialogType.CUSTOM,
      dialogContentProps: {
        type: DialogType.largeHeader,
        showCloseButton: true,
      },
      onClose: onDialogClose,
      modalProps: {
        isBlocking: true,
      },
      width: 'min(800px, 98vw)',
      dialogTypeOptions: {
        renderBody: () => {
          return (
            <div className={styles.dialog}>
              <div className={styles.selectors}>
                <div className={styles.selectorWrapper}>
                  <span className={styles.title}>{t('common.currentBaseLanguage')}</span>
                  <Dropdown
                    options={
                      currentBaseLanguage
                        ? [
                            {
                              key: currentBaseLanguage.identifier,
                              text: currentBaseLanguage.name,
                            },
                          ]
                        : []
                    }
                    defaultSelectedKey={currentBaseLanguage?.identifier}
                    disabled
                  />
                </div>
                <Icon className={styles.arrow} iconName={'arrowright'}></Icon>
                <div className={styles.selectorWrapper}>
                  <span className={styles.title}>{t('common.newBaseLanguage')}</span>
                  <Dropdown options={dropdownOptions} onChange={handleLanguageChange} />
                </div>
              </div>
              <DialogFooter>
                <div className={styles.footer}>
                  <DefaultButton text={t('common.cancel')} onClick={onDialogClose} />
                  <PrimaryButton onClick={onSubmit} text={t('common.confirm')} disabled={loading} />
                </div>
              </DialogFooter>
            </div>
          );
        },
      },
    });
  }, [currentBaseLanguage, dialogState.item, dropdownOptions, handleLanguageChange, loading, onDialogClose, onSubmit, t]);

  return null;
});
