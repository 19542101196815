import Konva from 'konva';
import { Filter } from 'konva/types/Node';

import { ImageEditorOperations } from '../../../../helpers/ImageEditorOperations';
import { ImageEditorChildType, ImageEditorUtils } from '../../../../helpers/ImageEditorUtils';

export abstract class ImageEditorBrightnessContrast {
  private static getBrightnessFilter(amount: number): Filter {
    return (imageData: ImageData) => {
      amount = amount / 100;

      // do not manipulate without proper amount
      if (amount === 1) {
        return;
      }

      // align minimum
      if (amount < 0) {
        amount = 0;
      }

      // in rgba world, every
      // n * 4 + 0 is red,
      // n * 4 + 1 green and
      // n * 4 + 2 is blue
      // the fourth can be skipped as it's the alpha channel
      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i + 0] *= amount;
        imageData.data[i + 1] *= amount;
        imageData.data[i + 2] *= amount;
      }
    };
  }

  private static getContrastFilter(amount: number): Filter {
    return (imageData: ImageData) => {
      amount = amount / 100;

      // do not manipulate without proper amount
      if (amount === 1) {
        return;
      }

      // align minimum
      if (amount < 0) {
        amount = 0;
      }

      // in rgba world, every
      // n * 4 + 0 is red,
      // n * 4 + 1 green and
      // n * 4 + 2 is blue
      // the fourth can be skipped as it's the alpha channel
      // https://gist.github.com/jonathantneal/2053866
      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i + 0] = ((imageData.data[i + 0] / 255 - 0.5) * amount + 0.5) * 255;
        imageData.data[i + 1] = ((imageData.data[i + 1] / 255 - 0.5) * amount + 0.5) * 255;
        imageData.data[i + 2] = ((imageData.data[i + 2] / 255 - 0.5) * amount + 0.5) * 255;
      }
    };
  }

  public static setBrightnessContrast(stage: Konva.Stage, brightness: number, contrast: number): void {
    const layer = ImageEditorUtils.getLayer(stage);
    const image = ImageEditorUtils.getChild(stage, ImageEditorChildType.image);

    image.cache();

    image.filters([this.getBrightnessFilter(brightness), this.getContrastFilter(contrast)]);

    layer.batchDraw();
  }

  public static saveBrightnessContrast(stage: Konva.Stage, brightness: number, contrast: number): string {
    const image = ImageEditorUtils.getChild(stage, ImageEditorChildType.image) as Konva.Image;
    const imageToRender = image.image() as HTMLImageElement;

    const width = imageToRender.width;
    const height = imageToRender.height;

    return ImageEditorOperations.editAndSave(width, height, (renderedStage) => {
      const layer = new Konva.Layer();
      renderedStage.add(layer);

      const imageElement = new Konva.Image({
        x: 0,
        y: 0,
        image: imageToRender,
        width: width,
        height: height,
      });
      layer.add(imageElement);

      imageElement.cache();

      layer.batchDraw();

      imageElement.filters([this.getBrightnessFilter(brightness), this.getContrastFilter(contrast)]);

      layer.batchDraw();
    });
  }
}
