const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpNamespace',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpNamespace' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMember?$filter=cpNamespace%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_aggregationPipeline': ['[{"$set":{"name":{"$ifNull":[{"$concat":["$prefix","  －  ","$identifier"]},"$identifier"]}}}]'],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Namespace',
    'properties': {
        'prefix': {
            'title': 'Prefix',
            'description': '<div>Test</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/prefix', 'rel': 'canonical' }],
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'] },
            'cp_ui': { 'sortOrderForm': 21 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 18 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 19 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['prefix'],
};
export const CpNamespaceSchema = Schema;
