import { DataServiceModules } from '@cp/base-utils';

import { subscribeToEntityModification } from '../store/websocket/saga';

import { rootDataService } from './axios';
import { IDataStoreItemDto } from './entities';

class ItemCache {
  private cache: Map<string, Map<string, Promise<unknown>>> = new Map();

  private async fetchItem<T>(subjectUri: string, identifier: string): Promise<T> {
    const { value } = await rootDataService.get<IDataStoreItemDto<T>>(
      `${DataServiceModules.DATA_STORE}/${encodeURIComponent(subjectUri)}/${encodeURIComponent(identifier)}`
    );
    return value;
  }

  public async get<T>(subjectUri: string, identifier: string): Promise<T> {
    if (!this.cache.has(subjectUri)) {
      this.cache.set(subjectUri, new Map());
      await subscribeToEntityModification(subjectUri);
    }
    const subjectCache = this.cache.get(subjectUri)!;

    if (subjectCache.has(identifier)) {
      return (await subjectCache.get(identifier)) as T;
    }

    const itemPromise = this.fetchItem<T>(subjectUri, identifier);
    subjectCache.set(identifier, itemPromise);
    return itemPromise;
  }

  public clearAll() {
    this.cache.clear();
  }

  public clearSubject(subjectUri: string) {
    this.cache.delete(subjectUri);
  }
}

export const globalItemCache = new ItemCache();
