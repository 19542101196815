export var IdmAccountApprovalStatus;
(function (IdmAccountApprovalStatus) {
    IdmAccountApprovalStatus["Pending"] = "pending";
    IdmAccountApprovalStatus["Approved"] = "approved";
})(IdmAccountApprovalStatus || (IdmAccountApprovalStatus = {}));
export var IdmAccountsItemType;
(function (IdmAccountsItemType) {
    IdmAccountsItemType["Object"] = "object";
    IdmAccountsItemType["EscapedJson"] = "escapedJson";
    IdmAccountsItemType["Base64"] = "base64";
})(IdmAccountsItemType || (IdmAccountsItemType = {}));
