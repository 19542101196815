import { IJSONSchema, TypeConstants } from '@cp/base-types';
import { axiosDictionary, getEndpoint, getSchema, postEntityToEndpoint } from '@cpa/base-core/api';
import { ItemInfoContext } from '@cpa/base-core/constants';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { Icon } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import { DataServiceModules } from '@cp/base-utils';

import Form from '../../components/Form/Form';
import LoadingArea from '../../components/LoadingArea/LoadingArea';
import Widget from '../../components/Widget/Widget';

import styles from './Contact.module.scss';

const itemInfoContext = {
  type: 'add' as const,
  showMessage: () => {},
  dismissMessage: () => {},
  baseLanguage: '',
  currentLanguage: '',
};

const Contact: React.FC = () => {
  const [relationRequestSchema, setRelationRequestSchema] = useState<IJSONSchema>();
  const [loading, setLoading] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [t] = useTranslation();

  const user = useSelector((state: IGlobalState) => state.auth.user);
  const name = useSelector((state: IGlobalState) => state.auth.user?.account?.name || '-');
  const email = useSelector((state: IGlobalState) => state.auth.user?.account.email || '-');
  const cpaIdentifier = useSelector((state: IGlobalState) => state.app?.cpa?.identifier);
  const initialFormData = useMemo<IDataItem>(
    () => ({
      name,
      email,
      description: t('contact.messagePlaceholder') as string,
      createdByUser: user?.account?.email,
      cpa: cpaIdentifier ? { identifier: cpaIdentifier } : undefined,
    }),
    [name, email, t, user, cpaIdentifier]
  );

  useEffect(() => {
    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
    getSchema(urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(TypeConstants.CpRelationRequest)}`)).then(
      (schema) => {
        setRelationRequestSchema(schema);
      }
    );
  }, []);

  const onRelationRequestSubmit = useCallback(
    async ({ formData }: { formData: IDataItem }) => {
      setLoading(true);

      await postEntityToEndpoint(
        axiosDictionary.appDataService,
        `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpRelationRequest)}`,
        formData,
        relationRequestSchema
      );

      setLoading(false);
      setMessageSent(true);
    },
    [relationRequestSchema]
  );

  return (
    <>
      <section className={styles.contact}>
        <Widget title={t('contact.title')} subtitle={t('contact.subtitle')}>
          <div className={styles.wrapper}>
            <LoadingArea loading={loading} />
            {messageSent && (
              <div className={styles.thankYou}>
                <Icon className={darkMode ? styles.iconDark : styles.icon} iconName="ReceiptCheck" />
                <p className={darkMode ? styles.textDark : styles.text}>{t('contact.thankYouText')}</p>
              </div>
            )}
            <div style={{ visibility: messageSent ? 'hidden' : 'visible' }}>
              <ItemInfoContext.Provider value={itemInfoContext}>
                {!!relationRequestSchema && (
                  <Form
                    schema={relationRequestSchema}
                    formData={initialFormData}
                    disableStickySubmitButton={true}
                    onSubmit={onRelationRequestSubmit}
                  />
                )}
              </ItemInfoContext.Provider>
            </div>
          </div>
        </Widget>
      </section>
    </>
  );
};

export default Contact;
