const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotationProperty',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotationProperty' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpIndividual?$filter=annotations%2FannotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpProperty?$filter=annotations%2FannotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpClass?$filter=annotations%2FannotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotation?$filter=annotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType?$filter=annotations%2FannotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType?$filter=assignments%2Fannotations%2FannotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Fplaywright%2FTestPerson?$filter=annotations%2FannotationPropertyType%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_aggregationPipeline': ['[{"$set":{"name":"$identifier"}}]'],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Annotation Property',
    'properties': {
        'format': {
            'title': 'Format',
            'description': '<div>Formating information are used to determine how the input should be rendered in the user interface.</div>\n<div>For example, if a multiline string, a number, or a short string is expected.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypePropertyFormat' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/format', 'rel': 'canonical' },
            ],
        },
        'suggestionTypeUrl': {
            'title': 'Suggestion Type Url',
            'description': '<div>Some annotation properties always point to values of a certain type. For example, a seeAlso annotation property expects a cp:CpType as an input. </div>\n<div> </div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/suggestionTypeUrl', 'rel': 'canonical' },
            ],
        },
        'enums': {
            'title': 'Enums',
            'type': 'array',
            'items': {
                'anyOf': [
                    { 'type': 'boolean', 'title': 'List of Boolean' },
                    { 'type': 'string', 'title': 'List of String' },
                    { 'type': 'number', 'title': 'List of Number' },
                ],
            },
            'cp_ui': { 'sortOrderForm': 35 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/enum', 'rel': 'canonical' }],
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const CpAnnotationPropertySchema = Schema;
