import { PublicClientApplication } from '@azure/msal-browser';
import { Action, handleActions } from 'redux-actions';
import { EnvironmentType, Schemas, IPrefixMap } from '@cp/base-types';
import { generatePrefixMapFromCpNamespaces, getRelevantEndpoint } from '@cp/base-utils';

import { createAxiosForDataEndpoint } from '../../api/axios';
import {
  axiosDictionary,
  generatePropertyFormatsMap,
  IMatchedDataEndpointWithAxios,
  rootDataService,
  rootDataServiceWithoutCancelInterceptor,
} from '../../api';
import { BaseApi, ILanguage, MatchedCpa, IDataEndpoint, IMatchedDataEndpoint, IGlobalDialogState, IGlobalDrawerState } from '../../types';
import { Environment } from '../../app/environment';

import {
  appNotReady,
  appReady,
  authReady,
  finishLoading,
  hideDialog,
  setCpa,
  setDataEndpoints,
  setLocales,
  showDialog,
  startLoading,
  setCurrentTourBubble,
  connectionError,
  setNamespaces,
  showDrawer,
  hideDrawer,
  setPropertyFormats,
} from './actions';

const DEFAULT_STATE: IAppState = {
  isReady: false,
  msalProvider: null,
  pages: [],
  footerLinks: [],
  loadingCounter: 0,
  locales: [],
  allLocales: [],
  cpa: null,
  dataEndpoints: new Map<string, IMatchedDataEndpointWithAxios>(),
  globalDialog: null,
  globalDrawer: null,
  currentTourBubble: null,
  connectionError: null,
  prefixMap: {},
  cookieAvailable: false,
  propertyFormatMap: {},
};

export interface IAppState {
  isReady: boolean;
  msalProvider: PublicClientApplication | null;
  pages: Schemas.CpaPage[];
  footerLinks: Schemas.CpaFooter[];
  loadingCounter: number;
  locales: ILanguage[];
  allLocales: ILanguage[];
  cpa: MatchedCpa | null;
  dataEndpoints: Map<string, IMatchedDataEndpointWithAxios>;
  globalDialog: IGlobalDialogState | null;
  globalDrawer: IGlobalDrawerState | null;
  currentTourBubble: {
    step: string;
    isWidget: boolean;
  } | null;
  connectionError: number | null;
  prefixMap: IPrefixMap;
  cookieAvailable: boolean;
  propertyFormatMap: Record<string, string>;
}

export default handleActions<IAppState, unknown>(
  {
    [appReady.toString()]: (
      state,
      {
        payload,
      }: Action<{
        pages: Schemas.CpaPage[];
        footerLinks: Schemas.CpaFooter[];
      }>
    ) => {
      const sortedPages = [...payload.pages].sort(
        (
          { sortOrderMenu: sortOrderLeft = Infinity, parentCpaPage: parentLeft, showInMenu: showInMenuLeft },
          { sortOrderMenu: sortOrderRight = Infinity, parentCpaPage: parentRight, showInMenu: showInMenuRight }
        ) => {
          if (!showInMenuLeft && showInMenuRight) {
            return 1;
          }
          if (showInMenuLeft && !showInMenuRight) {
            return -1;
          }

          if (parentLeft?.identifier && !parentRight?.identifier) {
            return 1;
          }
          if (!parentLeft?.identifier && parentRight?.identifier) {
            return -1;
          }

          return sortOrderLeft < sortOrderRight ? -1 : sortOrderLeft > sortOrderRight ? 1 : 0;
        }
      );
      return {
        ...state,
        isReady: true,
        pages: sortedPages,
        footerLinks: payload.footerLinks,
      };
    },
    [appNotReady.toString()]: (state) => ({
      ...state,
      isReady: false,
    }),
    [startLoading.toString()]: (state) => ({
      ...state,
      loadingCounter: state.loadingCounter + 1,
    }),
    [finishLoading.toString()]: (state) => ({
      ...state,
      loadingCounter: state.loadingCounter - 1 < 0 ? 0 : state.loadingCounter - 1,
    }),
    [setLocales.toString()]: (state, { payload }: Action<{ locales: ILanguage[]; allLocales: ILanguage[] }>) => ({
      ...state,
      locales: payload.locales,
      allLocales: payload.allLocales,
    }),
    [setCpa.toString()]: (state, { payload }: Action<{ cpa: Schemas.Cpa }>) => {
      if (!payload.cpa.configurations) {
        throw new Error('Missing CPA configurations');
      }

      let configuration: MatchedCpa['configuration'] | undefined;
      switch (Environment.env.REACT_APP_ENVIRONMENT) {
        case EnvironmentType.Local:
          configuration = payload.cpa.configurations.localConfiguration;
          break;
        case EnvironmentType.Dev:
          configuration = payload.cpa.configurations.developmentConfiguration;
          break;
        case EnvironmentType.Staging:
          configuration = payload.cpa.configurations.stagingConfiguration;
          break;
        case EnvironmentType.Prod:
          configuration = payload.cpa.configurations.productionConfiguration;
          break;
        default:
          configuration = payload.cpa.configurations.localConfiguration;
          break;
      }

      if (!configuration) {
        throw new Error(`Missing CPA configuration for ${Environment.env.REACT_APP_ENVIRONMENT}`);
      }

      return {
        ...state,
        cpa: {
          ...payload.cpa,
          configuration: configuration,
        },
      };
    },
    [setNamespaces.toString()]: (state, { payload }: Action<{ namespaces: Schemas.CpNamespace[] }>) => {
      return {
        ...state,
        prefixMap: generatePrefixMapFromCpNamespaces(payload.namespaces),
      };
    },
    [setPropertyFormats.toString()]: (state, { payload }: Action<{ propertyFormats: Schemas.CpTypePropertyFormat[] }>) => {
      return {
        ...state,
        propertyFormatMap: generatePropertyFormatsMap(payload.propertyFormats),
      };
    },
    [setDataEndpoints.toString()]: (state, { payload }: Action<{ endpoints: IDataEndpoint[] }>) => {
      const matchedEndpointsMap = new Map<string, IMatchedDataEndpointWithAxios>(
        payload.endpoints
          .map((endpoint: IDataEndpoint): [string, IMatchedDataEndpointWithAxios] | null => {
            const url = getRelevantEndpoint(endpoint.endpoints, Environment.env.REACT_APP_ENVIRONMENT!);

            if (!url) {
              return null;
            }
            const matchedDataEndpoint: IMatchedDataEndpoint = {
              ...endpoint,
              url,
            };

            return [
              endpoint.identifier,
              {
                ...matchedDataEndpoint,
                axios: createAxiosForDataEndpoint(matchedDataEndpoint, false, state.cpa?.configuration.apiKey),
                axiosWithoutCancelInterceptor: createAxiosForDataEndpoint(matchedDataEndpoint, false, state.cpa?.configuration.apiKey, true),
              },
            ];
          })
          .filter((item) => item !== null) as [string, IMatchedDataEndpointWithAxios][]
      );

      matchedEndpointsMap.set(axiosDictionary.appDataService, {
        identifier: axiosDictionary.appDataService,
        name: axiosDictionary.appDataService,
        axios: rootDataService,
        axiosWithoutCancelInterceptor: rootDataServiceWithoutCancelInterceptor,
        dataType: BaseApi.DataService,
        url: Environment.env.REACT_APP_ROOT_DATA_SERVICE_URL as string,
        withCredentials: true,
      });

      return {
        ...state,
        dataEndpoints: matchedEndpointsMap,
      };
    },
    [authReady.toString()]: (state, { payload }: Action<{ msalProvider: PublicClientApplication }>) => ({
      ...state,
      msalProvider: payload.msalProvider,
    }),
    [showDialog.toString()]: (state, { payload }: Action<IAppState['globalDialog']>) => ({
      ...state,
      globalDialog: payload,
    }),
    [hideDialog.toString()]: (state) => ({
      ...state,
      globalDialog: null,
    }),
    [showDrawer.toString()]: (state, { payload }: Action<IAppState['globalDrawer']>) => ({
      ...state,
      globalDrawer: payload,
    }),
    [hideDrawer.toString()]: (state) => ({
      ...state,
      globalDrawer: null,
    }),
    [setCurrentTourBubble.toString()]: (state, { payload }: Action<{ bubble: { step: string; isWidget: boolean } }>) => ({
      ...state,
      currentTourBubble: payload.bubble,
    }),
    [connectionError.toString()]: (state, { payload }: Action<{ errorCode: number }>) => {
      return state.connectionError
        ? state
        : {
            ...state,
            connectionError: payload.errorCode,
          };
    },
  },
  DEFAULT_STATE
);
