import React from 'react';
import { IComponentWithOptions, IDataItem, ITableRowOption } from '@cpa/base-core/types';
import notification from '@cpa/base-core/helpers/toast';
import { i18n } from '@cpa/base-core/app';

import SolutionExplorerComponentsOnly from './SolutionExplorerComponentsOnly';
import { addAsComponent, checkIfSolutionCreatedInRootContext } from './utils';

const SolutionExplorerWrapped: React.FC & IComponentWithOptions<ITableRowOption> = SolutionExplorerComponentsOnly.bind({});
SolutionExplorerWrapped.options = {
  ...SolutionExplorerComponentsOnly.options,
  table: {
    ...SolutionExplorerComponentsOnly.options?.table,
    dragDrop: {
      allowDropToParent: true,
    },
    disabledManagedColumnsWidth: true,
    customActions: [
      {
        key: 'copy',
        text: 'common.copy',
        icon: 'Copy',
        canShow(selectedItems, page): boolean {
          return selectedItems.length === 1 && !!page.allowCreate && !!page.allowDelete;
        },
        async onClick(selectedItems, { copyActionHandler, onEdit, reloadCurrentItems, tableItems }) {
          if (!tableItems?.length) {
            return;
          }

          const item = tableItems[0] as IDataItem;
          const componentParentItem = item.__component ? item.__parentItem?.__originalItem || item.__parentItem : undefined;
          const newItem = await copyActionHandler([item]);
          if (!newItem || !componentParentItem || !!componentParentItem.standard) {
            return;
          }

          addAsComponent(componentParentItem, (h) => h?.(() => newItem, item, reloadCurrentItems), onEdit);
        },
      },
      {
        key: 'add',
        text: 'common.add',
        icon: 'CalculatorAddition',
        canShow(selectedItems, page): boolean {
          return (selectedItems.length === 0 || selectedItems.length === 1) && !!page.allowCreate && !!page.allowModify;
        },
        onClick(selectedItems, { onAddClick, onEdit, schema }) {
          if (!schema.cp_item_context) {
            return;
          }
          addAsComponent(schema.cp_item_context, (customAddHandler) => onAddClick(schema.cp_item_context, customAddHandler), onEdit, true);
        },
      },
    ],
  },
};

const removeCustomAction = SolutionExplorerComponentsOnly.options?.table?.customActions?.find(
  (customAction) => customAction.key === 'remove_component'
);
if (removeCustomAction) {
  SolutionExplorerWrapped.options.table?.customActions?.push({
    ...removeCustomAction,
    canShow(selectedItems, page, schema): boolean {
      return (
        !!schema &&
        removeCustomAction.canShow(selectedItems, page, schema) &&
        selectedItems.every((selectedItem) => !checkIfSolutionCreatedInRootContext(selectedItem, schema.cp_item_context, schema))
      );
    },
  });

  SolutionExplorerWrapped.options.table?.customActions?.push({
    key: 'delete',
    text: 'common.removeAndDelete',
    icon: 'Delete',
    canShow(selectedItems, page, schema): boolean {
      return (
        !!schema &&
        removeCustomAction.canShow(selectedItems, page, schema) &&
        !!page.allowDelete &&
        selectedItems.every((selectedItem) => checkIfSolutionCreatedInRootContext(selectedItem, schema.cp_item_context, schema))
      );
    },
    async onClick(selectedItems, context) {
      const removeResult = (await removeCustomAction.onClick(selectedItems, {
        ...context,
        reloadCurrentItems: () => {},
      })) as boolean;
      if (!removeResult) {
        return;
      }

      const toastId = notification.info(i18n.t('common.deletingItem'));
      try {
        await context.onDelete(selectedItems);
        notification.dismiss(toastId);
        notification.success(i18n.t('common.itemDeleted'));
        context.clearTableSelection();
        await context.reloadCurrentItems();
      } catch (error) {
        if (toastId) {
          notification.dismiss(toastId);
        }
        notification.error(error.message);
        console.error(error);
      }
    },
  });
}

export default SolutionExplorerWrapped;
