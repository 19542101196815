import Konva from 'konva';

import { ImageEditorChildType, ImageEditorUtils } from './ImageEditorUtils';

export abstract class ImageEditorAdaptivity {
  public static getSizeDimensions(
    imageElement: HTMLImageElement,
    wrapper: HTMLDivElement
  ): {
    x: number;
    y: number;
    width: number;
    height: number;
    scale: number;
  } {
    const container = wrapper.parentElement as HTMLDivElement;

    const padding = 20;

    const containerHeight = container.offsetHeight - padding * 2;
    const containerWidth = container.offsetWidth - padding * 2;

    const aspectRatio = imageElement.height / imageElement.width;

    let height = imageElement.height;
    let width = imageElement.width;

    if (height > containerHeight) {
      height = containerHeight;
      width = containerHeight / aspectRatio;
    }

    if (width > containerWidth) {
      width = containerWidth;
      height = containerWidth * aspectRatio;
    }

    const canvasBoundingRect = wrapper.getBoundingClientRect();

    return {
      width: width,
      height: height,
      scale: width / imageElement.width,
      x: canvasBoundingRect.left,
      y: canvasBoundingRect.top,
    };
  }

  public static refreshSize(stage: Konva.Stage, wrapper: HTMLDivElement): void {
    if (!stage || !wrapper) {
      return;
    }

    const stageContainer = stage.container() as HTMLDivElement;

    const image = ImageEditorUtils.getChild(stage, ImageEditorChildType.image) as Konva.Image;
    const imageElement = image.image() as HTMLImageElement;

    const { width, height, scale, x, y } = this.getSizeDimensions(imageElement, wrapper);

    stage.width(width);
    stage.height(height);
    stage.scaleX(scale);
    stage.scaleY(scale);

    stage.batchDraw();

    stageContainer.style.height = height + 'px';
    stageContainer.style.width = width + 'px';
    stageContainer.style.top = y + 'px';
    stageContainer.style.left = x + 'px';

    wrapper.style.width = width + 'px';
    wrapper.style.minWidth = width + 'px';
    wrapper.style.maxWidth = width + 'px';

    wrapper.style.height = height + 'px';
    wrapper.style.minHeight = height + 'px';
    wrapper.style.maxHeight = height + 'px';
  }
}
