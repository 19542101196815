export const DataItemProperties = {
    INTERNAL_ID_KEY: '_id',
    ID_KEY: 'identifier',
    E_TAG_KEY: '_eTag',
    BASE_LANGUAGE_KEY: '_baseLanguage',
    IS_LOCALIZED_KEY: '__isLocalized',
    META_DATA_KEY: '_cp:dataServiceMetadata',
    SEARCH_TEXT_KEY: '_searchText',
    NO_SERIES_KEY: '_noSeries',
    CHILD_COUNT: '__childrenCount',
    CURRENT_VERSION: '__version',
    HAS_LOCALIZATIONS: '__hasLocalizations',
    PUBLISHED: '__published',
    BLOCK_LOCALIZATION: 'blockLocalization',
};
