const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://schema.org/SoftwareApplication',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FSoftwareApplication' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Software Application',
    'description': 'A software application.',
    'properties': {
        'applicationSuite': {
            'title': 'Application Suite',
            'description': 'The name of the application suite to which the application belongs (e.g. Excel belongs to Office).',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/applicationSuite', 'rel': 'canonical' }],
        },
        'countriesSupported': {
            'title': 'Countries Supported',
            'description': 'Countries for which the application is supported. You can also provide the two-letter ISO 3166-1 alpha-2 country code.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/countriesSupported', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['applicationSuite'],
};
export const SoftwareApplicationSchema = Schema;
