import React, { ElementType } from 'react';
import { IComponentWithOptions, ITableRowOption } from '@cpa/base-core/types';

import SolutionExplorer, { SolutionExplorerRowActionsMode } from './SolutionExplorer';

function withComponentsPromoted(WrappedComponent: ElementType): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} rowActionsMode={SolutionExplorerRowActionsMode.ComponentsPromoted} />;
  };
}

const SolutionExplorerWrapped: React.FC & IComponentWithOptions<ITableRowOption> = withComponentsPromoted(SolutionExplorer);
SolutionExplorerWrapped.options = {
  table: SolutionExplorer.options?.table,
};
export default SolutionExplorerWrapped;
