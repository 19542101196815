import { IJSONSchema } from '@cp/base-types';
import { IDataItem } from '@cpa/base-core/types';
import Form, { AjvError, ErrorSchema, FormProps, utils } from '@rjsf/core';
import React from 'react';
import { validateFormData, cleanupRecursive, clearVirtualProperties } from '@cpa/base-core/helpers';
import { cloneDeepWithMetadata } from '@cp/base-utils';

const { getDefaultRegistry } = utils;

class FormComponent<T extends IDataItem> extends Form<T> {
  public getRegistry = (): ReturnType<typeof getDefaultRegistry> & Record<string, unknown> => {
    const { fields, widgets } = getDefaultRegistry();
    return {
      fields: { ...fields, ...this.props.fields },
      widgets: { ...widgets, ...this.props.widgets },
      ArrayFieldTemplate: this.props.ArrayFieldTemplate,
      ObjectFieldTemplate: this.props.ObjectFieldTemplate,
      FieldTemplate: this.props.FieldTemplate,
      definitions: this.props.schema.definitions || {},
      rootSchema: this.props.schema,
      rootFormData: this.props.formData,
      formContext: this.props.formContext || {},
      formRef: this,
    };
  };

  public validate = (formData: T): { errors: AjvError[]; errorSchema: ErrorSchema } => {
    // reset errors before validation
    this.setState({
      errors: {},
      errorSchema: {},
      schemaValidationErrors: {},
      schemaValidationErrorSchema: {},
    });

    const formDataToValidate = cloneDeepWithMetadata(formData);
    clearVirtualProperties(formDataToValidate);
    cleanupRecursive(formDataToValidate);
    return validateFormData(formDataToValidate, this.props.schema as IJSONSchema);
  };
}

export type FormRef = React.Ref<FormComponent<{}>>;

const JsonSchemaForm: React.FC<FormProps<{}> & { formRef?: React.Ref<FormComponent<{}>> }> = ({ formRef, children, ...rest }) => (
  <FormComponent ref={formRef} {...rest}>
    {children}
  </FormComponent>
);

export default JsonSchemaForm;
