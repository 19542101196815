import { createAxiosForDataEndpoint } from '@cpa/base-core/api';
import { BaseApi } from '@cpa/base-core/types';

export const getIconNameForWorkItemType = (workItemType: string) => {
  switch (workItemType) {
    case 'Bug':
      return 'ladybugsolid';
    case 'Epic':
      return 'crownsolid';
    case 'Feature':
      return 'trophy2solid';
    case 'Issue':
      return 'constructionconesolid';
    case 'Task':
      return 'tasksolid';
    case 'Test Case':
      return 'testcase';
    case 'Test Plan':
      return 'testplan';
    case 'Test Suite':
      return 'testsuite';
    case 'User Story':
      return 'dictionary';
    default:
      return 'diamondsolid';
  }
};

export const getClassNamesForWorkItemType = (workItemType: string) => {
  switch (workItemType) {
    case 'Bug':
      return 'bug';
    case 'Epic':
      return 'epic';
    case 'Feature':
      return 'feature';
    case 'Issue':
      return 'issue';
    case 'Task':
      return 'task';
    case 'Test Case':
      return 'testCase';
    case 'Test Plan':
      return 'testPlan';
    case 'Test Suite':
      return 'testSuite';
    case 'User Story':
      return 'userStory';
    default:
      return 'default';
  }
};

const loopApiEndpoint = {
  identifier: '5a098334-d884-45b3-b9e9-6606814a871a',
  name: 'COSMO Loop API',
  url: 'https://func-cosmoloop-prod.azurewebsites.net',
  // url: 'http://localhost:7071',
  dataType: BaseApi.LoopApi,
  withCredentials: false,
};

const loopApiSiEndpoint = {
  identifier: 'b4e3739d-7c90-4993-b9ea-20a0c57e7e9e',
  name: 'COSMO Loop API (COSMO Consult SI)',
  url: 'https://func-cosmoloop-si-prod.azurewebsites.net',
  // url: 'http://localhost:7071',
  dataType: BaseApi.LoopApiSi,
  withCredentials: false,
};

export const loopApi = createAxiosForDataEndpoint(loopApiEndpoint, true);
export const loopApiSi = createAxiosForDataEndpoint(loopApiSiEndpoint, true);
