import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { DialogType, Spinner, SpinnerSize } from '@fluentui/react';
import { useMediaQuery } from 'react-responsive';
import { showDialog } from '@cpa/base-core/helpers';
import { GlobalDialogType } from '@cpa/base-core/types';
import { hideDialog } from '@cpa/base-core/store/app/actions';
import { useDispatch } from 'react-redux';

import ImageEditor from './ImageEditor/ImageEditor';
import styles from './ImageEditorDialog.module.scss';

export interface IImageEditorDialogProps {
  onSubmit: (originalUrl: string, url: string) => void;
}

export interface IImageEditorDialogRef {
  openDialog(filename: string, imageUrl: string): void;

  closeDialog(): void;
}

export const ImageEditorDialog = React.forwardRef(function ImageEditorDialog(
  { onSubmit }: IImageEditorDialogProps,
  ref: React.Ref<IImageEditorDialogRef>
) {
  const dispatch = useDispatch();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });

  const [isLoading, setIsLoading] = useState<boolean>();

  const [originalImageUrl, setOriginalImageUrl] = useState<string>();
  const [filename, setFilename] = useState<string>();

  const [image, setImage] = useState<HTMLImageElement>();

  const onSubmitDialog = useCallback(
    (imageUrl: string) => {
      if (originalImageUrl) {
        onSubmit(originalImageUrl, imageUrl);

        setOriginalImageUrl(undefined);
        dispatch(hideDialog());
      }
    },
    [dispatch, onSubmit, originalImageUrl]
  );

  const onDismissDialog = useCallback(() => {
    setOriginalImageUrl(undefined);
    dispatch(hideDialog());
  }, [dispatch]);

  useImperativeHandle(
    ref,
    () => ({
      openDialog(filename: string, imageUrl: string): void {
        setIsLoading(true);
        setOriginalImageUrl(imageUrl);
        setFilename(filename);

        const image = new window.Image();
        image.crossOrigin = 'Anonymous';
        image.src = imageUrl;
        image.onload = () => {
          setImage(image);
          setIsLoading(false);
        };
      },
      closeDialog(): void {
        onDismissDialog();
      },
    }),
    [onDismissDialog]
  );

  useEffect(() => {
    if (!originalImageUrl) return;
    showDialog({
      type: GlobalDialogType.CUSTOM,
      dialogContentProps: { type: DialogType.largeHeader },
      onClose: onDismissDialog,
      className: styles.dialog,
      width: isMobileDevice ? '100%' : '90%',
      dialogTypeOptions: {
        renderBody: () => {
          return (
            <div className={styles.wrapper}>
              {isLoading && (
                <div className={styles.loader}>
                  <Spinner size={SpinnerSize.large} />
                </div>
              )}

              {!isLoading && image && (
                <>
                  <ImageEditor filename={filename!} image={image} onSubmit={onSubmitDialog} onClose={onDismissDialog} />
                </>
              )}
            </div>
          );
        },
      },
    });
  }, [filename, image, isLoading, isMobileDevice, onDismissDialog, onSubmitDialog, originalImageUrl]);

  return null;
});
