import { IJSONSchema, Schemas } from '@cp/base-types';
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDataItem } from '@cpa/base-core/types';
import { DialogType, MessageBar, MessageBarType } from '@fluentui/react';

import Difference from '../../../../Difference/Difference';
import Drawer from '../../../../Drawer/Drawer';
import { IItemSelectionDialogRef, ItemSelectionDialog } from '../../../../ItemSelectionDialog/ItemSelectionDialog';

import styles from './CompareDialog.module.scss';
import { IDiffSource } from '../../../../Difference/DifferenceAsObjects/helpers/difference';

export interface ICompareDialogProps {
  page: Schemas.CpaPage;
}

export interface ICompareDialogRef {
  openDialog(item: IDataItem): void;

  closeDialog(): void;

  setError(error: string): void;
}

const compareDialogInitialState = {
  item: null as IDataItem | null,
  selectedKey: undefined as string | undefined,
  error: undefined as string | undefined,
};

export const CompareDialog = React.forwardRef(function CompareDialog({ page }: ICompareDialogProps, ref: React.Ref<ICompareDialogRef>) {
  const [dialogState, setDialogState] = useState(compareDialogInitialState);
  const [t] = useTranslation();
  const itemSelectionDialogRef = useRef<IItemSelectionDialogRef>(null);

  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: t('common.compare'),
    }),
    [t]
  );

  const [mergingItems, setMergingItems] = useState<[IDataItem, IDataItem] | [null, null]>([null, null]);

  const onDismissDialog = useCallback(() => {
    setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    setMergingItems([null, null]);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openDialog(item: IDataItem): void {
        itemSelectionDialogRef.current?.openDialog();
        setDialogState((state) => ({ ...state, item, error: undefined }));
      },
      closeDialog(): void {
        itemSelectionDialogRef.current?.closeDialog();
        setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
        setMergingItems([null, null]);
      },
      setError(error: string): void {
        setDialogState((state) => ({ ...state, error }));
      },
    }),
    []
  );

  const [schema, setSchema] = useState<IJSONSchema>();

  const handleItemClick = useCallback(
    (item: IDataItem, schema: IJSONSchema) => {
      setSchema(schema);
      setMergingItems([dialogState.item!, item]);
      setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    },
    [dialogState.item]
  );

  const itemsToCompare = useMemo(() => {
    if (!schema || !mergingItems[0] || !mergingItems[1]) {
      return [];
    }

    const itemsToCompare: IDiffSource[] = [];

    const itemA = mergingItems[0] as IDataItem;
    const itemB = mergingItems[1] as IDataItem;

    itemsToCompare.push({
      itemA: itemA,
      itemB: itemB,
      schema: schema,
      nameA: (itemA.name || itemA.identifier || '-') as unknown as string,
      nameB: (itemA.name || itemA.identifier || '-') as unknown as string,
    });

    return itemsToCompare;
  }, [mergingItems, schema]);

  return (
    <>
      <ItemSelectionDialog ref={itemSelectionDialogRef} page={page} onSubmit={handleItemClick} dialogContentProps={dialogContentProps} />
      <Drawer
        isOpen={!!mergingItems[0] && !!mergingItems[1]}
        onClose={onDismissDialog}
        defaultMaximized={true}
        disableMaximizing={true}
        className={styles.differenceDialog}
      >
        {!!dialogState.error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {dialogState.error}
          </MessageBar>
        )}
        {!!schema && !!mergingItems[0] && !!mergingItems[1] && (
          <div className={styles.differenceDialogBody}>
            <Difference page={page} itemsToCompare={itemsToCompare} />
          </div>
        )}
      </Drawer>
    </>
  );
});
