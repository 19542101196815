import { IJSONSchema, Schemas } from '@cp/base-types';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { IDataStoreItemDto, axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { TableKeyPrefix } from '@cpa/base-core/constants';
import { showDialog } from '@cpa/base-core/helpers';
import notification from '@cpa/base-core/helpers/toast';
import { useLoadableData } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { hideDialog } from '@cpa/base-core/store/app/actions';
import { GlobalDialogType, ICustomActionUnbound, IDataItem } from '@cpa/base-core/types';
import { DefaultButton, Dialog, DialogFooter, DialogType, MessageBarType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '../../../Drawer/Drawer';
import MessageBars from '../../../MessageBars/MessageBars';
import ScrollingContent from '../../../ScrollingContent/ScrollingContent';
import ShimmerGrid from '../../../ShimmerGrid/ShimmerGrid';
import Widget from '../../../Widget/Widget';
import { SettingsItemType } from '../../../Widget/components/Settings/Settings';
import { handleShare } from '../../utils/share';
import { getSchemaUIOptions } from '../../../../screens/GenericScreen/utils';
import ImageEditor from '../../../Form/components/FileWidget/components/FilesInfo/ImageEditorDialog/ImageEditor/ImageEditor';

import styles from './VersionsDrawer.module.scss';
import VersionsDrawerCompare from './VersionsDrawerCompare/VersionsDrawerCompare';

export interface IVersionsDrawerProps {
  isOpen: boolean;
  page: Schemas.CpaPage;
  pageSchema: IJSONSchema;
  item: IDataItem | null;
  onClose: (...args: unknown[]) => void;
  onUpdate?: (...args: unknown[]) => void;
  comparedVersions?: [string, string];
}

export const VersionsDrawer: React.FC<IVersionsDrawerProps> = ({ isOpen, item, page, pageSchema, onClose, onUpdate, comparedVersions }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const allLocales = useSelector((state: IGlobalState) => state.app.allLocales);
  const [t] = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [isRevertDialogOpened, { setTrue: openRevertDialog, setFalse: closeRevertDialog }] = useBoolean(false);
  const [isDifferenceDrawerOpened, { setTrue: openDifferenceDrawer, setFalse: closeDifferenceDrawer }] = useBoolean(false);
  const [isPublishMajorVersionDialogOpened, { setTrue: openPublishMajorVersionDialog, setFalse: closePublishMajorVersionDialog }] = useBoolean(false);
  const [differenceDrawerItems, setDifferenceDrawerItems] = useState<[IDataItem | null, IDataItem | null]>([null, null]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isCompareForced = useRef(false);
  const dispatch = useDispatch();
  const versionsUrl = useMemo(() => {
    if (!page.dataUrl || !item?.identifier) {
      return;
    }
    const url = new URL(page.dataUrl, window.location.origin);

    if (!url.pathname.endsWith('/')) {
      url.pathname += '/';
    }

    url.pathname += `${encodeURIComponent(item.identifier)}/versions`;

    return url.pathname;
  }, [page.dataUrl, item?.identifier]);

  const {
    loadItems,
    schema,
    totalItems,
    isFetching,
    errors: loadableErrors,
    items,
  } = useLoadableData(versionsUrl || '/', page.dataEndpoint?.identifier || axiosDictionary.appDataService);
  useEffect(() => {
    loadItems({}, { resetExistingData: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionsUrl, page.dataEndpoint?.identifier]);

  useEffect(() => {
    if (!isFetching && !isCompareForced.current && comparedVersions && comparedVersions.length) {
      const foundVersions = items.filter((item) => item.number && comparedVersions.includes(item.number as string));
      if (foundVersions.length !== comparedVersions.length) return;
      handleShowDifferenceClick(foundVersions);
      isCompareForced.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const onPageRefresh = useCallback(() => {
    loadItems({}, { resetExistingData: true });
  }, [loadItems]);

  const versionSchema: IJSONSchema | null = useMemo(() => {
    if (!schema) {
      return null;
    }

    const clonedSchema = cloneDeepWithMetadata(schema);

    if (clonedSchema.properties?.item && typeof clonedSchema.properties.item === 'object') {
      clonedSchema.properties.item = pageSchema;
    }

    if (clonedSchema.properties?.localizations && typeof clonedSchema.properties.localizations === 'object') {
      clonedSchema.properties.localizations = {
        ...(clonedSchema.properties.localizations as object),
        properties: allLocales.reduce((acc, locale) => {
          return {
            ...acc,
            [locale.identifier]: {
              ...pageSchema,
              title: locale.name,
            },
          };
        }, {}),
      };
    }

    return clonedSchema;
  }, [schema, pageSchema, allLocales]);

  const sortedData = useMemo(() => {
    if (!items || !items.length) {
      return items;
    }

    return items.slice().sort((a: IDataItem & { createdAt: number }, b: IDataItem & { createdAt: number }) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      return 0;
    });
  }, [items]);

  const itemToRevert = useRef<string | null>(null);
  const itemToPublish = useRef<string | null>(null);

  const handleRevertClick = useCallback(
    (items?: IDataItem[]) => {
      if (!items || items.length !== 1 || !items[0] || !items[0].createdAt || !page.dataEndpoint?.identifier) {
        return;
      }

      openRevertDialog();
      itemToRevert.current = items[0].number as string;
    },
    [page.dataEndpoint, openRevertDialog, itemToRevert]
  );
  const handlePublishMajorVersionClick = useCallback(
    (items?: IDataItem[]) => {
      if (!items || items.length !== 1 || !items[0] || !items[0].createdAt || !page.dataEndpoint?.identifier) {
        return;
      }

      openPublishMajorVersionDialog();
      itemToPublish.current = items[0].number as string;
    },
    [page.dataEndpoint, openPublishMajorVersionDialog, itemToPublish]
  );
  const handleClearVersionsClick = useCallback(async () => {
    if (!page.dataEndpoint?.identifier || !versionsUrl) {
      return;
    }

    const clearConfirmed = await showDialog({
      dialogContentProps: {
        type: DialogType.largeHeader,
        title: t('common.clearVersions'),
        subText: t('common.clearVersionsConfirmation'),
      },
      primaryButtonText: 'common.confirm',
      secondaryButtonText: 'common.cancel',
      closeOnClickOutside: true,
      closeOnAction: false,
    });

    try {
      if (clearConfirmed) {
        const endpoint = getEndpoint(page.dataEndpoint.identifier);
        await endpoint.axios.delete(versionsUrl);
        onUpdate?.();
      }
    } catch (e) {
      setErrors([e.message]);
    } finally {
      dispatch(hideDialog());
    }
  }, [page.dataEndpoint?.identifier, versionsUrl, t, onUpdate, dispatch]);

  const handleCopyToClipboardClick = useCallback(
    (selectedItems?: IDataItem[]) => {
      if (!selectedItems || selectedItems.length !== 2 || !item?.identifier) {
        return;
      }

      handleShare(
        page,
        item.identifier as string,
        {
          action: 'COMPARE_VERSIONS',
          versionA: selectedItems[1].number as string,
          versionB: selectedItems[0].number as string,
        },
        !!page.singleItemTemplate?.identifier // We use filter instead of param in case of single item template
      );
      notification.success(t('common.copiedToClipboard'));
    },
    [item?.identifier, page, t]
  );

  const handleShowDifferenceClick = useCallback(
    (selectedItems?: IDataItem[]) => {
      if (!selectedItems || selectedItems.length > 2) {
        return;
      }

      if (selectedItems.length === 2) {
        const ordered = [...selectedItems].sort((a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime());

        setDifferenceDrawerItems([ordered[0] as IDataItem, ordered[1] as IDataItem]);
      } else {
        const firstItem = sortedData.sort((a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime())[0];

        setDifferenceDrawerItems([firstItem, selectedItems[0] as IDataItem]);
      }
      openDifferenceDrawer();
    },
    [openDifferenceDrawer, sortedData]
  );

  const handleCloseDifferenceDrawer = useCallback(() => {
    setDifferenceDrawerItems([null, null]);
    closeDifferenceDrawer();
  }, [closeDifferenceDrawer]);

  const handlePublishMajorVersionDialogClose = useCallback(() => {
    closePublishMajorVersionDialog();
    dispatch(hideDialog());
  }, [closePublishMajorVersionDialog, dispatch]);

  const handleRevertDialogClose = useCallback(() => {
    closeRevertDialog();
    dispatch(hideDialog());
  }, [closeRevertDialog, dispatch]);

  const handleRevert = useCallback(async () => {
    if (!page.dataEndpoint?.identifier) {
      return;
    }

    const endpoint = getEndpoint(page.dataEndpoint.identifier);
    try {
      setIsLoading(true);
      await endpoint.axios.put<IDataStoreItemDto<IDataItem>>(`${versionsUrl}/${itemToRevert.current}/revert`);
      onUpdate?.();
    } catch (e) {
      setErrors([e.message]);
    } finally {
      setIsLoading(false);
      handleRevertDialogClose();
      itemToRevert.current = null;
    }
  }, [page.dataEndpoint, versionsUrl, onUpdate, itemToRevert, handleRevertDialogClose]);

  const handlePublish = useCallback(async () => {
    if (!page.dataEndpoint?.identifier) {
      return;
    }

    const endpoint = getEndpoint(page.dataEndpoint.identifier);
    try {
      await endpoint.axios.put<IDataStoreItemDto<IDataItem>>(`${versionsUrl}/${itemToPublish.current}/publish-major`, undefined, {
        headers: {
          'x-cp-cpa-page-path': page.path,
        },
      });
      onUpdate?.();
    } catch (e) {
      setErrors([e.message]);
    } finally {
      handlePublishMajorVersionDialogClose();
      itemToPublish.current = null;
    }
  }, [page.dataEndpoint?.identifier, page.path, versionsUrl, onUpdate, handlePublishMajorVersionDialogClose]);

  useEffect(() => {
    if (!isRevertDialogOpened || !itemToRevert) {
      return;
    }
    showDialog({
      type: GlobalDialogType.CUSTOM,
      dialogContentProps: {
        type: DialogType.largeHeader,
        title: t('common.revert'),
        subText: t('common.revertConfirmation'),
      },
      onClose: handleRevertDialogClose,
      dialogTypeOptions: {
        renderBody: () => {
          return (
            <DialogFooter>
              <PrimaryButton onClick={handleRevert} text={t('common.confirm')} disabled={isLoading} />
              <DefaultButton onClick={handleRevertDialogClose} text={t('common.cancel')} />
            </DialogFooter>
          );
        },
      },
    });
  }, [handleRevertDialogClose, handleRevert, isLoading, isRevertDialogOpened, t]);

  useEffect(() => {
    if (!isPublishMajorVersionDialogOpened || !itemToPublish) {
      return;
    }

    showDialog({
      type: GlobalDialogType.CUSTOM,
      dialogContentProps: {
        type: DialogType.largeHeader,
        title: t('common.publishMajorVersion'),
        subText: t('common.publishMajorVersionConfirmation'),
      },
      onClose: handlePublishMajorVersionDialogClose,
      dialogTypeOptions: {
        renderBody: () => {
          return (
            <DialogFooter>
              <PrimaryButton onClick={handlePublish} text={t('common.confirm')} />
              <DefaultButton onClick={handlePublishMajorVersionDialogClose} text={t('common.cancel')} />
            </DialogFooter>
          );
        },
      },
    });
  }, [handlePublish, handlePublishMajorVersionDialogClose, isPublishMajorVersionDialogOpened, t]);

  const widgetSettings = useMemo(() => {
    return [
      {
        type: SettingsItemType.REFRESH,
        onClick: onPageRefresh,
        disabled: isFetching,
      },
    ];
  }, [isFetching, onPageRefresh]);

  const customActions: ICustomActionUnbound[] = useMemo(
    () => [
      {
        onClick: handleRevertClick,
        button: {
          key: 'revert',
          title: t('common.revert'),
          icon: 'RevToggleKey',
          condition: (selected): boolean => selected.length === 1,
        },
      },
      ...(pageSchema?.cp_enableLatestMajor
        ? [
            {
              onClick: handlePublishMajorVersionClick,
              button: {
                key: 'publishMajorVersion',
                title: t('common.publishMajorVersion'),
                icon: 'VersionControlPush',
                condition: (selected: IDataItem[]): boolean => selected.length === 1,
              },
            },
          ]
        : []),
      {
        onClick: handleCopyToClipboardClick,
        button: {
          key: 'copyToClipboard',
          title: t('common.share'),
          condition: (selected): boolean => selected.length === 2,
          icon: 'Share',
        },
      },
      {
        onClick: handleShowDifferenceClick,
        button: {
          key: 'openDiffDrawer',
          title: (selected): string => (selected.length === 1 ? t('common.compareToCurrent') : t('common.compareTwoVersions')),
          condition: (selected): boolean => selected.length > 0 && selected.length < 3,
          icon: 'Compare',
        },
      },
      {
        onClick: handleClearVersionsClick,
        button: {
          key: 'clearVersions',
          title: t('common.clearVersions'),
          icon: 'RemoveFromShoppingList',
          condition: (selected): boolean => selected.length === 0 && !!page.allowDelete,
        },
      },
    ],
    [
      handleClearVersionsClick,
      handleCopyToClipboardClick,
      handlePublishMajorVersionClick,
      handleRevertClick,
      handleShowDifferenceClick,
      page.allowDelete,
      pageSchema?.cp_enableLatestMajor,
      t,
    ]
  );

  const uiOptions = useMemo(() => getSchemaUIOptions(schema), [schema]);

  const handleDrawerClose = useCallback(() => {
    dispatch(hideDialog());
    onClose();
  }, [dispatch, onClose]);

  return (
    <>
      <Drawer isOpen={isOpen} onClose={handleDrawerClose}>
        <div className={styles.drawerRoot}>
          <MessageBars messageBarType={MessageBarType.error} isMultiline={true} messages={[...loadableErrors, ...errors]} />
          <Widget title={t('common.versions')} settingsItems={widgetSettings} headerIconName={'DependencyAdd'}>
            <div style={{ padding: 20 }}>
              {isFetching && sortedData.length === 0 && !versionSchema ? (
                <ShimmerGrid darkMode={darkMode} gridArea={'"a" "b" "c" "d" "e"'} gridGap={10} height={250} />
              ) : (
                <ScrollingContent
                  tableKey={TableKeyPrefix.Versions}
                  disableItemSharing={true}
                  disableDoubleClick={false}
                  isWidget={true}
                  pageSize={5}
                  widgetMaxHeight={'calc(100vh - 280px)'}
                  data={{
                    items: sortedData,
                    schema: versionSchema,
                    isFetching,
                    totalItems: totalItems || 0,
                    page: {
                      identifier: 'versions',
                      name: t('common.versions'),
                      spanColumn: 'number',
                    } as Schemas.CpaPage,
                  }}
                  customActions={customActions}
                  hiddenInTable={uiOptions.hiddenInTable}
                />
              )}
            </div>
          </Widget>
        </div>
      </Drawer>
      <Drawer isOpen={isDifferenceDrawerOpened} onClose={handleCloseDifferenceDrawer} defaultMaximized={true} disableMaximizing={true}>
        <VersionsDrawerCompare page={page} differenceDrawerItems={differenceDrawerItems} pageSchema={pageSchema} versionSchema={versionSchema} />
      </Drawer>
    </>
  );
};

export default VersionsDrawer;
