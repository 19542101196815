import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import { DataServiceModules, resolveSubjectUri } from '@cp/base-utils';
import { axiosDictionary, getEndpoint, getSchema } from '@cpa/base-core/api';
import { IJSONSchema } from '@cp/base-types';
import urlJoin from 'url-join';
import { Spinner } from '@fluentui/react';
import { FormContext } from '@cpa/base-core/constants';
import { useFormField } from '@cpa/base-core/hooks/form';
import { useDebouncedValue } from '@cpa/base-core/hooks';

import { TextWidgetProps } from '../../TextWidget';
import FilterComponent from '../../../../../ScrollingContent/components/Filter/Filter';
import TitleField, { TitleFieldType } from '../../../TitleField/TitleField';

const Filter: React.FC<TextWidgetProps> = ({ onChange, value, label, required, registry }) => {
  const formContext = useContext(FormContext);
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);
  const [isFetching, setIsFetching] = useState(false);
  const unExpandedType = useFormField<string>('cpTypeUrl');
  const resolvedCpTypeUrl = useMemo(() => {
    if (!unExpandedType) {
      return undefined;
    }

    try {
      return resolveSubjectUri(unExpandedType, prefixMap);
    } catch (e) {
      return undefined;
    }
  }, [prefixMap, unExpandedType]);
  const [debouncedResolvedCpTypeUrl] = useDebouncedValue(resolvedCpTypeUrl, 500);

  const [schema, setSchema] = useState<IJSONSchema | undefined>(undefined);

  useEffect(() => {
    setSchema(undefined);

    if (!debouncedResolvedCpTypeUrl) {
      return;
    }

    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
    setIsFetching(true);
    getSchema(urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(debouncedResolvedCpTypeUrl)}`))
      .then(setSchema)
      .catch((e) => {
        console.error(e);
        setSchema(undefined);
      })
      .finally(() => setIsFetching(false));
  }, [debouncedResolvedCpTypeUrl]);

  let filterContent = null;
  if (isFetching) {
    filterContent = <Spinner />;
  } else if (debouncedResolvedCpTypeUrl && schema) {
    // Bind filter to selected cpTypeUrl in form
    filterContent = (
      <FilterComponent
        onChange={onChange}
        value={value}
        dataUrl={`${DataServiceModules.DATA_STORE}/${encodeURIComponent(debouncedResolvedCpTypeUrl)}`}
        schema={schema}
        disableSavedFilters={true}
        disableFullTextFilter={true}
        disableNotifications={true}
      />
    );
  } else if (formContext.page?.dataUrl && registry.rootSchema) {
    // Bind filter to current page
    filterContent = (
      <FilterComponent
        onChange={onChange}
        value={value}
        dataUrl={formContext.page.dataUrl}
        schema={registry.rootSchema}
        disableSavedFilters={true}
        disableFullTextFilter={true}
        disableNotifications={true}
      />
    );
  }

  return (
    <>
      <TitleField type={TitleFieldType.Primitive} title={label} required={required} />
      {filterContent}
    </>
  );
};

export default Filter;
