import { IJSONSchema } from '@cp/base-types';
import * as _ from 'lodash';
import { cloneDeepWithMetadata, isDefinedAndNotEmpty, resolveSchemaPath } from '@cp/base-utils';
import { createPatch, Operation } from 'rfc6902';

import { IDataItem } from '../../types';

// Check if value should stay in final payload
const isForCleanup = (value: unknown): boolean => {
  if (!isDefinedAndNotEmpty(value)) {
    return true;
  }

  // case for multi-undefined fields in object
  if (value && typeof value === 'object' && Object.values(value).every((v) => v === undefined || v === null)) {
    return true;
  }

  // @all: additional conditions can be added here to mark the field for cleanup

  return false;
};

// Mutates input
export const cleanupRecursive = (formData: Record<string | number, unknown>): void => {
  if (!formData || typeof formData !== 'object') {
    return;
  }

  for (const [key, value] of Object.entries(formData)) {
    if (value && typeof value === 'object') {
      // Object / Array
      cleanupRecursive(value as Record<string | number, unknown>);
    }

    if (isForCleanup(value) && !Array.isArray(formData)) {
      delete formData[key];
    }
  }
};

const createNestedObjectFromKeys = (keys: string[], finalValue: unknown): Record<string, unknown> => ({
  [keys.shift() as string]: keys.length ? createNestedObjectFromKeys(keys, finalValue) : finalValue,
});
export const expandObject = (obj: Record<string, unknown>, separator = '.'): Record<string, unknown> =>
  Object.entries(obj ?? {}).reduce((acc, [key, value]) => _.merge({}, acc, createNestedObjectFromKeys(key.split(separator), value)), {});

export function removeEmptyObjects(obj: object): object {
  return _.chain(obj)
    .pickBy(_.isObject) // pick objects only
    .mapValues(removeEmptyObjects) // call only for object values
    .omitBy(_.isEmpty) // remove all empty objects
    .assign(_.omitBy(obj, _.isObject)) // assign back primitive values
    .value();
}

export function isFormDataEqual(v1: object, v2: object): boolean {
  return _.isEqual(removeEmptyObjects(v1), removeEmptyObjects(v2));
}

export const flatObject = (object: Record<string, unknown>, res: Record<string, string> = {}, separator = ''): Record<string, string> => {
  const clonedRes = cloneDeepWithMetadata(res);
  return flatObjectRecursive(object, [], clonedRes, separator);
};

const flatObjectRecursive = (
  object: Record<string, unknown>,
  keysStack: string[] = [],
  res: Record<string, string>,
  separator = '.'
): Record<string, string> => {
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object') {
      flatObjectRecursive(value as Record<string, unknown>, [...keysStack, key], res);
    } else {
      res[[...keysStack, key].join(separator)] = value as string;
    }
  });
  return res;
};

const getFilterObjectFromSchemaRecursive = (
  schema: IJSONSchema,
  res: Record<string, undefined>,
  keysStack: string[] = []
): Record<string, undefined> => {
  Object.entries(schema.properties ?? {}).forEach(([propertyKey, property]) => {
    if (typeof (property as IJSONSchema).properties === 'object') {
      getFilterObjectFromSchemaRecursive(property as IJSONSchema, res, [...keysStack, propertyKey]);
    } else {
      res[encodeURIComponent([...keysStack, propertyKey].join('/'))] = undefined;
    }
  });
  return res;
};

export const getFilterObjectFromSchema = (schema: IJSONSchema): Record<string, undefined> => {
  const result = {};

  return getFilterObjectFromSchemaRecursive(schema, result, []);
};

export const replaceByPattern = (text: string, source: unknown): string => {
  return text.replace(/\{\{(?<field>.*?)\}\}/g, (match, field) => _.get(source, field, match));
};

export const prepareActionLink = (link: string, source: unknown, removeQuery: boolean = false): string => {
  const linkWithoutQuery = link.indexOf('?') >= 0 ? link.substring(0, link.indexOf('?')) : link;
  return replaceByPattern(removeQuery ? linkWithoutQuery : link, source);
};
export const isDefined = (value: unknown): boolean => value !== null && value !== undefined;

export function getRelationWrapperPath(propertyJsonPath: string): string {
  const parts = _.toPath(propertyJsonPath);
  if (parts[parts.length - 1] === 'identifier') {
    return parts.slice(0, -1).join('.');
  }
  return propertyJsonPath;
}

export function setParentForItem<T extends IDataItem = IDataItem>(
  item: T,
  parentPropertyJsonPath: string,
  parentIdentifier: string,
  schema: IJSONSchema | null
): T {
  const pathToRelationWrapper = getRelationWrapperPath(parentPropertyJsonPath);
  const relationWrapperSchema = schema && resolveSchemaPath(schema, pathToRelationWrapper, true);

  if (relationWrapperSchema && (relationWrapperSchema.type === 'array' || relationWrapperSchema.anyOf?.some((s) => s.type === 'array'))) {
    // Treat parent field as array
    const currentValue = _.get(item, pathToRelationWrapper) as IDataItem[];
    if (Array.isArray(currentValue)) {
      currentValue.push({ identifier: parentIdentifier });
    } else {
      _.set(item, pathToRelationWrapper, [{ identifier: parentIdentifier }]);
    }
    return item;
  }

  _.set(item, parentPropertyJsonPath!, parentIdentifier);
  return item;
}

export function createPatchAndUnsureStructure(input: unknown, output: unknown): Operation[] {
  const patch = createPatch(input, output);
  // Correct null behavior
  const correctedPatch = patch
    .map((operation) => {
      if (operation.op === 'replace' && !isDefined(operation.value)) {
        return {
          op: 'remove',
          path: operation.path,
        };
      }
      if (operation.op === 'add' && !isDefined(operation.value)) {
        return null;
      }
      return operation;
    })
    .filter(Boolean) as Operation[];
  const structure = cloneDeepWithMetadata(input);
  for (const operation of correctedPatch) {
    // First element of path is "/", which breaks lodash get/set by path
    const path = operation.path.substring(1);
    // Remove "-", which means add element to array.
    const formattedPath = path
      .split('/')
      .filter((char) => char !== '-')
      .join('.');
    const inputValue = _.get(input, formattedPath);
    if (!inputValue) {
      const outputValue = _.get(output, formattedPath);
      if (outputValue && typeof outputValue === 'object') {
        _.set(structure as object, formattedPath, Array.isArray(outputValue) ? [] : {});
      }
    }
  }
  const structurePatch = createPatch(input, structure);
  return [...structurePatch, ...correctedPatch];
}
