// Disabled animation for this component

import { Environment } from '@cpa/base-core/app/environment';
import { IGenericComponentProps, IScreenProps } from '@cpa/base-core/types';
import React from 'react';

// import Animation from '../../components/Animation/Animation';
import HtmlContent from '../../components/HtmlContent/HtmlContent';

import styles from './StaticContent.module.scss';

// import { useAnimationOptions } from '@cpa/base-core/helpers';

const StaticContent: React.FC<IScreenProps & IGenericComponentProps> = ({ page, data, isWidget }) => {
  // const [computedDuration, disableAnimation] = useAnimationOptions();

  return (
    <section className={styles.wrapper}>
      {/* <Animation duration={computedDuration} disable={disableAnimation} delay={0}> */}
      <HtmlContent
        html={page?.staticContent || data?.page?.staticContent}
        allowScripts={Environment.env.REACT_APP_ALLOW_SCRIPTS_IN_STATIC_CONTENT === 'true'}
      />
      {/* </Animation> */}
    </section>
  );
};

export default StaticContent;
