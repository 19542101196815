import { CultureInfo } from './cultureInfo';
import { parseLanguages } from './headerParser';
export function verifyLanguage(language, limitedToLanguages = ['en'], cultureInfo = new CultureInfo('en-US')) {
    let fallbackAllowed = true;
    if (Array.isArray(language))
        language = language[0];
    if (language) {
        try {
            const parsedLanguages = parseLanguages(language);
            fallbackAllowed = parsedLanguages.length > 1 || language === '*';
            const parsedCultureInfos = parsedLanguages
                .map((parsedLanguage) => {
                try {
                    return new CultureInfo(parsedLanguage);
                }
                catch (e) {
                    return null;
                }
            })
                .filter(Boolean);
            for (const testCultureInfo of parsedCultureInfos) {
                if (limitedToLanguages.includes(testCultureInfo.TwoLetterLanguageName) || limitedToLanguages.includes(testCultureInfo.SpecificName)) {
                    cultureInfo = testCultureInfo;
                    break;
                }
                const matchedDefault = limitedToLanguages.find((cultureToMatch) => new CultureInfo(cultureToMatch).TwoLetterLanguageName === testCultureInfo.TwoLetterLanguageName);
                if (matchedDefault) {
                    cultureInfo = new CultureInfo(matchedDefault);
                    break;
                }
            }
        }
        catch (e) { }
    }
    return {
        twoLetterName: cultureInfo.TwoLetterLanguageName,
        specificName: cultureInfo.SpecificName,
        fallbackAllowed,
    };
}
