import React, { ElementType } from 'react';
import { IComponentWithOptions, ITableRowOption } from '@cpa/base-core/types';

import SolutionExplorer, { SolutionExplorerRowActionsMode } from './SolutionExplorer';

function withComponentsOnly(WrappedComponent: ElementType): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} rowActionsMode={SolutionExplorerRowActionsMode.ComponentsOnly} />;
  };
}

const SolutionExplorerWrapped: React.FC & IComponentWithOptions<ITableRowOption> = withComponentsOnly(SolutionExplorer);
SolutionExplorerWrapped.options = {
  table: {
    ...SolutionExplorer.options?.table,
    dynamicRowActions: false,
  },
};
export default SolutionExplorerWrapped;
