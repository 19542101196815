import React from 'react';
import { Schemas } from '@cp/base-types';

export const PageGraphics: React.FC<{ graphic: Schemas.CpaPage['graphic'] }> = ({ graphic }) => {
  if (!graphic || !graphic.url) {
    return null;
  }

  const { align } = graphic;

  return (
    <div
      style={{
        position: 'absolute',
        top: align && align.toLowerCase().includes('top') ? 0 : undefined,
        bottom: align ? (align.toLowerCase().includes('bottom') ? 0 : undefined) : 0,
        opacity: graphic.opacity || 0.3,
        width: 'calc(100% - 40px)',
        pointerEvents: 'none',
      }}
    >
      <img
        alt={graphic.name || 'Page graphics'}
        src={graphic.url}
        style={{
          float: align && align.toLowerCase().includes('right') ? 'right' : 'left',
          paddingBottom: '20px',
          paddingTop: '20px',
          width: `min(${graphic.width || '30%'}, 100%)`,
        }}
      />
    </div>
  );
};
