const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpFeedback',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFeedback' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Feedback',
    'properties': {
        'creator': {
            'title': 'Creator',
            'description': 'A person (alive, dead, undead, or fictional).',
            '$id': 'http://schema.org/Person',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' },
                { 'href': 'http://schema.org/creator', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'email': {
                    'title': 'Email',
                    'description': 'Email address.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                },
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'cpa': {
            'title': 'Cpa',
            '$id': 'http://platform.cosmoconsult.com/ontology/Cpa',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpa', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa' } },
            'cp_ui': { 'sortOrderForm': 32 },
        },
        'cpaPage': {
            'title': 'Cpa Page',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaPage',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpaPage', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentCpaPage.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Page' } },
            'cp_ui': { 'sortOrderForm': 33 },
        },
        'feedback': {
            'title': 'Feedback',
            '$ref': 'https://cpstaticressources.blob.core.windows.net/$web/feedback.json',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/feedback', 'rel': 'canonical' }],
        },
        'done': {
            'title': 'Done',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/done', 'rel': 'canonical' }],
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
    },
};
export const CpFeedbackSchema = Schema;
