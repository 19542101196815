import { BaseError } from './';
export class ServiceBusError extends BaseError {
    constructor(m) {
        super(m ? `Error in service bus. ${m}` : `Error in service bus.`);
        this.name = this.constructor.name;
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        }
        else {
            this.__proto__ = actualProto;
        }
        Error.captureStackTrace(this, this.constructor);
    }
}
