import { EventEmitter } from 'events';

import { generateNewColumn, tableSort, generateParentingExternalDataQuery } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { IGenericComponentData, IDataItem, ITableProps, IOrderOptions } from '@cpa/base-core/types';
import { IObjectWithKey, Selection } from '@fluentui/react';
import { push } from 'connected-react-router';
import React, { RefObject, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import PageCard from './components/PageCard/PageCard';
import styles from './PageCardView.module.scss';

interface IPageCardViewProps extends Pick<ITableProps, 'isODataSupportedByEndpoint'> {
  selection: Selection<IObjectWithKey & IDataItem>;
  data: IGenericComponentData;
  items: IDataItem[];
  selectedItems: IDataItem[];
  onCardClick?: (item: IDataItem) => unknown;
  onCardRightClick?: (item: IDataItem, position: { x: number; y: number; targetId: string }) => unknown;
  containerRef: RefObject<HTMLDivElement>;
  pageSize?: number;
  order?: IOrderOptions;
  selectionEmitter?: EventEmitter;
}

const PageCardView: React.FC<IPageCardViewProps> = ({
  data,
  items,
  onCardClick,
  containerRef,
  pageSize,
  selection,
  selectedItems,
  onCardRightClick,
  order,
  isODataSupportedByEndpoint,
  selectionEmitter,
}): JSX.Element => {
  const getSelectedItemIndex = useCallback(
    (item: IDataItem): number => {
      const targetKey = item.identifier ? 'identifier' : '__identifier';
      return selectedItems.findIndex((i) => i[targetKey] === item[targetKey]);
    },
    [selectedItems]
  );

  const handleItemSelect = useCallback(
    (item: IDataItem, replace?: boolean) => {
      if (replace) {
        selection.setAllSelected(false);
        selection.setKeySelected(selection.getKey(item), true, false);
        return;
      }
      selection.toggleKeySelected(selection.getKey(item));
    },
    [selection]
  );

  const deselectAll = useCallback(() => {
    selection.setAllSelected(false);
  }, [selection]);

  useEffect(() => {
    selectionEmitter?.on('deselectAll', deselectAll);

    return (): void => {
      selectionEmitter?.off('deselectAll', deselectAll);
    };
  }, []);

  useEffect(() => {
    return (): void => {
      deselectAll();
    };
  }, [deselectAll]);

  const shimmeredItems = useMemo<null[]>(() => {
    const { totalItems } = data;
    return totalItems && pageSize ? [...Array(Math.abs(totalItems - items.length > pageSize ? pageSize : totalItems - items.length))].fill(null) : [];
  }, [data, pageSize, items]);

  const getAmountOfColumnsStyle = (width: number): string => {
    let columnAmount = 1;

    if (width > 576) {
      columnAmount = 2;
    }
    if (width > 768) {
      columnAmount = 3;
    }
    if (width > 992) {
      columnAmount = 4;
    }

    return `repeat(${columnAmount}, ${100 / columnAmount}%)`;
  };

  const currentLocation = useSelector((state: IGlobalState) => state.router.location);
  const dispatch = useDispatch();

  const breadcrumbsStructure = useMemo(() => {
    if (!currentLocation.state || typeof currentLocation.state !== 'object') {
      return [];
    }
    const currentState = currentLocation.state as { breadcrumbsStructure?: IDataItem[] } | undefined;
    if (!currentState?.breadcrumbsStructure || !Array.isArray(currentState.breadcrumbsStructure)) {
      return [];
    }

    return currentState.breadcrumbsStructure;
  }, [currentLocation.state]);

  const updateBreadcrumbsStructure = useCallback(
    (newBreadcrumbsStructure: IDataItem[]) => {
      const previousState = currentLocation.state && typeof currentLocation.state === 'object' ? currentLocation.state : {};
      const newParentItem = newBreadcrumbsStructure[newBreadcrumbsStructure.length - 1];

      dispatch(
        push({
          state: {
            ...previousState,
            filterValue: '',
            breadcrumbsStructure: newBreadcrumbsStructure,
            externalDataQuery: generateParentingExternalDataQuery(data.schema, newParentItem),
          },
        })
      );
    },
    [currentLocation.state, data.schema, dispatch]
  );

  const handleNavigationCardClick = useCallback(
    (item: IDataItem) => (): void => {
      const existingItemIndex = breadcrumbsStructure.findIndex(
        (i) => i.identifier === item.identifier || (i.__identifier && i.__identifier === item.__identifier)
      );

      // Append new navigation
      if (existingItemIndex === -1) {
        updateBreadcrumbsStructure([...breadcrumbsStructure, item]);
        return;
      }
    },
    [breadcrumbsStructure, updateBreadcrumbsStructure]
  );

  const handleCardClick = useCallback(
    (item: IDataItem, isFolderItem?: boolean): void => {
      if (isFolderItem) {
        handleNavigationCardClick(item)();
        return;
      }

      onCardClick?.(item);
    },
    [handleNavigationCardClick, onCardClick]
  );

  const sortedItems = useMemo(() => {
    if (!order || !data.schema) {
      return items;
    }

    const column = generateNewColumn(order.columnKey, data.schema);

    return tableSort(items, {
      column: order.columnKey,
      isDesc: !order.isAscending,
      type: column.type || 'string',
      propertySchema: column.propertySchema,
      isODataSupportedByEndpoint: isODataSupportedByEndpoint,
    });
  }, [data.schema, items, order, isODataSupportedByEndpoint]);

  return (
    <div ref={containerRef}>
      <ReactResizeDetector handleWidth={true}>
        {({ width, targetRef }: { width: number; targetRef: React.RefObject<HTMLDivElement> }): JSX.Element => (
          <div ref={targetRef} data-testid="PageCardView" className={styles.list} style={{ gridTemplateColumns: getAmountOfColumnsStyle(width) }}>
            {(data.isFetching ? [...sortedItems, ...shimmeredItems] : sortedItems).map((item, index) => (
              <PageCard
                key={index}
                data={data}
                item={item}
                index={index}
                isSelected={!!item && getSelectedItemIndex(item) !== -1}
                handleItemSelect={handleItemSelect}
                onCardRightClick={onCardRightClick}
                onCardClick={handleCardClick}
              />
            ))}
          </div>
        )}
      </ReactResizeDetector>
    </div>
  );
};

export default PageCardView;
