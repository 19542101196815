import React, { MutableRefObject, useEffect, useRef } from 'react';
import { TooltipHost, ITooltipHost, ITooltipHostProps } from '@fluentui/react';

const HoverTooltip: React.FC<ITooltipHostProps> = (props) => {
  const tooltipRef: MutableRefObject<ITooltipHost | null> = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      // @ts-ignore
      tooltipRef.current['_onTooltipFocus'] = null;
    }
  }, []);

  return <TooltipHost componentRef={tooltipRef} {...props} />;
};

export default HoverTooltip;
