const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/SalesInvoice',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSalesInvoice' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Invoice',
    'properties': {
        'amount': {
            'title': 'Amount',
            'description': 'A monetary value or range. This type can be used to describe an amount of money such as $50 USD, or a range as in describing a bank account being suitable for a balance between £1,000 and £1,000,000 GBP, or the value of a salary, etc. It is recommended to use [[PriceSpecification]] Types to describe the price of an Offer, Invoice, etc.',
            '$id': 'http://schema.org/MonetaryAmount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FMonetaryAmount' },
                { 'href': 'http://schema.org/amount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'currency': {
                    'title': 'Currency',
                    'description': 'The currency in which the monetary amount is expressed.\\n\\nUse standard formats: [ISO 4217 currency format](http://en.wikipedia.org/wiki/ISO_4217) e.g. "USD"; [Ticker symbol](https://en.wikipedia.org/wiki/List_of_cryptocurrencies) for cryptocurrencies e.g. "BTC"; well known names for [Local Exchange Tradings Systems](https://en.wikipedia.org/wiki/Local_exchange_trading_system) (LETS) and other currency types e.g. "Ithaca HOUR".',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/currency', 'rel': 'canonical' }],
                },
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'format': 'cp:monetaryAmount',
            'cp_ui': { 'sortOrderForm': 43 },
        },
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 45 },
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'documentUrl': {
            'title': 'Document Url',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'downloadable': true, 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/documentUrl', 'rel': 'canonical' }],
        },
        'category': {
            'title': 'Category',
            'description': 'A category for the item. Greater signs or slashes can be used to informally indicate a category hierarchy.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://schema.org/category', 'rel': 'canonical' }],
        },
        'customer': {
            'title': 'Customer',
            'description': '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Customer',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'customer/all', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://schema.org/customer', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 48 },
        },
        'paymentDueDate': {
            'title': 'Payment Due Date',
            'description': 'The date that payment is due.',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 46 },
            'links': [{ 'href': 'http://schema.org/paymentDueDate', 'rel': 'canonical' }],
        },
        'totalPaymentDue': {
            'title': 'Total Payment Due',
            'description': 'A monetary value or range. This type can be used to describe an amount of money such as $50 USD, or a range as in describing a bank account being suitable for a balance between £1,000 and £1,000,000 GBP, or the value of a salary, etc. It is recommended to use [[PriceSpecification]] Types to describe the price of an Offer, Invoice, etc.',
            '$id': 'http://schema.org/MonetaryAmount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FMonetaryAmount' },
                { 'href': 'http://schema.org/totalPaymentDue', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'currency': {
                    'title': 'Currency',
                    'description': 'The currency in which the monetary amount is expressed.\\n\\nUse standard formats: [ISO 4217 currency format](http://en.wikipedia.org/wiki/ISO_4217) e.g. "USD"; [Ticker symbol](https://en.wikipedia.org/wiki/List_of_cryptocurrencies) for cryptocurrencies e.g. "BTC"; well known names for [Local Exchange Tradings Systems](https://en.wikipedia.org/wiki/Local_exchange_trading_system) (LETS) and other currency types e.g. "Ithaca HOUR".',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/currency', 'rel': 'canonical' }],
                },
                'value': {
                    'title': 'Value',
                    'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                },
            },
            'format': 'cp:monetaryAmount',
            'cp_ui': { 'sortOrderForm': 47 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
    },
};
export const SalesInvoiceSchema = Schema;
