const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/MarketplaceCategory',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'categories', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2FmarketplaceCategory%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=solutionTypeDetails%2Foffers%2FofferException%2FsalesChannelMarketplace%2FmarketplaceCategory%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "categories?$filter=parentProductCategory%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4',
        },
    ],
    'cp_parentPropertyJsonPath': 'parentProductCategory.identifier',
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Marketplace Category',
    'properties': {
        'dateModified': {
            'title': 'Date Modified',
            'description': "The date on which the CreativeWork was most recently modified or when the item's entry was modified within a DataFeed.",
            'type': 'string',
            'format': 'date-time',
            'links': [{ 'href': 'http://schema.org/dateModified', 'rel': 'canonical' }],
        },
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date-time',
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'active': { 'title': 'Active', 'type': 'boolean', 'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/active', 'rel': 'canonical' }] },
        'parentProductCategory': {
            'title': 'Parent Product Category',
            '$id': 'http://platform.cosmoconsult.com/ontology/MarketplaceCategory',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'categories', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentProductCategory', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentProductCategory.identifier',
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Marketplace Category' } },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const MarketplaceCategorySchema = Schema;
