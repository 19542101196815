import { getAppBasePath } from '@cpa/base-core/helpers';
import tinymce, { Editor, Plugin } from 'tinymce/tinymce';

export default () => {
  tinymce.PluginManager.add('bootstrap', function (editor: Editor): void | Plugin {
    editor.on('init', () => {
      const headElem = editor.getDoc().getElementsByTagName('head')[0];

      // Good here but some plugins don't see that (e. g. template plugin). Therefore Tiny's content_css is used.
      //// Stylesheets:
      // var linkElem1 = editor.dom.create('link', {
      //     id: editor.dom.uniqueId(),
      //     rel: 'stylesheet',
      //     href: `${getAppBasePath()}/vendor/tinymce/templates/bootstrap/assets/bootstrap-cpa-sc.min.css`
      // });
      // headElem.appendChild(linkElem1);
      //
      // var linkElem2 = editor.dom.create('link', {
      //     id: editor.dom.uniqueId(),
      //     rel: 'stylesheet',
      //     href: `${getAppBasePath()}/vendor/tinymce/templates/bootstrap/assets/cosmo-theme-cpa-sc.min.css`
      // });
      // headElem.appendChild(linkElem2);

      // Scripts:
      const scriptElem1 = editor.dom.create('script', {
        id: editor.dom.uniqueId(),
        type: 'text/javascript',
        src: '//code.jquery.com/jquery-3.5.1.slim.min.js',
        integrity: 'sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs=',
        crossorigin: 'anonymous',
      });
      headElem.appendChild(scriptElem1);

      const scriptElem2 = editor.dom.create('script', {
        id: editor.dom.uniqueId(),
        type: 'text/javascript',
        src: `${getAppBasePath()}/vendor/tinymce/templates/bootstrap/assets/js/bootstrap.bundle.min.js`,
      });
      headElem.appendChild(scriptElem2);
    });
  });
};
