import { IDarkMode, ILanguage } from '@cpa/base-core/types';
import { CommandBarButton, ThemeContext } from '@fluentui/react';
import React, { useCallback, useContext } from 'react';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';

import styles from './LanguageSelect.module.scss';

interface ILanguageSelectProps extends IDarkMode {
  languages: ILanguage[];
  onLanguageClick: (code: string) => void;
  title: string;
  currentLanguage: string;
  className?: string;
  useLocalizedName?: boolean;
}

const flagStyle = {
  width: '30px',
  height: 'unset',
  backgroundSize: 'contain',
  marginRight: '5px',
};

const LanguageSelect: React.FC<ILanguageSelectProps> = ({
  className,
  currentLanguage,
  onLanguageClick,
  languages,
  title,
  darkMode,
  useLocalizedName,
}) => {
  const theme = useContext(ThemeContext);

  const renderFlag = useCallback(
    (code?: string) => (): JSX.Element | null => {
      if (!code) {
        return null;
      }
      return <ReactCountryFlag style={flagStyle} countryCode={code} svg={true} />;
    },
    []
  );

  const onClick = useCallback(
    (code: string) => (): void => {
      onLanguageClick(code);
    },
    [onLanguageClick]
  );

  return (
    <div className={classNames(className, styles.wrapper)}>
      <h3 style={{ color: darkMode ? '#fefefe' : '#323130' }} className={styles.title}>
        {title}
      </h3>

      {languages.map(({ identifier, name, alternateName, icon }) => (
        <CommandBarButton
          style={{
            height: 44,
            width: '100%',
            display: 'inline-block',
            textAlign: 'left',
            borderLeft: currentLanguage === identifier ? `3px solid ${theme?.palette.themePrimary}` : '3px solid transparent',
            backgroundColor: currentLanguage === identifier ? 'rgba(0, 0, 0, 0.18)' : undefined,
          }}
          text={useLocalizedName ? name : alternateName || name}
          onClick={onClick(identifier)}
          key={identifier}
          data-switch-to-language={icon}
          onRenderIcon={renderFlag(icon)}
        />
      ))}
    </div>
  );
};

export default LanguageSelect;
