import { fork } from 'redux-saga/effects';

import app from './app/saga';
import settings from './settings/saga';
import websocket from './websocket/saga';

export default function* (): Generator {
  console.debug('Starting root saga');
  yield fork(app);
  yield fork(settings);
  yield fork(websocket);
}
