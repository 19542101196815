import { IconButton, Modal } from '@fluentui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ConnectionErrorModal.module.scss';

interface IConnectionErrorModalProps {
  errorCode: number;
  onModalClose?: () => void;
}

const modalStyles = {
  layer: {
    zIndex: '100000010 !important',
  },
};
const overlayProps = { styles: { root: { backgroundColor: 'transparent' } } };
const refreshIconProps = { iconName: 'Refresh' };

const ConnectionErrorModal: React.FC<IConnectionErrorModalProps> = ({ errorCode, onModalClose }) => {
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  const errorText = useMemo(() => {
    if (errorCode === 0) {
      if (i18n.language)
        return {
          title: t('errors.connection.networkErrorTitle'),
          message: t('errors.connection.networkError'),
        };
      else {
        return {
          title: 'Connection Error',
          message: 'Something is temporarily wrong with your network connection. Please try again later.',
        };
      }
    } else if (errorCode) {
      if (i18n.language)
        return {
          title: t('errors.connection.internalErrorTitle'),
          message: t('errors.connection.internalError'),
        };
      else {
        return {
          title: 'Something went wrong',
          message: 'We have an issue and working on it. Please try again later.',
        };
      }
    }
    return null;
  }, [errorCode, i18n.language, t]);

  return (
    <Modal isOpen styles={modalStyles} overlay={overlayProps}>
      <div className={styles.modalBody}>
        <span className={styles.title}>{errorText?.title}</span>
        <span className={styles.text}>{errorText?.message}</span>
        <IconButton className={styles.refresh} iconProps={refreshIconProps} onClick={onModalClose} />
      </div>
    </Modal>
  );
};

export default ConnectionErrorModal;
