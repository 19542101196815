import { IDataItem, KpiOp } from '@cpa/base-core/types';
import * as _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Schemas } from '@cp/base-types';

import { IKpiRenderProps } from '../../Kpi';

interface IKpiTileProps {
  redirectPath?: string;
  kpi: Extract<Schemas.CpaPage['kpis'], {}>[number];
  items: IDataItem[];
  isFetching: boolean;
  children: (props: IKpiRenderProps) => JSX.Element;
}

const KpiTile: React.FC<IKpiTileProps> = ({ redirectPath, kpi, items, isFetching, children }) => {
  const [, i18n] = useTranslation();

  const checkItemFunction = useMemo(() => (kpi.filter ? new Function('item', kpi.filter) : null), [kpi.filter]);

  const calculatedNumber = useMemo(() => {
    return items
      .filter((item) => {
        if (checkItemFunction) {
          return !!checkItemFunction(item);
        }
        return true;
      })
      .reduce((acc, item) => {
        switch (kpi.kpiType) {
          case KpiOp.COUNT:
            return acc + 1;
          case KpiOp.SUM:
            if (!kpi.valueProperty) {
              console.warn(`Missing 'field' for op ${KpiOp.SUM}.`);
              return acc;
            }
            const value = parseInt(_.get(item, kpi.valueProperty) as string, 10);
            if (Number.isNaN(value)) {
              console.warn(`Missing value ${value} in field ${kpi.valueProperty}.`);
            }

            return acc + value;
          default:
            console.warn(`Unsupported kpi op - ${kpi.kpiType}`);
            return acc;
        }
      }, 0);
  }, [checkItemFunction, items, kpi.kpiType, kpi.valueProperty]);

  const formattedNumber = useMemo(
    () => calculatedNumber.toLocaleString(i18n.language, { maximumFractionDigits: 2 }),
    [calculatedNumber, i18n.language]
  );

  const kpiRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef<number>(82);

  useEffect(() => {
    if (kpiRef.current) {
      heightRef.current = Math.max(heightRef.current, kpiRef.current.getBoundingClientRect().height);
    }
  }, [formattedNumber, kpi.pattern, kpi.name]);

  return children({
    name: kpi.name,
    isFetching,
    errors: [],
    redirectPath,
    value: formattedNumber,
    identifier: kpi.identifier,
  });
};

export default React.memo(KpiTile);
