import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './Bot.module.scss';

interface IBotProps {
  title?: string;
  url: string;
  visible: boolean;
}

const Bot: React.FC<IBotProps> = ({ url, title, visible }) => {
  const [initialized, setInitialized] = useState(false);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  useEffect(() => {
    if (visible) {
      setInitialized(true);
    }
  }, [visible]);
  if (!initialized) {
    return null;
  }

  return <iframe title={title || 'Bot'} className={classNames(styles.iframe, { [styles.dark]: darkMode })} src={url} />;
};

export default Bot;
