import React, { useCallback, useState } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { axiosDictionary, postEntityToEndpoint } from '@cpa/base-core/api';
import { TypeConstants } from '@cp/base-types';
import notification from '@cpa/base-core/helpers/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';

import TinyEditor from '../../../../../../../../../components/Form/components/TextWidget/components/Html/components/TinyEditor/TinyEditor';

import styles from './CommentInput.module.scss';

interface ICommentInputProps {
  identifier: string;
  cpTypeUrl?: string;
}

const CommentInput: React.FC<ICommentInputProps> = ({ identifier, cpTypeUrl }) => {
  const [t] = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState<string | boolean | number | object | null | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const toggleExpanded = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setInputValue('');
      setExpanded(!expanded);
    },
    [expanded]
  );

  const handleInputChange = useCallback((content: string | boolean | number | object | null | undefined) => {
    setInputValue(content);
  }, []);

  const handlePost = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setLoading(true);
      // Post item
      await postEntityToEndpoint(axiosDictionary.appDataService, `data-store/${encodeURIComponent(TypeConstants.Comment)}`, {
        about: {
          _type: cpTypeUrl,
          identifier: identifier,
        },
        description: inputValue as string,
      })
        .then(() => {
          setInputValue('');
          setExpanded(false);
        })
        .catch((e) => {
          notification.error('Failed to post comment');
          console.error('Failed to post comment', e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [cpTypeUrl, identifier, inputValue]
  );

  if (!expanded) {
    return (
      <div className={classNames(styles.box, { [styles.light]: !darkMode })} onClick={toggleExpanded}>
        {t('common.writeAComment')}
      </div>
    );
  }

  return (
    <div className={styles.editor}>
      <TinyEditor
        value={inputValue as string}
        onChange={handleInputChange}
        emptyValue={''}
        readOnly={loading}
        customToolbarButtons={
          'undo redo | removeformat | formatselect | bold italic underline blockquote hr' + ' | link emoticons ' + ' | bullist numlist outdent indent'
        }
      />
      <div className={styles.buttons}>
        <div className={styles.collapse} onClick={toggleExpanded}>
          {t('common.collapse')}
        </div>
        <PrimaryButton disabled={loading} onClick={handlePost}>
          Post
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CommentInput;
