import { IGlobalState } from '@cpa/base-core/store';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GlobalDialog } from '../../../GlobalDialog/GlobalDialog';
import { GlobalDrawer } from '../../../GlobalDrawer/GlobalDrawer';

interface IPreloadProps {
  loaderSelector: string;
  isAppReady: boolean;
  children?: React.ReactNode;
}

const Preload: React.FC<IPreloadProps> = ({ loaderSelector, children, isAppReady }) => {
  const loader = useMemo(() => {
    return document.querySelector<HTMLElement>(loaderSelector);
  }, [loaderSelector]);

  const globalDialog = useSelector((state: IGlobalState) => state.app.globalDialog);
  const globalDrawer = useSelector((state: IGlobalState) => state.app.globalDrawer);

  useEffect(() => {
    if (!loader) {
      return;
    }

    const loaderVisible = loader.style.display === 'block' || !loader.style.display;
    if (loaderVisible === isAppReady) {
      loader.style.display = isAppReady ? 'none' : 'block';
    }
  }, [isAppReady, loader]);

  return (
    <>
      {!!globalDialog && <GlobalDialog {...globalDialog} />}
      {!!globalDrawer && <GlobalDrawer {...globalDrawer} />}
      {isAppReady ? children : null}
    </>
  );
};

export default Preload;
