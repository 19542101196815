import { LogLevel, Environment } from '@cp/base-types';
import chalk from 'chalk';
const logLevelColorMap = {
    [LogLevel.Error]: chalk.red.bold,
    [LogLevel.Warn]: chalk.yellow.bold,
    [LogLevel.Info]: chalk.cyan,
    [LogLevel.Log]: chalk.blue,
    [LogLevel.Debug]: chalk.blueBright.dim,
};
export class MessageFormatter {
    format(logMessage) {
        const time = logMessage.timestamp;
        const logLevel = LogLevel[logMessage.level].toUpperCase();
        const sender = logMessage.sender;
        const logPrefix = Environment.env.LOG_DISABLE_COLORS === 'true'
            ? `${time.toISOString()}  ${logLevel}\t[${sender}]`
            : `${chalk.dim(time.toISOString())}  ${logLevelColorMap[logMessage.level](logLevel)}\t${chalk.dim('[' + sender + ']')}`;
        if (typeof logMessage.message === 'string') {
            return [`${logPrefix} ${logMessage.message}`, ...logMessage.optionalParams];
        }
        else {
            return [logPrefix, logMessage.message, ...logMessage.optionalParams];
        }
    }
    createMessage(logMessage) {
        let msg = this.convertToString(logMessage.message);
        for (const param of logMessage.optionalParams) {
            msg = msg.concat(' ', this.convertToString(param));
        }
        return msg;
    }
    convertToString(object) {
        var _a;
        if (typeof object === 'string' || object instanceof String) {
            return object;
        }
        else if (object instanceof Error) {
            const error = object;
            return (_a = error.stack) !== null && _a !== void 0 ? _a : `${error.name}: ${error.message}`;
        }
        else if (typeof object === 'object') {
            return JSON.stringify(object);
        }
        else {
            return `${object}`;
        }
    }
}
