import { IJSONSchema } from '@cp/base-types';
import { JSONSchema7 } from 'json-schema';
import React from 'react';
import { IDataItem } from '@cpa/base-core/types';
import { PathContext } from '@cpa/base-core/constants';

export interface IBlurOptions {
  fieldItem?: IDataItem;
  oldFieldValue?: IDataItem;
  schema?: IJSONSchema;
  path?: string;
}

interface IWrappedComponentProps {
  id?: string;
  value?: unknown;
  onBlur?: (...args: unknown[]) => unknown;
  onChange?: (...args: unknown[]) => unknown;
  schema?: IJSONSchema | JSONSchema7;
}

const withBlurEventHandling = <P extends IWrappedComponentProps>(
  Component: React.ComponentType<P>,
  emitBlurOnChange: boolean = false
): React.ComponentType<P> =>
  class WithBlurEventHandling extends React.Component<P> {
    static contextType = PathContext;
    private previousBlurValue: unknown = undefined;

    constructor(props: P) {
      super(props);
      this.previousBlurValue = props.value;
    }

    componentDidUpdate(): void {
      this.previousBlurValue = this.props.value;
    }

    onBlurWrapper: IWrappedComponentProps['onBlur'] = (...args) => {
      if (2 in args && typeof args[2] !== 'object') {
        console.warn(`Unexpected onBlur arguments`, args, this.props);
        return;
      }

      const [id, value] = args;

      this.props.onBlur?.(id, value, {
        ...(args[2] && typeof args[2] === 'object' ? args[2] : {}),
        schema: this.props.schema,
        oldFieldValue: this.previousBlurValue,
        path: this.context,
      });
      this.previousBlurValue = value;
    };

    onChangeWrapper: IWrappedComponentProps['onChange'] = (...args) => {
      this.props.onChange?.(...args);
      this.onBlurWrapper?.(this.props.id, args[0]);
    };

    render(): JSX.Element {
      return (
        <Component
          {...(this.props as P)}
          {...(this.props.onBlur ? { onBlur: this.onBlurWrapper } : {})}
          {...(this.props.onChange && emitBlurOnChange ? { onChange: this.onChangeWrapper } : {})}
        />
      );
    }
  };

export default withBlurEventHandling;
