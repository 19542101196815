import { Options, transformSync } from '@swc/wasm-web';

let wasmModule: typeof import('@swc/wasm-web') | null = null;
let initialization: Promise<void> | null = null;

export function startSwcInitialization(): Promise<void> {
  if (initialization) {
    return initialization;
  }

  initialization = (async () => {
    // Dynamic import of the WASM module
    wasmModule = await import('@swc/wasm-web');
    await wasmModule.default();
  })();

  return initialization;
}

const defaultOptions: Options = {
  jsc: {
    parser: {
      syntax: 'typescript',
    },
  },
};
export async function transformCode(code: string, options: Options = defaultOptions): Promise<string> {
  if (!wasmModule) {
    await startSwcInitialization();
  }

  if (!wasmModule) {
    throw new Error('Failed to initialize SWC');
  }

  const result = await wasmModule.transform(code, options);
  return result.code;
}

export function transformCodeSync(code: string, options: Options = defaultOptions) {
  return transformSync(code, options);
}
