import { store } from '@cpa/base-core/store';
import { openSettings } from '@cpa/base-core/store/settings/actions';
import { signIn } from '@cpa/base-core/helpers';

export interface ITourStep {
  name: string;
  target: string | string[];
  title: string;
  text: string;
  actionText?: string;
  primaryBtn?: {
    label: string;
    onClick: () => void;
  };
  doNotLayer: boolean;
  // TODO: use page identifier instead of pathname
  pathname?: string;
  dismissOnPathHit?: boolean;
  dismissOnTargetClick?: boolean;
  isWidget?: boolean;
  offset?: number;
}

const steps: ITourStep[] = [
  {
    name: 'Settings',
    target: '#commandBarContainer-settings',
    title: 'header.controls.settings.bubble.title',
    text: 'header.controls.settings.bubble.text',
    actionText: 'header.controls.settings.bubble.actionText',
    primaryBtn: {
      label: 'header.controls.settings.bubble.primaryBtn',
      onClick: () => {
        store.dispatch(openSettings());
      },
    },
    doNotLayer: false,
  },
  {
    name: 'Search',
    target: '#commandBarContainer-search',
    title: 'header.controls.search.bubble.title',
    text: 'header.controls.search.bubble.text',
    doNotLayer: false,
  },
  {
    name: 'Feedback',
    target: '#action-bubble-feedback',
    title: 'feedback.bubble.title',
    text: 'feedback.bubble.text',
    doNotLayer: false,
  },
  {
    name: 'WidgetShowMore',
    target: ['#Tickets #widget-showMore', '#widget-showMore'],
    title: 'header.controls.widgetShowMore.bubble.title',
    text: 'header.controls.widgetShowMore.bubble.text',
    doNotLayer: true,
    isWidget: true,
  },
  {
    name: 'SemanticSearch',
    target: '#semantic-search',
    title: 'common.semanticSearchTitle',
    text: 'common.semanticSearchText',
    doNotLayer: true,
    isWidget: true,
  },
  {
    name: 'WidgetSettings',
    target: ['#Tickets #widget-settings', '#widget-settings'],
    title: 'header.controls.widgetSettings.bubble.title',
    text: 'header.controls.widgetSettings.bubble.text',
    doNotLayer: true,
    isWidget: true,
  },
  {
    name: 'AddTicket',
    target: '#Tickets #table-add',
    title: 'header.controls.addTicket.bubble.title',
    text: 'header.controls.addTicket.bubble.text',
    doNotLayer: true,
    isWidget: true,
  },
  {
    name: 'AddSolution',
    target: '#solution-add',
    title: 'tour.solution.addChild.title',
    text: 'tour.solution.addChild.text',
    doNotLayer: true,
    isWidget: true,
    offset: 400,
    dismissOnTargetClick: true,
  },
  {
    name: 'ExpandChildren',
    target: '#children-expand',
    title: 'common.expandChildren',
    text: 'tour.solution.expandChildren.text',
    doNotLayer: true,
    isWidget: true,
    offset: 400,
    dismissOnTargetClick: true,
  },
  {
    name: 'AddComponent',
    target: '#component-add',
    title: 'tour.solution.addComponent.title',
    text: 'tour.solution.addComponent.text',
    doNotLayer: true,
    isWidget: true,
    offset: 400,
    dismissOnTargetClick: true,
  },
  {
    name: 'ExploreComponents',
    target: '#component-explore',
    title: 'common.exploreComponents',
    text: 'tour.solution.exploreComponents.text',
    doNotLayer: true,
    isWidget: true,
    offset: 400,
    dismissOnTargetClick: true,
  },
  {
    name: 'Profile',
    target: '#profile',
    title: 'tour.profile.title',
    text: 'tour.profile.text',
    doNotLayer: false,
    dismissOnTargetClick: true,
  },
  {
    name: 'SignIn',
    target: '#sign-in-button',
    title: 'header.controls.signin.bubble.title',
    text: 'header.controls.signin.bubble.text',
    doNotLayer: false,
    primaryBtn: {
      label: 'header.controls.signin.bubble.title',
      onClick: async () => {
        const state = store.getState();
        const msalProvider = state.app.msalProvider;
        const configuration = state.app.cpa?.configuration;
        const currentLocation = state.router.location;

        if (!msalProvider) {
          console.warn(`Failed to start signin. Msal is not initialized.`);
          return;
        }

        if (!configuration) return;

        console.debug(`Executing signin`);
        await signIn(msalProvider, configuration.msalAuthScopes ?? [], currentLocation);
      },
    },
  },
];

export const isTourCompleted = (): boolean => {
  return steps.every((step) => {
    return localStorage.getItem(`hide${step.name}Bubble`) === 'true';
  });
};

export const completeTour = (): void => {
  for (const step of steps) {
    localStorage.setItem(`hide${step.name}Bubble`, 'true');
  }
};

export default steps;
