import { Schemas } from '@cp/base-types';
import { adjustHexColorLightness, findColorsInBetween, hexToRgb } from '@cp/base-utils';
import {
  IButtonStyles,
  ICheckboxStyles,
  IDetailsHeaderStyles,
  IPalette,
  ITooltipStyles,
  createTheme,
  loadTheme,
  IContextualMenuItem,
  IButtonProps,
  CommandBarButton,
  ICommandBarItemProps,
} from '@fluentui/react';
import { detect } from 'detect-browser';
import { clearCache, dropByCacheKey, getCachingKeys } from 'react-router-cache-route';
import React from 'react';
import * as _ from 'lodash';

import { store } from '../store';
import { showDialog as showDialogAction, showDrawer as showDrawerAction } from '../store/app/actions';
import { GlobalDialogType, GlobalDrawerType, IGlobalDialogState, IGlobalDrawerState, IMenuItem } from '../types';

export type IGlobalDialogProps = Partial<IGlobalDialogState>;

export function isValidMenuItem(menuItem: IMenuItem): boolean {
  return !!menuItem.url || (!!menuItem.links && menuItem.links.some(isValidMenuItem));
}

export function buildGroupedButtons(
  actions: { group?: string; button: ICommandBarItemProps }[],
  classnames: string,
  level = 0
): ICommandBarItemProps[] {
  const groupedCustomActions = _.groupBy(actions, (action) => action.group?.split(',')[0]);

  return Object.keys(groupedCustomActions).map((groupKey, index): ICommandBarItemProps => {
    const childActions = groupedCustomActions[groupKey].map((childAction) => ({
      ...childAction,
      group: childAction.group?.split(',').slice(1).join(','),
    }));
    const childActionsWithGroup = childActions.filter((childAction) => childAction.group);
    const childActionsWithoutGroup = childActions.filter((childAction) => !childAction.group);

    return {
      key: groupKey + index,
      text: groupKey,
      split: false,
      onClick: undefined,
      [level === 0 ? 'menuProps' : 'subMenuProps']: {
        items: [
          ...buildGroupedButtons(childActionsWithGroup, classnames, level + 1),
          ...childActionsWithoutGroup.map((action, index) => ({
            onRender: undefined,
            ...action.button,
            key: action.button.key + index,
          })),
        ],
      },
      className: classnames,
      onRender: level === 0 ? buttonRenderer : undefined,
    };
  });
}

export const splitBtnRenderer: (classnames: string, ...args: Parameters<NonNullable<ICommandBarItemProps['onRender']>>) => JSX.Element = (
  classnames,
  item
) => {
  return (
    <div className={classnames}>
      <CommandBarButton {...item} dir={'ltr'} />
    </div>
  );
};

export const buttonRenderer: IContextualMenuItem['onRender'] = (buttonProps, dismissMenu) => {
  const { onClick } = buttonProps;
  const newProps: IButtonProps = {
    ...buttonProps,
    onClick(e) {
      const result = onClick?.(e);
      dismissMenu({ ...e, defaultPrevented: false }, true);
      return result;
    },
  };
  return <CommandBarButton {...newProps} dir={'ltr'} />;
};

export function showDrawer({ type = GlobalDrawerType.DIFFERENCE, drawerOptions, isMaximized }: IGlobalDrawerState): Promise<unknown> {
  return new Promise<unknown>((resolve) => {
    store.dispatch(
      showDrawerAction({
        type,
        drawerOptions,
        isMaximized,
        onClose: resolve,
      })
    );
  });
}

export function showDialog({
  message,
  type = GlobalDialogType.CONFIRMATION,
  primaryOption = true,
  title = null,
  dialogContentProps = null,
  primaryButtonText = null,
  secondaryButtonText = null,
  closeOnClickOutside = false,
  closeOnAction = true,
  styles,
  dialogTypeOptions,
  isHtml = false,
  width,
  isMaximizable,
  className,
}: IGlobalDialogProps): Promise<unknown> {
  return new Promise<unknown>((resolve) => {
    store.dispatch(
      showDialogAction({
        type: type,
        message,
        title,
        primaryOption,
        dialogContentProps,
        primaryButtonText,
        secondaryButtonText,
        closeOnClickOutside,
        closeOnAction,
        dialogTypeOptions,
        styles,
        onClose: resolve,
        isHtml: isHtml,
        width,
        isMaximizable,
        className,
      })
    );
  });
}

const browser = detect();

export const isFirefox = browser && browser.name === 'firefox';
export const isSafari = browser && browser.name === 'safari';

export function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  const mq = (q: string): boolean => {
    return window.matchMedia(q).matches;
  };

  if (
    'ontouchstart' in window ||
    // @ts-ignore
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export const sortWidgets = <T extends Schemas.CpaPage>(pages: T[], respectGivenSortOrder?: boolean): T[] => {
  if (respectGivenSortOrder) {
    // Used for arrays like singleItemComponents (e. g. from single item templates)
    return pages;
  }
  return [...pages].sort(({ sortOrderAsWidget: sortOrderLeft = Infinity }, { sortOrderAsWidget: sortOrderRight = Infinity }) =>
    sortOrderLeft < sortOrderRight ? -1 : sortOrderLeft > sortOrderRight ? 1 : 0
  );
};

export const chunkWidgets = (pages: Schemas.CpaPage[], maxItemsPerRow: number = 3, respectGivenSortOrder?: boolean): Schemas.CpaPage[][] => {
  return sortWidgets(pages, respectGivenSortOrder).reduce((acc, page) => {
    if (page.fullWidthAsWidget) {
      return [...acc, [page]];
    }

    if (!acc[acc.length - 1]) {
      acc.push([]);
    }

    if (
      (!acc[acc.length - 1][acc[acc.length - 1].length - 1]?.fullWidthAsWidget && acc[acc.length - 1]?.length % maxItemsPerRow) ||
      acc[acc.length - 1]?.length === 0
    ) {
      acc[acc.length - 1].push(page);
    } else {
      acc.push([page]);
    }

    return acc;
  }, [] as Schemas.CpaPage[][]);
};

export const dropPageRelatedRoutesCache = (pageIdentifier: string): Promise<void> => {
  const relatedCacheKeys = getCachingKeys().filter((key) => key.includes(pageIdentifier));

  relatedCacheKeys.forEach((key) => dropByCacheKey(key));

  console.warn(`Dropped related to ${pageIdentifier} cached routes - ${relatedCacheKeys.length}`);

  // TODO: Fix when PR is merged: https://github.com/CJY0208/react-router-cache-route/pull/113
  return new Promise((resolve) => setTimeout(resolve, 100));
};

export const dropRoutesCache = (): Promise<void> => {
  clearCache();
  console.warn(`Dropped cached routes - ${getCachingKeys().length}`);

  // TODO: Fix when PR is merged: https://github.com/CJY0208/react-router-cache-route/pull/113
  return new Promise((resolve) => setTimeout(resolve, 100));
};

function setGlobalColorVariable(name: string, hex: string): void {
  const { r, g, b } = hexToRgb(hex);
  document.documentElement.style.setProperty(`--${name}`, hex);
  document.documentElement.style.setProperty(`--${name}-rgb`, `${r}, ${g}, ${b}`);
}

const checkboxStyles: ICheckboxStyles = {
  text: {
    fontWeight: 500,
  },
};

const detailsHeaderStyles: Partial<IDetailsHeaderStyles> = {
  root: {
    selectors: {
      '.ms-DetailsHeader-cellName': {
        fontSize: '12px',
      },
    },
  },
};

const getTooltipStyles = (): Partial<ITooltipStyles> => {
  const touchDevice: boolean = isTouchDevice();
  if (!touchDevice) return {};
  return {
    root: {
      display: 'none',
    },
  };
};

export function loadColorTheme /*
  Colors style guide:
  https://docs.cosmoconsult.com/en-us/internal/corporate-identity/folder-brand-design/brand-design-farben.html
   */(
  palette: Schemas.Cpa['colorPalette'] = {
    colorWhite: '#ffffff',
    colorBlack: '#000000',
    colorPrimary: '#b39c4d',
    colorSecondary: '#1b212e',
    colorDarkRed: '#6A0040',
    colorDarkBlue: '#002D6E',
    colorDarkGreen: '#00423C',
    colorDarkAccent1: '#AA4628',
    colorDarkGrey: '#464F57',
    colorLightRed: '#FF671E',
    colorLightBlue: '#3296DF',
    colorLightGreen: '#78BE20',
    colorLightAccent1: '#EBE6DC',
    colorLightGray: '#CED0D0',
  },
  darkMode: boolean = false,
  highContrast: boolean = false
): void {
  if (highContrast) {
    // High contrast palettes
    palette = darkMode
      ? {
          colorWhite: '#ffffff',
          colorBlack: '#000000',
          colorPrimary: '#ffff00',
          colorSecondary: '#000000',
          colorDarkRed: '#ff0000',
          colorDarkBlue: '#0000ff',
          colorDarkGreen: '#00ff00',
          colorDarkAccent1: '#ffffff',
          colorDarkGrey: '#1f1f1f',
          colorLightRed: '#ff0000',
          colorLightBlue: '#0000ff',
          colorLightGreen: '#00ff00',
          colorLightAccent1: '#ffffff',
          colorLightGray: '#ffffff',
        }
      : {
          colorWhite: '#ffffff',
          colorBlack: '#000000',
          colorPrimary: '#0000d2',
          colorSecondary: '#000000',
          colorDarkRed: '#d70000',
          colorDarkBlue: '#0000d2',
          colorDarkGreen: '#00a400',
          colorDarkAccent1: '#ffffff',
          colorDarkGrey: '#000000',
          colorLightRed: '#d70000',
          colorLightBlue: '#0000d2',
          colorLightGreen: '#00a400',
          colorLightAccent1: '#ffffff',
          colorLightGray: '#ffffff',
        };
  }

  const grayExtraColors = findColorsInBetween(palette.colorLightGray, palette.colorDarkGrey, 6);
  const [grayDarkColor] = findColorsInBetween(palette.colorDarkGrey, palette.colorSecondary, 1);

  const themePalette: Partial<IPalette> = darkMode
    ? {
        // Dark Theme
        themePrimary: palette.colorPrimary,
        themeLighterAlt: adjustHexColorLightness(palette.colorPrimary, 38),
        themeLighter: adjustHexColorLightness(palette.colorPrimary, 4),
        themeLight: adjustHexColorLightness(palette.colorPrimary, 9),
        themeTertiary: adjustHexColorLightness(palette.colorPrimary, 13),
        themeSecondary: adjustHexColorLightness(palette.colorPrimary, 17),
        themeDarkAlt: adjustHexColorLightness(palette.colorPrimary, 21),
        themeDark: adjustHexColorLightness(palette.colorPrimary, 25),
        themeDarker: adjustHexColorLightness(palette.colorPrimary, 29),
        neutralLighterAlt: palette.colorSecondary,
        neutralLighter: palette.colorDarkGrey,
        neutralLight: grayExtraColors[5],
        neutralQuaternaryAlt: grayExtraColors[4],
        neutralQuaternary: grayExtraColors[3],
        neutralTertiaryAlt: grayExtraColors[1],
        neutralTertiary: grayExtraColors[0],
        neutralSecondary: palette.colorLightGray,
        neutralPrimaryAlt: adjustHexColorLightness(palette.colorWhite, -6),
        neutralPrimary: adjustHexColorLightness(palette.colorWhite, -6),
        neutralDark: adjustHexColorLightness(palette.colorWhite, -6),
        black: palette.colorLightAccent1,
        white: grayDarkColor,
        neutralSecondaryAlt: adjustHexColorLightness(grayDarkColor, -4),
      }
    : {
        // Light Theme
        themePrimary: palette.colorPrimary,
        themeLighterAlt: adjustHexColorLightness(palette.colorPrimary, 21),
        themeLighter: adjustHexColorLightness(palette.colorPrimary, 17),
        themeLight: adjustHexColorLightness(palette.colorPrimary, 13),
        themeTertiary: adjustHexColorLightness(palette.colorPrimary, 9),
        themeSecondary: adjustHexColorLightness(palette.colorPrimary, 4),
        themeDarkAlt: adjustHexColorLightness(palette.colorPrimary, -4),
        themeDark: adjustHexColorLightness(palette.colorPrimary, -8),
        themeDarker: adjustHexColorLightness(palette.colorPrimary, -12),
        neutralLighterAlt: adjustHexColorLightness(palette.colorLightGray, 15.2),
        neutralLighter: adjustHexColorLightness(palette.colorWhite, -9),
        neutralLight: adjustHexColorLightness(palette.colorLightGray, 5),
        neutralQuaternaryAlt: palette.colorLightGray,
        neutralQuaternary: grayExtraColors[0],
        neutralTertiaryAlt: grayExtraColors[1],
        neutralTertiary: grayExtraColors[2],
        neutralSecondary: grayExtraColors[3],
        neutralPrimaryAlt: grayExtraColors[4],
        neutralPrimary: grayExtraColors[5],
        neutralDark: palette.colorDarkGrey,
        black: palette.colorSecondary,
        white: palette.colorWhite,
        neutralSecondaryAlt: adjustHexColorLightness(grayDarkColor, -4),
      };

  const buttonStyles: IButtonStyles = {
    label: {
      fontWeight: 500,
    },
  };

  const primaryButtonStyles: IButtonStyles = {
    label: {
      fontWeight: 500,
    },
    root: {
      borderColor: themePalette.themePrimary,
    },
  };

  const defaultButtonStyles: IButtonStyles = {
    root: {
      borderColor: themePalette.neutralSecondary,
    },
    splitButtonMenuButton: {
      borderColor: themePalette.neutralSecondary,
    },
    label: {
      fontWeight: 500,
    },
  };

  const componentsStyles = {
    PrimaryButton: { styles: primaryButtonStyles },
    DefaultButton: { styles: defaultButtonStyles },
    Checkbox: { styles: checkboxStyles },
    CommandBarButton: { styles: buttonStyles },
    DetailsHeader: { styles: detailsHeaderStyles },
    Tooltip: { styles: getTooltipStyles() },
  };

  const theme = createTheme({
    components: componentsStyles,
    palette: themePalette,
    defaultFontStyle: { fontFamily: 'Neptune, Verdana' },
  });

  setGlobalColorVariable('color-cosmo-primary', palette.colorPrimary);
  setGlobalColorVariable('color-cosmo-blue', palette.colorDarkBlue);
  setGlobalColorVariable('color-cosmo-blue-light', palette.colorLightBlue);
  setGlobalColorVariable('color-cosmo-berry', palette.colorDarkRed);
  setGlobalColorVariable('color-cosmo-orange', palette.colorLightRed);
  setGlobalColorVariable('color-cosmo-green-1', palette.colorLightGreen);
  setGlobalColorVariable('color-cosmo-green-2', palette.colorDarkGreen);
  setGlobalColorVariable('color-cosmo-light-accent1', palette.colorLightAccent1);
  setGlobalColorVariable('color-cosmo-dark-accent1', palette.colorDarkAccent1);
  setGlobalColorVariable('color-cosmo-white', palette.colorWhite);
  setGlobalColorVariable('color-cosmo-black', palette.colorBlack);

  // Lighter gray colors
  setGlobalColorVariable('color-cosmo-gray-1', palette.colorLightGray);
  grayExtraColors.forEach((color, index) => {
    setGlobalColorVariable(`color-cosmo-gray-${index + 2}`, color);
  });
  setGlobalColorVariable('color-cosmo-gray-8', palette.colorDarkGrey);

  // Darker gray color
  setGlobalColorVariable('color-cosmo-gray-dark', grayDarkColor);

  setGlobalColorVariable('color-cosmo-secondary', palette.colorSecondary);

  loadTheme(theme);
}
