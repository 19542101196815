import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import React, { FC, HTMLAttributes, RefObject } from 'react';

import styles from './EmptySpace.module.scss';

export interface IEmptySpace extends HTMLAttributes<HTMLDivElement> {
  darkMode?: boolean;
  iconName: string;
  text?: string;
  elementRef?: RefObject<HTMLDivElement>;
}

const EmptySpace: FC<IEmptySpace> = ({ darkMode = false, text = '', iconName, className, elementRef, ...rest }) => (
  <div className={classNames(styles.root, className)} ref={elementRef} {...rest}>
    <div className={styles.container} style={{ color: darkMode ? 'white' : '#565656' }}>
      <Icon iconName={iconName} className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </div>
  </div>
);

export default EmptySpace;
