import { IJobInfo, IJSONSchema, JobState, Schemas } from '@cp/base-types';
import { getEntitiesFromEndpoint } from '@cpa/base-core/api';
import { IDarkMode, IDataItem } from '@cpa/base-core/types';
import { IShimmerColors, Shimmer, TooltipOverflowMode } from '@fluentui/react';
import classNames from 'classnames';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './JobStatusBadge.module.scss';

export interface IJobStatusBadgeProps extends IDarkMode {
  item: IDataItem;
  srcFieldName: string | undefined;

  // Used only to display inside badge
  srcFieldValue: string;

  schema: IJSONSchema;
  style?: CSSProperties;
  page?: Schemas.CpaPage;
}

const JobStatusBadge: React.FC<IJobStatusBadgeProps> = ({ item, srcFieldName, srcFieldValue, schema, style, darkMode, page }) => {
  const [jobInfo, setJobInfo] = useState<IJobInfo>();
  const [dataFetched, setDataFetched] = useState(false);

  // Create colour map for job info states
  const stateClasses: Map<JobState, string> = new Map();
  stateClasses.set('completed', styles.completed);
  stateClasses.set('waiting', styles.waiting);
  stateClasses.set('active', styles.active);
  stateClasses.set('delayed', styles.delayed);
  stateClasses.set('failed', styles.failed);
  stateClasses.set('paused', styles.paused);

  // Fetch job info by item (JobDefinition) identifier
  useEffect(() => {
    const fetchJobInfo = async (): Promise<void> => {
      if (page?.dataEndpoint?.identifier) {
        try {
          // Get job info from data store
          const jobInfos = (await Promise.all(getEntitiesFromEndpoint(page.dataEndpoint.identifier, `jobs/${item.identifier}`))).reduce(
            (acc, response) => [...acc, ...response.entities],
            []
          );

          if (Array.isArray(jobInfos) && jobInfos.length === 1) {
            setJobInfo(jobInfos[0] as IJobInfo);
          }

          setDataFetched(true);
        } catch (err) {
          console.error(`Could not request job info for job definition identifier '${item.identifier}'. ${err}`);
        }
      } else {
        throw new Error();
      }
    };
    fetchJobInfo().catch(console.error);
  }, [page, item]);

  const lastUpdated = useMemo(() => {
    return jobInfo && new Date(jobInfo.timestamp).toLocaleString();
  }, [jobInfo]);

  const shimmerColors = useMemo((): IShimmerColors => {
    // '#B39C4D'
    return {
      shimmerWave: darkMode ? '#8f7c3d' : '#c2af70',
      shimmer: darkMode ? '#707372' : '#C7C9C7',
      background: darkMode ? '#707372' : '#C7C9C7',
    };
  }, [darkMode]);

  return (
    <div className={styles.wrapper}>
      <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={srcFieldValue}>
        <span style={{ ...style }}>
          {/* Show job status badge */}
          <Shimmer
            className={classNames(styles.infoComponent, styles.shimmer)}
            shimmerColors={shimmerColors}
            isDataLoaded={dataFetched}
            width={'85px'}
            styles={{
              root: { padding: '0 !important', minHeight: '19px !important' },
              dataWrapper: { padding: 0, margin: 0 },
              shimmerWrapper: { borderRadius: '0.25rem' },
            }}
          >
            <span className={styles.badge}>
              {jobInfo ? (
                <>
                  <span className={classNames(styles.state, stateClasses.get(jobInfo.state))}>{jobInfo.state}</span>
                  <span className={styles.infoComponent}>{`Updated: ${lastUpdated}`}</span>
                  {jobInfo.state === 'active' && (
                    <>
                      <span>|</span>
                      <span className={styles.infoComponent}>{`Progress: ${jobInfo.progress}`}</span>
                    </>
                  )}
                </>
              ) : (
                <span className={classNames(styles.state)}>not queued</span>
              )}
            </span>
          </Shimmer>
          <span>&nbsp;</span>
          {/* Show original value */}
          <span>{srcFieldValue}</span>
        </span>
      </HoverTooltip>
    </div>
  );
};

export default React.memo(JobStatusBadge);
