// Exports the "paste" plugin for usage with module loaders
// Usage:
//   CommonJS:
//     require('tinymce/plugins/paste')
//   ES2015:
//     import 'tinymce/plugins/paste'

export default () => {
  require('./plugin.js');
};
