import { cpCollections, isDefined } from '@cp/base-types';
import * as _ from 'lodash';
import urlJoin from 'url-join';
export const TypeQueryParameters = {
    allData: 'allData',
    cp_view: 'cp_view',
    cp_collection: 'cp_collection',
};
export function getSchemaUrl(subjectUri, apiEndpoint, mode = 'schemajson', refDepth, derefDepth) {
    const url = new URL(urlJoin(apiEndpoint, `/ontology/${mode}`));
    url.searchParams.append('subjectUri', subjectUri);
    if (refDepth !== undefined && !isNaN(refDepth)) {
        url.searchParams.append('refDepth', refDepth.toString());
    }
    if (derefDepth !== undefined && !isNaN(derefDepth)) {
        url.searchParams.append('derefDepth', derefDepth.toString());
    }
    return url.href;
}
export function removeSubjectUriQueryParams(subjectUri, whitelist = []) {
    try {
        const parsedSubjectUri = new URL(subjectUri);
        for (const key of parsedSubjectUri.searchParams.keys()) {
            if (!whitelist.includes(key)) {
                parsedSubjectUri.searchParams.delete(key);
            }
        }
        return parsedSubjectUri.href;
    }
    catch (e) { }
    return subjectUri;
}
export function getViewFromSubjectUri(subjectUri) {
    try {
        const parsedSubjectUri = new URL(subjectUri);
        return parsedSubjectUri.searchParams.get('cp_view') || undefined;
    }
    catch (e) { }
    return undefined;
}
export function setViewToSubjectUri(subjectUri, value) {
    const parsedSubjectUri = new URL(subjectUri);
    parsedSubjectUri.searchParams.set('cp_view', value);
    return parsedSubjectUri.href;
}
export function formatRelationSubjectUri(subjectUri) {
    try {
        const parsedSubjectUri = new URL(subjectUri);
        const cpCollection = parsedSubjectUri.searchParams.get('cp_collection');
        if (cpCollection && cpCollection !== cpCollections.publish) {
            parsedSubjectUri.search = '?cp_collection=' + cpCollection;
        }
        else {
            parsedSubjectUri.search = '';
        }
        return parsedSubjectUri.href;
    }
    catch (e) { }
    return subjectUri;
}
export function getCollectionNameFromSubjectUri(subjectUri) {
    try {
        const url = new URL(subjectUri);
        return encodeURIComponent(`${url.host}${url.pathname}${url.hash}${url.searchParams.has('cp_collection') ? `#${url.searchParams.get('cp_collection')}` : ''}`.replace(/[^\w#]/g, '_'))
            .replace(/[^\w%23]/g, '_')
            .replace(/%23/g, '|');
    }
    catch (e) {
        return Buffer.from(subjectUri, 'utf8').toString('base64');
    }
}
export function getSubjectUriFromCollectionName(collectionName) {
    const parts = collectionName.split('_');
    const lastPart = parts.pop();
    const [path, subcollection] = (lastPart || '').split('|');
    if (parts[0] === 'platform' && parts[1] === 'cosmoconsult' && parts[2] === 'com' && parts[3] === 'ontology') {
        parts[0] = 'http://platform.cosmoconsult.com/ontology';
        parts.splice(1, 3);
    }
    else if (parts[0] === 'schema' && parts[1] === 'org') {
        parts[0] = 'http://schema.org';
        parts.splice(1, 1);
    }
    else {
        parts[0] = 'http://' + parts[0];
    }
    const host = parts.join('/');
    const searchParams = subcollection ? `?cp_collection=${subcollection}` : '';
    return `${host}/${path}${searchParams}`;
}
export function getSubjectLocalizedCacheCollectionName(subjectUri, language) {
    return `_cache_${getCollectionNameFromSubjectUri(subjectUri)}_[${language}]`;
}
export function isCacheCollectionForCollection(collectionName, mainCollectionName) {
    return collectionName.startsWith(`_cache_${mainCollectionName}_[`);
}
export function annotationValueToBoolean(annotationValue) {
    switch (annotationValue) {
        case '1':
            return true;
        case '0':
            return false;
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return undefined;
    }
}
export function getIntermediateAnyOfSchema(propertyJsonPath, parentSchema, schema) {
    var _a;
    if (propertyJsonPath) {
        const propertyKey = _.toPath(propertyJsonPath).slice(-1)[0];
        if (propertyKey) {
            const directProperty = (_a = parentSchema === null || parentSchema === void 0 ? void 0 : parentSchema.properties) === null || _a === void 0 ? void 0 : _a[propertyKey];
            if (directProperty && directProperty.type !== schema.type && directProperty.anyOf) {
                return directProperty;
            }
        }
    }
    return undefined;
}
export function isRelationSchema(option, identifierKey = 'identifier') {
    var _a, _b;
    if (!option.properties) {
        return false;
    }
    const patternRegexps = Object.keys((_a = option.patternProperties) !== null && _a !== void 0 ? _a : {}).map((p) => new RegExp(p));
    const keys = Object.keys(option.properties).filter((key) => !patternRegexps.some((regexp) => regexp.test(key)));
    return !!((_b = option.links) === null || _b === void 0 ? void 0 : _b.some((link) => link.rel === 'collection')) && keys.length === 1 && keys.includes(identifierKey);
}
export function hasLocalizableFieldInSchema(schema) {
    if (!_.isObject(schema)) {
        return false;
    }
    const found = _.find(schema, (value, key) => {
        if (key === 'cp_localizable' && value === true) {
            return true;
        }
        else if (_.isObject(value)) {
            return hasLocalizableFieldInSchema(value);
        }
        else {
            return false;
        }
    });
    return !!found;
}
export const resolveSchemaPath = (object, propertyPath, stopOnFirstLevel = false) => {
    const path = _.toPath(propertyPath.replaceAll('/', '.'));
    const property = path.shift();
    const value = _.get(object, property);
    if (!value && object.items)
        return resolveSchemaPath(object.items, [property, ...path].join('.'), stopOnFirstLevel);
    if (!value && object.anyOf) {
        for (const item of object.anyOf) {
            const result = resolveSchemaPath(item, [property, ...path].join('.'), stopOnFirstLevel);
            if (result)
                return result;
        }
    }
    if (!path.length && stopOnFirstLevel && (value === null || value === void 0 ? void 0 : value.type))
        return value;
    if (!value && object.properties) {
        return resolveSchemaPath(object.properties, [property, ...path].join('.'), stopOnFirstLevel);
    }
    if (value === null || value === undefined) {
        return;
    }
    if (!path.length)
        return value.items || value;
    if (Array.isArray(value)) {
        for (const item of value) {
            const result = resolveSchemaPath(item, path.join('.'), stopOnFirstLevel);
            if (result)
                return result;
        }
    }
    return resolveSchemaPath(value, path.join('.'), stopOnFirstLevel);
};
export function parseOntologyUri(iri) {
    const lastIndexSlash = iri.lastIndexOf('/');
    const lastIndexHash = iri.lastIndexOf('#');
    const lastIndexColon = iri.lastIndexOf(':');
    const lastIndex = Math.max(lastIndexHash, lastIndexSlash, lastIndexColon);
    if (lastIndex === -1) {
        return { name: iri, iri: iri };
    }
    else {
        return {
            name: iri.substring(lastIndex + 1),
            prefix: iri.substring(0, lastIndex + 1),
            iri: iri,
        };
    }
}
export const getReadableSchemaPath = (schema, schemaPath, instancePath) => {
    const result = [];
    const getTitle = (object) => {
        const property = schemaPath.shift();
        const value = _.get(object, property);
        if (value && value.title) {
            result.push(`'${value.title}'`);
            if (schemaPath[0] && schemaPath[0] === 'items') {
                const propertyIndex = instancePath.findIndex((instanceProperty) => instanceProperty === property);
                if (propertyIndex !== -1) {
                    const arrayIndexValue = instancePath.find((instanceProperty, index) => {
                        if (index <= propertyIndex)
                            return;
                        if (!isNaN(parseInt(instanceProperty)))
                            return instanceProperty;
                        return;
                    });
                    if (arrayIndexValue)
                        result.push(`${parseInt(arrayIndexValue) + 1}`);
                }
            }
        }
        if (schemaPath && schemaPath.length) {
            getTitle(value);
        }
    };
    getTitle(schema);
    return result.join(' → ');
};
export const getAllSchemaPaths = (schema, options = {}, pathsMetaChain = [{ title: schema.title, propertySchema: schema }], outPathsMeta = []) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const primitivesOnly = (_a = options.primitivesOnly) !== null && _a !== void 0 ? _a : true;
    const nestingLevel = (_b = options.nestingLevel) !== null && _b !== void 0 ? _b : Infinity;
    const propertyPath = (_c = pathsMetaChain
        .map((p) => p.property)
        .filter(Boolean)
        .join('.')) !== null && _c !== void 0 ? _c : '';
    if ((schema.properties || ((_d = schema.items) === null || _d === void 0 ? void 0 : _d.properties)) && nestingLevel > 0) {
        const schemaProperties = ((_e = schema.items) === null || _e === void 0 ? void 0 : _e.properties) || schema.properties;
        const schemaPath = schema.items || schema;
        if (propertyPath && options.validator && !options.validator(propertyPath, schema, true)) {
            return outPathsMeta;
        }
        const isRelated = isRelationSchema(schemaPath);
        Object.entries(schemaProperties).forEach(([property, nestedSchema]) => {
            getAllSchemaPaths(nestedSchema, Object.assign(Object.assign({}, options), { nestingLevel: nestingLevel - 1 }), [
                ...pathsMetaChain,
                {
                    property,
                    title: nestedSchema.title,
                    propertySchema: nestedSchema,
                    shortcutOption: isRelated,
                },
            ], outPathsMeta);
        });
    }
    else if (schema.type === 'array' && ((_f = schema.items) === null || _f === void 0 ? void 0 : _f.anyOf) && ((_g = schema.items.anyOf) === null || _g === void 0 ? void 0 : _g.every((item) => item.enum))) {
        const pathMeta = {
            propertyJsonPath: propertyPath,
            propertySchema: schema,
            items: pathsMetaChain,
            type: schema.type,
            enumMapping: (_h = schema.items.anyOf) === null || _h === void 0 ? void 0 : _h.reduce((acc, item) => {
                var _a;
                if (((_a = item.enum) === null || _a === void 0 ? void 0 : _a[0]) && item.enumNames[0]) {
                    acc[item.enum[0]] = item.enumNames[0];
                }
                return acc;
            }, {}),
        };
        if ((_k = (_j = options.validator) === null || _j === void 0 ? void 0 : _j.call(options, propertyPath, schema, false)) !== null && _k !== void 0 ? _k : true) {
            outPathsMeta.push(pathMeta);
        }
    }
    else if (schema.type === 'array' && schema.items && !schema.items.anyOf) {
        const pathMeta = {
            propertyJsonPath: propertyPath,
            propertySchema: schema,
            items: pathsMetaChain,
            type: schema.type,
        };
        if ((_m = (_l = options.validator) === null || _l === void 0 ? void 0 : _l.call(options, propertyPath, schema, false)) !== null && _m !== void 0 ? _m : true) {
            outPathsMeta.push(pathMeta);
        }
    }
    else if ((schema.anyOf || ((_o = schema.items) === null || _o === void 0 ? void 0 : _o.anyOf)) && nestingLevel > 0) {
        const schemaAnyOf = schema.anyOf || ((_p = schema.items) === null || _p === void 0 ? void 0 : _p.anyOf);
        schemaAnyOf.forEach((nestedSchema) => {
            getAllSchemaPaths(nestedSchema, options, [
                ...pathsMetaChain,
                {
                    title: nestedSchema.title,
                    anyOf: true,
                    propertySchema: nestedSchema,
                },
            ], outPathsMeta);
        });
    }
    else {
        if ((primitivesOnly && schema.type !== 'array' && schema.type !== 'object') || (primitivesOnly && !options.nestingLevel) || !primitivesOnly) {
            const pathMeta = {
                propertyJsonPath: propertyPath,
                propertySchema: schema,
                items: pathsMetaChain,
                type: schema.type,
                enumMapping: schema.enum && _.zipObject(schema.enum, schema.enumNames),
            };
            if ((_r = (_q = options.validator) === null || _q === void 0 ? void 0 : _q.call(options, propertyPath, schema, false)) !== null && _r !== void 0 ? _r : true) {
                outPathsMeta.push(pathMeta);
            }
        }
    }
    return outPathsMeta;
};
export const getSchemaPathsMeta = (schema, propertyJsonPaths) => {
    const propertyJsonPathsSplit = propertyJsonPaths.map((propertyJsonPath) => propertyJsonPath.split('.'));
    const resultPaths = getAllSchemaPaths(schema, {
        validator: (path) => {
            const splitPath = path.split('.');
            const matchedPath = propertyJsonPathsSplit.find((path) => {
                for (const [index, value] of Object.entries(splitPath)) {
                    if (value !== path[Number.parseInt(index)])
                        return false;
                }
                return true;
            });
            return !!matchedPath;
        },
        primitivesOnly: false,
    });
    return _.uniqBy(resultPaths, (d) => d.propertyJsonPath).sort((a, b) => {
        const indexOfA = propertyJsonPaths.indexOf(a.propertyJsonPath);
        const indexOfB = propertyJsonPaths.indexOf(b.propertyJsonPath);
        return indexOfA - indexOfB;
    });
};
export const getFilePropertyJsonPaths = _.memoize((schema) => {
    const dataUrlSchemaPaths = getAllSchemaPaths(schema, {
        primitivesOnly: false,
        validator: (key, propertySchema, stepIntoValidation) => {
            var _a;
            if (stepIntoValidation) {
                return true;
            }
            if (propertySchema.type === 'string' && propertySchema.format === 'data-url') {
                return true;
            }
            if (propertySchema.type === 'array' && ((_a = propertySchema.items) === null || _a === void 0 ? void 0 : _a.format) === 'data-url') {
                return true;
            }
            return false;
        },
    });
    return dataUrlSchemaPaths.map(({ propertyJsonPath, propertySchema, items }) => {
        var _a, _b, _c;
        return ({
            propertyJsonPath,
            schemaPathItems: items,
            downloadable: ((_a = propertySchema === null || propertySchema === void 0 ? void 0 : propertySchema['cp_ui']) === null || _a === void 0 ? void 0 : _a.downloadable) === true || ((_c = (_b = propertySchema === null || propertySchema === void 0 ? void 0 : propertySchema.items) === null || _b === void 0 ? void 0 : _b['cp_ui']) === null || _c === void 0 ? void 0 : _c.downloadable) === true,
        });
    });
});
export function extractFromItem(item, schema, predicate) {
    const extractionMap = new Map();
    function recursiveExtract(item, schema, path = '') {
        if (path !== '' && predicate(schema, item)) {
            extractionMap.set(path, { schema, value: item });
        }
        if (isObjectType(item, schema) && schema.properties) {
            for (const key in schema.properties) {
                if (item.hasOwnProperty(key)) {
                    const propertySchema = schema.properties[key];
                    const newPath = path ? `${path}.${key}` : key;
                    recursiveExtract(item[key], propertySchema, newPath);
                }
            }
        }
        else if (schema.type === 'array' && schema.items) {
            if (Array.isArray(item) && item.length) {
                for (const [index, value] of item.entries()) {
                    const newPath = `${path}[${index}]`;
                    recursiveExtract(value, schema.items, newPath);
                }
            }
        }
        else if (schema.anyOf) {
            if (isDefined(item)) {
                const matchingTypeSchema = schema.anyOf.find((typeSchema) => typeSchema.$id === item._type);
                if (matchingTypeSchema) {
                    recursiveExtract(item, matchingTypeSchema, path);
                }
            }
        }
    }
    recursiveExtract(item, schema);
    return extractionMap;
}
export function isObjectType(item, schema) {
    return schema.type === 'object';
}
