import { isUuid } from '@cp/base-types';
import notification from '@cpa/base-core/helpers/toast';
import { IScreenProps } from '@cpa/base-core/types';
import { createCancelToken, IAxiosInstance } from '@cpa/base-http';
import { useBoolean } from '@fluentui/react-hooks';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { AxiosError, CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Widget from '../../components/Widget/Widget';

import ActivityItemPersona from './components/ActivityItemPersona/ActivityItemPersona';
import { IHistoryItem, IWorkItem } from './helpers/types';
import { isExternalUser, loopApi, loopApiSi } from './helpers/utils';
import styles from './LoopWorkItemHistory.module.scss';

interface HistoryProps extends IScreenProps {
  item: IWorkItem;
}

const History: React.FC<HistoryProps> = ({ page, item }) => {
  const [context, setContext] = useState<IHistoryItem[] | undefined>();
  const [loading, { setTrue: startLoading, setFalse: stopLoading }] = useBoolean(true);
  const [showExternal, { toggle: toggleExternal }] = useBoolean(false);
  const [t] = useTranslation();
  const cancelToken = useRef<CancelTokenSource | null>(null);

  const onChange = useCallback(() => {
    toggleExternal();
  }, [toggleExternal]);

  useEffect(() => {
    startLoading();

    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();

    // Generous approach to determine DevOps name (which is ID there) as special treatment
    // TODO: Ensure in data that we always use identifier instead of name
    const devOpsOrganizationId = isUuid(item.devOpsOrganization.identifier) ? item.devOpsOrganization.name : item.devOpsOrganization.identifier;

    const callAPI = (api: IAxiosInstance) => {
      api
        .get<IHistoryItem[]>(`/api/history?id=${item.workItemId}&organization=${devOpsOrganizationId}`, {
          cancelToken: cancelToken.current?.token,
        })
        .then((response) => {
          if (response.length) {
            const historyItems = response.reverse();

            const groupBy = (items: IHistoryItem[], getKey: Function) => {
              return items.reduce((memo: Record<number, IHistoryItem[]>, item: IHistoryItem) => {
                const key = getKey(item);
                memo[key] ||= [];

                if (item.revisedDate === '9999-01-01T00:00:00.000Z') delete memo[key];
                else memo[key].push(item);

                return memo;
              }, {});
            };

            const byId = groupBy(historyItems, ({ revisedBy }: IHistoryItem) => revisedBy.id);

            const results = Object.values(byId).map((items: IHistoryItem[]) => {
              return items.reduce((item: IHistoryItem) => {
                const res = {
                  ...item,
                };
                return res;
              });
            });

            let transfomedHistoryItems = results;

            if (!showExternal) {
              transfomedHistoryItems = transfomedHistoryItems.filter((item: IHistoryItem) => {
                return isExternalUser(item.revisedBy.uniqueName);
              });
            }

            setContext(transfomedHistoryItems);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error);
          notification.error(error.response?.data);
          notification.error(t('errors.connection.internalErrorTitle'));
        })
        .finally(() => {
          stopLoading();
        });
    };

    if (devOpsOrganizationId === 'cosmoconsultsi') callAPI(loopApiSi);
    else callAPI(loopApi);

    return () => {
      cancelToken.current?.cancel();
    };
  }, [item.workItemId, item.devOpsOrganization.identifier, item.devOpsOrganization.name, showExternal, startLoading, stopLoading, t]);

  return (
    <div className={styles.historyContainer}>
      <Widget className={styles.widget} title={page.name as string} headerIconName={page.icon as string} hideSettings={true}>
        <Toggle className={styles.msToggle} label={t('common.showExternalUsers')} inlineLabel onChange={onChange} />
        <div className={styles.historyContent}>
          {loading
            ? t('common.loading')
            : context?.map((rev: IHistoryItem) => <ActivityItemPersona key={rev.id} workItem={item} historyItem={rev} />)}
        </div>
      </Widget>
    </div>
  );
};

export default History;
