import { DatePicker, DayOfWeek, mergeStyleSets } from '@fluentui/react';
import { utils, WidgetProps } from '@rjsf/core';
import classNames from 'classnames';
import _pick from 'lodash/pick';
import React from 'react';

import TitleField, { TitleFieldType } from '../TitleField/TitleField';
import DescriptionField from '../DescriptionField/DescriptionField';

import styles from './DateWidget.module.scss';

const { pad } = utils;

// Keys of IDropdownProps from @fluentui/react
const allowedProps = [
  'componentRef',
  'styles',
  'theme',
  'calloutProps',
  'calendarProps',
  'textField',
  'calendarAs',
  'onSelectDate',
  'label',
  'isRequired',
  'disabled',
  'ariaLabel',
  'underlined',
  'pickerAriaLabel',
  'isMonthPickerVisible',
  'showMonthPickerAsOverlay',
  'allowTextInput',
  'disableAutoFocus',
  'placeholder',
  'today',
  'value',
  'formatDate',
  'parseDateFromString',
  'firstDayOfWeek',
  'strings',
  'highlightCurrentMonth',
  'highlightSelectedMonth',
  'showWeekNumbers',
  'firstWeekOfYear',
  'showGoToToday',
  'borderless',
  'className',
  'dateTimeFormatter',
  'minDate',
  'maxDate',
  'initialPickerDate',
  'allFocusable',
  'onAfterMenuDismiss',
  'showCloseButton',
  'tabIndex',
];

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
  },
});

// how we can get this to work with locales.
const formatDate = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const yyyy = pad(date.getFullYear(), 4);
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  return `${yyyy}-${MM}-${dd}`;
};

const parseDate = (dateStr?: string): Date | undefined => {
  if (!dateStr) {
    return undefined;
  }
  const [year, month, day] = dateStr.split('-').map((e) => parseInt(e));
  return new Date(year, month - 1, day);
};

// Original file
// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/fluent-ui/src/DateWidget/DateWidget.tsx
const DateWidget: React.FC<WidgetProps> = ({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  registry,
  onFocus,
  options,
  placeholder,
  schema,
}: WidgetProps) => {
  const _onSelectDate = (date: Date | null | undefined): void => {
    if (date) {
      const formatted = formatDate(date);
      formatted && onChange(formatted);
    }
  };
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>): void => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>): void => onFocus(id, value);

  const uiProps = _pick(options.props || {}, allowedProps) as object;
  return (
    <>
      <TitleField type={TitleFieldType.Primitive} title={label} required={label ? required : false} registry={registry} />
      <DescriptionField description={schema.description || ''} detailed />
      <DatePicker
        isRequired={!label ? required : false}
        className={classNames(controlClass.control, styles.input)}
        firstDayOfWeek={DayOfWeek.Monday}
        placeholder={placeholder}
        onSelectDate={_onSelectDate}
        onBlur={_onBlur}
        onFocus={_onFocus}
        value={parseDate(value)}
        disabled={
          disabled ||
          (schema as { disabled: boolean }).disabled ||
          readonly ||
          (
            schema as {
              readonly: boolean;
            }
          ).readonly
        }
        showGoToToday={false}
        {...uiProps}
      />
    </>
  );
};

export default DateWidget;
