import { ConnectionType, LogLevel, NotImplementedError } from '@cp/base-types';
import { LoggerBase } from './loggerBase';
export class ConnectionLogger extends LoggerBase {
    constructor(connections, name, customConsole, messsageFormatter) {
        super(name, customConsole, messsageFormatter);
        this.consoleActive = false;
        this.connections = Array.isArray(connections) ? connections : [connections];
        for (const connection of this.connections) {
            if (connection.type === ConnectionType.Console) {
                this.consoleActive = true;
            }
        }
    }
    async handleMessage(logMessage) {
        let consoleLog = false;
        try {
            for (const connection of this.connections) {
                if (logMessage.level <= connection.level) {
                    switch (connection.type) {
                        case ConnectionType.Console: {
                            this.doConsoleLog(logMessage);
                            consoleLog = true;
                            break;
                        }
                        case ConnectionType.Server: {
                            this.doServerLog(logMessage, connection).catch((err) => {
                                if (!consoleLog) {
                                    this.doFallbackLog(logMessage, err);
                                }
                            });
                            break;
                        }
                        case ConnectionType.Bus: {
                            this.doBusLog(logMessage, connection).catch((err) => {
                                if (!consoleLog) {
                                    this.doFallbackLog(logMessage, err);
                                }
                            });
                            break;
                        }
                    }
                }
            }
        }
        catch (error) {
            throw error;
        }
    }
    doFallbackLog(logMessage, error) {
        if (this.consoleActive) {
            const messageSegments = this.formatMessage(logMessage);
            this._console.info(...messageSegments);
            if (error) {
                this._console.error('Error in logging connection', error);
            }
        }
    }
    doConsoleLog(logMessage) {
        const messageSegments = this.formatMessage(logMessage);
        let logged = false;
        switch (logMessage.level) {
            case LogLevel.Debug:
                if (this._console.debug) {
                    this._console.debug(...messageSegments);
                    logged = true;
                }
                break;
            case LogLevel.Info:
                if (this._console.info) {
                    this._console.info(...messageSegments);
                    logged = true;
                }
                break;
            case LogLevel.Warn:
                if (this._console.warn) {
                    this._console.warn(...messageSegments);
                    logged = true;
                }
                break;
            case LogLevel.Error:
                if (this._console.error) {
                    this._console.error(...messageSegments);
                    logged = true;
                }
                break;
            case LogLevel.Log:
                if (this._console.log) {
                    this._console.log(...messageSegments);
                    logged = true;
                }
                break;
            default:
                throw new Error(`Log level not supported: ${logMessage.level}`);
        }
        if (!logged) {
            this._console.log(...messageSegments);
        }
    }
    async doServerLog(logMessage, connection) {
        throw new NotImplementedError();
    }
    async doBusLog(msg, connection) {
        throw new NotImplementedError();
    }
}
