import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { axiosDictionary, executeAggregationTemplate } from '@cpa/base-core/api';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { TypeConstants } from '@cp/base-types';
import { getCollectionNameFromSubjectUri } from '@cp/base-utils';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import { ScrollablePaneContext } from '../../../components/ScrollablePaneContextProvider/ScrollablePaneContextProvider';

const generateDots = (dotsCount: number, noTop: boolean): { x: number; y: number }[] => {
  const MIN_DOTS_COUNT = 10;
  const dots = Math.ceil(MIN_DOTS_COUNT / dotsCount) * dotsCount;
  const distance = 300 / dots;
  const dotsArray = [];
  let currentPoint = 0;
  for (let i = 0; i < dots; i++) {
    if (currentPoint <= 100) {
      dotsArray.push({ x: 0, y: currentPoint });
      currentPoint += distance;
    } else if (currentPoint <= 200) {
      if (!noTop) {
        dotsArray.push({ x: currentPoint - 100, y: 0 });
      }
      currentPoint += distance;
    } else if (currentPoint <= 300) {
      dotsArray.push({ x: 100, y: currentPoint - 200 });
      currentPoint += distance;
    }
  }

  return dotsArray.map((dot) => ({ x: dot.x - (dot.x - 50) / 1.6, y: dot.y - (dot.y - 50) / 1.6 }));
};

const generateMesh = (colors: string[]): string => {
  const emptyDots = generateDots(colors.length, colors.length === 1);
  const dotsCount = emptyDots.length;
  const dotsForColor = Math.max(Math.floor(dotsCount / colors.length), 1);
  // map dotsCoords, adding color to each dot, changing color every dotsForColor dots
  const dots = emptyDots.map((dot, index) => ({
    ...dot,
    color: colors[Math.floor(index / dotsForColor)],
  }));
  return dots.map((dot) => `radial-gradient(at ${dot.x}% ${dot.y}%, ${dot.color} 0px, transparent 50%)`).join(', ');
};

const usePlatformColors = (platforms: string[], disabled?: boolean): void => {
  const scrollablePaneContext = useContext(ScrollablePaneContext);
  const surfaceLight = useSelector((state: IGlobalState) => state.settings.surfaceLight);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    if (!disabled && surfaceLight) {
      const mesh = generateMesh(colors);
      if (mesh) {
        scrollablePaneContext?.setCustomStyles({
          contentContainer: {
            backgroundImage: mesh,
          },
        });
      }
    }

    return () => {
      cancelToken.current?.cancel();
      scrollablePaneContext?.setCustomStyles({});
    };
  }, [surfaceLight, scrollablePaneContext, colors, disabled]);

  const cancelToken = useRef<CancelTokenSource | null>(null);

  const loadItems = useCallback(async () => {
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();

    try {
      const response = await executeAggregationTemplate(
        axiosDictionary.appDataService,
        TypeConstants.ProductPlatform,
        'solution-colors',
        {
          platforms: platforms?.map((platform) => platform.toLowerCase()) || [],
          fromCollection: getCollectionNameFromSubjectUri(TypeConstants.ProductPlatform),
        },
        undefined,
        cancelToken.current
      );
      setColors(response[0].colors as string[]);
    } catch (e) {
      console.error(`Response error.`, e as Error);
    }
  }, [platforms]);

  useEffect(() => {
    loadItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePlatformColors;
