import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

interface IParentingIndicatorProps {
  level: number;
  color?: string;
}

const ParentingIndicator: React.FC<IParentingIndicatorProps> = ({ level, color }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const defaultColor = useMemo(() => {
    if (darkMode) return 'rgba(255, 255, 255, .15)';
    return 'rgba(0, 0, 0, .15)';
  }, [darkMode]);
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: (level + 1) * 10,
        background: 'transparent',
        zIndex: 1500,
        borderLeft: '3px solid',
        borderBottom: '2px solid',
        borderTop: '2px solid',
        borderColor: color || defaultColor,
        pointerEvents: 'none',
        paddingLeft: 3,
        fontSize: level > 9 ? '80%' : '100%',
      }}
    >
      {level}
    </div>
  );
};

export default ParentingIndicator;
