import { useDebouncedValue } from '@cpa/base-core/hooks';
import { IMenuItem } from '@cpa/base-core/types';
import {
  IFocusTrapZoneProps,
  ISearchBoxStyleProps,
  ISearchBoxStyles,
  IStyleFunctionOrObject,
  Panel,
  PanelType,
  SearchBox,
  ThemeContext,
} from '@fluentui/react';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { useMediaQuery } from 'react-responsive';

import CollapsibleItem from './components/CollapsibleItem/CollapsibleItem';
import styles from './VerticalMenu.module.scss';

export interface IVerticalMenuProps {
  isOpen: boolean;
  onDismiss: () => void;
  onLinkClick?: (link: string) => void;
  menuItems: IMenuItem[];
  activePath: string;
  appTitle?: string;
  isSearchVisible?: boolean;
}

const processWithFilter = (item: IMenuItem, filter: string): IMenuItem | null => {
  if (item.name.toUpperCase().includes(filter.toUpperCase()) || item.cpType?.toUpperCase().includes(filter.toUpperCase())) {
    return item;
  }
  if (item.links) {
    item.links = item.links.map((i) => processWithFilter(i, filter)).filter((i) => !!i) as IMenuItem[];
    return item.links.length > 0 ? item : null;
  }
  return null;
};

export const applyFilter = (items: IMenuItem[], filter: string): IMenuItem[] => {
  return (_.cloneDeep(items) as IMenuItem[]).map((i) => processWithFilter(i, filter)).filter((i) => !!i) as IMenuItem[];
};

const VerticalMenu: React.FC<IVerticalMenuProps> = ({ isSearchVisible, isOpen, onDismiss, onLinkClick, menuItems, activePath }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const theme = useContext(ThemeContext);
  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });

  const focusTrapZoneProps: IFocusTrapZoneProps | undefined = useMemo(
    () => ({
      forceFocusInsideTrap: false,
      focusPreviouslyFocusedInnerElement: false,
      disableRestoreFocus: true,
      disableFirstFocus: isMobileDevice || !isSearchVisible,
    }),
    [isMobileDevice, isSearchVisible]
  );

  const onClick = useCallback(
    (key: string) => {
      if (onLinkClick) {
        onLinkClick(key);
      }
    },
    [onLinkClick]
  );

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);

  const onSearchChange = useCallback((_: unknown, v: string | undefined) => {
    setSearchValue(v || '');
  }, []);

  const searchBoxStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = useMemo(
    () => ({
      root: {
        backgroundColor: 'none',
        '&::after': {
          border: 'unset',
        },
      },
      icon: {
        color: darkMode ? theme?.palette.black : undefined,
      },
      field: {
        color: darkMode ? theme?.palette.black : undefined,
      },
    }),
    [darkMode, theme]
  );

  const filteredMenuItems = useMemo(() => {
    if (debouncedSearchValue) {
      return applyFilter(menuItems, debouncedSearchValue);
    }

    return menuItems;
  }, [menuItems, debouncedSearchValue]);

  const handleDismiss = useCallback(
    (ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => {
      // We want to dismiss menu only if clicked on overlay
      if (ev?.type === 'click') {
        onDismiss();
      }
    },
    [onDismiss]
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={handleDismiss}
      type={PanelType.smallFixedNear}
      hasCloseButton={false}
      isLightDismiss={true}
      isBlocking={true}
      className={classNames(styles.menuPanel, { [styles.menuPanelLight]: !darkMode })}
      layerProps={{
        styles: {
          root: {
            // We want header callouts to have more priority
            zIndex: '999999 !important',
          },
        },
      }}
      focusTrapZoneProps={focusTrapZoneProps}
    >
      {isSearchVisible && (
        <div className={classNames(styles.search, { [styles.searchLight]: !darkMode })}>
          <SearchBox placeholder={t('menu.filter')} styles={searchBoxStyles} value={searchValue} onChange={onSearchChange} />
        </div>
      )}
      <CollapsibleItem
        items={filteredMenuItems}
        onItemClick={onClick}
        activePath={activePath}
        isFiltered={!!debouncedSearchValue}
        disableFirstFocus={!isSearchVisible}
      />
    </Panel>
  );
};

export default VerticalMenu;
