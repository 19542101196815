export class ConsoleBinding {
    constructor(console, logger) {
        this._logger = logger;
        this.consoleCopy = Object.assign({}, console);
        this.bind(console);
    }
    bind(console) {
        console.debug = this._logger.debug.bind(this._logger);
        console.log = this._logger.log.bind(this._logger);
        console.info = this._logger.info.bind(this._logger);
        console.warn = this._logger.warn.bind(this._logger);
        console.error = this._logger.error.bind(this._logger);
    }
}
