import { BaseType, BatchOperationItemResult, DataItemProperties, IJSONSchema, ItemVersion, MainCollectionItem, Schemas } from '@cp/base-types';
import React, { useMemo, useState } from 'react';
import { Checkbox, DefaultButton, MessageBar, MessageBarType } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';

import styles from './BatchedDifference.module.scss';
import { IDiffSource } from './DifferenceAsObjects/helpers/difference';
import Difference from './Difference';

export interface IDifferenceProps {
  page: Schemas.CpaPage;
  schema: IJSONSchema;
  results: BatchOperationItemResult<any>[];
}

function hasVersions<T extends BaseType>(item: MainCollectionItem<T>): boolean {
  const versions = item[DataItemProperties.META_DATA_KEY]?.versions;
  return versions && versions.length > 0 ? true : false;
}

function getLatestVersion<T extends BaseType>(item: MainCollectionItem<T>): ItemVersion<T> | MainCollectionItem<T> {
  const versions = item[DataItemProperties.META_DATA_KEY]!.versions!;
  return versions[versions.length - 1];
}

function getVersionSchema(schema: IJSONSchema): IJSONSchema {
  const versionSchema = (schema as any).properties[DataItemProperties.META_DATA_KEY].properties['versions'].items;
  if (versionSchema) {
    return versionSchema;
  } else {
    return schema;
  }
}

const BatchedDifference: React.FC<IDifferenceProps> = ({ page, schema, results }) => {
  const [t] = useTranslation();
  const [currentPosition, setCurrentPosition] = useState<number>(0);

  const [compareOnlyLatestVersion, { toggle: toggleCompareOnlyLatestVersion }] = useBoolean(true);

  const currentResult = useMemo(() => {
    if (!results) return null;
    return results[currentPosition];
  }, [results, currentPosition]);

  const isError = useMemo(() => {
    if (!currentResult) return false;
    return currentResult.operation === 'FAILED';
  }, [currentResult]);

  const itemsToCompare = useMemo(() => {
    if (!currentResult) return [];
    const itemsToCompare: IDiffSource[] = [];

    if (compareOnlyLatestVersion && hasVersions(currentResult.item)) {
      let itemA;
      switch (currentResult.operation) {
        case 'SKIPPED':
          itemA = getLatestVersion(currentResult.item);
          break;
        case 'UPDATED':
          itemA = getLatestVersion(currentResult.newItem);
          break;
        case 'FAILED':
          itemA = currentResult?.error;
          break;
        case 'DELETED':
          itemA = 'DELETED';
          break;
      }

      const itemB = getLatestVersion(currentResult.item);

      itemsToCompare.push({
        itemA: itemA,
        itemB: itemB,
        schema: getVersionSchema(schema),
        nameA: (itemA.name || itemA.identifier || '-') as unknown as string,
        nameB: (itemB.name || itemB.identifier || '-') as unknown as string,
      });
    } else {
      let itemA;
      switch (currentResult.operation) {
        case 'SKIPPED':
          itemA = currentResult.item;
          break;
        case 'UPDATED':
          itemA = currentResult.newItem;
          break;
        case 'FAILED':
          itemA = {};
          break;
        case 'DELETED':
          itemA = {};
          break;
      }

      const itemB = currentResult.item;

      itemsToCompare.push({
        itemA: itemA,
        itemB: itemB,
        schema: schema,
        nameA: (itemA.name || itemA.identifier || '-') as unknown as string,
        nameB: (itemB.name || itemB.identifier || '-') as unknown as string,
      });
    }

    return itemsToCompare;
  }, [currentResult, schema, compareOnlyLatestVersion]);

  // Prev and next buttons respecting the current position and the length of the results array
  const controls = useMemo(
    () => (
      <div className={styles.batchedDifferenceControls}>
        <DefaultButton
          iconProps={{ iconName: 'ChevronLeftMed' }}
          onClick={() => setCurrentPosition(currentPosition - 1)}
          disabled={currentPosition <= 0}
        />

        <DefaultButton
          iconProps={{ iconName: 'ChevronRightMed' }}
          onClick={() => setCurrentPosition(currentPosition + 1)}
          disabled={currentPosition >= results.length - 1}
        />
      </div>
    ),
    [currentPosition, results.length]
  );

  if (!currentResult) {
    return <div className={styles.batchedDifferenceTitle}>{t('common.nothingToDisplay')}</div>;
  }

  return (
    <div>
      {controls}
      <div>
        <span className={styles.batchedDifferenceTitle}>
          {t('common.item')} <strong>{currentPosition + 1}</strong> / {results.length}
          <strong>{currentResult?.operation}</strong>
        </span>
        {currentResult?.operation === 'FAILED' && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true} className={styles.error}>
            {currentResult.error}
          </MessageBar>
        )}
      </div>

      <Checkbox label={t('common.compareOnlyLatestVersion')} checked={compareOnlyLatestVersion} onChange={toggleCompareOnlyLatestVersion} />

      <Difference
        className={classNames({
          [styles.difference]: true,
          [styles.differenceWithError]: isError,
        })}
        page={page}
        itemsToCompare={itemsToCompare}
      />
    </div>
  );
};

export default BatchedDifference;
