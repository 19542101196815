import Konva from 'konva';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';

import ImageEditorStartMenu from './ImageEditorStartMenu/ImageEditorStartMenu';
import ImageEditorCropMenu from './ImageEditorCropMenu/ImageEditorCropMenu';
import ImageEditorBrightnessContrastMenu from './ImageEditorBrightnessContrastMenu/ImageEditorBrightnessContrastMenu';

import styles from './ImageEditorMenu.module.scss';

interface ImageEditorMenuProps {
  filename: string;
  stage: Konva.Stage;

  onChange: (imageUrl: string) => void;
  onSubmit: (url: string) => void;
  onClose: () => void;
}

export enum ImageEditorMenuType {
  start,
  crop,
  brightnessContrast,
}

const ImageEditorMenu: React.FC<ImageEditorMenuProps> = ({ filename, stage, onSubmit, onClose, onChange }) => {
  const [activeMenu, setActiveMenu] = useState<ImageEditorMenuType>(ImageEditorMenuType.start);

  const onGoBack = useCallback(() => {
    setActiveMenu(ImageEditorMenuType.start);
  }, [setActiveMenu]);

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const getMenuElement = (): JSX.Element => {
    switch (activeMenu) {
      case ImageEditorMenuType.crop:
        return <ImageEditorCropMenu stage={stage} onChange={onChange} onClose={onGoBack} />;
      case ImageEditorMenuType.brightnessContrast:
        return <ImageEditorBrightnessContrastMenu stage={stage} onChange={onChange} onClose={onGoBack} />;
      default:
        return <ImageEditorStartMenu filename={filename} stage={stage} onSubmit={onSubmit} onClose={onClose} setActiveMenu={setActiveMenu} />;
    }
  };

  return <div className={classNames(styles.menu, { [styles.menuDark]: darkMode })}>{getMenuElement()}</div>;
};

export default ImageEditorMenu;
