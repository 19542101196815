import React, { useContext } from 'react';
import { IJSONSchema } from '@cp/base-types';
import { FormContext, ItemInfoContext, PathContext } from '@cpa/base-core/constants';

import LocalizationEditor from './LocalizationEditor';

export interface LocalizationEditorContainerProps {
  schema: IJSONSchema;
  rootSchema: IJSONSchema;
  widgetRef: React.RefObject<HTMLDivElement | null>;
  onClose: () => void;
}

const LocalizationEditorContainer: React.FC<LocalizationEditorContainerProps> = (props) => {
  const formContext = useContext(FormContext);
  const pathContext = useContext(PathContext);
  const itemInfoContext = useContext(ItemInfoContext);

  return (
    <LocalizationEditor
      {...props}
      page={formContext.page}
      baseLanguage={formContext.initialFormData?._baseLanguage}
      identifier={formContext.initialFormData?.identifier}
      onFieldLocalizationChanged={formContext.onFieldLocalizationChanged}
      path={pathContext}
      currentLanguage={itemInfoContext?.currentLanguage}
    />
  );
};

export default LocalizationEditorContainer;
