import { getEndpoint, IDataStoreItemDto } from '@cpa/base-core/api';
import urlJoin from 'url-join';
import { Schemas } from '@cp/base-types';

export const mergeItem = async (from: string, to: string, page: Schemas.CpaPage, properties: string[]): Promise<void> => {
  if (!page.dataEndpoint?.identifier || !page.dataUrl) {
    return;
  }

  const endpoint = getEndpoint(page.dataEndpoint?.identifier);
  const baseLinkWithoutQuery = page.dataUrl.indexOf('?') >= 0 ? page.dataUrl.substring(0, page.dataUrl.indexOf('?')) : page.dataUrl;
  const baseLinkQuery = page.dataUrl.indexOf('?') >= 0 ? page.dataUrl.substring(page.dataUrl.indexOf('?')) : '';

  const fullLink = urlJoin(baseLinkWithoutQuery, 'merge', encodeURIComponent(from), encodeURIComponent(to), baseLinkQuery);

  await endpoint.axios.patch<IDataStoreItemDto>(fullLink, null, {
    params: {
      properties: properties.join(','),
    },
  });
};
