import { Label } from '@fluentui/react';
import { FieldProps } from '@rjsf/core';
import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { FormContext, ItemInfoContext } from '@cpa/base-core/constants';
import { DataItemProperties } from '@cp/base-types';

import LocalizationEditorContainer from '../TextWidget/components/LocalizationEditor/LocalizationEditorContainer';

import styles from './TitleField.module.scss';

export enum TitleFieldType {
  Primitive = 'primitive',
  Object = 'object',
  Root = 'root',
}

export interface ITitleFieldProps extends Omit<Partial<FieldProps>, 'title'> {
  title?: string | JSX.Element | undefined;
  type?: TitleFieldType;
  onClick?: (event?: React.MouseEvent) => void;
  isLoading?: boolean;
  tooltipText?: string;
  customClassName?: string;
}

const TitleField = ({
  title,
  required,
  localizable,
  type,
  schema,
  onClick,
  registry,
  isLoading,
  tooltipText,
  customClassName,
}: ITitleFieldProps): JSX.Element | null => {
  const itemInfoContext = useContext(ItemInfoContext);
  const formContext = useContext(FormContext);

  const blockLocalization =
    !!formContext.initialFormData &&
    DataItemProperties.BLOCK_LOCALIZATION in formContext.initialFormData &&
    !!formContext.initialFormData[DataItemProperties.BLOCK_LOCALIZATION];

  const [t] = useTranslation();

  const innerElement = useRef<HTMLDivElement>(null);
  const [isLocalizationEditorShown, { toggle: toggleSetIsLocalizationEditorShown }] = useBoolean(false);

  if (!title || type === TitleFieldType.Root) {
    return null;
  }

  return (
    <Label
      className={classNames(customClassName, {
        [styles.object]: type === TitleFieldType.Object,
        [styles.primitive]: type === TitleFieldType.Primitive || !type,
        [styles.clickable]: !!onClick,
        [styles.loading]: isLoading,
      })}
      required={required}
      onClick={onClick}
      title={tooltipText}
    >
      {title}

      {localizable && !blockLocalization && (
        <div
          ref={innerElement}
          data-testid="localizableIcon"
          className={classNames(styles.localizable, { [styles.localizationEditable]: itemInfoContext?.type === 'edit' })}
          title={t('common.localizable')}
          onClick={toggleSetIsLocalizationEditorShown}
        >
          <svg fill="currentColor" viewBox="0 0 2048 2048">
            <path
              shapeRendering="geometricPrecision"
              d="M1024 0q141 0 272 36t245 103 207 160 160 208 103 245 37 272q0 141-36 272t-103 245-160 207-208 160-245 103-272 37q-141 0-272-36t-245-103-207-160-160-208-103-244-37-273q0-141 36-272t103-245 160-207 208-160T751 37t273-37zm809 640q-38-81-92-152t-120-131-143-105-161-75q36 50 65 106t51 115 39 121 28 121h333zm87 384q0-133-37-256h-363q8 64 12 127t4 129q0 65-4 128t-12 128h363q37-123 37-256zm-896 896q49 0 91-27t79-71 64-99 51-113 37-110 23-92H679q8 39 23 92t37 110 50 112 65 100 78 71 92 27zm367-640q8-64 12-127t5-129q0-65-4-128t-13-128H657q-8 64-12 127t-5 129q0 65 4 128t13 128h734zM128 1024q0 133 37 256h363q-8-64-12-127t-4-129q0-65 4-128t12-128H165q-37 123-37 256zm896-896q-49 0-91 27t-79 71-64 99-51 113-37 110-23 92h690q-8-39-23-92t-37-110-50-112-65-100-78-71-92-27zm-293 49q-84 29-161 75T427 357 307 487t-92 153h333q12-60 28-121t38-120 52-116 65-106zM215 1408q38 81 92 152t120 131 143 105 161 75q-36-50-65-106t-51-115-39-121-28-121H215zm1102 463q84-29 161-75t143-105 120-130 92-153h-333q-12 60-28 121t-38 120-52 116-65 106z"
            ></path>
          </svg>
        </div>
      )}

      {isLocalizationEditorShown && schema && itemInfoContext?.type === 'edit' && (
        <LocalizationEditorContainer
          schema={schema}
          rootSchema={registry.rootSchema}
          widgetRef={innerElement}
          onClose={toggleSetIsLocalizationEditorShown}
        ></LocalizationEditorContainer>
      )}
    </Label>
  );
};

export default TitleField;
