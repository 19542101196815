import React, { useCallback, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './ShowMore.module.scss';

export interface IShowMoreProps {
  maxHeight: number | null;
  children: JSX.Element | JSX.Element[];
  onExpandClick?: () => void;
}

const ShowMore: React.FC<IShowMoreProps> = ({ maxHeight, children, onExpandClick }) => {
  const [t] = useTranslation();

  const [height, setHeight] = useState<number | null>(null);
  const [expanded, setExpanded] = useState(false);
  const { ref: resizeRef } = useResizeDetector({
    onResize: (width, height) => {
      setHeight(height || 0);
    },
  });

  const isExpandable = useMemo(() => {
    if (!height || !maxHeight) return false;
    return height > maxHeight;
  }, [height, maxHeight]);

  const contentHeight = useMemo(() => {
    if (!maxHeight || !isExpandable || (isExpandable && expanded)) return undefined;
    return maxHeight;
  }, [expanded, isExpandable, maxHeight]);

  const toggleExpanded = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      e.stopPropagation();
      // Support custom click behaviour, otherwise expand
      if (onExpandClick) {
        onExpandClick();
      } else {
        setExpanded((expanded) => !expanded);
      }
    },
    [onExpandClick]
  );

  return (
    <div className={styles.root}>
      <div className={classNames({ [styles.short]: isExpandable && !expanded })}>
        <div className={styles.wrapper} style={{ maxHeight: contentHeight }}>
          <div ref={resizeRef}>{children}</div>
        </div>
      </div>
      {isExpandable && !expanded ? (
        <span className={styles.showMore} onClick={toggleExpanded}>
          {t('common.showMore')}
        </span>
      ) : null}
      {isExpandable && expanded ? (
        <span className={styles.showLess} onClick={toggleExpanded}>
          {t('common.showLess')}
        </span>
      ) : null}
    </div>
  );
};

export default ShowMore;
