import { IDarkMode } from '@cpa/base-core/types';
import { IMenuItem } from '@cpa/base-core/types';
import {
  CommandBar,
  CommandBarButton,
  IButtonProps,
  ICommandBarItemProps,
  ISearchBoxStyleProps,
  ISearchBoxStyles,
  IStyleFunctionOrObject,
  SearchBox,
  ThemeContext,
} from '@fluentui/react';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { CSSProperties, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedValue, useIsCached } from '@cpa/base-core/hooks';
import { useTranslation } from 'react-i18next';
import { menuItemsToCommandBarItems } from '@cpa/base-core/helpers';

import { applyFilter } from '../VerticalMenu/VerticalMenu';

import styles from './HorizontalMenu.module.scss';

export interface IHorizontalMenuProps extends IDarkMode {
  menuItems: IMenuItem[];
  activePath: string;
  style?: CSSProperties;
  isSearchVisible?: boolean;
}

const CustomButton: React.FunctionComponent<IButtonProps> = (props) => {
  return (
    <div className={styles.buttonWrapper}>
      <div className={styles.clickableArea}>
        {/* <div className={styles.hoverMarker} /> */}
        <CommandBarButton {...props} />
        <div className={styles.hoverLine} />
      </div>
      <span className={styles.divider} />
    </div>
  );
};

const commandBarStyles = { root: { marginBottom: 0, padding: 0, backgroundColor: 'transparent' } };

const HorizontalMenu: React.FC<IHorizontalMenuProps> = ({ menuItems, style, isSearchVisible, darkMode }) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isCached = useIsCached();

  const onItemClick = useCallback(
    (item: IMenuItem) => {
      if (item.external) {
        window.open(item.url, '_blank');
        return;
      }

      if (item.url) {
        dispatch(push(item.url));
      }
    },
    [dispatch]
  );

  const onSearchChange = useCallback((_: unknown, v: string | undefined) => {
    setSearchValue(v || '');
  }, []);

  const commandBarItems: ICommandBarItemProps[] = useMemo(() => {
    let filteredItems = menuItems;
    if (debouncedSearchValue) {
      filteredItems = applyFilter(menuItems, debouncedSearchValue);
    }
    return menuItemsToCommandBarItems(filteredItems, onItemClick);
  }, [debouncedSearchValue, menuItems, onItemClick]);

  const searchBoxStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = useMemo(
    () => ({
      root: {
        width: 200,
        backgroundColor: 'none',
        margin: '0 8px',
        '&::after': {
          border: 'unset',
        },
      },
      icon: {
        color: darkMode ? theme?.palette.black : undefined,
      },
      field: {
        color: darkMode ? theme?.palette.black : undefined,
      },
    }),
    [darkMode, theme]
  );

  if (isCached) return null;

  return (
    <div className={classNames(styles.wrapper, { [styles.dark]: darkMode })} style={style}>
      {isSearchVisible && (
        <div className={styles.searchWrapper}>
          <SearchBox placeholder={t('menu.filter')} styles={searchBoxStyles} value={searchValue} onChange={onSearchChange} />
          <div className={styles.divider} />
        </div>
      )}
      <CommandBar className={styles.commandBar} items={commandBarItems} styles={commandBarStyles} buttonAs={CustomButton} />
    </div>
  );
};

export default HorizontalMenu;
