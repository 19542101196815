const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpaUxPreferenceFeatureFlag',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaUxPreferenceFeatureFlag' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=uxPreferenceFeatureFlags%2FfeatureFlags%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa Ux Preference Feature Flag',
    'properties': {
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': "<div>Short description or summary that serves to convey the most important information on the subject or subject matter in a concise form (1-3 sentences). Often used for list, card or tiles. The idea is to capture the reader's or viewer's attention without going into detail. A short description can be compared with a teaser text.</div>\n<div> </div>",
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 47 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const CpaUxPreferenceFeatureFlagSchema = Schema;
