import { IGlobalState } from '@cpa/base-core/store';
import Konva from 'konva';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ImageEditorAdaptivity } from '../helpers/ImageEditorAdaptivity';
import { ImageEditorOperations } from '../helpers/ImageEditorOperations';

import ImageEditorMenu from './ImageEditorMenu/ImageEditorMenu';
import styles from './ImageEditor.module.scss';

interface ImageEditorProps {
  filename: string;

  image: HTMLImageElement;

  onSubmit: (src: string) => void;
  onClose: () => void;
}

const ImageEditor: React.FC<ImageEditorProps> = ({ filename, image, onSubmit, onClose }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const wrapper = useRef<HTMLDivElement>(null);

  const [stage, setStage] = useState<Konva.Stage>();

  useEffect(() => {
    const createdStage = ImageEditorOperations.create(image, wrapper.current!);
    setStage(createdStage);
  }, [image]);

  useEffect(() => {
    return () => {
      if (stage) {
        ImageEditorOperations.remove(stage);
      }
    };
  }, [stage]);

  const onChange = useCallback((imageUrl: string) => {
    const image = new window.Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageUrl;
    image.onload = () => {
      const createdStage = ImageEditorOperations.create(image, wrapper.current!);
      setStage(createdStage);
    };
  }, []);

  const refreshSize = useCallback(() => {
    if (!stage) {
      return;
    }

    ImageEditorAdaptivity.refreshSize(stage, wrapper.current!);
  }, [stage]);

  useEffect(() => {
    refreshSize();

    const refreshSizeInterval = setInterval(() => {
      refreshSize();
    }, 200);

    return () => {
      clearInterval(refreshSizeInterval);
    };
  }, [refreshSize]);

  return (
    <div className={styles.body}>
      <div>{!!stage && <ImageEditorMenu stage={stage} filename={filename} onChange={onChange} onClose={onClose} onSubmit={onSubmit} />}</div>

      <div className={classNames(styles.contents, { [styles.contentsDark]: darkMode })}>
        <div ref={wrapper} className={styles.contentsWrap}></div>
      </div>
    </div>
  );
};

export default ImageEditor;
