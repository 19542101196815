import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import history from '../app/history';

import app from './app/reducer';
import auth from './auth/reducer';
import settings from './settings/reducer';
import websocket from './websocket/reducer';

export default combineReducers({
  app,
  auth,
  settings,
  websocket,
  router: connectRouter(history),
});
