const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Industry',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIndustry' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIndustry?$filter=parent%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolutionDomain?$filter=industries%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserProfile?$filter=industries%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserProfileShadow?$filter=industries%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessEndeavor?$filter=industries%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parent.identifier',
    'cp_aggregationPipeline': [
        '[{"$graphLookup":{"from":"platform_cosmoconsult_com_ontology_SolutionDomain","startWith":"$identifier","connectFromField":"identifier","connectToField":"industries.identifier","as":"__searchSolutionDomains","depthField":"level","maxDepth":5}},{"$set":{"__searchSolutionDomains":{"$function":{"body":"function (arr) { arr.sort((a, b) => b.level - a.level); arr = arr.map(({ identifier, name }) => ({identifier, name})); return arr; }","args":["$__searchSolutionDomains"],"lang":"js"}}}},{"$set":{"__searchTextSolutionDomains":{"$function":{"body":"function (arr) { arr.sort((a, b) => b.level - a.level); arr = arr.map((a) => a.name); return arr; }","args":["$__searchSolutionDomains"],"lang":"js"}}}}]',
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_aggergationPipelineSequence': 'BeforeFilters',
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Industry',
    'properties': {
        'parent': {
            'title': 'Parent',
            '$id': 'http://platform.cosmoconsult.com/ontology/Industry',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FIndustry' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parent', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parent.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Industry' } },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'isicRev4Code': {
            'title': 'Isic Rev 4 Code',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isicRev4Code', 'rel': 'canonical' }],
        },
        '__searchSolutionDomains': {
            'title': 'Search Solution Domains',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/SolutionDomain',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolutionDomain' }],
                'cp_parentPropertyJsonPath': 'parents.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Solution Domain',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Solution Domain' } },
                'readOnly': true,
            },
            'cp_ui': { 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/__searchSolutionDomain', 'rel': 'canonical' }],
        },
        '__searchTextSolutionDomains': {
            'title': 'Search Text Solution Domains',
            'type': 'array',
            'items': { 'type': 'string', 'readOnly': true },
            'cp_ui': { 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/__searchTextSolutionDomain', 'rel': 'canonical' }],
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 33 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 32 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 31 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name'],
};
export const IndustrySchema = Schema;
