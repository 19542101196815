import { IDataItem } from '@cpa/base-core/types';
import { CommandBarButton } from '@fluentui/react';
import React, { useCallback } from 'react';

export interface ISelectionButtonProps {
  item: IDataItem;
  isSelected?: boolean;
  onClick?: (item: IDataItem) => void;
  className?: string;
  preventDefault?: boolean;
}

const SelectionButton: React.FC<ISelectionButtonProps> = ({ isSelected, onClick, item, className, preventDefault }) => {
  const handleSelectClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (preventDefault) {
        e.preventDefault();
      }
      e.stopPropagation();
      onClick?.(item);
    },
    [item, onClick]
  );

  return (
    <CommandBarButton
      onClick={handleSelectClick}
      iconProps={{ iconName: isSelected ? 'CompletedSolid' : 'CircleRing' }}
      styles={{
        root: {
          paddingTop: 12,
          paddingBottom: 12,
          backgroundColor: 'transparent',
          zIndex: 1,
          width: 'max-content',
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
      className={className}
    />
  );
};

export default SelectionButton;
