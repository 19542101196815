import { getSafeString } from '@cpa/base-core/helpers';
import { IHtmlRendererProps } from '@cpa/base-core/types';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

const DefaultHtmlRenderer: React.FC<{ html: string; onInit?: () => void } | IHtmlRendererProps> = ({ html, onInit }) => {
  useEffect(() => {
    onInit?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={'html-content'}>{ReactHtmlParser(getSafeString(html) as string) as React.ReactNode}</div>;
};

export default DefaultHtmlRenderer;
