import { createFilter } from '@cp/base-odata';
import { IJSONSchema } from '@cp/base-types';
import { IODataProps, ODataPropsFilter, formatEntries, isDefinedAndNotEmpty, objectToFlattenMap } from '@cp/base-utils';
import { CancelTokenSource, createCancelToken, isCancelError } from '@cpa/base-http';
import * as _ from 'lodash';
import buildQuery, { Filter, QueryOptions } from 'odata-query';
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEntitiesFromEndpoint, getSchema, replaceDataUrlPlaceholders } from '../api';
import { IGlobalState } from '../store';
import {
  BaseApi,
  DynamicDataUrlFunction,
  IDataItem,
  IDataUrlDetails,
  IEntitiesResponse,
  ILoadableDataInterceptors,
  LoadItemsFunction,
} from '../types';

const reservedODataQueryKeys = ['$top', '$skip', '$orderBy', '$filter', '$select'];

function checkIsFilterEmpty(filter?: object, ignoredODataPaths?: Set<string>): boolean {
  return (
    !filter ||
    _.isEmpty(filter) ||
    _.every(filter, (value, key) => {
      if (ignoredODataPaths?.has(key)) {
        return true;
      }

      if (_.isObjectLike(value)) {
        return checkIsFilterEmpty(value, ignoredODataPaths);
      }

      return false;
    })
  );
}

export const useLoadableData = (
  dataUrl: string | DynamicDataUrlFunction,
  dataEndpointIdentifier: string,
  groupPropertyJsonPath?: string,
  schemaUrl?: string,
  externalDataQuery?: Record<string, string>, // Used to pass query params into data url
  externalODataFilter?: ODataPropsFilter, // OData filter which will be appended to request
  getExternalODataFilter?: () => ODataPropsFilter | Promise<ODataPropsFilter>,
  interceptors?: ILoadableDataInterceptors
): {
  loadItems: LoadItemsFunction;
  parsedODataFilter: ODataPropsFilter | null;
  parsedMongoFilter: object | null;
  isFetching: boolean;
  errors: string[];
  items: IDataItem[];
  schema: IJSONSchema | null;
  totalItems: number;
  isODataSupportedByEndpoint: boolean;
  cancelLoading: (cancelToken?: CancelTokenSource | null) => void;
  forceSetItems: (items: IDataItem[]) => void;
  forceSetTotalItems: Dispatch<SetStateAction<number>>;
  latestFetchingTimestamp: MutableRefObject<number>;
  cancelAllActiveRequests: () => void;
} => {
  const [parsedODataFilter, setParsedODataFilter] = useState<ODataPropsFilter | null>(null);
  const [parsedMongoFilter, setParsedMongoFilter] = useState<object | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const dataRef = useRef<IDataItem[]>([]);
  const [data, setData] = useState<IDataItem[]>([]);
  const [schema, setSchema] = useState<IJSONSchema | null>(null);
  const [totalItems, setTotalEntities] = useState<number>(0);
  const latestFetchingTimestamp = useRef(Date.now());

  const onDataLoaded = (response: IEntitiesResponse, reset: boolean = false): void => {
    if (reset) {
      dataRef.current = [...(response.entities as IDataItem[])];
    } else {
      dataRef.current = [...dataRef.current, ...(response.entities as IDataItem[])];
    }
    setData(dataRef.current);
    setSchema(response.schema as IJSONSchema);

    if (response.totalItems === undefined) {
      // In case of no totalItems we fetch items until empty response
      setTotalEntities(response.entities.length ? dataRef.current.length + 1 : dataRef.current.length);
    } else {
      setTotalEntities(response.totalItems);
    }

    setErrors([]);
  };

  const isODataSupportedByEndpoint: boolean = useSelector(
    (state: IGlobalState) => !!dataEndpointIdentifier && state.app.dataEndpoints.get(dataEndpointIdentifier)?.dataType === BaseApi.DataService
  );

  const onDataError = useCallback(
    (e: Error & { err?: string }) => {
      setErrors([`Error: ${e.message ?? e.err}`]);
      console.error(`Response error.`, e as Error);
    },
    [setErrors]
  );

  const getEntitiesFilterOptions = useCallback(
    async (
      url: URL,
      dataUrlDetails: IDataUrlDetails,
      odataOptions: IODataProps | undefined,
      detachedRequest: boolean = false, // Doesn't affect state
      forceParentFilters: boolean = false // Apply parent filtering
    ): Promise<Partial<QueryOptions<unknown>>> => {
      // Data query
      const mergedSearchParams = new URLSearchParams(url.search);

      // External query
      if (externalDataQuery) {
        Object.entries(externalDataQuery).forEach(([key, value]) => mergedSearchParams.set(key, value));
      }

      // Parse OData filter from (Data query + External query)
      const odataFilterFromDataQuery = mergedSearchParams.get('$filter');
      let parsedODataFilterFromDataQuery;
      let replacedOdataFilter;
      if (odataFilterFromDataQuery) {
        replacedOdataFilter = replaceDataUrlPlaceholders(odataFilterFromDataQuery);

        const mongoFilter = createFilter(replacedOdataFilter);
        parsedODataFilterFromDataQuery = _.merge({}, formatEntries(mongoFilter) as ODataPropsFilter);
        if (!_.isEqual(parsedODataFilter, parsedODataFilterFromDataQuery) && !detachedRequest) {
          setParsedODataFilter(parsedODataFilterFromDataQuery);
          setParsedMongoFilter(mongoFilter);
        }
      } else if (parsedODataFilter || parsedMongoFilter) {
        setParsedODataFilter(null);
        setParsedMongoFilter(null);
      }

      // Remove reserved OData keys from mergedSearchParams
      reservedODataQueryKeys.forEach((key) => mergedSearchParams.delete(key));

      // Other query params, not related for filtering or OData
      const otherQueryParams = Object.fromEntries(mergedSearchParams.entries());

      // Query options for request
      const requestQueryOptions: Parameters<typeof buildQuery>[0] = {
        /* TEMP: ...(isODataSupportedByEndpoint ? odataOptions : {}),*/ ...odataOptions,
        ...otherQueryParams,
      };

      /* TEMP:
      if (!isODataSupportedByEndpoint) {
        // Return in case of Api Gateway endpoint
        return requestQueryOptions;
      }
      */

      // Continue in case of Data Service endpoint

      const filterOptionsFromBrowserLocation = await getExternalODataFilter?.();
      const composedRequestFilter = [
        odataOptions?.filter, // Function parameter
        replacedOdataFilter && encodeURIComponent(replacedOdataFilter), // Filter from (Data url query + External query)
        externalODataFilter, // Additional OData filter part
        isODataSupportedByEndpoint ? filterOptionsFromBrowserLocation : undefined, // From browser url
      ].filter(isDefinedAndNotEmpty) as Filter[];

      if (isODataSupportedByEndpoint) {
        // cp_parentPropertyJsonPath handling for not detached requests
        if (!detachedRequest || forceParentFilters) {
          const fetchedSchema = schemaUrl ? await getSchema(schemaUrl) : undefined;

          // Remove top/skip if lazy loading is disabled
          if (fetchedSchema?.cp_disableLazyLoading) {
            delete requestQueryOptions.top;
            delete requestQueryOptions.skip;
          }

          if (fetchedSchema?.cp_parentPropertyJsonPath && !dataUrlDetails.disableParenting) {
            // Get only root items if there is no user filter
            // Check filter deep because it can contain empty objects
            const ignoredODataPaths = new Set(
              fetchedSchema.cp_parentIsNullFilterPropertyJsonPaths?.split(',').map((jsonPath) => jsonPath.replace(/\./gi, '/'))
            );

            const notFilteredByFilterField = checkIsFilterEmpty(odataOptions?.filter, ignoredODataPaths);
            const notFilteredByQuery = checkIsFilterEmpty(filterOptionsFromBrowserLocation, ignoredODataPaths);

            const notFilteredByExternalODataFilter = !externalODataFilter || _.every(externalODataFilter, (p) => _.isEmpty(p));
            const notFilteredByExternalDataQueryFilter = !externalDataQuery?.$filter;

            const parentPropertyOdataPath = fetchedSchema.cp_parentPropertyJsonPath.replace(/\./gi, '/');
            const hasParentPropertyInDataQuery = Array.from(objectToFlattenMap(parsedODataFilterFromDataQuery || {}).keys()).some((path) =>
              _.toPath(path).includes(parentPropertyOdataPath!)
            );

            if (
              notFilteredByFilterField &&
              notFilteredByQuery &&
              notFilteredByExternalODataFilter &&
              notFilteredByExternalDataQueryFilter &&
              !hasParentPropertyInDataQuery
            ) {
              const entries = formatEntries({ [fetchedSchema.cp_parentPropertyJsonPath]: null }) as object;
              const isAlreadyFilteredByParentProperty =
                !!parsedODataFilterFromDataQuery && Object.keys(parsedODataFilterFromDataQuery).some((k) => Object.keys(entries).includes(k));
              if (!isAlreadyFilteredByParentProperty) {
                composedRequestFilter.push(entries);
              }
            }
          }
        }

        // Adjust requestQueryOptions by adding orderBy and filter
        requestQueryOptions.orderBy =
          groupPropertyJsonPath && !odataOptions?.orderBy?.some(([propertyJsonPath]) => propertyJsonPath === groupPropertyJsonPath)
            ? [[groupPropertyJsonPath, 'asc'], ...(odataOptions?.orderBy ?? [])]
            : odataOptions?.orderBy;

        // Replace dots by slashes in orderBy keys
        if (Array.isArray(requestQueryOptions.orderBy)) {
          requestQueryOptions.orderBy = requestQueryOptions.orderBy.filter(Boolean).map(([key, direction]) => [key.replace(/\./gi, '/'), direction]);
        }
      }

      if (composedRequestFilter.length > 0) {
        requestQueryOptions.filter = composedRequestFilter.length === 1 ? composedRequestFilter[0] : { and: composedRequestFilter };
      }
      return requestQueryOptions;
    },
    [
      externalDataQuery,
      parsedODataFilter,
      parsedMongoFilter,
      getExternalODataFilter,
      externalODataFilter,
      isODataSupportedByEndpoint,
      groupPropertyJsonPath,
      schemaUrl,
    ]
  );

  const activeCancelTokens = useRef<Set<CancelTokenSource>>(new Set());
  const loadItems: LoadItemsFunction = useCallback<LoadItemsFunction>(
    async (
      odataOptions?: IODataProps,
      {
        resetExistingData = false,
        overwriteExistingData = false,
        detachedRequest = false,
        forceParentFilters = false,
        disableTriggers = false,
        cancelToken = createCancelToken(),
        silentFetching = false,
        interceptors: localInterceptors = {},
        throwOnCancel = false,
      } = {}
    ): Promise<{ items: IDataItem[]; responses: IEntitiesResponse[] }> => {
      const dataUrlDetails: IDataUrlDetails | null = typeof dataUrl === 'function' ? await dataUrl() : { url: dataUrl };
      if (!dataUrlDetails) {
        return { items: [], responses: [] };
      }

      activeCancelTokens.current.add(cancelToken);

      const url = new URL(dataUrlDetails.url, window.location.origin);
      const requestOptions = await getEntitiesFilterOptions(url, dataUrlDetails, odataOptions, detachedRequest, forceParentFilters);

      if (resetExistingData) {
        dataRef.current = [];
        setData(dataRef.current);
        setSchema(null);
      }

      let getEntitiesArgs: Parameters<typeof getEntitiesFromEndpoint> = [
        dataEndpointIdentifier,
        url.pathname,
        requestOptions,
        disableTriggers,
        cancelToken,
        undefined,
        undefined,
        throwOnCancel,
      ];

      if (localInterceptors?.beforeRequest) {
        getEntitiesArgs = await localInterceptors.beforeRequest(...getEntitiesArgs);
      } else if (interceptors?.beforeRequest) {
        getEntitiesArgs = await interceptors.beforeRequest(...getEntitiesArgs);
      }

      // We overwrite loaded data as soon as we get first response
      let overwrittenExistingData = false;

      const requestPromises = getEntitiesFromEndpoint(...getEntitiesArgs).map((requestPromise) =>
        requestPromise
          .then((response) => {
            if (localInterceptors?.afterRequest) {
              return localInterceptors.afterRequest(response, detachedRequest);
            } else if (interceptors?.afterRequest) {
              return interceptors.afterRequest(response, detachedRequest);
            }
            return response;
          })
          .then((response) => {
            if (!detachedRequest) {
              onDataLoaded(response, overwriteExistingData && !overwrittenExistingData);
              overwrittenExistingData = true;
            }
            return response;
          })
          .catch((error) => {
            if (throwOnCancel && isCancelError(error)) {
              throw error;
            }
            onDataError(error);
            return { entities: [], totalItems: 0, schema: null };
          })
          .finally(() => {
            activeCancelTokens.current.delete(cancelToken);
          })
      );

      if (!detachedRequest) {
        latestFetchingTimestamp.current = Date.now();
      }

      if (!detachedRequest && !silentFetching) {
        setIsFetching(true);
      }

      return Promise.all(requestPromises)
        .then((responses: IEntitiesResponse[]) => ({
          items: responses.flatMap((response) => response.entities as IDataItem[]),
          responses: responses,
        }))
        .finally(() => {
          if (!detachedRequest) {
            setIsFetching(false);
          }
        });
    },
    [dataUrl, getEntitiesFilterOptions, dataEndpointIdentifier, interceptors, onDataError]
  );

  const cancelAllActiveRequests = useCallback(() => {
    activeCancelTokens.current.forEach((token) => token.cancel());
  }, []);

  useEffect(() => {
    return (): void => {
      // Cancel all active requests
      cancelAllActiveRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelLoading = useCallback((cancelToken?: CancelTokenSource | null) => {
    cancelToken?.cancel();
    setIsFetching(false);
  }, []);

  const forceSetItems = useCallback((items: IDataItem[]) => {
    dataRef.current = items;
    setData(dataRef.current);
  }, []);

  return {
    loadItems,
    parsedODataFilter,
    parsedMongoFilter,
    isFetching,
    errors,
    items: data,
    schema,
    totalItems,
    isODataSupportedByEndpoint,
    cancelLoading,
    forceSetItems,
    forceSetTotalItems: setTotalEntities,
    latestFetchingTimestamp,
    cancelAllActiveRequests,
  };
};

/*
Can be used if we call loadItems in component different to component where it was produced
Like in TableRow, it is produced in GenericScreen, but used inside TableRow

Example:
  const cancellableLoadEntities = useCancellableLoadEntities(rawLoadEntities);
  cancellableLoadEntities({ top: 10 });

 */
export const useCancellableLoadEntities = (loadItems: LoadItemsFunction | undefined): LoadItemsFunction | undefined => {
  const activeCancelTokens = useRef<Set<CancelTokenSource>>(new Set());

  useEffect(() => {
    return (): void => {
      // Cancel all active requests related to this hook
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeCancelTokens.current.forEach((token) => token.cancel());
    };
  }, []);

  return useMemo(() => {
    if (loadItems) {
      return (odataOptions, requestOptions): ReturnType<LoadItemsFunction> => {
        const patchedRequestOptions = requestOptions || {};

        if (!patchedRequestOptions.cancelToken) {
          patchedRequestOptions.cancelToken = createCancelToken();
        }

        const cancelToken = patchedRequestOptions.cancelToken;
        activeCancelTokens.current.add(cancelToken);

        return loadItems(odataOptions, patchedRequestOptions).finally(() => {
          activeCancelTokens.current.delete(cancelToken);
        });
      };
    }

    return undefined;
  }, [loadItems]);
};
