import { createFilter } from '@cp/base-odata';
import { Schemas } from '@cp/base-types';
import { createQueryFilter, getODataFilter, ODataPropsFilter, parseFilterMessage } from '@cp/base-utils';
import {
  axiosDictionary,
  cloneEntityToEndpoint,
  deleteEntityFromEndpoint,
  postEntityToEndpoint,
  putEntityToEndpoint,
  replaceDataUrlPlaceholders,
} from '@cpa/base-core/api';
import { EndpointContext, filterQueryKeyName, fullPageSize, GenericScreenContext, widgetPageSize } from '@cpa/base-core/constants';
import { QueryFilterManager } from '@cpa/base-core/helpers';
import {
  useDebouncedValue,
  useItemIdParam,
  useLiveUpdates,
  useLoadableData,
  useQuery,
  useRelatedLiveUpdates,
  useSyncedPageSettings,
} from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import {
  IDataItem,
  IGenericComponentData,
  IPageSetting,
  IRelatedViewProps,
  IScreenProps,
  IScrollableContent,
  OrderDirection,
} from '@cpa/base-core/types';
import { MessageBarType } from '@fluentui/react';
import classNames from 'classnames';
import * as _ from 'lodash';
import { Document, Filter } from 'mongodb';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import GenericComponent from '../../components/GenericComponent/GenericComponent';
import Kpi from '../../components/Kpi/Kpi';
import MessageBars from '../../components/MessageBars/MessageBars';
import CallToAction from '../RebrushedDashboard/components/CallToAction/CallToAction';
import KpiView from '../RebrushedDashboard/components/KpiView/KpiView';
import StaticContent from '../StaticContent/StaticContent';

import SingleItem, { generateSingleItemQuery } from './components/SingleItem/SingleItem';
import styles from './GenericScreen.module.scss';
import { getParsedFilter, getSchemaUIOptions } from './utils';

export interface IGenericScreenProps extends IScreenProps, IRelatedViewProps {}

const GenericScreen: React.FC<IGenericScreenProps> = (props) => {
  const {
    page,
    location: currentLocation,
    externalPrefill,
    externalODataFilter,
    isWidget = false,
    withoutAnimation,
    animationDelay,
    resetFilterOnRefresh,
    initialFilterValue,
    genericComponentProps,
    externalInterceptors,
    showEmptyMessage,
  } = props;
  const pageSettings: IPageSetting | undefined = useSelector((state: IGlobalState) => state.settings.pages[page.identifier!]);
  const dark = useSelector((state: IGlobalState) => state.settings.darkMode);
  const latestUserFilter = useRef<ODataPropsFilter | undefined>(undefined);

  const tableRef = useRef<IScrollableContent>(null);

  const updatePageSetting = useSyncedPageSettings(false, true);

  // Browser location handling
  const browserQuery = useQuery();
  const queryFilterManager = useMemo(() => new QueryFilterManager(), []);
  const identifierFromBrowserQueryFilter = useMemo(() => {
    const parsedIdentifier = queryFilterManager.parseQuery(browserQuery[filterQueryKeyName]).identifier;
    if (typeof parsedIdentifier !== 'string' || !parsedIdentifier) {
      return undefined;
    }
    return parsedIdentifier;
  }, [browserQuery, queryFilterManager]);
  const browserItemIdParameter = useItemIdParam();
  const showSingleItem = useMemo(
    () =>
      ((identifierFromBrowserQueryFilter && browserQuery.action && browserQuery.action.toUpperCase() === 'VIEW') || browserItemIdParameter) &&
      page.singleItemTemplate,
    [browserItemIdParameter, browserQuery.action, identifierFromBrowserQueryFilter, page.singleItemTemplate]
  );
  const lastUsedOptionsFromBrowserQuery = useRef<string | null>(null);
  const getFilterOptionsFromBrowserLocation = useCallback((): ODataPropsFilter => {
    if (isWidget) {
      return {};
    }

    // Options from browser location
    let optionsFromBrowserLocation = {};

    // Browser query param /?filter=JSON
    if (browserQuery[filterQueryKeyName]) {
      try {
        _.merge(optionsFromBrowserLocation, getODataFilter(parseFilterMessage(createQueryFilter(browserQuery[filterQueryKeyName]))));
      } catch {}
    }

    // Browser parameter /:itemId
    if (browserItemIdParameter) {
      const encodedItemId = encodeURIComponent(browserItemIdParameter);
      _.merge(optionsFromBrowserLocation, {
        or: [{ identifier: encodedItemId }, { urlSlug: encodedItemId }],
      });
    }

    // Allow using browser location filter only once per unique query
    const serializedOptionsFromBrowserQuery = JSON.stringify(optionsFromBrowserLocation);
    if (lastUsedOptionsFromBrowserQuery.current === serializedOptionsFromBrowserQuery && !showSingleItem) {
      optionsFromBrowserLocation = {};
    } else {
      lastUsedOptionsFromBrowserQuery.current = serializedOptionsFromBrowserQuery;
    }

    return optionsFromBrowserLocation;
  }, [showSingleItem, browserItemIdParameter, browserQuery, isWidget]);

  const [dataUpdate, setDataUpdate] = useState<number>(Date.now());

  const dataUrlMongoFilter = useMemo<Filter<Document> | undefined>(() => {
    if (!page.dataUrl) {
      return undefined;
    }

    const url = new URL(page.dataUrl, window.location.origin);
    const mergedSearchParams = new URLSearchParams(url.search);
    const externalDataQuery = currentLocation?.state?.externalDataQuery || props.externalDataQuery;
    // External query
    if (externalDataQuery) {
      Object.entries(externalDataQuery).forEach(([key, value]) => mergedSearchParams.set(key, value));
    }

    const odataFilterFromDataQuery = mergedSearchParams.get('$filter');

    const replacedOdataFilter = odataFilterFromDataQuery ? replaceDataUrlPlaceholders(odataFilterFromDataQuery) : null;

    if (replacedOdataFilter) {
      return createFilter(replacedOdataFilter);
    } else {
      return undefined;
    }
  }, [currentLocation?.state?.externalDataQuery, page.dataUrl, props.externalDataQuery]);

  const {
    loadItems,
    isODataSupportedByEndpoint,
    parsedODataFilter,
    schema: loadedSchema,
    isFetching,
    items,
    totalItems,
    errors,
    cancelLoading,
    cancelAllActiveRequests,
    forceSetItems,
    forceSetTotalItems,
  } = useLoadableData(
    page?.dynamicDataUrl ? page.dynamicDataUrl : page?.dataUrl || '/',
    page.dataEndpoint?.identifier || axiosDictionary.appDataService,
    page.groupPropertyJsonPath,
    page.schemaUrl as string | undefined,
    currentLocation?.state?.externalDataQuery || props.externalDataQuery,
    externalODataFilter,
    getFilterOptionsFromBrowserLocation,
    externalInterceptors
  );
  const schema = props.externalSchema || loadedSchema;
  const uiOptions = useMemo(() => getSchemaUIOptions(schema), [schema]);

  const [parsedFilter] = useMemo<[IDataItem, string[]]>(
    () => (externalPrefill ? [externalPrefill, []] : (getParsedFilter(parsedODataFilter, schema, true) as [IDataItem, string[]])),
    [externalPrefill, parsedODataFilter, schema]
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [debouncedWindowHeight] = useDebouncedValue(windowHeight, 100);

  const getWindowHeight = useCallback(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getWindowHeight);

    return () => {
      window.removeEventListener('resize', getWindowHeight);
    };
  }, [getWindowHeight]);

  // Page size calculation
  const fitSize = useMemo(() => {
    // Default raw height in table
    const rowHeight = 42;
    const footerHeight = document.body.getElementsByTagName('footer')[0]?.clientHeight || 0;
    // Doubled amount of rows to fit screen height
    return Math.floor((debouncedWindowHeight - Math.max(footerHeight, 350)) / rowHeight) * 2;
  }, [debouncedWindowHeight]);
  const pageSize = useMemo(() => (isWidget ? widgetPageSize : Math.max(fitSize, fullPageSize)), [isWidget, fitSize]);

  // Initial items load
  useEffect(() => {
    // Cancel all requests before loading initial data
    cancelAllActiveRequests();

    const singleItemIdentifier = browserItemIdParameter || identifierFromBrowserQueryFilter;
    if (showSingleItem && isODataSupportedByEndpoint && singleItemIdentifier) {
      loadItems(undefined, {
        resetExistingData: true,
        interceptors: {
          beforeRequest: (endpointId, path, queryOptions, ...other) => {
            // Ignore filter from page level. We always fetch item by identifier.
            return [
              endpointId,
              path,
              {
                ..._.pick(queryOptions, '$expand'),
                ...generateSingleItemQuery(singleItemIdentifier),
              },
              ...other,
            ];
          },
        },
      });
    } else {
      loadItems(
        isODataSupportedByEndpoint
          ? {
              top: pageSize,
              orderBy: page.initialOrderPropertyJsonPath
                ? [[page.initialOrderPropertyJsonPath, page.initialOrderDirection === OrderDirection.DESCENDING ? 'desc' : 'asc']]
                : undefined,
              filter: latestUserFilter.current,
            }
          : {},
        { resetExistingData: true }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isODataSupportedByEndpoint, currentLocation?.state?.externalDataQuery, externalODataFilter]);

  // Refresh handling
  const onPageRefresh = useCallback(
    (refreshFilter?: ODataPropsFilter) => {
      setDataUpdate(Date.now());
      cancelAllActiveRequests();
      return loadItems(
        isODataSupportedByEndpoint
          ? {
              top: pageSize,
              filter: refreshFilter,
              orderBy: page.initialOrderPropertyJsonPath
                ? [[page.initialOrderPropertyJsonPath, page.initialOrderDirection === OrderDirection.DESCENDING ? 'desc' : 'asc']]
                : undefined,
            }
          : {},
        { resetExistingData: true }
      );
    },
    [cancelAllActiveRequests, loadItems, isODataSupportedByEndpoint, pageSize, page.initialOrderPropertyJsonPath, page.initialOrderDirection]
  );

  /*
    Operations
   */
  const onAddRow = useCallback(
    async (tableItem: IDataItem) => {
      if (!page.dataUrl || !schema) {
        return;
      }

      const addedEntity = await postEntityToEndpoint(
        page.dataEndpoint?.identifier || axiosDictionary.appDataService,
        page.dataUrl,
        { ...tableItem },
        schema
      );
      setDataUpdate(Date.now());
      return addedEntity;
    },
    [page, schema]
  );

  const onEditRow = useCallback(
    async (editedItem: IDataItem, initialItem: IDataItem) => {
      if (!page.dataUrl || !schema) {
        return;
      }

      const updatedItem = await putEntityToEndpoint(
        page.dataEndpoint?.identifier || axiosDictionary.appDataService,
        page.dataUrl,
        initialItem,
        { ...editedItem },
        schema
      );
      setDataUpdate(Date.now());

      if (showSingleItem) {
        forceSetItems(items.map((item) => (item.identifier === updatedItem.identifier ? updatedItem : item)));
      }

      return updatedItem;
    },
    [page, schema, showSingleItem, forceSetItems, items]
  );

  const onDeleteRows = useCallback(
    async (items: IDataItem[]) => {
      if (!page.dataUrl || !schema) {
        return;
      }

      await deleteEntityFromEndpoint(page.dataEndpoint?.identifier || axiosDictionary.appDataService, page.dataUrl, items, schema);
      setDataUpdate(Date.now());
    },
    [page, schema]
  );

  const onCopyRow = useCallback(
    async (item: IDataItem): Promise<IDataItem | undefined> => {
      if (!page.dataUrl || !schema) {
        return;
      }

      const clonedEntity = await cloneEntityToEndpoint(page.dataEndpoint?.identifier || axiosDictionary.appDataService, page.dataUrl, item, schema);

      setDataUpdate(Date.now());
      return clonedEntity;
    },
    [page, schema]
  );

  // Header with static content
  const staticContentHeader = useMemo(
    () => (page.staticContent ? <StaticContent page={page} data={{} as IGenericComponentData} pageSize={pageSize} /> : null),
    [page, pageSize]
  );

  const pageKpis = useMemo(() => {
    if (!page.kpis) {
      return [];
    }
    return page.kpis.filter((kpi) => kpi.showOnPage);
  }, [page.kpis]);

  // Header with tiles
  const tilesHeader = useMemo(() => {
    if ((!pageKpis.length && !page.callToActions?.length) || isWidget) {
      return null;
    }
    return (
      <div className={classNames({ [styles.kpis]: !dark, [styles.kpisDark]: dark })}>
        {pageKpis.map((kpi) => {
          return (
            <Kpi
              key={kpi.identifier}
              kpi={kpi}
              page={page}
              items={isFetching ? undefined : items}
              clickable={false}
              lastUpdate={dataUpdate}
              component={KpiView}
            />
          );
        })}
        {!!page.callToActions &&
          page.callToActions.map((callToAction, index) => {
            return <CallToAction key={index} callToAction={callToAction} />;
          })}
      </div>
    );
  }, [pageKpis, page, isWidget, dark, isFetching, items, dataUpdate]);

  const forcedCardViewTemplate = useMemo(() => browserQuery?.['cardViewTemplate'] || undefined, [browserQuery]);

  // Force card view
  // e.g. ?cardViewTemplate=ImageCard
  useEffect(() => {
    if (!forcedCardViewTemplate || isWidget || !pageSettings || pageSettings.displayAsCards) {
      return;
    }

    updatePageSetting({ pageKey: page.identifier, setting: 'displayAsCards', value: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentData = useMemo<IGenericComponentData>((): IGenericComponentData => {
    return {
      items: items,
      page: forcedCardViewTemplate
        ? ({
            ...page,
            customCardTemplate: {
              identifier: forcedCardViewTemplate,
            },
          } as Schemas.CpaPage)
        : page,
      schema,
      isFetching,
      totalItems,
    };
  }, [isFetching, items, page, schema, totalItems, forcedCardViewTemplate]);

  useLiveUpdates(
    loadItems,
    items,
    (items) => {
      externalInterceptors?.afterRequest?.({ entities: items, schema, totalItems }, false);
      forceSetItems(items);
    },
    forceSetTotalItems,
    totalItems,
    page,
    (item, operation) => {
      if (operation !== 'CREATE') return true;
      return !showSingleItem;
    },
    false,
    () => {
      setDataUpdate(Date.now());
    },
    latestUserFilter
  );

  useRelatedLiveUpdates(
    schema,
    loadItems,
    items,
    (items) => {
      externalInterceptors?.afterRequest?.({ entities: items, schema, totalItems }, false);
      forceSetItems(items);
    },
    forceSetTotalItems,
    totalItems,
    (item, operation) => {
      if (operation !== 'CREATE') return true;
      return !showSingleItem;
    },
    false,
    () => {
      setDataUpdate(Date.now());
    },
    latestUserFilter
  );

  if (showSingleItem) {
    const path = browserItemIdParameter
      ? currentLocation?.pathname.slice(0, currentLocation.pathname.indexOf(encodeURIComponent(browserItemIdParameter)))
      : currentLocation?.pathname;

    return (
      <EndpointContext.Provider value={page.dataEndpoint?.identifier || null}>
        <SingleItem
          onRefresh={onPageRefresh}
          data={componentData}
          path={path || '/'}
          identifier={(browserItemIdParameter || identifierFromBrowserQueryFilter) as string}
          onEditRow={onEditRow}
          onCopyRow={onCopyRow}
          onDeleteRows={onDeleteRows}
          loadItems={loadItems}
          cancelLoading={cancelLoading}
          isODataSupportedByEndpoint={isODataSupportedByEndpoint}
          initialAction={browserQuery.action}
          customActions={genericComponentProps?.scrollingContentProps?.customActions}
        />
      </EndpointContext.Provider>
    );
  }

  return (
    <GenericScreenContext.Provider value={{ lastUpdate: dataUpdate, latestUserFilter: latestUserFilter }}>
      <div
        data-testid="GenericScreen"
        className={classNames({
          [styles.hidden]: !componentData.isFetching && componentData.items.length === 0 && !page.allowCreate && isWidget && !showEmptyMessage,
        })}
      >
        <MessageBars messageBarType={MessageBarType.error} isMultiline={false} messages={errors} />
        {staticContentHeader}
        {tilesHeader}
        <section className={isWidget ? undefined : styles.wrapper}>
          <GenericComponent
            data={componentData}
            animationDelay={animationDelay}
            onRefresh={onPageRefresh}
            resetFilterOnRefresh={resetFilterOnRefresh}
            isWidget={isWidget}
            onAddRow={onAddRow}
            onEditRow={onEditRow}
            onDeleteRows={onDeleteRows}
            onCopyRow={onCopyRow}
            isODataSupportedByEndpoint={isODataSupportedByEndpoint}
            loadItems={loadItems}
            pageSize={pageSize}
            tableRef={tableRef}
            dataUrlMongoFilter={dataUrlMongoFilter}
            hiddenInTable={uiOptions.hiddenInTable}
            preFillItems={parsedFilter}
            initialAction={browserQuery.action}
            initialFilterValue={initialFilterValue}
            externalODataFilter={externalODataFilter}
            withoutAnimation={withoutAnimation}
            {...(genericComponentProps || {})}
          />
        </section>
      </div>
    </GenericScreenContext.Provider>
  );
};

export default React.memo(GenericScreen);
