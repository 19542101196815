import { EventEmitter } from 'events';

import { MessageBarType } from '@fluentui/react';
import React, { MutableRefObject } from 'react';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { Editor } from '@tinymce/tinymce-react';
import { ODataPropsFilter } from '@cp/base-utils';

import { IDataItem } from '../types';

export const EndpointContext = React.createContext<string | null>(null);

export const FormContext = React.createContext<{
  startAction: () => unknown;
  finishAction: () => unknown;
  registerEditor: (editor: Editor) => unknown;
  unregisterEditor: (editor: Editor) => unknown;
  onFieldLocalizationChanged: (_eTag: string, language: string, dataPath: string, updatedValue: IDataItem) => void;
  page?: Schemas.CpaPage;
  formEventEmitter: EventEmitter | null;
  currentFormData: React.MutableRefObject<IDataItem | undefined>;
  initialFormData?: IDataItem;
  isWizard?: boolean;
  wizardArrayMeta?: (number | undefined)[];
}>({
  startAction: () => {},
  finishAction: () => {},
  registerEditor: () => {},
  unregisterEditor: () => {},
  onFieldLocalizationChanged: () => {},
  page: undefined,
  formEventEmitter: null,
  currentFormData: { current: undefined },
  initialFormData: undefined,
  isWizard: false,
  wizardArrayMeta: undefined,
});

export const GenericScreenContext = React.createContext<{
  lastUpdate: number;
  latestUserFilter: MutableRefObject<ODataPropsFilter | undefined>;
}>({
  lastUpdate: Date.now(),
  latestUserFilter: { current: undefined },
});

export const ItemInfoContext = React.createContext<{
  showMessage: (text: string, type?: MessageBarType) => void;
  dismissMessage: (text: string) => void;
  type: 'add' | 'edit';
  baseLanguage: string | null;
  currentLanguage: string;
} | null>(null);

export const PathContext = React.createContext<string>('');

export const ParentMessageContext = React.createContext<string | undefined>('');
export const MessageStateContext = React.createContext<IDataItem | undefined>(undefined);

export const ArrayContext = React.createContext<{
  depth?: number;
}>({
  depth: undefined,
});
