import { chunkWidgets } from '@cpa/base-core/helpers';
import { ISingleItemTemplateProps } from '@cpa/base-core/types';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { Schemas } from '@cp/base-types';

import GenericScreen from '../../../screens/GenericScreen/GenericScreen';
import HtmlContent from '../../../components/HtmlContent/HtmlContent';
import Widget from '../../../components/Widget/Widget';
import { ScreenTypesConfiguration } from '../../../mapping';

import styles from './ProfessionSingleItemTemplate.module.scss';

const ProfessionSingleItemTemplate: React.FC<ISingleItemTemplateProps> = ({ schema, item, subPages, subPageFilter, subPagePrefill }) => {
  const { push } = useHistory();

  const [t] = useTranslation();

  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });
  const widgetsChunked: Schemas.CpaPage[][] = useMemo(() => chunkWidgets(subPages, desktopOnly ? 2 : 1, true), [desktopOnly, subPages]);

  // Create prefill object in case of applying to job
  const applyToPostingPrefill: string = JSON.stringify({
    jobPosting: {
      identifier: item.identifier,
    },
  });

  // Create click handler for 'Apply now' button
  const onClickApplyNow = useCallback(() => {
    push({
      pathname: `/career/applications`,
      search: qs.stringify({
        action: 'add',
        preset: applyToPostingPrefill,
      }),
    });
  }, [push, applyToPostingPrefill]);

  return (
    <section className={styles.wrapper}>
      <div className={classNames(styles.item, styles.info)}>
        <div>
          <Widget title={''} hideSettings={true}>
            <div className={styles.contentContainer}>
              <div className={styles.applyButtonContainer}>
                <PrimaryButton className={styles.applyButton} onClick={onClickApplyNow}>
                  {t('common.applyNow')}
                </PrimaryButton>
              </div>
              <HtmlContent className={styles.jobDescription} html={item.description as string} />
              <div className={styles.applyButtonContainer}>
                <PrimaryButton className={styles.applyButton} onClick={onClickApplyNow}>
                  {t('common.applyNow')}
                </PrimaryButton>
              </div>
            </div>
          </Widget>
        </div>
      </div>

      {widgetsChunked.map((pagesPart: Schemas.CpaPage[], i: number) => {
        return (
          <div
            key={i}
            className={classNames(styles.row)}
            style={{ gridTemplateColumns: desktopOnly ? `repeat(${pagesPart.length}, ${100 / pagesPart.length}%)` : '100%' }}
          >
            {pagesPart.map((p: Schemas.CpaPage) => {
              if (p.customTemplate?.identifier) {
                const _Component = ScreenTypesConfiguration[p.customTemplate.identifier];
                return (
                  <div key={p.identifier} className={classNames(styles.item)}>
                    <_Component
                      item={item}
                      itemSchema={schema}
                      page={p}
                      externalODataFilter={subPageFilter}
                      isWidget={true}
                      externalPrefill={subPagePrefill}
                    />
                  </div>
                );
              }
              return (
                <div key={p?.identifier} className={classNames(styles.item)}>
                  <GenericScreen
                    item={item}
                    itemSchema={schema}
                    page={p}
                    externalODataFilter={subPageFilter}
                    isWidget={true}
                    externalPrefill={subPagePrefill}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </section>
  );
};

export default ProfessionSingleItemTemplate;
