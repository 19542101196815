import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { IJSONSchema } from '@cp/base-types';
import { cleanUpInternalProperties, isDefined } from '@cpa/base-core/helpers';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import { IDataItem } from '@cpa/base-core/types';
import { useBoolean } from '@fluentui/react-hooks';
import { isRelationSchema } from '@cp/base-utils';
import { FormatSource } from '@cpa/base-core/constants';

import ExpandButton from '../../../ExpandButton/ExpandButton';
import PropertyContent, { IPropertyContentProps } from '../PropertyContent/PropertyContent';
import styles from '../../ReadonlyContent.module.scss';

const ObjectContent: React.FC<IPropertyContentProps> = ({
  value,
  schema,
  rootSchema,
  parentSchemas,
  options,
  level,
  itemKey,
  dataPath,
  onDownload,
  page,
  darkMode,
}) => {
  const itemToDisplay = useMemo(() => {
    if (!value || Array.isArray(value)) {
      return value;
    }

    const clonedItem = cloneDeepWithMetadata(value);
    cleanUpInternalProperties(clonedItem, schema);
    return clonedItem;
  }, [value, schema]);

  const [isExpanded, { toggle: toggleExpanded }] = useBoolean(
    options.highlightOptions && options.highlightOptions.properties.some((p) => p.key.startsWith(itemKey ? itemKey + '.' : ''))
      ? true
      : options.expandFunctionalityOptions
      ? isDefined(options.expandFunctionalityOptions?.expandedLevel)
        ? (options.expandFunctionalityOptions?.expandedLevel as number) >= (level || 1)
        : !!options.expandFunctionalityOptions?.defaultExpanded
      : true
  );

  const onExpandIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      toggleExpanded();
    },
    [toggleExpanded]
  );

  const sortCallback = useMemo(() => {
    type Properties = Record<string, IJSONSchema>;
    const uiOrder: string[] = schema?.cp_rjsfUiSchema?.['ui:order'];

    if (uiOrder) {
      return ([a]: [string, Properties], [b]: [string, Properties]): number => {
        return uiOrder.indexOf(a) - uiOrder.indexOf(b);
      };
    }
    return ([, aProps]: [string, Properties], [, bProps]: [string, Properties]): number => {
      const aSortOrder = aProps?.cp_ui?.sortOrderForm || Infinity;
      const bSortOrder = bProps?.cp_ui?.sortOrderForm || Infinity;

      if (aSortOrder === bSortOrder) {
        if (aProps.__readonlyIndex && bProps.__readonlyIndex) {
          return +aProps.__readonlyIndex - +bProps.__readonlyIndex;
        } else {
          return 0;
        }
      }

      return aSortOrder < bSortOrder ? -1 : aSortOrder > bSortOrder ? 1 : 0;
    };
  }, [schema]);

  const appendedParentSchema = useMemo(() => {
    if (
      parentSchemas &&
      parentSchemas.length &&
      parentSchemas[parentSchemas.length - 1] &&
      _.isEqual(parentSchemas[parentSchemas.length - 1], schema)
    ) {
      return parentSchemas;
    }

    return [...(parentSchemas || []), schema];
  }, [parentSchemas, schema]);

  const isRelatedSchema = useMemo(() => {
    return isRelationSchema(schema);
  }, [schema]);

  if (isRelatedSchema) {
    const [key, subSchema] = Object.entries(schema.properties || {})[0];
    const valueToDisplay = (itemToDisplay as IDataItem)?.name || (itemToDisplay as IDataItem)?.[key];
    const relatedParentSchema = appendedParentSchema[appendedParentSchema.length - 2];
    const relatedObjectSchema = appendedParentSchema[appendedParentSchema.length - 1];

    const baseTitle = relatedObjectSchema.title;
    const anyOfTitle =
      relatedParentSchema?.anyOf && relatedObjectSchema?.properties?.identifier?.title
        ? ` (${relatedObjectSchema?.properties?.identifier?.title})`
        : '';

    const title = baseTitle + anyOfTitle;
    return (
      <PropertyContent
        onDownload={onDownload}
        key={`property-${key}-${itemKey}`}
        value={valueToDisplay as IDataItem}
        parentSchemas={appendedParentSchema}
        schema={{ ...subSchema, ...{ title } }}
        rootSchema={rootSchema}
        options={options}
        level={(level || 1) + 1}
        itemKey={itemKey + (itemKey === '' ? '' : '.') + key}
        dataPath={dataPath + (dataPath === '' ? '' : '.') + key}
        page={page}
        darkMode={darkMode}
      />
    );
  }

  return (
    <div className={styles.object}>
      <div className={styles.title}>
        {!!options.expandFunctionalityOptions && <ExpandButton isExpanded={isExpanded} onClick={onExpandIconClick} />}
        <div>{schema.title}</div>
      </div>
      <div
        className={classNames(styles.inner, {
          [styles.collapsed]: !!options.expandFunctionalityOptions && !isExpanded,
          [styles.gridView]: level === 1 && options.gridView,
          [styles.hoverCardInner]: options.source === FormatSource.HoverCard,
        })}
      >
        {Object.entries(schema.properties || {})
          .sort(sortCallback)
          .map(([key, subSchema]) => {
            if (options.onlySelectedFields && !options.onlySelectedFields.includes(key)) {
              return null;
            }

            if (Array.isArray(itemToDisplay)) {
              return null;
            }

            return (
              <PropertyContent
                onDownload={onDownload}
                key={`object-${key}-${itemKey}`}
                value={itemToDisplay?.[key] as IDataItem}
                parentSchemas={appendedParentSchema}
                schema={subSchema}
                rootSchema={rootSchema}
                options={options}
                level={(level || 1) + 1}
                itemKey={itemKey + (itemKey === '' ? '' : '.') + key}
                dataPath={dataPath + (dataPath === '' ? '' : '.') + key}
                page={page}
                darkMode={darkMode}
              />
            );
          })}
      </div>
    </div>
  );
};

export default React.memo(ObjectContent);
