import { ILanguage } from '../../types';

export const generateLanguageFallbackMap = (languages: ILanguage[]) => {
  const fallbackLanguages: { [key: string]: string[] } = {
    'default': [languages[0].identifier],
  };
  const reducedFallbackLanguages = languages.reduce((acc, language) => {
    const locale = language.identifier.split('-')[0];
    if (!acc[locale]) {
      acc[locale] = [language.identifier];
    } else {
      acc[locale].push(language.identifier);
    }
    return acc;
  }, fallbackLanguages);
  return reducedFallbackLanguages;
};
