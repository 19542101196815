import validator from 'validator';
export function generatePrefixMapFromCpNamespaces(cpNamespaces) {
    return cpNamespaces.reduce((acc, namespace) => (Object.assign(Object.assign({}, acc), { [namespace.prefix]: namespace.identifier })), {});
}
export function isRdfFullUri(test) {
    return (validator.isURL(test.trim(), {
        protocols: ['http', 'https'],
        require_protocol: true,
    }) || test.startsWith('cpVirtual://'));
}
export class PrefixNotMatchedError extends Error {
}
export function parseShortcutUri(uri, prefixMap) {
    const delimiterPosition = uri.indexOf(':');
    if (delimiterPosition >= 1 && delimiterPosition < uri.length - 1) {
        const prefix = uri.substring(0, delimiterPosition);
        if (prefix in prefixMap) {
            return prefixMap[prefix] + uri.substring(delimiterPosition + 1);
        }
        else {
            throw new PrefixNotMatchedError(`Prefix '${prefix}' from uri '${uri}' not found in prefix map`);
        }
    }
    throw new Error(`Unknown uri format - ${uri}`);
}
export function isInternalRdfReference(test, prefixMap) {
    if (isRdfFullUri(test)) {
        return Object.values(prefixMap).some((prefixedUrl) => test.startsWith(prefixedUrl));
    }
    try {
        parseShortcutUri(test, prefixMap);
        return true;
    }
    catch (e) {
        return false;
    }
}
export function resolveSubjectUri(uri, prefixMap) {
    if (isRdfFullUri(uri)) {
        return uri;
    }
    return parseShortcutUri(uri, prefixMap);
}
export function shortenSubjectUri(uri, prefixMap) {
    if (isRdfFullUri(uri)) {
        const matchedPrefix = Object.entries(prefixMap).reduce((match, prefix) => {
            const [, prefixUri] = prefix;
            if (!uri.startsWith(prefixUri)) {
                return match;
            }
            if (!match) {
                return prefix;
            }
            return prefix[1].length > match[1].length ? prefix : match;
        }, undefined);
        return matchedPrefix ? uri.replace(matchedPrefix[1], `${matchedPrefix[0]}:`) : uri;
    }
    return uri;
}
export function getSubjectUriOptions(uri, prefixMap) {
    let fullSubjectUri = uri;
    try {
        fullSubjectUri = resolveSubjectUri(fullSubjectUri, prefixMap);
    }
    catch (e) { }
    let shortSubjectUri = uri;
    try {
        shortSubjectUri = shortenSubjectUri(shortSubjectUri, prefixMap);
    }
    catch (e) { }
    if (fullSubjectUri === shortSubjectUri) {
        return [fullSubjectUri];
    }
    return [fullSubjectUri, shortSubjectUri];
}
