import { IGlobalState } from '@cpa/base-core/store';
import { Checkbox, DefaultButton, PrimaryButton, Stack, StackItem } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { IUserAgreement } from '@cpa/base-core/types';

import { UserAgreements } from '../../../../mapping';

import styles from './Cookie.module.scss';

interface ILink {
  title: string;
  link: string;
}

export interface ICookieProps {
  title: string;
  text: string;
  btnAcceptAllText: string;
  btnAcceptSelectedText: string;
  onAccept: (fields: Record<string, boolean>) => void;
  agreements?: IUserAgreement[];
  links?: ILink[];
  zIndex?: number;
}

const defaultIsChecked = (currentValue: boolean): boolean => currentValue;

const Cookie: React.FC<ICookieProps> = ({
  title,
  text,
  btnAcceptAllText,
  btnAcceptSelectedText,
  agreements = UserAgreements,
  onAccept,
  links = [],
  zIndex = 2147483647,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [t] = useTranslation();
  const settingsStorage = useSelector((state: IGlobalState) => state.settings as Record<string, boolean> & IGlobalState['settings']);
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);

  const [acceptedFields, setAcceptedFields] = useState<Record<string, boolean>>(() =>
    agreements.reduce(
      (acc, { fieldName, isChecked = defaultIsChecked }) => ({
        ...acc,
        [fieldName]: typeof isChecked === 'function' ? isChecked(!!settingsStorage[fieldName]) : isChecked,
      }),
      {}
    )
  );

  const onOptionChange = useCallback(
    (fieldName: string, value?: boolean) => {
      setAcceptedFields({ ...acceptedFields, [fieldName]: !!value });
    },
    [acceptedFields, setAcceptedFields]
  );

  const processedOnAcceptAll = useCallback(() => {
    const allFieldsAccepted = agreements.reduce((acc, option) => ({ ...acc, [option.fieldName]: true }), {});
    onAccept(allFieldsAccepted);
  }, [onAccept, agreements]);

  const processedOnAcceptSelected = useCallback(() => {
    onAccept(acceptedFields);
  }, [acceptedFields, onAccept]);

  if (!cpa?.configuration.googleAnalyticsTrackingId) return null;

  return (
    <div style={{ zIndex }} className={classNames(styles.cookie, { [styles.cookieDark]: darkMode })}>
      <h4>{title}</h4>
      <p>{text}</p>
      <Stack horizontal={true} wrap={true} tokens={{ childrenGap: 10 }}>
        <StackItem align="start" grow={true}>
          {agreements.length ? (
            <div className={styles.options}>
              {agreements.map((option) => (
                <div key={option.fieldName} className={`agreement_${option.fieldName}`}>
                  <Checkbox
                    label={t(option.title)}
                    onChange={(_e, checked): void => onOptionChange(option.fieldName, checked)}
                    checked={acceptedFields[option.fieldName]}
                    disabled={option.disabled}
                  />
                </div>
              ))}
            </div>
          ) : null}
          {links.length ? (
            <div className={styles.links}>
              {links.map((link) => (
                <div key={link.title}>
                  <a href={link.link} className={darkMode ? styles.linkDark : styles.link}>
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
          ) : null}
        </StackItem>
        <StackItem align="end">
          <Stack horizontal={true} horizontalAlign="end" verticalAlign="end" wrap={true} tokens={{ childrenGap: 10 }}>
            <PrimaryButton onClick={processedOnAcceptAll}>{btnAcceptAllText}</PrimaryButton>
            <DefaultButton onClick={processedOnAcceptSelected}>{btnAcceptSelectedText}</DefaultButton>
          </Stack>
        </StackItem>
      </Stack>
    </div>
  );
};

export default Cookie;
