import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconButton } from '@fluentui/react';
import { FacetFilterPropertyValue } from '@cp/base-utils';

import LoadingArea from '../../../../../LoadingArea/LoadingArea';
import EmptySpace from '../../../../../EmptySpace/EmptySpace';
import { IFacetFilterData } from '../../helpers/getFacetFilters';

import styles from './FacetFilters.module.scss';
import FacetFilterItem from './FacetFilterItem/FacetFilterItem';

interface IFacetFiltersProps {
  darkMode: boolean;
  isMobileDevice: boolean;

  isLoading: boolean;
  facetFilters: IFacetFilterData[];

  onPropertySelected: (propertyJsonPath: string, selectedValue: FacetFilterPropertyValue) => void;
  onShowMoreClick: (facetFilter: IFacetFilterData) => void;
  onShowUnselectedItems: (facetFilter: IFacetFilterData) => void;
  onClose: () => void;
}

const FacetFilters: React.FC<IFacetFiltersProps> = ({
  isLoading,
  facetFilters,
  onClose,
  darkMode,
  isMobileDevice,
  onPropertySelected,
  onShowMoreClick,
  onShowUnselectedItems,
}) => {
  const [t] = useTranslation();

  const filtersToShow = useMemo(() => facetFilters.filter((s) => !!s.items.length), [facetFilters]);

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerDark]: darkMode,
      })}
    >
      {isLoading && (
        <div>
          <LoadingArea />
        </div>
      )}
      {!isLoading && (
        <div
          className={classNames({
            [styles.body]: true,
            [styles.bodyMobile]: isMobileDevice,
          })}
        >
          {!!filtersToShow.length && (
            <div>
              {filtersToShow.map((facetFilter) => {
                return (
                  <FacetFilterItem
                    facetFilter={facetFilter}
                    key={facetFilter.key}
                    onToggleSelect={onPropertySelected}
                    onShowMoreClick={onShowMoreClick}
                    onShowUnselectedItems={onShowUnselectedItems}
                    darkMode={darkMode}
                  />
                );
              })}
            </div>
          )}

          {!filtersToShow.length && (
            <div>
              <EmptySpace darkMode={darkMode} iconName="PageListFilter" text={t('facetFilters.noFilters')} />
            </div>
          )}

          {!isMobileDevice && (
            <IconButton
              className={classNames({
                [styles.closeButton]: true,
                [styles.closeButtonDark]: darkMode,
              })}
              iconProps={{ iconName: 'Cancel' }}
              onClick={onClose}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(FacetFilters);
