import React, { useCallback, useMemo } from 'react';
import { Icon, Rating as RatingComponent, useTheme } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { useAboutValue } from '@cpa/base-core/hooks';
import { TypeConstants } from '@cp/base-types';
import { axiosDictionary, patchEntityToEndpoint, postEntityToEndpoint } from '@cpa/base-core/api';

import HoverTooltip from '../../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './Rating.module.scss';

interface IRatingProps {
  identifier: string;
  cpTypeUrl?: string;
  isRatingExists: boolean;
}

const Rating: React.FC<IRatingProps> = ({ identifier, cpTypeUrl, isRatingExists }) => {
  const email = useSelector((state: IGlobalState) => state.auth.user?.account.email);
  const theme = useTheme();

  const [rating] = useAboutValue(TypeConstants.Rating, identifier, 'rating', 'value', cpTypeUrl, {}, !isRatingExists);
  const [userRate, originalItem] = useAboutValue(
    TypeConstants.Rating,
    identifier,
    'rating-user-rate',
    'value',
    cpTypeUrl,
    { email: email },
    !isRatingExists
  );

  const onRatingChange = useCallback(
    async (event: React.FormEvent<HTMLElement>, rating?: number | undefined) => {
      if (!isRatingExists || rating === userRate) return;
      if (!originalItem) {
        // Post item
        await postEntityToEndpoint(axiosDictionary.appDataService, `data-store/${encodeURIComponent(TypeConstants.Rating)}`, {
          about: {
            _type: cpTypeUrl,
            identifier: identifier,
          },
          value: rating,
        });
      } else {
        await patchEntityToEndpoint(
          axiosDictionary.appDataService,
          `data-store/${encodeURIComponent(TypeConstants.Rating)}`,
          {
            identifier: originalItem.identifier,
            value: originalItem.value,
            _eTag: originalItem['_eTag'],
          },
          { value: rating, identifier: originalItem.identifier, _eTag: originalItem['_eTag'] }
        );
      }
    },
    [cpTypeUrl, identifier, isRatingExists, originalItem, userRate]
  );

  const content = useMemo(() => {
    return (
      <RatingComponent
        styles={{
          ratingStarFront: {
            color: theme.palette.themePrimary,
          },
        }}
        allowZeroStars
        defaultRating={userRate || 0}
        max={5}
        onChange={onRatingChange}
      />
    );
  }, [onRatingChange, theme.palette.themePrimary, userRate]);

  return (
    <HoverTooltip content={content}>
      <div className={styles.rating}>
        <Icon iconName={'favoritestar'} />
        <span className={styles.ratingLabel}>{rating ? (rating % 1 === 0 ? rating : rating.toFixed(2)) : 0}</span>
      </div>
    </HoverTooltip>
  );
};

export default Rating;
