import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DirectionalHint, Dropdown, IDropdownOption } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IDataItem } from '@cp/base-types';

import styles from './Picker.module.scss';

interface IPickerProps {
  title: string;
  items: IDataItem[];
  renderedItems: string[];
  onChange: (selectedRoles: string[]) => void;
  selectedItems: string[];
  disabled?: boolean;
  urlQueryKey?: string;
  localStorageKey?: string;
  className?: string;
  submitOnCheck?: boolean;
  renderCalloutOnTop?: boolean;
}

const Picker: React.FC<IPickerProps> = ({
  title,
  items,
  renderedItems,
  onChange,
  selectedItems,
  disabled = false,
  urlQueryKey = undefined,
  localStorageKey = undefined,
  className,
  submitOnCheck = false,
  renderCalloutOnTop = false,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [t] = useTranslation();
  const [selectedPickerItems, setSelectedPickerItems] = useState<string[]>(selectedItems);

  useEffect(() => {
    setSelectedPickerItems(selectedItems);
  }, [selectedItems]);

  const parseSelectedItems = useCallback(
    (items: IDataItem[]) => {
      if (items.length > 0) {
        // url parsing
        const url = new URL(window.location.href);
        const urlItems = urlQueryKey ? url.searchParams.get(urlQueryKey) : '';
        const preselectedItems = urlItems ? urlItems.split(',') : [];
        if (!preselectedItems.length) {
          // local storage parsing
          const tryItemsString = localStorageKey ? localStorage.getItem(localStorageKey) : '';
          const tryItems = tryItemsString ? tryItemsString.split(',') : [];
          preselectedItems.push(...tryItems);
        }
        const validItems = preselectedItems.filter((itemId) => renderedItems.includes(itemId) && items.find((item) => item.identifier === itemId));
        onChange(validItems);
      }
    },
    [onChange, renderedItems, localStorageKey, urlQueryKey]
  );

  useEffect(() => {
    parseSelectedItems(items);
  }, [items, parseSelectedItems]);

  const submitChanges = useCallback(
    (updatedItems?: string[]) => {
      const items = updatedItems || selectedPickerItems;
      if (urlQueryKey) {
        if (items.length > 0) {
          // update job role search param
          const url = new URL(window.location.href);
          url.searchParams.set(urlQueryKey, items.join(','));
          window.history.replaceState({}, '', url.toString());
        } else {
          // remove job role search param
          const url = new URL(window.location.href);
          url.searchParams.delete(urlQueryKey);
          window.history.replaceState({}, '', url.toString());
        }
      }
      onChange(items);
    },
    [urlQueryKey, onChange, selectedPickerItems]
  );

  const onCheck = useCallback(
    (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
      if (item) {
        const updatedItems = item.selected ? [...selectedPickerItems, item.key as string] : selectedPickerItems.filter((key) => key !== item.key);
        setSelectedPickerItems(updatedItems);
        if (localStorageKey) {
          const currentTryItemsString = localStorage.getItem(localStorageKey);
          const currentTryItems = currentTryItemsString ? currentTryItemsString.split(',') : [];
          if (item.selected) {
            currentTryItems.push(item.key as string);
            localStorage.setItem(localStorageKey, currentTryItems.join(','));
          } else {
            localStorage.setItem(localStorageKey, currentTryItems.filter((key) => key !== item.key).join(','));
          }
        }
        if (submitOnCheck) {
          submitChanges(updatedItems);
        }
      }
    },
    [submitOnCheck, selectedPickerItems, localStorageKey, submitChanges]
  );

  const dropdownItems = useMemo(() => {
    const validItems = renderedItems
      .map((identifier) => {
        const matchedItem = items.find((item) => item.identifier == identifier);
        if (!matchedItem) return;
        return { name: matchedItem.name, id: identifier };
      })
      .filter(Boolean) as { name: string; id: string }[];
    return validItems.map((item) => ({
      key: item.id,
      text: item.name,
    }));
  }, [items, renderedItems]);

  return (
    <div className={classNames([styles.selector, className])}>
      <Dropdown
        calloutProps={{
          className: classNames({ [styles.selectorCalloutLight]: !darkMode, [styles.selectorCalloutDark]: darkMode }),
          directionalHint: renderCalloutOnTop ? DirectionalHint.topCenter : undefined,
        }}
        placeholder={t('common.selectPlaceholder', { label: title })}
        label={title}
        multiSelect
        options={dropdownItems}
        onChange={onCheck}
        selectedKeys={selectedPickerItems}
        onDismiss={submitOnCheck ? undefined : submitChanges}
        disabled={disabled}
      />
    </div>
  );
};

export default Picker;
