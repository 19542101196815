import { IDataStoreItemDto, getEndpoint } from '@cpa/base-core/api';
import { IDataItem } from '@cpa/base-core/types';

export async function getAllLocalizations(
  dataUrl: string,
  dataEndpointIdentifier: string,
  identifier: string
): Promise<{ [language: string]: IDataItem } | undefined> {
  const endpoint = getEndpoint(dataEndpointIdentifier);

  const url = new URL(dataUrl, window.location.origin);

  if (!url.pathname.endsWith('/')) {
    url.pathname += '/';
  }

  url.pathname += `${encodeURIComponent(identifier)}/localizations`;

  const response = await endpoint.axios.get<IDataStoreItemDto<{ [language: string]: IDataItem }>>(url.pathname);

  return response.value;
}
