import { IGlobalState } from '@cpa/base-core/store';
import { HoverCard, HoverCardType } from '@fluentui/react';
import React, { useCallback, useMemo, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './HoverCardAutogrow.module.scss';

interface IHoverCardAutogrowProps {
  // content inside table line
  previewContentRenderer: () => string | JSX.Element;
  disableMaxHeight?: boolean;
  expandDelay?: number;
  isInsideTable?: boolean;
  children?: React.ReactNode;
  cardWrapperClassName?: string;
  cardClassName?: string;
  previewClassName?: string;
  calloutClassName?: string;
  contentClassName?: string;
}

const HoverCardAutogrow: React.FC<IHoverCardAutogrowProps> = ({
  children,
  previewContentRenderer,
  isInsideTable,
  cardWrapperClassName,
  cardClassName,
  previewClassName,
  calloutClassName,
  contentClassName,
}) => {
  const targetRef = useRef<HTMLSpanElement>(null);

  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const onRenderPlainCard = useCallback(
    (data: string | JSX.Element) => {
      return (
        <div className={classNames(styles.card, darkMode ? styles.cardDark : styles.cardLight, cardClassName)}>
          <div className={classNames(styles.content, contentClassName)}>
            {typeof data === 'string' ? (ReactHtmlParser(data as string) as React.ReactNode) : data}
          </div>
        </div>
      );
    },
    [cardClassName, contentClassName, darkMode]
  );

  const calloutProps = useMemo(() => {
    return calloutClassName ? { className: calloutClassName } : {};
  }, [calloutClassName]);

  return (
    <>
      <HoverCard
        cardDismissDelay={50}
        className={classNames(styles.hoverCard, styles.expanded, cardWrapperClassName)}
        type={HoverCardType.plain}
        {...(isInsideTable
          ? {
              target: targetRef.current,
            }
          : {})}
        plainCardProps={{
          renderData: children,
          onRenderPlainCard,
          calloutProps: {
            isBeakVisible: false,
            ...calloutProps,
          },
        }}
      >
        <span className={classNames(styles.target, previewClassName)} ref={targetRef}>
          {previewContentRenderer()}
        </span>
      </HoverCard>
    </>
  );
};

export default HoverCardAutogrow;
