import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Callout, DefaultButton, DirectionalHint, PrimaryButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { TourContext } from '../../Layout';

import styles from './TourCallout.module.scss';

export interface ITourBubble {
  visible: boolean;
  title: string;
  text: string;
  actionText?: string;
  type?: BubbleType;
  primaryBtn?: {
    label: string;
    onClick: () => void;
  };
  cancelBtn?: {
    label: string;
    onClick: () => void;
  };
}

export enum BubbleType {
  Primary,
  Secondary,
}

interface ITourCalloutProps {
  bubble: ITourBubble;
  target: Element;
  directionalHint: DirectionalHint;
  doNotLayer: boolean;
}

const TourCallout: React.FC<ITourCalloutProps> = ({ bubble, target, directionalHint, doNotLayer }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const highContrast = useSelector((state: IGlobalState) => state.settings.highContrast);
  const tourContext = useContext(TourContext);
  const [t] = useTranslation();

  return (
    <Callout
      doNotLayer={doNotLayer}
      backgroundColor={'green'}
      target={target}
      directionalHint={directionalHint}
      role={'dialog'}
      gapSpace={0}
      isBeakVisible={true}
      beakWidth={10}
      calloutMaxHeight={undefined}
      className={classNames(
        { [styles.bubble]: !darkMode },
        { [styles.bubbleDark]: darkMode },
        { [styles.bubbleDarkHighContrast]: darkMode && highContrast },
        { [styles.bubbleBlue]: bubble.type === BubbleType.Secondary && !darkMode },
        { [styles.bubbleGray]: bubble.type === BubbleType.Secondary && darkMode },
        { [styles.bubbleGrayHighContrast]: bubble.type === BubbleType.Secondary && darkMode && highContrast }
      )}
    >
      <h3>{bubble.title}</h3>
      <p>{bubble.text}</p>
      <p>
        <b>{bubble.actionText}</b>
      </p>
      <div className={styles.bubbleButtons}>
        {!!bubble.cancelBtn && (
          <DefaultButton onClick={bubble.cancelBtn.onClick} className={darkMode ? styles.cancelBtnDark : styles.cancelBtn}>
            {bubble.cancelBtn.label}
          </DefaultButton>
        )}
        {!!bubble.primaryBtn && (
          <PrimaryButton
            label={bubble.primaryBtn.label}
            onClick={bubble.primaryBtn.onClick}
            className={darkMode ? styles.primaryBtnDark : styles.primaryBtn}
          >
            {bubble.primaryBtn.label}
          </PrimaryButton>
        )}
        <DefaultButton
          label={t('header.controls.skipTour')}
          onClick={tourContext?.skipTour}
          className={darkMode ? styles.skipButtonDark : styles.skipButton}
        >
          {t('header.controls.skipTour')}
        </DefaultButton>
      </div>
    </Callout>
  );
};

export default TourCallout;
