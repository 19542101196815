import { useId } from '@fluentui/react-hooks';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ICardProps, IDataItem } from '@cpa/base-core/types';
import { IGlobalState } from '@cpa/base-core/store';

import ShimmerGrid from '../../../../../ShimmerGrid/ShimmerGrid';
import GenericCard, { IGenericCardProps } from '../../../GenericCard/GenericCard';

import styles from './PageCard.module.scss';

export interface IPageCardProps {
  data: ICardProps['data'];
  item?: IDataItem | null;
  index: number;
  isSelected: boolean;
  handleItemSelect: (item: IDataItem, replace?: boolean) => void;
  onCardRightClick?: (item: IDataItem, position: { x: number; y: number; targetId: string }) => unknown;
  onCardClick?: IGenericCardProps['onClick'];
}

const PageCard: React.FC<IPageCardProps> = ({ data, item, index, isSelected, handleItemSelect, onCardRightClick, onCardClick }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const target = item && ((item.identifier || item.__identifier) as string)?.replaceAll(/[^\d\w]/g, '_');
  const targetId = useId(`#card-item--${target}`);

  const onRightClick = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      if (item) {
        handleItemSelect(item, true);
        onCardRightClick?.(item, { x: e.clientX, y: e.clientY, targetId });
      }
    },
    [handleItemSelect, item, onCardRightClick, targetId]
  );

  if (!item) {
    return (
      <div className={styles.card} key={index}>
        <div className={styles.wrapper}>
          <ShimmerGrid darkMode={darkMode} gridArea={'"a"'} gridGap={0} height={270} />
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid="PageCardView__card"
      id={targetId}
      data-selection-invoke={true}
      className={styles.card}
      data-selection-index={index}
      data-is-focusable="true"
      data-item-index={index}
      onContextMenu={onRightClick}
    >
      <div className={styles.wrapper}>
        <GenericCard data={data} item={item} onClick={onCardClick} onItemSelect={handleItemSelect} isSelected={isSelected} />
      </div>
    </div>
  );
};

export default PageCard;
