import { IMessageBarProps, MessageBar } from '@fluentui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { HTMLAttributes } from 'react';

export interface IMessageBars extends IMessageBarProps {
  messages: (string | JSX.Element)[];
  isClosable?: boolean;
  rootProps?: HTMLAttributes<HTMLDivElement>;
}

const MessageBars: FC<IMessageBars> = ({ messages = [], isClosable = true, rootProps, ...rest }) => {
  const [shownMessages, setShownMessages] = useState(() => new Set(messages));

  const onDismissMessage = useCallback(
    (dismissedError: string | JSX.Element) => (): void => {
      setShownMessages((currentSet) => {
        const newSet = new Set(currentSet);
        newSet.delete(dismissedError);
        return newSet;
      });
    },
    []
  );

  useEffect(() => {
    setShownMessages(new Set(messages));
  }, [messages]);

  if (!shownMessages.size) {
    return null;
  }

  return (
    <div {...rootProps}>
      {Array.from(shownMessages).map((message, index) => (
        <MessageBar key={index} onDismiss={isClosable ? onDismissMessage(message) : undefined} {...rest}>
          <div style={{ whiteSpace: 'pre-line' }}>{message}</div>
        </MessageBar>
      ))}
    </div>
  );
};

export default MessageBars;
