import * as _ from 'lodash';
import { IJSONSchema } from '@cp/base-types';
import { cloneDeepWithMetadata } from '@cp/base-utils';

export function buildFieldEditorSchema(rootSchema: IJSONSchema, fieldSchema: IJSONSchema, fieldPath: string): IJSONSchema {
  const path = _.toPath(fieldPath);

  const fieldName = path.slice(-1)[0];

  const schema = cloneDeepWithMetadata(rootSchema);

  schema.properties = {
    [fieldName]: {
      ...fieldSchema,

      cp_localizable: false,
      cp_rjsfUiSchema: {
        defaultExpanded: true,
      },
    },
  };
  schema.title = undefined;
  schema.required = undefined;

  return schema;
}
