import DOMPurify from 'dompurify';

export const initializeDOMElement = (value: string, stripHtml: boolean, specialConfig: DOMPurify.Config = {}): undefined | HTMLElement => {
  if (DOMPurify.isSupported) {
    // Use DOMPurify as default if it's able to do the job
    const defaultConfig: DOMPurify.Config = {
      RETURN_DOM: true,
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    };
    const config = { ...defaultConfig, ...specialConfig };
    return DOMPurify.sanitize(value, config) as HTMLElement;
  }
  return undefined;
};

export const getSafeString = (value: string | undefined, stripHtml: boolean = false): string | undefined => {
  if (value) {
    // console.time('getSafeString');
    // console.timeLog('getSafeString', value);

    const purifiedElem = initializeDOMElement(
      value,
      stripHtml,
      stripHtml
        ? {
            ALLOWED_TAGS: ['#text'],
            KEEP_CONTENT: true,
          }
        : {}
    );
    if (purifiedElem) {
      // Purified (default)
      if (stripHtml) {
        value = purifiedElem.innerText; // To make sure we remove entities like &nbsp; etc.
      } else {
        value = purifiedElem.innerHTML;
      }
    } else {
      // Emergency (not safe) regEx fallback
      value = value.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim, '');
      if (stripHtml) {
        // Tags
        value = value.replace(/(<([^>]+)>)/gim, '');
        // HTML & XML entities
        value = value.replace(/&quot;/gim, '"');
        value = value.replace(/&nbsp;/gim, ' ');
        value = value.replace(/&apos;/gim, "'"); // eslint-disable-line quotes
        value = value.replace(/&lt;/gim, '<');
        value = value.replace(/&gt;/gim, '>');
      }
      console.warn('DOMPurify could not be used.');
    }
    // console.timeEnd('getSafeString');
  }
  return value;
};

export const containsHtml = (value: string | undefined): boolean => {
  if (!value) {
    return false;
  }
  return /<[^\s]+?\s*?\/>|<[^\s]+?\s*?>.*?<\/.+?>/im.test(value); // lazy (non-greedy)
};
