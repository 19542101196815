import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';

import styles from './ChoiceGroup.module.scss';
import Option from './components/Option/Option';

export interface IOption {
  key: string;
  text: string;
  disabled: boolean;
  iconName: string;
  description: string | undefined;
}

interface IChoiceGroupProps {
  multiple: boolean;
  disableIcon?: boolean;
  options: IOption[];
  required: boolean;
  disabled?: boolean;
  selectedKeys?: string[];
  onChange: (key: string) => void;
}

const ChoiceGroup: React.FC<IChoiceGroupProps> = ({ multiple = false, disableIcon = false, options, selectedKeys = [], onChange }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const handleOptionClick = useCallback((key: string) => onChange(key), [onChange]);

  return (
    <div className={classNames(styles.container, { [styles.light]: !darkMode })}>
      {options.map((option) => (
        <Option
          key={option.key}
          option={option}
          isSelected={selectedKeys.includes(option.key)}
          multiple={multiple}
          disableIcon={disableIcon}
          onClick={handleOptionClick}
        />
      ))}
    </div>
  );
};

export default ChoiceGroup;
