import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { Schemas } from '@cp/base-types';
import { IGenericComponentProps } from '@cpa/base-core/types';
import { DefaultButton, DialogFooter } from '@fluentui/react';

import styles from '../../../ScrollingContent/components/Filter/components/Notifications/Notifications.module.scss';
import GenericScreen from '../../../../screens/GenericScreen/GenericScreen';

interface ITableDialogProps {
  dialogTypeOptions?: Record<string, unknown>;
  onClose: (payload: unknown) => void;
  secondaryText: string;
  dialogMaximized: boolean;
}

const TableDialog: React.FC<ITableDialogProps> = ({ dialogTypeOptions, onClose, secondaryText, dialogMaximized }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const page = useMemo(() => {
    return dialogTypeOptions?.page as Schemas.CpaPage;
  }, [dialogTypeOptions?.page]);

  const genericComponentProps = useMemo(() => {
    const props = dialogTypeOptions?.genericComponentProps as Partial<IGenericComponentProps> | undefined;
    if (!props) return {};
    return { ...props, scrollingContentProps: { ...props.scrollingContentProps, widgetHeight: dialogMaximized ? '52vh' : undefined } };
  }, [dialogMaximized, dialogTypeOptions?.genericComponentProps]);

  return (
    <>
      <div className={classNames(styles.targetSelection, { [styles.dark]: darkMode })}>
        <GenericScreen
          page={page}
          isWidget={true}
          withoutAnimation={true}
          resetFilterOnRefresh={true}
          genericComponentProps={genericComponentProps}
          showEmptyMessage
        />
      </div>
      <DialogFooter>
        <DefaultButton onClick={onClose} text={secondaryText} />
      </DialogFooter>
    </>
  );
};

export default TableDialog;
