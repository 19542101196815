import urlJoin from 'url-join';
import { TFunction } from 'i18next';
import { IDataItem } from '@cpa/base-core/types';
import { axiosDictionary, getEntitiesFromEndpoint } from '@cpa/base-core/api';
import { copyToClipboard, getAppBasePath } from '@cpa/base-core/helpers';
import { Schemas, TypeConstants } from '@cp/base-types';
import { DataServiceModules } from '@cp/base-utils';

export const handleShare = (page: Schemas.CpaPage, itemIdentifier?: string, queryObject?: Record<string, string>, useFilter?: boolean): void => {
  let uri: string = urlJoin(`${window.location.origin}${getAppBasePath()}`, page.path?.replace('/:id', '') || '/');

  if (!useFilter) {
    // Add identifier as uri param
    uri = urlJoin(uri, encodeURIComponent(itemIdentifier?.toString() || '-'));
  }

  if (queryObject || useFilter) {
    const queryParams = new URLSearchParams(queryObject);

    if (useFilter) {
      // Add identifier as uri filter query
      queryParams.set('filter', JSON.stringify({ identifier: itemIdentifier?.toString() }));
    }

    uri += `?${queryParams.toString()}`;
  }

  copyToClipboard(uri);
};

export const fetchSharedItem = async (sharedItemIdentifier: string, t: TFunction): Promise<IDataItem> => {
  try {
    const fetchedSharedItems = (
      await Promise.all(
        getEntitiesFromEndpoint(
          axiosDictionary.appDataService,
          `${DataServiceModules.DATA_STORE}/${encodeURIComponent(TypeConstants.CpSharedItem)}`,
          {
            filter: {
              identifier: sharedItemIdentifier,
            },
          }
        )
      )
    ).flatMap((response) => response.entities);

    if (fetchedSharedItems.length !== 1) {
      throw new Error(`${t('common.itemNotFound')} - ${sharedItemIdentifier}`);
    }

    return fetchedSharedItems[0];
  } catch (e) {
    throw new Error(e.message ?? e.err);
  }
};
