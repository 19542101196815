import { Schemas } from '@cp/base-types';
import { CommandBar, ICommandBarItemProps, ICommandBarStyles } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { cloneDeepWithMetadata, flattenMapToObject, objectToFlattenMap } from '@cp/base-utils';

import styles from './Difference.module.scss';
import DifferenceAsJson from './DifferenceAsJson/DifferenceAsJson';
import DifferenceAsObjects from './DifferenceAsObjects/DifferenceAsObjects';
import { IDiffSource } from './DifferenceAsObjects/helpers/difference';

const commandBarStyles: ICommandBarStyles = {
  root: { marginBottom: 0, padding: 0, backgroundColor: 'transparent' },
};

const sortDataItemProperties = (source: IDataItem): IDataItem => {
  const itemMap = objectToFlattenMap(cloneDeepWithMetadata(source));

  const itemFields: { key: string; value: unknown }[] = [];

  for (const [key, value] of itemMap) {
    itemFields.push({ key, value });
  }

  itemFields.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });

  itemMap.clear();

  for (const itemField of itemFields) {
    itemMap.set(itemField.key, itemField.value);
  }

  return flattenMapToObject(itemMap) as unknown as IDataItem;
};

export interface IDifferenceProps {
  className?: string;
  page: Schemas.CpaPage;
  itemsToCompare: IDiffSource[];
}

const Difference: React.FC<IDifferenceProps> = ({ className, page, itemsToCompare }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const [compareAsJson, { toggle: toggleCompareAsJson }] = useBoolean(false);
  const [isOnlyChangedFields, { toggle: toggleOnlyChangedFields }] = useBoolean(false);

  const commandBarButtons = useMemo(() => {
    const buttons: ICommandBarItemProps[] = [];

    if (compareAsJson) {
      buttons.push({
        key: 'compareAsObjects',
        name: t('common.compareAsObjects'),
        iconProps: { iconName: 'BulletedTreeList' },
        onClick: toggleCompareAsJson,
      });
    } else {
      buttons.push({
        key: 'compareAsJson',
        name: t('common.compareAsJson'),
        iconProps: { iconName: 'Code' },
        onClick: toggleCompareAsJson,
      });

      if (isOnlyChangedFields) {
        buttons.push({
          key: 'allFields',
          name: t('common.allFields'),
          className: styles.onlyChangedFieldsButton,
          onClick: toggleOnlyChangedFields,
          iconProps: { iconName: 'BacklogList' },
          disabled: compareAsJson,
        });
      } else {
        buttons.push({
          key: 'onlyChangedFields',
          name: t('common.onlyChangedFields'),
          className: styles.onlyChangedFieldsButton,
          onClick: toggleOnlyChangedFields,
          iconProps: { iconName: 'AutoFillTemplate' },
          disabled: compareAsJson,
        });
      }
    }

    if (!compareAsJson) {
    }

    return buttons;
  }, [compareAsJson, isOnlyChangedFields, t, toggleCompareAsJson, toggleOnlyChangedFields]);

  const jsonCompareItems = useMemo(() => {
    const items: IDataItem[] = [{}, {}];

    if (itemsToCompare.length === 1) {
      items[0] = sortDataItemProperties(itemsToCompare[0].itemA);
      items[1] = sortDataItemProperties(itemsToCompare[0].itemB);
    } else if (itemsToCompare.length > 1) {
      for (let i = 0; i < itemsToCompare.length; i++) {
        const item = itemsToCompare[i];
        const itemTitle = item.schema?.title ? item.schema.title : `${item}${i + 1}`;

        items[0][itemTitle] = sortDataItemProperties(item.itemA);
        items[1][itemTitle] = sortDataItemProperties(item.itemB);
      }
    }

    return items;
  }, [itemsToCompare]);

  return (
    <div className={classNames(styles.container, className, darkMode ? styles.containerDark : '')}>
      <CommandBar
        className={classNames({
          [styles.header]: true,
          [styles.headerDark]: darkMode,
        })}
        items={commandBarButtons}
        styles={commandBarStyles}
      />

      <div
        className={classNames({
          [styles.body]: true,
          [styles.bodyDark]: darkMode,
        })}
      >
        {compareAsJson && <DifferenceAsJson itemA={jsonCompareItems[0]} itemB={jsonCompareItems[1]} />}

        {!compareAsJson && (
          <DifferenceAsObjects
            items={itemsToCompare}
            nameA={`${itemsToCompare[0].nameA}`}
            nameB={`${itemsToCompare[0].nameB}`}
            page={page}
            isOnlyChangedFields={isOnlyChangedFields}
          />
        )}
      </div>
    </div>
  );
};

export default Difference;
