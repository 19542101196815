import React, { useMemo } from 'react';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { IDataItem } from '@cpa/base-core/types';

import { IDiffSource } from '../../../../Difference/DifferenceAsObjects/helpers/difference';
import Difference from '../../../../Difference/Difference';

export interface IVersionsDrawerCompareProps {
  page: Schemas.CpaPage;
  differenceDrawerItems: [IDataItem | null, IDataItem | null];
  pageSchema: IJSONSchema;
  versionSchema: IJSONSchema | null;
}

export const VersionsDrawerCompare: React.FC<IVersionsDrawerCompareProps> = ({ page, differenceDrawerItems, pageSchema, versionSchema }) => {
  const itemsToCompare = useMemo(() => {
    const sourceItems = [
      {
        itemA: differenceDrawerItems[0]?.item as IDataItem,
        itemB: differenceDrawerItems[1]?.item as IDataItem,
        schema: pageSchema,
      },
      {
        itemA: differenceDrawerItems[0]?.localizations as IDataItem,
        itemB: differenceDrawerItems[1]?.localizations as IDataItem,
        schema: versionSchema?.properties?.localizations || null,
      },
    ];

    const itemsToCompare: IDiffSource[] = [];

    for (const item of sourceItems) {
      itemsToCompare.push({
        itemA: item.itemA,
        itemB: item.itemB,
        schema: item.schema,
        nameA: `${differenceDrawerItems[0]?.name || differenceDrawerItems[0]?.number || differenceDrawerItems[0]?.identifier || '-'}`,
        nameB: `${differenceDrawerItems[1]?.name || differenceDrawerItems[1]?.number || differenceDrawerItems[1]?.identifier || '-'}`,
      });
    }

    return itemsToCompare;
  }, [differenceDrawerItems, pageSchema, versionSchema]);

  if (!differenceDrawerItems || !differenceDrawerItems[0] || !differenceDrawerItems[1]) {
    return <></>;
  }

  return <Difference page={page} itemsToCompare={itemsToCompare} />;
};

export default VersionsDrawerCompare;
