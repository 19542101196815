import { getSafeString } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { ICardProps } from '@cpa/base-core/types';
import { TooltipOverflowMode } from '@fluentui/react';
import classNames from 'classnames';
import React, { ElementType, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HoverTooltip from '../../../components/HoverTooltip/HoverTooltip';
import SelectionButton from '../../../components/SelectionButton/SelectionButton';

import styles from './RebrushedImageItemCard.module.scss';

export interface IRebrushedImageItemCardProps extends ICardProps {
  hideExternalLinkButton?: boolean;
  lightCard?: boolean;
}

const RebrushedImageItemCard: React.FC<IRebrushedImageItemCardProps> = ({
  item,
  onClick,
  onItemSelect,
  isSelected,
  hideSelectionMarker,
  hideExternalLinkButton,
  lightCard,
}) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick]
  );

  const stopEventPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  // Get card content from 'shortDescription' or 'description'
  const cardContent = useMemo(() => {
    const content = item.shortDescription || item.description;
    return (content && getSafeString(content as string, true)?.trim()) || '';
  }, [item.description, item.shortDescription]);

  return (
    <div
      key={String(item?.identifier)}
      className={classNames(styles.item, { [styles.itemDark]: darkMode, [styles.lightCard]: lightCard })}
      onClick={handleItemClick}
    >
      {!hideSelectionMarker && (
        <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} preventDefault={true} />
      )}
      <div className={styles.inner}>
        <div className={styles.cardHeader}>
          <div className={styles.titleWrapper}>
            <a className={styles.title}>{item.name}</a>
          </div>
          {item.image && <img className={styles.logo} src={item.image as string} />}
        </div>
        <div className={styles.content}>
          <span className={classNames(styles.textTitle, { [styles.textDark]: darkMode })}>{item.alternateName}</span>
          <span className={classNames(styles.text, { [styles.textDark]: darkMode })}>
            <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={cardContent}>
              {cardContent}
            </HoverTooltip>
          </span>
        </div>
      </div>
      <div className={styles.buttonContainer} onClick={stopEventPropagation}>
        {!hideExternalLinkButton && item.url ? (
          <div className={styles.moreLinkWrapper}>
            <a className={styles.linkWrapper} href={item.url as string | undefined} target="_blank" rel="noopener noreferrer">
              <p className={styles.moreLink}>{t('common.openExternalLink')} &#x276F;</p>
            </a>
          </div>
        ) : null}
        {onClick ? (
          <div className={styles.moreLinkWrapper}>
            <p className={styles.moreLink} onClick={onClick}>
              {t('common.more')} &#x276F;
            </p>
          </div>
        ) : null}
      </div>
      {typeof item.stampText === 'string' && item.stampText !== '' && <div className={styles.stamp}>{getSafeString(item.stampText)}</div>}
    </div>
  );
};

export function withLightCard(WrappedComponent: ElementType): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} lightCard={true} />;
  };
}

export default RebrushedImageItemCard;
