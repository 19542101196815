import { EventEmitter } from 'events';

import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import {
  IScrollablePane,
  IScrollablePaneStyleProps,
  IScrollablePaneStyles,
  IStyleFunctionOrObject,
  ScrollablePane,
  ThemeContext,
} from '@fluentui/react';
import * as _ from 'lodash';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import { IScrollablePaneContext } from '@cpa/base-core/types';
import { useMediaQuery } from 'react-responsive';

import { LayoutContext } from '../Layout/Layout';

export const ScrollablePaneContext = React.createContext<IScrollablePaneContext | null>(null);

interface IContextProps {
  bottomElementSelector?: string;
  customScrollablePaneStyles?: IStyleFunctionOrObject<IScrollablePaneStyleProps, IScrollablePaneStyles>;
  children: React.ReactNode;
}

const generateScrollEventEmitter = (): EventEmitter => {
  const eventEmitter = new EventEmitter();
  eventEmitter.setMaxListeners(0);
  return eventEmitter;
};

const HEADER_HEIGHT = 100;

const ScrollablePaneContextProvider: React.FC<IContextProps> = ({ bottomElementSelector, customScrollablePaneStyles, children }) => {
  const [customStyles, setCustomStyles] = useState<IStyleFunctionOrObject<IScrollablePaneStyleProps, IScrollablePaneStyles>>({});
  const scrollEventEmitter = useRef<EventEmitter>(generateScrollEventEmitter());
  const scrollablePaneRef = useRef<IScrollablePane & { contentContainer: HTMLElement | null; stickyAbove: HTMLElement | null }>(null);
  const theme = useContext(ThemeContext);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const layoutContext = useContext(LayoutContext);
  const laptopOnly = useMediaQuery({ query: '(max-width: 768px)' });
  const [scrollOverFooter, setScrollOverFooter] = useState(false);
  const [prevStickyHeight, setPrevStickyHeight] = useState<number | null>(null);

  const scrollablePaneContext = useMemo(() => {
    return {
      scrollEventEmitter: scrollEventEmitter,
      setCustomStyles: setCustomStyles,
      scrollablePaneRef: scrollablePaneRef,
      scrollOverFooter: scrollOverFooter,
    };
  }, [scrollOverFooter]);

  const scrollablePaneStyles = useMemo(
    () =>
      _.merge(
        {
          root: {
            marginTop: 60,
            maxWidth: '100%',
          },
          stickyAbove: { zIndex: 5000 },
          contentContainer: {
            overflowX: 'hidden',
            transition: 'all 0.5s ease-in-out',
            backgroundColor: darkMode ? theme?.palette.white : theme?.palette.neutralLighterAlt,
          },
        },
        customScrollablePaneStyles,
        customStyles
      ),
    [customScrollablePaneStyles, customStyles, darkMode, theme]
  );

  const onPaneScroll = useCallback(
    _.debounce(
      () => {
        const footer = bottomElementSelector && document.querySelector(bottomElementSelector);
        scrollEventEmitter.current.emit('onFullBodyScroll', { target: scrollablePaneRef.current?.contentContainer });
        if (footer) {
          const fromTop = footer.getBoundingClientRect().y;
          const screenHeight = window.innerHeight;

          scrollEventEmitter.current.emit('onGettingStickyScroll', { target: scrollablePaneRef.current?.contentContainer });

          const headerHeight = HEADER_HEIGHT + (layoutContext?.isHorizontalMenu ? 44 : 0);
          const stickyHeight = prevStickyHeight || scrollablePaneRef.current?.stickyAbove?.clientHeight || 0;

          const gap = laptopOnly ? 110 : 130;

          if (fromTop - (headerHeight + stickyHeight + gap) <= 0) {
            setScrollOverFooter(true);
            setPrevStickyHeight(stickyHeight);
          } else {
            setPrevStickyHeight(null);
            setScrollOverFooter(false);
          }
          if (screenHeight - fromTop >= -620) {
            // @all: condition works when viewport position is before footer for 620px
            scrollEventEmitter.current.emit('onBodyScroll', { target: scrollablePaneRef.current?.contentContainer });
          }
        }
      },
      150,
      { leading: true }
    ),
    [prevStickyHeight, bottomElementSelector]
  );

  return (
    <ScrollablePane componentRef={scrollablePaneRef} onScroll={onPaneScroll} styles={scrollablePaneStyles}>
      <ScrollablePaneContext.Provider value={scrollablePaneContext}>{children}</ScrollablePaneContext.Provider>
    </ScrollablePane>
  );
};

export default ScrollablePaneContextProvider;
