import React from 'react';
import Steps from 'rc-steps';
import { Icon } from '@fluentui/react';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import ArrowsIcon from '../../../../../assets/icons/arrows.svg';

import styles from './UspsList.module.scss';

interface IUspsListProps {
  items: { name?: string | undefined; description?: string | undefined; uspDetails?: string[] | undefined }[];
}

const UspsList: React.FC<IUspsListProps> = ({ items }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  return (
    <div className={styles.wrapper}>
      {items.map((item, index) => {
        const steps = item.uspDetails?.map((detail) => {
          return {
            title: detail,
            icon: <Icon iconName={'LocationDot'} style={{ fontSize: '27px' }}></Icon>,
          };
        });
        return (
          <div key={index} className={styles.uspsItem}>
            <span className={styles.name}>{item.name}</span>
            <Steps
              className={classNames({ [styles.steps]: true, [styles.dark]: darkMode })}
              items={steps}
              direction={'vertical'}
              current={steps?.length}
            />
            <div className={styles.descriptionWrapper}>
              <img className={styles.arrowsIcon} src={ArrowsIcon} />
              <span className={styles.description}>{item.description}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UspsList;
