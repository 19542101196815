const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/TicketComment',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'customer/{{accountNo}}/ticket-related/comment', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Ticket Activity',
    'description': 'Comment, email, phone call or other activity/interaction on the ticket.',
    'properties': {
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'direction': {
            'title': 'Direction',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/direction', 'rel': 'canonical' }],
        },
        'about': {
            'title': 'About',
            'description': 'The most generic type of item.',
            '$id': 'http://schema.org/Thing',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FThing' },
                { 'href': 'http://schema.org/about', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'required': ['identifier'],
            'cp_ui': { 'hiddenInForm': true },
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:htmlAttachmentReplacing',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Subject',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['description', 'about'],
};
export const TicketCommentSchema = Schema;
