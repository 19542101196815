export const missingGanScreen = 'Contact';
export const widgetPageSize = 18;
export const fullPageSize = 12;
export const maxAmountOfCards = 3;

export const itemExpandColumnKey = 'item-expand';

export const DateLanguageMapping: Record<string, string> = {
  'en-US': 'en',
  'de-DE': 'de',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'hu-HU': 'hu',
  'ro-RO': 'ro',
  'sv-SE': 'sv',
  'zh-CN': 'zh-cn',
};

// TODO: Change {{accountNo}} to {accountNo} to do the same as we do for all other placeholders
export const accountPlaceholderPattern = /{{accountNo}}|%7B%7BaccountNo%7D%7D/g;

export const simpleCardKey = '__simpleCard';

export enum TableKeyPrefix {
  SearchSuggestions = 'searchSuggestions',
  Relation = 'relation',
  Suggestions = 'suggestions',
  Localizations = 'localizations',
  Versions = 'versions',
}
