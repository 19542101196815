import React from 'react';
import { IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { usePowerUser } from '@cpa/base-core/hooks';

import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';

import styles from './ArrayItemButtons.module.scss';

interface IArrayItemButtonsProps {
  index: number;
  expanded: boolean;
  verticalArrows: boolean;
  disabled: boolean;
  readonly: boolean;
  hasMoveUp: boolean;
  hasMoveDown: boolean;
  onReorderClick: (
    index: number,
    newIndex: number
  ) => React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement>;
  onClone: () => void;
  onDropIndexClick: (index: number) => React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement>;
  allowReordering: boolean;
  allowDelete: boolean;
}

const ArrayItemButtons: React.FC<IArrayItemButtonsProps> = ({
  index,
  expanded,
  verticalArrows,
  disabled,
  readonly,
  hasMoveUp,
  hasMoveDown,
  onReorderClick,
  onClone,
  onDropIndexClick,
  allowReordering,
  allowDelete,
}) => {
  const powerUser = usePowerUser();
  const [t] = useTranslation();

  const removeButton = allowDelete ? (
    <HoverTooltip content={t('common.delete')} key="remove">
      <IconButton
        className="array-item-remove"
        disabled={disabled || readonly}
        onClick={onDropIndexClick(index)}
        iconProps={{
          iconName: 'Delete',
        }}
        color="secondary"
      />
    </HoverTooltip>
  ) : null;

  const copyButton = powerUser ? (
    <HoverTooltip content={t('common.copy')} key="copy">
      <IconButton
        className="array-item-copy"
        disabled={disabled || readonly}
        onClick={onClone}
        iconProps={{
          iconName: 'Copy',
        }}
        color="secondary"
      />
    </HoverTooltip>
  ) : null;

  const moveUpButton =
    allowReordering && powerUser ? (
      <HoverTooltip content={t('common.moveUp')} key="moveUp">
        <IconButton
          className="array-item-move-up"
          disabled={disabled || readonly || !hasMoveUp}
          onClick={onReorderClick(index, index - 1)}
          iconProps={{
            iconName: 'Up',
          }}
          color="secondary"
        />
      </HoverTooltip>
    ) : null;

  const moveDownButton =
    allowReordering && powerUser ? (
      <HoverTooltip content={t('common.moveDown')} key="moveDown">
        <IconButton
          className="array-item-move-down"
          disabled={disabled || readonly || !hasMoveDown}
          onClick={onReorderClick(index, index + 1)}
          iconProps={{
            iconName: 'Down',
          }}
          color="secondary"
        />
      </HoverTooltip>
    ) : null;

  if (expanded && verticalArrows) {
    return (
      <div className={styles.buttonsVertical} style={{ textAlign: 'right' }}>
        {removeButton}
        {copyButton}
        {moveUpButton}
        {moveDownButton}
      </div>
    );
  }

  return (
    <div className={styles.buttons} style={{ textAlign: 'right' }}>
      {moveUpButton}
      {moveDownButton}
      {copyButton}
      {removeButton}
    </div>
  );
};

export default ArrayItemButtons;
