export async function sha256(inputString: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(inputString);
  const buffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(buffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

export async function generateShortHash(inputString: string, length: number): Promise<string> {
  // Slicing a hash to create a shorter version is a common practice and can be a reasonable approach
  // However, collision risks need to be considered
  const hash = await sha256(inputString);
  return hash.slice(0, length);
}
