import { axiosDictionary, postEntityToEndpoint, putEntityToEndpoint } from '@cpa/base-core/api';
import { useLoadableData } from '@cpa/base-core/hooks';
import { IRelatedContentProps, IDataItem, IScreenProps, IRelatedViewProps } from '@cpa/base-core/types';
import { ODataPropsFilter } from '@cp/base-utils';
import { MessageBarType } from '@fluentui/react';
import { IChangeEvent } from '@rjsf/core';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { fullPageSize } from '@cpa/base-core/constants';

import GenericComponent from '../../components/GenericComponent/GenericComponent';
import Form from '../../components/Form/Form';
import MessageBars from '../../components/MessageBars/MessageBars';
import { getParsedFilter } from '../GenericScreen/utils';
import styles from '../Statement/Statement.module.scss';

export enum CustomAggregationTemplate {
  TAGS = 'TAGS',
}

const Tags: React.FC<IScreenProps & IRelatedViewProps & IRelatedContentProps> = ({
  page,
  location,
  closeDrawer,
  externalDataQuery,
  externalODataFilter,
}) => {
  const { parsedODataFilter, loadItems, schema, totalItems, isFetching, errors, items, isODataSupportedByEndpoint } = useLoadableData(
    page.dataUrl || '/',
    page.dataEndpoint?.identifier || axiosDictionary.appDataService,
    undefined,
    undefined,
    location?.state?.externalDataQuery || externalDataQuery,
    externalODataFilter
  );

  useEffect(() => {
    loadItems({}, { resetExistingData: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.dataUrl, page.dataEndpoint?.identifier]);

  // Operations
  const onAddTag = useCallback(
    async (tableItem: IDataItem) => {
      if (!page.dataUrl || !page.dataEndpoint?.identifier || !schema) {
        return;
      }

      await postEntityToEndpoint(page.dataEndpoint?.identifier, page.dataUrl, tableItem, schema);
      await loadItems({}, { overwriteExistingData: true });
    },
    [page.dataUrl, page.dataEndpoint?.identifier, schema, loadItems]
  );
  const onEditTag = useCallback(
    async (editedItem: IDataItem, initialItem: IDataItem) => {
      if (!page.dataUrl || !page.dataEndpoint?.identifier || !schema) {
        return;
      }

      await putEntityToEndpoint(page.dataEndpoint?.identifier, page.dataUrl, initialItem, editedItem, schema);
      await loadItems({}, { overwriteExistingData: true });
    },
    [page.dataUrl, page.dataEndpoint?.identifier, schema, loadItems]
  );

  const formSubmitHandler = useCallback(
    async ({ formData }: IChangeEvent): Promise<void> => {
      if (!formData || !Object.keys(formData).length) {
        return;
      }
      const tagPromise = items.length
        ? onEditTag?.(
            _.mergeWith({}, items[0], formData, (obj: unknown, src: unknown) => (Array.isArray(obj) ? src : undefined)),
            items[0]
          )
        : onAddTag?.(formData);
      await tagPromise;
      closeDrawer();
    },
    [closeDrawer, items, onAddTag, onEditTag]
  );

  const initialFormData = useMemo(() => {
    return _.merge({}, items[0], getParsedFilter(_.merge({}, parsedODataFilter || {}, externalODataFilter || {}))[0]);
  }, [externalODataFilter, items, parsedODataFilter]);

  const statementComponent = useMemo(
    () =>
      schema ? (
        <section className={styles.tagsWrapper}>
          <Form page={page} schema={schema} formData={initialFormData} onSubmit={formSubmitHandler} />
        </section>
      ) : null,
    [schema, page, initialFormData, formSubmitHandler]
  );

  const onPageRefresh = useCallback(
    (refreshFilter?: ODataPropsFilter) => {
      return loadItems(isODataSupportedByEndpoint ? { filter: refreshFilter } : {}, { resetExistingData: true });
    },
    [loadItems, isODataSupportedByEndpoint]
  );

  const componentData = useMemo(() => {
    return { items: items, page, schema: schema, isFetching: isFetching, totalItems: totalItems };
  }, [isFetching, items, page, schema, totalItems]);

  if (!page.dataUrl) {
    return null;
  }

  return (
    <>
      <MessageBars messageBarType={MessageBarType.error} isMultiline={false} messages={errors} />
      <section className={styles.wrapper}>
        <GenericComponent
          isWidget={false}
          animationDelay={0}
          onRefresh={onPageRefresh}
          customContent={statementComponent}
          data={componentData}
          pageSize={fullPageSize}
          hideSettings={true}
          withoutAnimation={true}
        />
      </section>
    </>
  );
};

export default Tags;
