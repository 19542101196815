import { AuthenticationResult } from '@azure/msal-browser';
import { Schemas } from '@cp/base-types';
import { Action } from 'redux';
import { handleActions } from 'redux-actions';
import { getPrimaryEmailFromToken, isValidTokenClaimValue } from '@cp/base-utils';

import { ICompany } from '../../types';

import { loginError, loginSuccess, setUserSettings, setUserSettingsEtag } from './actions';

const DEFAULT_STATE: IAuthState = {
  user: null,
  error: null,
  userSettings: null,
  userSettingsEtag: null,
};

export interface IAuthState {
  user: {
    gan: number | null;
    companies: ICompany[];
    authenticationResult: AuthenticationResult;
    account: {
      name: string;
      email: string;
      accountIdentifier: string;
      preferred_username: string;
      [key: string]: string | string[];
    };
  } | null;
  error: Error | null;
  userSettings: Schemas.UserProfileShadow['cpaUserConfiguration'] | null;
  userSettingsEtag: string | null;
}

export default handleActions<IAuthState, any>(
  {
    [loginSuccess.toString()]: (state, action: Action & { payload: AuthenticationResult }) => {
      const {
        cp_gan,
        cp_an,
        name: initialName,
        given_name: givenName,
        family_name: familyName,
        preferred_username: username,
      } = action.payload.idTokenClaims as Record<string, any>;

      const email = getPrimaryEmailFromToken(action.payload.idTokenClaims) || '-';

      // Search for valid name
      let name: string = '-';
      if (isValidTokenClaimValue(givenName)) {
        name = `${givenName} ${familyName}`;
      } else if (isValidTokenClaimValue(initialName)) {
        name = initialName;
      } else if (isValidTokenClaimValue(username)) {
        name = initialName;
      } else if (isValidTokenClaimValue(email)) {
        name = email;
      }

      const companies: ICompany[] = cp_an?.map((company: string) => JSON.parse(company)) || [];

      return {
        ...state,
        user: {
          gan: cp_gan || null,
          companies: companies,
          authenticationResult: action.payload,
          account: {
            ...action.payload.idTokenClaims,
            name,
            email,
            accountIdentifier: action.payload.uniqueId,
            preferred_username: username,
          },
        },
      };
    },
    [loginError.toString()]: (state, action: Action & { payload: Error }) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    [setUserSettings.toString()]: (state, action: Action & { payload: Schemas.UserProfileShadow['cpaUserConfiguration'] }) => {
      return {
        ...state,
        userSettings: action.payload,
      };
    },
    [setUserSettingsEtag.toString()]: (state, action: Action & { payload: { eTag: string | null } }) => {
      return {
        ...state,
        userSettingsEtag: action.payload.eTag,
      };
    },
  },
  DEFAULT_STATE
);
