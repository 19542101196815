export var LegacyType;
(function (LegacyType) {
    LegacyType["Erp"] = "erp";
    LegacyType["Crm"] = "crm";
    LegacyType["Dms"] = "dms";
    LegacyType["Shop"] = "shop";
    LegacyType["Graph"] = "graph";
    LegacyType["SharePoint"] = "sharepoint";
    LegacyType["SharePointOnline"] = "sharepoint-online";
    LegacyType["SharePointGlit"] = "sharepoint-glit";
    LegacyType["Hubdrive"] = "hubdrive";
    LegacyType["DevOps"] = "devops";
    LegacyType["Docusign"] = "docusign";
})(LegacyType || (LegacyType = {}));
