import { itemExpandColumnKey } from '@cpa/base-core/constants';
import * as _ from 'lodash';
import { useCancellableLoadEntities, useCardWidth, useLiveUpdates, usePowerUser } from '@cpa/base-core/hooks';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import { IDataItem, ITableRowProps } from '@cpa/base-core/types';
import { getObjectValuesByPath, IODataProps } from '@cp/base-utils';
import { DetailsRow, DirectionalHint, IColumn, IDetailsRowBaseProps } from '@fluentui/react';
import { AnimationClassNames, IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import LongPress from 'react-long';
import { useInView } from 'react-intersection-observer';
import { formatEntries } from '@cp/base-utils';
import { useTranslation } from 'react-i18next';

import HoverTooltip from '../../../components/HoverTooltip/HoverTooltip';

import styles from './TableRow.module.scss';
import ParentingIndicator from './components/ParentingIndicator/ParentingIndicator';
import { simplifiedRowRenderer } from './components/SimpleCard/SimpleCard';

const ColorizeFirstRowContext = React.createContext(true);

const TableRow: React.FC<ITableRowProps> = ({
  page,
  rowId,
  detailedRowProps,
  columns,
  fireInvokeEvent,
  colorizedRows,
  darkMode,
  onDoubleClick,
  onContextMenu,
  item,
  parentPropertyJsonPath,
  childTableRender,
  level,
  loadItems: loadItemsFromProps,
  reloadEmitter,
  order,
  onAddClick,
  onNewChildItemsLoaded,
  pageSize,
}) => {
  const [t] = useTranslation();
  const loadItems = useCancellableLoadEntities(loadItemsFromProps);
  const childrenCount = item.__originalItem?.__childrenCount || item.__childrenCount || 0;

  const powerUser = usePowerUser();
  const colorizeFirstRow = useContext(ColorizeFirstRowContext);
  const paintRow = useMemo(() => {
    return !!colorizedRows && !!detailedRowProps && (colorizeFirstRow ? detailedRowProps.itemIndex % 2 === 0 : detailedRowProps.itemIndex % 2 !== 0);
  }, [colorizedRows, detailedRowProps, colorizeFirstRow]);

  const classes = classNames({
    [styles.highlightedRow]: paintRow && !darkMode,
    [styles.highlightedRowDark]: paintRow && darkMode,
    [styles.transparentRow]: !paintRow && !darkMode && powerUser,
    [styles.transparentRowDark]: !paintRow && darkMode && powerUser,
    [styles.defaultRow]: !paintRow && !darkMode && !powerUser,
    [styles.defaultRowDark]: !paintRow && darkMode && !powerUser,
  });

  const [isExpanded, { setTrue: openExpandedRow, setFalse: closeExpandedRow }] = useBoolean(false);
  const [childItems, setChildItems] = useState<IDataItem[]>([]);
  const [isFetching, { setTrue: startFetching, setFalse: finishFetching }] = useBoolean(false);

  const [totalItems, setTotalItems] = useState<number>(childrenCount);
  const lazyLoadingEnabled = useMemo(
    () => isExpanded && totalItems > childItems.length && !isFetching,
    [childItems.length, isExpanded, totalItems, isFetching]
  );

  const [lazyLoadingMarker, lazyLoadingMarkerInView] = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const loadChildItems = useCallback(
    (withAnimation: boolean = true, resetItems: boolean = true, appendLoadedItems: boolean = false, extraOptions: IODataProps = {}) => {
      if (!loadItems || !parentPropertyJsonPath || !item.identifier) {
        return;
      }

      if (withAnimation) {
        startFetching();
      }
      if (resetItems) {
        setChildItems([]);
      }

      cancelToken.current?.cancel();
      cancelToken.current = createCancelToken();
      loadItems(
        {},
        {
          detachedRequest: true,
          disableTriggers: true,
          cancelToken: cancelToken.current || undefined,
          interceptors: {
            beforeRequest: (endpointId, path, queryOptions, ...other) => {
              // Ignore filter from page level. We always fetch all children.
              return [
                endpointId,
                path,
                {
                  ...(queryOptions?.$expand ? { $expand: queryOptions.$expand } : {}),
                  filter: formatEntries({
                    [parentPropertyJsonPath]: item.identifier,
                  }) as object,
                  orderBy: order && [[order.columnKey, order.isAscending ? 'asc' : 'desc']],
                  top: pageSize,
                  ...extraOptions,
                },
                ...other,
              ];
            },
          },
        }
      )
        .then(({ items, responses }) => {
          if (responses?.[0]?.totalItems) {
            setTotalItems(responses?.[0]?.totalItems);
          }
          setChildItems(
            !appendLoadedItems
              ? items
              : (currentItems): IDataItem[] => {
                  if (!currentItems) {
                    return items;
                  }
                  return [...currentItems, ...items];
                }
          );
          onNewChildItemsLoaded?.(items);
        })
        .finally(() => {
          finishFetching();
        });
    },
    [loadItems, parentPropertyJsonPath, item.identifier, order, pageSize, startFetching, onNewChildItemsLoaded, finishFetching]
  );

  const reloadChildItems = useCallback(() => {
    if (isExpanded || childItems.length > 0) {
      loadChildItems(false, false, false, { top: pageSize <= childItems.length ? childItems.length + 1 : pageSize });
    }
  }, [loadChildItems, childItems.length, pageSize, isExpanded]);
  const mountedReloadEventHandler = useRef<typeof reloadChildItems>();
  useEffect(() => {
    if (mountedReloadEventHandler.current) {
      reloadEmitter?.off('reload', mountedReloadEventHandler.current);
    }

    // Handle reload
    mountedReloadEventHandler.current = reloadChildItems;
    reloadEmitter?.on('reload', mountedReloadEventHandler.current);

    return (): void => {
      // Unmount
      if (mountedReloadEventHandler.current) {
        reloadEmitter?.off('reload', mountedReloadEventHandler.current);
      }
      reloadEmitter?.off('reload', reloadChildItems);
    };
  }, [reloadEmitter, reloadChildItems]);

  // Lazy loading
  useEffect(() => {
    if (!lazyLoadingMarkerInView || !lazyLoadingEnabled || isFetching || childItems.length === 0) {
      return;
    }

    loadChildItems(true, false, true, {
      skip: childItems.length,
    });
  });

  const preventOtherEvents = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onExpandIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);

      if (isExpanded) {
        closeExpandedRow();
        return;
      }

      openExpandedRow();
      if (childItems.length === 0) {
        loadChildItems();
      }
    },
    [childItems.length, closeExpandedRow, isExpanded, loadChildItems, openExpandedRow, preventOtherEvents]
  );

  const onAddIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);
      onAddClick?.();
    },
    [preventOtherEvents, onAddClick]
  );

  const onExpandColumnRender = useCallback(() => {
    return (
      <div className={styles.expandButtonWrapper} onDoubleClick={preventOtherEvents} onMouseDown={preventOtherEvents}>
        {!!onAddClick && (
          <HoverTooltip
            content={t('common.addChild')}
            calloutProps={{
              gapSpace: 0,
              beakWidth: 10,
              directionalHint: DirectionalHint.topCenter,
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'CalculatorAddition',
                style: {
                  color: darkMode ? '#fefefe' : '#383838',
                },
              }}
              styles={{
                rootHovered: {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={onAddIconClick}
            />
          </HoverTooltip>
        )}
        {totalItems > 0 && (
          <HoverTooltip
            content={t(isExpanded ? 'common.hideChildren' : 'common.expandChildren')}
            calloutProps={{
              gapSpace: 0,
              beakWidth: 10,
              directionalHint: DirectionalHint.topCenter,
            }}
          >
            <IconButton
              iconProps={{
                iconName: 'ChevronDown',
                style: {
                  color: darkMode ? '#fefefe' : '#383838',
                },
                className: isExpanded ? '' : AnimationClassNames.rotateN90deg,
              }}
              styles={{
                rootHovered: {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={onExpandIconClick}
            />
          </HoverTooltip>
        )}
      </div>
    );
  }, [preventOtherEvents, onAddClick, t, darkMode, onAddIconClick, totalItems, isExpanded, onExpandIconClick]);

  const isItemRelatedToTableRow = useCallback(
    (childItem: IDataItem) => {
      if (!parentPropertyJsonPath) return false;
      const parentIdentifiers = getObjectValuesByPath(parentPropertyJsonPath, childItem);
      return (Array.isArray(parentIdentifiers) ? parentIdentifiers : [parentIdentifiers]).includes(
        item.__originalItem?.identifier || item.identifier
      );
    },
    [item, parentPropertyJsonPath]
  );

  useLiveUpdates(
    loadItems,
    childItems,
    setChildItems,
    (newState) => {
      const originalItem = item.__originalItem || item;
      if (typeof newState === 'number') {
        originalItem.__childrenCount = newState;
        setTotalItems(newState);
      } else {
        setTotalItems((prevTotalItems) => {
          const newTotalItems = newState(prevTotalItems);
          originalItem.__childrenCount = newTotalItems;
          return newTotalItems;
        });
      }
    },
    totalItems,
    page,
    isItemRelatedToTableRow,
    true
  );

  const columnsToRender = useMemo(() => {
    if (!parentPropertyJsonPath || !loadItems) {
      return columns;
    }

    let firstColumn: IColumn;

    return columns.map((column) => {
      if (column.key === itemExpandColumnKey) {
        return {
          ...column,
          onRender: onExpandColumnRender,
        };
      }

      if (!firstColumn && column.key && !column.key.startsWith('__file-')) {
        firstColumn = { ...column };

        const oldOnRender = firstColumn.onRender;
        firstColumn.onRender = (...args): JSX.Element | null => {
          if (!firstColumn.fieldName) {
            return null;
          }

          const child = oldOnRender ? oldOnRender?.(...args) : args[0][firstColumn.fieldName] || null;
          return (
            <div
              style={{
                paddingLeft: level * 15,
              }}
            >
              {child}
            </div>
          );
        };

        return firstColumn;
      }

      return column;
    });
  }, [parentPropertyJsonPath, columns, onExpandColumnRender, level, loadItems]);

  useEffect(() => {
    closeExpandedRow();
    setChildItems([]);
    setTotalItems(childrenCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowId, item?.identifier, order]);

  const onLongPress = useCallback((): void => {
    // For some reason LongPress library did not provide any event for handler
    // to make possible to open context menu it is passed as empty function
    // TODO: Fix
  }, []);

  const cardMargin = useCardWidth();

  return (
    <div
      className={classNames({
        [styles.rowWrapper]: true,
        [styles.expandableRow]: !!parentPropertyJsonPath,
        [styles.goldGradient]: !powerUser,
        [styles.goldGradientHighlightedLight]: !powerUser && paintRow && !darkMode,
        [styles.goldGradientHighlightedDark]: !powerUser && paintRow && darkMode,
        [styles.goldGradientVisible]: !powerUser && detailedRowProps.selection.isIndexSelected(detailedRowProps.itemIndex),
      })}
      style={{ margin: powerUser ? undefined : '10px 0', marginLeft: powerUser ? undefined : `${level !== 0 ? cardMargin : 0}px` }}
    >
      <div>
        <LongPress time={1000} onLongPress={onLongPress}>
          <div data-selection-invoke={fireInvokeEvent} id={rowId} onDoubleClick={onDoubleClick} onContextMenu={onContextMenu}>
            <DetailsRow
              {...(detailedRowProps as IDetailsRowBaseProps)}
              columns={columnsToRender}
              styles={{ root: { borderRadius: powerUser ? undefined : '12px' } }}
              className={classes}
              onRenderField={powerUser ? undefined : (props, defaultRender) => simplifiedRowRenderer(props, defaultRender, level, page)}
            />
          </div>
        </LongPress>
      </div>
      {isExpanded && (childItems.length > 0 || isFetching) && <ParentingIndicator level={level} />}

      <div onClick={preventOtherEvents} onDoubleClick={preventOtherEvents} data-selection-disabled={true}>
        {isExpanded && (childItems.length > 0 || isFetching) && (
          <ColorizeFirstRowContext.Provider value={!paintRow}>
            <div style={{ marginBottom: 5 }}>{childTableRender?.(columns, rowId || 'item', childItems, isFetching, totalItems)}</div>
            {lazyLoadingEnabled && <div ref={lazyLoadingMarker} />}
          </ColorizeFirstRowContext.Provider>
        )}
      </div>
    </div>
  );
};

export default React.memo(TableRow, _.isEqual);
