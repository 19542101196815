import { Icon, IconButton, MessageBar, MessageBarType, ProgressIndicator } from '@fluentui/react';
import { CancelTokenSource } from '@cpa/base-http';
import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { getFileExtensionFromURL, getFilenameFromURL } from '@cpa/base-core/helpers';

import styles from './FilesInfo.module.scss';
import { IImageEditorDialogRef, ImageEditorDialog } from './ImageEditorDialog/ImageEditorDialog';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';

export interface IUploadTask {
  id: string;
  cancelToken: CancelTokenSource;
  file: File;
  progress?: number;
  error?: string;
}

interface IFilesInfoProps {
  files: string[];
  uploadTasks: IUploadTask[];
  onFileRemove: (value: string) => unknown;
  onFileEdited: (url: string | undefined, newUrl: string) => unknown;
  onUploadTaskRemove: (id: string) => unknown;
}

export interface IFileToDisplay {
  name: string;
  url?: string;
  isImage?: boolean;
  uploadTask?: IUploadTask;
}

const FilesInfo: React.FC<IFilesInfoProps> = ({ files, uploadTasks, onFileRemove, onUploadTaskRemove, onFileEdited }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const imageEditorDialog = useRef<IImageEditorDialogRef>(null);

  const showImageEditor = useCallback(
    (fileToEdit: IFileToDisplay) => (): void => {
      if (fileToEdit.url) {
        imageEditorDialog.current?.openDialog(fileToEdit.name, fileToEdit.url);
      }
    },
    []
  );

  const filesToDisplay = useMemo<IFileToDisplay[]>(() => {
    return [
      ...files.map((url) => ({
        name: getFilenameFromURL(url),
        isImage: ['jpeg', 'jpg', 'png', 'gif'].includes(getFileExtensionFromURL(url)),
        url: url,
      })),
      ...uploadTasks.map((uploadTask) => ({
        name: uploadTask.file.name,
        uploadTask: uploadTask,
      })),
    ];
  }, [files, uploadTasks]);

  const handleRemoveClick = useCallback(
    (fileToDisplay: IFileToDisplay) => (): void => {
      if (fileToDisplay.uploadTask) {
        onUploadTaskRemove(fileToDisplay.uploadTask.id);
      } else if (fileToDisplay.url) {
        onFileRemove(fileToDisplay.url);
      }
    },
    [onFileRemove, onUploadTaskRemove]
  );

  if (filesToDisplay.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {filesToDisplay.map((fileToDisplay, key) => {
        let fileIcon: string = 'FileASPX';
        switch (true) {
          case !!fileToDisplay.uploadTask?.error: {
            fileIcon = 'FileBug';
            break;
          }
          case !!fileToDisplay.uploadTask: {
            fileIcon = 'OpenFile';
            break;
          }
        }

        return (
          <div className={classNames(styles.fileWrapper, { [styles.fileWrapperDark]: darkMode })} key={key}>
            <div className={styles.iconWrapper}>
              <Icon iconName={fileIcon} className={styles.icon} />
            </div>
            <div className={styles.fileDetails}>
              <div className={styles.fileActions}>
                {fileToDisplay.url ? (
                  <a href={fileToDisplay.url} target="_blank" rel="noreferrer" className={styles.name}>
                    {fileToDisplay.name}
                  </a>
                ) : (
                  <span className={styles.name}>{fileToDisplay.name}</span>
                )}

                {fileToDisplay.isImage && (
                  <IconButton iconProps={{ iconName: 'Edit' }} className={styles.edit} onClick={showImageEditor(fileToDisplay)} />
                )}

                <IconButton iconProps={{ iconName: 'Cancel' }} className={styles.close} onClick={handleRemoveClick(fileToDisplay)} />
              </div>
              {!!fileToDisplay.uploadTask &&
                (fileToDisplay.uploadTask.error ? (
                  <MessageBar messageBarType={MessageBarType.error} isMultiline={false} className={styles.error}>
                    {fileToDisplay.uploadTask.error}
                  </MessageBar>
                ) : (
                  <ProgressIndicator
                    description={`${(fileToDisplay.uploadTask.progress || 0).toFixed(0)}%`}
                    percentComplete={fileToDisplay.uploadTask.progress && fileToDisplay.uploadTask.progress / 100}
                  />
                ))}
            </div>
          </div>
        );
      })}

      <ImageEditorDialog ref={imageEditorDialog} onSubmit={onFileEdited} />
    </div>
  );
};

export default FilesInfo;
