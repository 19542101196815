import React, { useMemo } from 'react';
import { Schemas } from '@cp/base-types';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';

import { DataCallbackContext } from '../../../../components/ScrollingContent/ScrollingContent';
import { ScreenTypesConfiguration } from '../../../../mapping';

import styles from './Widgets.module.scss';

interface IWidgetsProps {
  widgets: Schemas.CpaPage[][];
  setLoadedItemsMap: React.Dispatch<React.SetStateAction<Record<string, IDataItem[]>>>;
  setDataUpdate: React.Dispatch<React.SetStateAction<number>>;
  renderShowMoreText?: boolean;
  updatedWidgetView?: boolean;
}

const Widgets: React.FC<IWidgetsProps> = ({ widgets, setLoadedItemsMap, setDataUpdate, renderShowMoreText = false, updatedWidgetView = false }) => {
  const colorizedRows = useSelector((state: IGlobalState) => state.settings.zebraRows);
  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const dataUpdateHandlers = useMemo(() => {
    return {
      onDataUpdated: (page: Schemas.CpaPage, items: IDataItem[]): void => {
        setLoadedItemsMap((currentItemsMap) => ({ ...currentItemsMap, [page.identifier!]: items }));
      },
      onDataModification: (): void => {
        setDataUpdate(Date.now());
      },
    };
  }, [setDataUpdate, setLoadedItemsMap]);

  return (
    <div className={styles.widgets}>
      {widgets.map((pagesPart: Schemas.CpaPage[], i: number) => {
        const isStaticContent =
          pagesPart.length === 1 &&
          pagesPart[0]?.customTemplate?.identifier &&
          ScreenTypesConfiguration[pagesPart[0].customTemplate.identifier] === ScreenTypesConfiguration.StaticContent;

        return (
          <div
            key={i}
            className={classNames(
              { [styles.desktopRow]: desktopOnly },
              { [styles.mobileRow]: !desktopOnly },
              { [styles.darkRow]: colorizedRows && i % 2 === 1 },
              { [styles.staticContentRow]: isStaticContent }
            )}
          >
            <DataCallbackContext.Provider value={dataUpdateHandlers}>
              {pagesPart.map((widget: Schemas.CpaPage, pageIndex: number) => {
                const Component =
                  !!widget.customTemplate?.identifier && !!ScreenTypesConfiguration[widget.customTemplate.identifier]
                    ? ScreenTypesConfiguration[widget.customTemplate.identifier]
                    : ScreenTypesConfiguration.Default;

                const showMoreLinkClickHandler =
                  renderShowMoreText && widget.path
                    ? (e: React.MouseEvent<HTMLElement>): void => {
                        e?.preventDefault();
                        dispatch(push(widget.path as string));
                      }
                    : undefined;

                return (
                  <Component
                    key={widget.identifier}
                    page={widget}
                    isWidget={true}
                    animationDelay={desktopOnly ? 250 * pagesPart.length - (pageIndex + 1) * 250 : 0}
                    genericComponentProps={
                      updatedWidgetView
                        ? {
                            widgetSettings: {
                              hideIcon: !!showMoreLinkClickHandler,
                              subtitleClassName: styles.widgetSubtitle,
                              footerRenderer: () => {
                                if (showMoreLinkClickHandler) {
                                  return (
                                    <div className={styles.moreLinkWrapper}>
                                      <a href={widget.path} className={styles.moreLink} onClick={showMoreLinkClickHandler}>
                                        {t('common.more')} &#x276F;
                                      </a>
                                    </div>
                                  );
                                }
                                return null;
                              },
                            },
                          }
                        : {}
                    }
                  />
                );
              })}
            </DataCallbackContext.Provider>
          </div>
        );
      })}
    </div>
  );
};

export default Widgets;
