import { defaultParser } from '../odata-v4-parser';
import { Visitor } from './visitor';
export function createQuery(odataQuery) {
    if (odataQuery == '' || !odataQuery)
        odataQuery = null;
    const ast = (typeof odataQuery == 'string' ? defaultParser.query(odataQuery) : odataQuery);
    return new Visitor().Visit(ast);
}
export function createFilter(odataFilter) {
    if (odataFilter == '' || !odataFilter)
        odataFilter = null;
    const context = { query: {} };
    const ast = (typeof odataFilter == 'string' ? defaultParser.filter(odataFilter) : odataFilter);
    new Visitor().Visit(ast, context);
    return context.query;
}
