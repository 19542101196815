import React, { useCallback } from 'react';
import { IPivotItemProps, Pivot, PivotItem } from '@fluentui/react';
import ReactCountryFlag from 'react-country-flag';

import LocalizationEditorTabsWrapper from '../LocalizationEditorTabsWrapper/LocalizationEditorTabsWrapper';
import { IFieldLocalizationInfo } from '../helpers/getLocalizedFields';

import styles from './LocalizationEditorTabs.module.scss';

export interface LocalizationEditorTabsProps {
  localizations: IFieldLocalizationInfo[];

  selectedLanguage: string;
  onSelectedLanguageChanged: (language: string) => void;
}

const LocalizationEditorTabs: React.FC<LocalizationEditorTabsProps> = ({ localizations, selectedLanguage, onSelectedLanguageChanged }) => {
  const handleItemRender = useCallback((link: IPivotItemProps): JSX.Element | null => {
    return (
      <span className={styles.tab} data-testid={`LocalizationEditor__tab__${link.itemIcon!}`}>
        <ReactCountryFlag style={{ width: 18, height: 18, backgroundSize: 'contain' }} countryCode={link.itemIcon!} svg={true} />

        {link.itemType === 'baseLanguage' && (
          <span data-testid={`LocalizationEditor__tab__${link.itemIcon!}__base`} className={styles.baseLanguage}></span>
        )}
        {link.itemType === 'set' && <span data-testid={`LocalizationEditor__tab__${link.itemIcon!}__set`} className={styles.set}></span>}
        {link.itemType === 'unset' && <span data-testid={`LocalizationEditor__tab__${link.itemIcon!}__unset`} className={styles.unset}></span>}
      </span>
    );
  }, []);

  const handleItemSelect = useCallback(
    (e: PivotItem) => {
      onSelectedLanguageChanged(e.props.itemKey!);
    },
    [onSelectedLanguageChanged]
  );

  return (
    <LocalizationEditorTabsWrapper>
      <Pivot defaultSelectedKey={selectedLanguage} onLinkClick={handleItemSelect}>
        {localizations.map((localization) => (
          <PivotItem
            key={localization.language.identifier}
            itemKey={localization.language.identifier}
            itemIcon={localization.language.icon}
            itemType={localization.isBaseLanguage ? 'baseLanguage' : localization.isSet ? 'set' : 'unset'}
            onRenderItemLink={handleItemRender}
          ></PivotItem>
        ))}
      </Pivot>
    </LocalizationEditorTabsWrapper>
  );
};

export default LocalizationEditorTabs;
