import { IJSONSchema, JsonObj, Url } from '@cp/base-types';
import { ODataPropsFilter } from '@cp/base-utils';

export type Filter = object[] | object | string;

export const getParsedFilter = (
  filter?: ODataPropsFilter | null,
  schema?: IJSONSchema | null,
  excludeNull: boolean = false
): [Record<string, object | string>, string[]] => {
  const reducer: JsonObj = {};

  function parseFilterToRawObject(internalFilter: Filter): void {
    if (Array.isArray(internalFilter)) {
      // Iterate filter array
      internalFilter.forEach((item) => parseFilterToRawObject(item));
    } else if (internalFilter && typeof internalFilter === 'object') {
      Object.entries(internalFilter).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          // We go deeper in case of nested object in filter
          // but only in case of or/and key (we ignore such keys as 'ne')
          if (['or', 'and'].includes(key)) {
            parseFilterToRawObject(value);
          }
          return;
        }

        let nestedObject = reducer;
        key.split('/').forEach((keyPart, i, arr) => {
          const isLastKeyPart = i === arr.length - 1;
          if (isLastKeyPart) {
            if (!value && excludeNull) return;
            if (arr.length === 1 && schema?.properties?.[keyPart]?.type === 'array') {
              nestedObject[keyPart] ??= [decodeURIComponent(value)];
            } else {
              nestedObject[keyPart] ??= decodeURIComponent(value);
            }
          } else {
            // If key on object not defined, set default value for nested object based on schema
            nestedObject[keyPart] ??= schema?.properties?.[keyPart]?.type === 'array' ? [{}] : {};
          }

          nestedObject = Array.isArray(nestedObject[keyPart]) ? (nestedObject![keyPart] as JsonObj[])[0] : (nestedObject[keyPart] as JsonObj);
        });
      });
    }
  }

  parseFilterToRawObject(filter as Filter);

  return [reducer as Record<string, object | string>, Object.keys(reducer)];
};

const getSchemaUIOptionsRecursive = (
  schema: IJSONSchema,
  accumulator: Record<string, Record<string, boolean>>,
  key: string | null
): Record<string, Record<string, boolean>> => {
  if (!schema?.properties) {
    return accumulator;
  }

  return Object.entries(schema.properties).reduce((acc, [propertyKey, propertyValue]) => {
    if ('cp_ui' in propertyValue && propertyValue.cp_ui) {
      const generatedKey = key ? `${key}.${propertyKey}` : propertyKey;
      Object.keys(propertyValue.cp_ui).forEach((option: keyof typeof propertyValue.cp_ui) => {
        if (!acc[option]) {
          acc[option] = {};
        }
        if (propertyValue.cp_ui![option]) {
          acc[option][generatedKey] = true;
        }
      });
    }

    if (propertyValue.properties) {
      getSchemaUIOptionsRecursive(propertyValue, accumulator, key ? `${key}.${propertyKey}` : propertyKey);
    }

    return acc;
  }, accumulator);
};

export const getSchemaUIOptions = (schema: IJSONSchema | null): Partial<Record<keyof Extract<IJSONSchema['cp_ui'], {}>, string[]>> => {
  if (!schema) {
    return {};
  }

  const optionsMap = getSchemaUIOptionsRecursive(schema, {}, null);
  return Object.keys(optionsMap).reduce(
    (acc, optionKey) => ({
      ...acc,
      [optionKey]: Object.keys(optionsMap[optionKey]),
    }),
    {}
  );
};

export function removeMajorCollectionQueryFromTypeUrl(typeUrl: string): string {
  // Decode type url and parse to url object
  const parsedTypeUrl = Url.parse(typeUrl);

  // Delete 'cp_collection' param if value is 'latestMajor'
  if (parsedTypeUrl.query['cp_collection'] === 'latestMajor') {
    delete parsedTypeUrl.query['cp_collection'];
  }

  return parsedTypeUrl.toString();
}
