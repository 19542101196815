const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/QuotePreparation',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FQuotePreparation' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Quote Preparation',
    'properties': {
        'agent': {
            'title': 'Agent',
            'description': 'An organization such as a school, NGO, corporation, club, etc.',
            '$id': 'http://schema.org/Organization',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FOrganization' },
                { 'href': 'http://schema.org/agent', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'format': 'email',
                    'transform': ['toLowerCase', 'trim'],
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'required': ['identifier', 'name'],
        },
        'customer': {
            'title': 'Customer',
            'description': 'An organization such as a school, NGO, corporation, club, etc.',
            '$id': 'http://schema.org/Organization',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FOrganization' },
                { 'href': 'http://schema.org/customer', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'required': ['identifier', 'name'],
        },
        'lastReviewed': {
            'title': 'Last Reviewed',
            'description': 'Date on which the content on this web page was last reviewed for accuracy and/or completeness.',
            'type': 'string',
            'format': 'date',
            'links': [{ 'href': 'http://schema.org/lastReviewed', 'rel': 'canonical' }],
        },
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date',
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'sender': {
            'title': 'Sender',
            'description': 'A sub property of participant. The participant who is at the sending end of the action.',
            'type': 'string',
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'links': [{ 'href': 'http://schema.org/sender', 'rel': 'canonical' }],
        },
        'deliveryDate': {
            'title': 'Delivery Date',
            'type': 'string',
            'format': 'date',
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/deliveryDate', 'rel': 'canonical' }],
        },
        'quotePreparationItems': {
            'title': 'Quote Preparation Items',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/QuotePreparationItem',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FQuotePreparationItem' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Quote Preparation Item',
                'properties': {
                    'eligibleQuantity': {
                        'title': 'Eligible Quantity',
                        'description': ' A point value or interval for product characteristics and other purposes.',
                        '$id': 'http://schema.org/QuantitativeValue',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FQuantitativeValue' },
                            { 'href': 'http://schema.org/eligibleQuantity', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': {
                            'unitCode': {
                                'title': 'Unit Code',
                                'description': '<div>Type or select here the unit of measure.</div>',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUnitOfMeasure' },
                                    { 'href': 'http://schema.org/unitCode', 'rel': 'canonical' },
                                ],
                            },
                            'value': {
                                'title': 'Value',
                                'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                'type': 'number',
                                'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                            },
                        },
                    },
                    'description': {
                        'title': 'Description',
                        'description': 'A description of the item.',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
                    },
                },
            },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/quotePreparationItems', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
    },
};
export const QuotePreparationSchema = Schema;
