const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpTypePermission',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypePermission' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Type Permission',
    'properties': {
        'cpTypeUrl': {
            'title': 'Cp Type Url',
            'description': '<div>The identifier of the type that the permission controls access to.</div>',
            'type': 'string',
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
            ],
            'cp_ui': { 'sortOrderForm': 31 },
        },
        'permissionOperationTypes': {
            'title': 'Permission Operation Types',
            'description': '<div>The operations that are allowed on the type.</div>\n<div>Operations are create/delete/read/update.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionOperationType',
                'type': 'string',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionOperationType' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cp Permission Operation Type',
                'anyOf': [
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Create'],
                        'enumNames': ['Create'],
                        'title': 'Create',
                        'cp_enumIcons': ['CalculatorAddition'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Delete'],
                        'enumNames': ['Delete'],
                        'title': 'Delete',
                        'cp_enumIcons': ['Delete'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Read'],
                        'enumNames': ['Read'],
                        'title': 'Read',
                        'cp_enumIcons': ['View'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Update'],
                        'enumNames': ['Update'],
                        'title': 'Update',
                        'cp_enumIcons': ['Edit'],
                    },
                ],
                'format': 'cp:TileOption',
            },
            'uniqueItems': true,
            'minItems': 1,
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/permissionOperationTypes', 'rel': 'canonical' }],
        },
        'permissionTarget': {
            'title': 'Permission Target',
            'description': '<div>Role-based and user-based permission targets can be defined. </div>\n<div>The permission rule is triggered for a user if the user has a matching role or user name.</div>',
            'anyOf': [
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Permission Target Role',
                    'properties': {
                        'role': {
                            'title': 'Role',
                            'description': '<div>The role the permission policy is defined for. </div>',
                            'type': 'string',
                            'links': [
                                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                            ],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['role'],
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Permission Target User',
                    'properties': {
                        'email': {
                            'title': 'Email',
                            'description': '<div>The name of the user the permission policy is adressing.</div>',
                            'type': 'string',
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['email'],
                },
            ],
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/permissionTarget', 'rel': 'canonical' }],
        },
        'permissionType': {
            'title': 'Permission Type',
            'description': '<div>If a user matches the permission target, should access be allowed or denied. </div>\n<div> </div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/permissionType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': ['http://platform.cosmoconsult.com/ontology/Allow', 'http://platform.cosmoconsult.com/ontology/Deny'],
            'enumNames': ['Allow', 'Deny'],
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 38 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Remarks or notes regarding this permission</div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
    },
    'required': ['cpTypeUrl', 'permissionOperationTypes', 'permissionType', 'permissionTarget'],
};
export const CpTypePermissionSchema = Schema;
