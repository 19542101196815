import { IJSONSchema } from '@cp/base-types';
import { Rating } from '@fluentui/react';
import { WidgetProps } from '@rjsf/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import TitleField, { TitleFieldType } from '../../../TitleField/TitleField';

interface IRatingProps extends WidgetProps {
  schema: IJSONSchema;
}

const RatingField: React.FC<IRatingProps> = ({ required, readonly, disabled, registry, label, value, onChange, schema }) => {
  const [t] = useTranslation();
  const onRatingChange = useCallback(
    (event: React.FormEvent<HTMLElement>, rating?: number) => {
      onChange(rating);
    },
    [onChange]
  );

  return (
    <>
      <TitleField
        type={TitleFieldType.Primitive}
        title={t('common.yourRating')}
        localizable={schema?.cp_localizable}
        registry={registry}
        schema={schema}
      />
      <Rating
        allowZeroStars={true}
        max={5}
        rating={value || 0}
        onChange={onRatingChange}
        disabled={disabled || (schema as { disabled: boolean }).disabled}
        readOnly={readonly || (schema as { readonly: boolean }).readonly}
      />
    </>
  );
};

export default RatingField;
