import React, { ElementType } from 'react';

import RebrushedDashboard from './RebrushedDashboard';

function withoutAnimation(WrappedComponent: ElementType): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} suspendAnimation={true} />;
  };
}

export default withoutAnimation(RebrushedDashboard);
