import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { DataItemProperties, IJSONSchema, Schemas } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { useQuery } from '@cpa/base-core/hooks';
import { gaStatus, sortWidgets } from '@cpa/base-core/helpers';
import { BaseApi, IDataItem, IScreenProps } from '@cpa/base-core/types';
import { apm } from '@elastic/apm-rum';
import { formatEntries } from '@cp/base-utils';

import Animation from '../../components/Animation/Animation';
import { DataCallbackContext, IDataCallbackContext } from '../../components/ScrollingContent/ScrollingContent';
import Widget from '../../components/Widget/Widget';
import { ScreenTypesConfiguration } from '../../mapping';

import styles from './SearchResults.module.scss';

type SearchModule = {
  isODataSupportedByEndpoint: boolean;
} & Schemas.CpaPage;

export interface IModulePayload {
  searchMessage: string | null;
  isLoading: boolean;
  items: IDataItem[];
  totalItems: number | null;
  schema: IJSONSchema | null;
  filtered?: boolean;
  error?: string;
}

const SearchResults: React.FC<IScreenProps> = ({ page }) => {
  const disableAnimation = useSelector((store: IGlobalState) => store.settings.disableAnimation);
  const pages = useSelector((state: IGlobalState) => state.app.pages);
  const dataEndpoints = useSelector((state: IGlobalState) => state.app.dataEndpoints);

  const { query, modules } = useQuery();

  const [t] = useTranslation();

  const matchedPages: Schemas.CpaPage[] = useMemo(() => {
    const modulesList = modules?.split(',') || [];
    return pages.filter((p) => p.searchable && p.dataUrl && modulesList.includes(p.identifier!));
  }, [modules, pages]);

  const searchModules: SearchModule[] = useMemo(() => {
    return sortWidgets<SearchModule>(
      matchedPages.map(
        (module): SearchModule =>
          ({
            ...module,
            chart: undefined,
            isODataSupportedByEndpoint:
              !!module.dataEndpoint?.identifier && dataEndpoints.get(module.dataEndpoint?.identifier)?.dataType === BaseApi.DataService,
          } as SearchModule)
      )
    );
  }, [dataEndpoints, matchedPages]);

  const modulesWithResult = useRef<SearchModule[]>([]);
  const modulesWithoutResult = useRef<SearchModule[]>(searchModules);
  const [, triggerResultsUpdate] = useState<null | object>(null);

  useEffect(() => {
    modulesWithResult.current = [];
    modulesWithoutResult.current = searchModules;
    triggerResultsUpdate({});
  }, [query, searchModules]);

  const onFilteredDataUpdated = useCallback((page: SearchModule, items: IDataItem[]): void => {
    if (items.length > 0 && modulesWithoutResult.current.find((m) => m.identifier === page.identifier)) {
      modulesWithoutResult.current = modulesWithoutResult.current.filter((m) => m.identifier !== page.identifier);
      modulesWithResult.current = sortWidgets([...modulesWithResult.current, page]);
      triggerResultsUpdate({});
    }
    // reverse operation if callback fired twice with contradictive results
    if (items.length == 0 && modulesWithResult.current.find((m) => m.identifier === page.identifier)) {
      modulesWithResult.current = modulesWithResult.current.filter((m) => m.identifier !== page.identifier);
      modulesWithoutResult.current = sortWidgets([...modulesWithoutResult.current, page]);
      triggerResultsUpdate({});
    }
  }, []);

  const dataCallbackContext = useMemo<IDataCallbackContext>(() => {
    return { onFilteredDataUpdated };
  }, [onFilteredDataUpdated]);

  // Format filter value, do not encode value here, otherwise it gets double encoded and search results are not found
  const filterValue = useMemo(() => formatEntries({ [DataItemProperties.SEARCH_TEXT_KEY]: query }, { isLoose: true }) as object, [query]);

  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });

  useEffect(() => {
    if (searchModules.length === 0) {
      return;
    }

    apm.startTransaction(`${query} in ${searchModules.map((module) => module.identifier).join(', ')}`, 'global-search', {
      managed: true,
    });

    if (gaStatus.initialized) {
      ReactGA.event({
        category: 'Search',
        action: 'Search Query',
        label: `${query} in ${searchModules.map((module) => module.identifier).join(', ')}`,
      });
    }
  }, [searchModules, query]);

  const inText = !searchModules.length ? '' : t('search.results.in');

  return (
    <div className={styles.resultsWrapper}>
      <Widget
        title={page.name}
        subtitle={`'${query || '-'}' ${inText} ${searchModules.map((module) => module.name).join(', ')}`}
        className={styles.resultsHeader}
      />
      <DataCallbackContext.Provider value={dataCallbackContext}>
        <Animation duration={desktopOnly ? 800 : 1000} disable={disableAnimation} delay={0}>
          {[...modulesWithResult.current, ...modulesWithoutResult.current].map((module) => {
            const Component =
              !!module.customTemplate?.identifier && !!ScreenTypesConfiguration[module.customTemplate.identifier]
                ? ScreenTypesConfiguration[module.customTemplate.identifier]
                : ScreenTypesConfiguration.Default;

            return (
              <Component
                key={`${module.identifier}`}
                page={module}
                isWidget={true}
                externalODataFilter={module.isODataSupportedByEndpoint ? filterValue : undefined}
                initialFilterValue={query}
                withoutAnimation={true}
                resetFilterOnRefresh={true}
              />
            );
          })}
        </Animation>
      </DataCallbackContext.Provider>
    </div>
  );
};

export default SearchResults;
