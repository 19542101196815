import * as _ from 'lodash';
export function copyMeta(from, to) {
    const keys = Object.keys(from);
    const metaKeys = Object.getOwnPropertyNames(from).filter((name) => !keys.includes(name));
    for (const key of metaKeys) {
        Object.defineProperty(to, key, {
            enumerable: false,
            configurable: false,
            writable: false,
            value: from[key],
        });
    }
}
export function cloneDeepWithMetadata(o) {
    const cloned = _.cloneDeep(o);
    if (typeof o === 'object' && o && typeof cloned === 'object' && cloned) {
        if (Array.isArray(o) && Array.isArray(cloned)) {
            for (const item of o) {
                const clonedItem = cloned[o.indexOf(item)];
                if (typeof item === 'object' && item && typeof clonedItem === 'object' && clonedItem) {
                    copyMeta(item, clonedItem);
                }
            }
        }
        else {
            copyMeta(o, cloned);
        }
    }
    return cloned;
}
