const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpSharedItem',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpSharedItem' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Shared Item',
    'properties': {
        'dateCreated': {
            'title': 'Date Created',
            'description': 'The date on which the CreativeWork was created or the item was added to a DataFeed.',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'links': [{ 'href': 'http://schema.org/dateCreated', 'rel': 'canonical' }],
        },
        'itemData': {
            'title': 'Item Data',
            '$id': 'http://platform.cosmoconsult.com/ontology/AnyThing',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAnyThing' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/itemData', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': true,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
    },
    'required': ['itemData'],
};
export const CpSharedItemSchema = Schema;
