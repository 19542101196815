import {
  IButtonStyles,
  IChoiceGroupStyles,
  IDialogContentProps,
  IDialogContentStyles,
  IDialogStyles,
  IModalProps,
  ITextFieldStyles,
} from '@fluentui/react';
import React from 'react';
import { IJSONSchema, Schemas } from '@cp/base-types';

import { IBase } from './base';

export interface ICompany {
  company: string;
  accounts: {
    type: string;
    no: string;
    relContNo: string;
  }[];
}

export interface IUserAgreement {
  title: string;
  fieldName: string;
  disabled?: boolean;
  isChecked?: ((currentValue: boolean) => boolean) | boolean;
  requiredConfigurationPath?: string;
}

export interface IDarkMode {
  darkMode?: boolean;
}

export interface IMenuLink {
  key: string;
  path?: string;
  text: string;
  icon?: string;
  divider?: boolean;
  parentMenuItemKey?: IBase;
  sortOrderMenu?: number;
  externalUrl?: string;
  cpType?: string;
  actions?: Schemas.CpaPage['actions'];
  dataEndpointIdentifier?: string;
  pageIdentifier?: string;
}

export interface IMenuItem {
  key: string;
  name: string;
  url?: string;
  icon?: string;
  links?: IMenuItem[];
  expanded?: boolean;
  divider?: boolean;
  external?: boolean;
  cpType?: string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

export enum GlobalDialogType {
  CONFIRMATION,
  MESSAGE,
  INPUT,
  OPTIONS,
  ITEM_SELECTION,
  FORM,
  TABLE,
  CUSTOM,
}

export enum GlobalDrawerType {
  DIFFERENCE,
  BATCHED_DIFFERENCE,
}

export interface IGlobalDialogStyles {
  dialogContent?: IDialogContentStyles;
  dialog?: IDialogStyles;
  primaryButton?: IButtonStyles;
  secondaryButton?: IButtonStyles;
  textField?: ITextFieldStyles;
  choiceGroup?: IChoiceGroupStyles;
}

export interface IGlobalDrawerState {
  type: GlobalDrawerType;
  drawerOptions: Record<string, unknown>;
  isMaximized: boolean;
}

export interface IGlobalDialogState {
  type: GlobalDialogType;
  message: string;
  primaryOption: boolean;
  title: string | null;
  dialogContentProps: IDialogContentProps | null;
  modalProps: IModalProps | null;
  primaryButtonText: string | null;
  secondaryButtonText: string | null;
  closeOnClickOutside: boolean;
  closeOnAction: boolean;
  onClose: (result: unknown) => void;
  dialogTypeOptions?: Record<string, unknown>;
  styles?: IGlobalDialogStyles;
  isHtml?: boolean;
  schema?: IJSONSchema;
  width?: string | number;
  isMaximizable?: boolean;
  className?: string;
}
