import { IDataItem, IEntitiesResponse, IGenericComponentProps, ILoadableDataInterceptors, ISingleItemTemplateProps } from '@cpa/base-core/types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { DefaultButton, PanelType, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { axiosDictionary, getCpFunction, putEntityToEndpoint } from '@cpa/base-core/api';
import { executeUiTrigger, formatStringOrNumber, generateReducedSchema, getAppBasePath } from '@cpa/base-core/helpers';
import notification from '@cpa/base-core/helpers/toast';
import { CpaPageActionExecutionContext, Schemas } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import { formatEntries, ODataPropsFilter, sortItemsByIdentifiers } from '@cp/base-utils';
import urlJoin from 'url-join';
import * as _ from 'lodash';

import GenericScreen from '../../../../../screens/GenericScreen/GenericScreen';
import { DataCallbackContext } from '../../../../../components/ScrollingContent/ScrollingContent';
import { useTableDragDrop } from '../../../../../components/ScrollingContent/components/Table/hooks/useTableDragDrop';
import { addAsComponent } from '../../../../row/SolutionExplorer/utils';
import ScrollablePaneContextProvider from '../../../../../components/ScrollablePaneContextProvider/ScrollablePaneContextProvider';
import SolutionSingleItemTemplate from '../../../SolutionSingleItemTemplate/SolutionSingleItemTemplate';
import Drawer from '../../../../../components/Drawer/Drawer';

import SolutionEnvironmentExceptionsDrawer from './components/SolutionEnvironmentExceptionsDrawer';
import styles from './LeftSide.module.scss';

export interface IFetchedCalculationResult {
  currency: string;
  mergedCompoundPriceSpecification?: {
    calculatedTotalPrice?: number;
  };
}

// Configurable reduced form view
export const SolutionReducedFormFields = {
  create: ['name', 'shortDescription'],
  update: ['name', 'shortDescription', 'description', 'tags', 'solutionTypeDetails'],
  table: ['name'],
  readonlyContent: ['name', 'shortDescription', 'description', 'tags', 'solutionTypeDetails'],
};

const SolutionHiddenActions = ['configuration-canvas', 'configuration-canvas-create'];

const LeftSide: React.FC<ISingleItemTemplateProps & { item: Schemas.Solution }> = ({
  page: solutionsPage,
  schema: solutionsSchema,
  item: rootSolution,
}) => {
  // We need to have the latest root solution for delayed operations like fetching children
  const latestRootSolution = useRef(rootSolution);
  latestRootSolution.current = rootSolution;

  const [t, i18n] = useTranslation();
  const [envEditingTarget, setEnvEditingTarget] = useState<Schemas.Solution | null>(null);
  const [activeSolution, setActiveSolution] = useState<Schemas.Solution>();
  const handleItemOpen = useCallback((item: IDataItem) => setActiveSolution(item as Schemas.Solution), []);
  const handleSolutionDrawerClose = useCallback(() => setActiveSolution(undefined), []);

  const handleDrawerClose = useCallback(() => setEnvEditingTarget(null), []);
  const environmentVariablesTitle: string = t('configurationCanvas.action');

  const genericComponentProps: Partial<IGenericComponentProps> = useMemo(
    () => ({
      hideSettings: true,
      hideMoreButton: true,
      scrollingContentProps: {
        disableItemSharing: true,
        widgetHeight: '53vh',
        customActions: [
          {
            button: {
              key: 'view',
              icon: 'ViewOriginal',
              title: t('common.view'),
              url: (selectedItems) => {
                return solutionsPage.path && selectedItems[0].identifier
                  ? urlJoin(getAppBasePath(), solutionsPage.path.replace('/:id', ''), encodeURIComponent(selectedItems[0].identifier))
                  : '';
              },
              condition: (selectedItems) => selectedItems.length === 1,
            },
            onClick: (items, event) => {
              event?.preventDefault();
              if (!items?.length) {
                return;
              }
              handleItemOpen(items[0]);
            },
          },
          {
            onClick: (items?: IDataItem[]) => {
              if (items?.length !== 1) {
                return;
              }
              setEnvEditingTarget(items[0] as Schemas.Solution);
            },
            button: {
              key: 'env',
              title: environmentVariablesTitle,
              icon: 'CustomList',
              condition: (selected): boolean => selected.length === 1,
            },
          },
        ],
      },
    }),
    [environmentVariablesTitle, handleItemOpen, solutionsPage.path, t]
  );

  const leftSidePage = useMemo(() => {
    return {
      identifier: solutionsPage.identifier,
      name: t('configurationCanvas.topics'),
      cpas: solutionsPage.cpas,
      dataUrl: solutionsPage.dataUrl,
      cpTypeUrl: solutionsPage.cpTypeUrl,
      dataEndpoint: { identifier: solutionsPage.dataEndpoint?.identifier },
      allowCreate: solutionsPage.allowCreate,
      allowModify: solutionsPage.allowModify,
      allowDelete: solutionsPage.allowDelete,
      path: solutionsPage.path,
      customRowTemplate: { identifier: 'SolutionExplorerConfigurationCanvas' },
      disableFilterHelper: true,
      displayRelatedPageAsDrawer: true,
      spanColumn: 'name',
      icon: 'WaitlistConfirm',
      actions: solutionsPage.actions?.filter((action) => !SolutionHiddenActions.includes(action.identifier)),
    } as Schemas.CpaPage;
  }, [
    solutionsPage.actions,
    solutionsPage.allowCreate,
    solutionsPage.allowDelete,
    solutionsPage.allowModify,
    solutionsPage.cpTypeUrl,
    solutionsPage.cpas,
    solutionsPage.dataEndpoint?.identifier,
    solutionsPage.dataUrl,
    solutionsPage.identifier,
    solutionsPage.path,
    t,
  ]);

  const leftSidePatchedSchema = useMemo(() => {
    const newSchema = generateReducedSchema(solutionsSchema, SolutionReducedFormFields);

    newSchema.cp_custom_treatments = ['DROP_AS_COMPONENT'];
    newSchema.cp_item_context = rootSolution;

    return newSchema;
  }, [solutionsSchema, rootSolution]);

  const [rootSolutionComponentsMap, rootSolutionComponentsIdentifiers]: [Record<string, string | undefined>, string[]] = useMemo(() => {
    const identifiers: { identifier: string; positionNumber: number }[] = [];

    const solutionComponentsMap = (
      (((rootSolution.solutionTypeDetails || []) as IDataItem[]).filter(
        (solutionTypeDetail) => solutionTypeDetail?._type === 'http://platform.cosmoconsult.com/ontology/Components'
      )[0]?.components || []) as IDataItem[]
    ).reduce<Record<string, string | undefined>>((acc, component) => {
      const solutionIdentifier = (component.solution as IDataItem)?.identifier;
      if (solutionIdentifier) {
        acc[solutionIdentifier as string] = component._componentId as string | undefined;

        identifiers.push({
          identifier: solutionIdentifier,
          positionNumber: 'positionNumber' in component && typeof component.positionNumber === 'number' ? component.positionNumber : 0,
        });
      }

      return acc;
    }, {});

    return [solutionComponentsMap, _.chain(identifiers).sortBy('positionNumber').map('identifier').value()];
  }, [rootSolution]);

  const leftSideFilter = useMemo<ODataPropsFilter>(
    () =>
      formatEntries({
        identifier: rootSolutionComponentsIdentifiers.length ? rootSolutionComponentsIdentifiers : '',
      }) as ODataPropsFilter,
    [rootSolutionComponentsIdentifiers]
  );

  const leftSidePrefill = useMemo(
    () => ({
      solutionType: { identifier: rootSolution.solutionType.identifier },
      parentSolution: { identifier: rootSolution.identifier },
    }),
    [rootSolution]
  );

  const [isCalculating, { setTrue: startCalculation, setFalse: stopCalculation }] = useBoolean(false);
  const [isLoadingInvestmentOverview, { setTrue: startLoadingInvestmentOverview, setFalse: stopLoadingInvestmentOverview }] = useBoolean(false);
  const [isCalculationOutdated, { setTrue: makeCalculationOutdated, setFalse: makeCalculationRelevant }] = useBoolean(true);
  const [calculationResult, setCalculationResult] = useState<IFetchedCalculationResult | null>(null);

  const uploadCalculationResult = useCallback(
    async (calculationResult: IFetchedCalculationResult): Promise<string | void> => {
      try {
        const cpFunction = await getCpFunction('solution-upload-calculation');
        const result = (await executeUiTrigger<CpaPageActionExecutionContext<Schemas.Solution>>(
          {
            event: 'Action',
            schema: solutionsSchema,
            page: solutionsPage,
            items: [rootSolution],
            setInfoMessages: (): void => {},
            reloadCurrentItems: (): Promise<void> => {
              return Promise.resolve();
            },
            additionalProps: {
              calculationResult,
            },
          },
          { sourceCode: cpFunction.sourceCode }
        )) as { success: true; url: string } | { success: false; error: string };

        if (result.success) {
          return result.url;
        } else {
          notification.error(result.error);
        }
      } catch (e) {
        notification.error(e.message);
        console.error(`Failed to execute upload function`, e.message);
      }
    },
    [rootSolution, solutionsPage, solutionsSchema]
  );

  const executeCalculation = useCallback(async () => {
    startCalculation();

    try {
      const cpFunction = await getCpFunction('solution-calculate-new');
      const result = await executeUiTrigger<CpaPageActionExecutionContext<Schemas.Solution>>(
        {
          event: 'Action',
          schema: solutionsSchema,
          page: solutionsPage,
          items: [rootSolution],
          setInfoMessages: (): void => {},
          reloadCurrentItems: (): Promise<void> => {
            return Promise.resolve();
          },
        },
        { sourceCode: cpFunction.sourceCode }
      );

      if (result) {
        setCalculationResult(result as IFetchedCalculationResult);
        makeCalculationRelevant();
        await uploadCalculationResult(result as IFetchedCalculationResult);
      }
    } catch (e) {
      makeCalculationOutdated();
      notification.error(e.message);
      console.error(`Failed to execute calculation function`, e.message);
    } finally {
      stopCalculation();
    }
  }, [
    makeCalculationOutdated,
    makeCalculationRelevant,
    rootSolution,
    solutionsPage,
    solutionsSchema,
    startCalculation,
    stopCalculation,
    uploadCalculationResult,
  ]);

  const showInvestmentOverview = useCallback(async () => {
    if (!calculationResult) {
      return;
    }

    try {
      startLoadingInvestmentOverview();
      const url = await uploadCalculationResult(calculationResult);
      if (url) {
        window.open(url, '_blank')?.focus();
      }
    } catch (e) {
      notification.error(e.message);
      console.error(`Failed to show investment overview`, e.message);
    } finally {
      stopLoadingInvestmentOverview();
    }
  }, [calculationResult, startLoadingInvestmentOverview, stopLoadingInvestmentOverview, uploadCalculationResult]);

  const dataUpdateHandlers = useMemo(() => {
    return {
      onDataModification: (): void => {
        makeCalculationOutdated();
      },
    };
  }, [makeCalculationOutdated]);

  const externalInterceptors = useMemo<ILoadableDataInterceptors>(
    () => ({
      afterRequest(response: IEntitiesResponse, detachedRequest: boolean) {
        if (detachedRequest) {
          return response;
        }

        response.entities = sortItemsByIdentifiers(response.entities, rootSolutionComponentsIdentifiers);

        response.entities.forEach((loadedItem) => {
          Object.defineProperty(loadedItem, '__component', {
            enumerable: false,
            configurable: false,
            writable: true,
            value: true,
          });

          const loadedComponentIdentifier = loadedItem.__originalItem?.identifier || loadedItem.identifier;
          if (loadedComponentIdentifier) {
            Object.defineProperty(loadedItem, '__componentId', {
              enumerable: false,
              configurable: false,
              writable: true,
              value: rootSolutionComponentsMap[loadedComponentIdentifier],
            });
          }

          Object.defineProperty(loadedItem, '__parentItem', {
            enumerable: false,
            configurable: false,
            writable: true,
            value: latestRootSolution.current,
          });
        });

        return response;
      },
    }),
    [rootSolutionComponentsMap, rootSolutionComponentsIdentifiers]
  );

  const dragDropHandlers = useTableDragDrop({
    areaKey: `LeftSide-${solutionsPage.cpTypeUrl || solutionsPage.identifier}`,
    readonly: !solutionsPage.allowModify,
    schema: solutionsSchema,
    t,
    dragDropAreaClass: styles.wrapper,
    onAreaTransfer: (item) => {
      if (!item || !solutionsPage.dataUrl) {
        return;
      }

      if (item.identifier && rootSolutionComponentsIdentifiers.includes(item.identifier)) {
        // Item is already on root level
        return;
      }

      addAsComponent(
        rootSolution,
        (h) =>
          h?.(
            async () => item,
            item,
            () => {}
          ),
        async (item, initialItem) => {
          return putEntityToEndpoint(
            solutionsPage.dataEndpoint?.identifier || axiosDictionary.appDataService,
            solutionsPage.dataUrl!,
            initialItem,
            { ...item },
            solutionsSchema
          );
        }
      );
    },
  });

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      dragDropHandlers?.onDragEnter?.(undefined, event.nativeEvent);
    },
    [dragDropHandlers]
  );
  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      dragDropHandlers?.onDragLeave?.(undefined, event.nativeEvent);
    },
    [dragDropHandlers]
  );
  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (dragDropHandlers?.canDrop?.()) {
        event.dataTransfer.dropEffect = 'copy';
      }
    },
    [dragDropHandlers]
  );
  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      dragDropHandlers?.onDragLeave?.(undefined, event.nativeEvent);
      dragDropHandlers?.onDrop?.(undefined, event.nativeEvent);
    },
    [dragDropHandlers]
  );

  return (
    <>
      <div
        className={classNames(styles.wrapper)}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <DataCallbackContext.Provider value={dataUpdateHandlers}>
          <GenericScreen
            page={leftSidePage}
            isWidget={true}
            externalODataFilter={leftSideFilter}
            withoutAnimation={true}
            resetFilterOnRefresh={true}
            externalSchema={leftSidePatchedSchema}
            externalPrefill={leftSidePrefill}
            genericComponentProps={genericComponentProps}
            externalInterceptors={externalInterceptors}
          />
        </DataCallbackContext.Provider>
        <div className={styles.calculationArea}>
          <PrimaryButton
            className={styles.calculateButton}
            iconProps={{ iconName: 'Calculator' }}
            onClick={executeCalculation}
            text={t('configurationCanvas.calculate')}
            disabled={isCalculating}
          />

          {!!calculationResult && (
            <>
              <DefaultButton
                className={styles.investmentOverviewButton}
                onClick={showInvestmentOverview}
                text={t('configurationCanvas.showInvestmentOverview')}
                disabled={isCalculating || isCalculationOutdated || isLoadingInvestmentOverview}
              />
              <div className={styles.total}>
                {t('common.total') + ': '}
                <span className={classNames(styles.number, { [styles.outdated]: isCalculationOutdated })}>
                  {formatStringOrNumber(calculationResult?.mergedCompoundPriceSpecification?.calculatedTotalPrice || 0, i18n.language)}{' '}
                  {calculationResult.currency}
                </span>
              </div>
            </>
          )}
        </div>
        <SolutionEnvironmentExceptionsDrawer
          title={environmentVariablesTitle}
          solution={envEditingTarget as Schemas.Solution & IDataItem<Schemas.Solution>}
          onClose={handleDrawerClose}
          rootSolution={rootSolution}
          solutionsSchema={solutionsSchema}
          solutionsPage={solutionsPage}
        />
      </div>
      <Drawer isOpen={!!activeSolution} onClose={handleSolutionDrawerClose} isBlocking={false} type={PanelType.custom}>
        {!!activeSolution && (
          <ScrollablePaneContextProvider>
            <SolutionSingleItemTemplate
              // @ts-ignore
              page={leftSidePage}
              item={activeSolution as IDataItem & Schemas.Solution}
              schema={solutionsSchema}
              subPages={[]}
              subPageFilter={{}}
              subPagePrefill={{}}
              disableSidePages={true}
              hideCover={true}
            />
          </ScrollablePaneContextProvider>
        )}
      </Drawer>
    </>
  );
};

export default LeftSide;
