import { isDefined } from '../typeGuards';
const jobSplitChar = '|';
export const toJobId = (cpJobSchedule) => {
    return [cpJobSchedule.jobDefinition.identifier, cpJobSchedule.identifier].join(jobSplitChar);
};
export const fromJobId = (jobId) => {
    const [jobDefinitionId, jobScheduleId] = jobId.split(jobSplitChar);
    return {
        jobDefinitionId,
        jobScheduleId,
    };
};
export const getJobIdFromBull = (job) => {
    var _a;
    const extendedBullJob = job;
    const recurrentJobId = (_a = extendedBullJob.opts.repeat) === null || _a === void 0 ? void 0 : _a.jobId;
    if (recurrentJobId) {
        return recurrentJobId;
    }
    else {
        return extendedBullJob.id.toString();
    }
};
export const isRecurrent = (job) => {
    return isDefined(job.settings.repeat);
};
