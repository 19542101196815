import React, { ElementType } from 'react';

import Statement from './Statement';

function withSmall(WrappedComponent: ElementType): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} isSmall={true} />;
  };
}

export default withSmall(Statement);
