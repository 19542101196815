const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpFieldTrigger',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFieldTrigger' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType?$filter=assignments%2FfieldTriggers%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Field Trigger',
    'properties': {
        'fieldTriggerType': {
            'title': 'Field Trigger Type',
            'description': '<div>The type of a field trigger defines when it is executed.</div>\n<div>For example "On Input Blur" is executed at the moment before the input is blurred out.</div>\n<div>"On Lookup" is called when items in a list are looked up and can be used to apply additionaly filters. </div>\n<div>"On Validate" is called on the client-side when a form is submitted.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpFieldTriggerType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFieldTriggerType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/fieldTriggerType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/OnInputBlur',
                'http://platform.cosmoconsult.com/ontology/OnLookup',
                'http://platform.cosmoconsult.com/ontology/OnValidate',
                'http://platform.cosmoconsult.com/ontology/OnMonacoGetType',
                'http://platform.cosmoconsult.com/ontology/OnInputInit',
            ],
            'enumNames': ['On Input Blur', 'On Lookup', 'On Validate', 'On Monaco Get Type', 'On Input Init'],
        },
        'function': {
            'title': 'Function',
            'description': '<div>The Javascript function be executed when the trigger becomes active. </div>\n<div>Can be either an inline function or a shared function.</div>',
            'anyOf': [
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpFunction',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFunction' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': true,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Function',
                    'properties': {
                        'identifier': { 'type': 'string', 'title': 'Cp Function' },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpFunction',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpInlineFunction' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Cp Inline Function',
                    'properties': {
                        'sourceCode': {
                            'title': 'Source Code',
                            'type': 'string',
                            'format': 'cp:code',
                            'default': 'trigger.execute = function(executionContext, utils) {\n  // Here we write code that will be called on every execute\n};\n',
                            'cp_ui': { 'sortOrderForm': 0 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/sourceCode', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/CpInlineFunction',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                    'required': ['sourceCode'],
                },
            ],
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/function', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The display name of the Field Trigger.</div>',
            'type': 'string',
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'fieldTriggerType', 'function'],
};
export const CpFieldTriggerSchema = Schema;
