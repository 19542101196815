import { Schemas } from '@cp/base-types';
import { getSafeString } from '@cpa/base-core/helpers';
import { ICardProps, IDataItem } from '@cpa/base-core/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectionButton from '../../../components/SelectionButton/SelectionButton';
import CosmoCometWhite from '../../../assets/icons/cosmo-comet-white.svg';

import styles from './CometRewardCard.module.scss';

const CometRewardCard: React.FC<ICardProps> = ({ item, onClick, onItemSelect, isSelected, hideSelectionMarker }) => {
  const [t, { language }] = useTranslation();

  const cometAssociation: Schemas.CometAssociation = item as Schemas.CometAssociation;
  const cometAchievement: Schemas.CometAchievement = item.cometAchievement as Schemas.CometAchievement;

  return (
    <div
      key={String(cometAchievement?.identifier)}
      className={styles.item}
      onClick={onClick}
      style={
        cometAchievement.image && typeof cometAchievement.image === 'string'
          ? {
              backgroundImage: `url(${CSS.escape(cometAchievement.image)})`,
              backgroundRepeat: 'no-repeat',
              cursor: onClick ? 'pointer' : 'default',
            }
          : undefined
      }
    >
      {!hideSelectionMarker && (
        <SelectionButton item={cometAchievement as IDataItem} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} />
      )}
      <div className={styles.inner}>
        <div className={styles.badge}>
          <div>
            <div className={styles.title}>{cometAchievement.name}</div>
            {cometAssociation.dateCreated && (
              <div className={styles.completedOn}>
                {t('common.completedOn')} {new Date(cometAssociation.dateCreated).toLocaleDateString()}
              </div>
            )}
          </div>
          <div className={styles.amountBadge}>
            {cometAchievement.amount && <div className={styles.amount}>{(~~cometAchievement.amount).toLocaleString(language)}</div>}
            <img className={styles.icon} src={CosmoCometWhite}></img>
          </div>
        </div>
        {cometAchievement.shortDescription ? (
          <div className={styles.text}>{getSafeString(cometAchievement.shortDescription as string, true)}</div>
        ) : undefined}
      </div>
    </div>
  );
};

export default CometRewardCard;
