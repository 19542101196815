import { MAX, NIL, parse, stringify, v1, v1ToV6, v3, v4, v5, v6, v6ToV1, v7, validate, version } from 'uuid';
export { parse as parseUuid, stringify as stringifyUuid, MAX as UUID_MAX, NIL as UUID_NIL, version as uuidVersion, validate as validateUuid };
export const createUuidV1 = () => v1();
export const createUuidV3 = (name, namespace) => v3(name, namespace);
export const createUuidV4 = () => v4();
export const createUuidV5 = (name, namespace) => v5(name, namespace);
export const createUuidV6 = () => v6();
export const createUuidV7 = () => v7();
export const convertUuidV1ToV6 = (uuidV1) => v1ToV6(uuidV1);
export const convertUuidV6ToV1 = (uuidV6) => v6ToV1(uuidV6);
export const isUuid = (value) => validate(value);
export const isUuidV1 = (value) => isUuid(value) && version(value) === 1;
export const isUuidV3 = (value) => isUuid(value) && version(value) === 3;
export const isUuidV4 = (value) => isUuid(value) && version(value) === 4;
export const isUuidV5 = (value) => isUuid(value) && version(value) === 5;
export const isUuidV6 = (value) => isUuid(value) && version(value) === 6;
export const isUuidV7 = (value) => isUuid(value) && version(value) === 7;
