import { IDataItem } from '@cpa/base-core/types';
import { DefaultButton, IPersonaProps, Label, Persona, PersonaCoin, PersonaPresence, PersonaSize, TooltipOverflowMode } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { getEndpoint } from '@cpa/base-core/api';
import { gaStatus, instantApmTransaction } from '@cpa/base-core/helpers';

import HoverTooltip from '../../../../../components/HoverTooltip/HoverTooltip';

import styles from './PersonCard.module.scss';

interface IPersonCardProps {
  person: {
    name: string;
    image?: string;
    position?: string;
    phone?: string;
    email?: string;
    teamsLink?: string;
  };
  company?: string;
  darkMode?: boolean;
  onNameClick?: (person: IDataItem) => void;
  personData: IDataItem;
  endpointId?: string;
  isInHoverCard?: boolean;
  topicAdditionalUsers?: string[];
  topicName?: string;
  topicMessage?: string | ((personName: string) => Promise<string | null>);
}

export const personImagesGlobalCache = new Map<string, Promise<string>>();

const PersonCard: React.FC<IPersonCardProps> = ({
  endpointId,
  person,
  darkMode,
  company,
  onNameClick,
  personData,
  isInHoverCard,
  topicName,
  topicMessage,
  topicAdditionalUsers,
}) => {
  const { name, image, position, phone, email } = person;
  const [t] = useTranslation();

  const getTeamsLink = useCallback(async () => {
    if (person.teamsLink) {
      return person.teamsLink;
    }

    if (email) {
      const users = [email, ...(topicAdditionalUsers || [])].join(',');
      const topic = topicName ? `&topicName=${encodeURIComponent(topicName)}` : '';
      let messageValue = typeof topicMessage === 'string' ? topicMessage : null;
      if (typeof topicMessage !== 'string') {
        const value = await topicMessage?.(name);
        if (value) {
          messageValue = value;
        } else {
          return null;
        }
      }
      const message = messageValue ? `&message=${encodeURIComponent(messageValue)}` : '';
      return `https://teams.microsoft.com/l/chat/0/0?users=${users}${topic}${message}`;
    }

    return null;
  }, [email, name, person.teamsLink, topicAdditionalUsers, topicMessage, topicName]);

  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if (!image || !endpointId) {
      return;
    }

    let cancelled = false;

    if (!personImagesGlobalCache.has(image)) {
      personImagesGlobalCache.set(
        image,
        getEndpoint(endpointId)
          .axios.get(image, {
            headers: {
              'x-cp-data-company': company,
            },
            responseType: 'blob',
          })
          .then((response: Blob) => {
            return URL.createObjectURL(response);
          })
      );
    }

    personImagesGlobalCache.get(image)?.then((url: string) => {
      if (!cancelled) {
        setImageUrl(url);
      }
    });

    return (): void => {
      cancelled = true;
    };
  }, [image, company, endpointId]);

  const data = useMemo<IPersonaProps>(() => {
    return {
      imageUrl: imageUrl,
      imageAlt: name,
      text: name,
      secondaryText: position,
      tertiaryText: phone ? t('dashboard.persons.hotline', { phone }) : undefined,
      optionalText: email,
    };
  }, [name, imageUrl, position, phone, email, t]);

  const trackContact = useCallback(
    (method: string) => (): void => {
      // Track contact in APM
      instantApmTransaction(name, 'contact', { method });

      if (gaStatus.initialized) {
        ReactGA.event({
          category: 'Person',
          action: `Contact - ${name}`,
          label: method,
        });
      }
    },
    [name]
  );

  const onContactBtnClick = useCallback(async () => {
    trackContact('Teams')();
    const teamsLink = await getTeamsLink();
    if (teamsLink) {
      parent.open(teamsLink);
    }
  }, [getTeamsLink, trackContact]);

  const onEmailRender = useCallback(
    (persona: IPersonaProps) => {
      return (
        <a
          onClick={trackContact('Email')}
          target="_blank"
          className={darkMode ? styles.linkDark : styles.link}
          href={`mailto:${persona.optionalText}`}
          rel="noreferrer"
        >
          {persona.optionalText}
        </a>
      );
    },
    [darkMode, trackContact]
  );

  const onPrimaryClick = useCallback(() => {
    if (onNameClick) {
      onNameClick({ ...personData, image: imageUrl as string });
    }
  }, [onNameClick, personData, imageUrl]);

  const primaryTextRenderer = useCallback(
    (persona: IPersonaProps) => {
      if (isInHoverCard) {
        return (
          <HoverTooltip styles={{ root: { cursor: 'default' } }} content={persona.text} overflowMode={TooltipOverflowMode.Parent}>
            {persona.text}
          </HoverTooltip>
        );
      }
      return (
        <div style={{ cursor: !onNameClick ? 'default' : 'pointer' }} onClick={onPrimaryClick}>
          {persona.text}
        </div>
      );
    },
    [isInHoverCard, onNameClick, onPrimaryClick]
  );

  const onImageRender = useCallback(
    ({ coinSize, imageAlt, size, text }: IPersonaProps) => {
      return (
        <PersonaCoin
          onClick={onPrimaryClick}
          imageUrl={imageUrl}
          imageAlt={imageAlt}
          coinSize={coinSize}
          size={size}
          style={{ cursor: !onNameClick ? 'default' : 'pointer' }}
          text={text}
        />
      );
    },
    [imageUrl, onNameClick, onPrimaryClick]
  );

  return (
    <div
      className={styles.card}
      onContextMenu={(e): void => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Persona
        {...data}
        size={PersonaSize.size100}
        presence={PersonaPresence.none}
        hidePersonaDetails={false}
        onRenderOptionalText={onEmailRender}
        onRenderPrimaryText={primaryTextRenderer}
        onRenderPersonaCoin={onImageRender}
      />
      {(person.teamsLink || email) && (
        <div style={{ display: 'grid', justifyContent: 'start' }}>
          <Label>{t('dashboard.persons.contact')}</Label>
          <DefaultButton iconProps={{ iconName: 'TeamsLogo' }} text={t('dashboard.persons.write')} onClick={onContactBtnClick} />
        </div>
      )}
    </div>
  );
};

export default React.memo(PersonCard);
