import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { WidgetProps } from '@rjsf/core';
import * as _ from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';

import DescriptionField from '../DescriptionField/DescriptionField';
import TitleField, { TitleFieldType } from '../TitleField/TitleField';

// Keys of IChoiceGroupProps from @fluentui/react
const allowedProps = [
  'componentRef',
  'options',
  'defaultSelectedKey',
  'selectedKey',
  'onChange',
  'label',
  'onChanged',
  'theme',
  'styles',
  'ariaLabelledBy',
];

interface IRadioWidgetProps {
  onBlur: (id: string, value: string, options?: object) => void;
}

const RadioWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  registry,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
}: WidgetProps & IRadioWidgetProps): JSX.Element => {
  const { enumOptions, enumDisabled } = options;
  const _onChange = useCallback(
    (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
      if (option) {
        onChange(option.key);
      }
    },
    [onChange]
  );

  // this _onBlur was triggered after any change inside radio group with value 'on'
  const _onBlur = useCallback(
    (_e: React.FocusEvent<HTMLInputElement>) => {
      // this is triggered on each radio button bluring inside a choice group
      onBlur(id, value);
    },
    [id, onBlur, value]
  );

  const _onFocus = useCallback(
    ({ target: { value: v } }: React.FocusEvent<HTMLInputElement>) => {
      onFocus(id, v);
    },
    [onFocus, id]
  );

  const newOptions = useMemo(
    () =>
      (enumOptions as { value: string; label: string }[]).map((option) => ({
        key: option.value,
        text: option.label,
        disabled: ((enumDisabled as string[]) || []).indexOf(option.value) !== -1,
      })),
    [enumOptions, enumDisabled]
  );

  const uiProps = useMemo(() => _.pick(options.props || {}, allowedProps) as object, [options.props]);

  return (
    <>
      <TitleField title={label || schema.title} type={TitleFieldType.Primitive} required={required} registry={registry} />
      <DescriptionField description={schema.description || ''} detailed />
      <ChoiceGroup
        options={newOptions as IChoiceGroupOption[]}
        onChange={_onChange}
        onFocus={_onFocus}
        onBlur={_onBlur}
        label={undefined}
        required={required}
        selectedKey={value}
        disabled={disabled}
        readOnly={readonly}
        {...uiProps}
      />
    </>
  );
};

export default RadioWidget;
