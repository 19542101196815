import classNames from 'classnames';
import React, { useEffect, useCallback, useMemo, HTMLAttributeAnchorTarget, MouseEventHandler, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { TooltipOverflowMode } from '@fluentui/react';

import HoverTooltip from '../../../../../../components/HoverTooltip/HoverTooltip';

import styles from './SingleItemCoverCtaButton.module.scss';

interface SingleItemCoverCtaButtonProps {
  children?: React.ReactNode;
  customImageComponent?: React.ReactNode;
  description?: string;
  target?: HTMLAttributeAnchorTarget;
  url?: string;
  image?: string;
  buttonIndex?: number;
  useFullWidth?: boolean;
  isComponent?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  className?: string;
}

const SingleItemCoverCtaButton: React.FC<SingleItemCoverCtaButtonProps> = ({
  children,
  description,
  url,
  target,
  image,
  customImageComponent,
  buttonIndex,
  useFullWidth,
  isComponent,
  onClick,
  className,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const [hoverType, setHoverType] = React.useState<'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | undefined>(undefined);
  const [buttonShown, setButtonShown] = React.useState(false);

  const hoverBackground = useMemo(() => {
    const backgroundColor = darkMode ? '#ffffff' : '#000000';

    let gradientAngle = '0deg';

    if (hoverType === 'topLeft') {
      gradientAngle = '135deg';
    } else if (hoverType === 'topRight') {
      gradientAngle = '240deg';
    } else if (hoverType === 'bottomLeft') {
      gradientAngle = '45deg';
    } else if (hoverType === 'bottomRight') {
      gradientAngle = '315deg';
    }

    if (hoverType) {
      return {
        background: `linear-gradient(${gradientAngle}, ${backgroundColor} 0%, transparent 100%)`,
      };
    } else {
      return {};
    }
  }, [darkMode, hoverType]);

  const hoverEffect = useMemo(() => {
    const perspective = useFullWidth ? '1300px' : '600px';
    const rotate = useFullWidth ? '2deg' : '5deg';

    const rotateX = hoverType === 'topLeft' || hoverType === 'topRight' ? rotate : `-${rotate}`;
    const rotateY = hoverType === 'topLeft' || hoverType === 'bottomLeft' ? `-${rotate}` : rotate;

    if (hoverType) {
      return {
        transform: `perspective(${perspective}) rotateX(${rotateX}) rotateY(${rotateY}) scale(1.01)`,
      };
    } else {
      return {};
    }
  }, [hoverType, useFullWidth]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setButtonShown(true);
    }, 400 + 200 * (buttonIndex || 0));

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const rect = containerRef.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    const x = e.clientX - rect.x;
    const y = e.clientY - rect.y;

    const width = rect.width;
    const height = rect.height;

    if (x < width / 2 && y < height / 2) {
      setHoverType('topLeft');
    } else if (x > width / 2 && y < height / 2) {
      setHoverType('topRight');
    } else if (x < width / 2 && y > height / 2) {
      setHoverType('bottomLeft');
    } else if (x > width / 2 && y > height / 2) {
      setHoverType('bottomRight');
    } else {
      setHoverType(undefined);
    }
  }, []);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
        return;
      }

      if (!url) {
        return;
      }

      const currentUrl = window.location.href.split('#')[0];

      if (url.includes('#') && url.startsWith(currentUrl)) {
        e.preventDefault();

        const activeElement = document.activeElement as unknown as HTMLElement;
        activeElement?.blur();

        const elementId = new URL(url).hash.replace('#', '');

        window.location.hash = elementId;

        let element = document.getElementById(elementId);

        if (!element) {
          element = document.querySelector(`[data-heading="${elementId}"]`);
        }

        if (element) {
          element?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    },
    [onClick, url]
  );

  return (
    <div
      ref={containerRef}
      className={classNames(
        {
          [styles.ctaButtonContainer]: true,
        },
        className
      )}
      onMouseLeave={() => setHoverType(undefined)}
      onMouseMove={handleMouseMove}
    >
      <a
        className={classNames({
          [styles.ctaButton]: true,
          [styles.ctaButtonDark]: darkMode,
          [styles.ctaButtonShown]: buttonShown,
          [styles.ctaButtonFullWidth]: useFullWidth,
          [styles.ctaButtonComponent]: isComponent,
        })}
        target={url ? target : undefined}
        href={url}
        rel={url ? 'noreferrer' : undefined}
        onClick={handleOnClick}
        style={hoverEffect}
      >
        <div className={styles.bodyWrapper}>
          <div className={styles.body}>
            <div className={styles.bodyIn}>
              {!!customImageComponent && (
                <div
                  className={classNames({
                    [styles.image]: true,
                    [styles.imageCustom]: true,
                    [styles.imageCustomDark]: darkMode,
                  })}
                >
                  {customImageComponent}
                </div>
              )}
              {!customImageComponent && image && (
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                ></div>
              )}

              <div className={styles.details}>
                <div className={styles.name}>{children}</div>

                {description && (
                  <div className={styles.description}>
                    <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={description}>
                      {description}
                    </HoverTooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames({
            [styles.hoverOverlay]: true,
            [styles.hoverOverlayShown]: !!hoverType,
          })}
          style={hoverBackground}
        ></div>
      </a>
    </div>
  );
};

export default SingleItemCoverCtaButton;
