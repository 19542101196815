import React from 'react';
import { Text } from '@fluentui/react';
import { IDataItem } from '@cpa/base-core/types';

import styles from '../../Statement.module.scss';

interface IVotingProps {
  data?: IDataItem<unknown>;
  labels: {
    total?: string;
    active?: string;
    revoked?: string;
    noData?: string;
  };
}

const Voting: React.FC<IVotingProps> = ({ data, labels }) => {
  return (
    <div className={styles.votings}>
      <div>
        <Text variant={'xxLargePlus'}>{labels.active}: </Text>
        <Text variant={'xxLargePlus'}>{data?.value || labels.noData}</Text>
      </div>
      <div>
        <Text variant={'large'}>{labels.revoked}: </Text>
        <Text variant={'large'}>{(data?.count as number) - (data?.value as number) || labels.noData}</Text>
      </div>
      <div>
        <Text variant={'xLargePlus'}>{labels.total}: </Text>
        <Text variant={'xLargePlus'}>{data?.count || labels.noData}</Text>
      </div>
    </div>
  );
};

export default Voting;
