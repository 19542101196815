import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';
import { getPathTitle } from '@cpa/base-core/helpers';
import { FacetFilterPropertyValue } from '@cp/base-utils';

import ExpandButton from '../../../../../../ExpandButton/ExpandButton';
import { IFacetFilterData } from '../../../helpers/getFacetFilters';
import FacetFilterItemCheckbox from '../FacetFilterItemCheckbox/FacetFilterItemCheckbox';

import styles from './FacetFilterItem.module.scss';

interface IFacetFiltersProps {
  facetFilter: IFacetFilterData;
  onToggleSelect: (propertyJsonPath: string, selectedValues: FacetFilterPropertyValue | FacetFilterPropertyValue[]) => void;
  onShowMoreClick: (facetFilter: IFacetFilterData) => void;
  onShowUnselectedItems: (facetFilter: IFacetFilterData) => void;

  darkMode: boolean;
}

const FacetFilterItem: React.FC<IFacetFiltersProps> = ({ facetFilter, onToggleSelect, darkMode, onShowMoreClick, onShowUnselectedItems }) => {
  const [isExpanded, { toggle: toggleExpanded }] = useBoolean(true);
  const [t] = useTranslation();

  const facetTitle = useMemo(() => facetFilter.title || getPathTitle(facetFilter.property, true), [facetFilter]);

  const handleShowMoreClick = useCallback(() => {
    onShowMoreClick(facetFilter);
  }, [facetFilter, onShowMoreClick]);

  const handleShowUnselectedItems = useCallback(() => {
    onShowUnselectedItems(facetFilter);
  }, [facetFilter, onShowUnselectedItems]);

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerDark]: darkMode,
      })}
    >
      {facetFilter.property.type !== 'boolean' && (
        <div className={styles.title}>
          <ExpandButton
            className={classNames(styles.expandButton, { [styles.expandButtonLight]: !darkMode })}
            isExpanded={isExpanded}
            onClick={toggleExpanded}
          />

          <span>{facetTitle}</span>
        </div>
      )}

      {isExpanded && (
        <div className={styles.body}>
          {facetFilter.items.map((item) => (
            <FacetFilterItemCheckbox
              facetFilter={facetFilter}
              facetFilterItem={item}
              darkMode={darkMode}
              onToggleSelect={onToggleSelect}
              key={`${item._id}`}
            />
          ))}

          {!facetFilter.areAllItemsLoaded && !facetFilter.areMoreItemsLoading && (
            <a className={styles.showMore} onClick={handleShowMoreClick}>
              {t('facetFilters.showMore')}
            </a>
          )}

          {facetFilter.areAllItemsLoaded &&
            !!facetFilter.selectedItems?.length &&
            !!facetFilter.uncheckedItems?.length &&
            !facetFilter.areUnselectedItemsShown && (
              <a className={styles.showMore} onClick={handleShowUnselectedItems}>
                {t('facetFilters.showMore')}
              </a>
            )}

          {facetFilter.areMoreItemsLoading && <span> {t('facetFilters.loadingMore')}</span>}
        </div>
      )}
    </div>
  );
};

export default React.memo(FacetFilterItem);
