import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IUserAgreement } from '../types';
import { IGlobalState } from '../store';

export const useAvailableAgreements = (agreements: IUserAgreement[]): [IUserAgreement[], boolean] => {
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);
  const visibleAgreements = agreements.filter((agreement) => {
    if (!agreement.requiredConfigurationPath) return true;
    return (cpa?.configuration as { [key: string]: unknown })?.[agreement.requiredConfigurationPath];
  });
  const showDialog = visibleAgreements.some((agreement) => agreement.requiredConfigurationPath);
  return [visibleAgreements, showDialog];
};

export const useLanguage = () => {
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const [t, i18n] = useTranslation();

  return useMemo(() => {
    let currentLanguageCode = i18n.language;
    if (dataLanguage && dataLanguage !== 'notSelected') {
      currentLanguageCode = dataLanguage;
    }

    return currentLanguageCode;
  }, [dataLanguage, i18n.language]);
};
