import Konva from 'konva';

export enum ImageEditorChildType {
  image,
  crop,
  cropTransformer,
}

export abstract class ImageEditorUtils {
  public static getLayer(stage: Konva.Stage): Konva.Layer {
    return stage.children[0];
  }

  public static getChild(stage: Konva.Stage, type: ImageEditorChildType): Konva.Image | Konva.Group | Konva.Transformer {
    const container = type == ImageEditorChildType.cropTransformer ? this.getChild(stage, ImageEditorChildType.crop) : this.getLayer(stage);

    return container.children.toArray().filter((child) => child.name() === ImageEditorChildType[type])[0] as
      | Konva.Image
      | Konva.Group
      | Konva.Transformer;
  }
}
