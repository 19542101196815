import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { executeAggregationTemplate } from '@cpa/base-core/api';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem, KpiOp } from '@cpa/base-core/types';
import { Schemas } from '@cp/base-types';

import { IKpiRenderProps } from '../../Kpi';

interface IExternalKpiTileProps {
  dataEndpointIdentifier: string;
  kpi: Extract<Schemas.CpaPage['kpis'], {}>[number];
  page: Schemas.CpaPage;
  redirectPath?: string;
  lastUpdate?: number;
  children: (props: IKpiRenderProps) => JSX.Element;
}

const ExternalKpiTile: React.FC<IExternalKpiTileProps> = ({ dataEndpointIdentifier, redirectPath, kpi, lastUpdate, page, children }) => {
  const [, i18n] = useTranslation();
  const [isFetching, setIsFetching] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const dataRef = useRef<IDataItem[]>([]);
  const [data, setData] = useState<IDataItem[]>([]);

  const onDataLoaded = (response: IDataItem[]): void => {
    console.debug(`KPI response loaded. Entities: ${response.length}.`, response);
    dataRef.current = [...dataRef.current, ...response];
    setData(dataRef.current);
    setErrors([]);
  };
  const onDataError = useCallback(
    (e: Error & { err?: string }) => {
      setErrors([`Error: ${e.message ?? e.err}`]);
      console.error(`Response error.`, e as Error);
    },
    [setErrors]
  );

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const loadItems = useCallback(() => {
    const cpTypeUrl = kpi.cpTypeUrl ?? page.cpTypeUrl;
    if (!cpTypeUrl) {
      return;
    }

    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();
    setIsFetching(true);

    const dataPromise = executeAggregationTemplate(
      dataEndpointIdentifier,
      cpTypeUrl,
      kpi.aggregationTemplate?.identifier ?? (kpi.kpiType === KpiOp.SUM ? 'kpi-sum' : 'kpi-count'),
      kpi.valueProperty ? { property: kpi.valueProperty } : {},
      [],
      cancelToken.current
    );

    dataPromise
      .then(onDataLoaded)
      .catch(onDataError)
      .finally(() => setIsFetching(false));
  }, [kpi, page.cpTypeUrl, dataEndpointIdentifier, onDataError]);

  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  useEffect(() => {
    dataRef.current = [];
    setData([]);
    loadItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEndpointIdentifier, lastUpdate, dataLanguage]);

  useEffect(() => {
    return (): void => {
      cancelToken.current?.cancel();
    };
  }, []);

  const calculatedValue = useMemo(() => {
    try {
      return (kpi?.pattern || '{{0.total}}').replace(/\{\{(?<field>.*?)}}/g, (match, field: string) => {
        const matchedData = _.get(data, field)?.toLocaleString(i18n.language, { maximumFractionDigits: 2 });

        if ((matchedData === undefined || matchedData === null) && !isFetching) {
          throw new Error(`Data on path ${field} in ${kpi.pattern} not found - ${JSON.stringify(data)}`);
        }
        return matchedData;
      });
    } catch (e) {
      console.warn(`Failed to display KPI.`, e);
      return null;
    }
  }, [data, i18n.language, isFetching, kpi.pattern]);

  const kpiRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef<number>(82);

  useLayoutEffect(() => {
    if (kpiRef.current) {
      heightRef.current = Math.max(heightRef.current, kpiRef.current.getBoundingClientRect().height);
    }
  }, [calculatedValue, kpi.name, isFetching]);

  return children({
    name: kpi.name,
    isFetching,
    errors,
    redirectPath,
    value: calculatedValue,
    identifier: kpi.identifier,
  });
};
export default React.memo(ExternalKpiTile);
