import docxtemplater from 'docxtemplater';
import angularParser from 'docxtemplater/expressions.js';
export const docxTemplater = function ({ zip, data, mimeType, outputType, options }) {
    try {
        const docxTemplater = new docxtemplater(zip, Object.assign({ parser: angularParser }, options));
        docxTemplater.setData(data);
        docxTemplater.render();
        const docFile = docxTemplater.getZip().generate({
            type: outputType,
            mimeType,
        });
        return docFile;
    }
    catch (err) {
        console.warn(JSON.stringify(err));
        throw err;
    }
};
