const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/SolutionVariableKey',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolutionVariableKey' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Solution Variable Key',
    'properties': {
        'defaultValue': {
            'title': 'Default Value',
            'anyOf': [
                {
                    '$id': 'http://schema.org/StructuredValue',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FStructuredValue' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Structured Value',
                    'description': 'Structured values are used when the value of a property has a more complex structure than simply being a textual value or a reference to another thing.',
                    'properties': {
                        'formula': {
                            'title': 'Formula',
                            'description': '<div>Vanilla JavaScript</div>',
                            'type': 'string',
                            'format': 'cp:code',
                            'cp_ui': { 'sortOrderForm': 23 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/formula', 'rel': 'canonical' }],
                        },
                        '_type': {
                            'type': 'string',
                            'const': 'http://schema.org/StructuredValue',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
                { 'type': 'string', 'title': 'String' },
                { 'type': 'number', 'title': 'Number' },
            ],
            'cp_ui': { 'sortOrderForm': 28 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/defaultValue', 'rel': 'canonical' }],
        },
        'supportedValues': {
            'title': 'Supported Values',
            'type': 'array',
            'items': { 'type': 'string' },
            'cp_ui': { 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/supportedValues', 'rel': 'canonical' }],
        },
        'isGlobal': {
            'title': 'Is Global',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isGlobal', 'rel': 'canonical' }],
        },
        'importance': {
            'title': 'Importance',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/importance', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 21 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 23 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 22 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name'],
};
export const SolutionVariableKeySchema = Schema;
