function parseAcceptLanguage(accept) {
    return accept
        .split(',')
        .map((part, i) => parseLanguage(part.trim(), i))
        .filter(Boolean);
}
const simpleLanguageRegExp = /^\s*([^\s\-;]+)(?:-([^\s;]+))?\s*(?:;(.*))?$/;
function parseLanguage(str, i) {
    const match = simpleLanguageRegExp.exec(str);
    if (!match)
        return null;
    const prefix = match[1], suffix = match[2];
    let full = prefix;
    if (suffix)
        full += '-' + suffix;
    let q = 1;
    if (match[3]) {
        const params = match[3].split(';');
        for (let j = 0; j < params.length; j++) {
            const p = params[j].split('=');
            if (p[0] === 'q')
                q = parseFloat(p[1]);
        }
    }
    return {
        prefix: prefix,
        suffix: suffix,
        q: q,
        i: i,
        full: full,
    };
}
function getFullLanguage(spec) {
    return spec.full;
}
function isQuality(spec) {
    return spec.q > 0;
}
function compareSpecs(a, b) {
    return b.q - a.q || a.i - b.i || 0;
}
export function parseLanguages(accept) {
    const accepts = parseAcceptLanguage(accept === undefined ? '*' : accept || '');
    return accepts.filter(isQuality).sort(compareSpecs).map(getFullLanguage);
}
