import { Callout, ICalloutProps, MessageBar } from '@fluentui/react';
import React, { FC, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import Drawer from '../Drawer/Drawer';

import styles from './DrawerOrCallout.module.scss';

const calloutStyles = { root: { zIndex: 1000 } };

const DrawerOrCallout: FC<{
  title?: string;
  hintText?: string;
  renderInput: () => JSX.Element | null;
  renderBody: () => JSX.Element | null;
  calloutProps?: ICalloutProps;
  isOpened: boolean;
  onDrawerClose: () => void;
  calloutClassname?: string;
}> = ({ title, hintText, renderBody, calloutProps, isOpened, onDrawerClose, renderInput, calloutClassname }) => {
  const isWide = useMediaQuery({ query: '(min-width: 768px)' });

  const handleDismissOnEvent = useCallback(
    (event: Event | React.FocusEvent | React.KeyboardEvent | React.MouseEvent): boolean => {
      // TODO: Remove this workaround once the issue is resolved - https://github.com/tinymce/tinymce/issues/5746
      const isWordCountClick = !!event.target && 'className' in event.target && event.target.className === 'tox-statusbar__wordcount';
      return calloutProps?.preventDismissOnEvent?.(event) || isWordCountClick;
    },
    [calloutProps]
  );

  const additionalType = isWide ? (
    isOpened ? (
      <Callout
        className={calloutClassname}
        {...(calloutProps ?? {})}
        hidden={!isOpened}
        styles={calloutStyles}
        preventDismissOnEvent={handleDismissOnEvent}
      >
        {renderBody()}
      </Callout>
    ) : null
  ) : (
    <Drawer title={title} isOpen={isOpened} onClose={onDrawerClose} doNotFocusOnOpen={true}>
      <div className={styles.inner}>
        {hintText && <MessageBar className={styles.hint}>{hintText}</MessageBar>}
        {renderInput()}
        {renderBody()}
      </div>
    </Drawer>
  );

  return (
    <div>
      {renderInput()}
      {additionalType}
    </div>
  );
};

export default React.memo(DrawerOrCallout);
