import React, { useMemo, useRef } from 'react';
import { Icon } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import LocalizationEditor from '../../../../../components/Form/components/TextWidget/components/LocalizationEditor/LocalizationEditor';

import styles from './Localization.module.scss';

interface ILocalizationProps {
  page: Schemas.CpaPage;
  schema: IJSONSchema | null;
  rootSchema: IJSONSchema;
  dataPath: string;
  baseLanguage: string;
  identifier: string;
  top?: number;
  left?: number;
  children?: JSX.Element | null;
}

const editorWrapperProps = {
  isInForm: false,
  className: styles.editor,
  centered: true,
};

const Localization: React.FC<ILocalizationProps> = ({
  schema,
  rootSchema,
  page,
  baseLanguage,
  identifier,
  dataPath,
  left = -20,
  top = 28,
  children,
}) => {
  const [localizationEditorShown, { setTrue: showEditor, setFalse: hideEditor }] = useBoolean(false);
  const wrapperRef = useRef(null);
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
  const [, i18n] = useTranslation();

  const currentLanguage = useMemo(() => {
    let currentLanguageCode = i18n.language;
    if (dataLanguage && dataLanguage !== 'notSelected') {
      currentLanguageCode = dataLanguage;
    }

    return currentLanguageCode.slice(0, 2).toUpperCase();
  }, [dataLanguage, i18n.language]);

  if (!page.allowModify) {
    return <>{children ?? null}</>;
  }

  return (
    <div className={styles.localizationWrapper} ref={wrapperRef}>
      <div className={styles.content}>
        <Icon className={styles.editIcon} iconName="Edit" style={{ top: top, left: left }} onClick={showEditor} />
        {children ?? null}
      </div>

      {localizationEditorShown && schema && (
        <LocalizationEditor
          schema={schema}
          rootSchema={rootSchema}
          onClose={hideEditor}
          page={page}
          baseLanguage={baseLanguage}
          identifier={identifier}
          path={dataPath}
          widgetRef={wrapperRef}
          currentLanguage={currentLanguage}
          editorWrapperProps={editorWrapperProps}
        />
      )}
    </div>
  );
};

export default Localization;
