import { TypeConstants } from '@cp/base-types';
import { checkIfRelatedLinkExist, getSafeString } from '@cpa/base-core/helpers';
import { useAboutValue } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { ICardProps } from '@cpa/base-core/types';
import { Icon, TooltipOverflowMode } from '@fluentui/react';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HoverTooltip from '../../../components/HoverTooltip/HoverTooltip';
import SelectionButton from '../../../components/SelectionButton/SelectionButton';
import { IWorkItem } from '../../../screens/LoopWorkItemHistory/helpers/types';
import { getClassNamesForWorkItemType, getIconNameForWorkItemType } from '../../../screens/LoopWorkItemHistory/helpers/utils';

import styles from './LoopWorkItemCard.module.scss';

interface WorkItemProps extends ICardProps {
  item: IWorkItem;
}

const LoopWorkItemCard: React.FC<WorkItemProps> = ({ item, data, onClick, onItemSelect, isSelected, hideSelectionMarker }) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const currentLocation = useSelector((state: IGlobalState) => state.router.location);

  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick]
  );

  // Get card content from 'shortDescription' or 'description'
  const cardContent = useMemo(() => {
    const content = item.shortDescription || item.description;
    return (content && getSafeString(content as string, true)?.trim()) || '';
  }, [item.description, item.shortDescription]);

  const isVotingExists = useMemo(() => {
    const schema = data.schema;
    if (!schema) return false;
    return checkIfRelatedLinkExist(TypeConstants.Voting, schema);
  }, [data.schema]);

  const [voting] = useAboutValue(TypeConstants.Voting, item.identifier || '', 'voting', 'value', data.page.cpTypeUrl, undefined, !isVotingExists);

  return (
    <a
      key={String(item?.identifier)}
      className={classNames(styles.item, { [styles.itemDark]: darkMode })}
      href={`${currentLocation.pathname}/${item.identifier}`}
      onClick={handleItemClick}
    >
      {!hideSelectionMarker && (
        <SelectionButton item={item} isSelected={isSelected} onClick={onItemSelect} className={styles.customSelect} preventDefault={true} />
      )}
      <Icon
        iconName={getIconNameForWorkItemType(item.workItemType)}
        className={classNames(styles.typeIcon, getClassNamesForWorkItemType(item.workItemType))}
      />
      <div className={styles.inner}>
        <div className={styles.cardHeader}>
          <div className={styles.titleWrapper}>
            <a className={styles.title}>{item.name}</a>
          </div>
          {item.image && <img className={styles.logo} src={item.image as string} />}
        </div>
        <div className={styles.content}>
          <span className={classNames(styles.textTitle, { [styles.textDark]: darkMode })}>{item.alternateName}</span>
          <span className={classNames(styles.text, { [styles.textDark]: darkMode })}>
            <HoverTooltip
              overflowMode={TooltipOverflowMode.Parent}
              content={cardContent.length > 500 ? `${cardContent.substring(0, 500)}...` : cardContent}
            >
              {cardContent}
            </HoverTooltip>
          </span>
        </div>
      </div>
      {onClick ? (
        <div className={styles.moreLinkWrapper}>
          {isVotingExists ? (
            <div className={styles.votings}>
              <Icon iconName={'like'} />
              <span>{(voting as number) || 0}</span>
            </div>
          ) : null}
          <p className={styles.moreLink} onClick={onClick}>
            {t('common.more')} &#x276F;
          </p>
        </div>
      ) : null}
      {typeof item.stampText === 'string' && item.stampText !== '' && <div className={styles.stamp}>{getSafeString(item.stampText)}</div>}
    </a>
  );
};

export default LoopWorkItemCard;
