import { Dropdown, IDropdownOption, MessageBar, Slider, Toggle } from '@fluentui/react';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { maxAmountOfCards } from '@cpa/base-core/constants';
import { IGlobalState } from '@cpa/base-core/store';
import { Schemas } from '@cp/base-types';
import classNames from 'classnames';
import { IPageSetting } from '@cpa/base-core/hooks';

import styles from './GenericComponentSettings.module.scss';

export interface IColumnDetails {
  title: string;
  fieldName: string;
}

export interface IGenericComponentSettingsProps {
  page: Schemas.CpaPage;
  updatePageSetting: ({ pageKey, setting, value }: IPageSetting) => Promise<void>;
}

const GenericComponentSettings: React.FC<IGenericComponentSettingsProps> = ({ page, updatePageSetting }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const pageSettings = useSelector((state: IGlobalState) => state.settings.pages[page.identifier!]);
  const allPages = useSelector((state: IGlobalState) => state.app.pages);
  const dashboards: IDropdownOption[] = useMemo(
    () =>
      allPages
        .filter((page) => page.isDashboard)
        .map((page) => ({
          key: page.identifier!,
          text: page.name,
          selected: !!pageSettings.showOnDashboards?.includes(page.identifier!),
        })),
    [allPages, pageSettings.showOnDashboards]
  );
  const onDashboardToggle = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) {
        return;
      }

      let newSelectedDashboards = pageSettings.showOnDashboards || [];
      if (newSelectedDashboards.includes(option.key as string)) {
        if (!option.selected) {
          newSelectedDashboards = newSelectedDashboards.filter((value) => value !== option.key);
        }
      } else {
        if (option.selected) {
          newSelectedDashboards = [...newSelectedDashboards, option.key as string];
        }
      }

      updatePageSetting({
        pageKey: page.identifier,
        setting: 'showOnDashboards',
        value: newSelectedDashboards,
      });
    },
    [page.identifier, pageSettings.showOnDashboards, updatePageSetting]
  );

  const isSmallScreen = useMediaQuery({
    query: '(max-width: 500px)',
  });

  const onSettingsKeyChange = useCallback(
    (key: string) =>
      (event: React.MouseEvent<HTMLElement>, value: boolean | undefined): void => {
        updatePageSetting({ pageKey: page.identifier, setting: key, value });
      },
    [page.identifier, updatePageSetting]
  );

  const onSliderKeyChange = useCallback(
    (key: string) =>
      (value: number): void => {
        updatePageSetting({ pageKey: page.identifier, setting: key, value });
      },
    [page.identifier, updatePageSetting]
  );

  const isWidget = useSelector((state: IGlobalState) => state.settings.pagesDrawer.isWidget);
  const columns = useSelector((state: IGlobalState) => state.settings.pagesDrawer.columns);

  const onTableColumnChange =
    (column: IColumnDetails) =>
    (event: React.MouseEvent<HTMLElement>, value: boolean | undefined): void => {
      if (!column || !columns) {
        return;
      }

      const currentDisabledColumns = isWidget ? pageSettings.widgetDisabledColumns : pageSettings.disabledColumns;
      const updatedDisabledColumns = currentDisabledColumns.filter((col) => col !== column.fieldName);
      if (value === false) {
        updatedDisabledColumns.push(column.fieldName);
      }

      updatePageSetting({
        pageKey: page.identifier,
        setting: isWidget ? 'widgetDisabledColumns' : 'disabledColumns',
        value: updatedDisabledColumns,
      });
    };

  const onTableColumnsSelectAll = useCallback(() => {
    updatePageSetting({
      pageKey: page.identifier,
      setting: isWidget ? 'widgetDisabledColumns' : 'disabledColumns',
      value: [],
    });
  }, [dispatch, isWidget, page.identifier]);

  const widgetsSettings = useMemo(() => {
    if (!isWidget) {
      return null;
    }

    return (
      <>
        {!!dashboards.length && (
          <Dropdown
            label={t('common.showOnDashboards', { pageTitle: page.name })}
            multiSelect={true}
            options={dashboards}
            onChange={onDashboardToggle}
          />
        )}
        {!!page.chart && (!pageSettings.widgetCustomView || !page.customTemplate) && (
          <Toggle
            checked={pageSettings.widgetDisplayTable}
            onChange={onSettingsKeyChange('widgetDisplayTable')}
            label={t('common.displayTable')}
            role={'checkbox'}
            disabled={pageSettings.widgetDisplayAsCards}
          />
        )}
        {!!page.customTemplate?.identifier && !!page.customSwitchText && (
          <Toggle
            checked={pageSettings.widgetCustomView}
            onChange={onSettingsKeyChange('widgetCustomView')}
            label={page.customSwitchText}
            role={'checkbox'}
          />
        )}
        {'widgetDisplayAsCards' in pageSettings && (
          <Toggle
            checked={pageSettings.widgetDisplayAsCards}
            onChange={onSettingsKeyChange('widgetDisplayAsCards')}
            label={t('common.displayAsCards')}
            role={'checkbox'}
            disabled={pageSettings.widgetCustomView && !!page.customTemplate}
          />
        )}
        {pageSettings.widgetDisplayAsCards && (
          <>
            <Slider
              label={t('common.amountOfCards')}
              max={page.maxNoOfCardsInSlider || maxAmountOfCards}
              min={1}
              value={isSmallScreen ? 1 : pageSettings.amountOfColumns || page.maxNoOfCardsInSlider}
              onChange={onSliderKeyChange('amountOfColumns')}
              disabled={(pageSettings.widgetCustomView && !!page.customTemplate) || isSmallScreen}
            />
            {isSmallScreen && <MessageBar>{t('common.amountOfCardsLimit')}</MessageBar>}
          </>
        )}
      </>
    );
  }, [
    isWidget,
    page.name,
    page.chart,
    page.customTemplate,
    page.customSwitchText,
    page.maxNoOfCardsInSlider,
    t,
    pageSettings,
    onSettingsKeyChange,
    isSmallScreen,
    onSliderKeyChange,
    dashboards,
    onDashboardToggle,
  ]);

  const screenSettings = useMemo(() => {
    if (isWidget) {
      return null;
    }
    return (
      <>
        {!!dashboards.length && (
          <Dropdown
            label={t('common.showOnDashboards', { pageTitle: page.name })}
            multiSelect={true}
            options={dashboards}
            onChange={onDashboardToggle}
          />
        )}
        {!!page.customTemplate?.identifier && !!page.customSwitchText && (
          <Toggle checked={pageSettings.customView} onChange={onSettingsKeyChange('customView')} label={page.customSwitchText} role={'checkbox'} />
        )}
        {!!page.chart && (!pageSettings.customView || !page.customTemplate) && (
          <Toggle
            checked={pageSettings.displayChart}
            onChange={onSettingsKeyChange('displayChart')}
            label={t('common.displayChart')}
            role={'checkbox'}
          />
        )}
        {!!page.chart && (!pageSettings.customView || !page.customTemplate) && (
          <Toggle
            checked={pageSettings.displayTable}
            onChange={onSettingsKeyChange('displayTable')}
            label={t('common.displayTable')}
            role={'checkbox'}
            disabled={pageSettings.displayAsCards}
          />
        )}
        {'displayAsCards' in pageSettings && (
          <Toggle
            checked={pageSettings.displayAsCards}
            onChange={onSettingsKeyChange('displayAsCards')}
            label={t('common.displayAsCards')}
            role={'checkbox'}
            disabled={pageSettings.customView && !!page.customTemplate}
          />
        )}
      </>
    );
  }, [
    isWidget,
    t,
    page.name,
    page.customTemplate,
    page.customSwitchText,
    page.chart,
    dashboards,
    onDashboardToggle,
    pageSettings,
    onSettingsKeyChange,
  ]);

  return (
    <div style={{ marginTop: 40 }}>
      <h3 style={{ marginBottom: 5, fontWeight: 300 }}>{t('header.controls.settings.title')}</h3>
      {screenSettings}
      {widgetsSettings}

      {!pageSettings.widgetDisplayAsCards && !!columns && columns.length > 0 && (
        <div className={styles.titleWrapper} style={{ display: 'flex', alignItems: 'baseline', gap: '15px' }}>
          <h3 className={styles.columnsTitle}>{t('common.columns')}</h3>
          {(isWidget ? pageSettings.widgetDisabledColumns : pageSettings.disabledColumns)?.length ? (
            <span className={classNames(styles.columnsTitle, styles.selectAll)} onClick={onTableColumnsSelectAll}>
              {t('common.selectAll')}
            </span>
          ) : null}
        </div>
      )}
      {!pageSettings.widgetDisplayAsCards &&
        !!columns &&
        columns.map((column) => (
          <Toggle
            key={column.fieldName + column.title}
            checked={
              isWidget ? !pageSettings.widgetDisabledColumns.includes(column.fieldName) : !pageSettings.disabledColumns.includes(column.fieldName)
            }
            onChange={onTableColumnChange(column)}
            label={column.title}
            role={'checkbox'}
            disabled={
              isWidget
                ? (pageSettings.widgetCustomView && !!page.customTemplate) || (!pageSettings.widgetDisplayTable && !!page.chart)
                : !pageSettings.displayTable || (pageSettings.customView && !!page.customTemplate) || pageSettings.displayAsCards
            }
          />
        ))}
    </div>
  );
};

export default GenericComponentSettings;
