import { useMemo, useRef } from 'react';
import { DialogType, IDragDropEvents } from '@fluentui/react';
import * as _ from 'lodash';
import { IDataItem, ITableProps } from '@cpa/base-core/types';
import { executeUiTriggers, getRelationWrapperPath, resolveUiTriggersCode, setParentForItem, showDialog } from '@cpa/base-core/helpers';
import { cloneDeepWithMetadata, removeSubjectUriQueryParams } from '@cp/base-utils';
import { IJSONSchema, OnBeforeUiDropExecutionContext, TypeTrigger } from '@cp/base-types';

import { CustomRowTemplates } from '../../../../../mapping';

const dragDropUnderlyingElementClass = 'dragDropUnderlyingElement';
const dragDropRowClass = 'ms-List-cell';
const dragDropTableClass = 'ms-List';

export interface ITableDragDropParams extends Pick<ITableProps, 'parentPropertyJsonPath' | 't' | 'isWidget'> {
  disableDragDrop?: boolean;
  onDragDropItemEdit?: (updatedItem: IDataItem, originalItem: IDataItem) => unknown;
  onMoveTo?: (item?: IDataItem) => void;
  readonly: boolean;
  schema: IJSONSchema | null;
  areaKey: string;
  customRowTemplate?: string;
  dragDropAreaClass?: string;
  onAreaTransfer?: (item?: IDataItem) => void;
  onDragChange?: (dragging: boolean) => void;
}

const canMoveUnderItem = (
  parentPropertyJsonPath?: string,
  originalItem?: IDataItem,
  underlyingItem?: IDataItem,
  customRowTemplate?: string
): boolean => {
  if (
    !parentPropertyJsonPath ||
    !originalItem ||
    !originalItem.identifier ||
    !underlyingItem ||
    !underlyingItem.identifier ||
    underlyingItem.identifier === originalItem.identifier
  ) {
    return false;
  }

  if (
    (!!customRowTemplate && CustomRowTemplates[customRowTemplate]?.options?.table?.dragDrop?.allowDropToParent === true) ||
    !parentPropertyJsonPath
  ) {
    return true;
  }

  const pathToRelationWrapper = getRelationWrapperPath(parentPropertyJsonPath);

  if (!_.has(originalItem, pathToRelationWrapper)) {
    return true;
  }

  const originalItemParentWrapper = _.get(originalItem, pathToRelationWrapper);

  if (
    Array.isArray(originalItemParentWrapper) &&
    originalItemParentWrapper.some(
      (parentWrapper) => !!parentWrapper && typeof parentWrapper === 'object' && parentWrapper.identifier === underlyingItem.identifier
    )
  ) {
    // Underlying item is already one of parents of original item
    return false;
  }

  if (
    !!originalItemParentWrapper &&
    typeof originalItemParentWrapper === 'object' &&
    'identifier' in originalItemParentWrapper &&
    originalItemParentWrapper.identifier === underlyingItem.identifier
  ) {
    // Underlying item is already a parent of original item
    return false;
  }

  return true;
};

function compareOrigins(a: string, b: string): boolean {
  return removeSubjectUriQueryParams(a.toLowerCase()) === removeSubjectUriQueryParams(b.toLowerCase());
}

export const useTableDragDrop = ({
  areaKey,
  parentPropertyJsonPath,
  disableDragDrop,
  onDragDropItemEdit,
  onMoveTo,
  t,
  readonly,
  schema,
  customRowTemplate,
  dragDropAreaClass = dragDropTableClass,
  onAreaTransfer,
  onDragChange,
}: ITableDragDropParams): IDragDropEvents | undefined => {
  const currentDragDropItem = useRef<IDataItem | undefined>(undefined);
  const formatBase = `application/json/cp/`;
  const formatBaseWithContext = `${formatBase}${encodeURIComponent(areaKey).toLowerCase()}/`;

  return useMemo<IDragDropEvents | undefined>(() => {
    if (disableDragDrop) {
      return;
    }

    let areaEnterCounter = 0;

    return {
      canDrop: (): boolean => {
        return !readonly;
      },
      canDrag: (item?: IDataItem): boolean => {
        // FluentUI fires group drag and drop events, so we need to filter them out by checking identifier
        return !!item?.__originalItem?.identifier || !!item?.identifier;
      },
      onDragEnter: (underlyingItem?: IDataItem, event?: DragEvent & { path?: HTMLElement[] }): string => {
        event?.stopPropagation();
        if (readonly) {
          return '';
        }

        const dataTransferIdentifier = event?.dataTransfer
          ? event.dataTransfer.types.find((type) => type.startsWith(formatBaseWithContext))?.slice(formatBaseWithContext.length)
          : undefined;

        const originalUnderlyingItem = (underlyingItem?.__originalItem as IDataItem) || underlyingItem;

        const path = event?.path || (event?.composedPath?.() as HTMLElement[] | undefined);

        const transferOrigin = event?.dataTransfer?.types.find((type) => type.startsWith(formatBase))?.split('/')[3];

        const isTransferToAnotherEntity = transferOrigin && schema?.$id && !compareOrigins(decodeURIComponent(transferOrigin), schema?.$id);

        const targetRow = path?.find((element) => element?.className?.includes(dragDropRowClass));
        const targetArea = path && [...path]?.reverse().find((element) => element?.className?.includes(dragDropAreaClass));

        if (!currentDragDropItem.current && dataTransferIdentifier && originalUnderlyingItem && parentPropertyJsonPath) {
          // Try to use identifier from event
          if (originalUnderlyingItem.identifier === dataTransferIdentifier) {
            return '';
          }
        } else if (isTransferToAnotherEntity || onAreaTransfer) {
          if (!targetArea) return '';
          areaEnterCounter++;
          targetArea.classList.add(dragDropUnderlyingElementClass);
          return '';
        } else {
          if (!canMoveUnderItem(parentPropertyJsonPath, currentDragDropItem.current, originalUnderlyingItem, customRowTemplate)) {
            return '';
          }
        }

        if (!targetRow) {
          return '';
        }

        targetRow.classList.add(dragDropUnderlyingElementClass);
        return '';
      },
      onDragLeave: (underlyingItem?: IDataItem, event?: DragEvent & { path?: HTMLElement[] }): void => {
        event?.stopPropagation();
        const path = event?.path || (event?.composedPath?.() as HTMLElement[] | undefined);

        const transferOrigin = event?.dataTransfer?.types[0].split('/')[3];
        const isTransferToAnotherEntity = transferOrigin && schema?.$id && !compareOrigins(decodeURIComponent(transferOrigin), schema?.$id);

        const targetRow = path?.find((element) => element?.className?.includes(dragDropRowClass));
        const targetArea = path && [...path]?.reverse().find((element) => element?.className?.includes(dragDropAreaClass));

        if (isTransferToAnotherEntity || onAreaTransfer) {
          if (!targetArea) return;
          areaEnterCounter--;
          if (areaEnterCounter === 0) {
            targetArea.classList.remove(dragDropUnderlyingElementClass);
          }
        } else {
          if (!targetRow) return;
          targetRow.classList.remove(dragDropUnderlyingElementClass);
        }
      },
      onDrop: async (underlyingItem?: IDataItem, event?: DragEvent): Promise<void> => {
        event?.stopPropagation();
        if (readonly) {
          return;
        }

        const originalUnderlyingItem = (underlyingItem?.__originalItem as IDataItem) || underlyingItem;
        let originalItem = currentDragDropItem.current;

        const cpTransferType = event?.dataTransfer?.types.find((type) => type.startsWith(formatBase));

        const transferOrigin = cpTransferType?.split('/')[3];
        const isTransferToAnotherEntity = transferOrigin && schema?.$id && !compareOrigins(decodeURIComponent(transferOrigin), schema?.$id);

        if (event?.dataTransfer && cpTransferType) {
          const transferredData = event.dataTransfer.getData(cpTransferType);
          if (transferredData) {
            const parsedData: IDataItem = JSON.parse(transferredData);
            if (!originalItem && parsedData) {
              originalItem = parsedData.__originalItem || parsedData;
            }
          }
        }

        const updatedItem = cloneDeepWithMetadata(originalItem!);

        if (isTransferToAnotherEntity && onMoveTo) {
          const dropConfirmed = await showDialog({
            message: t('common.moveToAnotherEntityConfirmation', {
              child: updatedItem?.name || updatedItem?.identifier,
              target: schema?.title || 'unknown',
            }),
            dialogContentProps: {
              type: DialogType.largeHeader,
            },
          });
          if (!dropConfirmed) {
            return;
          }
          onMoveTo?.(updatedItem);
          return;
        } else if (onAreaTransfer) {
          onAreaTransfer(updatedItem);
          return;
        }

        if (!canMoveUnderItem(parentPropertyJsonPath, originalItem, originalUnderlyingItem, customRowTemplate)) {
          return;
        }

        if (onDragDropItemEdit && originalItem) {
          let preventDefault: boolean = false;
          const beforeDropTriggers = schema?.cp_typeTriggers?.[TypeTrigger.OnBeforeUiDrop];
          if (Array.isArray(beforeDropTriggers) && beforeDropTriggers.length) {
            await executeUiTriggers<OnBeforeUiDropExecutionContext>(
              {
                event: TypeTrigger.OnBeforeUiDrop,
                targetItem: originalUnderlyingItem,
                droppedItem: originalItem,
                parentPropertyJsonPath,
                onDragDropItemEdit,
                preventDefault: () => {
                  preventDefault = true;
                },
                schema: schema || undefined,
              },
              await resolveUiTriggersCode(beforeDropTriggers)
            );
          }

          if (preventDefault) return;

          if (parentPropertyJsonPath && originalUnderlyingItem.identifier) {
            setParentForItem(updatedItem, parentPropertyJsonPath, originalUnderlyingItem.identifier, schema);
          }

          // Call confirmation dialog
          const dropConfirmed = await showDialog({
            message: t('common.moveConfirmation', {
              child: updatedItem?.name || updatedItem?.identifier,
              parent: originalUnderlyingItem?.name || originalUnderlyingItem?.identifier,
            }),
            dialogContentProps: {
              type: DialogType.largeHeader,
            },
          });
          if (!dropConfirmed) {
            return;
          }

          onDragDropItemEdit(updatedItem!, originalItem);
        }
      },
      onDragStart: (item?: IDataItem, itemIndex?: number, selectedItems?: IDataItem[], event?: DragEvent): void => {
        onDragChange?.(true);
        currentDragDropItem.current = (item?.__originalItem as IDataItem) || item || null;

        if (currentDragDropItem.current && event?.dataTransfer) {
          event.dataTransfer.setData(formatBaseWithContext + currentDragDropItem.current.identifier, JSON.stringify(currentDragDropItem.current));
        }
      },
      onDragEnd: (): void => {
        onDragChange?.(false);
        currentDragDropItem.current = undefined;
      },
      canDragGroups: false,
    };
  }, [
    disableDragDrop,
    readonly,
    formatBaseWithContext,
    schema,
    parentPropertyJsonPath,
    onAreaTransfer,
    formatBase,
    dragDropAreaClass,
    customRowTemplate,
    onMoveTo,
    onDragDropItemEdit,
    t,
  ]);
};
