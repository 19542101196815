const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpValueMapping',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpValueMapping' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Value Mapping',
    'properties': {
        'sourceSystem': {
            'title': 'Source System',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpSystem',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpSystem' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/sourceSystem', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/cdn',
                'http://platform.cosmoconsult.com/ontology/crm',
                'http://platform.cosmoconsult.com/ontology/data',
                'http://platform.cosmoconsult.com/ontology/erp',
                'http://platform.cosmoconsult.com/ontology/graph',
                'http://platform.cosmoconsult.com/ontology/hubdrive',
                'http://platform.cosmoconsult.com/ontology/sharepoint',
                'http://platform.cosmoconsult.com/ontology/shop',
            ],
            'enumNames': ['CDN', 'CRM', 'Data', 'ERP', 'Graph', 'Hubdrive', 'Sharepoint', 'Shop'],
            'cp_ui': { 'sortOrderForm': 30 },
        },
        'targetSystem': {
            'title': 'Target System',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpSystem',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpSystem' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/targetSystem', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/cdn',
                'http://platform.cosmoconsult.com/ontology/crm',
                'http://platform.cosmoconsult.com/ontology/data',
                'http://platform.cosmoconsult.com/ontology/erp',
                'http://platform.cosmoconsult.com/ontology/graph',
                'http://platform.cosmoconsult.com/ontology/hubdrive',
                'http://platform.cosmoconsult.com/ontology/sharepoint',
                'http://platform.cosmoconsult.com/ontology/shop',
            ],
            'enumNames': ['CDN', 'CRM', 'Data', 'ERP', 'Graph', 'Hubdrive', 'Sharepoint', 'Shop'],
            'cp_ui': { 'sortOrderForm': 40 },
        },
        'valueMappings': {
            'title': 'Value Mappings',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/ValueMap',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FValueMap' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Value Map',
                'properties': {
                    'value': {
                        'title': 'Value',
                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 20 },
                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                    },
                    'key': {
                        'title': 'Key',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 10 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 60 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/valueMappings', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>A short description of what the value mapping is doing.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
    },
};
export const CpValueMappingSchema = Schema;
