import classNames from 'classnames';
import { Checkbox, DirectionalHint, Icon, Callout, TooltipOverflowMode } from '@fluentui/react';
import React, { useCallback, useRef, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';

import styles from '../../ChoiceGroup.module.scss';
import HoverTooltip from '../../../../../../HoverTooltip/HoverTooltip';
import { IOption } from '../../ChoiceGroup';
import DefaultHtmlRenderer from '../../../../../../../components/Formatter/components/DefaultHtmlRenderer/DefaultHtmlRenderer';

interface IOptionProps {
  option: IOption;
  isSelected: boolean;
  multiple: boolean;
  disableIcon: boolean;
  onClick: (key: string) => void;
}

const Option: React.FC<IOptionProps> = ({ option, isSelected, multiple, disableIcon, onClick }) => {
  const optionRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

  const handleEnterKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!multiple && event.key === ' ') {
        event.preventDefault();
        onClick(option.key);
      }
    },
    [multiple, onClick, option.key]
  );

  const toggleCalloutVisibility = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      toggleIsCalloutVisible();
      e.currentTarget.releasePointerCapture(e.pointerId);
    },
    [toggleIsCalloutVisible]
  );

  return (
    <div
      ref={optionRef}
      key={option.key}
      className={classNames(styles.optionWrapper, {
        [styles.selected]: isSelected,
        [styles.multiple]: multiple,
      })}
    >
      <div
        onPointerEnter={toggleCalloutVisibility}
        onPointerLeave={toggleCalloutVisibility}
        onClick={() => onClick(option.key)}
        className={classNames(styles.option, {
          [styles.withoutIcon]: disableIcon,
        })}
        tabIndex={multiple ? undefined : 0}
        onKeyDown={handleEnterKeyDown}
      >
        {multiple ? (
          <div
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              onClick(option.key);
            }}
            className={classNames(styles.checkbox, { [styles.selected]: isSelected })}
          >
            <Checkbox checked={isSelected} />
          </div>
        ) : null}
        {disableIcon ? null : (
          <div className={styles.iconWrapper}>
            <Icon className={styles.icon} iconName={option.iconName} />
          </div>
        )}
        <div ref={labelRef} className={styles.textWrapper}>
          <HoverTooltip
            overflowMode={TooltipOverflowMode.Parent}
            content={option.text}
            calloutProps={{ target: labelRef, directionalHint: DirectionalHint.bottomAutoEdge }}
          >
            {option.text}
          </HoverTooltip>
        </div>
      </div>
      {option.description && isCalloutVisible ? (
        <Callout className={styles.callout} role="dialog" directionalHint={DirectionalHint.topAutoEdge} gapSpace={10} target={optionRef}>
          <div className={classNames(styles.description)}>
            <DefaultHtmlRenderer html={option.description as string} />
          </div>
        </Callout>
      ) : undefined}
    </div>
  );
};

export default Option;
