import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import classNames from 'classnames';

import HtmlContent from '../../../../../components/HtmlContent/HtmlContent';

import styles from './QuestionWidget.module.scss';

interface IQuestionWidgetProps {
  questions?: { title: string; questions: string[]; answer: string }[];
}

const QuestionsWidget: React.FC<IQuestionWidgetProps> = ({ questions }) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const content = useMemo(() => {
    return (
      <div>
        {questions?.map((question, index) => {
          return (
            <div key={index} className={styles.questionWrapper}>
              <h2 className={styles.title}>{question.title}</h2>
              <div className={classNames(styles.questions, { [styles.questionsDark]: darkMode })}>
                {question.questions?.map((q, index) => (
                  <span key={question.title + index} className={styles.question}>
                    {q}
                  </span>
                ))}
              </div>
              <HtmlContent style={{ display: 'inline' }} html={question.answer} />
            </div>
          );
        })}
      </div>
    );
  }, [darkMode, questions]);
  return <div>{content}</div>;
};

export default QuestionsWidget;
