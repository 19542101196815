import { i18n } from '@cpa/base-core/app';
import { ActionType, IActionDescriptor, useCommandBarButtons, useIsCached } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { TableActionsPosition } from '@cpa/base-core/store/settings/reducer';
import { ICustomAction } from '@cpa/base-core/types';
import { CommandBar, CommandBarButton, IButtonProps, ICommandBarItemProps, ICommandBarStyles, Icon, IContextualMenuItem } from '@fluentui/react';
import classNames from 'classnames';
import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './Actions.module.scss';

export interface IActionsRef {
  getButtonsHeight: () => number;
}

export interface IActionProps {
  isChart?: boolean | null;
  availableActions: IActionDescriptor[];
  onActionClick: (action: ActionType | string, payload?: unknown, event?: React.MouseEvent<HTMLDivElement>) => void;
  selectedItemsCount?: number | null;
  isInsideContextMenu: boolean;
  customActions?: ICustomAction[];
  onClearSelection: () => void;
}

const commandBarStyles: ICommandBarStyles = {
  root: { marginBottom: 0, padding: 0, backgroundColor: 'transparent' },
};
const contextMenuButtonStyles = {
  splitButtonContainer: {
    width: '100%',
    '& > span': {
      width: '100%',
    },
  },
};

const buttonRenderer: IContextualMenuItem['onRender'] = (buttonProps, dismissMenu) => {
  const { onClick } = buttonProps;
  const newProps: IButtonProps = {
    ...buttonProps,
    onClick(e) {
      const result = onClick?.(e);
      dismissMenu({ ...e, defaultPrevented: false }, true);
      return result;
    },
  };
  return <CommandBarButton {...newProps} dir={'ltr'} />;
};

const labelRenderer: (
  selectedItemsCount: number,
  onClear: () => void,
  ...args: Parameters<NonNullable<IContextualMenuItem['onRender']>>
) => JSX.Element | null = (selectedItemsCount, onClear, itemProps) => {
  if (!itemProps.text) {
    return null;
  }

  return (
    <div style={itemProps.style} className={itemProps.className} dir={'ltr'}>
      {itemProps.text}
      {selectedItemsCount ? (
        <HoverTooltip content={i18n.t('header.controls.search.deselectAll')}>
          <Icon className={styles.cancelIcon} iconName={'Cancel'} onClick={onClear} />
        </HoverTooltip>
      ) : null}
    </div>
  );
};

const OverflowButtonComponent = (props: IButtonProps): JSX.Element => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const processedProps = {
    ...props,
    className: classNames(props.className, darkMode ? styles.controlDark : styles.control),
    menuProps: {
      ...(props.menuProps || {}),
      items: props.menuProps
        ? props.menuProps.items.map((item: IContextualMenuItem) => {
            const { split, ...processed } = item;
            if (split && !processed?.menuProps?.items?.find?.((i: IContextualMenuItem) => i.key === processed.key)) {
              const { menuProps, ...duplicate } = processed;
              processed?.menuProps?.items?.unshift?.({
                ...duplicate,
                onRender: undefined,
              });
            }
            return {
              ...processed,
              onRender: buttonRenderer,
              className: classNames(
                darkMode ? styles.overflowControlDark : styles.overflowControl,
                darkMode ? styles.buttonsContainerDark : styles.buttonsContainer
              ),
            };
          })
        : [],
    },
  };
  return <CommandBarButton {...processedProps} />;
};
const Actions = React.forwardRef(function _Actions(
  {
    availableActions,
    onActionClick,
    selectedItemsCount,
    isChart,
    isInsideContextMenu,
    customActions: customActions = [],
    onClearSelection,
  }: IActionProps,
  ref: React.Ref<IActionsRef>
) {
  // FluentUI doesn't pass updated buttons to underlying OverflowSet for some reason.
  const latestOnActionClick = useRef(onActionClick);
  const tableActionsPosition = useSelector((state: IGlobalState) => state.settings.tableActionsPosition);
  latestOnActionClick.current = onActionClick;

  const [t] = useTranslation();
  const isCached = useIsCached();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const buttons = useCommandBarButtons(
    availableActions,
    latestOnActionClick,
    classNames(darkMode ? styles.controlDark : styles.control, darkMode ? styles.buttonsContainerDark : styles.buttonsContainer),
    classNames(styles.splitControlWrapper, { [styles.splitControlWrapperLeft]: !(tableActionsPosition === TableActionsPosition.Right) }),
    darkMode ? styles.controlDark : styles.control,
    customActions
  );

  const farItems: ICommandBarItemProps[] = useMemo(
    () =>
      selectedItemsCount == null || (!selectedItemsCount && isChart)
        ? []
        : [
            {
              key: 'counter',
              text: t('common.selected', { amount: selectedItemsCount }),
              style: { cursor: 'default', backgroundColor: 'transparent' },
              className: styles.selectedItemsLabel,
              commandBarButtonAs: (itemProps, dismissMenu) => labelRenderer(selectedItemsCount, () => onClearSelection(), itemProps, dismissMenu),
            },
          ],
    [selectedItemsCount, isChart, t, onClearSelection]
  );

  useImperativeHandle(
    ref,
    () => ({
      getButtonsHeight: (): number => buttons.length * 40,
    }),
    [buttons]
  );
  if (isCached) return null;

  if (isInsideContextMenu) {
    return (
      <div className={darkMode ? styles.buttonsContainerDark : styles.buttonsContainer} style={{ display: 'grid', width: 'max-content' }}>
        {buttons.map((btnProps) => {
          return (
            <CommandBarButton
              key={btnProps.key}
              {...(btnProps as IButtonProps)}
              styles={contextMenuButtonStyles}
              className={classNames(styles.contextMenuBtn, !!btnProps.split && styles.withDivider)}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <CommandBar
        className={darkMode ? styles.buttonsContainerDark : styles.buttonsContainer}
        items={buttons.slice().reverse()}
        styles={commandBarStyles}
        farItems={farItems}
        // FluentUI replaces items with '...' icon, not farItems
        dir={tableActionsPosition === TableActionsPosition.Right ? 'rtl' : 'ltr'}
        overflowButtonAs={OverflowButtonComponent}
      />
    </div>
  );
});

export default React.memo(Actions);
