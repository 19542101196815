const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://schema.org/Thing',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FThing' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTag?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FComment?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=documents%2Fabout%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        { 'rel': 'related', 'href': "data-store/http%3A%2F%2Fschema.org%2FThing?$filter=about%2Fidentifier%20eq%20'{identifier}'" },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FRating?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FVoting?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "customer/{{accountNo}}/ticket-related/comment?$filter=about%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
        { 'rel': 'related', 'href': "documents?$filter=about%2Fidentifier%20eq%20'{identifier}'", 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypeVersion?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpMessageState?$filter=about%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Thing',
    'description': 'The most generic type of item.',
    'properties': {
        'about': {
            'title': 'About',
            'description': 'The most generic type of item.',
            '$id': 'http://schema.org/Thing',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FThing' },
                { 'href': 'http://schema.org/about', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 36 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 29 },
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': {
                'anyOf': [
                    { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'], 'title': 'List of String' },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                                'cp_ui': { 'sortOrderForm': 29 },
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                        'format': 'email',
                        'transform': ['toLowerCase', 'trim'],
                    },
                ],
                'format': 'email',
                'transform': ['toLowerCase', 'trim'],
            },
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                                'cp_ui': { 'sortOrderForm': 45 },
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'cp_ui': { 'sortOrderForm': 27 },
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 26 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 28 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 27 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const ThingSchema = Schema;
