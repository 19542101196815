const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/User',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'users', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
        {
            'rel': 'related',
            'href': "/sales-opportunities?$filter=owner%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Fppi%2FArtifactSignRequest?$filter=approvers%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'User',
    'properties': {
        'userPrincipalName': {
            'title': 'User Principal Name',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 95 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/userPrincipalName', 'rel': 'canonical' }],
        },
        'email': {
            'title': 'Email',
            'description': 'Email address.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 90 },
            'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
        },
        'familyName': {
            'title': 'Family Name',
            'description': '<div>Last name, surname, or family name.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 88 },
            'links': [{ 'href': 'http://schema.org/familyName', 'rel': 'canonical' }],
        },
        'givenName': {
            'title': 'Given Name',
            'description': '<div>First or given name. The name given to a person at birth, distinguishing them from their family name.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://schema.org/givenName', 'rel': 'canonical' }],
        },
        'jobTitle': {
            'title': 'Job Title',
            'description': 'The job title of the person (for example, Financial Manager).',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 89 },
            'links': [{ 'href': 'http://schema.org/jobTitle', 'rel': 'canonical' }],
        },
        'knowsLanguage': {
            'title': 'Knows Language',
            'description': 'Natural languages such as Spanish, Tamil, Hindi, English, etc. Formal language code tags expressed in [BCP 47](https://en.wikipedia.org/wiki/IETF_language_tag) can be used via the [[alternateName]] property. The Language type previously also covered programming languages such as Scheme and Lisp, which are now best represented using [[ComputerLanguage]].',
            '$id': 'http://schema.org/Language',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FLanguage' },
                { 'href': 'http://schema.org/knowsLanguage', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 93 },
        },
        'telephone': {
            'title': 'Telephone',
            'description': '<div>The telephone number at which a company or person can be reached during business hours.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://schema.org/telephone', 'rel': 'canonical' }],
        },
        'workLocation': {
            'title': 'Work Location',
            'description': 'Entities that have a somewhat fixed, physical extension.',
            '$id': 'http://schema.org/Place',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPlace' },
                { 'href': 'http://schema.org/workLocation', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 92 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 85 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 86 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const UserSchema = Schema;
