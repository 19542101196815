import { IconButton, IPanelHeaderRenderer, Panel, PanelType, IPanelProps, PanelBase } from '@fluentui/react';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useAutoControlled, useDrawerWidth } from '@cpa/base-core/hooks';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import dark from './styles/dark.module.scss';
import light from './styles/light.module.scss';

export interface ISettingsDrawerProps extends IPanelProps {
  isOpen: boolean;
  onClose: (...args: unknown[]) => void;
  title?: string;
  onOpen?: () => void;
  width?: string;
  panelType?: PanelType;
  onRenderHeader?: IPanelHeaderRenderer;
  doNotFocusOnOpen?: boolean;
  defaultMaximized?: boolean;
  disableMaximizing?: boolean;
  hideMaximizing?: boolean;
  className?: string;
  headerClassName?: string;
  extraButtonsRender?: () => JSX.Element | JSX.Element[] | null;
  wheelEventHandler?: (e: React.WheelEvent<PanelBase>) => void;
}

const Drawer: React.FC<ISettingsDrawerProps> = ({
  onClose,
  defaultMaximized,
  disableMaximizing,
  hideMaximizing,
  title,
  children,
  width,
  onRenderHeader,
  doNotFocusOnOpen,
  className,
  headerClassName,
  extraButtonsRender,
  panelType,
  wheelEventHandler,
  ...rest
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [isMaximized, setMaximized] = useAutoControlled({
    defaultValue: false,
    value: defaultMaximized,
    initialValue: !!defaultMaximized,
  });
  const { responsiveWidth, mobileOnly } = useDrawerWidth({ isMaximized, width });
  const styles = useMemo(() => (darkMode ? dark : light), [darkMode]);

  const toggleMaximizeButton = useCallback(() => {
    setMaximized((isMax) => !isMax);
  }, [setMaximized]);

  const onDismiss = useCallback(
    (event: React.SyntheticEvent<HTMLElement>) => {
      if (event instanceof MouseEvent) {
        return;
      }
      onClose();
    },
    [onClose]
  );
  const renderHeader: IPanelHeaderRenderer = useCallback(
    (...props: Parameters<IPanelHeaderRenderer>) => (
      <div className={classNames(styles.headerRoot, headerClassName)}>
        <div className={styles.headerRow}>
          {onRenderHeader?.(...props)}
          <p className={styles.headerTitle}>{title}</p>
          {extraButtonsRender?.()}
          {!mobileOnly && !hideMaximizing && (
            <IconButton
              iconProps={{ iconName: 'MaximumValue' }}
              onClick={toggleMaximizeButton}
              ariaLabel="Maximize"
              className={styles.maximizeButton}
              disabled={!!disableMaximizing}
              style={{ color: isMaximized ? undefined : 'rgb(165, 167, 165)' }}
            />
          )}
        </div>
      </div>
    ),
    [
      headerClassName,
      hideMaximizing,
      disableMaximizing,
      isMaximized,
      mobileOnly,
      onRenderHeader,
      title,
      toggleMaximizeButton,
      styles,
      extraButtonsRender,
    ]
  );

  const overlayProps = useMemo(() => ({ onClick: onClose }), [onClose]);

  const focusTrapZoneProps = useMemo(
    () => ({
      disabled: !!doNotFocusOnOpen,
      forceFocusInsideTrap: false,
      ignoreExternalFocusing: true,
      disableRestoreFocus: true,
    }),
    [doNotFocusOnOpen]
  );

  return (
    <Panel
      onDismiss={onDismiss}
      className={classNames(styles.settings, className)}
      headerText={title}
      customWidth={responsiveWidth}
      type={panelType ?? PanelType.custom}
      isLightDismiss={false}
      overlayProps={overlayProps}
      focusTrapZoneProps={focusTrapZoneProps}
      onRenderHeader={renderHeader}
      onWheel={wheelEventHandler}
      {...rest}
    >
      {children}
    </Panel>
  );
};

export default Drawer;
