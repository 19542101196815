import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Checkbox, ThemeContext, TooltipOverflowMode } from '@fluentui/react';
import { FormatSource } from '@cpa/base-core/constants';
import { useTranslation } from 'react-i18next';
import { FacetFilterPropertyValue, IFacetFilterFieldItemData } from '@cp/base-utils';

import { IFacetFilterData } from '../../../helpers/getFacetFilters';
import HoverTooltip from '../../../../../../HoverTooltip/HoverTooltip';
import { formatValue } from '../../../../../../Formatter/formatter';

import styles from './FacetFilterItemCheckbox.module.scss';

interface IFacetFiltersProps {
  facetFilter: IFacetFilterData;
  facetFilterItem: IFacetFilterFieldItemData;

  onToggleSelect: (propertyJsonPath: string, selectedValues: FacetFilterPropertyValue | FacetFilterPropertyValue[]) => void;

  darkMode: boolean;
}

const FacetFilterItem: React.FC<IFacetFiltersProps> = ({ facetFilter, facetFilterItem, onToggleSelect, darkMode }) => {
  const itemTitle = useMemo(() => {
    if (facetFilter.property.type === 'boolean') {
      return facetFilter.property.propertySchema.title!;
    } else if (facetFilterItem.title) {
      return facetFilterItem.title;
    } else {
      return `${facetFilterItem._id}`;
    }
  }, [facetFilterItem, facetFilter]);

  const [t, i18n] = useTranslation();

  const itemTitleFormatted = useMemo(() => {
    if (facetFilter.property.type === 'boolean') {
      return itemTitle;
    }

    return formatValue(itemTitle, FormatSource.Drawer, {
      schema: facetFilter.property.propertySchema,
      locale: i18n.language,
      darkMode: darkMode,
      t: t,
      itemKey: facetFilter.property.propertyJsonPath,
      rootSchema: facetFilter.property.items[0].propertySchema,
      originalItem: facetFilterItem,
      disableTooltip: true,
    });
  }, [
    darkMode,
    facetFilter.property.type,
    facetFilter.property.items,
    facetFilter.property.propertyJsonPath,
    facetFilter.property.propertySchema,
    facetFilterItem,
    i18n.language,
    itemTitle,
    t,
  ]);

  const itemCounts = useMemo(() => {
    return facetFilterItem.count;
  }, [facetFilterItem]);

  const [forceIsSelectedValue, setForceIsSelectedValue] = useState<boolean | undefined>();

  const isSelected = useMemo(() => {
    return forceIsSelectedValue !== undefined ? forceIsSelectedValue : facetFilter.selectedItems?.includes(facetFilterItem._id);
  }, [forceIsSelectedValue, facetFilter.selectedItems, facetFilterItem._id]);

  const handleCheckboxChange = useCallback(async () => {
    setForceIsSelectedValue(!isSelected);

    await new Promise((resolve) => setTimeout(resolve, 50));

    onToggleSelect(facetFilter.property.propertyJsonPath, facetFilterItem._id);
  }, [isSelected, setForceIsSelectedValue, onToggleSelect, facetFilter, facetFilterItem._id]);

  return (
    <label
      className={classNames({
        [styles.container]: true,
        [styles.containerDark]: darkMode,
      })}
    >
      <Checkbox
        className={classNames({
          [styles.checkbox]: true,
          [styles.checkboxDark]: darkMode,
          [styles.checkboxUnselected]: !isSelected,
        })}
        checked={isSelected}
        onChange={handleCheckboxChange}
      />
      <span
        className={classNames({
          [styles.text]: true,
          [styles.textDark]: darkMode,
        })}
      >
        {typeof itemTitleFormatted === 'string' ? (
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={itemTitleFormatted || undefined}>
            {itemTitleFormatted}
          </HoverTooltip>
        ) : (
          itemTitleFormatted
        )}
      </span>
      <span
        className={classNames({
          [styles.counter]: true,
          [styles.counterDark]: darkMode,
        })}
      >
        {itemCounts}
      </span>
    </label>
  );
};

export default React.memo(FacetFilterItem);
