const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpaLink',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaLink' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa Link',
    'properties': {
        'icon': {
            'title': 'Icon',
            'description': '<div>The friendly name of the symbol for this page. Use https://storybook.platform.cosmoconsult.com/?path=/story/icons--all-icons to find icons (incl. friendly name).</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/icon', 'rel': 'canonical' }],
        },
        'endpoints': {
            'title': 'Endpoints',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaEndpointEnvironments',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaEndpointEnvironments' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/endpoints', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'developmentEndpoint': {
                    'title': 'Development Endpoint',
                    'description': '<div>The link target for the development endpoint.</div>',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/developmentEndpoint', 'rel': 'canonical' }],
                },
                'localEndpoint': {
                    'title': 'Local Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/localEndpoint', 'rel': 'canonical' }],
                },
                'productionEndpoint': {
                    'title': 'Production Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/productionEndpoint', 'rel': 'canonical' }],
                },
                'stagingEndpoint': {
                    'title': 'Staging Endpoint',
                    'type': 'string',
                    'format': 'uri',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/stagingEndpoint', 'rel': 'canonical' }],
                },
            },
            'required': ['developmentEndpoint', 'localEndpoint', 'productionEndpoint', 'stagingEndpoint'],
            'cp_ui': { 'sortOrderForm': 29 },
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                                'cp_ui': { 'sortOrderForm': 19 },
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 28 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The displayed name of the app.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['icon', 'name'],
};
export const CpaLinkSchema = Schema;
