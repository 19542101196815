import { Environment } from '@cp/base-types';
import dcopy from 'deep-copy';
import { ConnectionLogger } from '../logger';
import { createDefaultConnections } from '..';
import { ConsoleBinding } from './consoleBinding';
export class LogManager {
    get consoleCopy() {
        return dcopy(this.globalConsoleCopy);
    }
    constructor(connections, customConsole, sender) {
        const baseConnections = Array.isArray(connections) ? connections : [connections];
        this.globalConsole = customConsole !== null && customConsole !== void 0 ? customConsole : Environment.console;
        this.globalConsoleCopy = dcopy(this.globalConsole);
        this._senderName = sender;
        this._customConnections = new Map();
        this._appName = Environment.appName;
        this._hostName = Environment.hostName;
        this.bindConsole({
            name: this._senderName,
            connections: baseConnections,
        }, this.globalConsole);
    }
    bindConsole(options, console) {
        var _a;
        const logger = new ConnectionLogger((_a = options.connections) !== null && _a !== void 0 ? _a : createDefaultConnections(), options.name, this.consoleCopy);
        const consoleToBind = console !== null && console !== void 0 ? console : this.consoleCopy;
        const consoleBinding = new ConsoleBinding(consoleToBind, logger);
        return consoleToBind;
    }
}
