const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Ticket',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'customer/{{accountNo}}/ticket', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
        {
            'rel': 'related',
            'href': "customer/{{accountNo}}/ticket-related/comment?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
    ],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Ticket',
    'properties': {
        'status': {
            'title': 'Status',
            'description': 'The status of a thing',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketStatusType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketStatusType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Canceled',
                'http://platform.cosmoconsult.com/ontology/CustomerFeedback',
                'http://platform.cosmoconsult.com/ontology/EscalatedToThirdParty',
                'http://platform.cosmoconsult.com/ontology/InProgress',
                'http://platform.cosmoconsult.com/ontology/InformationProvided',
                'http://platform.cosmoconsult.com/ontology/Merged',
                'http://platform.cosmoconsult.com/ontology/NotReproducable',
                'http://platform.cosmoconsult.com/ontology/OnHold',
                'http://platform.cosmoconsult.com/ontology/Open',
                'http://platform.cosmoconsult.com/ontology/ProblemSolved',
            ],
            'enumNames': [
                'Canceled',
                'Customer feedback',
                'Escalated to third party',
                'In progress',
                'Information provided',
                'Merged',
                'Not reproducible',
                'On hold',
                'Open',
                'Problem solved',
            ],
            'readOnly': true,
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 65 },
        },
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 60 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'priority': {
            'title': 'Priority',
            'type': 'string',
            'maxLength': 10,
            'cp_ui': { 'sortOrderForm': 62 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/priority', 'rel': 'canonical' }],
        },
        'additionalContact': {
            'title': 'Additional Contact',
            'type': 'string',
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'sortOrderForm': 64 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/additionalContact', 'rel': 'canonical' }],
        },
        'caseType': {
            'title': 'Case Type',
            'description': '<div>Please select the purpose of your ticket.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketCaseType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketCaseType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/caseType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/ChangeRequest',
                'http://platform.cosmoconsult.com/ontology/Question',
                'http://platform.cosmoconsult.com/ontology/TechnicalSupport',
            ],
            'enumNames': ['Change request', 'Question', 'Technical support'],
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 55 },
        },
        'contact': {
            'title': 'Contact',
            'type': 'string',
            'readOnly': true,
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 66 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/contact', 'rel': 'canonical' }],
        },
        'entitlement': {
            'title': 'Arrangement',
            'description': '<div>Service contract, support agreement etc.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Entitlement',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'customer/{{accountNo}}/entitlement', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/entitlement', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Arrangement' } },
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'hiddenIfUpdate': true, 'sortOrderForm': 52 },
        },
        'entitlementName': {
            'title': 'Arrangement',
            'description': '<div>Service contract, support agreement etc.</div>',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 53 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/entitlementName', 'rel': 'canonical' }],
        },
        'externalReference': {
            'title': 'Your Reference',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 61 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/externalReference', 'rel': 'canonical' }],
        },
        'severity': {
            'title': 'Severity',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketSeverityType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketSeverityType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/severity', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/TSCritical',
                'http://platform.cosmoconsult.com/ontology/TSHigh',
                'http://platform.cosmoconsult.com/ontology/TSMedium',
                'http://platform.cosmoconsult.com/ontology/TSLow',
            ],
            'enumNames': ['1 - Critical', '2 - High', '3 - Medium', '4 - Low'],
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 58 },
        },
        'affectedSolutions': {
            'title': 'Affected Solutions',
            'description': '<div>Please select the solution(s) that concern your ticket.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Solution',
                'type': 'object',
                'links': [
                    {
                        'rel': 'collection',
                        'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution',
                        'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
                    },
                ],
                'cp_parentPropertyJsonPath': 'parentSolution.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Solution',
                'properties': { 'identifier': { 'type': 'string', 'title': 'Solution' } },
            },
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'hiddenIfUpdate': true, 'sortOrderForm': 54 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/affectedSolution', 'rel': 'canonical' }],
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': '<div>Describe your issue in short.</div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 57 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'sortOrderForm': 63 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 51 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Describe your issue in detail. Please add repro steps if applicable.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 59 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Subject',
            'description': '<div>Give your ticket a name / title / subject.</div>',
            'type': 'string',
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 56 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['shortDescription', 'name', 'caseType', 'severity', 'entitlement'],
    'cp_restrictablePaths': ['affectedSolutions'],
    'cp_hasPermissions': true,
};
export const TicketSchema = Schema;
