const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CrmAccount',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': '/crmAccounts', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Crm Account',
    'properties': {
        'customers': {
            'title': 'Customers',
            'description': 'Party placing the order or paying the invoice.',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/Customer',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'customer/all', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' }],
                'cp_handledByApiGateway': true,
                'cp_disableTrash': true,
                'cp_disableLocalization': true,
                'cp_disableVersions': true,
                'cp_disableClone': true,
                'cp_disableLazyLoading': true,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Customer',
                'description': '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
                'properties': {
                    'identifier': {
                        'title': 'Identifier',
                        'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 166 },
                        'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 94 },
            'links': [{ 'href': 'http://schema.org/customer', 'rel': 'canonical' }],
        },
        'alternateIdentifier': {
            'title': 'Alternate Identifier',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/alternateIdentifier', 'rel': 'canonical' }],
        },
        'owner': {
            'title': 'Owner',
            'description': 'A person (alive, dead, undead, or fictional).',
            '$id': 'http://schema.org/Person',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FPerson' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'sortOrderForm': 95 },
        },
        'businessRelations': {
            'title': 'Business Relations',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/BusinessRelation',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessRelation' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Business Relation',
                'properties': {
                    'businessUnit': {
                        'title': 'Business Unit',
                        '$id': 'http://platform.cosmoconsult.com/ontology/BusinessUnit?allData=true',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessUnit%3FallData%3Dtrue' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/businessUnit', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': { 'identifier': { 'type': 'string', 'title': 'Business Unit' } },
                        'cp_ui': { 'sortOrderForm': 28 },
                    },
                    'externalIdentifier': {
                        'title': 'External Identifier',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 24 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/externalIdentifier', 'rel': 'canonical' }],
                    },
                    'businessRelationType': {
                        'title': 'Business Relation Type',
                        'type': 'array',
                        'items': {
                            '$id': 'http://platform.cosmoconsult.com/ontology/BusinessRelationType',
                            'type': 'string',
                            'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FBusinessRelationType' }],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'title': 'Business Relation',
                            'anyOf': [
                                {
                                    'type': 'string',
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Customer'],
                                    'enumNames': ['Customer'],
                                    'title': 'Customer',
                                    'cp_enumIcons': ['CpCustomer'],
                                    'description': '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
                                    'cp_enumDescriptions': [
                                        '<div>Customer of COSMO CONSULT as a natural person, legal entity or as a natural person acting on behalf of a legal entity.</div>',
                                    ],
                                },
                                {
                                    'type': 'string',
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Prospect'],
                                    'enumNames': ['Prospect'],
                                    'title': 'Prospect',
                                    'cp_enumIcons': ['ReminderPerson'],
                                    'description': '<div>Natural or legal person who is interested in the products and/or services of COSMO CONSULT.</div>',
                                    'cp_enumDescriptions': ['<div>Natural or legal person who is interested in the products and/or services of COSMO CONSULT.</div>'],
                                },
                                {
                                    'type': 'string',
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Supplier'],
                                    'enumNames': ['Supplier'],
                                    'title': 'Supplier',
                                    'cp_enumIcons': ['Teamwork'],
                                    'description': '<div>Supplier of COSMO CONSULT as a natural person, legal entity or as a natural person in the external relationship for a legal entity.</div>',
                                    'cp_enumDescriptions': [
                                        '<div>Supplier of COSMO CONSULT as a natural person, legal entity or as a natural person in the external relationship for a legal entity.</div>',
                                    ],
                                },
                                {
                                    'type': 'string',
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Partner'],
                                    'enumNames': ['Partner'],
                                    'title': 'Partner',
                                    'cp_enumIcons': ['ContactHeart'],
                                    'description': '<div>Natural or legal person who has a partner contract with COSMO CONSULT.</div>',
                                    'cp_enumDescriptions': ['<div>Natural or legal person who has a partner contract with COSMO CONSULT.</div>'],
                                },
                                {
                                    'type': 'string',
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Press'],
                                    'enumNames': ['Press'],
                                    'title': 'Press',
                                    'cp_enumIcons': ['UserOptional'],
                                    'description': '<div>Acting for or as press and/or media services.</div>',
                                    'cp_enumDescriptions': ['<div>Acting for or as press and/or media services.</div>'],
                                },
                                {
                                    'type': 'string',
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Reseller'],
                                    'enumNames': ['Reseller'],
                                    'title': 'Reseller',
                                    'cp_enumIcons': ['TeamFavorite'],
                                    'description': '<div>\n<div>Reseller of the products and/or services of COSMO CONSULT.</div>\n</div>',
                                    'cp_enumDescriptions': ['<div>\n<div>Reseller of the products and/or services of COSMO CONSULT.</div>\n</div>'],
                                },
                            ],
                        },
                        'uniqueItems': true,
                        'cp_ui': { 'sortOrderForm': 25 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/businessRelationType', 'rel': 'canonical' }],
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 93 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/businessRelation', 'rel': 'canonical' }],
        },
        'email': {
            'title': 'Email',
            'description': '<div>The name of the account.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 90 },
            'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
        },
        'telephone': {
            'title': 'Telephone',
            'description': '<div>The name of the account.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://schema.org/telephone', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': '<div>The identifier of the account.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 86 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>The name of the account.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 89 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>The name of the account.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 88 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>The name of the account.</div>',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 92 },
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
    },
};
export const CrmAccountSchema = Schema;
