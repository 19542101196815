const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Subscription',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSubscription' }],
    'cp_handledByApiGateway': true,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': true,
    'cp_disableLazyLoading': true,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Subscription',
    'properties': {
        'orderQuantity': {
            'title': 'Order Quantity',
            'description': 'The number of the item ordered. If the property is not set, assume the quantity is one.',
            'type': 'number',
            'cp_ui': { 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://schema.org/orderQuantity', 'rel': 'canonical' }],
        },
        'orderedItem': {
            'title': 'Ordered Item',
            'description': 'An order item is a line of an order. It includes the quantity and shipping details of a bought offer.',
            '$id': 'http://schema.org/OrderItem',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fschema.org%2FOrderItem' },
                { 'href': 'http://schema.org/orderedItem', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 20 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'sortOrderForm': 35 },
        },
        'endDate': {
            'title': 'End Date',
            'description': 'The end date of a thing',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 38 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
        },
        'startDate': {
            'title': 'Start Date',
            'description': 'The start date of a thing',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 37 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/startDate', 'rel': 'canonical' }],
        },
        'status': {
            'title': 'Status',
            'description': 'The status of a thing',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 39 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' }],
        },
        'marketplaceAccount': {
            'title': 'Marketplace Account',
            '$id': 'http://platform.cosmoconsult.com/ontology/MarketplaceAccount',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FMarketplaceAccount' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceAccount', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': true,
            'cp_disableTrash': true,
            'cp_disableLocalization': true,
            'cp_disableVersions': true,
            'cp_disableClone': true,
            'cp_disableLazyLoading': true,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'identifier': {
                    'title': 'Identifier',
                    'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 87 },
                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                },
                'name': {
                    'title': 'Name (Title or Designation)',
                    'description': 'The name of the thing or subject.',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 147 },
                    'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                },
            },
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 40 },
        },
        'marketplaceRegion': {
            'title': 'Marketplace Region',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/marketplaceRegion', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'cp_hasPermissions': true,
};
export const SubscriptionSchema = Schema;
