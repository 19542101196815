const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa?$filter=configurations%2FdevelopmentConfiguration%2FsupportedCpCultures%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa?$filter=configurations%2FlocalConfiguration%2FsupportedCpCultures%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa?$filter=configurations%2FproductionConfiguration%2FsupportedCpCultures%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa?$filter=configurations%2FstagingConfiguration%2FsupportedCpCultures%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FGdprVendorContract?$filter=culture%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "businessunits?$filter=culture%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution?$filter=documents%2Fculture%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FGdprCustomerContract?$filter=culture%2Fidentifier%20eq%20'{identifier}'",
        },
        { 'rel': 'related', 'href': "documents?$filter=culture%2Fidentifier%20eq%20'{identifier}'", 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTargetGroup?$filter=cultures%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FMachineTranslationCache?$filter=cpCulture%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Culture',
    'description': 'A culture based on RFC 4646',
    'properties': {
        'icon': {
            'title': 'Icon',
            'description': '<div>The icon used to display the language in the user interface.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/icon', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FLanguage' },
                { 'href': 'http://schema.org/identifier', 'rel': 'canonical' },
            ],
        },
        'alternateName': {
            'title': 'Native Name',
            'description': '<div>The name of the culture as endonym (as the culture it writes).</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>Enter here the localized name of the culture.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['icon', 'identifier', 'name'],
};
export const CpCultureSchema = Schema;
