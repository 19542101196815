import { ActivityItem } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IHistoryItem, IWorkItem } from '../../helpers/types';
import { getInitials, isExternalUser } from '../../helpers/utils';

import styles from './ActivityItemPersona.module.scss';

interface IActivityItemPersonaProps {
  workItem: IWorkItem;
  historyItem: IHistoryItem;
}

const ActivityItemPersona: React.FC<IActivityItemPersonaProps> = ({ workItem, historyItem }) => {
  const [t] = useTranslation();
  const externalUser: boolean = isExternalUser(historyItem.revisedBy.uniqueName);
  const fullName: string = externalUser ? historyItem.revisedBy.displayName : t('common.externalUser');
  const initials: string = getInitials(fullName);
  const persona = {
    imageUrl: historyItem.revisedBy.imageUrl,
    imageInitials: initials,
  };
  const activityItem = {
    activityDescription: [
      <span key={1} className={styles.nameText}>
        {externalUser ? (
          <a
            href={`https://teams.microsoft.com/l/chat/0/0?users=${historyItem.revisedBy.uniqueName}&message=Hello ${
              fullName.split(' ')[0]
            }, i have a question regarding ${workItem.workItemType} with Title ${workItem.title}. ${historyItem.url.split('_api')[0]}${
              workItem.teamProject
            }/_workitems/edit/${workItem.workItemId}`}
          >
            {fullName}
          </a>
        ) : (
          fullName
        )}
      </span>,
      <span key={2}> {t('common.historyItemText')}</span>,
    ],
    activityPersonas: [persona],
    timeStamp: new Date(historyItem.revisedDate).toLocaleDateString(),
  };

  return <ActivityItem {...activityItem} className={styles.exampleRoot} />;
};

export default ActivityItemPersona;
