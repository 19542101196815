import { IGlobalState } from '@cpa/base-core/store';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { formatCurrency, getMatchingEnum } from '@cpa/base-core/helpers';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import * as _ from 'lodash';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
import { DirectionalHint, Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { Quantity } from '../../SolutionSingleItemTemplate';
import Localization from '../Localization/Localization';
import HoverTooltip from '../../../../../components/HoverTooltip/HoverTooltip';

import styles from './SaleableList.module.scss';

type Unpacked<T> = T extends (infer U)[] ? U : T;

type SolutionTypeDetails = Unpacked<Schemas.Solution['solutionTypeDetails']>;

type Offers = Extract<SolutionTypeDetails, { offers?: object }>['offers'];

type Offer = Extract<Offers, {}>[0];

type PriceSpecification = Offer['priceSpecification'];

type PriceComponent = Extract<Extract<PriceSpecification, {}>[0]['priceComponent'], {}>[0];

interface ISaleableListProps {
  value: Extract<SolutionTypeDetails, { offers?: object }>['offers'];
  schema: IJSONSchema;
  formatQuantity: (quantity: Quantity) => string | undefined;
  formatBillingDuration: (billingDuration: string) => string | undefined;
  renderHeader?: () => JSX.Element;
  wrapperClassName?: string;
  commonLocalizationProps: {
    page: Schemas.CpaPage;
    rootSchema: IJSONSchema;
    identifier: string;
    baseLanguage: string;
  };
  dataIndex?: number;
}

const CARD_WIDTH = 250;

const SaleableList: React.FC<ISaleableListProps> = ({
  value,
  schema,
  formatQuantity,
  formatBillingDuration,
  renderHeader,
  wrapperClassName,
  commonLocalizationProps,
  dataIndex,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [width, setWidth] = useState(0);

  const [t] = useTranslation();

  const { ref: resizeRef } = useResizeDetector({
    onResize: (width) => {
      setWidth(width || 0);
    },
  });

  const groupSize = useMemo(() => {
    return Math.floor(width / CARD_WIDTH);
  }, [width]);

  const content = useMemo(() => {
    const priceComponents = schema.items?.properties?.priceSpecification.items?.properties?.priceComponent.items?.properties;
    const cards: (JSX.Element | undefined)[] = [];
    for (const item of Array.isArray(value) ? value : [value]) {
      if (!item || !item.priceSpecification) return null;
      for (const specification of item.priceSpecification) {
        const filteredPriceComponents = specification?.priceComponent?.filter((component) => typeof component.price === 'number');
        if (!filteredPriceComponents?.length) continue;
        cards.push(
          <div className={classNames({ [styles.specificationLight]: !darkMode, [styles.specificationDark]: darkMode })}>
            <p className={styles.specificationName}>
              {`${!item.name || specification.name === item.name ? '' : `${item.name} - `} ${
                specification.name || getMatchingEnum(schema.items!, 'businessFunction', item.businessFunction)
              }`}
            </p>
            {filteredPriceComponents &&
              filteredPriceComponents.map((component: PriceComponent) => {
                const componentType = component.priceComponentType;
                const componentTypeName = getMatchingEnum(
                  schema.items?.properties?.priceSpecification.items?.properties?.priceComponent.items!,
                  'priceComponentType',
                  componentType as string
                );
                return (
                  <div key={component.priceComponentType} className={styles.priceComponent}>
                    {componentTypeName && <span className={styles.componentType}>{componentTypeName}</span>}
                    <div className={styles.priceRow}>
                      <p className={styles.title}>{priceComponents?.price.title}</p>
                      <p className={styles.value}>{`${formatCurrency(component.price as number, component.priceCurrency)}`}</p>
                    </div>
                    {component.referenceQuantity && (
                      <div className={styles.row}>
                        <p className={styles.title}>{`${priceComponents?.referenceQuantity.title}`}</p>
                        <p className={styles.value}>
                          {component.referenceQuantity && typeof component.referenceQuantity.value === 'number'
                            ? `${formatQuantity(component.referenceQuantity)} ${
                                component.referenceQuantity.valueReference
                                  ? `${t('common.for')} ${formatQuantity(component.referenceQuantity.valueReference)}`
                                  : ''
                              }`
                            : t('common.calculatedOnRequest')}
                        </p>
                      </div>
                    )}
                    {component.billingDuration && (
                      <div className={styles.row}>
                        <p className={styles.title}>{priceComponents?.billingDuration.title}</p>
                        <p className={styles.value}>{formatBillingDuration(component.billingDuration) || component.billingDuration}</p>
                      </div>
                    )}
                    {component.validFrom || component.validThrough ? (
                      <HoverTooltip
                        className={styles.tooltip}
                        hostClassName={styles.iconWrapper}
                        directionalHint={DirectionalHint.topCenter}
                        content={
                          <div>
                            {component.validFrom && (
                              <div className={styles.row}>
                                <p className={styles.title}>{`${priceComponents?.validFrom.title}: ${moment(component.validFrom).format(
                                  'DD.MM.YYYY'
                                )}`}</p>
                              </div>
                            )}
                            {component.validThrough && (
                              <div className={styles.row}>
                                <p className={styles.title}>
                                  {`${priceComponents?.validThrough.title}: ${moment(component.validThrough).format('DD.MM.YYYY')}`}
                                </p>
                              </div>
                            )}
                          </div>
                        }
                      >
                        <Icon className={styles.icon} iconName={'StatusCircleQuestionMark'} />
                      </HoverTooltip>
                    ) : null}
                  </div>
                );
              })}
          </div>
        );
      }
    }
    return cards;
  }, [darkMode, formatBillingDuration, formatQuantity, schema.items, t, value]);

  const groups = useMemo(() => {
    return _.chunk(content, groupSize);
  }, [content, groupSize]);

  if (!value || !schema) {
    return <div>Nothing to show.</div>;
  }

  if (!content?.length) return null;

  return (
    <div className={classNames(wrapperClassName, styles.inline)}>
      <Localization {...commonLocalizationProps} schema={schema} dataPath={`[solutionTypeDetails][${dataIndex}][offers]`} top={14}>
        <>
          {renderHeader?.()}
          <div className={darkMode ? styles.viewDark : styles.view} ref={resizeRef}>
            <Carousel showThumbs={false} autoPlay={false} showStatus={false} infiniteLoop={true} interval={5000}>
              {groups.map((group, index) => (
                <div key={index} className={styles.cardsWrapper}>
                  {group}
                </div>
              ))}
            </Carousel>
          </div>
        </>
      </Localization>
    </div>
  );
};

export default SaleableList;
