import { FormatSource } from '@cpa/base-core/constants';
import { IGenericComponentProps, IScreenProps } from '@cpa/base-core/types';
import React, { ElementType } from 'react';

import ReadonlyContent, { IReadonlyContentProps } from '../../components/ReadonlyContent/ReadonlyContent';
import Widget from '../../components/Widget/Widget';

import styles from './ItemReadonlyContent.module.scss';

interface IItemReadonlyContentProps {
  options?: Partial<IReadonlyContentProps['options']>;
}

const ItemReadonlyContent: React.FC<IScreenProps & IGenericComponentProps & IItemReadonlyContentProps> = ({ page, item, itemSchema, options }) => {
  if (!item || !itemSchema) {
    return null;
  }

  return (
    <section className={styles.wrapper}>
      <Widget title={page.name as string} subtitle={page.description as string} headerIconName={page.icon as string} hideSettings={true}>
        <div style={{ padding: 20 }}>
          <ReadonlyContent
            item={item}
            data={{
              schema: itemSchema,
              page: page,
            }}
            options={{
              source: FormatSource.Drawer,
              expandFunctionalityOptions: {
                defaultExpanded: true,
                expandedLevel: 1,
              },
              ...(options || {}),
            }}
          />
        </div>
      </Widget>
    </section>
  );
};

export function withReadonlyContentOptions(WrappedComponent: ElementType, options: IItemReadonlyContentProps['options']): React.FC {
  return (props): JSX.Element => {
    return <WrappedComponent {...props} options={options} />;
  };
}

export default ItemReadonlyContent;
