import { DefaultButton, PrimaryButton } from '@fluentui/react';
import Konva from 'konva';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageEditorCrop } from './helpers/ImageEditorCrop';

import styles from './ImageEditorCropMenu.module.scss';

interface ImageEditorCropMenuProps {
  stage: Konva.Stage;

  onChange: (imageUrl: string) => void;
  onClose: () => void;
}

const ImageEditorCropMenu: React.FC<ImageEditorCropMenuProps> = ({ stage, onChange, onClose }) => {
  const [t] = useTranslation();

  // Showing crop area...
  useEffect(() => {
    ImageEditorCrop.showCropZone(stage);

    return () => {
      ImageEditorCrop.hideCropZone(stage);
    };
  }, []);

  const applyCropping = useCallback(() => {
    const imageUrl = ImageEditorCrop.applyCrop(stage);

    onChange(imageUrl);

    onClose();
  }, [stage, onChange, onClose]);

  const cancelCropping = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <div className={styles.croppingOptions}>
      <PrimaryButton text={t('imageEditor.applyCropping')} onClick={applyCropping} />
      <DefaultButton text={t('imageEditor.undoCropping')} onClick={cancelCropping} />
    </div>
  );
};

export default ImageEditorCropMenu;
