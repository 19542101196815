import { IJSONSchema, Schemas } from '@cp/base-types';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDataItem } from '@cpa/base-core/types';
import { DialogType, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';

import Drawer from '../../../../Drawer/Drawer';
import { IItemSelectionDialogRef, ItemSelectionDialog } from '../../../../ItemSelectionDialog/ItemSelectionDialog';

import MergeDifference from './components/MergeDifference/MergeDifference';
import styles from './MergeIntoDialog.module.scss';

export interface IMergeIntoDialogProps {
  onSubmit: (from: IDataItem, to: IDataItem, properties: string[]) => void;
  page: Schemas.CpaPage;
}

export interface IMergeIntoDialogRef {
  openDialog(item: IDataItem): void;

  closeDialog(): void;

  setError(error: string): void;
}

const mergeIntoDialogInitialState = {
  item: null as IDataItem | null,
  selectedKey: undefined as string | undefined,
  error: undefined as string | undefined,
};

export const MergeIntoDialog = React.forwardRef(function MergeIntoDialog(
  { onSubmit, page }: IMergeIntoDialogProps,
  ref: React.Ref<IMergeIntoDialogRef>
) {
  const [isSubmitting, { setTrue: startSubmitting, setFalse: finishSubmitting }] = useBoolean(false);
  const [movedProperties, setMovedProperties] = useState<string[]>([]);
  const [dialogState, setDialogState] = useState(mergeIntoDialogInitialState);
  const [t] = useTranslation();
  const itemSelectionDialogRef = useRef<IItemSelectionDialogRef>(null);

  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: t('common.mergeInto.title'),
      subText: t('common.mergeInto.description'),
    }),
    [t]
  );

  const [mergingItems, setMergingItems] = useState<[IDataItem, IDataItem] | [null, null]>([null, null]);

  const onDismissDialog = useCallback(() => {
    setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    setMergingItems([null, null]);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openDialog(item: IDataItem): void {
        itemSelectionDialogRef.current?.openDialog();
        setDialogState((state) => ({ ...state, item, error: undefined }));
      },
      closeDialog(): void {
        itemSelectionDialogRef.current?.closeDialog();
        setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
        setMovedProperties([]);
        setMergingItems([null, null]);
        finishSubmitting();
      },
      setError(error: string): void {
        setDialogState((state) => ({ ...state, error }));
        finishSubmitting();
      },
    }),
    [finishSubmitting]
  );

  const [schema, setSchema] = useState<IJSONSchema>();

  const handleItemClick = useCallback(
    (item: IDataItem, schema: IJSONSchema) => {
      setSchema(schema);
      setMovedProperties([]);
      setMergingItems([dialogState.item!, item]);
      setDialogState((state) => ({ ...state, item: null, selectedKey: '' }));
    },
    [dialogState.item]
  );

  const onMergeSubmit = useCallback(() => {
    if (!mergingItems[0] || !mergingItems[1]) {
      return;
    }
    startSubmitting();
    onSubmit(mergingItems[0], mergingItems[1], movedProperties);
  }, [mergingItems, startSubmitting, onSubmit, movedProperties]);

  return (
    <>
      <ItemSelectionDialog ref={itemSelectionDialogRef} page={page} onSubmit={handleItemClick} dialogContentProps={dialogContentProps} />
      <Drawer
        isOpen={!!mergingItems[0] && !!mergingItems[1]}
        onClose={onDismissDialog}
        defaultMaximized={true}
        disableMaximizing={true}
        className={styles.differenceDialog}
      >
        {!!dialogState.error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {dialogState.error}
          </MessageBar>
        )}
        {!!schema && !!mergingItems[0] && !!mergingItems[1] && (
          <div className={styles.differenceDialogBody}>
            <MergeDifference
              page={page}
              schema={schema}
              itemA={mergingItems[0]}
              itemB={mergingItems[1]}
              movedProperties={movedProperties}
              onPropertiesChange={setMovedProperties}
            />
          </div>
        )}
        <div className={styles.differenceDialogFooter}>
          <PrimaryButton disabled={isSubmitting} onClick={onMergeSubmit} text={t('common.confirm')} />
        </div>
      </Drawer>
    </>
  );
});
