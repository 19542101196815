import { IGenericComponentData, IDataItem } from '@cpa/base-core/types';
import * as _ from 'lodash';
import React, { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDownload } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { IJSONSchema } from '@cp/base-types';
import { replaceAnyThingWithSchema } from '@cpa/base-core/helpers';
import { FormatSource } from '@cpa/base-core/constants';
import { EndpointContext } from '@cpa/base-core/constants';

import PropertyContent, { IHighlightedProperty } from './components/PropertyContent/PropertyContent';

export interface IReadonlyContentProps {
  item: IDataItem;
  data: Pick<IGenericComponentData, 'schema' | 'page'>;
  options: {
    onlySelectedFields?: string[];
    useHovercardForHtml?: boolean;
    differences?: Map<string, string>;
    gridView?: boolean;
    source: FormatSource;
    expandFunctionalityOptions?: {
      defaultExpanded?: boolean;
      expandedLevel?: number;
    };
    highlightOptions?: {
      properties: IHighlightedProperty[];
    };
    customPropertyRender?: (
      itemKey: string,
      formattedContent: JSX.Element | null,
      propertyStyles: CSSProperties,
      level: number,
      propertySchema?: IJSONSchema,
      parentSchemas?: IJSONSchema[],
      rootSchema?: IJSONSchema,
      value?: IDataItem | IDataItem[],
      rootItem?: IDataItem
    ) => JSX.Element | null;
    showEmptyValues?: boolean;
  };
}

export interface IReadonlyContentRef {}

const ReadonlyContent = React.forwardRef(function _ReadonlyContent(
  { item, data, options }: IReadonlyContentProps,
  _ref: React.Ref<IReadonlyContentRef>
): JSX.Element | null {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [resolvedSchema, setResolvedSchema] = useState<IJSONSchema | null>(null);

  const endpointIdentifierFromContext = useContext(EndpointContext);

  const [_originalItem] = useState<IDataItem>(item);
  const handleDownload = useDownload(data.page.dataEndpoint?.identifier || endpointIdentifierFromContext || undefined);

  useEffect(() => {
    let mounted = true;
    const getResolvedSchema = async (): Promise<void> => {
      if (data.schema && item) {
        const resolvedSchema = await replaceAnyThingWithSchema(data.schema, item);
        if (mounted) {
          setResolvedSchema(resolvedSchema);
        }
      }
    };

    getResolvedSchema();

    return () => {
      mounted = false;
    };
  }, [data.schema, item]);

  const propertyOptions = useMemo(
    () => ({
      ...options,
      _originalItem,
    }),
    [options, _originalItem]
  );

  if (!data.schema) {
    return null;
  }

  return (
    <div>
      <PropertyContent
        onDownload={handleDownload}
        page={data.page}
        value={item}
        schema={resolvedSchema || data.schema}
        rootSchema={data.schema}
        options={propertyOptions}
        darkMode={darkMode}
      />
    </div>
  );
});

export default React.memo(ReadonlyContent, (prev, next) => {
  return _.isEqual(prev, next);
});
