const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/AttachmentCategory',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachmentCategory' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachmentCategory?$filter=parent%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parent.identifier',
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Attachment Category',
    'properties': {
        'parent': {
            'title': 'Parent',
            '$id': 'http://platform.cosmoconsult.com/ontology/AttachmentCategory',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachmentCategory' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parent', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parent.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Attachment Category' } },
            'cp_ui': { 'sortOrderForm': 11 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': 'A description of the item.',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 30 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 20 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'cp_restrictablePaths': ['description'],
    'cp_hasPermissions': true,
};
export const AttachmentCategorySchema = Schema;
