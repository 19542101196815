const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/WebHook',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FWebHook' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': true,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Web Hook',
    'properties': {
        'cpTypeUrl': {
            'title': 'Cp Type Url',
            'description': '<div>The URL that indicates which type this is assigned to</div>',
            'type': 'string',
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/cpTypeUrl', 'rel': 'canonical' },
            ],
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 30 },
        },
        'filter': {
            'title': 'Filter',
            'type': 'string',
            'format': 'cp:filter',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/filter', 'rel': 'canonical' }],
        },
        'dataOperationTypes': {
            'title': 'Data Operation Types',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/DataOperationType',
                'type': 'string',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FDataOperationType' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Data Operation Type',
                'anyOf': [
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Create'],
                        'enumNames': ['Create'],
                        'title': 'Create',
                        'cp_enumIcons': ['CalculatorAddition'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Delete'],
                        'enumNames': ['Delete'],
                        'title': 'Delete',
                        'cp_enumIcons': ['Delete'],
                    },
                    {
                        'type': 'string',
                        'enum': ['http://platform.cosmoconsult.com/ontology/Update'],
                        'enumNames': ['Update'],
                        'title': 'Update',
                        'cp_enumIcons': ['Edit'],
                    },
                ],
                'format': 'cp:TileOption',
            },
            'uniqueItems': true,
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/dataOperationTypes', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                                'readOnly': true,
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                        'required': ['role'],
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'readOnly': true,
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                        'required': ['email'],
                    },
                ],
                'readOnly': true,
            },
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 29 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 35 },
            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
        },
    },
    'required': ['cpTypeUrl', 'url'],
};
export const WebHookSchema = Schema;
