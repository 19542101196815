import Modeler from 'bpmn-js/lib/Modeler';

export async function getSvg(xml: string): Promise<string | null> {
  const container = document.createElement('div');
  container.style.width = '1000px';
  container.style.height = '1000px';
  container.style.position = 'absolute';
  container.style.top = '-10000px';
  document.body.appendChild(container);

  const modeler = new Modeler({ container });
  await modeler.importXML(xml);

  const canvas = modeler.get('canvas');

  // @ts-ignore
  canvas.zoom('fit-viewport');

  const result = await modeler.saveSVG();
  if (result.svg) {
    return result.svg;
  }

  document.body.removeChild(container);
  return null;
}
