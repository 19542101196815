import {IJSONSchema} from '@cp/base-types';
import * as _ from 'lodash';
import {getMatchingEnum} from '@cpa/base-core/helpers';
import {cloneDeepWithMetadata, isRelationSchema} from '@cp/base-utils';
import {utils} from '@rjsf/core';
import {IDataItem} from '@cpa/base-core/types';
import flatten from 'flat';
import React from 'react';
import { i18n } from '@cpa/base-core/app';

export enum StepTypes {
  Regular = 'regular',
  Guided = 'guided',
  Repeat = 'repeat',
}

// Normal step w/o extra logic, mainly used for object fields
export type RegularStep = {
  type: StepTypes.Regular;
  fields: string[];
}

// Step with extra logic of handling array items
export type GuidedStep = {
  type: StepTypes.Guided;
  fields: string[];
}

// Utility step used after GuidedStep to create one more array item and move cursor
export type RepeatStep = {
  type: StepTypes.Repeat;
  from: number;
  question: string;
  path: string;
  deactivated?: boolean;
}

export type Step = RepeatStep | GuidedStep | RegularStep;


// Constants for array manipulation operations
const ANYOF_ARRAY_STRIP_COUNT = 5; // Number of items to strip from an "anyOf" array
const ANYOF_ARRAY_EXTEND_COUNT = 3; // Number of items to extend an "anyOf" array by
const ARRAY_STRIP_COUNT = 3; // Number of items to strip from a regular array
const ANYOF_EXTEND_COUNT = 2; // Number of items to extend an "anyOf" array by in a specific context

export const makeUiSchema = (schema: IJSONSchema): object => {
  const transformer =
    (path: string = '') =>
    (acc: any, value: unknown, key: string | number): any => {
      const currentPath = path ? `${path}.${key}` : key.toString();

      if (key === 'cp_rjsfUiSchema') {
        _.merge(acc, value);
        return acc;
      }

      // If object
      if (_.isObject(value)) {
        const transformedObject = _.transform(
          (_.isArray(value) ? Object.assign({}, value) : value) as _.Dictionary<unknown>,
          transformer(currentPath)
        );
        if (Object.keys(transformedObject).length === 0) {
          return acc;
        }

        const appendLevelToUiSchema = _.toPath(path.toString()).pop() === 'properties';
        if (appendLevelToUiSchema) {
          acc[key] = transformedObject;
        } else {
          _.merge(acc, transformedObject);
        }
        return acc;
      }

      return acc;
    };

  return _.transform(schema, transformer());
};

export const compareSchemaSortOrder = (aSchema?: IJSONSchema, bSchema?: IJSONSchema): number => {
  const aSortOrder = aSchema?.cp_ui?.sortOrderForm || Infinity;
  const bSortOrder = bSchema?.cp_ui?.sortOrderForm || Infinity;
  return aSortOrder < bSortOrder ? -1 : aSortOrder > bSortOrder ? 1 : 0;
};

export const getObjectPreviewText = (
  formDataEntries: [string, unknown][],
  propertiesEntries: [string, unknown][],
  formData: any,
  schema: IJSONSchema
): string | undefined => {
  const previewProperty = formDataEntries
    .map(([property, value]) => {
      const matchingPropertyEntry = propertiesEntries.find((propertyEntry) => propertyEntry[0] === property);
      if (!matchingPropertyEntry) return;
      if (value && typeof value === 'string') {
        const matchedEnum = getMatchingEnum(schema as IJSONSchema, property, value as string | undefined);
        if (matchedEnum) return matchedEnum;
        else return value;
      }
      if (isRelationSchema(matchingPropertyEntry[1] as IJSONSchema) && formData[property]?.identifier) {
        const formDataProperty = formData[property];
        return formDataProperty?.name || formDataProperty?.identifier;
      }
      return false;
    })
    .filter(Boolean);
  return previewProperty[0];
};

const stripExceptions = (exceptions: string[], level: number): string[] => {
  return exceptions.map((exception) => {
    return _.toPath(exception).slice(level).join('.');
  });
};

export function transformSchemaPathToFormDataPath(schemaPath: string, formData: any, wizardArrayMeta: (number | undefined)[]): string {
  const pathSegments = schemaPath.split('.');
  const formDataPath: string[] = [];
  let currentData = formData;

  for (let i = 0; i < pathSegments.length; i++) {
    const segment = pathSegments[i];

    if (['anyOf', 'oneOf', 'allOf', 'items', 'properties'].includes(segment)) {
      if (segment === 'anyOf' && i + 1 < pathSegments.length) {
        i++;
        continue;
      }
      continue;
    }

    if (Array.isArray(currentData)) {
      const indexFromWizardMeta: number | undefined = wizardArrayMeta.shift();
      const index = typeof indexFromWizardMeta === 'number' ? indexFromWizardMeta : currentData.length > 0 ? currentData.length - 1 : 0;
      if (formDataPath.length === 0 || isNaN(Number(formDataPath[formDataPath.length - 1]))) {
        formDataPath.push(String(index));
      }
      currentData = currentData[index];
    }

    formDataPath.push(segment);

    if (currentData && typeof currentData === 'object') {
      if (currentData.hasOwnProperty(segment)) {
        currentData = currentData[segment];
      }
    }
  }

  return formDataPath.join('.');
}

/**
 * Takes a schema path and removes any segments that are "schema keywords",
 * namely `anyOf`, `oneOf`, `allOf`, `items`, and `properties`. If the
 * keyword is `anyOf`, the segment immediately following it is also removed.
 *
 * @param path - The schema path to process.
 * @returns The modified schema path.
 */
const removeSchemaKeywordsFromPath = (path: string) => {
    const repeatPathAsArray = _.toPath(path);
    let skipNext: boolean = false;
    const repeatArrayPath = repeatPathAsArray.filter((item, index) => {
      if (skipNext) {
        skipNext = false;
        return false;
      }
      if (['anyOf', 'oneOf', 'allOf', 'items', 'properties'].includes(item)) {
        if (item === 'anyOf') {
          skipNext = true;
          return false;
        }
        return false;
      }
      return true;
    });
    return repeatArrayPath.join('.');
};

export const createNewArrayItemAtPath = (currentStepArrayPath: string, schema: IJSONSchema, formData: IDataItem<unknown> | undefined, wizardArrayMeta: (number | undefined)[]) => {
  const fieldSchema = _.get(schema.properties, currentStepArrayPath);
  const defaultFormData = getNewFormDataRow(fieldSchema as IJSONSchema);
  const formDataPath = transformSchemaPathToFormDataPath(currentStepArrayPath, formData, wizardArrayMeta);
  if (!wizardArrayMeta.length) {
    const currentArrayPath = removeSchemaKeywordsFromPath(currentStepArrayPath);
    const currentArrayPathSegments = currentArrayPath.split('.');
    const generatedData = currentArrayPathSegments.reduce((acc: any, segment: string, index) => {
      // Skip first array because acc is already array
      if (index === 0) return acc;
      if (index === currentArrayPathSegments.length - 1) {
        acc[segment] = [defaultFormData];
        return acc;
      }
      acc[segment] = [];
      return acc;
    }, {});
    const currentData = _.get(formData, currentArrayPathSegments[0]) as IDataItem[] || {};
    _.set(formData || {}, currentArrayPathSegments[0], [...currentData, generatedData ]);
    return;
  }
  const currentData = _.get(formData, formDataPath) as IDataItem[] || {};
  _.set(formData || {}, formDataPath, [...currentData, defaultFormData ]);
};

const incrementRepeatStepsAfterCurrent = (stepsAfterCurrent: Step[], count: number): Step[] => {
  return stepsAfterCurrent.map((step) => {
    if (step.type !== StepTypes.Repeat) return step;
    return {
      ...step,
      from: step.from + count
    };
  });
};

export const addRepeatSteps = (steps: Step[], currentStepIndex: number, lastVisitedStep: React.MutableRefObject<number>, returnTo?: number): Step[] => {
  if (!returnTo) {
    return steps;
  }
  const stepsBeforeArray = steps.slice(0, currentStepIndex + 1);
  const arraySteps = steps.slice(returnTo, currentStepIndex);
  const currentStep = steps[currentStepIndex] as RepeatStep;
  const modifiedReturnStep = {...currentStep, from: currentStepIndex + 1 };
  const stepsAfterArray = steps.slice(currentStepIndex + 1);
  const incrementedStepsAfterArray = incrementRepeatStepsAfterCurrent(stepsAfterArray, arraySteps.length + 1);
  lastVisitedStep.current += arraySteps.length + 1;
  return [...stepsBeforeArray, ...arraySteps, modifiedReturnStep, ...incrementedStepsAfterArray];
};

const getPathAtLevel = (paths: string[], level: number, exact?: boolean) => {
  return paths.map((path) => {
    const exceptionPath = _.toPath(path).slice(0, level + 1);
    if (exact) {
      return exceptionPath[0];
    }
    return exceptionPath.join('.');
  });
};

export const isNewArrayItemsRequired = (steps: Step[], stepIndex: number) => {
  const step = steps[stepIndex];
  if (step.type !== StepTypes.Guided) return false;
  const fields = step.fields;
  const path = fields[0];
  const currentArrayPath = getCurrentArrayPath(path, getCurrentDepth(path));
  const currentArrayPathAtFirstDepth = getCurrentArrayPath(path, 1);

  const isFirstFill = steps.every((step, index) => {
    if (index >= stepIndex) return true;
    if (step.type !== StepTypes.Guided) return true;
    const path = step.fields[0];
    return !path.startsWith(currentArrayPathAtFirstDepth);
  });

  return isFirstFill;
};

const removeRequiredField = (schema: IJSONSchema, key: string): void => {
  if (!schema.required?.length) {
    return;
  }
  schema.required = schema.required.filter((requiredKey) => requiredKey !== key);
};

export const getNewFormDataRow = (schema: IJSONSchema): object => {
  let itemSchema = schema.items;
  if (utils.isFixedItems(schema) && utils.allowAdditionalItems(schema)) {
    itemSchema = schema.additionalItems as IJSONSchema;
  }
  return utils.getDefaultFormState(itemSchema as IJSONSchema, {}) as unknown as IJSONSchema;
};

export const hideFields = (schema: IJSONSchema, exceptions: string[], forValidation: boolean, createNewArrayItems: boolean, currentPath = ''): IJSONSchema => {
  if (forValidation) {
    schema.additionalProperties = true;
  }
  const currentLevel = _.toPath(currentPath).length;
  const schemaCopy = cloneDeepWithMetadata(schema);
  const properties = schemaCopy.properties || {};
  for (const key of Object.keys(properties)) {
    const isRootException = exceptions.includes(key);
    if (isRootException) {
      if (properties[key].items?.anyOf) {
        properties[key] = {
          ...properties[key],
          items: {
            ...properties[key].items,
            cp_rjsfUiSchema: {
              defaultExpanded: true,
                visible: true
              }
          },
          cp_rjsfUiSchema: {
            defaultExpanded: true,
            visible: true,
          }
        };
      }
      properties[key] = {
        ...properties[key],
        cp_rjsfUiSchema: {
          defaultExpanded: true,
          visible: true,
          defaultView: true
        }
      };
    }
    const exceptionsAtCurrentLevel = getPathAtLevel(exceptions, currentLevel, true);
    if (exceptionsAtCurrentLevel.includes(key)) {
      // anyOf array
      if (properties[key]?.items?.anyOf) {
        schemaCopy.cp_rjsfUiSchema = {
          ...schemaCopy.cp_rjsfUiSchema,
          defaultExpanded: true
        };
        if (properties[key].items) {
          properties[key].items!.cp_rjsfUiSchema = {
            ...properties[key].items!.cp_rjsfUiSchema,
            defaultExpanded: true
          };
        }
        const exceptionsAtAnyOfLevel = getPathAtLevel(exceptions, currentLevel + ANYOF_ARRAY_EXTEND_COUNT);
        if (createNewArrayItems) {
          properties[key].minItems = 1;
        }
        properties[key].items!.anyOf = (properties[key].items?.anyOf || []).map((item, index) => {
          const anyOfPath = `${currentPath ? `${currentPath}.properties.` : ''}${key}.items.anyOf.${index}`;
          // Hide all anyOf if it is not in path
          if (exceptionsAtAnyOfLevel.includes(anyOfPath)) {
            return hideFields(item, stripExceptions(exceptions, ANYOF_ARRAY_STRIP_COUNT), forValidation, createNewArrayItems, anyOfPath);
          }
          if (exceptionsAtAnyOfLevel.includes(key)) {
            return item;
          }
          // If we need to hide then
          if (forValidation) {
            return item;
          } else {
            if (properties[key].items) {
              properties[key].items!.cp_rjsfUiSchema = {
                ...schemaCopy.cp_rjsfUiSchema,
                hiddenOptions: [...(properties[key].items?.cp_rjsfUiSchema?.hiddenOptions || []), index]
              };
            }
            return item;
          }
        }).filter(Boolean) as IJSONSchema[];
        // array
      } else if (properties[key]?.items) {
        const itemsPath = `${currentPath ? `${currentPath}.properties.` : ''}${key}.items`;
        if (!properties[key].items?.format && createNewArrayItems) {
          properties[key].minItems = 1;
        }
        if (!exceptions.includes(key)) {
          properties[key].items = hideFields(properties[key].items || {}, stripExceptions(exceptions, ARRAY_STRIP_COUNT), forValidation, createNewArrayItems, itemsPath);
        } else {
          properties[key].items!.cp_rjsfUiSchema = {
            defaultView: true,
          };
        }
        // anyOf
      } else if (properties[key]?.anyOf) {
        properties[key].cp_rjsfUiSchema = {
          ...properties[key].cp_rjsfUiSchema,
          defaultExpanded: true
        };
        const exceptionsAtAnyOfLevel = getPathAtLevel(exceptions, currentLevel + ANYOF_EXTEND_COUNT);
        const finishedExceptions = exceptionsAtAnyOfLevel.filter((exception) => _.toPath(exception).length === 1);
        properties[key].anyOf = (properties[key].anyOf || []).map((item, index) => {
          const anyOfPath = `${key}.anyOf.${index}`;
          if (exceptionsAtAnyOfLevel.includes(anyOfPath) || finishedExceptions.includes(key)) {
            return item;
          }
          return null;
        }).filter(Boolean) as IJSONSchema[];
      }
      const propertyPath = `${currentPath ? `${currentPath}.properties.` : ''}${key}`;
      const exceptionsAtPropertyLevel = getPathAtLevel(exceptions, currentLevel + ANYOF_EXTEND_COUNT);
      const currentExceptionIndex = exceptionsAtCurrentLevel.findIndex((exception) => exception === key);
      const currentExceptionAtNextLevel = currentExceptionIndex !== -1 ? stripExceptions([exceptionsAtPropertyLevel[currentExceptionIndex]], ANYOF_EXTEND_COUNT)[0] : null;
      if (!currentExceptionAtNextLevel) {
        continue;
      }
      properties[key] = hideFields(properties[key], stripExceptions(exceptions, ANYOF_EXTEND_COUNT), forValidation, createNewArrayItems, propertyPath);
    } else {
      removeRequiredField(schemaCopy, key);
      if (forValidation) {
        schema.additionalProperties = true;
        delete properties[key];
      } else {
        properties[key] = {
          ...properties[key],
          cp_ui: {
            ...properties[key].cp_ui,
            hiddenInForm: true,
          },
        };
      }
    }
  }

  return {...schemaCopy, cp_rjsfUiSchema: {
    ...schemaCopy.cp_rjsfUiSchema,
      defaultExpanded: true
    }};
};

/**
 * Checks if a given schema path is a regular field (i.e. not an array item).
 *
 * A regular field is a field that is not an item of an array. This is determined by
 * checking if the path contains the string 'items'. If it does, then the path is
 * not a regular field.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns true if the path is a regular field, false otherwise.
 */
const isRegularField = (path: string) => {
  const pathAsArray = path.split('.');
  return !pathAsArray.includes('items');
};

/**
 * Calculates the depth of the 'items' in a given schema path.
 *
 * The depth is determined by counting how many times 'items'
 * appears in the path. This is useful for understanding the
 * nesting level of array items in the schema.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns The number of 'items' occurrences in the path.
 */
export const getCurrentDepth = (path: string) => {
  const pathAsArray = path.split('.');
  return pathAsArray.filter((item) => item === 'items').length;
};

const getCurrentArrayPath = (path: string, depth: number) => {
  const pathAsArray = path.split('.');
  const targetItemsIndex = pathAsArray.findIndex((pathPart) => {
    if (pathPart === 'items') {
      if (depth === 1) return true;
      else {
        depth--;
        return false;
      }
    }
    return false;
  });
  if (targetItemsIndex === -1) {
    return path;
  }
  return pathAsArray.slice(0, targetItemsIndex).join('.');
};

/**
 * Checks if a given schema path is a root path.
 *
 * A root path is a path that only contains one element, i.e. it is not nested
 * inside another array or object. This is determined by splitting the path
 * into its components and checking if the resulting array has a length of 1.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns true if the path is a root path, false otherwise.
 */
const isRoot = (path: string): boolean => {
  const level = path.split('.').length;
  return level === 1;
};

const toPath = (path: string): string[] => {
  return path.split('.');
};

const transformCountToIndexArray = (counts: number[]): number[] => {
  return counts.map((count) => count - 1);
};

const getFieldIndices = (fields: string[]): (number | undefined)[] => {
  const target = fields[fields.length - 1];
  const targetArray = toPath(target);
  const result = [];
  let usedPaths: string[] = [];
  let level = 1;
  let currentRoot = '';
  for (const segment of targetArray) {
    // Get count at level 0
    currentRoot = targetArray.slice(0, level).join('.');
    const fieldsFiltered = fields.filter((field) =>
      field.startsWith(currentRoot)
    );
    const useCount = fieldsFiltered.reduce((acc, field) => {
      if (isRoot(field) || field === currentRoot) {
        return acc + 1;
      } else {
        if (usedPaths.includes(field)) {
          return acc;
        }
        usedPaths.push(field);
        return acc + 1;
      }
    }, 0);
    level++;
    usedPaths = [];
    result.push(useCount);
  }

  return transformCountToIndexArray(result);
};


export const getWizardArrayMeta = (steps: Step[], path: string, stepIndex: number): (number | undefined)[] => {
  const stepsOnTheLeft = steps.slice(0, stepIndex + 1).filter((step) => step.type === StepTypes.Repeat);
  const repeatStepPaths = stepsOnTheLeft.map((step) => (step as RepeatStep).path);
  const repeatStepPathsArrays = repeatStepPaths.map(removeSchemaKeywordsFromPath);
  return getFieldIndices(repeatStepPathsArrays);
};

export const getNextRepeatStepIndex = (steps: Step[], index: number): number | null => {
  const nextRepeatToCurrentIndexStep = steps.findIndex((step) => {
    if (step.type !== StepTypes.Repeat) return false;
    return step.from === index;
  });
  if (nextRepeatToCurrentIndexStep === -1) {
    return null;
  }
  return nextRepeatToCurrentIndexStep;
};

export const addArrayItemIfRequired = (steps: Step[], stepIndex: number, schema: IJSONSchema, formData?: IDataItem<unknown>): void => {
  const step = steps[stepIndex];
  // New array items are possible only for 'guided' step
  if (step.type !== StepTypes.Guided) return;
  // It is not possible to have items from different arrays at the same step, so we take first field path
  const path = step.fields[0];
  const currentArrayPath = getCurrentArrayPath(path, getCurrentDepth(path));
  const currentArrayPathAtFirstDepth = getCurrentArrayPath(path, 1);
  const prevStep = steps[stepIndex - 1];
  // If current array was never filled in the past, skip it
  const isFirstFill = steps.every((step, index) => {
    if (index >= stepIndex) return true;
    if (step.type !== StepTypes.Guided) return true;
    const path = step.fields[0];
    return !path.startsWith(currentArrayPathAtFirstDepth);
  });
  if (isFirstFill) return;
  if (prevStep.type !== StepTypes.Guided) {
    const isAnyOfArray = path.startsWith(`${currentArrayPath}.items.anyOf`);
    const anyOfIndex = isAnyOfArray ? _.toPath(path.replace(`${currentArrayPath}.items.anyOf.`, ''))[0] : undefined;
    createNewArrayItemAtPath(currentArrayPath, schema, formData, []);
    return;
  }
  const prevPath = (prevStep as GuidedStep).fields[0];
  const prevStepArrayPath = getCurrentArrayPath(prevPath, getCurrentDepth(prevPath));
  if (currentArrayPath !== prevStepArrayPath) {
    createNewArrayItemAtPath(currentArrayPath, schema, formData, []);
  }
};


export const getStepsFromSchema = (schema: IJSONSchema): Step[] => {
  const result: Step[] = [];
  const schemaFlat = flatten(schema.properties) as Record<string,unknown>;
  const groupProperties = Object.keys(schemaFlat).filter((key) => key.endsWith('wizardGroup')) as string[];
  const fieldsOnSteps: Record<number, string[]> = {};

  let currentDepth: number | undefined = undefined;
  let arrayStartIndex: undefined | number = undefined;
  let currentArrayPath: string | undefined = undefined;

  const pushAndCleanupVariables = () => {
    // Handle case with anyOf
    if (!schema?.properties?.[currentArrayPath!]?.items?.anyOf) {
      const arrayTitle = currentArrayPath ? _.get(schema, `properties.${currentArrayPath}`)?.title : '';
      result.push({
        type: StepTypes.Repeat,
        from: arrayStartIndex!,
        question: i18n.t('common.wizardArrayQuestion', { arrayTitle }),
        path: currentArrayPath!
      });
    } else {
      const arrayTitle = currentArrayPath ? _.get(schema, `properties.${currentArrayPath}`)?.title : '';
      result.push({
        type: StepTypes.Repeat,
        from: arrayStartIndex!,
        question: i18n.t('common.wizardArrayQuestion', { arrayTitle }),
        path: currentArrayPath!,
        deactivated: true,
      });
    }
    currentArrayPath = undefined;
    arrayStartIndex = undefined;
    currentDepth = undefined;
  };

  const checkAndHandleArrayEnd = (skipCheck: boolean, fields: string[]): boolean => {

    if (!currentDepth || !currentArrayPath) {
      return true;
    }

    if (skipCheck) {
      pushAndCleanupVariables();
      return false;
    } else {
      if (!currentDepth || !currentArrayPath) {
        return true;
      }
      const fieldDepth = getCurrentDepth(fields[0]);
      const isSameArray = fields[0].startsWith(currentArrayPath as string);
      if (fieldDepth === currentDepth && isSameArray) {
        if (fieldDepth === 1 && fields[0].includes('anyOf')) {
          pushAndCleanupVariables();
          return false;
        }
        return true;
      } else {
        pushAndCleanupVariables();
        return false;
      }
    }
  };

  for (const property of groupProperties) {
    const propertyPath = property.replace('.cp_ui.wizardGroup', '');
    const groupNumber = schemaFlat[property] as number;
    if (fieldsOnSteps[groupNumber]) {
      fieldsOnSteps[groupNumber].push(propertyPath);
    } else {
      fieldsOnSteps[groupNumber] = [propertyPath];
    }
  }
  for (const field of Object.keys(fieldsOnSteps)) {
    const fields = fieldsOnSteps[field as unknown as number];
    if (fields.every(isRegularField)) {
      checkAndHandleArrayEnd(true, fields);
      result.push({
        type: StepTypes.Regular,
        fields: fields,
      });
    } else {
      const isSameArray = checkAndHandleArrayEnd(false, fields);
      result.push({
        type: StepTypes.Guided,
        fields: fields,
      });
      if (currentDepth && currentArrayPath && isSameArray) {
        continue;
      }
      arrayStartIndex = result.length - 1;
      currentDepth = getCurrentDepth(fields[0]);
      currentArrayPath = getCurrentArrayPath(fields[0], currentDepth);
    }
  }
  if (arrayStartIndex !== undefined && currentArrayPath !== undefined) {
    pushAndCleanupVariables();
  }
  return result;
};
