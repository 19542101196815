import { isUuid } from '@cp/base-types';
import notification from '@cpa/base-core/helpers/toast';
import { IGlobalState } from '@cpa/base-core/store';
import { IAxiosInstance, createCancelToken } from '@cpa/base-http';
import { Icon, TooltipOverflowMode } from '@fluentui/react';
import { AxiosError, CancelTokenSource } from 'axios';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HoverTooltip from '../../../../components/HoverTooltip/HoverTooltip';
import { ITargetWorkItem, IWorkItem, IWorkItemTypeState } from '../../helpers/types';
import { getClassNamesForWorkItemType, getIconNameForWorkItemType, loopApi, loopApiSi } from '../../helpers/utils';

import styles from './TargetWorkItem.module.scss';

interface TargetWorkItemProps {
  item: IWorkItem;
  target: ITargetWorkItem;
}

const TargetWorkItem: React.FC<TargetWorkItemProps> = ({ item, target }) => {
  const [t] = useTranslation();
  const [context, setContext] = useState<IWorkItemTypeState | undefined>();
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const currentLocation = useSelector((state: IGlobalState) => state.router.location);
  const workItemsBaseLocation = currentLocation.pathname.split('/')[1];

  useEffect(() => {
    cancelToken.current?.cancel();
    cancelToken.current = createCancelToken();

    // Generous approach to determine DevOps name (which is ID there) as special treatment
    // TODO: Ensure in data that we always use identifier instead of name
    const devOpsOrganizationId = isUuid(item.devOpsOrganization.identifier) ? item.devOpsOrganization.name : item.devOpsOrganization.identifier;

    const callAPI = (api: IAxiosInstance) => {
      api
        .get<IWorkItemTypeState[]>(`/api/state?organization=${devOpsOrganizationId}&project=${item.teamProject}&type=${item.workItemType}`, {
          cancelToken: cancelToken.current?.token,
        })
        .then((response) => {
          const mapedStates = Object.fromEntries(response.map((e) => [e.name, e]));
          const itemState = mapedStates[target.State];
          setContext(itemState);
        })
        .catch((error: AxiosError) => {
          console.error(error);
          notification.error(error.response?.data);
          notification.error(t('errors.connection.internalErrorTitle'));
        });
    };

    if (devOpsOrganizationId === 'cosmoconsultsi') callAPI(loopApiSi);
    else callAPI(loopApi);

    return () => {
      cancelToken.current?.cancel();
    };
  }, [item.teamProject, item.devOpsOrganization.identifier, item.devOpsOrganization.name, item.workItemType, t, target.State]);

  return (
    <div className={styles.targetWorkItemContainer}>
      <div className={styles.primaryData}>
        <Icon
          iconName={getIconNameForWorkItemType(target.WorkItemType)}
          className={classNames(styles.typeIcon, getClassNamesForWorkItemType(target.WorkItemType))}
        />
        <div className={styles.primaryDataId}>{target.WorkItemId}</div>
        <div className={styles.primaryDataItemLink}>
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={target.Title}>
            <a href={`/${workItemsBaseLocation}/${target.identifier}`}>{target.Title}</a>
          </HoverTooltip>
        </div>
      </div>
      <div className={styles.additionalData}>
        <div className={styles.additionalDataUpdated}>{`Updated ${new Date(target.ChangedDate).toLocaleDateString()}`}</div>
        <div className={styles.additionalDataState}>
          <span
            className={styles.additionalDataStateColoredCircle}
            style={{ backgroundColor: `#${context?.color}`, borderColor: `#${context?.color}` }}
          ></span>
          <span className={styles.additionalDataStateText}>{target.State}</span>
        </div>
      </div>
    </div>
  );
};

export default TargetWorkItem;
