import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { IJSONSchema } from '@cp/base-types';
import { isDefined } from '@cpa/base-core/helpers';
import { useBoolean } from '@fluentui/react-hooks';
import { FormatSource } from '@cpa/base-core/constants';

import ExpandButton from '../../../ExpandButton/ExpandButton';
import PropertyContent, { IPropertyContentProps } from '../PropertyContent/PropertyContent';
import styles from '../../ReadonlyContent.module.scss';

const ArrayContent: React.FC<IPropertyContentProps> = ({
  value,
  schema,
  rootSchema,
  parentSchemas,
  options,
  level,
  itemKey,
  dataPath,
  onDownload,
  page,
  darkMode,
}) => {
  const [isExpanded, { toggle: toggleExpanded }] = useBoolean(
    options.highlightOptions && options.highlightOptions.properties.some((p) => p.key.startsWith(itemKey || ''))
      ? true
      : options.expandFunctionalityOptions
      ? isDefined(options.expandFunctionalityOptions?.expandedLevel)
        ? (options.expandFunctionalityOptions?.expandedLevel as number) >= (level || 1)
        : !!options.expandFunctionalityOptions?.defaultExpanded
      : true
  );

  const onExpandIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      toggleExpanded();
    },
    [toggleExpanded]
  );

  const appendedParentSchema = useMemo(() => {
    if (
      parentSchemas &&
      parentSchemas.length &&
      parentSchemas[parentSchemas.length - 1] &&
      _.isEqual(parentSchemas[parentSchemas.length - 1], schema)
    ) {
      return parentSchemas;
    }

    return [...(parentSchemas || []), schema];
  }, [parentSchemas, schema]);

  return (
    <div className={styles.array}>
      <div className={styles.title}>
        {!!options.expandFunctionalityOptions && <ExpandButton isExpanded={isExpanded} onClick={onExpandIconClick} />}
        <div>{schema.title}</div>
      </div>
      <div
        className={cn(styles.inner, {
          [styles.collapsed]: !!options.expandFunctionalityOptions && !isExpanded,
          [styles.hoverCardInner]: options.source === FormatSource.HoverCard,
        })}
      >
        {Array.isArray(value) &&
          value.map((v, i) => {
            return (
              <PropertyContent
                onDownload={onDownload}
                key={`object-${i}-${itemKey}`}
                value={v}
                schema={schema.items as IJSONSchema}
                parentSchemas={appendedParentSchema}
                rootSchema={rootSchema}
                options={options}
                level={(level || 1) + 1}
                itemKey={itemKey + '[' + i + ']'}
                dataPath={dataPath + (dataPath === '' ? '' : '.') + i}
                page={page}
                darkMode={darkMode}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ArrayContent;
