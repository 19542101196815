import * as React from 'react';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';

import { LocalizationEditorTabsWrapperArrow } from './Arrows/LocalizationEditorTabsWrapperArrow';
import styles from './LocalizationEditorTabsWrapper.module.scss';

export interface HorizontalScrollProps {
  children?: React.ReactNode;
}

const LocalizationEditorTabsWrapper = ({ children }: HorizontalScrollProps) => {
  const { ref: scrollerRef } = useResizeDetector({
    onResize: () => {
      onscroll();
    },
  });

  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(false);

  const isCustomScrollingRef = React.useRef(false);

  const scrollTo = React.useCallback(
    (getScrollPosition: (currentPosition: number) => number) => {
      const scrollElement = scrollerRef.current;

      if (!scrollElement || !getScrollPosition) {
        return;
      }

      const initialScrollWidth = scrollElement?.firstElementChild?.scrollWidth || 0;
      const maxLeft = initialScrollWidth - scrollElement.offsetWidth;

      const startLeft = Math.ceil(scrollElement.scrollLeft);
      let endLeft = getScrollPosition(startLeft);

      isCustomScrollingRef.current = false;

      if (endLeft >= maxLeft) {
        setCanScrollRight(false);
        endLeft = maxLeft;
      }

      if (!scrollElement) {
        isCustomScrollingRef.current = false;
        return;
      }

      const currentLeft = startLeft + (endLeft - startLeft);
      scrollElement.scrollLeft = Math.ceil(currentLeft);
    },
    [scrollerRef]
  );

  const scrollToLeft = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      scrollTo((i: number) => i - scrollerRef.current!.offsetWidth);
    },
    [scrollTo, scrollerRef]
  );

  const scrollToRight = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      scrollTo((i: number) => i + scrollerRef.current!.offsetWidth);
    },
    [scrollTo, scrollerRef]
  );

  const onscroll = React.useCallback(() => {
    if (scrollerRef.current && !isCustomScrollingRef.current) {
      const scrollElement = scrollerRef.current;

      setCanScrollLeft(scrollElement.scrollLeft > 0);
      setCanScrollRight(Math.ceil(scrollElement.scrollLeft) + scrollElement.offsetWidth < scrollElement.scrollWidth);
    }
  }, [scrollerRef]);

  React.useEffect(() => {
    const scroller = scrollerRef.current;

    if (scroller) {
      scroller.addEventListener('scroll', onscroll);
    }

    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', onscroll);
      }
    };
  }, [scrollerRef, children, onscroll]);

  return (
    <div
      className={classNames({
        [styles.scroll]: true,
        [styles.scrollWithArrowRight]: canScrollRight,
        [styles.scrollWithArrowLeft]: canScrollLeft,
      })}
    >
      {canScrollLeft && <LocalizationEditorTabsWrapperArrow direction="left" className={styles.arrowLeft} onClick={scrollToLeft} />}
      {canScrollRight && <LocalizationEditorTabsWrapperArrow direction="right" className={styles.arrowRight} onClick={scrollToRight} />}
      <div className={styles.in} ref={scrollerRef}>
        <div className={styles.inWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default React.memo(LocalizationEditorTabsWrapper);
