const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/ComplexTicket',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'customer/{{accountNo}}/complex-ticket', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
        {
            'rel': 'related',
            'href': "customer/{{accountNo}}/ticket-related/comment?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
            'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec',
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Ticket & CR',
    'properties': {
        'billingType': {
            'title': 'Billing type',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketBillingType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketBillingType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/billingType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Cap',
                'http://platform.cosmoconsult.com/ontology/Fixed',
                'http://platform.cosmoconsult.com/ontology/NotChargeable',
                'http://platform.cosmoconsult.com/ontology/Support',
                'http://platform.cosmoconsult.com/ontology/TimeAndMaterial',
            ],
            'enumNames': ['Cap', 'Fixed', 'Not Chargeable', 'Support', 'Time And Material'],
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 83 },
        },
        'classification': {
            'title': 'Classification',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketClassificationType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketClassificationType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/classification', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/TCAddOnsOrChangeRequests',
                'http://platform.cosmoconsult.com/ontology/TCClassificationPending',
                'http://platform.cosmoconsult.com/ontology/TCConsulting',
                'http://platform.cosmoconsult.com/ontology/TCCosmoSupportAgreement',
                'http://platform.cosmoconsult.com/ontology/TCGoodwill',
                'http://platform.cosmoconsult.com/ontology/TCManufacturerWarranty',
                'http://platform.cosmoconsult.com/ontology/TCOperatorError',
                'http://platform.cosmoconsult.com/ontology/TCReworking',
                'http://platform.cosmoconsult.com/ontology/TCSystemOrInfrastructure',
                'http://platform.cosmoconsult.com/ontology/TCWarranty',
            ],
            'enumNames': [
                'Z - Add-on or change request',
                '? - Classification pending',
                'C - Consulting',
                'W - COSMO support agreement',
                'K - Goodwill',
                'M - Warranty (Manufacturer)',
                'B - Operator error',
                'N - Reworking',
                'S - System or infrastructure',
                'G - Warranty',
            ],
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenIfUpdate': true, 'sortOrderForm': 90 },
        },
        'closeRating': {
            'title': 'Close Rating',
            '$id': 'http://platform.cosmoconsult.com/ontology/CloseRatingType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCloseRatingType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/closeRating', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Canceled',
                'http://platform.cosmoconsult.com/ontology/Excellent',
                'http://platform.cosmoconsult.com/ontology/Good',
                'http://platform.cosmoconsult.com/ontology/NotSatisfied',
                'http://platform.cosmoconsult.com/ontology/OnHold',
                'http://platform.cosmoconsult.com/ontology/Sufficient',
            ],
            'enumNames': ['Canceled', 'Excellent', 'Good', 'Not Satisfied', 'On hold', 'Sufficient'],
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 93 },
        },
        'commercialApproval': {
            'title': 'Commercial Approval',
            'type': 'boolean',
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 86 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/commercialApproval', 'rel': 'canonical' }],
        },
        'detailStatus': {
            'title': 'Status',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketDetailStatusType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketDetailStatusType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/detailStatus', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/TDSCanceled',
                'http://platform.cosmoconsult.com/ontology/TDSClosed',
                'http://platform.cosmoconsult.com/ontology/TDSCustomerInquiry',
                'http://platform.cosmoconsult.com/ontology/TDSDelivered',
                'http://platform.cosmoconsult.com/ontology/TDSInProgress',
                'http://platform.cosmoconsult.com/ontology/TDSInternalInquiry',
                'http://platform.cosmoconsult.com/ontology/TDSNotClassified',
                'http://platform.cosmoconsult.com/ontology/TDSNotReproduceable',
                'http://platform.cosmoconsult.com/ontology/TDSOpen',
                'http://platform.cosmoconsult.com/ontology/TDSPostponed',
                'http://platform.cosmoconsult.com/ontology/TDSReservedForAppointmentOnSite',
                'http://platform.cosmoconsult.com/ontology/TDSTestCustomer',
                'http://platform.cosmoconsult.com/ontology/TDSTestInternal',
                'http://platform.cosmoconsult.com/ontology/TDSVendorInquiry',
            ],
            'enumNames': [
                'S - Canceled',
                '9 - Closed',
                '5 - Inquiry (Customer)',
                '8 - Delivered',
                '2 - In progress',
                '3 - Inquiry (COSMO)',
                '0 - Not classified',
                'R - Not reproduceable',
                '1 - Open',
                'Z - Postponed',
                'V - Reserved for appointment on site',
                '7 - Test (Customer)',
                '6 - Test (COSMO)',
                '4 - Inquiry (Vendor)',
            ],
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenIfUpdate': true, 'sortOrderForm': 88 },
        },
        'effort': {
            'title': 'Effort',
            'type': 'number',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 80 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/effort', 'rel': 'canonical' }],
        },
        'effortTotal': {
            'title': 'Effort Total',
            'type': 'number',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'hiddenIfCreate': true, 'sortOrderForm': 81 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/effortTotal', 'rel': 'canonical' }],
        },
        'effortUnit': {
            'title': 'Effort Unit',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketEffortUnitType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketEffortUnitType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/effortUnit', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Day',
                'http://platform.cosmoconsult.com/ontology/Euro',
                'http://platform.cosmoconsult.com/ontology/Hour',
            ],
            'enumNames': ['Day(s)', 'Euro', 'Hour(s)'],
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 82 },
        },
        'functionalApproval': {
            'title': 'Functional Approval',
            'type': 'boolean',
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 85 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/functionalApproval', 'rel': 'canonical' }],
        },
        'hotline': {
            'title': 'Hotline',
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 77 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/hotline', 'rel': 'canonical' }],
        },
        'offered': {
            'title': 'Offered',
            'type': 'boolean',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 79 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/offered', 'rel': 'canonical' }],
        },
        'ordered': {
            'title': 'Ordered',
            'type': 'boolean',
            'cp_ui': { 'hiddenInForm': true, 'hiddenIfCreate': true, 'sortOrderForm': 87 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/ordered', 'rel': 'canonical' }],
        },
        'plannedDeliveryDate': {
            'title': 'Planned Delivery Date',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 84 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/plannedDeliveryDate', 'rel': 'canonical' }],
        },
        'testFeedback': {
            'title': 'Test Feedback',
            '$id': 'http://platform.cosmoconsult.com/ontology/TestFeedbackType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTestFeedbackType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/testFeedback', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': ['http://platform.cosmoconsult.com/ontology/NotOk', 'http://platform.cosmoconsult.com/ontology/Ok'],
            'enumNames': ['Not Ok', 'Ok'],
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 92 },
        },
        'status': {
            'title': 'Status',
            'description': 'The status of a thing',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketStatusType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketStatusType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/Canceled',
                'http://platform.cosmoconsult.com/ontology/CustomerFeedback',
                'http://platform.cosmoconsult.com/ontology/EscalatedToThirdParty',
                'http://platform.cosmoconsult.com/ontology/InProgress',
                'http://platform.cosmoconsult.com/ontology/InformationProvided',
                'http://platform.cosmoconsult.com/ontology/Merged',
                'http://platform.cosmoconsult.com/ontology/NotReproducable',
                'http://platform.cosmoconsult.com/ontology/OnHold',
                'http://platform.cosmoconsult.com/ontology/Open',
                'http://platform.cosmoconsult.com/ontology/ProblemSolved',
            ],
            'enumNames': [
                'Canceled',
                'Customer feedback',
                'Escalated to third party',
                'In progress',
                'Information provided',
                'Merged',
                'Not reproducible',
                'On hold',
                'Open',
                'Problem solved',
            ],
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true, 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'sortOrderForm': 89 },
        },
        'files': {
            'title': 'Files',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'data-url', 'title': 'Data Url' },
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 73 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/files', 'rel': 'canonical' }],
        },
        'priority': {
            'title': 'Priority',
            'type': 'string',
            'maxLength': 10,
            'cp_ui': { 'sortOrderForm': 75 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/priority', 'rel': 'canonical' }],
        },
        'additionalContact': {
            'title': 'Additional Contact',
            'type': 'string',
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'sortOrderForm': 78 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/additionalContact', 'rel': 'canonical' }],
        },
        'caseType': {
            'title': 'Case Type',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketCaseType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketCaseType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/caseType', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/ChangeRequest',
                'http://platform.cosmoconsult.com/ontology/Question',
                'http://platform.cosmoconsult.com/ontology/TechnicalSupport',
            ],
            'enumNames': ['Change request', 'Question', 'Technical support'],
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 68 },
        },
        'contact': {
            'title': 'Contact',
            'type': 'string',
            'readOnly': true,
            'format': 'email',
            'transform': ['toLowerCase', 'trim'],
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 91 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/contact', 'rel': 'canonical' }],
        },
        'entitlement': {
            'title': 'Arrangement',
            'description': '<div>Service contract, support agreement etc.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Entitlement',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'customer/{{accountNo}}/entitlement', 'endpoint': '5c6e5d27-bb2e-4a0a-b0ef-32207da4beec' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/entitlement', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Arrangement' } },
            'cp_ui': { 'hiddenInReadonlyContent': true, 'hiddenInTable': true, 'hiddenIfUpdate': true, 'sortOrderForm': 66 },
        },
        'entitlementName': {
            'title': 'Arrangement',
            'description': '<div>Service contract, support agreement etc.</div>',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 67 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/entitlementName', 'rel': 'canonical' }],
        },
        'externalReference': {
            'title': 'Your Reference',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 74 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/externalReference', 'rel': 'canonical' }],
        },
        'severity': {
            'title': 'Severity',
            '$id': 'http://platform.cosmoconsult.com/ontology/TicketSeverityType',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTicketSeverityType' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/severity', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': [
                'http://platform.cosmoconsult.com/ontology/TSCritical',
                'http://platform.cosmoconsult.com/ontology/TSHigh',
                'http://platform.cosmoconsult.com/ontology/TSMedium',
                'http://platform.cosmoconsult.com/ontology/TSLow',
            ],
            'enumNames': ['1 - Critical', '2 - High', '3 - Medium', '4 - Low'],
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 71 },
        },
        'shortDescription': {
            'title': 'Short Description (Subtitle)',
            'description': '<div>Describe your issue in short.</div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 70 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/shortDescription', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'sortOrderForm': 76 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 65 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Describe your issue in detail. Please add repro steps if applicable.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 72 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Subject',
            'description': '<div>Give your ticket a name / title / subject.</div>',
            'type': 'string',
            'cp_ui': { 'hiddenIfUpdate': true, 'sortOrderForm': 69 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['shortDescription', 'name', 'caseType', 'severity', 'entitlement'],
};
export const ComplexTicketSchema = Schema;
