import { LogLevel, Environment } from '@cp/base-types';
import { ConsoleConnection } from './connections';
export const createDefaultConnections = () => {
    const logLevelEnv = Environment.env.LOG_LEVEL;
    let logLevel;
    switch (logLevelEnv) {
        case 'ERROR':
            logLevel = LogLevel.Error;
            break;
        case 'WARN':
            logLevel = LogLevel.Warn;
            break;
        case 'INFO':
            logLevel = LogLevel.Info;
            break;
        case 'LOG':
            logLevel = LogLevel.Log;
            break;
        case 'DEBUG':
            logLevel = LogLevel.Debug;
            break;
        default:
            logLevel = LogLevel.Log;
            break;
    }
    return [new ConsoleConnection(logLevel)];
};
