import axios, { AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse, CancelTokenSource, CancelTokenStatic } from 'axios';

interface AxiosPromise<T = any> extends Promise<T> {}

export interface IAxiosInstance {
  (config: AxiosRequestConfig): AxiosPromise;

  (url: string, config?: AxiosRequestConfig): AxiosPromise;

  defaults: AxiosRequestConfig;
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };

  request<T = any>(config: AxiosRequestConfig): AxiosPromise<T>;

  get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>;

  delete(url: string, config?: AxiosRequestConfig): AxiosPromise;

  head(url: string, config?: AxiosRequestConfig): AxiosPromise;

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T>;

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T>;

  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T>;

  CancelToken: CancelTokenStatic;
}

export const isCancelError = axios.isCancel;

export const configureAxios = (
  baseUrl: string,
  key?: string,
  getAccessToken?: () => string | Promise<string | undefined | null> | undefined | null,
  getLanguage?: () => string | null,
  withCredentials: boolean = true,
  authErrorCb?: () => void,
  cpaIdentifier?: string
): IAxiosInstance => {
  const axiosInstance: IAxiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: withCredentials,
    headers: key
      ? {
          'x-functions-key': key,
        }
      : {},
  }) as unknown as IAxiosInstance;

  axiosInstance.CancelToken = axios.CancelToken;

  axiosInstance.interceptors.request.use(
    async (req) => {
      const language = req.headers['Accept-Language'] || getLanguage?.();
      if (getAccessToken) {
        const token = await getAccessToken();
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }
      }

      if (language) {
        if (req.method && ['POST', 'PUT', 'PATCH'].includes(req.method.toUpperCase()) && !req.headers['Content-Language']) {
          req.headers['Content-Language'] = language;
        }
        req.headers['Accept-Language'] = `${language},*`;
      }

      if (cpaIdentifier) {
        req.headers['x-cp-cpa-id'] = cpaIdentifier;
      }

      console.debug(`Sending ${req.method} request to ${baseUrl} - ${req.url}. Language: ${language}.`);
      return req;
    },
    (error) => {
      console.error(`Failed to send http request.`, error);
      return error;
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      console.debug(`Fetched http ${res.config.method} response ${res.config.url}. Status: ${res.status}.`);
      return res.data;
    },
    (error) => {
      if (isCancelError(error)) {
        throw error;
      }

      console.error(`Http request failed.`, error);

      if (!error?.response?.status) {
        const networkError: Error & {
          originalError?: typeof error;
        } = new Error('Network error. Please try again later.');
        networkError.originalError = error;
        throw networkError;
      }

      if (!error?.response?.data?.message || typeof error?.response?.data !== 'object') {
        const networkError: Error & {
          originalError?: typeof error;
        } = new Error('Server error. Please try again later.');
        networkError.originalError = error;
        throw networkError;
      }

      if ([401, 403].includes(error.response.status)) {
        authErrorCb?.();
      }

      throw { ...error.response.data, originalError: error };
    }
  );

  return axiosInstance;
};

export function createCancelToken(): CancelTokenSource {
  return axios.CancelToken.source();
}

export type { CancelTokenSource };
