import React from 'react';

import { IGroupedLinks, ITargetWorkItem, IWorkItem } from '../../helpers/types';
import TargetWorkItem from '../TargetWorkItem/TargetWorkItem';

import styles from './RelatedWorkItems.module.scss';

interface RelatedWorkProps {
  item: IWorkItem;
  groupedLinks: IGroupedLinks;
}

const RelatedWorkItems: React.FC<RelatedWorkProps> = ({ item, groupedLinks }) => {
  return (
    <div className={styles.linkGroup}>
      <div className={styles.groupTitle}>
        {groupedLinks.linkTypeName} {groupedLinks.targetItems.length > 1 ? `(${groupedLinks.targetItems.length})` : ''}
      </div>
      {groupedLinks.targetItems.map((target: ITargetWorkItem) => (
        <TargetWorkItem key={target.WorkItemId} item={item} target={target} />
      ))}
    </div>
  );
};

export default RelatedWorkItems;
