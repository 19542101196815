import { IJSONSchema, Schemas } from '@cp/base-types';
import { ODataPropsFilter } from '@cp/base-utils';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { IChangeEvent } from '@rjsf/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ShimmerGrid from '../../components/ShimmerGrid/ShimmerGrid';

import styles from './Statement.module.scss';
import { useStatementItem } from './useStatementItem';

export interface IStatementItemProps {
  page: Schemas.CpaPage;
  targetItem?: IDataItem;
  schema: IJSONSchema | null;
  onAdd?: (arg: IDataItem) => void;
  onEdit?: (edited: IDataItem, initial: IDataItem) => void;
  filter?: ODataPropsFilter;
  submitOnFormChange?: boolean;
  onFormSubmit?: ({ formData }: IChangeEvent) => Promise<void>;
  shimmerHeight?: number;
  aggregatedData?: IDataItem;
  labels: {
    total?: string;
    active?: string;
    revoked?: string;
    noData?: string;
  };
  isAggregationFetching?: boolean;
  isButtonDataFetching?: boolean;
  isSmall?: boolean;
}

export const StatementItem: React.FC<IStatementItemProps> = (props) => {
  const [form, formattedData] = useStatementItem(props);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const widgetData = useMemo(() => {
    if (props.isAggregationFetching || props.isButtonDataFetching) {
      return <ShimmerGrid height={props.isSmall ? 93 : 154} gridArea={'"a"'} />;
    }
    if (props.isSmall) {
      return <span className={styles.label}>{formattedData}</span>;
    }
    return <div className={darkMode ? styles.sectionDark : styles.section}>{formattedData}</div>;
  }, [darkMode, formattedData, props.isAggregationFetching, props.isButtonDataFetching, props.isSmall]);

  return (
    <div className={styles.containerWrapper}>
      <section className={classNames(styles.container, { [styles.small]: props.isSmall })}>
        {props.isButtonDataFetching || !props.schema ? <ShimmerGrid height={93} gridArea={'"a"'} /> : form}
        {widgetData}
      </section>
    </div>
  );
};
