import { createBrowserHistory } from 'history';

import { getAppBasePath } from '../helpers/location';

export const history = createBrowserHistory({
  basename: getAppBasePath(),
});

try {
  // For scripts
  Object.defineProperty(window, 'appHistory', {
    value: history,
  });
} catch (e) {
  console.error(`Failed to assign appHistory to window`, e);
}

export default history;
