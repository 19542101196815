import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IGlobalState } from '@cpa/base-core/store';
import { useSelector } from 'react-redux';

import styles from './MenuButton.module.scss';

interface IMenuButtonProps {
  text: string;
  isActive: boolean;
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  isExpandable: boolean;
  expanded: boolean;
  onExpandClick: () => void;
  iconName?: string;
  url?: string;
  external?: boolean;
  level?: number;
}

const MenuButton: React.FC<IMenuButtonProps> = ({
  text,
  isExpandable,
  expanded,
  isActive,
  onClick,
  onExpandClick,
  iconName,
  url,
  external,
  level = 0,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const activeMarker = useMemo(() => <div key={url + '_activeMarker'} className={styles.activeMarker} />, [url]);
  const hoverMarker = useMemo(
    () => [<div key={url + '_hoverMarker'} className={styles.hoverMarker} />, <div key={url + '_hoverLine'} className={styles.hoverLine} />],
    [url]
  );

  const isCalculatedLink = useMemo(() => {
    if (!url) return false;
    return url.startsWith('/_navigation/');
  }, [url]);

  const icon = useMemo(() => (iconName ? <Icon iconName={iconName} /> : null), [iconName]);

  const expander = useMemo(
    () =>
      (onExpanderClick?: () => void, isFocusable: string = 'true'): JSX.Element =>
        (
          <div data-is-focusable={isFocusable} className={classNames(styles.arrow, { [styles.arrowLight]: !darkMode })} onClick={onExpanderClick}>
            <Icon iconName={'ChevronDown'} className={classNames(styles.collapsed, { [styles.expanded]: expanded })} />
          </div>
        ),
    [darkMode, expanded]
  );

  const onCalculatedLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onExpandClick();
    },
    [onExpandClick]
  );

  // Settings
  const indentationPerLevel = 12;

  const classNameText = level === 0 ? styles.textRootLevel : styles.text;

  const content = useMemo(() => {
    if (!url) {
      return isExpandable ? (
        <div
          onClick={onExpandClick}
          className={classNames(
            { [styles.flexible]: !isActive, [styles.flexibleActive]: isActive, [styles.clickableLight]: !darkMode },
            styles.clickable
          )}
        >
          <div style={{ display: 'flex' }}>
            <div className={styles.empty} style={{ width: level * indentationPerLevel, height: 44 }} />
            <div className={classNames(styles.icon)}>{icon}</div>
          </div>
          <div style={{ display: 'flex' }} data-is-focusable="true">
            <div data-testid="MainMenuItem__text" className={classNames(classNameText)}>
              {text}
            </div>
            {expander(undefined, 'false')}
          </div>
        </div>
      ) : null;
    }

    if (url && isCalculatedLink) {
      return isExpandable ? (
        <a
          onClick={onCalculatedLinkClick}
          className={classNames(
            { [styles.flexible]: !isActive, [styles.flexibleActive]: isActive, [styles.clickableLight]: !darkMode },
            styles.clickable,
            styles.calculatedLink
          )}
          href={url}
        >
          <div style={{ display: 'flex' }}>
            <div className={styles.empty} style={{ width: level * indentationPerLevel, height: 44 }} />
            <div className={classNames(styles.icon)}>{icon}</div>
          </div>
          <div style={{ display: 'flex' }} data-is-focusable="true">
            <div data-testid="MainMenuItem__text" className={classNames(classNameText)}>
              {text}
            </div>
            {expander(undefined, 'false')}
          </div>
        </a>
      ) : null;
    }

    return isExpandable ? (
      <div
        className={classNames({
          [styles.flexible]: !isActive,
          [styles.flexibleActive]: isActive,
          [styles.active]: isActive,
          [styles.activeLight]: isActive && !darkMode,
          [styles.linkable]: !isActive,
          [styles.linkableLight]: !isActive && !darkMode,
        })}
      >
        {external ? (
          <>
            <a
              data-is-focusable="false"
              href={url}
              target="_blank"
              rel="noreferrer"
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
            >
              <span className={styles.empty} style={{ width: level * indentationPerLevel, height: 44 }} />
              <span className={classNames(styles.icon)}>{icon}</span>
            </a>
            <a
              data-is-focusable="true"
              href={url}
              target="_blank"
              rel="noreferrer"
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
            >
              <span data-testid="MainMenuItem__text" className={classNames(classNameText)}>
                {text}
              </span>
            </a>
          </>
        ) : (
          <>
            <Link
              data-is-focusable="false"
              to={{ pathname: url, state: undefined }}
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
            >
              <span className={styles.empty} style={{ width: level * indentationPerLevel, height: 44 }} />
              <span className={classNames(styles.icon)}>{icon}</span>
            </Link>
            <Link
              to={{ pathname: url, state: undefined }}
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
              role="menuitem"
              data-is-focusable="true"
            >
              <span data-testid="MainMenuItem__text" className={classNames(classNameText)}>
                {text}
              </span>
            </Link>
          </>
        )}
        {expander(onExpandClick)}
      </div>
    ) : (
      <div
        className={classNames({
          [styles.flexible]: !isActive,
          [styles.flexibleActive]: isActive,
          [styles.active]: isActive,
          [styles.activeLight]: isActive && !darkMode,
          [styles.linkable]: !isActive,
          [styles.linkableLight]: !isActive && !darkMode,
        })}
      >
        {external ? (
          <>
            <a
              data-is-focusable="false"
              href={url}
              target="_blank"
              rel="noreferrer"
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
            >
              <span className={styles.empty} style={{ width: level * indentationPerLevel, height: 44 }} />
              <span className={classNames(styles.icon)}>{icon}</span>
            </a>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
              data-is-focusable="true"
            >
              <span data-testid="MainMenuItem__text" className={classNames(classNameText)}>
                {text}
              </span>
            </a>
          </>
        ) : (
          <>
            <Link
              data-is-focusable="false"
              to={{ pathname: url, state: undefined }}
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
            >
              <span className={styles.empty} style={{ width: level * indentationPerLevel, height: 44 }} />
              <span className={classNames(styles.icon)}>{icon}</span>
            </Link>
            <Link
              to={{ pathname: url, state: undefined }}
              className={classNames(styles.link, { [styles.linkLight]: !darkMode })}
              onClick={onClick}
              data-is-focusable="true"
            >
              <span data-testid="MainMenuItem__text" className={classNames(classNameText)}>
                {text}
              </span>
            </Link>
          </>
        )}
      </div>
    );
  }, [isCalculatedLink, darkMode, onExpandClick, isExpandable, url, text, icon]);

  return (
    <div data-testid="MainMenuItem" className={classNames(styles.btn, { [styles.btnLight]: !darkMode })}>
      {isActive ? activeMarker : hoverMarker}
      {content}
    </div>
  );
};

export default MenuButton;
