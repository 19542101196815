import { IJSONSchema, Schemas } from '@cp/base-types';
import { FormatSource } from '@cpa/base-core/constants';
import {
  cloneValueAndCleanUpInternalProperties,
  formatDate,
  formatDateTime,
  formatDateTimeLongYear,
  formatStringOrNumber,
  formatTimespan,
  getFileExtension,
  getFilenameFromURL,
  getSafeString,
  isAzureStorageResource,
  isDefined,
} from '@cpa/base-core/helpers';
import { IDataItem } from '@cpa/base-core/types';
import { CommandBarButton, Label, TooltipOverflowMode } from '@fluentui/react';
import React from 'react';

import { HtmlRenderers } from '../../mapping';
import HoverTooltip from '../HoverTooltip/HoverTooltip';
import ReadonlyContent from '../ReadonlyContent/ReadonlyContent';
import HoverCardAutogrow from '../ScrollingContent/components/Table/components/HoverCardAutogrow/HoverCardAutogrow';
import ObjectPreviewContent from '../ScrollingContent/components/Table/components/ObjectPreviewContent/ObjectPreviewContent';

import JobStatusBadge from './components/JobStatusBadge/JobStatusBadge';
import Language from './components/Language/Language';
import Email from './components/Email/Email';
import VersionInfo from './components/VersionInfo/VersionInfo';
import MessageTitle from './components/MessageTitle/MessageTitle';

interface IFormatValueOptions {
  schema: IJSONSchema | null;
  locale: string;
  darkMode: boolean;
  t: (key: string) => string;
  itemKey: string;
  rootSchema: IJSONSchema;
  originalItem: IDataItem;
  onDownload?: () => void | Promise<void>;
  disableTooltip?: boolean;

  // To proceed with versions on dashboard
  page?: Schemas.CpaPage;
}

// Cache for currency formatters. Should not be initialized for each call of 'formatValue'
const currencyFormatters: { [locale: string]: Intl.NumberFormat | undefined } = {};

export const formatValue = (value: unknown, source: FormatSource, options: IFormatValueOptions): string | JSX.Element | null => {
  const { schema, locale, darkMode, onDownload, t, itemKey, rootSchema, originalItem } = options;
  if (!isDefined(value)) {
    return null;
  }

  let formatted: string | JSX.Element = (value as string).toString?.() || (value as string);

  // Version info badge
  // Moved outside switch because 'value' could be not only a string
  if (schema?.format === 'cp:versionInfo') {
    // TODO: handle here cases with objects and arrays
    return (
      <VersionInfo
        page={options.page}
        item={originalItem}
        srcFieldName={itemKey}
        srcFieldValue={value as IDataItem}
        schema={rootSchema}
        darkMode={darkMode}
        source={source}
      />
    );
  }

  if (schema?.format === 'cp:language' || schema?.items?.format === 'cp:language') {
    return <Language value={value as string | string[]} />;
  }

  if (schema?.format === 'email' || schema?.items?.format === 'email') {
    // Ensure only strings are passed (example: 'owners' can be array of strings and objects)
    const stringValues = (Array.isArray(value) ? value : [value]).filter((v) => typeof v === 'string');
    return <Email email={stringValues} source={source} />;
  }

  // JobStatus badge
  if (schema?.format === 'cp:jobStatus') {
    return (
      <JobStatusBadge
        page={options.page}
        item={originalItem}
        srcFieldName={itemKey}
        srcFieldValue={value as string}
        schema={rootSchema}
        darkMode={darkMode}
      />
    );
  }

  if (schema?.format === 'cp:monetaryAmount') {
    const monetaryAmount = value as { value: number; currency: string };
    if (typeof monetaryAmount.value === 'number' && typeof monetaryAmount.currency === 'string') {
      // Try get cached formatter, if not found init new formatter for current locale
      let currencyFormatter = currencyFormatters[locale];
      if (!currencyFormatter) {
        currencyFormatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        currencyFormatters[locale] = currencyFormatter;
      }

      return (
        <div style={{ textAlign: 'right', fontFamily: 'Verdana', fontSize: '13px' }}>
          {currencyFormatter.format(monetaryAmount.value)} {monetaryAmount.currency}
        </div>
      );
    }
  }

  if (schema?.format === 'cp:messageTitle') {
    return <MessageTitle item={originalItem} srcFieldValue={value as string} />;
  }

  if (schema?.type === 'string') {
    // enum matching
    if (schema.enum && schema.enumNames) {
      const enumIndex = schema.enum.findIndex((s) => s === value);
      if (enumIndex !== -1) {
        formatted = schema.enumNames[enumIndex];
        return formatted;
      }
    }

    if (!schema?.format) {
      return options.disableTooltip ? (
        formatted
      ) : (
        <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formatted}>
          {formatted}
        </HoverTooltip>
      );
    }

    switch (schema?.format) {
      case 'date': {
        const formattedRawValue = formatDate(value as string, locale);
        formatted = (
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formattedRawValue}>
            {formattedRawValue}
          </HoverTooltip>
        );
        break;
      }
      case 'date-time': {
        const formattedRawValue = formatDateTime(value as string, locale);
        formatted = (
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formattedRawValue}>
            {formattedRawValue}
          </HoverTooltip>
        );
        break;
      }
      case 'date-time-long-year': {
        const formattedRawValue = formatDateTimeLongYear(value as string, locale);
        formatted = (
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formattedRawValue}>
            {formattedRawValue}
          </HoverTooltip>
        );
        break;
      }
      case 'cp:timespan': {
        const formattedRawValue = formatTimespan(value as string);
        formatted = (
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formattedRawValue}>
            {formattedRawValue}
          </HoverTooltip>
        );
        break;
      }
      case 'cp:color':
        formatted = (
          <div style={{ backgroundColor: value as string, display: 'flex', justifyContent: 'center', borderRadius: '5px' }}>
            <div style={{ mixBlendMode: 'difference' }}>{value as string}</div>
          </div>
        );
        break;
      case 'url':
      case 'uri':
        if (source === FormatSource.Table) {
          return (
            <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formatted}>
              {formatted}
            </HoverTooltip>
          );
        }
        formatted = (
          <a href={value as string} rel={'nofollow noreferrer noopener'} target={'_blank'} style={{ color: '#f39c4d' }}>
            {value as string}
          </a>
        );
        break;
      case 'data-url':
        if (source === FormatSource.Table) {
          return (
            <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formatted}>
              {formatted}
            </HoverTooltip>
          );
        }

        const handleDownload = (e: React.MouseEvent<HTMLDivElement>): void => {
          e.stopPropagation();
          onDownload?.();
        };

        const canOpen = getFileExtension(value as string)[1];

        formatted = (
          <div>
            {isAzureStorageResource(value as string) && <Label>{getFilenameFromURL(value as string)}</Label>}
            <CommandBarButton
              iconProps={{
                iconName: canOpen ? 'OpenInNewTab' : 'Download',
              }}
              style={{
                marginTop: 4,
                padding: '10px 12px',
              }}
              onClick={handleDownload}
            >
              {canOpen ? t('common.open') : t('common.download')}
            </CommandBarButton>
          </div>
        );
        break;
      case 'cp:html':
      case 'cp:htmlAttachmentReplacing':
      case 'cp:htmlView':
        const HtmlRendererComponent = HtmlRenderers[schema?.format] || HtmlRenderers.Default;

        if (source === FormatSource.Table) {
          formatted = (
            <HoverCardAutogrow
              previewContentRenderer={() => getSafeString(value as string, true) || '-'}
              isInsideTable={true}
              expandDelay={750}
              disableMaxHeight={true}
            >
              <HtmlRendererComponent html={value as string} item={originalItem} page={options.page} />
            </HoverCardAutogrow>
          );
        } else if (source === FormatSource.Card) {
          formatted = (
            <HoverCardAutogrow previewContentRenderer={() => getSafeString(value as string, true) || '-'}>
              <HtmlRendererComponent html={value as string} item={originalItem} page={options.page} />
            </HoverCardAutogrow>
          );
        } else {
          formatted = (
            <div style={{ wordBreak: 'break-word' }}>
              <HtmlRendererComponent html={value as string} item={originalItem} page={options.page} />
            </div>
          );
        }
        break;
      default:
        formatted = (
          <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={value as string}>
            {value as string}
          </HoverTooltip>
        );
    }
  } else if (schema?.type === 'number' || schema?.type === 'integer') {
    formatted = formatStringOrNumber(+(value as number), locale);
  } else if (schema?.type === 'boolean') {
    formatted = value === true ? t('common.yes') : t('common.no');
  } else if (typeof value === 'object') {
    if (!schema) {
      return '-';
    }

    const valueWithoutInternalProperties: IDataItem | IDataItem[] = cloneValueAndCleanUpInternalProperties(value as IDataItem | IDataItem[], schema);
    if (
      !Object.keys(valueWithoutInternalProperties).length ||
      Object.values(valueWithoutInternalProperties).every((v) => v === undefined || v === null)
    ) {
      return '-';
    }

    // Object or array
    const fullValueContent = Array.isArray(value) ? (
      <>
        {(value as IDataItem[]).map((v, index) => (
          <React.Fragment key={index}>
            <ReadonlyContent
              item={v}
              data={{
                schema: schema.items as IJSONSchema,
                page: options.page || ({} as Schemas.CpaPage),
              }}
              options={{
                source: FormatSource.HoverCard,
              }}
            />
          </React.Fragment>
        ))}
      </>
    ) : (
      <ReadonlyContent
        item={value as IDataItem}
        data={{
          schema: schema,
          page: options.page || ({} as Schemas.CpaPage),
        }}
        options={{
          source: FormatSource.HoverCard,
        }}
      />
    );

    // fullValueContent can be big, so we try to take small text preview
    const previewContent = (): JSX.Element => {
      return <ObjectPreviewContent value={valueWithoutInternalProperties} locale={locale} schema={schema} />;
    };

    return (
      <HoverCardAutogrow isInsideTable={true} disableMaxHeight={true} previewContentRenderer={previewContent} expandDelay={750}>
        {fullValueContent}
      </HoverCardAutogrow>
    );
  } else {
    const formattedRawValue = (value as string).toString();
    formatted = options.disableTooltip ? (
      formattedRawValue
    ) : (
      <HoverTooltip overflowMode={TooltipOverflowMode.Parent} content={formattedRawValue}>
        {formattedRawValue}
      </HoverTooltip>
    );
  }

  return formatted;
};
