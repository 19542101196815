import { IGlobalState } from '@cpa/base-core/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Schemas } from '@cp/base-types';
import { clearCache } from 'react-router-cache-route';
import { useTranslation } from 'react-i18next';

import { ScreenTypesConfiguration } from '../../mapping';

import FlexibleRoutes from './components/FlexibleRoutes/FlexibleRoutes';
import Preload from './components/Preload/Preload';

const Routes: React.FC = () => {
  const pages: Schemas.CpaPage[] = useSelector((state: IGlobalState) => state.app.pages);
  const isAppReady = useSelector((state: IGlobalState) => state.app.isReady);
  const configuration = useSelector((state: IGlobalState) => state.app.cpa?.configuration);

  const [, i18n] = useTranslation();

  useEffect(() => {
    clearCache();
  }, [i18n.language]);

  if (!configuration) {
    return null;
  }

  return (
    <Preload loaderSelector="#loader" isAppReady={isAppReady}>
      <FlexibleRoutes pages={pages} configuration={ScreenTypesConfiguration} />
    </Preload>
  );
};

export default Routes;
