import { createActions } from 'redux-actions';

export const {
  wsConnected,
  wsDisconnected,
  wsSubscribeToEntityModification,
  wsEntityModification,
  wsBatchOperationStatus,
  wsSubscribeToBatchOperationStatus,
} = createActions(
  'WS_CONNECTED',
  'WS_DISCONNECTED',
  'WS_SUBSCRIBE_TO_ENTITY_MODIFICATION',
  'WS_ENTITY_MODIFICATION',
  'WS_BATCH_OPERATION_STATUS',
  'WS_SUBSCRIBE_TO_BATCH_OPERATION_STATUS'
);
