import React from 'react';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { IDataItem } from '@cpa/base-core/types';
import { ODataPropsFilter } from '@cp/base-utils';

import { ScreenTypesConfiguration } from '../../../../../mapping';
import GenericScreen from '../../../../../screens/GenericScreen/GenericScreen';

interface ISubPageProps {
  page: Schemas.CpaPage;
  item: IDataItem;
  schema: IJSONSchema;
  filter: ODataPropsFilter;
  prefill: IDataItem<unknown>;
  handleHtmlContentClick: (e: React.MouseEvent<HTMLElement>) => void;
  classnames: string;
}

const SubPage: React.FC<ISubPageProps> = ({ page, item, schema, filter, prefill, handleHtmlContentClick, classnames }) => {
  if (page.customTemplate?.identifier) {
    const _Component = ScreenTypesConfiguration[page.customTemplate.identifier];
    return (
      <div key={page.identifier} onClick={handleHtmlContentClick} className={classnames}>
        <h1 data-heading={page.identifier} style={{ visibility: 'hidden', height: 0, padding: 0, margin: 0 }}>
          {page.name}
        </h1>
        <_Component
          withoutAnimation={true}
          item={item}
          itemSchema={schema}
          page={page}
          externalODataFilter={filter}
          isWidget={true}
          externalPrefill={prefill}
        />
      </div>
    );
  }
  return (
    <div key={page?.identifier} onClick={handleHtmlContentClick} className={classnames}>
      <h1 data-heading={page.identifier} style={{ visibility: 'hidden', height: 0, padding: 0, margin: 0 }}>
        {page.name}
      </h1>
      <GenericScreen
        withoutAnimation={true}
        item={item}
        itemSchema={schema}
        page={page}
        externalODataFilter={filter}
        isWidget={true}
        externalPrefill={prefill}
      />
    </div>
  );
};

export default SubPage;
