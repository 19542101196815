import { IJSONSchema } from '@cp/base-types';

import { removeOverwritePaths } from './removeOverwritePaths';
import { replaceOverwritePaths } from './replaceOverwritePaths';

/**
 * Changes schema overwrites on the removal of an item from an array schema path.
 * @param schemaOverwrites - The current schema overwrites.
 * @param arrayPath - The path to the array in which an item is dropped.
 * @param indexToDrop - The index of the item to be removed.
 * @param itemsCount - The number of items currently in the array.
 */
export function changeOverwritesOnArrayItemRemove(
  schemaOverwrites: Record<string, IJSONSchema>,
  arrayPath: string,
  indexToDrop: number,
  itemsCount: number
): Record<string, IJSONSchema> {
  let newSchemaOverwrites = removeOverwritePaths(schemaOverwrites, [arrayPath + `[${indexToDrop}]`]);

  if (indexToDrop + 1 < itemsCount) {
    for (let i = indexToDrop + 1; i < itemsCount; i++) {
      newSchemaOverwrites = replaceOverwritePaths(newSchemaOverwrites, [
        {
          pathFrom: arrayPath + `[${i}]`,
          pathTo: arrayPath + `[${i - 1}]`,
        },
      ]);
    }
  }

  return newSchemaOverwrites;
}
