import { Schemas } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { changePageSetting } from '@cpa/base-core/store/settings/actions';
import { ChartOptionsType, ChartType, IGenericComponentData, IPageSetting, ITooltipOptions } from '@cpa/base-core/types';
import { IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSyncedPageSettings } from '@cpa/base-core/hooks';

import LoadingArea from '../../../LoadingArea/LoadingArea';
import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import ChartSettingsDrawer from './ChartSettingsDrawer/ChartSettingsDrawer';
import styles from './ChartWrapper.module.scss';

const Chart = lazy(() => import('../../../Chart/Chart'));

export interface IChartWrapperProps {
  data: IGenericComponentData;

  darkMode: boolean;
  disableAnimation: boolean;
  isWidget: boolean;
  onAreaClick?: (...params: string[]) => void;
}

const ChartWrapper: React.FC<IChartWrapperProps> = ({ data, darkMode, disableAnimation, isWidget, onAreaClick }) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const pageSettings: IPageSetting | undefined = useSelector((state: IGlobalState) => state.settings.pages[data.page.identifier!]);
  const cachedChartOptions = pageSettings ? pageSettings.chartOptions : undefined;
  const updatePageSetting = useSyncedPageSettings();

  const [chartOptions, setChartOptions] = useState<Schemas.CpaPage['chart']>(cachedChartOptions ? cachedChartOptions : data.page.chart);

  const chartFormatter = useCallback((num: number): string => (num > 0 ? t('common.moreItems', { num }) : ''), [t]);

  const [chartOptionsShown, { toggle: toggleChartOptionsShown }] = useBoolean(false);

  const updateChart = useCallback(
    (chartOptionsToSet: Schemas.CpaPage['chart']) => {
      updatePageSetting({
        pageKey: data.page.identifier,
        setting: 'chartOptions',
        value: chartOptionsToSet,
      });

      setChartOptions(chartOptionsToSet);
      toggleChartOptionsShown();
    },
    [updatePageSetting, data.page.identifier, toggleChartOptionsShown]
  );

  const chartToolbar = useMemo(
    () => (
      <div className={styles.toolbar}>
        <HoverTooltip content={t('common.chartSettings')}>
          <IconButton
            className={classNames(styles.icon, { [styles.dark]: darkMode })}
            iconProps={{ iconName: 'Settings' }}
            onClick={toggleChartOptionsShown}
          />
        </HoverTooltip>
      </div>
    ),
    [toggleChartOptionsShown, darkMode, t]
  );

  return useMemo(
    () => (
      <div className={styles.wrapper}>
        {chartToolbar}

        {chartOptionsShown && (
          <ChartSettingsDrawer
            chartOptions={chartOptions}
            defaultChartOptions={data.page.chart}
            cpTypeUrl={data.page.cpTypeUrl!}
            onChange={updateChart}
            onClose={toggleChartOptionsShown}
          />
        )}

        {!!chartOptions && (
          <Suspense fallback={<LoadingArea />}>
            <Chart
              darkMode={darkMode}
              disableAnimation={disableAnimation}
              type={chartOptions!._type as ChartType}
              options={chartOptions! as ChartOptionsType}
              data={data.items}
              schema={data.schema}
              onAreaClick={onAreaClick}
              tooltipOptions={chartOptions!.tooltipPreviewProperty ? (chartOptions as ITooltipOptions) : undefined}
              dataCounterFormatter={chartFormatter}
              isWidget={isWidget}
              page={data.page}
            />
          </Suspense>
        )}
      </div>
    ),
    [
      chartFormatter,
      chartToolbar,
      darkMode,
      data,
      chartOptionsShown,
      chartOptions,
      updateChart,
      disableAnimation,
      isWidget,
      onAreaClick,
      toggleChartOptionsShown,
    ]
  );
};

export default ChartWrapper;
