import * as _ from 'lodash';
import { DataItemProperties, IJSONSchema, IPathMeta } from '@cp/base-types';
import { IFilter, IFilterValue, Operator } from '@cp/base-utils';

export type SuggestionValue = string | boolean | number;

export interface IFilterDescriptor {
  schemaPath: IPathMeta;
  operator: Operator;
  value: SuggestionValue | SuggestionValue[];
  index: number;
}

const searchTextSchema: IJSONSchema = {
  type: 'string',
};

export const searchTextColumnDescriptor: IPathMeta = {
  enumMapping: undefined,
  items: [
    { title: '-', propertySchema: { type: 'object' } },
    { title: 'Search text', propertySchema: searchTextSchema },
  ],
  propertySchema: searchTextSchema,
  propertyJsonPath: DataItemProperties.SEARCH_TEXT_KEY,
  type: 'string',
};

export const filterLayerColumnDescriptor: IPathMeta = {
  enumMapping: undefined,
  items: [
    { title: '-', propertySchema: { type: 'object' } },
    { title: 'Filter Layer', propertySchema: { type: 'string' } },
  ],
  propertySchema: { type: 'string' },
  propertyJsonPath: '__virtual',
  type: 'string',
};

export type IFilterExpression =
  | { $contains: SuggestionValue | SuggestionValue[] }
  | { $not: SuggestionValue | SuggestionValue[] }
  | { $not: null }
  | { $not: { $contains: SuggestionValue | SuggestionValue[] } }
  | { $gt: SuggestionValue }
  | { $lt: SuggestionValue }
  | SuggestionValue
  | SuggestionValue[]
  | null;

export const getFilterExpression = (filterOption: Operator, value: SuggestionValue | SuggestionValue[]): IFilterExpression => {
  switch (filterOption) {
    case Operator.NULL:
      return null;
    case Operator.NOT_NULL:
      return { $not: null };
    case Operator.CONTAINS:
      return { $contains: value };
    case Operator.NOT_EQUALS:
      return { $not: value };
    case Operator.NOT_CONTAINS:
      return { $not: { $contains: value } };
    case Operator.GREATER_THAN:
      return { $gt: Array.isArray(value) ? value[0] : value };
    case Operator.LESS_THAN:
      return { $lt: Array.isArray(value) ? value[0] : value };
    case Operator.EQUALS:
    default:
      return value;
  }
};

export function sortDescriptorsByEntriesOrder(descriptors: IFilterDescriptor[], order?: string[]): void {
  descriptors.sort((a, b) => {
    const aIndex = order?.indexOf(a.schemaPath.propertyJsonPath) || 0;
    const bIndex = order?.indexOf(b.schemaPath.propertyJsonPath) || 0;

    return aIndex - bIndex;
  });
}

export function generateDescriptorsFromParsedFilter(
  parsedFilter: IFilter,
  schemaPathsMap: Record<string, IPathMeta>,
  addGlobalFilter: boolean = true
): IFilterDescriptor[] {
  const counter: Record<string, number> = {};

  const generatedDescriptors = Object.entries(parsedFilter.entries ?? {})
    .flatMap(([operator, operatorItems]): IFilterDescriptor[] => {
      if (operator === Operator.AND || operator === Operator.OR) {
        const subFilters = operatorItems as IFilter[];

        return subFilters.flatMap((subFilter): IFilterDescriptor[] => {
          if (subFilter.entries) {
            if (Operator.AND in subFilter.entries && Array.isArray(subFilter.entries[Operator.AND])) {
              counter[filterLayerColumnDescriptor.propertyJsonPath] = counter[filterLayerColumnDescriptor.propertyJsonPath] || 0;

              return subFilter.entries[Operator.AND]!.map((filterLayerFilter) => ({
                schemaPath: filterLayerColumnDescriptor,
                value: JSON.stringify(filterLayerFilter),
                operator: Operator.AND,
                index: counter[filterLayerColumnDescriptor.propertyJsonPath]++,
              }));
            } else if (Operator.OR in subFilter.entries) {
              counter[filterLayerColumnDescriptor.propertyJsonPath] = counter[filterLayerColumnDescriptor.propertyJsonPath] || 0;

              return subFilter.entries[Operator.OR]!.map((filterLayerFilter) => ({
                schemaPath: filterLayerColumnDescriptor,
                value: JSON.stringify(filterLayerFilter),
                operator: Operator.OR,
                index: counter[filterLayerColumnDescriptor.propertyJsonPath]++,
              }));
            }
          }

          return generateDescriptorsFromParsedFilter(subFilter, schemaPathsMap, false);
        });
      }

      return Object.entries((operatorItems ?? {}) as IFilterValue).map(([property, value]) => {
        counter[property] = counter[property] || 0;

        const schemaPath = schemaPathsMap[property];
        return { schemaPath, value: value ?? '', operator: operator as Operator, index: counter[property]++ };
      });
    })
    .filter((descriptor) => !!descriptor.schemaPath);

  sortDescriptorsByEntriesOrder(generatedDescriptors, parsedFilter.entriesOrder);

  if (
    (parsedFilter.global || parsedFilter.entries?.contains?.[searchTextColumnDescriptor.propertyJsonPath] || addGlobalFilter) &&
    generatedDescriptors.every((descriptor) => descriptor.schemaPath.propertyJsonPath !== searchTextColumnDescriptor.propertyJsonPath)
  ) {
    generatedDescriptors.unshift({
      schemaPath: searchTextColumnDescriptor,
      operator: Operator.CONTAINS,
      value: parsedFilter.global || parsedFilter.entries?.contains?.[searchTextColumnDescriptor.propertyJsonPath] || '',
      index: 0,
    });
  }

  return generatedDescriptors;
}

export function getNextDescriptorIndexForPath(descriptors: IFilterDescriptor[], propertyJsonPath: string): number {
  return descriptors.filter((descriptor) => descriptor.schemaPath.propertyJsonPath === propertyJsonPath).length;
}

export const generateRawJsonFilterFromDescriptors = (
  descriptors: IFilterDescriptor[],
  groupOperator: Operator,
  modifier?: {
    propertyJsonPath: string;
    index: number;
    processor: (descriptor: IFilterDescriptor) => IFilterDescriptor | null;
  }
): Record<string, IFilterExpression> | string => {
  const updatedDescriptors = descriptors
    .map((descriptor) => {
      if (modifier?.propertyJsonPath === descriptor.schemaPath.propertyJsonPath && modifier.index === descriptor.index) {
        return modifier.processor(descriptor);
      }
      return descriptor;
    })
    .filter(Boolean) as IFilterDescriptor[];

  if (
    updatedDescriptors.length === 1 &&
    updatedDescriptors[0].schemaPath.propertyJsonPath === searchTextColumnDescriptor.propertyJsonPath &&
    updatedDescriptors[0].value
  ) {
    return updatedDescriptors[0].value.toString();
  }

  const generatedRawJsonFilter = updatedDescriptors.reduce<Record<string, IFilterExpression>>((acc, descriptor) => {
    // Remove searchText from filter if it's empty
    if (descriptor.schemaPath.propertyJsonPath === searchTextColumnDescriptor.propertyJsonPath && !descriptor.value) {
      return acc;
    }

    if (descriptor.schemaPath.propertyJsonPath === filterLayerColumnDescriptor.propertyJsonPath) {
      if (!Array.isArray(acc[descriptor.schemaPath.propertyJsonPath])) {
        acc[descriptor.schemaPath.propertyJsonPath] = [];
      }

      const parsedDescriptorValue = JSON.parse(descriptor.value as string);

      (acc[descriptor.schemaPath.propertyJsonPath] as unknown as Record<string, IFilterExpression>[]).push(
        typeof parsedDescriptorValue === 'object' && parsedDescriptorValue && 'raw' in parsedDescriptorValue
          ? JSON.parse(parsedDescriptorValue.raw)
          : parsedDescriptorValue
      );
      return acc;
    }

    acc[descriptor.schemaPath.propertyJsonPath] = getFilterExpression(descriptor.operator, descriptor.value);
    return acc;
  }, {});

  const filterGroupOperator: string = groupOperator === Operator.AND ? '$and' : '$or';

  if (generatedRawJsonFilter[filterLayerColumnDescriptor.propertyJsonPath]) {
    // If we have layers inside
    const { [filterLayerColumnDescriptor.propertyJsonPath]: filterLayers, ...rest } = generatedRawJsonFilter;

    if (Object.keys(rest).length) {
      // If we have field filters
      return {
        [filterGroupOperator]: [
          ...(groupOperator === Operator.OR ? splitFilterBySeparateParts(rest) : [rest]),
          { [filterGroupOperator]: filterLayers },
        ] as unknown as SuggestionValue,
      };
    } else {
      // If we have only layers
      return { [filterGroupOperator]: [{ [filterGroupOperator]: filterLayers }] as unknown as SuggestionValue };
    }
  } else if (groupOperator === Operator.OR) {
    // If we don't have layers, and we have OR operator we need to wrap all filters in $or
    return { [filterGroupOperator]: splitFilterBySeparateParts(generatedRawJsonFilter) as unknown as SuggestionValue };
  }

  return generatedRawJsonFilter;
};

function splitFilterBySeparateParts(filter: Record<string, IFilterExpression>): Record<string, IFilterExpression>[] {
  return Object.entries(filter).map(([key, value]) => ({ [key]: value }));
}

export function trimSerializedFilter(serializedFilter: string): string {
  return serializedFilter.trim().slice(1, -1);
}

export function toggleFilterProperty(
  parsedFilterRaw: string | undefined,
  propertyJsonPath: string,
  selectedValues: SuggestionValue | SuggestionValue[],
  operatorToUse: 'equals' | 'contains'
): Record<string, IFilterExpression> | string {
  const newFilter: Record<string, IFilterExpression> = {};

  const filterToAdd = operatorToUse === 'equals' ? selectedValues : { $contains: selectedValues };

  const parsedRawFilter = parsedFilterRaw ? JSON.parse(parsedFilterRaw) : '';
  if (parsedRawFilter) {
    if (typeof parsedRawFilter === 'string') {
      newFilter[DataItemProperties.SEARCH_TEXT_KEY] = { $contains: parsedRawFilter };
    } else if (typeof parsedRawFilter === 'object') {
      _.merge(newFilter, parsedRawFilter);
    }
  }

  const topLevelGroup = Array.isArray(newFilter.$and || newFilter.$or)
    ? ((newFilter.$and || newFilter.$or) as unknown as Record<string, IFilterExpression>[])
    : [newFilter];

  if ((Array.isArray(selectedValues) && selectedValues.length) || (!Array.isArray(selectedValues) && selectedValues)) {
    if (typeof topLevelGroup[0] === 'string') {
      if (newFilter.$or) {
        topLevelGroup[0] = {
          [DataItemProperties.SEARCH_TEXT_KEY]: { $contains: topLevelGroup[0] },
        };

        const matchedOrPart = topLevelGroup.find((groupPart) => propertyJsonPath in groupPart);
        if (matchedOrPart) {
          matchedOrPart[propertyJsonPath] = filterToAdd;
        } else {
          topLevelGroup.unshift({ [propertyJsonPath]: filterToAdd });
        }
      } else {
        topLevelGroup[0] = {
          [DataItemProperties.SEARCH_TEXT_KEY]: { $contains: topLevelGroup[0] },
          [propertyJsonPath]: filterToAdd,
        };
      }
    } else {
      if (newFilter.$or) {
        const matchedOrPart = topLevelGroup.find((groupPart) => propertyJsonPath in groupPart);
        if (matchedOrPart) {
          matchedOrPart[propertyJsonPath] = filterToAdd;
        } else {
          topLevelGroup.unshift({ [propertyJsonPath]: filterToAdd });
        }
      } else {
        topLevelGroup[0][propertyJsonPath] = filterToAdd;
      }
    }
  } else {
    if (newFilter.$or) {
      const matchedOrPartIndex = topLevelGroup.findIndex((groupPart) => propertyJsonPath in groupPart);
      topLevelGroup.splice(matchedOrPartIndex, 1);
    } else {
      delete topLevelGroup[0][propertyJsonPath];
    }
  }

  return Object.keys(newFilter).length === 1 &&
    DataItemProperties.SEARCH_TEXT_KEY in newFilter &&
    typeof newFilter[DataItemProperties.SEARCH_TEXT_KEY] === 'object' &&
    newFilter[DataItemProperties.SEARCH_TEXT_KEY] &&
    '$contains' in (newFilter[DataItemProperties.SEARCH_TEXT_KEY] as object)
    ? ((newFilter[DataItemProperties.SEARCH_TEXT_KEY] as { $contains: unknown }).$contains as string)
    : newFilter;
}
