import { ColorPicker, getColorFromString, IColor, IColorCellProps, SwatchColorPicker } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import * as _ from 'lodash';
import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TextWidgetProps } from '../../TextWidget';
import { colorMap } from '../Html/components/TinyEditor/colorMap';
import TitleField, { TitleFieldType } from '../../../TitleField/TitleField';

const Color: React.FC<TextWidgetProps> = ({ label, schema, registry, required, value, onChange }) => {
  const [t] = useTranslation();
  const updateColor = _.debounce((_e: SyntheticEvent<HTMLElement, Event>, colorObj: IColor) => {
    onChange(colorObj.str);
  }, 300);

  const updateSwatchColor = useCallback(
    (_id: string | undefined, color: string | undefined) => {
      if (color) {
        onChange(color);
      }
    },
    [onChange]
  );

  const colors = useConst<IColorCellProps[]>(() => {
    return _.chunk(colorMap, 2).map(([colorValue, colorName]) => {
      const hex = `#${colorValue}`.toLowerCase();
      return { id: hex, label: colorName, color: hex };
    }) as IColorCellProps[];
  });

  return (
    <>
      <TitleField
        type={TitleFieldType.Primitive}
        title={label || schema.title}
        localizable={schema?.cp_localizable}
        schema={schema}
        registry={registry}
        required={required}
      />
      {schema.format === 'cp:colorAll' ? (
        <ColorPicker
          color={getColorFromString(value) as IColor}
          onChange={updateColor}
          alphaType={'alpha'}
          showPreview={true}
          strings={{
            red: t('common.colors.red'),
            green: t('common.colors.green'),
            blue: t('common.colors.blue'),
            hex: t('common.colors.hex'),
            alpha: t('common.colors.alpha'),
          }}
        />
      ) : (
        <SwatchColorPicker
          selectedId={value}
          columnCount={5}
          cellShape={'square'}
          cellHeight={30}
          cellWidth={30}
          cellBorderWidth={2}
          colorCells={colors}
          onCellFocused={updateSwatchColor}
        />
      )}
    </>
  );
};

export default Color;
