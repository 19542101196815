import React, { RefObject, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Filter, Document } from 'mongodb';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { IPageSetting, IScrollableContent } from '@cpa/base-core/types';
import { changePageSetting } from '@cpa/base-core/store/settings/actions';
import { PanelType } from '@fluentui/react';
import { FacetFilterPropertyValue, IFacetFilterField, parseFilterMessage } from '@cp/base-utils';
import { useBoolean } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { GenericScreenContext } from '@cpa/base-core/constants';
import { useDebouncedValue, useSyncedPageSettings } from '@cpa/base-core/hooks';

import { Resizable } from '../../../Resizable/Resizable';
import { toggleFilterProperty, trimSerializedFilter } from '../Filter/components/FilterLayer/utils';
import Drawer from '../../../Drawer/Drawer';

import styles from './FacetFiltersWrapper.module.scss';
import FacetFilters from './components/FacetFilters/FacetFilters';
import ShowFacetFiltersButton from './components/FacetFiltersButton/ShowFacetFiltersButton';
import { IFacetFilterData, getFacetFilters } from './helpers/getFacetFilters';

const facetFiltersMinWidth = 150;
const facetFiltersDefaultWidth = 300;

const resizeDirections = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

export interface IFacetFiltersWrapperRef {
  setFilterValue: (value: string) => void;
}

export interface IFacetFiltersWrapperProps {
  page: Schemas.CpaPage;
  schema: IJSONSchema | null;

  children?: React.ReactNode;

  isWidget?: boolean;

  tableRef?: RefObject<IScrollableContent>;
  onFilterChange: (value: string) => void;

  signalForFacetsToRefresh?: number;
  dataUrlMongoFilter?: Filter<Document>;
}

const FacetFiltersWrapper = React.forwardRef<IFacetFiltersWrapperRef, IFacetFiltersWrapperProps>(
  ({ page, schema, children, isWidget, tableRef, dataUrlMongoFilter, signalForFacetsToRefresh, onFilterChange }: IFacetFiltersWrapperProps, ref) => {
    const dispatch = useDispatch();

    const [containerRef, isOnScreen] = useInView({
      triggerOnce: false,
      threshold: 0,
    });

    const genericScreenContext = useContext(GenericScreenContext);

    const [filterValue, setFilterValue] = useState('');
    const [debouncedSchema] = useDebouncedValue(schema, 500);

    const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
    const isMobileDevice = useMediaQuery({ query: '(max-width: 1000px)' });

    const pageSettings: IPageSetting | undefined = useSelector((state: IGlobalState) => state.settings.pages[page.identifier!]);

    const [facetFiltersWidth, setFacetFiltersWidth] = useState(() => {
      if (isMobileDevice || pageSettings?.facetFiltersWidth === 0) {
        return 0;
      }

      return pageSettings?.facetFiltersWidth ? pageSettings.facetFiltersWidth : facetFiltersDefaultWidth;
    });

    const resizableSize = useMemo(() => ({ width: facetFiltersWidth, height: '100%' }), [facetFiltersWidth]);
    const [isDragging, { toggle: toggleIsDragging }] = useBoolean(false);

    const [isLoading, setIsLoading] = useState(true);
    const [facetFilterProperties, setFacetFilterProperties] = useState<IFacetFilterField[] | undefined>();
    const [facetFilters, setFacetFilters] = useState<IFacetFilterData[]>([]);
    const [initialFacetFilters, setInitialFacetFilters] = useState<IFacetFilterData[]>([]);

    const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);
    const [, i18n] = useTranslation();
    const currentLanguage = useMemo(() => dataLanguage ?? i18n.language, [dataLanguage, i18n.language]);

    const updatePageSetting = useSyncedPageSettings(false, true);

    const updateFacetFilterWidth = useCallback(
      (width: number, storeToPageSettings: boolean) => {
        setFacetFiltersWidth(width);

        if (storeToPageSettings) {
          updatePageSetting({
            pageKey: page.identifier,
            setting: 'facetFiltersWidth',
            value: width,
          });
        }
      },
      [page.identifier, updatePageSetting]
    );

    const handleResize = useCallback(
      (event: MouseEvent | TouchEvent, direction: unknown, resizable: HTMLElement) => {
        updateFacetFilterWidth(resizable.clientWidth, false);
      },
      [updateFacetFilterWidth]
    );

    const handleResizeEnd = useCallback(
      (event: MouseEvent | TouchEvent, direction: unknown, resizable: HTMLElement) => {
        updateFacetFilterWidth(resizable.clientWidth > facetFiltersMinWidth ? resizable.clientWidth : 0, true);
        toggleIsDragging();
      },
      [updateFacetFilterWidth, toggleIsDragging]
    );

    const closeFilter = useCallback(() => {
      updateFacetFilterWidth(0, true);
    }, [updateFacetFilterWidth]);

    const showFilter = useCallback(() => {
      updateFacetFilterWidth(facetFiltersDefaultWidth, !isMobileDevice);
    }, [updateFacetFilterWidth, isMobileDevice]);

    const loadFacetFilterProperties = useCallback(async () => {
      if (!page || !debouncedSchema) return;

      const { fields, facetFilters } = await getFacetFilters(page, debouncedSchema, '', [], dataUrlMongoFilter, undefined);

      setFacetFilterProperties(fields);
      setInitialFacetFilters(facetFilters);
    }, [page, debouncedSchema, dataUrlMongoFilter, currentLanguage]);

    const loadFacetFilters = useCallback(
      async (filterValue: string) => {
        if (!page || !debouncedSchema || !facetFilterProperties) return;

        setFilterValue(filterValue);

        let facetFiltersToSet: IFacetFilterData[] = [];

        if (isLoading && filterValue === '' && facetFilterProperties.length && initialFacetFilters.length) {
          const facetFilterPropertyKeys = facetFilterProperties.map((facet) => facet.propertyJsonPath);

          facetFiltersToSet = initialFacetFilters.filter((facet) => facetFilterPropertyKeys.includes(facet.propertyJsonPath));
        } else {
          const response = await getFacetFilters(page, debouncedSchema, filterValue, facetFilterProperties, dataUrlMongoFilter, facetFilters);

          facetFiltersToSet = response.facetFilters;
        }

        setFacetFilters(facetFiltersToSet);

        if (isLoading) {
          setIsLoading(false);

          if (!facetFiltersToSet.length && !isMobileDevice) {
            closeFilter();
          }
        }
      },
      [
        page,
        debouncedSchema,
        facetFilterProperties,
        dataUrlMongoFilter,
        isLoading,
        currentLanguage,
        facetFilters,
        initialFacetFilters,
        isMobileDevice,
        closeFilter,
      ]
    );

    useEffect(() => {
      if (debouncedSchema) {
        loadFacetFilterProperties();
      }
    }, [page, debouncedSchema, currentLanguage]);

    useEffect(() => {
      if (debouncedSchema && tableRef?.current) {
        loadFacetFilters(tableRef?.current?.filterValue || '');
      }
    }, [page, debouncedSchema, tableRef, facetFilterProperties, initialFacetFilters, currentLanguage, dataUrlMongoFilter, signalForFacetsToRefresh]);

    useImperativeHandle(ref, () => ({
      setFilterValue: (value: string): void => {
        if (filterValue !== value) {
          loadFacetFilters(value);
        }
      },
    }));

    const handlePropertyFilterChange = useCallback(
      (propertyJsonPath: string, selectedValue: FacetFilterPropertyValue) => {
        const facetFilter = facetFilters.find((f) => f.property.propertyJsonPath === propertyJsonPath);

        let selectedValues = facetFilter?.selectedItems ?? [];

        if (selectedValues.includes(selectedValue)) {
          selectedValues = selectedValues.filter((v) => v !== selectedValue);
        } else {
          selectedValues = [...selectedValues, selectedValue];
        }

        const parsedFilter = parseFilterMessage(tableRef?.current?.filterValue || '');
        const newFilter = toggleFilterProperty(parsedFilter?.raw, propertyJsonPath, selectedValues, 'equals');

        const filterValue = trimSerializedFilter(JSON.stringify(newFilter));

        loadFacetFilters(filterValue);
        onFilterChange(filterValue);
      },
      [onFilterChange, loadFacetFilters, tableRef]
    );

    const handleShowMoreClick = useCallback(
      (facetFilter: IFacetFilterData) => {
        setFacetFilters((facetFilters) => {
          const index = facetFilters.findIndex((f) => f.property === facetFilter.property);
          const newFacetFilters = [...facetFilters];
          const oldFacetFilter = newFacetFilters[index];

          newFacetFilters[index] = {
            ...oldFacetFilter,

            maxItemsCount: oldFacetFilter.maxItemsCount + 10,
            areMoreItemsLoading: true,
          };

          return newFacetFilters;
        });

        setFacetFilterProperties((facetFilterProperties) => {
          if (!facetFilterProperties) {
            return facetFilterProperties;
          }

          const index = facetFilterProperties.findIndex((f) => f.propertyJsonPath === facetFilter.propertyJsonPath);
          const newFacetFilterProperties = [...facetFilterProperties];
          const oldFacetFilterProperty = newFacetFilterProperties[index];

          newFacetFilterProperties[index] = {
            ...oldFacetFilterProperty,

            maxItemsCount: oldFacetFilterProperty.maxItemsCount + 10,
          };

          return newFacetFilterProperties;
        });
      },
      [setFacetFilters, setFacetFilterProperties]
    );

    const handleShowUnselectedItems = useCallback(
      (facetFilter: IFacetFilterData) => {
        setFacetFilters((facetFilters) => {
          const index = facetFilters.findIndex((f) => f.property === facetFilter.property);
          const newFacetFilters = [...facetFilters];
          const oldFacetFilter = newFacetFilters[index];

          newFacetFilters[index] = {
            ...oldFacetFilter,

            areUnselectedItemsShown: true,
            items: [...oldFacetFilter.items, ...oldFacetFilter.uncheckedItems],
            uncheckedItems: [],
          };

          return newFacetFilters;
        });
      },
      [setFacetFilters]
    );

    const sidebarContent = useMemo(() => {
      return (
        <div
          className={classNames({
            [styles.facetFilters]: true,
            [styles.hidden]: !facetFiltersWidth,
          })}
        >
          <div className={styles.stickyWrapper}>
            <FacetFilters
              isLoading={isLoading}
              facetFilters={facetFilters}
              darkMode={darkMode}
              isMobileDevice={isMobileDevice}
              onClose={closeFilter}
              onPropertySelected={handlePropertyFilterChange}
              onShowMoreClick={handleShowMoreClick}
              onShowUnselectedItems={handleShowUnselectedItems}
            />
          </div>
        </div>
      );
    }, [
      isLoading,
      facetFilters,
      facetFiltersWidth,
      darkMode,
      isMobileDevice,
      closeFilter,
      handlePropertyFilterChange,
      handleShowMoreClick,
      handleShowUnselectedItems,
    ]);

    return (
      <div ref={containerRef}>
        <div className={classNames({ [styles.resizable]: !isMobileDevice })}>
          {isMobileDevice ? (
            <Drawer isOpen={!!facetFiltersWidth} onClose={closeFilter} panelType={PanelType.customNear}>
              {sidebarContent}
            </Drawer>
          ) : (
            <Resizable
              className={classNames({
                [styles.panel]: true,
                [styles.panelDragging]: isDragging,
                [styles.panelLeft]: true,
                [styles.panelDark]: darkMode,
                [styles.panelWithoutResizer]: !facetFiltersWidth,
              })}
              defaultSize={resizableSize}
              size={resizableSize}
              onResizeStart={toggleIsDragging}
              onResize={handleResize}
              onResizeStop={handleResizeEnd}
              maxWidth="100%"
              minWidth="1"
              enable={resizeDirections}
            >
              {sidebarContent}
            </Resizable>
          )}

          <div
            className={classNames({
              [styles.panel]: !isMobileDevice,
              [styles.panelRight]: !isMobileDevice,
              [styles.panelWithoutSidebar]: !isMobileDevice && !facetFiltersWidth,
            })}
          >
            {children}
          </div>
        </div>

        {!facetFiltersWidth && isOnScreen && <ShowFacetFiltersButton isMobileDevice={isMobileDevice} isWidget={isWidget} onClick={showFilter} />}
      </div>
    );
  }
);

export default React.memo(FacetFiltersWrapper);
