const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpNoSeries',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpNoSeries' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': false,
    'cp_disableVersions': true,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp No Series',
    'properties': {
        'stepValue': {
            'title': 'Step Value',
            'description': '<div>Defines the increment size of the series. Every time a new identifier is created, the number series will be incremented by the step value. Defaults to 1.</div>',
            'type': 'number',
            'links': [{ 'href': 'http://schema.org/stepValue', 'rel': 'canonical' }],
        },
        'endDate': {
            'title': 'End Date',
            'description': '<div>If the series is valid only during a specific time, the end date can be set here.</div>',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
        },
        'startDate': {
            'title': 'Start Date',
            'description': '<div>If the series is valid only during a specific time, the start date can be set here.</div>\n<div>For example if a different number series should be used every month.</div>',
            'type': 'string',
            'format': 'date',
            'cp_ui': { 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/startDate', 'rel': 'canonical' }],
        },
        'pattern': {
            'title': 'Pattern',
            'description': '<div>The pattern defines the pattern and the current state of the sequence. </div>\n<div>For example a pattern of the form <em>ABC-0002</em> defines that</div>\n<div>1. the identifier of the objects are of the form <em>ABC-0002</em> up to <em>ABC-9999. </em></div>\n<div>2. the next created object will be assigned the identifier <em>ABC-0002</em> and the number series will be updated to <em>ABC-0003</em>.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'cp_localizable': true,
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/pattern', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 10 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'url': {
            'title': 'Url',
            'description': '<div>The URL of the type the number series should be used for.</div>',
            'type': 'string',
            'format': 'uri',
            'cp_ui': { 'sortOrderForm': 20 },
            'links': [
                { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' },
                { 'href': 'http://schema.org/url', 'rel': 'canonical' },
            ],
        },
    },
    'required': ['pattern'],
};
export const CpNoSeriesSchema = Schema;
