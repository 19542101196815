import flatten from 'flat';
import * as _ from 'lodash';
export function objectToFlattenMap(obj) {
    const flattenObj = flatten(obj);
    const result = new Map();
    for (const [key, value] of Object.entries(flattenObj)) {
        if (value && Object.keys(value).length === 0 && value.constructor === Object) {
            continue;
        }
        result.set(key, value);
    }
    return result;
}
export function objectToPropertyList(obj) {
    const result = [];
    function flattenObject(value, path, propertyList) {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                flattenObject(item, path, propertyList);
            });
        }
        else if (value && typeof value === 'object') {
            Object.entries(value).forEach(([key, val]) => {
                const newPath = path ? `${path}.${key}` : key;
                flattenObject(val, newPath, propertyList);
            });
        }
        else {
            propertyList.push([path, value]);
        }
    }
    flattenObject(obj, '', result);
    return result;
}
export function flattenMapToObject(map) {
    const result = {};
    for (const [key, value] of map.entries()) {
        _.set(result, key, value);
    }
    return result;
}
