import { Environment, LogLevel } from '@cp/base-types';
import StackTrace from 'stacktrace-js';
import { MessageFormatter } from '../formatting';
import { LogMessage } from '../logMessage';
export class LoggerBase {
    constructor(name, customConsole, messsageFormatter) {
        this._console = customConsole !== null && customConsole !== void 0 ? customConsole : Environment.console;
        this._messsageFormatter = messsageFormatter !== null && messsageFormatter !== void 0 ? messsageFormatter : new MessageFormatter();
        this._name = name;
        this._appName = Environment.appName;
        this._hostName = Environment.hostName;
    }
    debug(message, ...optionalParams) {
        var _a;
        try {
            const stack = StackTrace.getSync();
            const sender = this.createSender((_a = this._name) !== null && _a !== void 0 ? _a : stack[1].functionName);
            this.handleMessage(new LogMessage({
                level: LogLevel.Debug,
                sender: sender,
                message: message,
                optionalParams: optionalParams,
            })).catch((err) => this._console.error(err));
        }
        catch (err) {
            this._console.error(err);
        }
    }
    log(message, ...optionalParams) {
        var _a;
        try {
            const stack = StackTrace.getSync();
            const sender = this.createSender((_a = this._name) !== null && _a !== void 0 ? _a : stack[1].functionName);
            this.handleMessage(new LogMessage({
                level: LogLevel.Log,
                sender: sender,
                message: message,
                optionalParams: optionalParams,
            })).catch((err) => this._console.error(err));
        }
        catch (err) {
            this._console.error(err);
        }
    }
    info(message, ...optionalParams) {
        var _a;
        try {
            const stack = StackTrace.getSync();
            const sender = this.createSender((_a = this._name) !== null && _a !== void 0 ? _a : stack[1].functionName);
            this.handleMessage(new LogMessage({
                level: LogLevel.Info,
                sender: sender,
                message: message,
                optionalParams: optionalParams,
            })).catch((err) => this._console.error(err));
        }
        catch (err) {
            this._console.error(err);
        }
    }
    warn(message, ...optionalParams) {
        var _a;
        try {
            const stack = StackTrace.getSync();
            const sender = this.createSender((_a = this._name) !== null && _a !== void 0 ? _a : stack[1].functionName);
            this.handleMessage(new LogMessage({
                level: LogLevel.Warn,
                sender: sender,
                message: message,
                optionalParams: optionalParams,
            })).catch((err) => this._console.error(err));
        }
        catch (err) {
            this._console.error(err);
        }
    }
    error(message, ...optionalParams) {
        var _a;
        try {
            const stack = StackTrace.getSync();
            const sender = this.createSender((_a = this._name) !== null && _a !== void 0 ? _a : stack[1].functionName);
            this.handleMessage(new LogMessage({
                level: LogLevel.Error,
                sender: sender,
                message: message,
                optionalParams: optionalParams,
            })).catch((err) => this._console.error(err));
        }
        catch (err) {
            this._console.error(err);
        }
    }
    createSender(callerName) {
        return [this._hostName, this._appName, callerName].filter((el) => el !== undefined && el !== '').join('|');
    }
    formatMessage(logMessage) {
        return this._messsageFormatter.format(logMessage);
    }
}
