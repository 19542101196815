export const filePick = (cb: (url: string, details: { title: string }) => void): void => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');

  input.onchange = (): void => {
    if (!input.files || !input.files[0]) {
      return;
    }

    const file = input.files[0];

    const reader = new FileReader();

    reader.onload = (): void => {
      input.remove();

      if (reader.result && typeof reader.result === 'string') {
        cb(reader.result, { title: file.name });
      }
    };

    reader.readAsDataURL(file);
  };

  input.click();
};
