const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/CpType',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpType' }],
    'cp_aggregationPipeline': ['[{"$set":{"name":"$identifier"}}]'],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cp Type',
    'properties': {
        'annotations': {
            'title': 'Annotations',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotation',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotation' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cp Annotation',
                'properties': {
                    'value': {
                        'title': 'Value',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 51 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/value', 'rel': 'canonical' }],
                    },
                    'annotationLanguage': {
                        'title': 'Annotation Language',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpLanguage',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpLanguage' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/annotationLanguage', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': true,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Language' } },
                        'cp_ui': { 'sortOrderForm': 30 },
                    },
                    'annotationPropertyType': {
                        'title': 'Annotation Property Type',
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotationProperty',
                        'type': 'object',
                        'links': [
                            { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotationProperty' },
                            { 'href': 'http://platform.cosmoconsult.com/ontology/annotationPropertyType', 'rel': 'canonical' },
                        ],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': true,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'properties': {
                            'identifier': {
                                'title': 'Identifier',
                                'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                                'type': 'string',
                                'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                            },
                        },
                        'cp_ui': { 'sortOrderForm': 27 },
                    },
                },
            },
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/annotations', 'rel': 'canonical' }],
        },
        'assignments': {
            'title': 'Assignments',
            'description': '<div>Properties assigned to this type based on the class (must be activated by expanding them and checking the corresponding box for them to be displayed)</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/CpFieldIndexAssignment',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFieldIndexAssignment' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Cp Field Index Assignment',
                'properties': {
                    'permissions': {
                        'title': 'Permissions',
                        'type': 'array',
                        'items': {
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpTypePermission',
                            'type': 'object',
                            'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypePermission' }],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': true,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'title': 'Cp Type Permission',
                            'properties': {
                                'permissionTarget': {
                                    'title': 'Permission Target',
                                    'anyOf': [
                                        {
                                            '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                            'type': 'object',
                                            'links': [
                                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' },
                                            ],
                                            'cp_handledByApiGateway': false,
                                            'cp_disableTrash': false,
                                            'cp_disableLocalization': false,
                                            'cp_disableVersions': false,
                                            'cp_disableClone': false,
                                            'cp_disableLazyLoading': false,
                                            'patternProperties': { '^[_@].*$': {} },
                                            'additionalProperties': false,
                                            'title': 'Cp Permission Target Role',
                                            'properties': {
                                                'role': {
                                                    'title': 'Role',
                                                    'description': '<div>\n<div>The role the permission policy is defined for. </div>\n</div>',
                                                    'type': 'string',
                                                    'links': [
                                                        {
                                                            'rel': 'glossary',
                                                            'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion',
                                                        },
                                                        { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                                    ],
                                                },
                                                '_type': {
                                                    'type': 'string',
                                                    'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                                    'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                                },
                                            },
                                            'required': ['role'],
                                        },
                                        {
                                            '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                            'type': 'object',
                                            'links': [
                                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' },
                                            ],
                                            'cp_handledByApiGateway': false,
                                            'cp_disableTrash': false,
                                            'cp_disableLocalization': false,
                                            'cp_disableVersions': false,
                                            'cp_disableClone': false,
                                            'cp_disableLazyLoading': false,
                                            'patternProperties': { '^[_@].*$': {} },
                                            'additionalProperties': false,
                                            'title': 'Cp Permission Target User',
                                            'properties': {
                                                'email': {
                                                    'title': 'Email',
                                                    'description': '<div>The name of the user the permission policy is adressing.</div>',
                                                    'type': 'string',
                                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                                                },
                                                '_type': {
                                                    'type': 'string',
                                                    'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                                    'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                                },
                                            },
                                            'required': ['email'],
                                        },
                                    ],
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/permissionTarget', 'rel': 'canonical' }],
                                },
                                'permissionType': {
                                    'title': 'Permission Type',
                                    '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionType',
                                    'type': 'string',
                                    'links': [
                                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionType' },
                                        { 'href': 'http://platform.cosmoconsult.com/ontology/permissionType', 'rel': 'canonical' },
                                    ],
                                    'cp_handledByApiGateway': false,
                                    'cp_disableTrash': false,
                                    'cp_disableLocalization': false,
                                    'cp_disableVersions': false,
                                    'cp_disableClone': false,
                                    'cp_disableLazyLoading': false,
                                    'patternProperties': { '^[_@].*$': {} },
                                    'additionalProperties': false,
                                    'enum': ['http://platform.cosmoconsult.com/ontology/Allow', 'http://platform.cosmoconsult.com/ontology/Deny'],
                                    'enumNames': ['Allow', 'Deny'],
                                },
                            },
                        },
                        'cp_ui': { 'sortOrderForm': 53 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/permission', 'rel': 'canonical' }],
                    },
                    'annotations': {
                        'title': 'Annotations',
                        'type': 'array',
                        'items': {
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotation',
                            'type': 'object',
                            'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotation' }],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'title': 'Cp Annotation',
                            'properties': {
                                'value': {
                                    'title': 'Value',
                                    'type': 'string',
                                    'cp_ui': { 'sortOrderForm': 32 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/value', 'rel': 'canonical' }],
                                },
                                'annotationLanguage': {
                                    'title': 'Annotation Language',
                                    '$id': 'http://platform.cosmoconsult.com/ontology/CpLanguage',
                                    'type': 'object',
                                    'links': [
                                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpLanguage' },
                                        { 'href': 'http://platform.cosmoconsult.com/ontology/annotationLanguage', 'rel': 'canonical' },
                                    ],
                                    'cp_handledByApiGateway': false,
                                    'cp_disableTrash': false,
                                    'cp_disableLocalization': true,
                                    'cp_disableVersions': false,
                                    'cp_disableClone': false,
                                    'cp_disableLazyLoading': false,
                                    'patternProperties': { '^[_@].*$': {} },
                                    'additionalProperties': false,
                                    'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Language' } },
                                    'cp_ui': { 'sortOrderForm': 31 },
                                },
                                'annotationPropertyType': {
                                    'title': 'Annotation Property Type',
                                    '$id': 'http://platform.cosmoconsult.com/ontology/CpAnnotationProperty',
                                    'type': 'object',
                                    'links': [
                                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpAnnotationProperty' },
                                        { 'href': 'http://platform.cosmoconsult.com/ontology/annotationPropertyType', 'rel': 'canonical' },
                                    ],
                                    'cp_handledByApiGateway': false,
                                    'cp_disableTrash': false,
                                    'cp_disableLocalization': true,
                                    'cp_disableVersions': false,
                                    'cp_disableClone': false,
                                    'cp_disableLazyLoading': false,
                                    'patternProperties': { '^[_@].*$': {} },
                                    'additionalProperties': false,
                                    'properties': {
                                        'identifier': {
                                            'title': 'Identifier',
                                            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
                                            'type': 'string',
                                            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                        },
                                    },
                                    'cp_ui': { 'sortOrderForm': 30 },
                                },
                                'permissions': {
                                    'title': 'Permissions',
                                    'type': 'array',
                                    'items': {
                                        '$id': 'http://platform.cosmoconsult.com/ontology/CpTypePermission',
                                        'type': 'object',
                                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpTypePermission' }],
                                        'cp_handledByApiGateway': false,
                                        'cp_disableTrash': false,
                                        'cp_disableLocalization': true,
                                        'cp_disableVersions': false,
                                        'cp_disableClone': false,
                                        'cp_disableLazyLoading': false,
                                        'patternProperties': { '^[_@].*$': {} },
                                        'additionalProperties': false,
                                        'title': 'Cp Type Permission',
                                        'properties': {
                                            'permissionTarget': {
                                                'title': 'Permission Target',
                                                'anyOf': [
                                                    {
                                                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                                        'type': 'object',
                                                        'links': [
                                                            {
                                                                'rel': 'collection',
                                                                'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole',
                                                            },
                                                        ],
                                                        'cp_handledByApiGateway': false,
                                                        'cp_disableTrash': false,
                                                        'cp_disableLocalization': false,
                                                        'cp_disableVersions': false,
                                                        'cp_disableClone': false,
                                                        'cp_disableLazyLoading': false,
                                                        'patternProperties': { '^[_@].*$': {} },
                                                        'additionalProperties': false,
                                                        'title': 'Cp Permission Target Role',
                                                        'properties': {
                                                            'role': {
                                                                'title': 'Role',
                                                                'type': 'string',
                                                                'links': [
                                                                    {
                                                                        'rel': 'glossary',
                                                                        'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion',
                                                                    },
                                                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                                                ],
                                                                'cp_ui': { 'sortOrderForm': 37 },
                                                            },
                                                            '_type': {
                                                                'type': 'string',
                                                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                                            },
                                                        },
                                                        'required': ['role'],
                                                    },
                                                    {
                                                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                                        'type': 'object',
                                                        'links': [
                                                            {
                                                                'rel': 'collection',
                                                                'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser',
                                                            },
                                                        ],
                                                        'cp_handledByApiGateway': false,
                                                        'cp_disableTrash': false,
                                                        'cp_disableLocalization': false,
                                                        'cp_disableVersions': false,
                                                        'cp_disableClone': false,
                                                        'cp_disableLazyLoading': false,
                                                        'patternProperties': { '^[_@].*$': {} },
                                                        'additionalProperties': false,
                                                        'title': 'Cp Permission Target User',
                                                        'properties': {
                                                            'email': {
                                                                'title': 'Email',
                                                                'type': 'string',
                                                                'cp_ui': { 'sortOrderForm': 30 },
                                                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                                                            },
                                                            '_type': {
                                                                'type': 'string',
                                                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                                                            },
                                                        },
                                                        'required': ['email'],
                                                    },
                                                ],
                                                'cp_ui': { 'sortOrderForm': 25 },
                                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/permissionTarget', 'rel': 'canonical' }],
                                            },
                                            'permissionType': {
                                                'title': 'Permission Type',
                                                '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionType',
                                                'type': 'string',
                                                'links': [
                                                    { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionType' },
                                                    { 'href': 'http://platform.cosmoconsult.com/ontology/permissionType', 'rel': 'canonical' },
                                                ],
                                                'cp_handledByApiGateway': false,
                                                'cp_disableTrash': false,
                                                'cp_disableLocalization': false,
                                                'cp_disableVersions': false,
                                                'cp_disableClone': false,
                                                'cp_disableLazyLoading': false,
                                                'patternProperties': { '^[_@].*$': {} },
                                                'additionalProperties': false,
                                                'enum': ['http://platform.cosmoconsult.com/ontology/Allow', 'http://platform.cosmoconsult.com/ontology/Deny'],
                                                'enumNames': ['Allow', 'Deny'],
                                                'default': 'http://platform.cosmoconsult.com/ontology/Allow',
                                                'cp_ui': { 'sortOrderForm': 42 },
                                            },
                                        },
                                    },
                                    'cp_ui': { 'sortOrderForm': 33 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/permission', 'rel': 'canonical' }],
                                },
                            },
                        },
                        'cp_ui': { 'sortOrderForm': 32 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/annotations', 'rel': 'canonical' }],
                    },
                    'deactivated': {
                        'title': 'Deactivated',
                        'type': 'boolean',
                        'cp_ui': { 'sortOrderForm': 40 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/deactivated', 'rel': 'canonical' }],
                    },
                    'propertyJsonPath': {
                        'title': 'Property Json Path',
                        'description': '<div>The name of the property (camelcase) in the Property Json</div>',
                        'type': 'string',
                        'cp_ui': { 'sortOrderForm': 55 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/propertyJsonPath', 'rel': 'canonical' }],
                    },
                    'fieldTriggers': {
                        'title': 'Field Triggers',
                        'type': 'array',
                        'items': {
                            '$id': 'http://platform.cosmoconsult.com/ontology/CpFieldTrigger',
                            'type': 'object',
                            'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFieldTrigger' }],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': true,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'title': 'Cp Field Trigger',
                            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Field Trigger' } },
                        },
                        'cp_ui': { 'sortOrderForm': 44 },
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/fieldTriggers', 'rel': 'canonical' }],
                    },
                },
                'format': 'propertyTreeView',
            },
            'cp_ui': { 'sortOrderForm': 32 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/assignments', 'rel': 'canonical' }],
        },
        'class': {
            'title': 'Class',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpClass',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpClass' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/class', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Class' } },
            'cp_ui': { 'sortOrderForm': 30 },
        },
        'area': {
            'title': 'Area',
            'description': '<div>The area this item belongs to. Currently this can be either the core area or the business area.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/Area',
            'type': 'string',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FArea' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/area', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'enum': ['http://platform.cosmoconsult.com/ontology/CoreArea', 'http://platform.cosmoconsult.com/ontology/BusinessArea'],
            'enumNames': ['Core', 'Business'],
            'default': 'http://platform.cosmoconsult.com/ontology/BusinessArea',
            'cp_ui': { 'sortOrderForm': 34 },
        },
        'internalHint': {
            'title': 'Internal Hint',
            'description': '<div>Internal notes, explanations, warnings, <span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;">helpful tips,</span><span style="font-family: Neptune, Verdana, -apple-system, BlinkMacSystemFont, Roboto, \'Helvetica Neue\', sans-serif;"> etc.</span></div>',
            'type': 'string',
            'format': 'cp:multiline',
            'cp_ui': { 'sortOrderForm': 35 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/internalHint', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 36 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 53 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 55 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 56 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 29 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'sortOrderForm': 31 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const CpTypeSchema = Schema;
