import * as React from 'react';
import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';

import styles from './LocalizationEditorTabsWrapperArrow.module.scss';

export interface HorizontalScrollArrowProps {
  className: string;
  direction: 'left' | 'right';
  onClick(event: React.MouseEvent): void;
}

export const LocalizationEditorTabsWrapperArrow = ({ direction, className, onClick }: HorizontalScrollArrowProps) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  return (
    <button
      className={classNames({
        [className]: true,
        [styles.arrow]: true,
        [styles.arrowDark]: darkMode,
        [styles.arrowLeft]: direction === 'left',
        [styles.arrowRight]: direction === 'right',
      })}
      onClick={onClick}
    >
      <span className={styles.icon}>
        <Icon iconName={direction === 'left' ? 'ChevronLeftMed' : 'ChevronRightMed'} />
      </span>
    </button>
  );
};
