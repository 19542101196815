import { SocialMediaIconRef } from '@cpa/base-core/types';
import React, { useLayoutEffect, useMemo } from 'react';
import { Schemas } from '@cp/base-types';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { Environment } from '@cpa/base-core/app/environment';
import { Footer as ExternalFooter, INavigationItem } from '@cpa/ui-components';
import { useMenuItems } from '@cpa/base-core/hooks';

import icons from './assets';
import styles from './Footer.module.scss';

export const SocialMediaIcon: Record<SocialMediaIconRef, keyof typeof icons | null> = {
  [SocialMediaIconRef.FACEBOOK]: 'facebook',
  [SocialMediaIconRef.INSTAGRAM]: 'instagram',
  [SocialMediaIconRef.LINKEDIN]: 'linkedin',
  [SocialMediaIconRef.TWITTER]: 'twitter',
  [SocialMediaIconRef.XING]: 'xing',
  [SocialMediaIconRef.YOUTUBE]: 'youtube',
  [SocialMediaIconRef.NOT_SELECTED]: null,
};

type FooterLinkWithInterpolatedLinks = Omit<Schemas.CpaFooter, 'socialMedia'> & { socialMedia: keyof typeof icons };

interface LinksState {
  social: FooterLinkWithInterpolatedLinks[];
  links: FooterLinkWithInterpolatedLinks[];
}

const Footer: React.FC = () => {
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);
  const footerLinks = useSelector((state: IGlobalState) => state.app.footerLinks);
  const copyright = useSelector((state: IGlobalState) => state.app.cpa?.configuration?.copyright);

  const [menuItems] = useMenuItems();

  const navigationItems: INavigationItem[] = useMemo(() => {
    return menuItems
      .map((menuItem) => {
        if (!menuItem.links?.length) return null;
        return {
          title: menuItem.name,
          link: menuItem.url || `/_navigation/${encodeURIComponent(menuItem.key)}`,
          target: '_blank',
          active: 1,
          current: 0,
          spacer: 0,
          hasSubpages: 0,
          children:
            (menuItem.links?.map((child) => {
              return {
                title: child.name,
                link: child.url || `/_navigation/${encodeURIComponent(child.key)}`,
                target: '_blank',
                active: 1,
                current: 0,
                spacer: 0,
                hasSubpages: 0,
                iconName: menuItem.icon,
              };
            }) as INavigationItem[]) || [],
          iconName: menuItem.icon,
        };
      })
      .filter(Boolean) as INavigationItem[];
  }, [menuItems]);

  const buildDate = useMemo(() => {
    if (!Environment.env.REACT_APP_BUILD_TIME) {
      return null;
    }

    return new Date(parseInt(Environment.env.REACT_APP_BUILD_TIME, 10)).toUTCString();
  }, []);
  const buildDetails = `${Environment.env.REACT_APP_NAME} | ${Environment.env.REACT_APP_VERSION}${buildDate ? ` | ${buildDate}` : ''}`;

  const { social, links } = useMemo(() => {
    return footerLinks.reduce(
      (acc, link) => {
        const socialMedia = link.socialMedia && SocialMediaIcon[link.socialMedia];
        acc[socialMedia ? 'social' : 'links'].push({ ...link, socialMedia } as FooterLinkWithInterpolatedLinks);
        return acc;
      },
      { social: [], links: [] } as LinksState
    );
  }, [footerLinks]);

  const labels = useMemo(
    () => ({
      logoAlt: 'COSMO CONSULT',
      copyright: `${buildDetails} | ${copyright?.replace(new RegExp('{{year}}', 'g'), new Date().getFullYear().toString()) ?? ''}`,
    }),
    [buildDetails, copyright]
  );

  /*
  TODO: Wait for @cpa/ui-components to allow custom color theme and logo
  Currently, we work it around with css applied to 'footer' element and useLayoutEffect code
   */
  useLayoutEffect(() => {
    const footerLogoElement = document.querySelector<HTMLImageElement>('footer img');
    if (!footerLogoElement) {
      return;
    }

    if (cpa?.footerLogo) {
      footerLogoElement.src = cpa.footerLogo;
    } else {
      footerLogoElement.remove();
    }
  }, [cpa?.footerLogo]);

  return (
    <footer className={styles.footerWrapper}>
      <ExternalFooter
        socialMediaItems={social.map((social) => ({
          type: social.socialMedia,
          title: social.name as string,
          link: social.url as string,
        }))}
        footerNavigation={navigationItems}
        legalInfoNavigation={links.map((link) => ({
          title: link.name as string,
          link: link.url as string,
          target: '_blank',
          active: 1,
          current: 0,
          spacer: 0,
          hasSubpages: 0,
        }))}
        text={(cpa?.description as string) || ''}
        labels={labels}
      />
    </footer>
  );
};

export default React.memo(Footer);
