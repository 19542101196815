import React, { MouseEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { Schemas } from '@cp/base-types';
import { useItemIdParam } from '@cpa/base-core/hooks';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useId } from '@fluentui/react-hooks';
import { getSafeString } from '@cpa/base-core/helpers';

import { LayoutContext } from '../Layout/Layout';
import Footer from '../Layout/components/Footer/Footer';
import ScrollablePaneContextProvider from '../ScrollablePaneContextProvider/ScrollablePaneContextProvider';
import { MainContent } from '../Layout/components/CachedContent/CachedContent';

import NavigationButton from './components/NavigationButton/NavigationButton';
import styles from './Navigation.module.scss';

interface INavigationProps {}

const Navigation: React.FC<INavigationProps> = () => {
  const footerWrapperId = useId('footer');
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const allAvailablePages = useSelector((state: IGlobalState) => state.app.pages);

  const currentItemIdentifier = useItemIdParam();

  useEffect(() => {
    document.title = (getSafeString(cpa?.configuration.name, true) || `Unknown CPA`) + ` - ${t('common.navigation')}`;
  }, [cpa?.configuration.name, t]);

  const currentItem: (Schemas.CpaPage & { children: (Schemas.CpaPage & { hasChildren: boolean })[] }) | null = useMemo(() => {
    const matchingItem = allAvailablePages.find((page) => page.identifier === currentItemIdentifier);

    if (!matchingItem) return null;

    const matchingChildren = allAvailablePages
      .filter((page) => page.parentCpaPage?.identifier === currentItemIdentifier)
      .map((page) => {
        return {
          ...page,
          hasChildren: allAvailablePages.some((p) => p.parentCpaPage?.identifier === page.identifier),
        };
      });

    return {
      ...matchingItem,
      children: matchingChildren,
    };
  }, [allAvailablePages, currentItemIdentifier]);

  const parentItem = useMemo(() => {
    const parentIdentifier = currentItem?.parentCpaPage?.identifier;
    if (!parentIdentifier) return null;
    const matchingItem = allAvailablePages.find((page) => page.identifier === parentIdentifier);
    if (matchingItem) {
      return {
        name: matchingItem.name,
        identifier: matchingItem.identifier,
        icon: matchingItem.icon,
      };
    }
    return null;
  }, [allAvailablePages, currentItem?.parentCpaPage?.identifier]);

  const layoutContext = useContext(LayoutContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const onPageClick = useCallback(
    (e: MouseEvent, identifier: string | undefined) => {
      e.preventDefault();
      e.stopPropagation();

      if (!identifier) return;

      const matchingPage = allAvailablePages.find((page) => page.identifier === identifier);
      if (matchingPage?.path) {
        dispatch(push(matchingPage.path));
      } else if (matchingPage?.externalUrl) {
        window.open(matchingPage.externalUrl, '_blank');
      } else {
        dispatch(push({ pathname: `/_navigation/${identifier}` }));
      }
    },
    [allAvailablePages, dispatch]
  );

  const handleBackToParent = useCallback(() => {
    if (parentItem?.identifier) {
      dispatch(push({ pathname: `/_navigation/${parentItem.identifier}` }));
    }
  }, [dispatch, parentItem?.identifier]);

  const customScrollablePaneStyles = useMemo(
    () => ({
      root: {
        marginTop: layoutContext?.isHorizontalMenu ? 147 : 101,
        maxWidth: '100%',
      },
      contentContainer: {
        overflowX: 'hidden',
      },
    }),
    [layoutContext?.isHorizontalMenu]
  );

  const handleBreadcrumbClick = useCallback(
    (e?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      e?.preventDefault();

      if (item?.href) {
        dispatch(push(item?.href));
      }
    },
    [dispatch]
  );

  const parentStructure = useMemo(() => {
    let parentIdentifier = currentItem?.parentCpaPage?.identifier;
    if (!currentItem) return [];
    const structure = [{ identifier: currentItem.identifier, name: currentItem.name }];
    while (parentIdentifier) {
      const parentPage = allAvailablePages.find((page) => page.identifier === parentIdentifier);
      if (parentPage) {
        structure.push({ identifier: parentPage.identifier, name: parentPage.name });
        parentIdentifier = parentPage?.parentCpaPage?.identifier;
      } else {
        parentIdentifier = undefined;
      }
    }
    structure.reverse();
    return structure.map((item) => {
      return {
        key: item.identifier!,
        text: item.name,
        href: `/_navigation/${item.identifier}`,
        onClick: handleBreadcrumbClick,
      };
    });
  }, [allAvailablePages, currentItem, handleBreadcrumbClick]);

  return (
    <ScrollablePaneContextProvider bottomElementSelector={`#${footerWrapperId} > footer`} customScrollablePaneStyles={customScrollablePaneStyles}>
      <MainContent>
        <div
          className={classNames({
            [styles.cover]: true,
            [styles.coverDark]: darkMode,
          })}
          data-is-single-item-cover="true"
          ref={containerRef}
        >
          <div
            className={classNames({
              [styles.body]: true,
            })}
          >
            <div className={styles.bodyIn}>
              <Breadcrumb items={parentStructure} className={styles.breadcrumb}></Breadcrumb>
              <div className={styles.subHeader}>{currentItem?.description}</div>

              <div className={classNames(styles.ctaButtons)}>
                {/*{parentItem && (*/}
                {/*  <div className={styles.button}>*/}
                {/*    <NavigationButton hasParent onClick={handleBackToParent} buttonIndex={0} highlighted image={parentItem.icon}>*/}
                {/*      {parentItem.name}*/}
                {/*    </NavigationButton>*/}
                {/*  </div>*/}
                {/*)}*/}

                {currentItem?.children &&
                  currentItem.children.map((child, index: number) => (
                    <div className={styles.button} key={index}>
                      <NavigationButton
                        url={child.path}
                        description={child?.description}
                        onClick={(e) => onPageClick(e, child.identifier)}
                        buttonIndex={index}
                        image={child.icon}
                        isComponent={true}
                        hasChildren={child.hasChildren}
                        identifier={child.identifier}
                      >
                        {child?.name}
                      </NavigationButton>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <div id={footerWrapperId}>
        <Footer />
      </div>
    </ScrollablePaneContextProvider>
  );
};

export default Navigation;
