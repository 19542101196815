import React, { ReactNode } from 'react';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { errored: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { errored: false };
  }

  public static getDerivedStateFromError = (error: Error): { errored: boolean } => {
    return { errored: true };
  };

  public render(): ReactNode {
    if (!this.state.errored) {
      return this.props.children;
    }
    return null;
  }
}

export default ErrorBoundary;
