import { IDataStoreItemDto, getEndpoint } from '@cpa/base-core/api';
import { IDataItem } from '@cpa/base-core/types';

export async function getLocalizedFormData(
  dataUrl: string,
  dataEndpointIdentifier: string,
  identifier: string,
  language: string
): Promise<IDataItem> {
  const endpoint = getEndpoint(dataEndpointIdentifier);
  const url = new URL(dataUrl, window.location.origin);

  if (!url.pathname.endsWith('/')) {
    url.pathname += '/';
  }

  url.pathname += `${encodeURIComponent(identifier)}`;

  const { value: formData } = await endpoint.axios.get<IDataStoreItemDto>(url.pathname, {
    headers: {
      'Accept-Language': language,
    },
  });

  return formData;
}
